import { ProdottoNode } from '@gql/graphql'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  BRAND,
  HEADER_BREAKPOINT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  PREHEADER_BREAKPOINT,
  PREHEADER_HEIGHT,
  PREHEADER_HEIGHT_MOBILE,
  TOOLBAR_HEIGHT,
} from 'utils/settings'

export const dateFormat = (valore: string) => dayjs(valore, 'YYYY-MM-DD').format('DD/MM/YYYY')
export const datePickerFormat = (valore: string | Date) => dayjs(valore).format('YYYY-MM-DD')
export const monthYearFormat = (valore: string, locale?: string | undefined) => {
  return dayjs(valore, 'YYYY-MM-DD')
    .toDate()
    .toLocaleDateString(locale, { year: 'numeric', month: 'long' })
}
export const localeDateFormat = (valore: string, locale?: string | undefined) => {
  return dayjs(valore, 'YYYY-MM-DD')
    .toDate()
    .toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' })
}

export const isDateValid = (valore: string, format: string) => {
  dayjs.extend(customParseFormat)
  return dayjs(valore, format, true).isValid()
}

export const getFormFieldArgs = (
  name: string,
  values: any,
  errors: any,
  touched: any,
  space?: 'md' | 'sm' | 'none' // | 'lg' | 'xl' | undefined
): {
  space?: 'md' | 'sm' | 'none' // | 'lg' | 'xl' | undefined
} => ({
  space: space || 'md',
})

export const getFormComponentArgs = (
  name: string,
  errors: any,
  touched: any
): {
  status?: '' | 'success' | 'error' | undefined
  errorMessage?: string
} => ({
  status: touched[name] && errors[name] ? 'error' : '',
  errorMessage: errors[name],
})

export const isEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

declare global {
  interface String {
    toGTMFormat(): string
    toHotjarFormat(v: string, isMobile?: boolean): string
    capitalize(): string
  }
}

String.prototype.toGTMFormat = function () {
  return this.replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\s+/g, '_')
    .toLowerCase()
}

String.prototype.toHotjarFormat = function (value: string, isMobile: boolean = false) {
  return `${value.replace('{0}', this.replace(/\.|\/|\s+/g, '_').toLowerCase())}${
    isMobile ? '_mobile' : ''
  }`
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export function escape(s: String) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

export const toAltTitle = (term: string) =>
  term ? `${term} | ${BRAND}`.replace(/  +/g, ' ') : BRAND

export const getYearOptions = (yearFrom: number, yearNumber: number) =>
  [...Array.from({ length: yearNumber }).keys()].map((i) => ({
    label: (i + yearFrom).toString(),
    value: (i + yearFrom).toString(),
  }))

export const closest = (arr: number[], goal: number) => {
  return arr.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev))
}

export const numberNotation = (valore: any, numeroDecimali?: number) => {
  const valoreSafe = Number.parseFloat(valore) || 0
  const [intPart, decimals] = valoreSafe.toFixed(numeroDecimali).toString().split('.')
  return intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (numeroDecimali > 0 ? `,${decimals}` : '')
}

export const priceNotation = (valore: any, noDecimals = false, simboloValuta = '€') =>
  `${simboloValuta} ${numberNotation(valore, noDecimals ? 0 : 2)}`

export const scrollTo = (ref: any, offset?: number, offsetMobile?: number) => {
  const y =
    ref.offsetTop -
    (window.innerWidth < HEADER_BREAKPOINT ? offsetMobile || offset || 0 : offset || 0) -
    getHeaderHeight()
  window.scrollTo({
    top: y,
    behavior: 'smooth',
  })
}

export const getFormFieldStatus = (formikProps: any, propName: string) =>
  formikProps.touched[propName] ? (formikProps.errors[propName] ? 'error' : 'success') : undefined

export const getHeaderHeight = () => {
  const pageRef = document.getElementsByClassName('page')?.[0]
  const paddingTop = window
    ?.getComputedStyle(pageRef)
    ?.getPropertyValue('padding-top')
    ?.replace('px', '')
  return paddingTop ? parseInt(paddingTop) : 0
}

// Id GraphQl
export const fromGlobalID = (globalID: string): string => atob(globalID).split(':')[1]
export const toGlobalID = (localID: number, nodeType: string) => btoa(`${nodeType}:${localID}`)

export const getCategoriaSlug = (slug: string | string[]) => {
  if (typeof slug === 'string') {
    return [slug]
  }
  const categoriaSlug = []
  for (let i = 0; i < slug.length; i++) {
    if (slug[i] === 'f') break
    else {
      categoriaSlug.push(slug[i])
    }
  }
  return categoriaSlug
}

export const getFileNameFromUrl = (url: string) => {
  let name = ''
  if (url) {
    name = url.split('/')[url.split('/').length - 1] || ''
    return name
  } else {
    return ''
  }
}

const emailRegex =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

// EmailValidator (https://github.com/manishsaraan/email-validator/blob/master/index.js)
export const validateEmail = (email: string | undefined) => {
  if (!email) return false

  var emailParts = email.split('@')

  if (emailParts.length !== 2) return false

  var account = emailParts[0]
  var address = emailParts[1]

  if (account.length > 64) return false
  else if (address.length > 255) return false

  var domainParts = address.split('.')

  if (
    domainParts.some(function (part) {
      return part.length > 63
    })
  )
    return false

  return emailRegex.test(email)
}

export const isProdottoBatteria = (prodotto: ProdottoNode) =>
  !!prodotto?.categoria?.alias?.toLowerCase()?.includes('batteria')

export const prezziProdotto = (prodotto: any) =>
  prodotto
    ? {
        prezzoFinale: prodotto.prezzoScontato || prodotto.prezzo,
        prezzoPrima: prodotto.promo ? prodotto.prezzoOmnibus : null,
        prezzoListino: prodotto.prezzoUnitario,
      }
    : undefined

export const normalizeGraphQLData = (data) => {
  if (!data) return

  if (!data.edges) {
    throw new Error('Invalid relay structure')
  }

  return data.edges.map((edge) => edge.node)
}

export function compareAttributes(prodotti: ProdottoNode[]) {
  if (!prodotti) return []

  const differences = []

  // Gather all unique attribute keys
  const allKeys = new Set(
    prodotti
      .map((x) => x.attributi)
      .map((x) => x.map((y) => y.chiave))
      .flat()
  )

  // Compare attributes for each key
  allKeys.forEach((key) => {
    const values = prodotti
      .map((x) => x.attributi)
      .map((attributo) => {
        const attr = attributo.find((x) => x.chiave === key)
        if (attr) {
          return attr.valori.map((valore) => valore.nome).join(', ')
        }
        return null // If attribute is not present in the product
      })

    // Check if values are different among products
    const uniqueValues = new Set(values)
    if (uniqueValues.size > 1) {
      differences.push(key)
    }
  })

  return differences
}

export const clickInRef = (event, ref: HTMLElement) => {
  var rect = ref.getBoundingClientRect()
  var res = true
  if (event.clientX === 0 && event.clientY === 0) {
    // navigazione da tastiera, considero il target
    // checkbox e radio sono in posizione assoluta esterni, prendo il parent
    const target =
      event.target.tagName.toUpperCase() === 'INPUT' &&
      ['radio', 'checkbox'].includes(event.target.type)
        ? event.target.parentElement
        : event.target
    if (target) {
      // se non capisco la casistica do per scontato che sia dentro, amen
      const targetRect = target.getBoundingClientRect()
      res =
        rect.top <= targetRect.bottom &&
        rect.bottom >= targetRect.top &&
        rect.left <= targetRect.right &&
        rect.right >= targetRect.left
    }
  } else {
    if (event.target.tagName.toUpperCase() === 'DIALOG') {
      // per le dialog il check contains risulta sempre true, quindi guardo la posizione del click
      res =
        rect.top <= event.clientY &&
        rect.bottom >= event.clientY &&
        rect.left <= event.clientX &&
        rect.right >= event.clientX
    } else res = ref.contains(event.target)
  }
  return res
}

export function hasHTML(stringa) {
  // Espressione regolare per rilevare tag HTML
  const regex = /<\/?[a-z][\s\S]*>/i
  return regex.test(stringa)
}

export function formatStringToHtml(input) {
  if (hasHTML(input)) return input

  // Suddividi la stringa in base ai punti e ai simboli "•"
  const parts = input.split(/(?<=\.)|(?=•)/g)

  // Crea i paragrafi HTML
  const paragraphs = parts.map((part) => {
    // Rimuovi eventuali spazi bianchi all'inizio e alla fine
    const trimmedPart = part.trim()
    // Se la parte non è vuota, avvolgila in un tag <p>
    return trimmedPart ? `<p>${trimmedPart}</p>` : ''
  })

  // Unisci i paragrafi in una singola stringa HTML
  return paragraphs.join('\n')
}

export function convertLocale(locale: string) {
  if (typeof locale !== 'string') {
    throw new Error('Input must be a string')
  }

  if (locale === 'default') return 'it_IT'
  if (locale === 'int') return 'en_US'

  const parts = locale.split('-')
  if (parts.length !== 2) {
    throw new Error('Locale format is incorrect')
  }

  const language = parts[0].toLowerCase()
  const region = parts[1].toUpperCase()

  return `${language}_${region}`
}
