import React from 'react'
import { ProdottoNode } from '@gql/graphql-get'
import useTrans from '@hooks/useTrans'
import { Close } from '@components/icons'
import { WImage } from '@components/atoms'
import styles from './CompareBarItem.module.sass'

interface Props {
  prodotto?: ProdottoNode
  onClose?: () => void
  className?: string
}

const CompareBarItem = (props: Props) => {
  const { prodotto = undefined, onClose = undefined, className = '' } = props

  const t = useTrans()

  return prodotto ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.imageBox}>
        <WImage src={prodotto.previewImage} maxWidth={40} maxHeight={40} objectFit="contain" />
      </div>
      <p className={styles.title}>{prodotto.nome}</p>
      <button
        type="button"
        aria-label={t('Rimuovi prodotto')}
        className={styles.close}
        onClick={onClose}
      >
        <Close />
      </button>
    </div>
  ) : (
    <div className={`${styles.root} ${styles['root--empty']} ${className}`}></div>
  )
}

export default CompareBarItem
