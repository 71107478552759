import PagebuilderContextProvider from '../services/pagebuilderContext'
import CustomBlocksContextProvider from '../services/customBlocksContext'
import GlobalContextProvider from '../services/globalContext'

import { Placeholder } from './Placeholder'

export const PageBuilder = ({
  contentTypeId,
  objectId,
  pkey,
  editMode = false,
  draft = false,
  serverSideContent,
}) => {
  return (
    <GlobalContextProvider
      contentTypeId={contentTypeId}
      objectId={objectId}
      pkey={pkey}
      editMode={editMode}
      draft={draft}
    >
      <PagebuilderContextProvider serverSideContent={serverSideContent}>
        <CustomBlocksContextProvider>
          <Placeholder />
        </CustomBlocksContextProvider>
      </PagebuilderContextProvider>
    </GlobalContextProvider>
  )
}
