import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function FacebookIllustration({ idVariant = '', className }: Props) {
  return (
    <IconBase name="facebook-illustration" w={128} className={className}>
      <g>
        <g>
          <rect fill="none" height="128" width="128" />
          <path
            clipRule="evenodd"
            d="M68.369,128H7.065C3.162,128,0,124.836,0,120.935    V7.065C0,3.162,3.162,0,7.065,0h113.871C124.837,0,128,3.162,128,7.065v113.87c0,3.902-3.163,7.065-7.064,7.065H88.318V78.431    h16.638l2.491-19.318H88.318V46.78c0-5.593,1.553-9.404,9.573-9.404l10.229-0.004V20.094c-1.769-0.235-7.841-0.761-14.906-0.761    c-14.749,0-24.846,9.003-24.846,25.535v14.246H51.688v19.318h16.681V128z"
            fill="#4460A0"
            fillRule="evenodd"
          />
        </g>
      </g>
    </IconBase>
  )
}

export default FacebookIllustration
