import React, { useState, useEffect } from 'react'

import { CKEditor } from 'ckeditor4-react'
import { AddGblinkPlugin } from './ckeditor/gblink/plugin'
import { AddGbFileLinkPlugin } from './ckeditor/gbfilelink/plugin'

export const RichTextEditor = ({ name, value, setValue, noHtml, readOnly = false }) => {
  //const editorName = noHtml ? 'ckeditor-no-toolbar' : 'ckeditor' + '_' + name
  const editorName = 'ckeditor' + '_' + name

  const toggleToolbarVisibility = (value) => {
    const toolbarElement = document.getElementsByClassName('cke_editor_ckeditor_' + name)[0]
    if (toolbarElement) {
      if (value) {
        toolbarElement.classList.remove('ckeditor-hide')
        toolbarElement.classList.add('ckeditor-show')
      } else {
        toolbarElement.classList.add('ckeditor-hide')
        toolbarElement.classList.remove('ckeditor-show')
      }
    }
  }

  useEffect(() => {
    toggleToolbarVisibility(!readOnly)
  }, [readOnly])

  return (
    <CKEditor
      name={editorName}
      initData={value}
      type={'inline'}
      onChange={({ editor }) => {
        setValue(editor.getData())
      }}
      readOnly={readOnly}
      config={{
        extraPlugins: [
          'justify',
          'link',
          'tab',
          'copyformatting',
          'font',
          'sourcedialog',
          'image',
          'gblink',
          'gbfilelink',
        ],
        removePlugins: ['magicline', 'sourcearea'],
        toolbar: noHtml ? NO_HTML_TOOLBAR : TOOLBAR,
        removeButtons: '',
        removeDialogTabs: '',
        versionCheck: false,
        tabSpaces: 4,
        stylesSet: PAGEBUILDER_TEXT_STYLES,
        extraAllowedContent: 'a[data-gb-href,data-gb-fileid]',
      }}
      onInstanceReady={({ editor }) => {
        toggleToolbarVisibility(!readOnly)
      }}
      onBeforeLoad={(CKEDITOR) => {
        AddGblinkPlugin(CKEDITOR)
        AddGbFileLinkPlugin(CKEDITOR)
      }}
    />
  )
}

const NO_HTML_TOOLBAR = [{ name: 'clipboard', items: ['Undo', 'Redo'] }]

const TOOLBAR = [
  { name: 'clipboard', items: ['Undo', 'Redo'] },
  { name: 'source', items: ['Source'] },
  {
    name: 'paragraph',
    items: [
      'BulletedList',
      'NumberedList',
      '-',
      'JustifyLeft',
      'JustifyCenter',
      'JustifyRight',
      'JustifyBlock',
    ],
  },
  {
    name: 'basicstyles',
    items: [
      'Bold',
      'Italic',
      'Underline',
      'Strike',
      'Subscript',
      'Superscript',
      '-',
      'CopyFormatting',
      'RemoveFormat',
    ],
  },
  { name: 'links', items: ['Gblink', 'Gbfilelink', 'Link', 'Unlink' /* 'Anchor' */] },
  { name: 'styles', items: ['Styles'] },
  { name: 'other', items: ['Table', 'SpecialChar', 'FilerImage', 'Image'] },
]

const PAGEBUILDER_TEXT_STYLES = [
  { name: 'Normale', element: 'p', attributes: { class: 'pnormal' } },
  { name: 'Large', element: 'p', attributes: { class: 'plarge' } },
  { name: 'Font secondary large', element: 'p', attributes: { class: 'psecondarylarge' } },
  { name: 'Caption', element: 'p', attributes: { class: 'pcaption' } },
  { name: 'Titolo 1', element: 'h2', attributes: { class: 'heading2' } },
  { name: 'Titolo 2', element: 'h3', attributes: { class: 'heading3' } },
  // { name: 'Titolo 3', element: 'h4', attributes: { class: 'heading4' } }, // non previsto in progettazione
  { name: 'Elenco bullet', element: 'ul', attributes: { class: 'bullets' } },
  { name: 'Elenco numeri', element: 'ol', attributes: { class: 'ol-numbers' } },
  { name: 'Elenco lettere', element: 'ol', attributes: { class: 'ol-letters' } },
  { name: 'Elenco numeri romani', element: 'ol', attributes: { class: 'ol-lower-roman' } },
]
