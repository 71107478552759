import React, { useId } from 'react'
import Select from 'react-select'
import { ChevronDown } from '@components/icons'
import { useRouter } from 'next/router'
import styles from './LangDropdown.module.sass'

interface Props {
  className?: string
  direction?: 'bottom' | 'top'
}

const LangDropdownIndicator = () => (
  <div className="react-select__dropdown-indicator">
    <ChevronDown />
  </div>
)

const LangDropdown = (props: Props) => {
  const { className = '', direction = 'bottom' } = props

  const { locale, push, domainLocales } = useRouter()
  const langsOpts = Object.entries(process.env.LANGUAGES).map(([lang, code]) => ({
    value: lang,
    label: code,
    selected: lang === locale,
  }))

  const onChange = (e) => {
    if (e?.value) {
      if (domainLocales) {
        const canale =
          domainLocales.find((x) => x.defaultLocale === e.value) ||
          domainLocales.find((x) => x.defaultLocale === 'default')

        if (canale) {
          window.location.href = `https://${canale.domain}/${canale.defaultLocale}/`
        } else {
          console.log(`Canale non trovato per "${e.value}"`)
        }
      } else {
        push('/', '/', {
          locale: e.value, // sono in ambiente locale
        })
      }
    }
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Select
        value={langsOpts.find((x) => x.selected)}
        options={langsOpts}
        onChange={onChange}
        components={{ DropdownIndicator: LangDropdownIndicator }}
        className={`react-select ${styles.select} ${styles[`select--${direction}`]}`}
        classNamePrefix="react-select"
        isSearchable={false}
        isClearable={false}
        instanceId={useId()}
      />
    </div>
  )
}

export default LangDropdown
