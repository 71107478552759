import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Instagram({ idVariant = '', className }: Props) {
  return (
    <IconBase name="instagram" w={24} className={className}>
      <g clipPath={`url(#instagram_${idVariant}_clip0_138_3447)`}>
        <mask
          id={`instagram_${idVariant}_mask0_138_3447`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <path d="M0.000305176 0H24V24H0.000305176V0Z" fill="currentColor" />
        </mask>
        <g mask={`url(#instagram_${idVariant}_mask0_138_3447)`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.4063 4.155C17.6103 4.155 16.9653 4.8 16.9653 5.595C16.9653 6.39 17.6103 7.035 18.4063 7.035C19.2013 7.035 19.8443 6.39 19.8443 5.595C19.8443 4.8 19.2013 4.155 18.4063 4.155ZM12.0003 16C9.79131 16 8.00031 14.21 8.00031 12C8.00031 9.791 9.79131 8 12.0003 8C14.2093 8 16.0003 9.791 16.0003 12C16.0003 14.21 14.2093 16 12.0003 16ZM12.0003 5.838C8.59731 5.838 5.83831 8.597 5.83831 12C5.83831 15.403 8.59731 18.163 12.0003 18.163C15.4033 18.163 18.1623 15.405 18.1623 12C18.1623 8.597 15.4033 5.838 12.0003 5.838ZM12.0003 0C8.74131 0 8.33331 0.014 7.05331 0.072C2.69531 0.272 0.273305 2.69 0.0733052 7.052C0.0143052 8.333 0.000305176 8.741 0.000305176 12C0.000305176 15.259 0.0143052 15.668 0.0723052 16.948C0.272305 21.306 2.69031 23.728 7.05231 23.928C8.33331 23.986 8.74131 24 12.0003 24C15.2593 24 15.6683 23.986 16.9483 23.928C21.3023 23.728 23.7303 21.31 23.9273 16.948C23.9863 15.668 24.0003 15.259 24.0003 12C24.0003 8.741 23.9863 8.333 23.9273 7.053C23.7323 2.699 21.3103 0.273 16.9493 0.073C15.6683 0.014 15.2593 0 12.0003 0ZM12.0003 2.163C15.2043 2.163 15.5843 2.175 16.8493 2.233C20.1013 2.381 21.6213 3.924 21.7683 7.152C21.8273 8.417 21.8383 8.797 21.8383 12.001C21.8383 15.206 21.8263 15.585 21.7683 16.85C21.6203 20.075 20.1053 21.621 16.8493 21.769C15.5843 21.827 15.2063 21.839 12.0003 21.839C8.7963 21.839 8.41631 21.827 7.15131 21.769C3.89131 21.62 2.38031 20.07 2.23231 16.849C2.17431 15.584 2.16231 15.205 2.16231 12C2.16231 8.796 2.17531 8.417 2.23231 7.151C2.38131 3.924 3.89631 2.38 7.15131 2.232C8.4173 2.175 8.7963 2.163 12.0003 2.163Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id={`instagram_${idVariant}_clip0_138_3447`}>
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default Instagram
