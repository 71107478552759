import React from 'react'

export const NumbersCardSettings = (settingsProps) => {
  return (
    <>
      <div className="empty-message">
        <p>No options available</p>
      </div>
    </>
  )
}

export const NumbersCardFloatingSettings = () =>
  React.createElement(NumbersCardSettings, { floating: true })
