import { useState, useEffect } from 'react'
import { FormSelect } from '../../../../../pb-components/atoms'
import * as icons from 'components/icons'

export const IconToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  onlyDynamic,
  defaultValue,
  nullable,
}) => {
  const [state, setState] = useState()

  useEffect(() => {
    setState(value)
  }, [value])

  const iconOpts = [
    ...[{ label: 'Nessuna', value: '' }],
    ...(icons
      ? Object.keys(icons).map((x) => ({
          label: x.replace(/[A-Z]/g, (m) => ' ' + m),
          value: x.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase()),
        }))
      : []),
  ]

  return (
    <>
      {state !== undefined && (
        <>
          {!onlyDynamic && state !== undefined && (
            <FormSelect
              value={state}
              options={(nullable
                ? [{ label: '-', value: 'no_value', groupLabel: '-' }]
                : []
              ).concat(iconOpts)}
              onChange={(e) =>
                setProp((props) => {
                  let value = e.target.value
                  if (value === 'no_value') {
                    value = defaultValue
                  }
                  props[propKey][propSubKey] = onChange ? onChange(value) : value
                }, 1000)
              }
            />
          )}
        </>
      )}
    </>
  )
}
