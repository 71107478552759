import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps } from './index'
import LayoutToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const WContainerSettings = () => {
  const variantOpts = [
    { label: 'Default', value: 'md' },
    { label: 'Small', value: 'sm' },
    { label: 'Extra-small', value: 'xs' },
    { label: 'Blog content', value: 'blog-content' },
    { label: 'Fullscreen', value: 'fluid' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="variant"
          type="select"
          options={variantOpts}
          label="Size"
          defaultValue={defaultProps['variant']}
        />
      </ToolbarSection>
      <LayoutToolbarSection defaultProps={defaultProps} initOpen />
    </>
  )
}
