import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './TabellaDifferenzeCardItem.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const TabellaDifferenzeCardItem = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      <span className={styles.icon}>{item.iconLeft}</span>
      <div className={styles.main}>
        <EditableTitle
          title={item.name}
          variant="h4"
          className={styles.title}
          editMode={editMode}
        />
        <EditableDescription
          description={item.description}
          className={styles.description}
          editMode={editMode}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TabellaDifferenzeCardItem
