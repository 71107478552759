import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function CheckboxMarked({ idVariant = '', className }: Props) {
  return (
    <IconBase name="checkbox-marked" w={24} className={className}>
      <rect x="4" y="4" width="16" height="16" fill="currentColor" />
      <path
        d="M10.6665 14.1139L16.7947 7.98566L17.7375 8.92846L10.6665 15.9995L6.42383 11.7569L7.36664 10.8141L10.6665 14.1139Z"
        fill="white"
      />
    </IconBase>
  )
}

export default CheckboxMarked
