import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import { MenuItemType } from '@utils/types'
import { WContainer, EditableTitle, EditableDescription } from '@components/atoms'
import { GallerySlide } from '@components/molecules'
import styles from './Gallery.module.sass'

interface Props {
  item: MenuItemType
  theme?: 'light' | 'neutrallight' | 'secondarydark'
  editMode?: boolean
  className?: string
}

export const GallerySwiperParams: SwiperOptions & { className?: string } = {
  slidesPerView: 1.1,
  spaceBetween: 24,
  modules: [A11y, Navigation, Pagination],
  navigation: true,
  pagination: { type: 'progressbar', clickable: true },
  breakpoints: {
    768: {
      slidesPerView: 1,
    },
  },
}

const Gallery = (props: Props) => {
  const { item = undefined, theme = 'light', editMode = false, className = '' } = props

  return item ? (
    // L'id serve per le ancore interne nelle landing
    <div
      id="gallery"
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        editMode ? styles['root--editMode'] : ''
      } ${className}`}
    >
      <WContainer variant="xs">
        <div className={`${styles.layout}`}>
          {(!!item.name || !!item.description) && (
            <div className={styles.main}>
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
            </div>
          )}
          {editMode ? (
            <>{item.children}</>
          ) : (
            !!Array.isArray(item.children) &&
            item.children?.length && (
              <div className={`${styles.slider}`}>
                <Swiper {...GallerySwiperParams} className={`swiper swiper--bottomNav`}>
                  {item.children.map((x) => (
                    <SwiperSlide key={x.pk}>
                      <GallerySlide item={x} className={styles.item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default Gallery
