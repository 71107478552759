import React from 'react'
import { MenuItemType } from '@utils/types'
import styles from './SizeTable.module.sass'

interface Props {
  item?: MenuItemType
  headers?: {
    label?: string
    colSpan?: number
  }[]
  rows?: {
    type?: 'header' | ''
    content: React.ReactNode
    rowSpan?: number
    className?: string
  }[][]
  className?: string
}

const SizeTable = (props: Props) => {
  const { item = undefined, headers = [], rows = [], className = '' } = props

  return (
    <div className={`${styles.root} ${className}`}>
      {(!!item?.name || !!item?.description) && (
        <div className={styles.main}>
          {!!item.name && <h2 className={styles.title}>{item.name}</h2>}
          {!!item.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>
      )}
      <div className={styles.tableWrap}>
        <table className={styles.table}>
          {!!headers?.length && (
            <thead>
              <tr>
                {headers.map((h) => (
                  <th colSpan={h.colSpan}>{h.label}</th>
                ))}
              </tr>
            </thead>
          )}
          {rows?.length && (
            <tbody>
              {rows.map((r, i) => (
                <tr key={i}>
                  {r.map((c, j) =>
                    c.type === 'header' ? (
                      <th key={j} rowSpan={c.rowSpan} className={c.className}>
                        {c.content}
                      </th>
                    ) : (
                      <td key={j} rowSpan={c.rowSpan} className={c.className}>
                        {c.content}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}

export default SizeTable
