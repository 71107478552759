import React, { Fragment, useContext, useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { prezziProdotto, priceNotation } from '@utils/safe'
import { PDPProdottoBannerCard } from '@components/molecules'
import styles from './PDPBanner.module.sass'
import { Plus } from '@components/icons'
import { Button, Modal } from '@components/atoms'
import { PromozioneNode } from '@gql/graphql'
import { CartContext } from '@utils/cartContext'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'
import {
  handleAddToCartTracking,
  handleBuyInPairsTracking,
  handleProductImpressionTracking,
} from '@utils/tracking'

interface Props {
  promozione?: PromozioneNode
  className?: string
}

const PDPBanner = (props: Props) => {
  const { promozione = undefined, className = '' } = props

  const t = useTrans()
  const [modalOpen, setModalOpen] = useState(false)
  const [hasTracked, setHasTracked] = useState(false)
  const prezzoProdottiBundle =
    promozione?.prodottiBundle?.reduce((sum, p) => (sum += p.prezzo), 0) || 0

  const {
    cart,
    addToCart: contextAddToCart,
    loading: cartLoading,
    refetch,
  } = useContext(CartContext)

  useEffect(() => {
    if (promozione && cart && !hasTracked) {
      handleProductImpressionTracking(promozione.prodottiBundle, cart)
      setHasTracked(true)
    }
  }, [cart])

  const addToCart = async () => {
    await Promise.all(
      promozione.prodottiBundle.map(async (prodotto) => {
        await contextAddToCart(prodotto.pk, 1, false)
        handleAddToCartTracking(prodotto, cart)
        handleBuyInPairsTracking(prodotto.codice)
      })
    )

    await refetch()
    setModalOpen(false)
  }

  return (promozione?.prodottiBundle?.length || 0) >= 2 ? (
    <>
      <div className={`${styles.root} ${className}`}>
        <div className={styles.main}>
          <h2 className={styles.title}>{t('Vuoi ottenere lo sconto?')}</h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: promozione.descrizioneProdotto }}
          />
        </div>
        <div className={`${styles.slider}`}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={20}
            centerInsufficientSlides
            watchOverflow
            modules={[A11y, Pagination]}
            pagination={{ clickable: true, type: 'progressbar' }}
          >
            {promozione.prodottiBundle.map((x) => (
              <SwiperSlide key={x.pk}>
                <PDPProdottoBannerCard prodotto={x} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.bottom}>
          <div className={styles.prices}>
            <span className={`${styles.price} ${styles['price--current']}`}>
              {priceNotation(prezzoProdottiBundle - (promozione.valore || 0))}
            </span>{' '}
            <span className={styles.priceLabel}>{t('invece di')}</span>{' '}
            <span className={`${styles.price} ${styles['price--full']}`}>
              {priceNotation(prezzoProdottiBundle)}
            </span>
          </div>
          <Button
            label={t('Acquistali in coppia')}
            onClick={() => setModalOpen(true)}
            className={styles.cta}
            theme="dark"
            color="white"
            variant="secondary"
            loading={cartLoading}
          />
        </div>
      </div>
      <Modal
        id={`pdpbanner_modal_${promozione.pk}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={t('Ti interessa uno sconto?')}
        cta={<Button label={t('Acquistali insieme')} onClick={addToCart} loading={cartLoading} />}
        className={styles.modal}
      >
        {!!promozione.descrizioneProdotto && (
          <div
            className={styles.modalDescription}
            dangerouslySetInnerHTML={{ __html: promozione.descrizioneProdotto }}
          />
        )}
        <div className={styles.modalContent}>
          <div className={styles.modalList}>
            {promozione.prodottiBundle.map((x) => (
              <PDPProdottoBannerCard prodotto={x} variant="modal" key={x.pk} />
            ))}
          </div>
          <div className={styles.prices}>
            <span className={`${styles.price} ${styles['price--current']}`}>
              {priceNotation(prezzoProdottiBundle - (promozione.valore || 0))}
            </span>{' '}
            <span className={styles.priceLabel}>{t('invece di')}</span>{' '}
            <span className={`${styles.price} ${styles['price--full']}`}>
              {priceNotation(prezzoProdottiBundle)}
            </span>
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <></>
  )
}

export default PDPBanner
