import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { BannerBlock as Original } from 'components/organisms'
import { BannerBlockSettings } from './BannerBlockSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { getTextEditor } from '../TextEditor'
import {
  layoutDefaultProps,
  layoutItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const defaultProps = {
  ...layoutDefaultProps('lg', false),
}

export const bannerBlockItemParams = (enabled, id, blockProps, children) => ({
  ...layoutItemParams(blockProps),
  pk: id,
  name: getTextEditor(children?.[0], enabled),
  description: getTextEditor(children?.[1], enabled),
})

export const dynamicProps = []

export const BannerBlock: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-banner-block ${props.className}`}>
        <Original
          item={bannerBlockItemParams(enabled, id, blockProps, children)}
          editMode={enabled}
        >
          {children?.[2]}
        </Original>
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

BannerBlock.craft = withCraft({
  name: 'BannerBlock',
  defaultProps: defaultProps,
  settings: BannerBlockSettings,
})
