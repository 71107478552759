import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Vibrazioni({ idVariant = '', className }: Props) {
  return (
    <IconBase name="vibrazioni" w={50} className={className}>
      <path
        d="M5.77375 8.49012C5.46801 8.18438 4.97413 8.18438 4.66839 8.49012C4.36265 8.79586 4.36265 9.28975 4.66839 9.60724L8.64299 13.5818L5.02117 17.2037C4.71543 17.5094 4.71543 18.0033 5.02117 18.3208L8.25494 21.5546L4.98589 24.8236C4.68015 25.1294 4.68015 25.6232 4.98589 25.9407L8.25494 29.2098L4.97413 32.4906C4.66839 32.7963 4.66839 33.2902 4.97413 33.6077L8.24318 36.8768L4.66839 40.4516C4.36265 40.7573 4.36265 41.2512 4.66839 41.5687C4.82126 41.7215 5.02117 41.7921 5.22107 41.7921C5.42098 41.7921 5.62088 41.7215 5.77375 41.5687L9.90122 37.4412C10.0541 37.2883 10.1364 37.1002 10.1364 36.8885C10.1364 36.6769 10.0541 36.4887 9.90122 36.3358L6.63217 33.0668L9.91298 29.786C10.2187 29.4802 10.2187 28.9864 9.91298 28.6689L6.64393 25.3998L9.91298 22.1308C10.0659 21.9779 10.1482 21.7897 10.1482 21.5781C10.1482 21.3664 10.0659 21.1665 9.91298 21.0254L6.67921 17.7916L10.301 14.1698C10.6068 13.8641 10.6068 13.3702 10.301 13.0644L5.77375 8.53716V8.49012Z"
        fill="currentColor"
      />
      <path
        d="M41.7451 36.8297L45.0142 33.5607C45.3199 33.2549 45.3199 32.7611 45.0142 32.4553L41.7333 29.1745L45.0024 25.9055C45.3081 25.5997 45.3081 25.1058 45.0024 24.8001L41.7333 21.531L44.9671 18.2973C45.2729 17.9915 45.2729 17.4976 44.9671 17.1802L41.3453 13.5583L45.3199 9.58373C45.6256 9.27799 45.6256 8.7841 45.3199 8.4666C45.0142 8.16087 44.5203 8.16087 44.2028 8.4666L39.6755 12.9939C39.5226 13.1468 39.4403 13.3349 39.4403 13.5466C39.4403 13.7582 39.5226 13.9581 39.6755 14.0992L43.2973 17.7211L40.0635 20.9548C39.9107 21.1077 39.8284 21.2959 39.8284 21.5075C39.8284 21.7192 39.9107 21.9191 40.0635 22.0602L43.3326 25.3293L40.0635 28.5983C39.7578 28.904 39.7578 29.3979 40.0635 29.7154L43.3444 32.9962L40.0753 36.2653C39.9224 36.4182 39.8401 36.6063 39.8401 36.818C39.8401 37.0296 39.9224 37.2295 40.0753 37.3706L44.2028 41.4981C44.3556 41.651 44.5555 41.7215 44.7555 41.7215C44.9554 41.7215 45.1553 41.651 45.3081 41.4981C45.6139 41.1924 45.6139 40.6985 45.3081 40.381L41.7333 36.8062L41.7451 36.8297Z"
        fill="currentColor"
      />
      <path
        d="M25 15.1693C19.5908 15.1693 15.1929 19.5673 15.1929 24.9765C15.1929 30.3857 19.5908 34.7836 25 34.7836C30.4092 34.7836 34.8072 30.3857 34.8072 24.9765C34.8072 19.5673 30.4092 15.1693 25 15.1693ZM32.5729 24.9765C32.5729 29.151 29.1745 32.5494 25 32.5494C20.8255 32.5494 17.4271 29.151 17.4271 24.9765C17.4271 20.802 20.8255 17.4036 25 17.4036C29.1745 17.4036 32.5729 20.802 32.5729 24.9765Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Vibrazioni
