import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button } from '@components/atoms'
import styles from './RicercaBanner.module.sass'
import { getIcon } from '@utils/icons'

interface Props {
  item: MenuItemType
  className?: string
}

const RicercaBanner = (props: Props) => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${className}`}>
      <span className={styles.icon}>{getIcon(item.icon)}</span>
      <div className={styles.main}>
        {!!item.name && <h3 className={styles.title}>{item.name}</h3>}
        {!!item.url && !!item.ctaLabel && (
          <div className={styles.ctaBox}>
            <Button
              label={item.ctaLabel}
              href={item.url}
              newPage={item.blank}
              variant="secondary"
              size="sm"
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RicercaBanner
