import React from 'react'
import styles from './TabBar.module.sass'
import Tab from '../Tab/Tab'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
  items?: {
    pk?: string | number
    label?: string
  }[]
  selected?: any
  setSelected?: (v: any) => void
  className?: string
}

const TabBar = (props: Props) => {
  const { items = [], selected = undefined, setSelected = undefined, className = '' } = props

  return items?.length > 0 ? (
    <div className={`${styles.root} ${className}`}>
      <Swiper slidesPerView={'auto'} centerInsufficientSlides>
        {items.map((x) => (
          <SwiperSlide key={x.pk}>
            <Tab
              key={x.pk}
              label={x.label}
              selected={x.pk === selected?.pk}
              onClick={() => setSelected(x)}
              className={styles.item}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}

export default TabBar
