import React from 'react'

export const TabellaDifferenzeSettings = (settingsProps) => {
  return (
    <div className="empty-message">
      <p>No options available</p>
    </div>
  )
}

export const TabellaDifferenzeFloatingSettings = () =>
  React.createElement(TabellaDifferenzeSettings, { floating: true })
