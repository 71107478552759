import React from 'react'
import { BreadcrumbType } from '@gql/graphql'
import { Breadcrumbs, WContainer, WImage } from '@components/atoms'
import styles from './ListingHead.module.sass'

interface Props {
  pagina?: {
    titolo?: string
    descrizione?: string
    mainImage?: string
    breadcrumbs?: BreadcrumbType[]
  }
  className?: string
}

const ListingHead = (props: Props) => {
  const { pagina = undefined, className = '' } = props

  const getMain = () => (
    <div className={styles.main}>
      <Breadcrumbs breadcrumbs={pagina.breadcrumbs} className={styles.breadcrumbs} />
      <div className={styles.content}>
        {!!pagina.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
        {!!pagina.descrizione && (
          <div
            dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
            className={styles.description}
          />
        )}
      </div>
    </div>
  )

  return pagina ? (
    <div className={`${styles.root} ${pagina.mainImage ? styles['root--image'] : ''} ${className}`}>
      {pagina.mainImage ? (
        <>
          {getMain()}
          <div className={styles.imageBox}>
            <WImage
              src={pagina.mainImage}
              maxWidth={767}
              maxHeight={400}
              objectFit="cover"
              priority
              loading="eager"
            />
          </div>
        </>
      ) : (
        <WContainer>{getMain()}</WContainer>
      )}
    </div>
  ) : (
    <></>
  )
}

export default ListingHead
