import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import useTrans from '@hooks/useTrans'
import { scrollTo } from '@utils/safe'
import { cittaComuniOptions } from '@utils/checkout'
import { richiestaManualiValidationSchema } from '@utils/validationSchemas'
import { Button, FormError, FormikField, FormLayout, Modal, WContainer } from '@components/atoms'
import styles from './RichiestaManualiForm.module.sass'
import { Check } from '@components/icons'
import { useCreateContattoManualeMutation, useNazioniManualiQuery } from '@gql/graphql'
import { useRouter } from 'next/router'

interface Props {
  className?: string
}

const RichiestaManualiForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const formRef = useRef(null)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const { data: nazioni } = useNazioniManualiQuery()
  const cittaOptions = cittaComuniOptions()
  const statoItaliaPk = 1
  const statoOptions = nazioni?.nazioniManuali?.map((x) => ({
    label: x.nome,
    value: x.pk.toString(),
  }))

  const router = useRouter()

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    scrollTo(formRef?.current, 20)
  }

  const [createContattoManuale] = useCreateContattoManualeMutation({
    onCompleted: (res) => {
      if (!res.createContattoManuale?.status) {
        raiseGlobalError(res?.createContattoManuale?.message || t('Errore durante il salvataggio'))
      } else {
        setConfirmModalOpen(true)
      }
    },
    onError: () => {
      // Errore non previsto - generico
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  const initialValues = {
    linee: '',
    classi: '',
    prodotto: '',
    lingua: '',
    nome: '',
    cognome: '',
    nazione: '',
    comune: '',
    provincia: '',
    via: '',
    cap: '',
    telefono: '',
    email: '',
    privacy: false,
    marketing: false,
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validationSchema={richiestaManualiValidationSchema(t)}
            onSubmit={(values) => {
              createContattoManuale({
                variables: {
                  contattoInput: {
                    ...values,
                    comune: values?.comune?.split('|')[0],
                    provincia: values?.comune?.split('|')[1],
                    nazione: parseInt(values.nazione),
                  },
                },
              })
            }}
          >
            {(formikProps) => (
              <Form className={styles.form} ref={formRef}>
                <FormError
                  id="richiestamanuali_global_error"
                  message={globalError}
                  visible={!!globalError}
                  variant="global"
                  className={styles.globalError}
                />
                <FormLayout space="lg">
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_linee'}
                    name="linee"
                    type="text"
                    label={t('Linee di prodotto')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                    }}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_classi'}
                    name="classi"
                    type="text"
                    label={t('Classi')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                    }}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_prodotto'}
                    name="prodotto"
                    type="text"
                    label={t('Prodotto')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                    }}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_lingua'}
                    name="lingua"
                    type="text"
                    label={t('Lingua')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                    }}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_nome'}
                    name="nome"
                    type="text"
                    label={t('Nome')}
                    // placeholder={t('Inserisci il tuo nome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_cognome'}
                    name="cognome"
                    type="text"
                    label={t('Cognome')}
                    // placeholder={t('Inserisci il tuo cognome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_nazione'}
                    name="nazione"
                    type="select"
                    options={statoOptions}
                    label={t('Nazione')}
                    isSearchable
                    // placeholder={t('Inserisci la nazione')}
                    async
                    required
                  />
                  {formikProps.values.nazione === statoItaliaPk.toString() ? (
                    <>
                      <FormikField
                        formik={formikProps}
                        id={'richiestamanuali_comune'}
                        name="comune"
                        type="select"
                        options={cittaOptions}
                        isSearchable={true}
                        label={t('Città')}
                        // placeholder={t('Inserisci la città')}
                        required
                        onChange={(e) => {
                          formikProps?.handleChange(e)
                          formikProps.setFieldValue(
                            'provincia',
                            e.target.value?.split('|')?.length || 0 > 1
                              ? e.target.value?.split('|')[1]
                              : undefined
                          )
                        }}
                        async
                      />
                      <FormikField
                        formik={formikProps}
                        id={'richiestamanuali_provincia'}
                        name="provincia"
                        type="text"
                        label={t('Provincia')}
                        // placeholder={t('Inserisci la provincia')}
                        disabled
                        required
                      />
                    </>
                  ) : (
                    <FormikField
                      formik={formikProps}
                      id={'richiestamanuali_comune'}
                      name="comune"
                      type="text"
                      label={t('Città')}
                      // placeholder={t('Inserisci la città')}
                      required
                    />
                  )}
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_via'}
                    name="via"
                    type="text"
                    label={t('Via')}
                    // placeholder={t('Inserisci il tuo indirizzo')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_cap'}
                    name="cap"
                    type="text"
                    label={t('Cap')}
                    // placeholder={t('Inserisci il tuo codice postale')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_telefono'}
                    name="telefono"
                    type="text"
                    label={t('Telefono')}
                    // placeholder={t('Inserisci il tuo numero di telefono')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'richiestamanuali_email'}
                    name="email"
                    type="email"
                    label={t('Email')}
                    // placeholder={t('Inserisci la tua email')}
                    required
                  />
                </FormLayout>
                <div
                  className={styles.privacyMessage}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "Ti invitiamo a visionare <a href='/privacy/informativa-per-marketing-e-profilazione/'>l'informativa privacy art. 13 GDPR</a> sul trattamento dei dati"
                    ),
                  }}
                />
                <FormLayout>
                  <FormikField
                    formik={formikProps}
                    id={'checkout_privacy'}
                    name="privacy"
                    type="checkbox"
                    label={t('Letta e compresa l’informativa*')}
                    checkboxLabel={t(
                      'Esprimo il consenso al trattamento dei miei dati per ricevere supporto, risposte ai miei quesiti e per le eventuali attività connesse di cui al punto a) dell’informativa.'
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                  <FormikField
                    formik={formikProps}
                    id={'checkout_marketing'}
                    name="marketing"
                    type="checkbox"
                    label={t('Consenso per il ‘Marketing diretto’ (facoltativo)')}
                    checkboxLabel={t(
                      'Esprimo il consenso per le finalità di cui al punto b) dell’informativa e quindi acconsento al ricevimento di comunicazioni pubblicitarie tramite e-mail, telefono, sms e similari. Resta inteso che potrò revocare in qualunque momento il presente consenso.'
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                </FormLayout>
                <div className={styles.ctaBox}>
                  <Button
                    type="submit"
                    label={t('Invia richiesta')}
                    loading={globalLoading}
                    size="lg"
                  />
                </div>
                <div className={styles.notes}>
                  <p>{t('(ndr: La firma è sostituita dal click)')}</p>
                  <p>{t('Campi obbligatori (*)')}</p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </WContainer>
      <Modal
        id="richiestamanualiform_confirmmodal"
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        icon={<Check />}
        title={t('Richiesta inviata con successo')}
        description={t('La tua richiesta è stata inviata con successo')}
        cta={<Button label={t('Torna alla home')} href="/" size="lg" />}
      />
    </div>
  )
}

export default RichiestaManualiForm
