import React, { useState } from 'react'
import { useDeleteUtenteMutation, useProfiloUtenteQuery } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { Alert } from '@components/icons'
import { Button, Modal } from '@components/atoms'
import { ProfiloDatiCard, ProfiloProgressCard } from '@components/molecules'
import styles from './MyAccountProfiloHub.module.sass'
import { useRouter } from 'next/router'
import { handleDeleteAccount } from '@utils/tracking'
import useCookie from '@hooks/useCookie'

interface Props {
  className?: string
}

const MyAccountProfiloHub = (props: Props) => {
  const { className = '' } = props

  const [modalOpen, setModalOpen] = useState(false)

  const t = useTrans()
  const router = useRouter()
  const { data } = useProfiloUtenteQuery()
  const [deleteUtente, { loading: deleteLoading }] = useDeleteUtenteMutation()

  const [omnisendContactCookieContactID, setOmnisendContactCookieContactID, deleteCookieContactID] =
    useCookie('omnisendContactID', '')
  const [omnisendContactCookieSessionID, setOmnisendContactCookieSessionID, deleteCookieSessionID] =
    useCookie('omnisendSessionID', '')

  const datiUtente = data?.me
    ? [
        { label: t('Nome'), value: data.me.nome },
        { label: t('Cognome'), value: data.me.cognome },
        { label: t('Indirizzo email'), value: data.me.email },
        { label: t('Codice fiscale'), value: data.me.cf },
        { label: t('Tipo di utente'), value: data.me.privato ? t('Privato') : t('Azienda') },
      ]
        .concat(
          data.me.privato
            ? []
            : [
                { label: t('Ragione sociale'), value: data.me.ragioneSociale },
                { label: t('Partita IVA'), value: data.me.piva },
                { label: t('Codice SDI'), value: data.me.sdi },
                { label: t('Indirizzo PEC'), value: data.me.pec },
              ]
        )
        .filter((x) => !!x.value)
    : []

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.layout}>
        <ProfiloProgressCard
          progress={data?.me?.progressoProfilo}
          className={styles.progressCard}
        />

        <ProfiloDatiCard title={t('Dati Personali')}>
          <div className={styles.dati}>
            <div className={styles.datiInfo}>
              {datiUtente.map((x) => (
                <div className={styles.datiInfoItem}>
                  <p className={styles.datiInfoItemLabel}>{x.label}</p>
                  <p className={styles.datiInfoItemValue}>{x.value}</p>
                </div>
              ))}
            </div>
            <div className={styles.inlineCardContent}>
              <Button
                label={t('Modifica i tuoi dati')}
                variant="ghost"
                href="/myaccount/profile/edit"
                size="sm"
                className={styles.inlineCardCta}
              />
            </div>
          </div>
        </ProfiloDatiCard>

        <ProfiloDatiCard title={t('Password')}>
          <div className={styles.inlineCardContent}>
            <p className={styles.inlineCardDescription}>{t('Vuoi modificare la tua password?')}</p>
            <Button
              label={t('Cambia la password')}
              variant={'ghost'}
              href="/myaccount/profile/edit-password"
              size="sm"
              className={styles.inlineCardCta}
            />
          </div>
        </ProfiloDatiCard>

        <ProfiloDatiCard title={t('Eliminazione del profilo')}>
          <div className={styles.inlineCardContent}>
            <p className={styles.inlineCardDescription}>{t('Vuoi eliminare il tuo profilo?')}</p>
            <Button
              label={t('Elimina il tuo profilo')}
              variant={'ghost'}
              onClick={() => setModalOpen(true)}
              size="sm"
              className={styles.inlineCardCta}
            />
          </div>
        </ProfiloDatiCard>
      </div>

      <Modal
        id="delete_profile_modal"
        open={modalOpen}
        icon={<Alert />}
        onClose={() => setModalOpen(false)}
        title={t('Sei sicuro di voler eliminare il tuo profilo?')}
        description={t(
          'Eliminando il profilo perderai lo storico dei tuoi ordini. Ti ricordiamo che questa azione è irreversibile.'
        )}
        cta={
          <>
            <Button
              label={t('Sì, voglio eliminarlo')}
              variant="secondary"
              loading={deleteLoading}
              onClick={() =>
                deleteUtente().then(() => {
                  handleDeleteAccount()
                  deleteCookieContactID()
                  deleteCookieSessionID()
                  window.location.href = '/' // force SSR
                })
              }
            />
            <Button label={t('No, voglio mantenerlo')} onClick={() => setModalOpen(false)} />
          </>
        }
        className={styles.modal}
      />
    </div>
  )
}

export default MyAccountProfiloHub
