import React from 'react'
import styles from './Modal.module.sass'
import { WModal } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { Close } from '@components/icons'

interface Props {
  id: string
  icon?: React.ReactNode
  title?: string
  subtitle?: string
  description?: string
  cta?: React.ReactNode
  open?: boolean
  onClose?: () => void
  children?: React.ReactNode
  className?: string
  subtitleClassName?: string
}

const Modal = (props: Props) => {
  const {
    id = undefined,
    icon = undefined,
    title = undefined,
    subtitle = undefined,
    description = undefined,
    children = undefined,
    cta = undefined,
    open = false,
    onClose = undefined,
    className = '',
    subtitleClassName = '',
  } = props

  const t = useTrans()

  return (
    <WModal id={id} open={open} onClose={onClose} className={`${styles.root} ${className}`}>
      <button type="button" aria-label={t('Chiudi')} onClick={onClose} className={styles.close}>
        <Close />
      </button>
      <div className={styles.content}>
        {!!icon && <span className={styles.icon}>{icon}</span>}
        {!!title && <span className={styles.title}>{title}</span>}
        {!!subtitle && (
          <div
            className={`${styles.subtitle} ${subtitleClassName}`}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        {!!description && (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {children}
        {!!cta && <div className={styles.ctaBox}>{cta}</div>}
      </div>
    </WModal>
  )
}

export default Modal
