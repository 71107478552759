import React, { useEffect, useState } from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { ProdottoCardDocument, ProdottoNode, useProdottiLazyQuery } from '@gql/graphql'
import { initializeApollo } from '@gql/client'

export const prodottoRetrieve = async (id, locale, callback) => {
  if (!id) {
    callback(undefined)
    return
  }

  const client = initializeApollo(locale)

  const {
    data: { prodottoByPk },
  } = await client.query({
    query: ProdottoCardDocument,
    variables: { pk: parseInt(id) },
  })

  callback(prodottoByPk?.pk ? prodottoByPk : undefined)
}

export const RicercaProdottoCardSettings = (settingsProps) => {
  const [prodottiOpts, setProdottiOpts] = useState([])

  const [loadProdotti] = useProdottiLazyQuery()

  const fetchProdotti = async () => {
    const response = await loadProdotti()
    setProdottiOpts(
      response.data.prodotti.map((x: ProdottoNode) => ({
        label: `${x.alias} - ${x.codice}`,
        value: x.pk,
      }))
    )
  }

  useEffect(() => {
    fetchProdotti()
  }, [])

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="prodottoId"
          type="select"
          options={[{ label: '-', value: '' }].concat(prodottiOpts)}
          label="Prodotto"
          defaultValue={defaultProps['prodottoId']}
        />
      </ToolbarSection>
    </>
  )
}

export const RicercaProdottoCardFloatingSettings = () =>
  React.createElement(RicercaProdottoCardSettings, { floating: true })
