/* eslint-disable import/no-extraneous-dependencies */
import { ADMIN_PORT, BRAND, INTERNAL_DOMAINS, PROTOCOL_SCHEMA } from 'utils/settings'
import axios from 'axios'
import {
  handleClickCategoryTracking,
  handleClickCtaTracking,
  handleClickPromoTracking,
  handleFindStoreCtaTracking,
  handleGaranziaProdottoTracking,
} from './tracking'

export const API_HOST = process.env.API_HOST
export const API_PREFIX = '/d/api/internals/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`

export const PAGEBUILDER_ADMIN_ENABLE = process.env.PAGEBUILDER_ADMIN_ENABLE === 'true'
export const GRAPHENE_HOST = API_HOST
export const GRAPHENE_PREFIX = '/d/graphql/'
export const GRAPEHENE_GET_PREFIX = '/d/graphql-get/'
export const GRAPHENE_FULL_PATH = `${GRAPHENE_HOST}${GRAPHENE_PREFIX}`
export const GRAPHENE_GET_FULL_PATH = `${GRAPHENE_HOST}${GRAPEHENE_GET_PREFIX}`

export const api = axios.create({
  baseURL: API_FULL_PATH,
  withCredentials: true,
  withXSRFToken: true,
})

export function getApi(host: string) {
  return axios.create({
    baseURL: `${PROTOCOL_SCHEMA}://${host}${ADMIN_PORT ? `:${ADMIN_PORT}` : ''}${API_PREFIX}`,
    withCredentials: true,
    withXSRFToken: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  })
}

export const graphene = axios.create({
  baseURL: GRAPHENE_FULL_PATH,
  withCredentials: true,
  withXSRFToken: true,
})

export const grapheneGet = axios.create({
  baseURL: GRAPHENE_GET_FULL_PATH,
})

export function getGrapheneGet(host: string) {
  return axios.create({
    baseURL: `${PROTOCOL_SCHEMA}://${host}${
      ADMIN_PORT ? `:${ADMIN_PORT}` : ''
    }${GRAPEHENE_GET_PREFIX}`,
  })
}

export const IS_EXPORT = process.env.EXPORT === 'true'
export const IS_TEST_ENV = !IS_EXPORT && process.env.ENVIRONMENT === 'test'

export const NEWRELIC_ACCOUNT_ID: string = process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID
  ? process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID
  : ''
export const NEWRELIC_TRUST_KEY: string = process.env.NEXT_PUBLIC_NEWRELIC_TRUST_KEY
  ? process.env.NEXT_PUBLIC_NEWRELIC_TRUST_KEY
  : ''
export const NEWRELIC_AGENT_ID: string = process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID
  ? process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID
  : ''
export const NEWRELIC_LICENSE_KEY: string = process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY
  ? process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY
  : ''
export const NEWRELIC_APPLICATION_ID: string = process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID
  ? process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID
  : ''

export const OMNISEND_ACCOUNT_ID: string = process.env.OMNISEND_ACCOUNT_ID
  ? process.env.OMNISEND_ACCOUNT_ID
  : ''

export const getAssetsUrl = (url: string) =>
  process.env.STORYBOOK ? `http://localhost:3000${url}` : url

export const getUploadedAssetsUrl = (url: string) =>
  process.env.STORYBOOK
    ? `http://localhost:3000/${url}`
    : process.env.NODE_ENV === 'development'
    ? `http://localhost:9999/${url}`
    : `/${url}`

export const isEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object

export const toggle = (array: any, value: any) => {
  const index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

export function escape(s: String) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&#(\d+);/g, (match, dec) => String.fromCodePoint(dec))
}

export const toAltTitle = (term: string) =>
  term ? `${term} | ${BRAND}`.replace(/  +/g, ' ') : term === '' ? '' : BRAND // Se l'alt è volutamente lasciato vuoto, è un'immagine decorativa quindi va lasciato vuoto

export const getImageUrl = (url) =>
  process.env.STORYBOOK ? `http://localhost:3000/images/${url}` : `/images/${url}`

export const getFormFieldStatus = (formikProps: any, propName: string) =>
  formikProps.touched[propName] ? (formikProps.errors[propName] ? 'error' : 'success') : null

export const isExternalDomain = (url: string, currentDomain?: string): boolean => {
  try {
    const domain = new URL(url)
    const hostname = domain.hostname.replace('www.', '')
    // se è un altro locale (domain diverso) o non appartiene a INTERNAL_DOMAINS
    return !currentDomain.includes(hostname) || !INTERNAL_DOMAINS.includes(hostname)
  } catch (e) {
    return url?.startsWith('/d/')
  }
}

export const isFileDownloadUrl = (url: string | object | null): boolean => {
  if (!url || typeof url === 'object') return false

  try {
    const domain = new URL(url)
    const pathname = domain.pathname.split('?')?.[0]
    if (!pathname.includes('/')) return false
    return pathname?.includes('.')
  } catch (_) {
    const pathname = url.split('?')?.[0]
    return pathname.split('?')?.[0]?.includes('.')
  }
}

export function truncateString(str: string, num: number) {
  return typeof str === 'string' && str.length > num ? str.slice(0, num) + '...' : str
}

export function slugify(text: string) {
  if (!text) return ''
  return text
    .toString() // Cast to string
    .normalize('NFD') // Normalize to NFD Unicode form
    .replace(/[\u0300-\u036F]/g, '') // Remove diacritics
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading and trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
}

export const removeHTMLTags = (str) => {
  if (str === null || str === '') return ''
  else str = str?.toString()
  return str?.replace(/(<([^>]+)>)/gi, '')
}
interface JsonData {
  [key: string]: any
}

export function checkIfComponetIsInJSON(json: JsonData, displayNamesToFind: string[]): boolean {
  let isPresent: boolean = false

  function traverse(obj: JsonData): void {
    for (const key in obj) {
      if (key === 'displayName' && displayNamesToFind.includes(obj[key])) {
        isPresent = true
        return // Se trovi una corrispondenza, esci dalla funzione
      } else if (typeof obj[key] === 'object') {
        traverse(obj[key])
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item: JsonData) => {
          traverse(item)
        })
      }
    }
  }

  traverse(json)

  return isPresent
}

export const isToolbarVisible = (utente) => {
  if (!utente) return false

  if (!((utente.isStaff && utente.showToolbar) || utente.isImpersonate)) return false

  return true
}

export const isEcommerce = (locale: string) => {
  if (!locale) return false
  return process.env.ECOMMERCE.includes(locale)
}

export const newsletterEnabled = (locale: string) => {
  if (!locale) return false
  return process.env.NEWSLETTER.includes(locale)
}

export const isFiliale = (locale: string) => {
  if (!locale) return false
  return process.env.FILIALI.includes(locale)
}

export const isInternational = (locale: string) => {
  if (!locale) return false
  return locale === 'int'
}

export const checkValidCoords = (latitudine, longitudine) => {
  if (
    (!isNaN(latitudine) && !isNaN(longitudine)) ||
    (typeof latitudine !== 'number' && typeof longitudine !== 'number')
  ) {
    const lat = parseFloat(latitudine)
    const lng = parseFloat(longitudine)
    return lng >= -180 && lng < 180 && lat >= -90 && lat < 90
  }

  return false
}

export function isAnyCheckboxChecked(formValues: any) {
  return Object.keys(formValues).some((key) => {
    return key.startsWith('checkbox_') && formValues[key] === true
  })
}

export const checkUrlAndTrackPageBuilder = (x: any, path: string) => {
  if (!x || !x.url) return

  const segmenets = x?.url?.split('/')
  const label = segmenets[segmenets.length - 1].replaceAll('-', '_')
  handleClickCtaTracking(x?.ctaLabel || label, path)

  if (x.url.includes('/c/')) {
    handleClickCategoryTracking(x.ctaLabel, path)
    return
  }

  if (x.url.includes('/promozioni')) {
    const promoLabel = String(x.url.split('/promozioni/')[1]).replaceAll('-', '_')
    handleClickPromoTracking(promoLabel, path)
    return
  }

  if (x.url.includes('store-locator')) {
    handleFindStoreCtaTracking(path)
    return
  }

  if (x.url.includes('warranty')) {
    handleGaranziaProdottoTracking('', 'start', 'registra_prodotto')
  }
}
