import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { HomeHeroSlide as Original } from 'components/molecules'
import { HomeHeroSlideSettings, HomeHeroSlideFloatingSettings } from './HomeHeroSlideSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  ctaDefaultProps,
  ctaItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import {
  imageDefaultProps,
  imageItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'
import { getTextEditor } from '../TextEditor'

export const defaultProps = {
  ...ctaDefaultProps('Button'),
  ...imageDefaultProps(true, true),
}

export const homeHeroSlideItemParams = (enabled, id, blockProps, children) => ({
  ...ctaItemParams(blockProps, enabled),
  ...imageItemParams(blockProps),
  pk: id,
  name: getTextEditor(children?.[0], enabled),
  description: getTextEditor(children?.[1], enabled),
})

export const dynamicProps = []

export const HomeHeroSlide: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children, loading, priority } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-home-hero-slide ${props.className}`}>
        <Original
          item={homeHeroSlideItemParams(enabled, id, blockProps, children)}
          editMode={enabled}
          loading={loading || 'lazy'}
          priority={priority || false}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

HomeHeroSlide.craft = withCraft({
  name: 'HomeHeroSlide',
  defaultProps: defaultProps,
  settings: HomeHeroSlideSettings,
  floatingSettings: HomeHeroSlideFloatingSettings,
})
