import React, { useEffect, useState } from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { BlogBanner as Original } from 'components/organisms'
import { BlogBannerSettings, BlogBannerFloatingSettings } from './BlogBannerSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  ctaDefaultProps,
  ctaItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import { getTextEditor } from '../TextEditor'
import { useRouter } from 'next/router'

export const defaultProps = {
  ...ctaDefaultProps(''),
}

export const blogBannerItemParams = (enabled, id, blockProps, children) => ({
  ...ctaItemParams(blockProps, enabled),
  pk: id,
  name: getTextEditor(children?.[0], enabled),
  description: getTextEditor(children?.[1], enabled),
  news: [],
})

export const dynamicProps = []

export const BlogBanner: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    const [news, setNews] = useState(blockProps.news || [])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const router = useRouter()

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-blog-banner ${props.className}`}>
        {news && !loading && !error ? (
          <Original
            item={blogBannerItemParams(enabled, id, blockProps, children)}
            editMode={enabled}
            news={news}
          />
        ) : (
          <></>
        )}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

BlogBanner.craft = withCraft({
  name: 'BlogBanner',
  defaultProps: defaultProps,
  settings: BlogBannerSettings,
  floatingSettings: BlogBannerFloatingSettings,
})
