import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { Button as Original } from 'components/atoms'
import { ButtonSettings } from './ButtonSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  ctaDefaultProps,
  ctaItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import { getIcon } from '@utils/icons'
import { getAncestorNode, getAncestorNodeByName } from 'react-pagebuilder/utils/node'

export const defaultProps = {
  ...ctaDefaultProps('Button', '', 'right'),
  variant: 'primary',
  size: 'md',
  // theme e color dipendono dallo sfondo, in generale il contenuto libero è su sfondo chiaro quindi non serve settarli
}

export const buttonItemParams = (enabled, id, blockProps) => ({
  ...ctaItemParams(blockProps, enabled),
  pk: id,
  style: blockProps.variant,
})

export const buttonParams = (enabled, id, blockProps) => ({
  pk: id,
  label: blockProps.ctaLabel,
  href: enabled
    ? blockProps.url || blockProps.extUrl
      ? '#'
      : ''
    : blockProps.url || blockProps.extUrl,
  newPage: enabled ? false : blockProps.blank,
  iconLeft: blockProps.iconPos === 'left' ? getIcon(blockProps.icon) : undefined,
  iconRight: blockProps.iconPos !== 'left' ? getIcon(blockProps.icon) : undefined,
  variant: blockProps.variant,
  size: blockProps.size,
})

export const dynamicProps = []

export const Button: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled, parent } = useEditor(
      (state, query) => ({
        enabled: state.options.enabled,
        parent: getAncestorNode(id, query, 2),
      }),
      props
    )

    const parentTheme = parent?.data?.props?.block?.theme
    const size =
      parent?.data?.name === 'TextSection'
        ? parent?.data?.props?.block?.size === 'sm'
          ? 'md'
          : 'lg'
        : undefined

    return enabled ? (
      <div ref={(ref) => props.retRef(ref)} className={'block-button ' + props.className}>
        <Original
          {...buttonParams(enabled, id, blockProps)}
          theme={['black', 'secondarydark', 'primary'].includes(parentTheme) ? 'dark' : undefined}
          color={
            (['black', 'secondarydark'].includes(parentTheme) &&
              blockProps.variant !== 'primary') ||
            parentTheme === 'primary'
              ? 'white'
              : undefined
          }
          size={size}
        />
      </div>
    ) : (
      <Original {...buttonParams(enabled, id, blockProps)} />
    )
  },
  defaultProps,
  dynamicProps
)

Button.craft = withCraft({
  name: 'Button',
  defaultProps: defaultProps,
  settings: ButtonSettings,
})
