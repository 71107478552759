import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const IssuuEmbedSettings = (settingsProps) => {
  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="issuuId"
          type="text"
          label="Id Issuu"
          defaultValue={defaultProps['issuuId']}
        />
      </ToolbarSection>
    </>
  )
}

export const IssuuEmbedFloatingSettings = () =>
  React.createElement(IssuuEmbedSettings, { floating: true })
