import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function RadioMarked({ idVariant = '', className }: Props) {
  return (
    <IconBase name="radio-marked" w={24} className={className}>
      <circle cx="12" cy="12" r="6" fill="currentColor" className="c2" />
      <rect x="2.5" y="2.5" width="19" height="19" rx="9.5" stroke="currentColor" />
    </IconBase>
  )
}

export default RadioMarked
