import React from 'react'
import { defaultProps } from './index'
import ImageToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'

export const BlogImageGalleryCardSettings = (settingsProps) => {
  return (
    <>
      {!settingsProps.floating && (
        <>
          <ImageToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const BlogImageGalleryCardFloatingSettings = () =>
  React.createElement(BlogImageGalleryCardSettings, { floating: true })
