/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo } from 'react'
import { NodeId, ROOT_NODE, Resolver, SerializedNodes } from '@craftjs/core'
import { resolver } from '../editor'
import { SerializedNodeWithId } from '../ssr'

export const deserializeNodes = (nodes: SerializedNodes): SerializedNodeWithId[] => {
  return Object.entries(nodes).map(([id, val]) => ({ id, ...val }))
}

export const getNodeById = (nodes: SerializedNodeWithId[], id: NodeId) => {
  return nodes?.find((node) => node.id === id)
}

export const renderNode = (
  nodes: SerializedNodeWithId[],
  resolver: Resolver,
  nodeId: NodeId
): JSX.Element => {
  const node = getNodeById(nodes, nodeId)

  if (!node) {
    throw new Error(`Could not find node with id ${nodeId}`)
  }

  const resolvedComponent = resolver?.[(node.type as any).resolvedName]

  if (!resolvedComponent) return null

  const children = node.nodes.map((descendant) => {
    return renderNode(nodes, resolver, descendant)
  })

  return React.createElement(
    resolvedComponent,
    { ...node.props, hidden: false, isSSR: true, id: nodeId, key: nodeId },
    children
  )
}

export const renderNodesToJSX = (
  nodes: SerializedNodeWithId[],
  resolver: Resolver,
  nodeId: NodeId
): JSX.Element => {
  return renderNode(nodes, resolver, nodeId)
}

export const SSRContent = memo(
  ({ json, skipError = true }: { json: string; skipError?: boolean }) => {
    let jsx = null

    try {
      const nodes = deserializeNodes(JSON.parse(json))
      jsx = renderNodesToJSX(nodes, resolver, ROOT_NODE)
    } catch (err) {
      console.log(err)
      if (!skipError) throw err
    }

    return jsx
  }
)
