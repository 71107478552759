import React, { useEffect, useRef, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Button, Tag, WImage, WLink } from '@components/atoms'
import styles from './VideoTutorialCard.module.sass'
import { Play } from '@components/icons'
import { VideoTutorialNode } from '@gql/graphql'
import { handleSelectVideoTracking } from '@utils/tracking'
import { Router, useRouter } from 'next/router'

interface Props {
  videoTutorial?: VideoTutorialNode
  variant?: '' | 'main'
  className?: string
}

const VideoTutorialCard = (props: Props) => {
  const { videoTutorial = undefined, variant = '', className = '' } = props

  const t = useTrans()
  const videoRef = useRef(null)
  const [play, setPlay] = useState(false)

  useEffect(() => {
    if (play) videoRef?.current?.play()
  }, [play])

  const router = useRouter()

  return videoTutorial ? (
    <div
      className={`${styles.root} ${play ? styles['root--play'] : ''} ${
        variant ? styles[`root--${variant}`] : ''
      } ${className}`}
    >
      <div className={styles.imageBox}>
        {videoTutorial.idYoutube && (
          <>
            <WImage
              src={`https://img.youtube.com/vi/${videoTutorial.idYoutube}/sddefault.jpg`}
              maxWidth={768}
              maxHeight={300}
              objectFit="cover"
              // alt=""
              // title=""
            />
            <span className={styles.play}>
              <Play />
            </span>
          </>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.head}>
          <Tag label={videoTutorial.categoria.nome} variant={'black'} />{' '}
        </div>
        <h3 className={styles.title}>{videoTutorial.titolo}</h3>
        {variant === 'main' && !!videoTutorial.sottotitolo && (
          <div className={styles.sottotitolo}>{videoTutorial.sottotitolo}</div>
        )}
        <WLink
          href={videoTutorial.url}
          className={styles.cta}
          onClick={() => handleSelectVideoTracking(videoTutorial.titolo, router.asPath)}
        >
          <Button label={t('Guarda il video')} variant="ghost" />
        </WLink>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default VideoTutorialCard
