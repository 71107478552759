import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import useLocalStorage from '@hooks/useLocalStorage'
import { useLogoutMutation } from '@gql/graphql'
import { Logout } from '@components/icons'
import { Button } from '@components/atoms'
import styles from './LogoutButton.module.sass'
import { handleUserLogoutTracking } from '@utils/tracking'
import useCookie from '@hooks/useCookie'

interface Props {
  className?: string
}

const LogoutButton = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [logout] = useLogoutMutation()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useLocalStorage('token', null)
  const [omnisendContactCookieContactID, setOmnisendContactCookieContactID, deleteCookieContactID] =
    useCookie('omnisendContactID', '')
  const [omnisendContactCookieSessionID, setOmnisendContactCookieSessionID, deleteCookieSessionID] =
    useCookie('omnisendSessionID', '')

  const handleClick = () => {
    setLoading(true)
    if (token) {
      setToken(null)
    }
    logout()
    handleUserLogoutTracking()
    deleteCookieContactID()
    deleteCookieSessionID()
    window.location.href = '/' // force SSR
  }

  return (
    <Button
      label={t('Esci')}
      variant="ghost"
      iconLeft={<Logout />}
      onClick={handleClick}
      loading={loading}
      className={`${styles.root} ${className}`}
    />
  )
}

export default LogoutButton
