import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription, WContainer } from '@components/atoms'
import styles from './HomeHeroSlide.module.sass'
import { getIcon } from '@utils/icons'
import { useRouter } from 'next/router'
import { checkUrlAndTrackPageBuilder } from '@utils/index'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
  loading?: 'lazy' | 'eager'
  layout?: '' | 'half'
  priority?: boolean
}

const HomeHeroSlide = (props: Props) => {
  const {
    item = undefined,
    editMode = false,
    className = '',
    loading = 'lazy',
    layout = '',
    priority = false,
  } = props

  const router = useRouter()

  return item ? (
    <div
      className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${
        layout ? styles[`root--${layout}`] : ''
      } ${className}`}
    >
      <div className={`${styles.imageBox}`}>
        <WImage
          src={item.image}
          maxWidth={1920}
          maxHeight={500}
          objectFit="cover"
          alt={item.alt}
          title={item.alt}
          loading={loading}
          priority={priority}
          className={`${styles.image} ${styles['image--desktop']}`}
        />
        <WImage
          src={item.imageTablet || item.image}
          maxWidth={992}
          maxHeight={500}
          objectFit="cover"
          alt={item.alt}
          title={item.alt}
          loading={loading}
          priority={priority}
          className={`${styles.image} ${styles['image--tablet']}`}
        />
        <WImage
          src={item.imageMobile || item.imageTablet || item.image}
          maxWidth={767}
          maxHeight={500}
          objectFit="cover"
          alt={item.alt}
          title={item.alt}
          loading={loading}
          priority={priority}
          className={`${styles.image} ${styles['image--mobile']}`}
        />
      </div>
      <div className={styles.layout}>
        <div className={styles.main}>
          {(!!item.name || !!item.description) && (
            <div className={styles.content}>
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
            </div>
          )}
          {!!item.url && !!item.ctaLabel && (
            <div className={styles.ctaBox}>
              <Button
                label={item.ctaLabel}
                href={item.url}
                newPage={item.blank}
                iconRight={getIcon(item.icon)}
                theme="dark"
                onClick={() => checkUrlAndTrackPageBuilder(item, router.asPath)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default HomeHeroSlide
