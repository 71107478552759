import React, { useEffect } from 'react'
import { WImage, WLink } from '@components/atoms'
import styles from './StripeCard.module.sass'
import { CategoriaNode } from '@gql/graphql'
import { handleClickCategoryTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  item: CategoriaNode
  className?: string
  loading?: 'lazy' | 'eager'
  priority?: boolean
}

const StripeCard = (props: Props) => {
  const { item = undefined, className = '', loading = 'lazy', priority = false } = props

  const classNames = `${styles.root} ${className}`
  const router = useRouter()

  const getContent = () => (
    <>
      <div className={`${styles.imageBox}`}>
        {!!item.previewImage && (
          <WImage
            src={item.previewImage}
            maxWidth={54}
            maxHeight={54}
            objectFit="contain"
            alt={item.boxTitolo}
            title={item.boxTitolo}
            loading={loading}
            priority={priority}
          />
        )}
      </div>
      {!!item.boxTitolo && <span className={styles.title}>{item.boxTitolo}</span>}
    </>
  )

  return item ? (
    item.url ? (
      <WLink
        href={item.url}
        onClick={() => handleClickCategoryTracking(item.boxTitolo, router.asPath)}
        className={classNames}
      >
        {getContent()}
      </WLink>
    ) : (
      <div className={classNames}>{getContent()}</div>
    )
  ) : (
    <></>
  )
}

export default StripeCard
