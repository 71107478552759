import React, { ReactNode } from 'react'
import { FormikErrors } from 'formik'
import { FormError, Tooltip } from 'components/atoms'
import styles from './FormField.module.sass'
import { Info } from '@components/icons'

export interface Props {
  id: string
  label?: string | React.ReactNode
  size?: 'md'
  layout?: 'sm' | 'md' | 'lg' | 'full' | 'none'
  space?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  variant?: '' | 'box'
  color?: '' | 'white'
  status?: '' | 'success' | 'error'
  tooltip?: string
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  className?: string
  labelClassName?: string
  required?: boolean
  childrenLayout?: '' | 'column-sm'
  children?: ReactNode
}

const FormField = (props: Props) => {
  const {
    id = '',
    label = '',
    size = 'md',
    layout = 'md',
    space = 'sm', // lo usiamo solo nelle varianti box, di default è 1.6rem, a volte diventa 2rem (md)
    variant = '',
    color = '',
    status = '',
    tooltip = undefined,
    errorMessage = '',
    className = '',
    labelClassName = '',
    required = false,
    childrenLayout = '',
    children = null,
  } = props

  const classNames = `${styles.root}
    ${size ? styles[`root--${size}`] : ''}
    ${variant ? styles[`root--${variant}`] : ''}
    ${size ? styles[`root--layout-${layout}`] : ''}
    ${space ? styles[`root--space-${space}`] : ''}
    ${color ? styles[`root--${color}`] : ''}
    ${status ? styles[`root--${status}`] : ''} 
    ${required ? styles[`root--required`] : ''}
    ${childrenLayout ? styles[`root--children-${childrenLayout}`] : ''}
    ${className ? className : ''}`

  return (
    <div className={classNames}>
      {label && (
        <label className={`${styles.label} ${labelClassName}`} htmlFor={id}>
          <span className={styles.labelValue}>{label}</span>
          {tooltip && (
            <span>
              <Tooltip id={`${id}_tooltip`} label={tooltip}>
                <span className={styles.tooltip}>
                  <Info />
                </span>
              </Tooltip>
            </span>
          )}
        </label>
      )}
      <div className={styles.children}>{children}</div>
      <FormError
        id={`${id}_mainerror`}
        message={errorMessage as string}
        visible={status === 'error'}
        className={styles.error}
      />
    </div>
  )
}

export default FormField
