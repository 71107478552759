import React from 'react'
import { defaultProps } from './index'
import LayoutToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const NumbersSectionSettings = (settingsProps) => {
  return (
    <>
      {!settingsProps.floating && (
        <>
          <LayoutToolbarSection defaultProps={defaultProps} initOpen />
        </>
      )}
    </>
  )
}

export const NumbersSectionFloatingSettings = () =>
  React.createElement(NumbersSectionSettings, { floating: true })
