import React from 'react'
import useTrans from '@hooks/useTrans'
import { NewsLinkedObjectType } from '@utils/types'
import { Button, Tag, WImage, WLink } from '@components/atoms'
import styles from './RicercaNewsCard.module.sass'
import { handleSelectContentTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  news?: NewsLinkedObjectType
  size?: 'sm' | 'md'
  className?: string
}

const RicercaNewsCard = (props: Props) => {
  const { news = undefined, size = 'md', className = '' } = props

  const t = useTrans()
  const router = useRouter()

  return news ? (
    <div className={`${styles.root} ${styles[`root--${size}`]} ${className}`}>
      {news.previewImage && size === 'md' && (
        <div className={`${styles.imageBox}`}>
          <WImage src={news.previewImage} maxWidth={120} maxHeight={120} objectFit="cover" />
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.content}>
          {!!news.categoria?.titolo && (
            <Tag label={news.categoria.titolo} variant="ideeverdi" className={styles.tag} />
          )}
          {!!news.titolo && <h3 className={styles.title}>{news.titolo}</h3>}
          {!!news.sottotitolo && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: news.sottotitolo }}
            />
          )}
        </div>
        <div className={styles.ctaBox}>
          <WLink
            href={news.url}
            className={styles.cta}
            onClick={() => {
              handleSelectContentTracking(
                news.titolo,
                'article',
                news?.categoria?.titolo || news.titolo,
                router.asPath
              )
            }}
          >
            <Button label={t("Leggi l'articolo")} variant="ghost" />
          </WLink>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RicercaNewsCard
