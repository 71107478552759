import React from 'react'
import styles from './CheckoutRiepilogoCosti.module.sass'
import useTrans from '@hooks/useTrans'
import { priceNotation } from '@utils/safe'
import { Tooltip, WModalTooltip } from '@components/atoms'
import { Info } from '@components/icons'
import { CarrelloNode, CheckoutNode } from '@gql/graphql'

interface Props {
  dati?: CarrelloNode | CheckoutNode
  variant?: '' | 'drawer'
  className?: string
}

const CheckoutRiepilogoCosti = (props: Props) => {
  const { dati = undefined, variant = '', className = '' } = props

  const t = useTrans()
  const TooltipComponent = variant === 'drawer' ? WModalTooltip : Tooltip

  // TODO - capire tutte le casistiche del tooltip
  const tooltipLabel = t('checkout_sidebar_tooltip')

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={`${styles.list} ${className}`}>
        <div className={styles.item}>
          <span className={styles.itemLabel}>{t('Subtotale')}</span>
          <span className={styles.itemValue}>
            {priceNotation(dati?.prezzo || dati?.totaleProdottiScontato)}
          </span>
        </div>
        {!!dati?.promoApplicata && !dati?.promoApplicata.promoDipendenti && (
          <div className={styles.item}>
            <span className={styles.itemLabel}>{t('Codice sconto')}</span>
            <span className={styles.itemValue}>
              <strong>{dati?.promoApplicata?.codice}</strong> -{' '}
              {priceNotation(dati?.scontoAssoluto)}
            </span>
          </div>
        )}
        {!!dati?.promoApplicata && dati?.promoApplicata.promoDipendenti && (
          <div className={styles.item}>
            <span className={styles.itemLabel}>{t('Codice sconto')}</span>
            <span className={styles.itemValue}>
              <strong>{dati?.promoApplicata?.codice}</strong> -{dati?.promoApplicata.valore}%
            </span>
          </div>
        )}
        {!!dati?.scontoBundles?.scontoAssoluto && (
          <div className={styles.item}>
            <span className={styles.itemLabel}>{t('Promozione')}</span>
            <span className={styles.itemValue}>
              <strong>{dati?.scontoBundles?.promoApplicata?.alias}</strong> -{' '}
              {priceNotation(dati?.scontoBundles?.scontoAssoluto)}
            </span>
          </div>
        )}
        {!dati?.sistemaSpedizione ? (
          <div className={styles.item}>
            <span className={styles.itemLabel}>
              {t('Costi calcolati al passaggio successivo in base al tipo di consegna scelto')}
            </span>
            <span className={styles.itemValue}>
              <TooltipComponent
                id={`checkout_riepilogo_costi_tooltip_${variant}`}
                label={tooltipLabel}
                pos="left"
              >
                <span className={styles.info}>
                  <Info />
                </span>
              </TooltipComponent>
            </span>
          </div>
        ) : dati?.sistemaSpedizione ? (
          <div className={styles.item}>
            <span className={styles.itemLabel}>{t('Costi di consegna')}</span>
            <span className={styles.itemValue}>
              {priceNotation(dati?.sistemaSpedizione?.prezzoScontato)}
            </span>
          </div>
        ) : (
          <div className={styles.item}>
            <span className={styles.itemLabel}>
              {t('Costi di consegna calcolati al passaggio successivo')}
            </span>
            <span className={styles.itemValue}>
              <TooltipComponent
                id="checkout_riepilogo_tooltip_info"
                label={tooltipLabel}
                pos="left"
              >
                <span className={styles.info}>
                  <Info />
                </span>
              </TooltipComponent>
            </span>
          </div>
        )}
      </div>
      <div className={styles.total}>
        <span className={styles.totalLabel}>{t('Totale')}</span>
        <span className={styles.totalValue}>{priceNotation(dati?.totaleScontato)}</span>
      </div>
    </div>
  )
}

export default CheckoutRiepilogoCosti
