import { Node, useNode as useCraftNode } from '@craftjs/core'
import _ from 'lodash'
import { BaseNodeProps } from '../ssr'

const SSR_DEFAULT_STATE: Node = {
  id: '',
  dom: null,
  events: {
    selected: false,
    hovered: false,
    dragged: false,
  },
  data: {
    props: {},
    type: '',
    name: '',
    displayName: '',
    isCanvas: false,
    parent: null,
    linkedNodes: {},
    nodes: [],
    hidden: false,
    custom: {},
  },
  related: {},
  rules: {
    canDrag: () => false,
    canDrop: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  _hydrationTimestamp: 0,
}

/**
 * This hook is used so we can achieve SSR of the CraftJS nodes.
 * If we are in SSR mode, we stub out the Node return type.
 *
 * The collect function type is slightly different to what the normal
 * CraftJS useNode specifies. The node it provides in the callback is
 * optional, indicating it may or may not be there (in SSR it will not
 * be there). This lets consumers provide a default value.
 */

export function useNode<A>(collect?: (node?: Node) => A, options: BaseNodeProps = {}) {
  if (options?.isSSR) {
    const collected: A = collect ? collect(SSR_DEFAULT_STATE) : (SSR_DEFAULT_STATE as A)
    return {
      id: options.id,
      inNodeContext: false,
      connectors: {
        connect: _.identity,
        drag: _.identity,
      },
      actions: {
        setProp: _.noop,
        setCustom: _.noop,
        setHidden: _.noop,
      },
      setProp: _.noop,
      ...(collected as any),
    }
  } else {
    return useCraftNode(collect)
  }
}
