import React from 'react'
import { MenuItemType } from '@utils/types'
import styles from './NavTag.module.sass'
import WLink from '../WLink/WLink'

interface Props {
  item?: MenuItemType
  className?: string
}

const NavTag = (props: Props) => {
  const { item = undefined, className = '' } = props

  return item ? (
    <WLink className={`${styles.root} ${className}`} href={item.url} newPage={item.blank}>
      {item.name}
    </WLink>
  ) : (
    <></>
  )
}

export default NavTag
