import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Flashlight({ idVariant = '', className }: Props) {
  return (
    <IconBase name="flashlight" w={24} className={className}>
      <path
        d="M13 9H21L11 24V15H4L13 0V9ZM11 11V7.22063L7.53238 13H13V17.3944L17.263 11H11Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Flashlight
