import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const AccordionSettings = (settingsProps) => {
  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="label"
          type="text"
          label="Label"
          defaultValue={defaultProps['label']}
        />
      </ToolbarSection>
    </>
  )
}

export const AccordionFloatingSettings = () =>
  React.createElement(AccordionSettings, { floating: true })
