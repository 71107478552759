import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button } from '@components/atoms'
import styles from './AttivitaCard.module.sass'
import { handleCosaDeviFareTracking } from '@utils/tracking'

interface Props {
  item: MenuItemType
  selected: string
  editMode?: boolean
  className?: string
}

const AttivitaCard = (props: Props) => {
  const { item = undefined, selected, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      <WImage
        src={item.image}
        maxWidth={340}
        maxHeight={460}
        objectFit="cover"
        alt={item.alt}
        title={item.alt}
      />
      {!!item.ctaLabel && !!item.url && (
        <Button
          label={item.ctaLabel}
          href={item.url}
          newPage={item.blank}
          color="white"
          onClick={() => handleCosaDeviFareTracking(selected, item.ctaLabel)}
          className={styles.cta}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

export default AttivitaCard
