import React from 'react'
import { ProdottoNode } from '@gql/graphql'
import { WContainer } from '@components/atoms'
import { SliderProdotti } from '@components/organisms'
import styles from './ProdottiCorrelati.module.sass'

interface Props {
  titolo?: string
  prodotti?: ProdottoNode[]
  className?: string
}

const ProdottiCorrelati = (props: Props) => {
  const { titolo = undefined, prodotti = [], className = '' } = props

  return prodotti?.length ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          {!!titolo && <h2 className={styles.title}>{titolo}</h2>}
          <div className={`${styles.slider}`}>
            <SliderProdotti prodotti={prodotti} navigation pagination className={styles.swiper} />
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ProdottiCorrelati
