import React from 'react'
import { PaginaNode } from '@gql/graphql'
import { WImage, WContainer } from '@components/atoms'
import styles from './LandingNewsletterHead.module.sass'

interface Props {
  pagina: PaginaNode
  className?: string
}

const LandingNewsletterHead = (props: Props) => {
  const { pagina = undefined, className = '' } = props

  return pagina ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={`${styles.layout}`}>
          <div className={`${styles.imageBox}`}>
            {!!pagina.mainImage && (
              <WImage
                src={pagina.mainImage}
                maxWidth={860}
                maxHeight={600}
                objectFit="cover"
                alt={pagina.titolo}
                title={pagina.titolo}
                priority
                loading="eager"
              />
            )}
          </div>
          {(!!pagina.titolo || !!pagina.descrizione) && (
            <div className={styles.main}>
              {!!pagina.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
              {!!pagina.descrizione && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
                />
              )}
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingNewsletterHead
