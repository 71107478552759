import React from 'react'
import styles from './Feedback.module.sass'
import { Check } from '@components/icons'

interface Props {
  title?: string
  description?: string
  status?: 'success' | 'error'
  className?: string
}

const Feedback = (props: Props) => {
  const { title = undefined, description = undefined, status = 'success', className = '' } = props

  return (
    <div className={`${styles.root} ${styles[`root--${status}`]} ${className}`}>
      <span className={styles.icon}>
        <Check />
      </span>
      <div className={styles.main}>
        {!!title && <p className={styles.title}>{title}</p>}
        {!!description && (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  )
}

export default Feedback
