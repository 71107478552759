import React from 'react'
import { Props as FormFieldProps } from 'components/atoms/FormField/FormField'
import {
  FormField,
  FormText,
  FormPassword,
  FormSelect,
  FormTextarea,
  FormCheckbox,
  FormRadio,
  FormRivenditoreRadio,
  FormToggle,
  // FormFile,
  FormDate,
} from 'components/atoms'
// import { setRecaptchaRequired } from 'containers/pagina'
import { useDispatch, useSelector } from 'react-redux'
import { RivenditoreNode } from '@gql/graphql'

interface Props {
  id?: string
  label?: string | React.ReactNode
  checkboxLabel?: string | React.ReactNode
  placeholder?: string
  type?:
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'select'
    | 'textarea'
    | 'checkbox'
    | 'radio'
    | 'rivenditore_radio'
    // | 'file'
    | 'date'
    | 'toggle'
  name: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  layout?: 'sm' | 'md' | 'lg' | 'full' | 'none'
  space?: 'none' | 'sm' | 'md' | 'lg'
  options?: any[]
  min?: number
  max?: number
  minDate?: string
  maxDate?: string
  step?: number | string
  helper?: any
  tooltip?: any
  togglePassword?: boolean
  isSearchable?: boolean
  showSublabel?: boolean
  icon?: React.ReactNode
  variant?: '' | 'horizontal' | 'horizontal-half' | 'horizontal-buttons'
  fieldVariant?: '' | 'box'
  errorAlwaysShown?: boolean
  rivenditori?: RivenditoreNode[]
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemove?: (e: React.ChangeEvent<HTMLInputElement>) => void
  formik: any
  className?: string
  labelClassName?: string
  async?: boolean
  yearFrom?: number
  yearNumber?: number
  // loadRecaptchaOnChange?: boolean
}

const FormikField = (props: Props) => {
  const {
    id = '',
    label = undefined,
    checkboxLabel = undefined,
    placeholder = undefined,
    name = '',
    required = undefined,
    readOnly = undefined,
    disabled = undefined,
    layout = undefined,
    space = undefined,
    formik = {},
    type = 'text',
    togglePassword = undefined,
    options = [],
    min = undefined,
    max = undefined,
    minDate = undefined,
    maxDate = undefined,
    showSublabel = undefined,
    step = undefined,
    helper = undefined,
    tooltip = undefined,
    icon = undefined,
    isSearchable = undefined,
    variant = undefined,
    fieldVariant = undefined,
    errorAlwaysShown = false,
    rivenditori = undefined,
    onBlur = undefined,
    onChange = undefined,
    onRemove = undefined,
    className = '',
    labelClassName = '',
    async = false,
    yearFrom = undefined,
    yearNumber = undefined,
    // loadRecaptchaOnChange = false,
  } = props

  // const { recaptchaRequired } = useSelector((state: any) => state?.pagina)
  // const dispatch = useDispatch()

  const recaptchaOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (loadRecaptchaOnChange && !recaptchaRequired) {
    //   dispatch(setRecaptchaRequired())
    // }

    // Se checkbox all'onChange metto anche touched, altrimenti devo aspettare il blur
    if (type === 'checkbox') formik.setFieldTouched(name)

    return onChange ? onChange(e) : formik?.handleChange(e)
  }

  const onBlurFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    return onBlur ? onBlur(e) : formik?.handleBlur(e)
  }

  const componentsMap = {
    text: FormText,
    email: FormText,
    number: FormText,
    password: FormPassword,
    select: FormSelect,
    textarea: FormTextarea,
    checkbox: FormCheckbox,
    radio: FormRadio,
    rivenditore_radio: FormRivenditoreRadio,
    // file: FormFile,
    date: FormDate,
    toggle: FormToggle,
  }

  const formFieldProps: FormFieldProps = {
    id: id,
    label: label,
    tooltip: tooltip,
    required: required,
    layout: layout,
    space: space,
    variant: fieldVariant,
    className: className,
    labelClassName: labelClassName,
  }

  const getSelectValue = (value) => {
    return value !== null && value !== undefined ? value.toString() : value
  }

  const formElementProps = {
    id: id,
    name: name,
    label: type === 'checkbox' || type === 'toggle' ? checkboxLabel : undefined,
    placeholder: placeholder,
    readOnly: readOnly,
    required: required,
    disabled: disabled,
    checked: formik?.values?.[name],
    value: type === 'select' ? getSelectValue(formik?.values?.[name]) : formik?.values?.[name],
    // files: type === 'file' ? formik?.values?.[name] : undefined,
    onBlur: onBlurFunction,
    onChange: recaptchaOnChange,
    onRemove: onRemove,
    options: type === 'select' || type === 'radio' ? options : undefined,
    togglePassword: type === 'password' ? togglePassword : undefined,
    type: type === 'text' || type === 'email' || type === 'number' ? type : undefined,
    variant: type === 'radio' ? variant : undefined,
    min: type === 'number' ? min : undefined,
    max: type === 'number' ? max : undefined,
    minDate,
    maxDate,
    showSublabel: type === 'date' ? showSublabel : undefined,
    step: type === 'number' ? step : undefined,
    helper: type === 'password' ? helper : undefined,
    icon: type === 'toggle' ? icon : undefined,
    isSearchable: type === 'select' ? isSearchable : undefined,
    rivenditori: type === 'rivenditore_radio' ? rivenditori : undefined,
    status:
      formik?.touched?.[name] || errorAlwaysShown
        ? formik?.errors?.[name]
          ? 'error'
          : 'success'
        : '',
    errorMessage: formik?.errors?.[name],
    async: type === 'select' ? async : undefined,
    yearFrom: type === 'date' ? yearFrom : undefined,
    yearNumber: type === 'date' ? yearNumber : undefined,
  }

  const FormComponent = componentsMap[type]

  return (
    <FormField {...formFieldProps}>
      <FormComponent {...formElementProps} />
    </FormField>
  )
}

export default FormikField
