import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { HomeHero as Original } from 'components/organisms'
import { HomeHeroSettings } from './HomeHeroSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { getItemChildren } from 'react-pagebuilder/utils/node'
import { homeHeroBlockItemParams } from '../HomeHeroBlock'

export const defaultProps = {}

export const homeHeroItemParams = (enabled, id, blockProps, children) => ({
  pk: id,
  children: getItemChildren(enabled, children?.[0], homeHeroBlockItemParams),
})

export const dynamicProps = []

export const HomeHero: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-home-hero ${props.className}`}>
        <Original item={homeHeroItemParams(enabled, id, blockProps, children)} editMode={enabled} />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

HomeHero.craft = withCraft({
  name: 'HomeHero',
  defaultProps: defaultProps,
  settings: HomeHeroSettings,
})
