import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { MenuItemType } from '@utils/types'
import { WContainer, TabBar, EditableTitle, EditableDescription } from '@components/atoms'
import { AttivitaCard } from '@components/molecules'
import styles from './TabAttivita.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const TabAttivita = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  const barItems = editMode ? [] : item?.children?.map((x) => ({ label: x.name, pk: x.pk })) || []
  const [selected, setSelected] = useState(barItems[0])

  useEffect(() => {
    if (!selected && barItems?.length > 0) setSelected(barItems[0])
  }, [barItems])

  return editMode || item?.children?.length > 0 ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          {(!!item.name || !!item.description) && (
            <div className={styles.main}>
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
            </div>
          )}
          <TabBar items={barItems} selected={selected} setSelected={setSelected} />
        </div>
      </WContainer>
      {editMode ? (
        <>{item.children}</>
      ) : (
        item.children.map((x) => (
          <div
            className={`${styles.slider} ${x.pk === selected.pk ? styles['slider--visible'] : ''}`}
            key={x.pk}
          >
            <Swiper
              slidesPerView={'auto'}
              modules={[A11y, Navigation, Pagination]}
              centerInsufficientSlides
              pagination={{
                type: 'progressbar',
              }}
              navigation
              loop
              className={'swiper--bottomNav'}
            >
              {x.children?.map((y) => (
                <SwiperSlide key={y.pk}>
                  <AttivitaCard item={y} selected={selected.label} className={styles.item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))
      )}
    </div>
  ) : (
    <></>
  )
}

export default TabAttivita
