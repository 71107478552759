import React from 'react'
import useTrans from '@hooks/useTrans'
import { VideoTutorialLinkedObjectType } from '@utils/types'
import { Play } from '@components/icons'
import { Button, Tag, WImage, WLink } from '@components/atoms'
import styles from './RicercaVideoTutorialCard.module.sass'
import { handleSelectVideoTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  videoTutorial?: VideoTutorialLinkedObjectType
  size?: 'sm' | 'md'
  className?: string
}

const RicercaVideoTutorialCard = (props: Props) => {
  const { videoTutorial = undefined, size = 'md', className = '' } = props

  const t = useTrans()
  const router = useRouter()

  return videoTutorial ? (
    <div className={`${styles.root} ${styles[`root--${size}`]} ${className}`}>
      <div className={`${styles.imageBox}`}>
        {videoTutorial.idYoutube && (
          <>
            <WImage
              src={`https://img.youtube.com/vi/${videoTutorial.idYoutube}/sddefault.jpg`}
              maxWidth={120}
              maxHeight={120}
              objectFit="cover"
            />
            <span className={styles.play}>
              <Play />
            </span>
          </>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          {!!videoTutorial.categoria?.titolo && (
            <Tag
              label={videoTutorial.categoria.titolo}
              variant="categoria"
              className={styles.tag}
            />
          )}
          {!!videoTutorial.titolo && <h3 className={styles.title}>{videoTutorial.titolo}</h3>}
        </div>
        <div className={styles.ctaBox}>
          <WLink
            href={videoTutorial.url}
            className={styles.cta}
            onClick={() => handleSelectVideoTracking(videoTutorial.titolo, router.asPath)}
          >
            <Button label={t('Guarda il video')} variant="ghost" />
          </WLink>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RicercaVideoTutorialCard
