import React, { useEffect } from 'react'
import { integer } from '@opensearch-project/opensearch/api/types'
import { FiltroCategoriaNode, ParentFiltroCategoriaNode } from '@gql/graphql-get'
import { Accordion, FormCheckbox } from '@components/atoms'
import styles from './Filtro.module.sass'
import { handleListingFilterTracking } from '@utils/tracking'

interface Props {
  id: string
  attributo?: ParentFiltroCategoriaNode
  onChange?: (a: FiltroCategoriaNode, type: string) => void
  className?: string
  appliedFilters?: { name: string; id: integer }[]
  isOpen?: boolean
}

const Filtro = (props: Props) => {
  const {
    id = undefined,
    attributo = undefined,
    onChange = undefined,
    className = '',
    appliedFilters = [],
    isOpen = false,
  } = props

  return attributo ? (
    <Accordion
      id={`filtro_${id}`}
      variant="filter"
      label={attributo.nome}
      badge={attributo.children?.filter((x) => x.selected).length}
      className={`${styles.root} ${className}`}
      isOpen={isOpen}
    >
      <div className={styles.content}>
        <div className={styles.list}>
          {attributo.children?.map((a) => (
            <FormCheckbox
              id={`filtro_checkbox_${id}_${a.nome}`}
              label={`${a.nome} ${a.facets ? `(${a.facets})` : ''}`}
              checked={appliedFilters ? appliedFilters.some((f) => f.id === a.id) : a.selected}
              onChange={() => {
                onChange(a, attributo.nome)
                handleListingFilterTracking(a.nome, attributo.nome, a.selected)
              }}
              disabled={a.disabled}
              key={a.nome}
            />
          ))}
        </div>
      </div>
    </Accordion>
  ) : (
    <></>
  )
}

export default Filtro
