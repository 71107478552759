import React from 'react'
import styles from './FiltroApplicato.module.sass'
import { Close } from '@components/icons'

interface Props {
  label?: string
  onClose?: () => void
  className?: string
}

const FiltroApplicato = (props: Props) => {
  const { label = undefined, onClose = undefined, className = '' } = props

  return (
    <button type="button" className={`${styles.root} ${className}`} onClick={onClose}>
      {!!label && <span className={styles.label}>{label}</span>}
      <span className={styles.close}>
        <Close />
      </span>
    </button>
  )
}

export default FiltroApplicato
