import React, { ReactNode } from 'react'
import styles from './WContainer.module.sass'

interface Props {
  variant?:
    | 'xs'
    | 'sm'
    | 'sm-plus'
    | 'md'
    | 'lg'
    | 'none'
    | 'fluid'
    | 'menu'
    | 'blog-head'
    | 'blog-content'
  className?: string
  children?: ReactNode
}

const WContainer = (props: Props) => {
  const { variant = 'md', className = '', children = null } = props

  return variant === 'none' ? (
    <>{children}</>
  ) : (
    <div className={`${styles.root} wcontainer ${styles[`root--${variant}`]} ${className}`}>
      {children}
    </div>
  )
}

export default WContainer
