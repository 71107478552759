import React from 'react'
import styles from './WModalTooltip.module.sass'

interface Props {
  id?: string
  label?: React.ReactNode
  pos?: 'top' | 'right' | 'bottom' | 'left'
  children?: React.ReactNode
  className?: string
}

const WModalTooltip = (props: Props) => {
  const {
    id = undefined,
    label = undefined,
    pos = 'top',
    children = undefined,
    className = '',
  } = props

  return (
    <div className={`${styles.root} ${styles[`root--${pos}`]} ${className}`}>
      {children}
      <div className={styles.tooltip} id={id}>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    </div>
  )
}

export default WModalTooltip
