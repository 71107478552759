import React from 'react'
import useTrans from '@hooks/useTrans'
import { FormikField, FormLayout } from '@components/atoms'
import styles from './CheckoutFormPartIndirizzo.module.sass'
import { cittaComuniOptions } from '@utils/checkout'

interface Props {
  formikProps?: any
  variant?: 'spedizione' | 'fatturazione'
  className?: string
}

const CheckoutFormPartIndirizzo = (props: Props) => {
  const { formikProps = undefined, variant = 'spedizione', className = '' } = props

  const t = useTrans()

  const statoOpts = [{ label: t('Italia'), value: 'it' }]
  const cittaOpts = cittaComuniOptions()

  const getName = (n: string) => `${n}${variant[0].toUpperCase()}${variant.slice(1)}`

  return (
    <div className={`${styles.root} ${className}`}>
      <FormLayout>
        <FormikField
          formik={formikProps}
          id={'checkout_nome_spedizione'}
          name={getName('nome')}
          type="text"
          label={t('Nome')}
          placeholder={t('Inserisci il tuo nome')}
          required
        />
        <FormikField
          formik={formikProps}
          id={'checkout_cognome_spedizione'}
          name={getName('cognome')}
          type="text"
          label={t('Cognome')}
          placeholder={t('Inserisci il tuo cognome')}
          required
        />
        <FormikField
          formik={formikProps}
          id={'checkout_indirizzo_spedizione'}
          name={getName('indirizzo')}
          type="text"
          label={t('Indirizzo (via, piazza, civico)')}
          placeholder={t('Inserisci il tuo indirizzo')}
          required
          layout="full"
        />
        <FormikField
          formik={formikProps}
          id={'checkout_info_spedizione'}
          name={getName('info')}
          type="text"
          label={t('Informazioni aggiuntive')}
          placeholder={t('Aggiungi informazioni utili per la consegna')}
          layout="full"
        />
        <FormikField
          formik={formikProps}
          id={'checkout_citta_spedizione'}
          name={getName('citta')}
          label={t('Città')}
          placeholder={t('Inserisci la città')}
          type="select"
          options={cittaOpts}
          isSearchable={true}
          required
          async
        />
        <FormikField
          formik={formikProps}
          id={'checkout_stato_spedizione'}
          name={getName('stato')}
          type="select"
          options={statoOpts}
          label={t('Stato')}
          placeholder={t('Inserisci lo stato')}
          required
          isSearchable={true}
        />
        <FormikField
          formik={formikProps}
          id={'checkout_cap_spedizione'}
          name={getName('cap')}
          type="text"
          label={t('Codice postale')}
          placeholder={t('Inserisci il tuo codice postale')}
          required
        />
        <FormikField
          formik={formikProps}
          id={'checkout_telefono_spedizione'}
          name={getName('telefono')}
          type="text"
          label={t('Numero di telefono')}
          placeholder={t('Inserisci il tuo numero di telefono')}
          required
        />
      </FormLayout>
      <div className={styles.requirements}>* {t('Campi obbligatori')}</div>
    </div>
  )
}

export default CheckoutFormPartIndirizzo
