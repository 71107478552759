import React from 'react'
import useTrans from '@hooks/useTrans'
import { CategoriaNode } from '@gql/graphql'
import { ArrowRight } from '@components/icons'
import { WImage, Button } from '@components/atoms'
import styles from './CategoriaLinkCard.module.sass'

interface Props {
  categoria: CategoriaNode
  className?: string
}

const CategoriaLinkCard = (props: Props) => {
  const { categoria = undefined, className = '' } = props

  const t = useTrans()

  return categoria ? (
    <div className={`${styles.root} ${className}`}>
      <div className={`${styles.imageBox}`}>
        {!!categoria.previewImage && (
          <WImage
            src={categoria.previewImage}
            maxWidth={157}
            maxHeight={157}
            // objectFit="cover"  commentato il "cover" e messa maxWidth=157 perché le previewImage di categoria sono quadrate
            alt={categoria.nome}
            title={categoria.nome}
          />
        )}
      </div>
      {!!categoria.boxTitolo && <h3 className={styles.title}>{categoria.boxTitolo}</h3>}
      {!!categoria.url && (
        <div className={styles.ctaBox}>
          <Button
            label={t('Vedi i prodotti')}
            href={categoria.url}
            variant="ghost"
            iconRight={<ArrowRight />}
            size="sm"
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default CategoriaLinkCard
