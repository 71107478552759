import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Button, FormField, FormRadio } from '@components/atoms'
import styles from './FaqFeedback.module.sass'
import { RispostaHelpOnlineNode, useFeedbackMutation } from '@gql/graphql'

interface Props {
  faq: RispostaHelpOnlineNode
  className?: string
}

const FaqFeedback = ({ faq, className = '' }: Props) => {
  const t = useTrans()
  const [value, setValue] = useState<string | undefined>(undefined)
  const [showFeedback, setShowFeedback] = useState(false)

  const [feedbackMutation] = useFeedbackMutation({
    onCompleted: (res) => {
      if (!res.feedback.status) {
        // Optionally handle global error here
        console.error(res.feedback.message || t('Errore durante il salvataggio'))
      } else {
        setShowFeedback(true)
      }
    },
    onError: (error) => {
      console.error(t('Errore durante il salvataggio'), error)
    },
  })

  const handleFeedback = (value: string) => {
    setValue(value)
    feedbackMutation({
      variables: {
        idRisposta: faq.pk.toString(),
        utile: value === '1',
      },
    })
  }

  return faq ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.feedback}>
        <FormField
          id={`faqfeedback_${faq.pk}`}
          label={t('Questo articolo è stato utile?')}
          className={styles.feedbackField}
          labelClassName={styles.feedbackLabel}
          layout="full"
        >
          <FormRadio
            id={`faqfeedback_${faq.pk}`}
            variant="horizontal-buttons"
            value={value}
            onChange={(e) => handleFeedback(e.target.value)}
            options={[
              { id: `faqfeedback_${faq.pk}_1`, label: t('Sì'), value: '1' },
              { id: `faqfeedback_${faq.pk}_0`, label: t('No'), value: '0' },
            ]}
          />
        </FormField>
      </div>
      {showFeedback && (
        <div className={styles.thankyou}>
          <div
            className={styles.thankyouContent}
            dangerouslySetInnerHTML={{
              __html: t(
                '<p>Grazie per il tuo feedback</p><p>Hai altre domande? Puoi inviarci la tua segnalazione via e-mail e sarai contattato per la gestione della tua richiesta</p>'
              ),
            }}
          />
          <Button label={t('Invia email')} variant="secondary" size="sm" href="/contatti" />
        </div>
      )}
    </div>
  ) : null
}

export default FaqFeedback
