import React, { useEffect, useState } from 'react'
import { Accordion } from '@components/atoms'
import { FaqFeedback } from '@components/molecules'
import styles from './FaqCard.module.sass'
import { RispostaHelpOnlineNode, useFeedbackMutation } from '@gql/graphql'
import { handleFaqTracking } from '@utils/tracking'
import { set } from 'lodash'

interface Props {
  faq: RispostaHelpOnlineNode
  categoria?: string
  className?: string
  isOpen?: boolean
}

const FaqCard = (props: Props) => {
  const { faq = undefined, categoria, className = '', isOpen } = props
  const [open, setOpen] = useState(isOpen)

  return faq ? (
    <Accordion
      id={`faqcard_accordion_${faq.pk}`}
      label={faq.nome}
      className={`${styles.root} ${className}`}
      variant="faq"
      isOpen={open}
      onClick={() => {
        setOpen(!open)
        if (!open) handleFaqTracking(faq.nome, categoria)
      }}
    >
      <div className={styles.main}>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: faq.risposta }} />
        <FaqFeedback faq={faq} />
      </div>
    </Accordion>
  ) : (
    <></>
  )
}

export default FaqCard
