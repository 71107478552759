import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import { InteresseNewsletterNode, useLandingNewsletterSubscriptionMutation } from '@gql/graphql'
import { landingNewsletterGiardinaggioMiniguidaValidation } from '@utils/validationSchemas'
import {
  Button,
  FormCheckbox,
  FormError,
  FormField,
  FormikField,
  FormLayout,
  Modal,
  WContainer,
} from '@components/atoms'
import styles from './LandingNewsletterGiardinaggioMiniguidaForm.module.sass'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface Props {
  interessiUtente?: InteresseNewsletterNode[]
  className?: string
}

const LandingNewsletterGiardinaggioMiniguidaForm = (props: Props) => {
  const { interessiUtente = [], className = '' } = props

  const t = useTrans()
  const [error, setError] = useState('') // TODO: gestione errore

  const [modalOpen, setModalOpen] = useState(false)

  const router = useRouter()

  const [subscribe, { loading }] = useLandingNewsletterSubscriptionMutation()

  const categoriaUtenteOpts = [
    {
      label: t('Privato'),
      // description: t('Sono un privato con la passione per la cura del verde'),
      value: 'privato',
    },
    {
      label: t('Professionista'),
      // description: t('Sono un professionista della cura del verde e/o forestale'),
      value: 'professionista',
    },
  ]

  // const ambienteLavoroOpts = interessiUtente?.map((interesse) => {
  //   return {
  //     label: interesse.alias,
  //     description: interesse.descrizione,
  //     value: interesse.pk.toString(),
  //   }
  // })

  const initialValues = {
    email: '',
    nome: '',
    cognome: '',
    birthdate: '',
    categoriaUtente: categoriaUtenteOpts[0].value,
    // ambienteLavoro: ambienteLavoroOpts && ambienteLavoroOpts[0]?.value,
    privacy: false,
    consensoMkt: false,
    interessi: [],
  }
  const { executeRecaptcha } = useGoogleReCaptcha()

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              let token = 'faketoken'
              if (process.env.ENVIRONMENT === 'prod') {
                if (!executeRecaptcha) return

                token = await executeRecaptcha('newsletter_giardinaggio')
                if (!token) return
              }

              const input = {
                nome: values.nome,
                cognome: values.cognome,
                email: values.email,
                privato: values.categoriaUtente === 'privato',
                consensoProfilazione: values.consensoMkt,
                dataDiNascita: values.birthdate,
                interessi: values.interessi.join('/'),
                landing: 'miniguida_giardinaggio',
                captcha: token,
              }

              const {
                data: {
                  landingNewsletterSubscription: { status, message },
                },
              } = await subscribe({ variables: { input } })

              if (status) setModalOpen(true)

              resetForm()
            }}
            validationSchema={landingNewsletterGiardinaggioMiniguidaValidation(t)}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <FormError
                  id="giardinaggio_miniguida_global_error"
                  message={error}
                  visible={!!error}
                  variant="global"
                />
                <FormLayout space="sm">
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_nome'}
                    name="nome"
                    type="text"
                    label={t('Nome')}
                    placeholder={t('Nome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_cognome'}
                    name="cognome"
                    type="text"
                    label={t('Cognome')}
                    placeholder={t('Cognome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_email'}
                    name="email"
                    type="email"
                    label={t('E-mail')}
                    placeholder={t('Email')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_birthdate'}
                    name="birthdate"
                    type="date"
                    label={t('Data di nascita')}
                    showSublabel={false}
                    required
                  />
                  <FormikField
                    id="giardinaggio_miniguida_categoria_utente"
                    formik={formikProps}
                    type="radio"
                    name="categoriaUtente"
                    label={t('Sei un privato o un professionista? *')}
                    options={categoriaUtenteOpts}
                    layout="full"
                    variant="horizontal"
                    fieldVariant="box"
                    required
                  />
                  <FormField
                    id="giardinaggio_miniguida_interessi"
                    required
                    label={t('Qual è il tuo ambiente di lavoro? *')}
                    layout="full"
                    variant="box"
                    status={
                      formikProps.touched?.interessi
                        ? formikProps.errors?.interessi
                          ? 'error'
                          : 'success'
                        : ''
                    }
                    errorMessage={formikProps?.errors?.interessi}
                  >
                    <div
                      className={styles.checkboxGroup}
                      role="group"
                      aria-labelledby="giardinaggio_miniguida_interessi"
                    >
                      {interessiUtente.map((interesse, i) => (
                        <FormCheckbox
                          key={interesse.pk}
                          id={`giardinaggio_miniguida_interesse_${interesse.pk}`}
                          label={`${interesse.alias}: ${interesse.descrizione}`}
                          name={`interessi`}
                          value={interesse.pk}
                          checked={formikProps.values.interessi.includes(interesse.pk.toString())}
                          onChange={(e) => formikProps?.handleChange(e)}
                        />
                      ))}
                    </div>
                  </FormField>
                </FormLayout>
                <div
                  className={styles.privacy}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "Ti invitiamo a visionare <a href='/privacy/informativa-per-marketing-e-profilazione/'>l'informativa privacy art. 13 GDPR</a> sul trattamento dei dati"
                    ),
                  }}
                />
                <FormLayout space="sm">
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_privacy'}
                    name="privacy"
                    type="checkbox"
                    label={t('Letta e compresa l’informativa*')}
                    checkboxLabel={t(
                      'Esprimo il consenso al trattamento dei miei dati per ricevere supporto, risposte ai miei quesiti e per le eventuali attività connesse di cui al punto a) dell’informativa.'
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                  <FormikField
                    formik={formikProps}
                    id={'giardinaggio_miniguida_marketing'}
                    name="consensoMkt"
                    type="checkbox"
                    label={t('Consenso per la "Profilazione" (facoltativo)')}
                    checkboxLabel={t(
                      'Esprimo il consenso per la finalità di cui al punto c) dell’informativa e quindi acconsento all’individuazione e definizione dei miei gusti e preferenze di consumo. Resta inteso che potrò revocare in qualunque momento il presente consenso.'
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                </FormLayout>
                <div className={styles.requirements}>
                  * {t('I campi obbligatori sono contrassegnati dall’asterisco')}
                </div>
                <Button
                  type="submit"
                  label={t('Scaricala ora')}
                  loading={loading}
                  size="lg"
                  className={styles.cta}
                />
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          id={'newsletter-modal'}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={t(
            'Grazie per aver scelto la nostra mini-guida, ora puoi diventare un vero pro del verde!'
          )}
          description={t(
            'Controlla la casella di posta. Ti abbiamo inviato il documento via email, così puoi averlo sempre con te! <br/>Puoi consultarlo in formato digitale o stamparlo.'
          )}
          cta={
            <Button
              label={t('Scopri le novità del mondo {0}', [process.env.BRAND])}
              href={`/`}
              onClick={() => setModalOpen(false)}
            />
          }
          className={`${styles.modal} ${className}`}
        />
      </WContainer>
    </div>
  )
}

export default LandingNewsletterGiardinaggioMiniguidaForm
