import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { Gallery as Original } from 'components/organisms'
import { GallerySettings, GalleryFloatingSettings } from './GallerySettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { getTextEditor } from '../TextEditor'
import { getItemChildren } from 'react-pagebuilder/utils/node'
import { gallerySlideItemParams } from '../GallerySlide'

export const defaultProps = {
  theme: 'light',
}

export const galleryItemParams = (enabled, id, blockProps, children) => ({
  pk: id,
  name: getTextEditor(children?.[0], enabled),
  description: getTextEditor(children?.[1], enabled),
  children: getItemChildren(enabled, children?.[2], gallerySlideItemParams),
})

export const dynamicProps = []

export const Gallery: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-gallery ${props.className}`}>
        <Original
          item={galleryItemParams(enabled, id, blockProps, children)}
          theme={blockProps.theme}
          editMode={enabled}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Gallery.craft = withCraft({
  name: 'Gallery',
  defaultProps: defaultProps,
  settings: GallerySettings,
  floatingSettings: GalleryFloatingSettings,
})
