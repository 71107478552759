/* eslint-disable unicorn/no-object-as-default-parameter */
import { api } from 'utils'
import { TRADUZIONI } from 'utils/endpoints'

export const fetchTraduzioni = (locale: string) => {
  const payload = api
    .get(TRADUZIONI, { params: { lang: locale } })
    .catch((error) => console.log(error))

  return {
    type: 'FETCH_TRADUZIONI',
    payload,
  }
}

export default function reducer(
  state = {
    traduzioni: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_TRADUZIONI_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_TRADUZIONI_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_TRADUZIONI_FULFILLED': {
      const traduzioni = action.payload.data

      return {
        ...state,
        fetching: false,
        fetched: true,
        traduzioni,
      }
    }

    default: {
      return state
    }
  }
}
