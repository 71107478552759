import React, { useEffect } from 'react'
import useTrans from '@hooks/useTrans'
import { RES_MD_MIN } from '@utils/settings'
import { ArrowRight, Close, MailLine, PhoneLine } from '@components/icons'
import { Button, Chip, CloseBar, Tag, WModal } from '@components/atoms'
import styles from './RivenditoreModal.module.sass'
import { RivenditoreStoreLocatorNode } from '@gql/graphql-get'
import { slugify } from '@utils/index'

interface Props {
  rivenditore?: RivenditoreStoreLocatorNode
  open?: boolean
  onClose?: () => void
  className?: string
}

const RivenditoreModal = (props: Props) => {
  const { rivenditore = undefined, open = false, onClose = undefined, className = '' } = props

  const t = useTrans()
  const details = [
    {
      label: t('Telefono'),
      icon: <PhoneLine />,
      value: rivenditore?.telefono ? (
        <a href={`tel:${rivenditore.telefono}`}>{rivenditore.telefono}</a>
      ) : undefined,
    },
    {
      label: t('Email'),
      icon: <MailLine />,
      value: rivenditore?.email ? (
        <a href={`mailto:${rivenditore.email}`}>{rivenditore.email}</a>
      ) : undefined,
    },
  ].filter((x) => !!x.value)
  const items = rivenditore?.tags

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= RES_MD_MIN) onClose()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return rivenditore ? (
    <WModal
      id="rivendiore_modal"
      open={open}
      onClose={onClose}
      className={`${styles.root} ${className}`}
    >
      <CloseBar onClose={onClose} className={styles.closeBar} />
      <div className={styles.layout}>
        <div className={styles.tags}>
          {(rivenditore.tipi.includes('rivenditore') || rivenditore.tipi.includes('b2b')) && (
            <Tag label={t('Punto vendita')} variant={'categoria'} size="sm" />
          )}
          {rivenditore.tipi.includes('assistenza') && rivenditore.tipi.length == 1 && (
            <Tag label={t('Centro assistenza')} variant={'categoria'} size="sm" />
          )}
          {rivenditore.tipi.includes('rivenditore') && (
            <Tag label={t('Vendita online')} variant="novita" size="sm" />
          )}
        </div>
        <div className={styles.main}>
          <button type="button" aria-label={t('Chiudi')} className={styles.close} onClick={onClose}>
            <Close />
          </button>
          <div className={styles.content}>
            {!!rivenditore.ragioneSociale && (
              <h3 className={styles.title}>{rivenditore.ragioneSociale}</h3>
            )}
            {!!items.length && (
              <div className={styles.list}>
                {items.map((x) => (
                  <Chip label={t(x)} size="sm" />
                ))}
              </div>
            )}
            {(!!rivenditore.indirizzo ||
              !!rivenditore.cap ||
              !!rivenditore.citta ||
              !!rivenditore.provincia) && (
              <div className={styles.indirizzo}>
                {rivenditore.indirizzo}
                {!!rivenditore.cap && (
                  <>
                    {rivenditore.indirizzo ? <>, </> : <></>} {rivenditore.cap}
                  </>
                )}
                {!!rivenditore.citta && (
                  <>
                    {rivenditore.indirizzo || rivenditore.cap ? <>, </> : <></>}
                    {rivenditore.citta}
                  </>
                )}
                {rivenditore.provincia ? <> ({rivenditore.provincia})</> : <></>}
              </div>
            )}
            {!!details?.length && (
              <div className={styles.details}>
                {details.map((x) => (
                  <div className={styles.detailItem}>
                    <span className={styles.detailIcon} aria-label={x.label}>
                      {x.icon}
                    </span>
                    <span className={styles.detailValue}>{x.value}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Button
          label={t('Vedi dettagli')}
          iconRight={<ArrowRight />}
          variant="secondary"
          href={rivenditore.url}
          className={styles.cta}
        />
      </div>
    </WModal>
  ) : (
    <></>
  )
}

export default RivenditoreModal
