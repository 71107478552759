import React, { useState } from 'react'
import Slider from 'rc-slider'
import styles from './FiltroSlider.module.sass'
import useTrans from '@hooks/useTrans'
import { Accordion, Button } from '@components/atoms'
import { handleListingFilterTracking } from '@utils/tracking'

interface Props {
  id: number | string
  label?: string
  min: number
  max: number
  formatFunc?: (v: any) => string
  valueMin?: number
  valueMax?: number
  onChange?: (e: any) => void
  className?: string
}

const FiltroSlider = (props: Props) => {
  const {
    id = undefined,
    label = undefined,
    min = 0,
    max = 100,
    formatFunc = undefined,
    valueMin = undefined,
    valueMax = undefined,
    onChange = () => {},
    className = '',
  } = props

  const t = useTrans()

  const formatValue = (v) => (formatFunc ? formatFunc(v) : v)
  const [values, setValues] = useState<number[]>([valueMin || min, valueMax || max])

  return (
    <Accordion
      id={`filtroslider_${id}`}
      variant="filter"
      label={label}
      className={`${styles.root} ${className}`}
    >
      <div className={styles.content}>
        <Slider
          range
          min={min}
          max={max}
          value={values}
          onChange={(v: number[]) => {
            setValues(v)
          }}
          onChangeComplete={(v: number[]) => handleListingFilterTracking('prezzo', 'prezzo', false)}
        />
        <div className={styles.bottom}>
          <span className={styles.values}>
            {formatValue(values[0])} - {formatValue(values[1])}
          </span>
          <Button label={t('Applica')} variant="ghost" onClick={() => onChange(values)} />
        </div>
      </div>
    </Accordion>
  )
}

export default FiltroSlider
