import React from 'react'
import styles from './OrdiniEmptyBox.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  variant?: 'light' | 'dark'
  className?: string
}

const OrdiniEmptyBox = (props: Props) => {
  const { variant = 'light', className = '' } = props

  const t = useTrans()

  return (
    <div className={`${styles.root} ${styles[`root--${variant}`]} ${className}`}>
      <h2 className={styles.title}>{t('La tua lista degli ordini è vuota')}</h2>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t('myaccount empty_orders_description') }}
      />
    </div>
  )
}

export default OrdiniEmptyBox
