import React from 'react'
import styles from './CheckoutRiepilogoProdottoCard.module.sass'
import { prezziProdotto, priceNotation } from '@utils/safe'
import { WImage } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { ProdottoNode } from '@gql/graphql-get'

interface Props {
  prodotto?: ProdottoNode
  quantita?: number
  className?: string
}

const CheckoutRiepilogoProdottoCard = (props: Props) => {
  const { prodotto = undefined, quantita = undefined, className = '' } = props

  const t = useTrans()
  const pp = prezziProdotto(prodotto)
  const prezzi = [
    { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
    { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
    {
      key: 'current',
      label: prodotto.promo ? t('Promo') : prodotto.lancio ? t('Prezzo lancio') : '',
      value: pp?.prezzoFinale,
    },
  ].filter((x) => !!x.value)

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.imageBox}>
        <WImage src={prodotto.previewImage} maxWidth={64} maxHeight={64} objectFit="contain" />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          {prodotto.categoria && (
            <span className={styles.category}>{prodotto.categoria.boxTitolo}</span>
          )}
          <h2 className={styles.title}>{prodotto.nome}</h2>
        </div>
        <div className={styles.details}>
          <div className={styles.quantity}>
            <span className={styles.quantityLabel}>{t('Quantità')}: </span>
            <span className={styles.quantityValue}>{quantita}</span>
          </div>
          {!!prezzi?.length && (
            <div className={styles.prices}>
              {prezzi.map((p) => (
                <div className={`${styles.price} ${styles[`price--${p.key}`]}`} key={p.key}>
                  {!!p.label && <span className={styles.priceLabel}>{p.label}: </span>}
                  <span className={styles.priceValue}>{priceNotation(p.value)}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckoutRiepilogoProdottoCard
