import React from 'react'
import styles from './CloseBar.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  onClose?: () => void
  className?: string
}

const CloseBar = (props: Props) => {
  const { onClose = undefined, className = '' } = props

  const t = useTrans()

  return (
    <button
      className={`${styles.root} ${className}`}
      type="button"
      aria-label={t('Chiudi')}
      onClick={onClose}
    ></button>
  )
}

export default CloseBar
