import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Position({ idVariant = '', className }: Props) {
  return (
    <IconBase name="position" w={24} className={className}>
      <path
        d="M13 1L13.001 4.06201C16.6192 4.51365 19.4869 7.38163 19.9381 11H23V13L19.938 13.001C19.4864 16.6189 16.6189 19.4864 13.001 19.938L13 23H11V19.9381C7.38163 19.4869 4.51365 16.6192 4.06201 13.001L1 13V11H4.06189C4.51312 7.38129 7.38129 4.51312 11 4.06189V1H13ZM12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Position
