import { ToolbarItem } from './ToolbarItem'
import { ToolbarSection } from './ToolbarSection'

export type PagebuilderImageItemType = {
  alt?: string
  image?: string
  imageTablet?: string
  imageMobile?: string
}

export const imageDefaultProps = (mobile: boolean = false, tablet: boolean = false) => ({
  ...(mobile ? { srcMobile: '' } : {}),
  ...(tablet ? { srcTablet: '' } : {}),
  src: '',
  alt: '',
})

export const imageItemParams: (props?: any) => PagebuilderImageItemType = (props) => ({
  ...('srcMobile' in props
    ? {
        imageMobile: props.srcMobile,
      }
    : {}),
  ...('srcTablet' in props
    ? {
        imageTablet: props.srcTablet,
      }
    : {}),
  alt: props.alt,
  image: props.src,
})

const ImageToolbarSection = ({ defaultProps, initOpen = true }) => {
  return (
    <ToolbarSection title="Immagine" initOpen={initOpen}>
      <ToolbarItem
        propKey="block"
        propSubKey="alt"
        type="text"
        label="Alt Text"
        defaultValue={defaultProps['alt']}
      />
      <ToolbarItem
        propKey="block"
        propSubKey="src"
        type="text"
        label="Desktop"
        description="Paste your url or upload a file"
        defaultValue={defaultProps['src']}
      />
      <ToolbarItem propKey="block" propSubKey="src" type="image" />
      {'srcTablet' in defaultProps && (
        <>
          <ToolbarItem
            propKey="block"
            propSubKey="srcTablet"
            type="text"
            label="Tablet"
            description="Paste your url or upload a file"
            defaultValue={defaultProps['srcTablet']}
          />
          <ToolbarItem propKey="block" propSubKey="srcTablet" type="image" />
        </>
      )}
      {'srcMobile' in defaultProps && (
        <>
          <ToolbarItem
            propKey="block"
            propSubKey="srcMobile"
            type="text"
            label="Mobile"
            description="Paste your url or upload a file"
            defaultValue={defaultProps['srcMobile']}
          />
          <ToolbarItem propKey="block" propSubKey="srcMobile" type="image" />
        </>
      )}
    </ToolbarSection>
  )
}

export default ImageToolbarSection
