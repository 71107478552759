import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import useUtente from '@hooks/useUtente'

const Omnisend = () => {
  const router = useRouter()
  const { utente } = useUtente()
  const mounted = useRef(false)
  const [omnisendLoaded, setOmnisendLoaded] = useState(false)

  const omnisend_enabled =
    process.env.NODE_ENV === 'production' &&
    (process.env.SITE_ID === '1' || process.env.SITE_ID === '2') &&
    router.locale === 'it-it'

  if (!omnisend_enabled) return null

  useEffect(() => {
    if (utente && omnisendLoaded && !mounted.current) {
      mounted.current = true
      if (window.omnisend?.identifyContact) {
        window.omnisend.identifyContact({ email: utente.email })
      } else {
        console.warn('Omnisend is not fully loaded yet')
      }
    }
  }, [utente, omnisendLoaded])

  useEffect(() => {
    window.omnisend = window.omnisend || []
    window.omnisend.push(['brandID', process.env.OMNISEND_ACCOUNT_ID])
    window.omnisend.push(['track', '$pageViewed'])

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://omnisnippet1.com/inshop/launcher-v2.js'

    script.onload = () => {
      setOmnisendLoaded(true)
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default Omnisend
