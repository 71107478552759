import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  InteresseNewsletterNode,
  useMyAccountEditMutation,
  useProfiloUtenteQuery,
  UtenteNode,
} from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import {
  Button,
  Feedback,
  FormCheckbox,
  FormError,
  FormField,
  FormFieldset,
  FormikField,
  FormLayout,
  FormText,
} from '@components/atoms'
import styles from './MyAccountProfiloForm.module.sass'
import { omit } from 'lodash'
import { myAccountProfileEditValidationSchema } from '@utils/validationSchemas'
import { useRouter } from 'next/router'

interface Props {
  profiloUtente?: UtenteNode
  interessiUtente?: InteresseNewsletterNode[]
  className?: string
}

const MyAccountProfiloForm = (props: Props) => {
  const { interessiUtente = [], className = '' } = props

  const t = useTrans()
  const router = useRouter()
  const { data, loading: utenteLoading } = useProfiloUtenteQuery()
  const [globalError, setGlobalError] = useState(undefined)
  const [globalLoading, setGlobalLoading] = useState(false)
  const profiloUtente = data?.me

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [myAccountEdit] = useMyAccountEditMutation({
    onCompleted: (res) => {
      if (!res.myAccountEdit?.status) {
        raiseGlobalError(res?.myAccountEdit?.message || t('Errore durante il salvataggio'))
      } else router.push('/myaccount/profile')
    },
    onError: () => {
      // Errore non previsto - generico
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  const tipoUtenteOpts = [
    { label: t('Privato'), value: 'privato' },
    { label: t('Azienda'), value: 'azienda' },
  ]

  const categoriaUtenteOpts = [
    {
      label: t('Privato'),
      description: t('Sono un privato con la passione per la cura del verde'),
      value: 'privato',
    },
    {
      label: t('Professionista'),
      description: t('Sono un professionista della cura del verde e/o forestale'),
      value: 'professionista',
    },
  ]
  const ambienteLavoroOpts =
    interessiUtente?.map((interesse) => {
      return {
        label: interesse.alias,
        description: interesse.descrizione,
        value: interesse.pk.toString(),
      }
    }) || []

  const initialValues = {
    email: profiloUtente?.email,
    tipoUtente: profiloUtente?.privato ? tipoUtenteOpts[0].value : tipoUtenteOpts[1].value,
    nome: profiloUtente?.nome,
    cognome: profiloUtente?.cognome,
    cf: profiloUtente?.cf,
    ragioneSociale: profiloUtente?.ragioneSociale,
    piva: profiloUtente?.piva,
    sdi: profiloUtente?.sdi,
    noSdi: !profiloUtente?.privato && !profiloUtente?.sdi && !!profiloUtente?.pec,
    pec: profiloUtente?.pec,
    categoriaUtente:
      profiloUtente?.professionista === false
        ? categoriaUtenteOpts[0].value
        : profiloUtente?.professionista === true
        ? categoriaUtenteOpts[1].value
        : undefined,
    dataDiNascita: profiloUtente?.dataDiNascita,
    ambienteLavoro: profiloUtente?.interessi?.[0]?.pk?.toString() || undefined,
  }

  return !utenteLoading ? (
    <div className={`${styles.root} ${className}`}>
      {!profiloUtente ? (
        <Feedback
          status="error"
          title={t('Non è stato possibile caricare i dati utente')}
          description={t(
            'Errore durante il recupero dei dati utente, provare a ricaricare la pagina'
          )}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={myAccountProfileEditValidationSchema(t)}
          onSubmit={(values) => {
            setGlobalLoading(true)
            myAccountEdit({
              variables: {
                input: {
                  ...omit(values, ['ambienteLavoro', 'tipoUtente', 'categoriaUtente', 'noSdi']),
                  interessi: values.ambienteLavoro ? [parseInt(values.ambienteLavoro)] : [],
                  privato: values.tipoUtente === 'privato',
                  professionista: values.categoriaUtente === 'professionista',
                },
              },
            })
          }}
        >
          {(formikProps) => (
            <Form className={styles.form}>
              <FormError
                id="profile_global_error"
                message={globalError}
                visible={!!globalError}
                variant="global"
              />
              <div className={styles.fieldsets}>
                <FormLayout space="lg">
                  <FormikField
                    formik={formikProps}
                    id={'profilo_tipo_utente'}
                    name="tipoUtente"
                    type="radio"
                    variant="horizontal"
                    options={tipoUtenteOpts}
                    required
                    layout="full"
                  />
                  <FormikField
                    formik={formikProps}
                    id={'profilo_nome'}
                    name="nome"
                    type="text"
                    label={t('Nome')}
                    placeholder={t('Inserisci il tuo nome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'profilo_cognome'}
                    name="cognome"
                    type="text"
                    label={t('Cognome')}
                    placeholder={t('Inserisci il tuo cognome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'profilo_email'}
                    name="email"
                    type="email"
                    label={t('Indirizzo email')}
                    placeholder={t('Inserisci il tuo indirizzo email')}
                    required
                  />
                  {formikProps.values.tipoUtente === 'azienda' && (
                    <FormikField
                      formik={formikProps}
                      id={'profilo_ragione_sociale'}
                      name="ragioneSociale"
                      type="text"
                      label={t('Ragione sociale')}
                      placeholder={t('Inserisci la tua ragione sociale')}
                      required
                    />
                  )}
                  <FormikField
                    formik={formikProps}
                    id={'profilo_cf'}
                    name="cf"
                    type="text"
                    label={t('Codice fiscale')}
                    placeholder={t('Inserisci il tuo codice fiscale')}
                    required
                  />
                  {formikProps.values.tipoUtente === 'azienda' && (
                    <>
                      <FormikField
                        formik={formikProps}
                        id={'profilo_piva'}
                        name="piva"
                        type="text"
                        label={t('Partita IVA')}
                        placeholder={t('Inserisci il tuo numero di partita IVA')}
                        required
                      />
                      <FormField
                        id={'profilo_sdi'}
                        label={t('SDI')}
                        required={!formikProps.values.noSdi}
                        childrenLayout="column-sm"
                        className={styles.sdiField}
                      >
                        <FormText
                          id={'profilo_sdi'}
                          name="sdi"
                          type="text"
                          placeholder={t('Inserisci il tuo codice SDI')}
                          required={!formikProps.values.noSdi}
                          disabled={formikProps.values.noSdi}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          status={
                            !formikProps.values.noSdi && formikProps.touched?.['sdi']
                              ? formikProps.errors?.['sdi']
                                ? 'error'
                                : 'success'
                              : ''
                          }
                          value={formikProps.values.sdi}
                          errorMessage={formikProps.errors?.['sdi']}
                        />
                        <FormCheckbox
                          id={'profilo_no_sdi'}
                          label={t('Non ho il codice SDI')}
                          name="noSdi"
                          value="1"
                          checked={formikProps.values.noSdi}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                        />
                      </FormField>
                      <FormikField
                        formik={formikProps}
                        id={'profilo_pec'}
                        name="pec"
                        type="email"
                        label={t('PEC')}
                        placeholder={t('Inserisci la tua PEC')}
                        required={formikProps.values.noSdi}
                      />
                    </>
                  )}
                </FormLayout>
                <FormFieldset title={t('Dati di profilazione')}>
                  <FormLayout space="lg" className={styles.sectionProfile}>
                    <FormikField
                      id="profilo_categoria_utente"
                      formik={formikProps}
                      type="radio"
                      name="categoriaUtente"
                      label={t('Sei un privato o un professionista?')}
                      options={categoriaUtenteOpts}
                      layout="full"
                      fieldVariant="box"
                      space="md"
                      variant={'horizontal-half'}
                    />
                    <FormikField
                      id="profilo_data_di_nascita"
                      formik={formikProps}
                      type="date"
                      name="dataDiNascita"
                      label={t('Qual è la tua data di nascita?')}
                      fieldVariant="box"
                      space="md"
                    />
                    <FormikField
                      id="profilo_ambiente_lavoro"
                      formik={formikProps}
                      type="radio"
                      name="ambienteLavoro"
                      label={t('Qual è il tuo ambiente di lavoro?')}
                      options={ambienteLavoroOpts}
                      layout="full"
                      fieldVariant="box"
                      space="md"
                      variant={'horizontal-half'}
                    />
                  </FormLayout>
                </FormFieldset>
              </div>
              <div className={styles.ctaBox}>
                <Button
                  type="submit"
                  label={t('Salva le modifiche')}
                  size="lg"
                  loading={globalLoading}
                />
                <Button
                  label={t('Annulla')}
                  variant="secondary"
                  href={'/myaccount/profile'}
                  size="lg"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  ) : (
    <></>
  )
}

export default MyAccountProfiloForm
