import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { API_HOST, IS_EXPORT, toAltTitle } from 'utils'
import styles from './WImage.module.sass'
import Skeleton from 'react-loading-skeleton'

interface Props {
  next?: boolean // Se utilizzare next o meno, default true
  src?: string
  alt?: string
  title?: string
  className?: string
  maxWidth: number
  maxHeight: number
  blur?: boolean
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  objectPosition?: string
  priority?: false | true
  loading?: 'lazy' | 'eager'
  quality?: number
  fallback?: string // object
  editMode?: boolean
  style?: any
  staticMedia?: boolean
  skeleton?: boolean
}

export const getFinalUrl = (url: string, staticMedia: boolean) => {
  // se è relativo, aggiungo dominio corrente
  if (!staticMedia && url?.startsWith('/')) {
    return API_HOST + url
  }

  return url
}

const WImage = (props: Props) => {
  const {
    next = true,
    src = '',
    alt = '',
    title = '',
    maxWidth = 0,
    maxHeight = 0,
    className = '',
    objectFit = undefined,
    objectPosition = undefined,
    blur = false,
    priority = false,
    loading = 'lazy',
    quality = 75,
    fallback = undefined,
    style = undefined,
    staticMedia = false,
    skeleton = false,
  } = props

  const [imgSrc, setImgSrc] = useState(getFinalUrl(src, staticMedia))
  const fallbackImage = fallback || `https://placehold.co/${maxWidth}x${maxHeight}/png`

  // dal pagebuilder src può cambiare, va tenuto aggiornato
  useEffect(() => {
    const getPagebuilderExportUrl = async (src: string) => {
      let finalUrl = src
      if (IS_EXPORT && src.includes('/filer/canonical/')) {
        try {
          await fetch(API_HOST + src).then((response) => {
            if (response.redirected) finalUrl = response.url
          })
        } catch (e) {
          console.error(e)
        }
      }
      return finalUrl
    }

    getPagebuilderExportUrl(src).then((url) => setImgSrc(getFinalUrl(url, staticMedia)))
  }, [src])

  if (skeleton) return <Skeleton height={maxHeight} />

  return next ? (
    <Image
      src={imgSrc || fallbackImage}
      alt={toAltTitle(alt)}
      title={toAltTitle(title)}
      width={maxWidth}
      height={maxHeight}
      priority={priority}
      loading={loading}
      quality={quality}
      placeholder={blur ? 'blur' : undefined}
      blurDataURL={blur ? `/_next/image?url=${imgSrc || fallbackImage}&w=32&q=70` : undefined}
      onError={() => {
        setImgSrc(fallbackImage)
      }}
      className={`${styles.root} ${
        objectFit ? styles[`root--objectFit-${objectFit}`] : ''
      } ${className}`}
      style={
        style || objectPosition
          ? { ...(style || {}), ...(objectPosition ? { objectPosition: objectPosition } : {}) }
          : undefined
      }
    />
  ) : (
    <img
      src={src || fallbackImage}
      alt={toAltTitle(alt)}
      title={toAltTitle(title)}
      className={className}
      style={style}
    />
  )
}

export default WImage
