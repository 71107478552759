import { ToolbarItem } from './ToolbarItem'
import { ToolbarSection } from './ToolbarSection'

export type PagebuilderLayoutType = {
  spaceTop?: 'none' | 'sm' | 'md' | 'lg'
  spaceBottom?: 'none' | 'sm' | 'md' | 'lg'
  borderTop?: boolean
  borderBottom?: boolean
}

export const layoutDefaultProps = (space = undefined, border = undefined) => ({
  ...(typeof space !== 'undefined'
    ? {
        spaceTop: space,
        spaceBottom: space,
      }
    : {}),
  ...(typeof border !== 'undefined'
    ? {
        borderTop: border,
        borderBottom: border,
      }
    : {}),
})

export const layoutItemParams: (props?: any) => PagebuilderLayoutType = (props) => ({
  spaceTop: props.spaceTop,
  spaceBottom: props.spaceBottom,
  borderTop: props.borderTop,
  borderBottom: props.borderBottom,
})

const LayoutToolbarSection = ({ defaultProps, initOpen = false }) => {
  const spaceOpts = [
    { label: 'Nessuno', value: 'none' },
    { label: 'Piccolo', value: 'sm' },
    { label: 'Medio', value: 'md' },
    { label: 'Grande', value: 'lg' },
  ]

  return (
    <ToolbarSection title="Layout" initOpen={initOpen}>
      {'spaceTop' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="spaceTop"
          type="select"
          options={spaceOpts}
          label="Spazio sopra"
          defaultValue={defaultProps['spaceTop']}
        />
      )}
      {'spaceBottom' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="spaceBottom"
          type="select"
          options={spaceOpts}
          label="Spazio sotto"
          defaultValue={defaultProps['spaceBottom']}
        />
      )}
      {'borderTop' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="borderTop"
          type="switch"
          label="Bordo sopra"
          defaultValue={defaultProps['borderTop']}
        />
      )}
      {'borderBottom' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="borderBottom"
          type="switch"
          label="Bordo sotto"
          defaultValue={defaultProps['borderBottom']}
        />
      )}
    </ToolbarSection>
  )
}

export default LayoutToolbarSection
