import React from 'react'
import { LogoBertolini, LogoEfco, LogoNibbi, LogoOleomac } from '@components/icons'

const loghi = {
  1: <LogoEfco />,
  2: <LogoOleomac />,
  3: <LogoBertolini />,
  4: <LogoNibbi />,
}

const Logo = () => {
  return loghi[process.env.SITE_ID]
}

export default Logo
