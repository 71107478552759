import React, { useContext, useEffect, useState } from 'react'
import { ProdottoNode, useChangeProdottoCardLazyQuery } from '@gql/graphql'
import {
  Button,
  ButtonIcon,
  CloseBar,
  Dropdown,
  FormRadio,
  Tag,
  WImage,
  WishlistButton,
  WLink,
  WModal,
} from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { Battery, Cart, Close, Plus } from '@components/icons'
import { prezziProdotto, priceNotation } from '@utils/safe'
import styles from './ProdottoCard.module.sass'
import { CartContext } from '@utils/cartContext'
import useComparatore from '@hooks/useComparatore'
import { RES_MD_MIN } from '@utils/settings'
import { useRouter } from 'next/router'
import { isEcommerce, isFiliale, isInternational } from '@utils/index'
import Skeleton from 'react-loading-skeleton'
import { handleAddToCartTracking, handleProductClickTracking } from '@utils/tracking'

interface Props {
  prodotto?: ProdottoNode
  loading?: boolean
  inSlider?: boolean // se è nello slider imposto la selezione del prodotto con la tendina in alto, altrimenti viene tagliata
  compareMode?: boolean
  compare?: boolean
  setCompare?: () => void
  correlati?: boolean
  appliedFilters?: boolean
  order?: string
  className?: string
}

const ProdottoCard = (props: Props) => {
  const {
    prodotto: item,
    inSlider = false,
    compareMode = false,
    compare = false,
    setCompare = undefined,
    loading = false,
    correlati = false,
    appliedFilters = false,
    order,
    className = '',
  } = props

  const { cart, addToCart: contextAddToCart } = useContext(CartContext)

  const router = useRouter()
  const ecommerce = isEcommerce(router.locale)
  const filiale = isFiliale(router.locale)
  const international = isInternational(router.locale)

  const [prodotto, setProdotto] = useState(item)

  const { removeItem, addItem } = useComparatore(prodotto?.categoriaComparatore?.pk)

  const [getProdotto, { loading: loadingChangeProdotto }] = useChangeProdottoCardLazyQuery({
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (item) {
      setProdotto({ ...item, ...prodotto })
    }
  }, [item])

  const t = useTrans()
  const [variantsModalOpen, setVariantModalOpen] = useState(false)

  const pp = prezziProdotto(prodotto)
  const prezzi = [
    ...(filiale || ecommerce ? [{ key: 'current', value: pp.prezzoFinale }] : []),
    ...(filiale && pp?.prezzoListino && pp?.prezzoListino !== pp.prezzoFinale
      ? [{ key: 'listino', label: t('Listino'), value: pp?.prezzoListino }]
      : []),
    ...(ecommerce
      ? [
          { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
          { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
        ]
      : []),
  ].filter((x) => !!x.value)

  const variantiOpts =
    prodotto.prodottiTendina?.map((x) => ({
      value: x.pk,
      label: x.labelTendina,
      price: x.prezzo,
      selected: x.pk === prodotto.pk,
    })) || []

  const [cartLoading, setCartLoading] = useState(false)

  const addToCart = async () => {
    setCartLoading(true)
    await contextAddToCart(prodotto.pk, 1)

    handleAddToCartTracking(prodotto, cart, order?.toLowerCase())
    setCartLoading(false)
  }

  const prodottoBatteria = prodotto.categoria?.alias?.toLowerCase()?.includes('batteria')

  async function changeProdotto(pk: number) {
    const {
      data: { prodottoByPk, prezziProdotti },
    } = await getProdotto({ variables: { pk, ids: [pk] } })

    setProdotto({ ...prodotto, ...prodottoByPk, ...prezziProdotti?.[0] })
  }

  const handleVariants = () => {
    if (window.innerWidth < RES_MD_MIN) {
      setVariantModalOpen(true)
      return false
    }
    return true
  }

  const skeleton = loading || loadingChangeProdotto

  const compareEnabled = !prodotto.isAccessorio && !prodotto.isAbbigliamento

  return prodotto ? (
    <div className={`${styles.root} ${inSlider ? styles['root--inSlider'] : ''} ${className}`}>
      <div className={styles.head}>
        <div className={styles.tags}>
          {prodotto.novita && <Tag variant="black" label={t('Novità')} />}
          {prodotto.promo || prodotto.inPromoBundle ? (
            <Tag
              variant="promo"
              label={t(prodotto.inPromoBundle ? 'Promo bundle' : 'Promo')}
              helper={{
                id: `prodottocard_promo_helper_${prodotto.pk}`,
                label: prodotto.inPromoBundle ? '' : t('prodottocard_promo_helper'),
              }}
            />
          ) : prodotto.lancio ? (
            <Tag variant="promo" label={t('Prezzo lancio')} />
          ) : (
            <></>
          )}
        </div>
        {compareMode && compareEnabled ? (
          <ButtonIcon
            icon={compare ? <Close /> : <Plus />}
            color={compare ? 'black' : 'primary'}
            className={styles.compare}
            onClick={() => (compare ? removeItem(prodotto) : addItem(prodotto))}
          />
        ) : ecommerce ? (
          <WishlistButton prodotto={prodotto} cart={cart} className={styles.fav} />
        ) : null}
      </div>
      <WLink
        href={prodotto.url}
        className={styles.imageBox}
        onClick={() => handleProductClickTracking(prodotto, order?.toLowerCase())}
      >
        <WImage
          src={prodotto.previewImage}
          maxWidth={300}
          maxHeight={skeleton ? 176 : 180}
          objectFit="contain"
          skeleton={skeleton}
          alt={`${prodotto.categoria?.boxTitolo} ${prodotto.nome}`}
        />
      </WLink>
      <div className={styles.main}>
        <div className={styles.utilizzo}>
          {prodotto.utilizzo && (
            <Tag variant="categoria" label={prodotto.utilizzo.toUpperCase()} skeleton={skeleton} />
          )}
        </div>
        <h2 className={styles.heading}>
          {prodotto.categoria && (
            <span
              className={`${styles.category} ${
                prodottoBatteria ? styles['category--battery'] : ''
              }`}
            >
              <span className={styles.categoryLabel}>
                {skeleton ? <Skeleton /> : prodotto.categoria.boxTitolo}
              </span>
              {prodottoBatteria && !skeleton && (
                <span className={styles.categoryIcon}>
                  <Battery />
                </span>
              )}
            </span>
          )}
          <WLink
            href={prodotto.url}
            className={styles.titleLink}
            onClick={() => handleProductClickTracking(prodotto)}
          >
            <p className={styles.title}>{skeleton ? <Skeleton /> : prodotto.nome}</p>
          </WLink>
        </h2>
        {!!prodotto.details?.length &&
          (skeleton ? (
            <Skeleton count={prodotto.details?.length} />
          ) : (
            <ul className={styles.details}>
              {prodotto.details.map((d) => (
                <li key={d}>{d}</li>
              ))}
            </ul>
          ))}
      </div>
      <div className={styles.bottom}>
        {(!ecommerce || prodotto.disponibile) && !!prezzi?.length && (
          <div className={styles.prices}>
            {prezzi.map((p) => (
              <span key={p.key} className={`${styles.price} ${styles[`price--${p.key}`]}`}>
                {!!p.label && (
                  <span className={styles.priceLabel}>{skeleton ? <Skeleton /> : p.label} </span>
                )}
                <span className={styles.priceValue}>
                  {skeleton ? (
                    <Skeleton />
                  ) : (
                    priceNotation(p.value, false, prodotto.valuta?.simbolo)
                  )}
                </span>
              </span>
            ))}
          </div>
        )}
        {(ecommerce || !international) && !prodotto.disponibile && (
          <div className={styles.noBuy}>
            {ecommerce && (
              <p>
                {prodotto.stato?.codice === 'non-disponibile'
                  ? t('Articolo fuori produzione')
                  : t('Non acquistabile online')}
              </p>
            )}
            {!international && (
              <WLink href="/store-locator">{t('Trova il rivenditore più vicino')}</WLink>
            )}
          </div>
        )}

        {prodotto.disponibile &&
          !!prodotto.prodottiTendina?.length &&
          prodotto.prodottiTendina?.length > 1 && (
            <div className={styles.variants}>
              {prodotto.showTaglia ? (
                <div className={styles.size}>
                  <label className={styles.sizeLabel}>{t('Seleziona una taglia:')}</label>
                  <Dropdown
                    id={`prodottocard_taglieselect_${prodotto.pk}`}
                    value={prodotto.pk}
                    options={variantiOpts}
                    direction={inSlider ? 'top' : undefined}
                    onChange={(v: number) => changeProdotto(v)}
                    onClick={handleVariants}
                    disabled={compareMode}
                  />
                </div>
              ) : (
                <Dropdown
                  id={`prodottocard_variantiselect_${prodotto.pk}`}
                  value={prodotto.pk}
                  options={variantiOpts}
                  direction={inSlider ? 'top' : undefined}
                  onChange={(v: number) => changeProdotto(v)}
                  onClick={handleVariants}
                  disabled={compareMode}
                />
              )}
            </div>
          )}

        {prodotto.disponibile && ecommerce ? (
          <Button
            label={t('Aggiungi al carrello')}
            iconRight={<Cart />}
            disabled={compareMode}
            onClick={addToCart}
            className={styles.cta}
            loading={loadingChangeProdotto || loading || cartLoading}
          />
        ) : (
          <Button
            label={t('Dettagli prodotto')}
            variant="secondary"
            href={compareMode ? undefined : prodotto.url}
            onClick={() => handleProductClickTracking(prodotto)}
            className={styles.cta}
            disabled={compareMode}
            loading={loadingChangeProdotto || loading || cartLoading}
          />
        )}
      </div>
      <WModal
        id={`prodottocard_variants_${prodotto.pk}`}
        open={variantsModalOpen}
        ariaLabelledby={`prodottocard_variants_${prodotto.pk}_title`}
        onClose={() => setVariantModalOpen(false)}
        className={styles.variantsModal}
      >
        <CloseBar
          onClose={() => setVariantModalOpen(false)}
          className={styles.variantModalCloseBar}
        />
        <div className={styles.variantsModalHead}>
          <p className={styles.variantsModalTitle}>
            {t(prodotto.showTaglia ? 'Seleziona una taglia' : 'Seleziona la tipologia')}
          </p>
          <button
            type="button"
            className={styles.variantsModalClose}
            onClick={() => setVariantModalOpen(false)}
          >
            <Close />
          </button>
        </div>
        <div className={styles.variantsModalList}>
          <FormRadio
            id={`prodottocard_variants_${prodotto.pk}_item`}
            variant="prodottoCard"
            value={prodotto.pk}
            onChange={(e) => changeProdotto(parseInt(e.target.value))}
            options={variantiOpts.map((x) => ({
              ...x,
              id: `prodottocard_variants_${prodotto.pk}_item_${x.value}`,
            }))}
          />
        </div>
        <div className={styles.variantsModalFooter}>
          <Button
            label={t('Aggiungi al carrello')}
            iconRight={<Cart />}
            onClick={addToCart}
            className={styles.variantsModalCta}
            loading={loadingChangeProdotto || loading}
          />
        </div>
      </WModal>
    </div>
  ) : (
    <></>
  )
}

export default ProdottoCard
