import React, { useEffect } from 'react'
import { CloseBar, WModal } from '@components/atoms'
import styles from './CheckoutRiepilogoDrawer.module.sass'
import useTrans from '@hooks/useTrans'
import { Close } from '@components/icons'
import { CheckoutRiepilogoCosti, RigaCheckoutCard } from '@components/molecules'
import { RES_LG_MIN } from '@utils/settings'

interface Props {
  checkout: any
  open?: boolean
  onClose?: () => void
  className?: string
}

const CheckoutRiepilogoDrawer = (props: Props) => {
  const { checkout = undefined, open = false, onClose = undefined, className = '' } = props

  const t = useTrans()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= RES_LG_MIN) onClose()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return checkout ? (
    <WModal
      id="checkout_riepilogo_drawer_modal"
      open={open}
      onClose={onClose}
      className={`${styles.root} ${className}`}
    >
      <CloseBar onClose={onClose} className={styles.closeBar} />
      <div className={styles.head}>
        <h2 className={styles.title}>{t('Riepilogo del tuo ordine')}</h2>
        <button className={styles.close} type="button" aria-label={t('Chiudi')} onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className={styles.main}>
        {checkout.items?.length > 0 && (
          <div className={styles.products}>
            {checkout.items.map((rigaCheckout) => (
              <RigaCheckoutCard key={rigaCheckout.pk} rigaCheckout={rigaCheckout} />
            ))}
          </div>
        )}
        <CheckoutRiepilogoCosti dati={checkout} variant="drawer" />
      </div>
    </WModal>
  ) : (
    <></>
  )
}

export default CheckoutRiepilogoDrawer
