import { PagebuilderContext } from 'react-pagebuilder/services/pagebuilderContext'
import { ToolbarItem } from './ToolbarItem'
import { ToolbarSection } from './ToolbarSection'
import React, { useContext } from 'react'

export type PagebuilderCtaItemType = {
  icon?: string
  iconPos?: 'left' | 'right'
  ctaLabel?: string
  url?: string
  blank?: boolean
  variant?: 'primary' | 'secondary' | 'ghost'
}

export const ctaDefaultProps = (ctaLabel = null, icon = null, iconPos = null) => ({
  ...(icon !== null ? { icon: icon } : {}),
  ...(iconPos !== null ? { iconPos: iconPos } : {}),
  ...(ctaLabel !== null ? { ctaLabel: ctaLabel } : {}),
  url: null,
  extUrl: '',
  blank: false,
  variant: '',
})

export const ctaItemParams: (props?: any, enabled?: boolean) => PagebuilderCtaItemType = (
  props,
  enabled
) => ({
  ...(Object.keys(props).includes('icon') ? { icon: props.icon } : {}),
  ...(Object.keys(props).includes('iconPos') ? { iconPos: props.iconPos } : {}),
  ...(Object.keys(props).includes('ctaLabel') ? { ctaLabel: props.ctaLabel } : {}),
  ...(Object.keys(props).includes('variant') ? { variant: props.variant } : {}),
  url: enabled ? (props.url || props.extUrl ? '#' : '') : props.url || props.extUrl,
  blank: enabled ? false : props.blank,
})

const CtaToolbarSection = ({ defaultProps, initOpen = false }) => {
  const { urlOpts } = useContext(PagebuilderContext)

  const iconPosOpts = [
    { label: 'Destra', value: 'right' },
    { label: 'Sinistra', value: 'left' },
  ]

  return (
    <ToolbarSection title="Call to action" initOpen={initOpen}>
      {Object.keys(defaultProps).includes('icon') && (
        <ToolbarItem
          propKey="block"
          propSubKey="icon"
          type="icon"
          label="Icona"
          defaultValue={defaultProps['icon']}
        />
      )}
      {Object.keys(defaultProps).includes('iconPos') && (
        <ToolbarItem
          propKey="block"
          propSubKey="iconPos"
          type="select"
          options={iconPosOpts}
          label="Posizione Icona"
          defaultValue={defaultProps['iconPos']}
        />
      )}
      {'ctaLabel' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="ctaLabel"
          type="text"
          label="Label"
          defaultValue={defaultProps['ctaLabel']}
        />
      )}
      <ToolbarItem
        propKey="block"
        propSubKey="extUrl"
        type="text"
        label="URL"
        defaultValue={defaultProps['extUrl']}
      />
      {urlOpts && (
        <ToolbarItem
          propKey="block"
          propSubKey="url"
          type="select"
          label="Page Link"
          options={urlOpts}
          nullable
          defaultValue={defaultProps['url']}
        />
      )}
      <ToolbarItem
        propKey="block"
        propSubKey="variant"
        type="select"
        label="Variant"
        options={[
          { label: 'Primary', value: 'primary' },
          { label: 'Secondary', value: 'secondary' },
          { label: 'Ghost', value: 'ghost' },
        ]}
        defaultValue={defaultProps['variant']}
      />
      <ToolbarItem
        propKey="block"
        propSubKey="blank"
        type="switch"
        label="Apri in nuova finestra"
        defaultValue={defaultProps['blank']}
      />
    </ToolbarSection>
  )
}

export default CtaToolbarSection
