import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import { Button, Feedback, FormikField, FormLayout } from '@components/atoms'
import { CheckoutFormRivenditore } from '@components/molecules'
import styles from './CheckoutFormModalitaConsegna.module.sass'
import { float } from '@opensearch-project/opensearch/api/types'
import { RivenditoreNode } from '@gql/graphql'
import { useRouter } from 'next/router'
import { handleFindStoreCtaTracking } from '@utils/tracking'

interface Props {
  onCompleted?: (v: any) => void
  className?: string
  modalitaConsegnaOpts: {
    pk: number
    label: string
    description: string
    price: float
    value: string
  }[]
  initialValue: string
  rivenditori: RivenditoreNode[]
  cart: any
  setRivenditori: (rivenditori: RivenditoreNode[]) => void
  handleShippingCheckoutTracking: (cart, modalita: string) => void
}

const CheckoutFormModalitaConsegna = (props: Props) => {
  const {
    onCompleted = undefined,
    className = '',
    modalitaConsegnaOpts = [],
    initialValue = '',
    rivenditori = [],
    setRivenditori = null,
    cart,
    handleShippingCheckoutTracking,
  } = props

  const t = useTrans()
  const [error, setError] = useState(null)

  const router = useRouter()

  const initialValues = {
    modalitaConsegna:
      modalitaConsegnaOpts?.find((modalitaConsegna) => modalitaConsegna.value === initialValue)
        ?.value || modalitaConsegnaOpts[0].value,
    rivenditoreId: null,
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const modalitaConsegnaPk = modalitaConsegnaOpts?.find(
            (modalitaConsegna) => modalitaConsegna.value === values.modalitaConsegna
          )

          if (!modalitaConsegnaPk) {
            setError(
              t("Si è verificato un errore durante l'assegnazione della modalità di spedizione")
            )
            return
          }

          if (onCompleted) {
            onCompleted(values).then(() => {
              handleShippingCheckoutTracking(cart, modalitaConsegnaPk.value)
              setSubmitting(false)
            })
          }
        }}
      >
        {(formikProps) => {
          useEffect(() => {
            if (formikProps.values.modalitaConsegna === 'corriere_rivenditori') {
              formikProps.setFieldValue('rivenditoreId', null)
            } else {
              handleFindStoreCtaTracking(router.asPath)
            }
          }, [formikProps.values.modalitaConsegna, formikProps.setFieldValue])

          return (
            <Form className={styles.layout}>
              {!!error && (
                <div className={styles.errorMessage}>
                  <Feedback title={t('Errore')} description={error} status="error" />
                </div>
              )}
              <FormLayout>
                <FormikField
                  formik={formikProps}
                  id={'checkout_modalita_consegna'}
                  name="modalitaConsegna"
                  type="radio"
                  options={modalitaConsegnaOpts}
                  label={t('Scegli la modalità di consegna e ritiro:')}
                  required
                  fieldVariant="box"
                  space="md"
                  layout="full"
                />
              </FormLayout>
              {formikProps.values['modalitaConsegna'] === 'ritiro' && (
                <CheckoutFormRivenditore
                  formikProps={formikProps}
                  rivenditori={rivenditori}
                  setRivenditori={setRivenditori}
                />
              )}
              <div className={styles.ctaBox}>
                <Button
                  type="submit"
                  label={t('Procedi')}
                  size="lg"
                  loading={formikProps.isSubmitting}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CheckoutFormModalitaConsegna
