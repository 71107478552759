/* eslint-disable arrow-body-style */
import * as yup from 'yup'
import { validateEmail } from './safe'

export const loginValidation = (t) => {
  return yup.object().shape({
    username: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Questo campo è obbligatorio')
      .nullable(),
    password: yup.string().required(t('Questo campo è obbligatorio')),
  })
}

export const newsletterSectionValidation = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo è obbligatorio')),

    privacy: yup
      .boolean()
      .required(t('Questo campo è obbligatorio'))
      .oneOf([true], t('Questo campo è obbligatorio')),
  })
}

export const registrazioneValidation = (t, requirePassword) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Campo obbligatorio')
      .nullable(),
    tipoUtente: yup
      .string()
      .oneOf(['privato', 'azienda'], t('Tipo utente non valido'))
      .required(t('Questo campo è obbligatorio')),
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    cf: yup.string().when('tipoUtente', {
      is: (tipoUtente) => tipoUtente === 'privato',
      then: () =>
        yup
          .string()
          .matches(
            /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
            t('Il Codice Fiscale non è valido')
          )
          .required(t('Questo campo è obbligatorio')),
      otherwise: () =>
        yup
          .string()
          .test('is-valid-cf-or-piva', t('Il Codice Fiscale non è valido'), function (value) {
            const { piva } = this.parent // Recupera il valore di piva
            const cfRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

            if (!value) return false
            return cfRegex.test(value) || value === piva
          })
          .required(t('Questo campo è obbligatorio')),
    }),
    ragioneSociale: yup.string().when('tipoUtente', {
      is: (tipoUtente) => tipoUtente === 'azienda',
      then: () => yup.string().required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
    piva: yup.string().when('tipoUtente', {
      is: (tipoUtente) => tipoUtente === 'azienda',
      then: () =>
        yup
          .string()
          .matches(/^[0-9]{11}$/, t('Partita IVA non valida'))
          .required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
    sdi: yup.string().when(['noSdi', 'tipoUtente'], {
      is: (noSdi, tipoUtente) => noSdi === false && tipoUtente === 'azienda',
      then: () =>
        yup
          .string()
          .required(t('Questo campo è obbligatorio'))
          .max(7, t('Deve essere lungo esattamente 7 caratteri'))
          .min(7, t('Deve essere lungo esattamente 7 caratteri')),
      otherwise: () => yup.string().nullable(),
    }),
    noSdi: yup.boolean(),
    pec: yup.string().when('noSdi', {
      is: (noSdi) => noSdi,
      then: () =>
        yup.string().email(t('PEC non valida')).required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
    privacy: yup
      .boolean()
      .oneOf([true], t('Questo campo è obbligatorio'))
      .required(t('Questo campo è obbligatorio')),
    password: yup.string().when([], {
      is: () => requirePassword,
      then: () =>
        yup
          .string()
          .required(t('Questo campo è obbligatorio'))
          .min(8, t('La password deve avere almeno 8 caratteri')),
      otherwise: () => yup.string().nullable(),
    }),
  })
}

export const getAddressValidationSchema = (t, variant) => {
  return yup.lazy((values) => {
    if (variant === 'spedizione' && values.stessoIndirizzo) {
      return yup.object().shape({
        nomeSpedizione: yup.string().required(t('Il nome è obbligatorio')),
        cognomeSpedizione: yup.string().required(t('Il cognome è obbligatorio')),
        indirizzoSpedizione: yup.string().required(t("L'indirizzo è obbligatorio")),
        infoSpedizione: yup.string().notRequired(),
        cittaSpedizione: yup.string().required(t('La città è obbligatoria')),
        statoSpedizione: yup.string().required(t('Lo stato è obbligatorio')),
        capSpedizione: yup
          .string()
          .required(t('Il codice postale è obbligatorio'))
          .matches(/^\d{5}$/, t('Il codice postale deve essere un numero di 5 cifre')),
        telefonoSpedizione: yup
          .string()
          .required(t('Il numero di telefono è obbligatorio'))
          .matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
        stessoIndirizzo: yup.boolean(),
      })
    } else if (variant === 'spedizione' && !values.stessoIndirizzo) {
      // When "stessoIndirizzo" is true, use the "fatturazione" schema
      return yup.object().shape({
        nomeFatturazione: yup.string().required(t('Il nome è obbligatorio')),
        cognomeFatturazione: yup.string().required(t('Il cognome è obbligatorio')),
        indirizzoFatturazione: yup.string().required(t("L'indirizzo è obbligatorio")),
        infoFatturazione: yup.string().notRequired(),
        cittaFatturazione: yup.string().required(t('La città è obbligatoria')),
        statoFatturazione: yup.string().required(t('Lo stato è obbligatorio')),
        capFatturazione: yup
          .string()
          .required(t('Il codice postale è obbligatorio'))
          .matches(/^\d{5}$/, t('Il codice postale deve essere un numero di 5 cifre')),
        telefonoFatturazione: yup
          .string()
          .required(t('Il numero di telefono è obbligatorio'))
          .matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
      })
    } else {
      // If it's not spedizione, fallback to fatturazione validation
      return yup.object().shape({
        nomeFatturazione: yup.string().required(t('Il nome è obbligatorio')),
        cognomeFatturazione: yup.string().required(t('Il cognome è obbligatorio')),
        indirizzoFatturazione: yup.string().required(t("L'indirizzo è obbligatorio")),
        infoFatturazione: yup.string().notRequired(),
        cittaFatturazione: yup.string().required(t('La città è obbligatoria')),
        statoFatturazione: yup.string().required(t('Lo stato è obbligatorio')),
        capFatturazione: yup
          .string()
          .required(t('Il codice postale è obbligatorio'))
          .matches(/^\d{5}$/, t('Il codice postale deve essere un numero di 5 cifre')),
        telefonoFatturazione: yup
          .string()
          .required(t('Il numero di telefono è obbligatorio'))
          .matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
      })
    }
  })
}

export const registrazioneLoginValidationSchema = (t) => {
  return yup.object().shape({
    username: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Questo campo è obbligatorio')
      .nullable(),
  })
}

export const recuperaPasswordValidationSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Questo campo è obbligatorio')
      .nullable(),
  })
}

export const confermaPasswordValidationSchema = (t) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t('Questo campo è obbligatorio'))
      .min(8, t('La password deve avere almeno 8 caratteri')),
  })
}

export const myAccountProfileEditValidationSchema = (t) => {
  return yup.object().shape({
    email: yup.string().email(t('Email non valida')).required(t('Questo campo è obbligatorio')),
    tipoUtente: yup
      .string()
      .oneOf(['privato', 'azienda'], t('Tipo utente non valido'))
      .required(t('Questo campo è obbligatorio')),
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    cf: yup
      .string()
      .matches(
        /^[A-Za-z0-9]{16}$/,
        t('Il Codice Fiscale deve essere lungo 16 caratteri alfanumerici')
      )
      .required(t('Questo campo è obbligatorio')),
    ragioneSociale: yup.string().when('tipoUtente', {
      is: (tipoUtente) => tipoUtente === 'azienda',
      then: () => yup.string().required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
    piva: yup.string().when('tipoUtente', {
      is: (tipoUtente) => tipoUtente === 'azienda',
      then: () => yup.string().required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
    sdi: yup.string().when(['noSdi', 'tipoUtente'], {
      is: (noSdi, tipoUtente) => noSdi === false && tipoUtente === 'azienda',
      then: () =>
        yup
          .string()
          .required(t('Questo campo è obbligatorio'))
          .max(7, t('Deve essere lungo esattamente 7 caratteri'))
          .min(7, t('Deve essere lungo esattamente 7 caratteri')),
      otherwise: () => yup.string().nullable(),
    }),
    noSdi: yup.boolean(),
    pec: yup.string().when('noSdi', {
      is: (noSdi) => noSdi,
      then: () =>
        yup.string().email(t('PEC non valida')).required(t('Questo campo è obbligatorio')),
      otherwise: () => yup.string().nullable(),
    }),
  })
}

export const myAccountProfileEditPasswordValidationSchema = (t) => {
  return yup.object().shape({
    oldPassword: yup.string().required(t('Questo campo è obbligatorio')),
    newPassword: yup
      .string()
      .required(t('Questo campo è obbligatorio'))
      .min(8, t('La password deve avere almeno 8 caratteri')),
  })
}

export const myAccountPrivacyEditValidationSchema = (t) => {
  return yup.object().shape({
    privacy: yup.boolean().required(t('Questo campo è obbligatorio')),
  })
}

export const myAccountIndirizzoValidationSchema = (t) => {
  return yup.object().shape({
    nome: yup.string().required(t('Il nome è obbligatorio')),
    cognome: yup.string().required(t('Il cognome è obbligatorio')),
    indirizzo: yup.string().required(t("L'indirizzo è obbligatorio")),
    info: yup.string().notRequired(),
    citta: yup.string().required(t('La città è obbligatoria')),
    stato: yup.string().required(t('La nazione è obbligatorio')),
    cap: yup
      .string()
      .required(t('Il codice postale è obbligatorio'))
      .matches(/^\d{5}$/, t('Il codice postale deve essere un numero di 5 cifre')),
    telefono: yup
      .string()
      .required(t('Il numero di telefono è obbligatorio'))
      .matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
  })
}

export const contattaciValidationSchema = (t) => {
  return yup.object().shape({
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo è obbligatorio')),
    telefono: yup.string().matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
    comune: yup.string().required(t('Questo campo è obbligatorio')),
    nazione: yup.string().required(t('Questo campo è obbligatorio')),
    cap: yup.string().required(t('Questo campo è obbligatorio')),
    privacy: yup.bool().oneOf([true], t('Questo campo è obbligatorio')),
  })
}

export const adesioneB2CValidationSchema = (t) => {
  return yup.object().shape({
    codice: yup.string().required(t('Questo campo è obbligatorio')),
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    ragioneSociale: yup.string().required(t('Questo campo è obbligatorio')),
    cellulare: yup.string().matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo è obbligatorio')),
    privacy: yup
      .bool()
      .required(t('Il consenso è necessario per aderire al B2C'))
      .oneOf([true], t('Il consenso è necessario per aderire al B2C')),
    concessione: yup
      .bool()
      .required(t('Il consenso è necessario per aderire al B2C'))
      .oneOf([true], t('Il consenso è necessario per aderire al B2C')),
  })
}

export const manualiValidationSchema = (t) => {
  return yup.object().shape({
    categoria: yup.string().required(t('Questo campo è obbligatorio')),
    linea: yup.string().required(t('Questo campo è obbligatorio')),
    classe: yup.string().nullable(),
    prodotto: yup.string().required(t('Questo campo è obbligatorio')),
    lingua: yup.string().nullable(),
    manuale: yup.string().required(t('Questo campo è obbligatorio')),
  })
}

export const richiestaManualiValidationSchema = (t) => {
  return yup.object().shape({
    linee: yup.string().required(t('Questo campo è obbligatorio')),
    classi: yup.string().required(t('Questo campo è obbligatorio')),
    prodotto: yup.string().required(t('Questo campo è obbligatorio')),
    lingua: yup.string().required(t('Questo campo è obbligatorio')),
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    nazione: yup.string().required(t('Questo campo è obbligatorio')),
    comune: yup.string().required(t('Questo campo è obbligatorio')),
    cap: yup.string().required(t('Questo campo è obbligatorio')),
    // .matches(/^\d{5}$/, t('Il codice postale deve essere un numero di 5 cifre')), - in altre nazioni i cap hanno formati diversi
    telefono: yup.string().matches(/^\+?\d{10,15}$/, t('Inserisci un numero di telefono valido')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo è obbligatorio')),
    privacy: yup.bool().oneOf([true], t('Questo campo è obbligatorio')),
  })
}

export const landingNewsletterAdvValidation = (t) => {
  return yup.object().shape({
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Campo obbligatorio')
      .nullable(),
    tipoUtente: yup
      .string()
      .oneOf(['privato', 'azienda'], t('Tipo utente non valido'))
      .required(t('Questo campo è obbligatorio')),
    privacy: yup
      .boolean()
      .oneOf([true], t('Questo campo è obbligatorio'))
      .required(t('Questo campo è obbligatorio')),
  })
}

export const landingNewsletterGiardinaggioMiniguidaValidation = (t) => {
  return yup.object().shape({
    nome: yup.string().required(t('Questo campo è obbligatorio')),
    cognome: yup.string().required(t('Questo campo è obbligatorio')),
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .test('email_validation', t("Il formato dell'email dev'essere valido"), (value, context) => {
        return validateEmail(value)
      })
      .required('Campo obbligatorio')
      .nullable(),
    categoriaUtente: yup
      .string()
      .oneOf(['privato', 'professionista'], t('Categoria utente non valida'))
      .required(t('Questo campo è obbligatorio')),
    interessi: yup
      .array()
      .min(1, t('Seleziona almeno un ambiente di lavoro'))
      .of(yup.string().required())
      .required(),
    privacy: yup
      .boolean()
      .oneOf([true], t('Questo campo è obbligatorio'))
      .required(t('Questo campo è obbligatorio')),
    birthdate: yup.string().required(t('Questo campo è obbligatorio')),
  })
}
