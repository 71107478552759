import React from 'react'
import styles from './Tab.module.sass'

interface Props {
  label?: string
  selected?: boolean
  size?: 'sm' | 'md'
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const Tab = (props: Props) => {
  const {
    label = undefined,
    selected = false,
    size = 'md',
    onClick = undefined,
    className = '',
  } = props

  const classNames = `${styles.root} ${styles[`root--${size}`]} ${
    selected ? styles['root--selected'] : ''
  } ${className}`

  return onClick ? (
    <button type="button" disabled={selected} onClick={onClick} className={classNames}>
      {label}
    </button>
  ) : (
    <span className={classNames}>{label}</span>
  )
}

export default Tab
