export const RadioToolbarItem = ({
  propKey,
  propSubKey,
  values,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
}) => {
  return (
    <>
      {!onlyDynamic &&
        values.map((val) => {
          return (
            <label key={val}>
              <input
                type="radio"
                name={propSubKey}
                value={val}
                checked={value === val}
                onChange={(e) =>
                  setProp((props) => {
                    const value = e.target.value
                    props[propKey][propSubKey] = onChange ? onChange(value) : value
                  }, 1000)
                }
              />
              {val}
            </label>
          )
        })}
    </>
  )
}
