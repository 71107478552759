import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LogoOleomac({ idVariant = '', className }: Props) {
  return (
    <IconBase name="logo-oleomac" w={200} h={70} className={className}>
      <g clipPath={`url(#logooleomac_${idVariant}_clip0_30_1635)`}>
        <path
          d="M7.19844 62.5429C7.19844 61.0996 6.4833 59.6082 4.86233 59.6082C3.24135 59.6082 2.52621 61.0996 2.52621 62.5429C2.52621 63.9862 3.24135 65.4776 4.86233 65.4776C6.4833 65.4776 7.19844 63.9862 7.19844 62.5429ZM0.666855 62.5429C0.666855 59.9931 2.19248 58.1649 4.81465 58.1649C7.43682 58.1649 8.96245 59.9931 8.96245 62.5429C8.96245 65.0928 7.43682 66.9209 4.81465 66.9209C2.19248 66.9209 0.666855 65.0928 0.666855 62.5429Z"
          fill="black"
        />
        <path
          d="M17.6397 66.7285H15.828V65.5739H15.7804C15.3513 66.3917 14.3501 66.9691 13.4442 66.9691C11.2988 66.9691 10.3453 65.8625 10.3453 63.6976V58.4055H12.157V63.5052C12.157 64.9485 12.7291 65.5258 13.7303 65.5258C15.2559 65.5258 15.7804 64.5636 15.7804 63.2646V58.4055H17.6397V66.7285Z"
          fill="black"
        />
        <path
          d="M19.4038 58.3574H21.1201V59.9931H21.1678C21.3585 59.1271 22.455 58.1649 23.5993 58.1649C24.0283 58.1649 24.1237 58.2131 24.2667 58.2131V59.9931C24.0283 59.945 23.7423 59.8969 23.5039 59.8969C22.2643 59.8969 21.2631 60.9072 21.2631 62.7354V66.6804H19.4515V58.3574H19.4038Z"
          fill="black"
        />
        <path
          d="M33.5645 64.6117C34.7087 64.6117 34.9471 63.5533 34.9471 62.5429C34.9471 61.5326 34.7087 60.4742 33.5645 60.4742C32.4203 60.4742 32.1819 61.5326 32.1819 62.5429C32.1819 63.5051 32.4203 64.6117 33.5645 64.6117ZM29.1306 58.3574H32.1819V59.2715H32.2296C32.611 58.7423 33.0877 58.1649 34.661 58.1649C36.9972 58.1649 38.1414 60.5223 38.1414 62.5911C38.1414 64.5636 37.3786 66.9209 34.6134 66.9209C33.6598 66.9209 32.8017 66.488 32.3249 65.8144H32.2772V69.4227H29.1306V58.3574Z"
          fill="black"
        />
        <path
          d="M43.4799 60.4742C42.288 60.4742 42.145 61.6289 42.145 62.5429C42.145 63.4089 42.288 64.6117 43.4799 64.6117C44.6718 64.6117 44.8149 63.4089 44.8149 62.5429C44.8625 61.6289 44.6718 60.4742 43.4799 60.4742ZM43.4799 66.9209C40.381 66.9209 38.9507 64.8522 38.9507 62.5429C38.9507 60.2337 40.3333 58.1649 43.4799 58.1649C46.5789 58.1649 48.0092 60.2337 48.0092 62.5429C48.0092 64.8522 46.6266 66.9209 43.4799 66.9209Z"
          fill="black"
        />
        <path
          d="M59.7852 66.7286H56.5432L55.3037 61.5808L54.0641 66.7286H50.8221L48.2476 58.3574H51.5373L52.7768 63.5052L53.8257 58.3574H56.7816L57.8781 63.5052H57.9735L59.1177 58.3574H62.4073L59.7852 66.7286Z"
          fill="black"
        />
        <path
          d="M68.7001 61.5807C68.7001 60.811 68.128 60.1856 67.3175 60.1856C66.364 60.1856 65.8395 60.6667 65.6965 61.5807H68.7001ZM65.6488 63.2646C65.7442 64.2268 66.2686 64.9003 67.2698 64.9003C67.7943 64.9003 68.3187 64.7079 68.6047 64.323H71.5607C70.9409 66.1512 69.1769 66.9691 67.3175 66.9691C64.6476 66.9691 62.6453 65.3814 62.6453 62.5911C62.6453 60.1375 64.4093 58.1649 67.0314 58.1649C70.3211 58.1649 71.7514 60.0412 71.7514 63.2646H65.6488Z"
          fill="black"
        />
        <path
          d="M72.8958 58.3574H75.9471V59.7045H75.9947C76.5192 58.6942 77.1866 58.1649 78.3309 58.1649C78.6646 58.1649 78.9506 58.2131 79.2367 58.3093V61.1478C78.903 61.0515 78.6169 60.9553 77.9971 60.9553C76.7576 60.9553 76.0901 61.677 76.0901 63.5052V66.8247H72.9435V58.3574H72.8958Z"
          fill="black"
        />
        <path
          d="M80.2387 64.5155H82.4794V66.6804C82.4794 67.9794 81.5736 68.9897 80.334 69.2302V68.268C81.0491 68.0756 81.3829 67.4021 81.3829 66.6804H80.2863V64.5155H80.2387Z"
          fill="black"
        />
        <path
          d="M87.8668 58.3574H89.8692L92.0146 64.5636H92.0623L94.16 58.3574H96.067L92.8727 67.1616C92.3006 68.653 91.8716 70 89.9645 70C89.5354 70 89.1064 69.9519 88.725 69.9038V68.3643C89.011 68.4124 89.2971 68.4605 89.5831 68.4605C90.4413 68.4605 90.632 68.0275 90.8704 67.3059L91.0611 66.6805L87.8668 58.3574Z"
          fill="black"
        />
        <path
          d="M103.075 62.5429C103.075 61.0996 102.36 59.6082 100.739 59.6082C99.118 59.6082 98.4029 61.0996 98.4029 62.5429C98.4029 63.9862 99.118 65.4776 100.739 65.4776C102.36 65.4776 103.075 63.9862 103.075 62.5429ZM96.5435 62.5429C96.5435 59.9931 98.0691 58.1649 100.691 58.1649C103.361 58.1649 104.839 59.9931 104.839 62.5429C104.839 65.0928 103.313 66.9209 100.691 66.9209C98.0691 66.9209 96.5435 65.0928 96.5435 62.5429Z"
          fill="black"
        />
        <path
          d="M113.516 66.7285H111.705V65.5739H111.657C111.228 66.3917 110.227 66.9691 109.321 66.9691C107.175 66.9691 106.222 65.8625 106.222 63.6976V58.4055H108.034V63.5052C108.034 64.9485 108.606 65.5258 109.607 65.5258C111.133 65.5258 111.657 64.5636 111.657 63.2646V58.4055H113.469V66.7285H113.516Z"
          fill="black"
        />
        <path
          d="M115.327 58.3574H117.044V59.9931H117.091C117.282 59.1271 118.379 58.1649 119.523 58.1649C119.952 58.1649 120.047 58.2131 120.19 58.2131V59.9931C119.952 59.945 119.666 59.8969 119.427 59.8969C118.188 59.8969 117.187 60.9072 117.187 62.7354V66.6804H115.375V58.3574H115.327Z"
          fill="black"
        />
        <path
          d="M129.44 64.6117C130.584 64.6117 130.822 63.5533 130.822 62.5429C130.822 61.5326 130.584 60.4742 129.44 60.4742C128.295 60.4742 128.057 61.5326 128.057 62.5429C128.057 63.5051 128.295 64.6117 129.44 64.6117ZM125.006 58.3574H128.057V59.2715H128.105C128.486 58.7423 128.963 58.1649 130.536 58.1649C132.872 58.1649 134.016 60.5223 134.016 62.5911C134.016 64.5636 133.254 66.9209 130.488 66.9209C129.535 66.9209 128.724 66.488 128.2 65.8144H128.152V69.4227H125.006V58.3574Z"
          fill="black"
        />
        <path
          d="M140.262 62.9278C139.881 63.1203 139.452 63.2165 139.023 63.3127C138.308 63.457 137.974 63.6495 137.974 64.2268C137.974 64.6117 138.403 64.9966 138.975 64.9966C139.69 64.9966 140.262 64.5636 140.31 63.6976V62.9278H140.262ZM143.266 64.8522C143.266 65.4777 143.314 66.1993 143.647 66.7285H140.405C140.31 66.488 140.262 66.1512 140.31 65.9588H140.262C139.595 66.7285 138.594 66.9691 137.592 66.9691C135.971 66.9691 134.684 66.1993 134.684 64.4192C134.684 61.7732 137.735 61.8694 139.356 61.5807C139.785 61.4845 140.215 61.3883 140.215 60.8591C140.215 60.2818 139.69 60.0893 139.166 60.0893C138.212 60.0893 138.022 60.5704 137.974 60.9553H135.018C135.113 58.5979 137.306 58.213 139.309 58.213C143.266 58.213 143.218 59.8969 143.218 61.4845V64.8522H143.266Z"
          fill="black"
        />
        <path
          d="M149.607 60.7629C149.607 60.4742 149.464 60.2818 149.225 60.1375C149.035 59.9931 148.796 59.8969 148.51 59.8969C148.081 59.8969 147.557 59.9931 147.557 60.5224C147.557 60.7629 147.747 60.8591 147.938 60.9553C148.463 61.1959 149.655 61.244 150.703 61.5808C151.752 61.9175 152.706 62.543 152.706 63.9382C152.706 66.2474 150.513 66.8729 148.51 66.8729C146.556 66.8729 144.41 66.1031 144.363 63.9382H147.366C147.366 64.2268 147.509 64.4674 147.748 64.6598C147.891 64.8041 148.224 64.9004 148.606 64.9004C149.035 64.9004 149.655 64.756 149.655 64.2749C149.655 63.7938 149.368 63.6495 147.891 63.409C145.459 62.976 144.506 62.2062 144.506 60.7629C144.506 58.6461 146.746 58.1169 148.415 58.1169C150.227 58.1169 152.372 58.598 152.42 60.7629H149.607Z"
          fill="black"
        />
        <path
          d="M158.617 60.7629C158.617 60.4742 158.474 60.2818 158.236 60.1375C158.045 59.9931 157.807 59.8969 157.521 59.8969C157.091 59.8969 156.567 59.9931 156.567 60.5224C156.567 60.7629 156.758 60.8591 156.948 60.9553C157.473 61.1959 158.665 61.244 159.714 61.5808C160.762 61.9175 161.716 62.543 161.716 63.9382C161.716 66.2474 159.523 66.8729 157.521 66.8729C155.566 66.8729 153.42 66.1031 153.373 63.9382H156.376C156.376 64.2268 156.519 64.4674 156.758 64.6598C156.901 64.8041 157.234 64.9004 157.616 64.9004C158.045 64.9004 158.665 64.756 158.665 64.2749C158.665 63.7938 158.379 63.6495 156.901 63.409C154.469 62.976 153.516 62.2062 153.516 60.7629C153.516 58.6461 155.757 58.1169 157.425 58.1169C159.237 58.1169 161.382 58.598 161.43 60.7629H158.617Z"
          fill="black"
        />
        <path
          d="M162.908 58.3574H166.055V66.7285H162.908V58.3574ZM166.102 57.4433H162.956V55.1821H166.102V57.4433Z"
          fill="black"
        />
        <path
          d="M171.92 60.4742C170.728 60.4742 170.585 61.6289 170.585 62.5429C170.585 63.4089 170.728 64.6117 171.92 64.6117C173.111 64.6117 173.254 63.4089 173.254 62.5429C173.302 61.6289 173.111 60.4742 171.92 60.4742ZM171.92 66.9209C168.821 66.9209 167.39 64.8522 167.39 62.5429C167.39 60.2337 168.773 58.1649 171.92 58.1649C175.018 58.1649 176.449 60.2337 176.449 62.5429C176.449 64.8522 175.066 66.9209 171.92 66.9209Z"
          fill="black"
        />
        <path
          d="M177.688 58.3574H180.739V59.4158H180.787C181.359 58.5979 182.217 58.1649 183.504 58.1649C184.982 58.1649 186.365 59.079 186.365 61.0034V66.7285H183.218V62.3505C183.218 61.3883 183.123 60.7148 182.169 60.7148C181.645 60.7148 180.93 61.0034 180.93 62.3505V66.7766H177.783V58.3574H177.688Z"
          fill="black"
        />
        <path
          d="M94.9696 25.3058H98.2116C98.2116 25.3058 98.6406 23.6701 98.879 22.7079C99.1174 21.6976 99.0697 20.6391 97.9732 20.6391C96.8767 20.6391 96.1615 20.5429 95.8278 21.9862C95.4941 23.4295 94.9696 25.3058 94.9696 25.3058ZM94.0161 28.3848C94.0161 28.3848 93.587 30.1168 93.3963 30.9347C93.2056 31.7526 92.9196 32.6185 93.6347 33.0996C94.3975 33.5807 96.3522 33.0515 97.0674 32.6666C97.8302 32.3299 99.4511 31.4639 99.4511 31.4639L100.691 33.3402C99.8325 34.1099 96.9243 36.9484 91.2032 36.9484C86.6264 36.9484 85.3868 34.3024 86.1019 31.6563L88.9625 21.4089C89.773 18.378 91.5846 16.6941 94.9696 16.6941H99.2604C101.835 16.6941 103.361 18.811 102.645 21.4089L100.691 28.3848H94.0161Z"
          fill="black"
        />
        <path
          d="M111.18 33.4364C111.8 33.4364 112.276 32.9072 112.467 32.2337L115.137 22.1787C115.328 21.5533 114.803 20.6392 114.183 20.6392H113.373C112.753 20.6392 112.086 21.1684 111.895 21.8419L109.082 32.2337C108.891 32.9072 109.273 33.4364 109.94 33.4364H111.18ZM105.649 36.9484C101.74 36.9484 101.454 34.3024 102.169 31.6564L104.982 21.3127C105.697 18.7148 108.367 16.5979 110.989 16.5979H115.328C117.902 16.5979 119.618 18.7148 118.903 21.3127L115.9 32.2337C115.185 34.8316 114.136 36.9484 110.512 36.9484H105.649Z"
          fill="black"
        />
        <path
          d="M165.007 33.4364C165.674 33.4364 166.103 32.9072 166.294 32.2337L168.821 22.7079C169.011 22.0344 168.487 21.1684 167.867 21.1684H167.057C166.389 21.1684 165.722 21.6976 165.579 22.3711L162.909 32.2337C162.718 32.9072 163.099 33.4364 163.767 33.4364H165.007ZM175.734 32.5704L176.735 34.0137C173.684 36.756 171.967 37.2371 169.393 37.2371C167.724 37.2371 166.627 36.3711 166.246 35.024C165.436 36.1787 164.196 36.9484 162.003 36.9484H159.095C155.185 36.9484 154.899 34.3024 155.614 31.6564L158.523 20.9759C159.238 18.378 161.431 16.5979 164.005 16.5979H165.96C167.533 16.5979 169.011 17.8007 169.726 19.2921L170.442 16.5979H177.831L173.779 31.3677V31.512C173.779 31.512 173.493 32.3299 173.636 32.9553C173.779 33.5807 174.542 33.4845 174.875 33.1959C175.161 33.0034 175.734 32.5704 175.734 32.5704Z"
          fill="black"
        />
        <path
          d="M193.993 12.4605H194.422V11.5464H194.755C194.994 11.5464 195.137 11.5945 195.232 11.6426C195.375 11.7388 195.47 11.9313 195.47 12.1718V12.3643V12.4605V12.5086H195.852V12.4605C195.852 12.4605 195.852 12.4124 195.852 12.3161C195.852 12.268 195.852 12.1718 195.852 12.1237V11.9313C195.852 11.835 195.804 11.6907 195.709 11.5464C195.613 11.4021 195.47 11.3539 195.327 11.3058C195.47 11.3058 195.613 11.2577 195.661 11.2096C195.804 11.1134 195.9 10.9691 195.9 10.7285C195.9 10.4399 195.757 10.2474 195.518 10.1512C195.375 10.1031 195.184 10.055 194.898 10.055H194.088V12.4605H193.993ZM195.184 11.1615C195.089 11.2096 194.946 11.2096 194.803 11.2096H194.422V10.3436H194.755C194.994 10.3436 195.137 10.3917 195.28 10.4399C195.375 10.488 195.423 10.6323 195.423 10.7766C195.423 10.9691 195.375 11.1134 195.184 11.1615ZM196.376 9.76631C195.947 9.33332 195.47 9.14088 194.851 9.14088C194.279 9.14088 193.754 9.33332 193.325 9.76631C192.896 10.1993 192.705 10.6804 192.705 11.3058C192.705 11.8832 192.896 12.4124 193.325 12.8454C193.754 13.2783 194.231 13.4708 194.851 13.4708C195.423 13.4708 195.947 13.2783 196.376 12.8454C196.805 12.4124 196.996 11.9313 196.996 11.3058C196.996 10.6804 196.805 10.1512 196.376 9.76631ZM196.186 9.95875C196.519 10.3436 196.71 10.7766 196.71 11.2577C196.71 11.7869 196.519 12.2199 196.186 12.6048C195.804 12.9897 195.375 13.134 194.898 13.134C194.374 13.134 193.945 12.9416 193.611 12.6048C193.277 12.2199 193.087 11.7869 193.087 11.2577C193.087 10.7285 193.277 10.2955 193.611 9.95875C193.993 9.57387 194.422 9.42954 194.898 9.42954C195.375 9.42954 195.804 9.57387 196.186 9.95875Z"
          fill="black"
        />
        <path
          d="M75.3275 9.42959H59.9282C57.3537 9.42959 54.6362 11.5464 53.9211 14.1444L49.0581 31.7045C48.343 34.3024 49.7256 36.9966 53.6827 36.9966H64.8865C68.5099 36.9966 70.1308 34.8798 70.8937 32.2818L74.7077 18.5224C75.8043 15.1066 74.6124 13.5189 73.7542 13.5189H68.9866C69.7494 13.6633 70.703 14.5292 70.1785 16.6461L66.4121 30.3574C65.8877 32.3299 65.4586 33.3884 63.3132 33.3884L61.8829 33.3402C59.5468 33.3402 59.5468 32.0894 60.1189 30.1169L64.0283 16.0207C64.5528 14.0481 65.4586 12.7011 67.9378 12.7011H74.1833L75.3275 9.42959Z"
          fill="black"
        />
        <path
          d="M79.7145 9.42959L73.8027 31.0791C73.0876 33.677 73.8504 36.9966 77.8075 36.9966C82.0506 36.9966 85.4833 34.2062 85.4833 34.2062L84.5775 32.4262C84.5775 32.4262 82.6704 33.7251 81.7646 32.7148C80.8587 31.6564 81.9553 29.1547 82.3367 27.8557C82.6704 26.5567 87.3427 9.4777 87.3427 9.4777H79.7145V9.42959Z"
          fill="black"
        />
        <path
          d="M190.083 24.488L190.703 22.1787C190.894 21.5533 190.369 20.6392 189.75 20.6392H188.939C188.272 20.6392 187.652 21.1684 187.461 21.8419L184.648 32.2337C184.458 32.9072 184.839 33.4364 185.506 33.4364H186.746C187.414 33.4364 187.843 32.9072 188.033 32.2337L188.462 30.6941H191.943L191.514 32.2337C190.799 34.8316 189.75 36.9484 186.126 36.9484H181.216C177.306 36.9484 177.02 34.3024 177.783 31.6564L180.596 21.3127C181.311 18.7148 183.981 16.5979 186.603 16.5979H190.942C193.516 16.5979 195.232 18.7148 194.517 21.3127L193.659 24.4399L190.083 24.488Z"
          fill="black"
        />
        <path
          d="M134.016 36.9485L133.158 22.4193L129.201 36.9485H125.339L131.346 15.0103C131.632 14.2406 131.775 13.2303 130.297 13.2303H127.246L128.343 9.42956H136.781C138.784 9.42956 139.546 9.91066 139.833 11.354C140.309 14.0481 141.692 21.9863 141.692 21.9863L149.225 11.5945C149.94 10.5361 151.227 9.38145 153.229 9.38145H159.237L151.704 36.9004H142.55L146.412 22.4674L136.114 36.9004H134.016V36.9485Z"
          fill="black"
        />
        <path
          d="M120.238 25.1134C120.429 24.4398 121.049 23.6701 122.002 23.6701H126.293L125.244 27.6151C125.054 28.2886 124.386 28.8178 123.004 28.8178H119.19L120.238 25.1134Z"
          fill="black"
        />
        <path
          d="M44.4812 29.7801C45.1487 27.6152 45.5301 25.3059 45.5301 22.9485C45.5301 13.6152 39.9997 5.58079 32.0378 1.97254L19.1654 29.7801H44.4812Z"
          fill="black"
        />
        <path
          d="M43.8617 31.512C40.5244 39.9313 32.3242 45.8969 22.7414 45.8969C10.2026 45.8969 0 35.6495 0 22.9485C0 10.2955 10.2026 0 22.7414 0C25.4589 0 28.0334 0.4811 30.4172 1.34708L29.6544 2.93471C27.5089 2.16495 25.1728 1.73196 22.7414 1.73196C11.1561 1.73196 1.764 11.2096 1.764 22.9003C1.764 34.5911 11.1561 44.0687 22.7414 44.0687C31.323 44.0687 38.7128 38.8729 41.9547 31.4639L43.8617 31.512Z"
          fill="black"
        />
        <path
          d="M28.9386 4.57041C26.9839 3.89687 24.8861 3.5601 22.7407 3.5601C12.4904 3.5601 4.0995 11.6426 3.52739 21.8419H20.9767L28.9386 4.57041Z"
          fill="#FF5100"
        />
        <path
          d="M3.52739 23.5739H20.2139L16.5429 31.5601H18.0685H40.0471C36.9004 37.9588 30.3689 42.433 22.7884 42.433C12.3474 42.3849 3.81344 34.0137 3.52739 23.5739Z"
          fill="#FF5100"
        />
        <path
          d="M188.796 42.1925H42.9081C43.9093 41.0859 44.8629 39.9313 45.721 38.6323H189.797L188.796 42.1925Z"
          fill="#FF5100"
        />
        <path
          d="M187.843 45.7045H38.9041C39.7145 45.1272 40.4774 44.5018 41.2402 43.8282H188.415L187.843 45.7045Z"
          fill="black"
        />
        <path
          d="M199.094 3.94499L198.141 7.45702H45.721C44.8629 6.20616 43.957 5.00341 42.9081 3.94499H199.094Z"
          fill="#FF5100"
        />
        <path
          d="M200.001 0.384895L199.524 2.26118H41.1925C40.4774 1.58764 39.6669 0.962215 38.9041 0.384895H200.001Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={`logooleomac_${idVariant}_clip0_30_1635`}>
          <rect width="200" height="70" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default LogoOleomac
