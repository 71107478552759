import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ArrowRight({ idVariant = '', className }: Props) {
  return (
    <IconBase name="arrow-right" w={24} className={className}>
      <path
        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ArrowRight
