import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import { IndirizzoNode, useCreateAddressMutation } from '@gql/graphql'
import { myAccountIndirizzoValidationSchema } from '@utils/validationSchemas'
import { cittaComuniOptions, statoOptions } from '@utils/checkout'
import { Button, FormError, FormikField, FormLayout } from '@components/atoms'
import styles from './MyAccountIndirizzoForm.module.sass'

interface Props {
  indirizzo?: IndirizzoNode
  tipoIndirizzo?: 'spedizione' | 'fatturazione'
  className?: string
}

const MyAccountIndirizzoForm = (props: Props) => {
  const { indirizzo = undefined, tipoIndirizzo = 'spedizione', className = '' } = props

  const t = useTrans()
  const cittaOpts = cittaComuniOptions()
  const router = useRouter()
  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [createAddress] = useCreateAddressMutation({
    onCompleted: (res) => {
      if (!res.createAddress?.status) {
        raiseGlobalError(res?.createAddress?.message || t('Errore durante il salvataggio'))
      } else router.push('/myaccount/addresses/')
    },
    onError: () => {
      // Errore non previsto - generico
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  const initialValues = {
    nome: indirizzo?.nome || '',
    cognome: indirizzo?.cognome || '',
    indirizzo: indirizzo?.indirizzo || '',
    note: indirizzo?.note || '',
    citta: `${indirizzo?.citta}|${indirizzo?.provincia}` || '',
    provincia: indirizzo?.provincia || '',
    cap: indirizzo?.cap || '',
    stato: indirizzo?.nazione?.isoCode || '',
    telefono: indirizzo?.telefono || '',
    isDefault: indirizzo?.isDefault || false,
    isFatturazione: tipoIndirizzo === 'fatturazione',
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={myAccountIndirizzoValidationSchema(t)}
        onSubmit={(values) => {
          createAddress({
            variables: {
              address: {
                ...values,
                citta: values.citta?.split('|')?.[0],
                pk: indirizzo?.pk || undefined,
              },
            },
          })
        }}
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <FormError
              id="change_password_global_error"
              message={globalError}
              visible={!!globalError}
              variant="global"
              className={styles.globalError}
            />
            <FormLayout space="lg" className={styles.sectionDati}>
              <FormikField
                formik={formikProps}
                id={'indirizzo_nome'}
                name="nome"
                type="text"
                label={t('Nome')}
                placeholder={t('Inserisci il tuo nome')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_cognome'}
                name="cognome"
                type="text"
                label={t('Cognome')}
                placeholder={t('Inserisci il tuo cognome')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_indirizzo'}
                name="indirizzo"
                type="text"
                label={t('Indirizzo (via, piazza, civico)')}
                placeholder={t('Inserisci il tuo indirizzo')}
                required
                layout="full"
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_note'}
                name="note"
                type="text"
                label={t('Informazioni aggiuntive')}
                placeholder={t('Aggiungi informazioni utili per la consegna')}
                layout="full"
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_citta'}
                name="citta"
                type="select"
                options={cittaOpts}
                isSearchable={true}
                label={t('Città')}
                placeholder={t('Inserisci la città')}
                required
                onChange={(e) => {
                  formikProps?.handleChange(e)
                  formikProps.setFieldValue(
                    'provincia',
                    e.target.value?.split('|')?.length || 0 > 1
                      ? e.target.value?.split('|')[1]
                      : undefined
                  )
                }}
                async
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_provincia'}
                name="provincia"
                type="text"
                label={t('Provincia')}
                placeholder={t('Inserisci la provincia')}
                required
                disabled
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_cap'}
                name="cap"
                type="text"
                label={t('Codice postale')}
                placeholder={t('Inserisci il tuo codice postale')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_stato'}
                name="stato"
                type="select"
                options={statoOptions}
                label={t('Nazione')}
                placeholder={t('Inserisci la nazione')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_telefono'}
                name="telefono"
                type="text"
                label={t('Numero di telefono')}
                placeholder={t('Inserisci il tuo numero di telefono')}
                required
                layout="full"
              />
              <FormikField
                formik={formikProps}
                id={'indirizzo_is_default'}
                name="isDefault"
                type="checkbox"
                checkboxLabel={t('Usa questo indirizzo come predefinito')}
                layout="full"
              />
            </FormLayout>
            <div className={styles.requirements}>* {t('Campi obbligatori')}</div>
            <div className={styles.buttons}>
              <Button
                label={t('Annulla')}
                theme="light"
                variant="secondary"
                href={'/myaccount/addresses/'}
              />
              <Button type="submit" label={t('Salva le modifiche')} loading={globalLoading} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountIndirizzoForm
