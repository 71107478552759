import React from 'react'
import { PaginaNode } from '@gql/graphql'
import { WImage, WContainer, Breadcrumbs } from '@components/atoms'
import styles from './HeroImage.module.sass'

interface Props {
  pagina: PaginaNode
  className?: string
}

const HeroImage = (props: Props) => {
  const { pagina = undefined, className = '' } = props

  return pagina ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          <div className={`${styles.imageBox}`}>
            {!!pagina.mainImage && (
              <WImage
                src={pagina.mainImage}
                maxWidth={1296}
                maxHeight={400}
                objectFit="cover"
                priority
                loading="eager"
                alt={pagina.titolo}
                // title={} TODO
              />
            )}
          </div>
          <div className={styles.main}>
            <Breadcrumbs
              breadcrumbs={pagina.breadcrumbs}
              theme={'dark'}
              className={styles.breadcrumbs}
            />
            <div className={styles.content}>
              {!!pagina.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
              {!!pagina.descrizione && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
                />
              )}
            </div>
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default HeroImage
