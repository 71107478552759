import React from 'react'
import styles from './Tag.module.sass'
import { Info } from '@components/icons'
import Tooltip from '../Tooltip/Tooltip'
import Skeleton from 'react-loading-skeleton'

interface Props {
  label?: string
  helper?: {
    id: string
    label: string
  }
  size?: 'sm' | 'lg'
  variant?:
    | 'black'
    | 'secondarydark'
    | 'white'
    | 'whiteborder'
    | 'promo'
    | 'ideeverdi'
    | 'novita'
    | 'categoria'
  editMode?: boolean
  className?: string
  skeleton?: boolean
}

const Tag = (props: Props) => {
  const {
    label = undefined,
    helper = undefined,
    size = 'sm',
    variant = 'black',
    editMode = false,
    className = '',
    skeleton = false,
  } = props

  return editMode || !!label ? (
    <div
      className={`${styles.root} ${styles[`root--${variant}`]} ${styles[`root--${size}`]}  ${
        editMode ? styles['root--editMode'] : ''
      } ${className}`}
    >
      {(!!label || editMode) &&
        (editMode ? (
          <div className={styles.label}>{label}</div>
        ) : (
          <span className={styles.label}>{skeleton ? <Skeleton width={50} /> : label}</span>
        ))}
      {!!helper && !!helper.label && (
        <div>
          <Tooltip id={helper.id} label={helper.label}>
            <span className={styles.icon}>
              <Info />
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default Tag
