import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import { MenuItemType } from '@utils/types'
import { WContainer, Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './HomeHero.module.sass'
import { getIcon } from '@utils/icons'
import { HomeHeroSlide } from '@components/molecules'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

export const HomeHeroSwiperParams: SwiperOptions & { className?: string } = {
  slidesPerView: 1,
  modules: [A11y, Navigation],
  navigation: true,
  loop: true,
}

const HomeHero = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      {editMode ? (
        <>{item.children}</>
      ) : (
        !!Array.isArray(item.children) &&
        item.children?.filter((x) => !!x.children?.length)?.length && (
          <>
            <div className={`${styles.slider} ${styles['slider--desktop']}`}>
              <Swiper {...HomeHeroSwiperParams}>
                {item.children
                  .filter((x) => !!x.children?.length)
                  .map((x) => (
                    <SwiperSlide key={x.pk}>
                      <div
                        className={`${styles.item} ${
                          x.children.length > 1 ? styles[`item--half`] : ''
                        }`}
                      >
                        {x.children.slice(0, 2).map((y, index) => (
                          <HomeHeroSlide
                            key={y.pk}
                            item={y}
                            className={styles.item}
                            loading={index ? 'lazy' : 'eager'}
                            priority={!index}
                            layout={x.children.length > 1 ? 'half' : ''}
                          />
                        ))}
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className={`${styles.slider} ${styles['slider--mobile']}`}>
              <Swiper {...HomeHeroSwiperParams}>
                {item.children
                  .filter((x) => !!x.children?.length)
                  .map((x) =>
                    x.children.map((y) => (
                      <SwiperSlide key={y.pk}>
                        <HomeHeroSlide item={y} className={styles.item} />
                      </SwiperSlide>
                    ))
                  )}
              </Swiper>
            </div>
          </>
        )
      )}
    </div>
  ) : (
    <></>
  )
}

export default HomeHero
