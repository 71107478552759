import { useLastPromoPopupQuery } from '@gql/graphql'
import { useRouter } from 'next/router'
import React, { useEffect, useState, useRef } from 'react'

export const initialPromoModal = {
  open: false,
  timer: 0,
  data: null,
}

export const PromoModalContext = React.createContext({
  promoModal: initialPromoModal,
  setPromoModal: (promoModal) => {},
})

const PromoModalProvider = (props) => {
  const [promoModal, setPromoModal] = useState(initialPromoModal)
  const timerRef = useRef(null)

  const { data, loading, error } = useLastPromoPopupQuery()

  useEffect(() => {
    if (promoModal.timer >= 30) {
      return
    }

    if (
      props.pageType !== 'homepage' &&
      props.pageType !== 'product' &&
      props.pageType !== 'listing'
    ) {
      return
    }

    if (data) {
      if (promoModal.timer < 29) {
        timerRef.current = setTimeout(() => {
          setPromoModal((prev) => ({ ...prev, timer: prev.timer + 1 }))
        }, 1000)
      } else {
        setPromoModal({ open: true, timer: 30, data: data.lastPromoPopup })
      }
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [data, promoModal.timer, props.pageType])

  return (
    <PromoModalContext.Provider value={{ promoModal, setPromoModal }}>
      {props.children}
    </PromoModalContext.Provider>
  )
}

export default PromoModalProvider
