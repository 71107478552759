import { AddGblinkPluginDialog } from './dialogs/gblink'

export const AddGblinkPlugin = (CKEDITOR) => {
  if (CKEDITOR.plugins.get('gblink')) return
  CKEDITOR.plugins.add('gblink', {
    init: function (editor) {
      AddGblinkPluginDialog(CKEDITOR)
      editor.addCommand('insertInternalLink', new CKEDITOR.dialogCommand('gblinkDialog'))
      editor.ui.addButton('Gblink', {
        label: 'Link Interno',
        command: 'insertInternalLink',
        toolbar: 'links',
        icon: '/images/ckeditor/gblink.png',
      })
      editor.on(
        'doubleclick',
        function (e) {
          var element = e.data.element
          if (element.is('a') && element.hasClass('gblink')) e.data.dialog = 'gblinkDialog'
        },
        null,
        null,
        100
      )
    },
  })
}
