import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Download({ idVariant = '', className }: Props) {
  return (
    <IconBase name="download" w={24} className={className}>
      <path
        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Download
