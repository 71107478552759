import { useNode } from 'react-pagebuilder/hooks/useNode'
import React from 'react'
import { TextSettings } from './TextSettings'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = ['text']

export const Text: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-text ${props.className}`}>
        {children[0]}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Text.craft = withCraft({
  name: 'Text',
  defaultProps: defaultProps,
  settings: TextSettings,
})
