import React, { ReactNode } from 'react'
import styles from './FormLayout.module.sass'

interface Props {
  space?: 'sm' | 'md' | 'lg'
  className?: string
  children?: ReactNode
}

const FormLayout = ({ space = 'md', className = '', children }: Props) => (
  <div className={`${styles.root} ${styles[`root--space-${space}`]} ${className}`}>{children}</div>
)

export default FormLayout
