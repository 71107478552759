import React from 'react'
import useTrans from '@hooks/useTrans'
import { IndirizzoNode } from '@gql/graphql'
import { Bin, Edit } from '@components/icons'
import { Button, ButtonIcon, Tag } from '@components/atoms'
import styles from './MyAccountIndirizzoCard.module.sass'

interface Props {
  indirizzo?: IndirizzoNode
  defaultDisabled?: boolean
  defaultLoading?: boolean
  onDefault?: () => void
  onDelete?: () => void
  className?: string
}

const MyAccountIndirizzoCard = (props: Props) => {
  const {
    indirizzo = undefined,
    defaultDisabled = false,
    defaultLoading = false,
    onDefault = undefined,
    onDelete = undefined,
    className = '',
  } = props

  const t = useTrans()

  return indirizzo ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.content}>
        <div className={styles.info}>
          {(!!indirizzo.nome || !!indirizzo.cognome) && (
            <p className={styles.name}>
              {indirizzo.nome} {indirizzo.cognome}
            </p>
          )}
          {!!indirizzo.isDefault && <Tag label={t('Predefinito')} variant={'novita'} />}
        </div>
        <p className={styles.description}>
          {indirizzo.indirizzo}
          {indirizzo.indirizzo && (indirizzo.citta || indirizzo.cap || indirizzo.provincia) && (
            <>, </>
          )}
          {indirizzo.citta} {indirizzo.cap}
          {!!indirizzo.provincia && <> ({indirizzo.provincia})</>}
          {(indirizzo.indirizzo || indirizzo.citta || indirizzo.cap || indirizzo.provincia) &&
          !!indirizzo.telefono ? (
            <> - </>
          ) : (
            <></>
          )}
          {indirizzo.telefono}
        </p>
      </div>
      <div className={styles.actions}>
        {!indirizzo.isDefault && (
          <Button
            label={t('Usa come predefinito')}
            variant={'secondary'}
            onClick={onDefault}
            disabled={defaultDisabled}
            loading={!defaultDisabled && defaultLoading}
            size="sm"
            className={styles.defaultBtn}
          />
        )}
        <ButtonIcon
          icon={<Edit />}
          href={`/myaccount/addresses/${indirizzo.pk}/?tipo=${
            indirizzo.isFatturazione ? 'fatturazione' : 'spedizione'
          }`}
          color="primary"
          className={styles.btnIcon}
        />
        <ButtonIcon icon={<Bin />} onClick={onDelete} color="primary" className={styles.btnIcon} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MyAccountIndirizzoCard
