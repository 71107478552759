import {
  useAddItemMutation,
  useCartLazyQuery,
  useChangeItemSizeMutation,
  useRemoveItemMutation,
  useRemovePromoCodeMutation,
  useSetPromoCodeMutation,
} from '@gql/graphql'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { isEcommerce } from '.'
import { handleCouponTracking } from './tracking'

export const initialCart = {
  items: [],
  numProdotti: 0,
  totaleProdottiScontato: 0,
  prezzo: 0,
  promoApplicata: null,
  sistemaSpedizione: {
    prezzoScontato: 0,
  },
  totaleServizi: 0,
  promoCode: '',
  hasOmaggio: false,
  correlati: [],
}

export type Cart = typeof initialCart

export const CartContext = React.createContext({
  cart: initialCart,
  addToCart: (idProdotto, quantita, refresh) => {},
  removeFromCart: (idProdotto) => {},
  setPromo: (promoCode) => {},
  setPromoCodeError: undefined,
  setPromoCodeLoading: false,
  removePromo: () => {},
  changeItemSize: (idProdottoOld, idProdottoNew, omaggio) => {},
  loading: false,
  refetch: () => {},
})

const CartContextProvider = (props) => {
  const [cart, setCart] = useState(initialCart)

  const router = useRouter()

  const [refetch, { loading: cartLoading, data }] = useCartLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [addItem, { loading: addItemLoading, error: addItemError }] = useAddItemMutation()

  const [removeItem, { loading: removeItemLoading, error: removeItemError }] =
    useRemoveItemMutation()

  const [setPromoCode, { loading: setPromoCodeLoading }] = useSetPromoCodeMutation()

  const [setPromoCodeError, setSetPromoCodeError] = useState(undefined)

  const [removePromoCode, { loading: removePromoCodeLoading, error: removePromoCodeError }] =
    useRemovePromoCodeMutation()

  const [changeItemSizeMutation, { loading: changeItemSizeLoading }] = useChangeItemSizeMutation()

  useEffect(() => {
    if (data && data.cart) {
      setCart(data?.cart)
    }
  }, [data])

  useEffect(() => {
    if (isEcommerce(router.locale) && !router.asPath?.includes('pagebuilder')) refetch()
  }, [router.asPath])

  async function changeItemSize(idProdottoOld: number, idProdottoNew: number, omaggio: boolean) {
    await changeItemSizeMutation({
      variables: {
        idProdottoOld: idProdottoOld,
        idProdottoNew: idProdottoNew,
        omaggio: omaggio,
      },
    })
    await refetch()
  }

  async function addToCart(idProdotto = null, quantita = null, refresh = true) {
    if (!idProdotto || !quantita) return

    await addItem({
      variables: {
        idProdotto,
        quantita,
      },
    })

    if (refresh) await refetch()
  }

  async function removeFromCart(idProdotto = null, omaggio = false) {
    if (!idProdotto) return

    await removeItem({
      variables: {
        idProdotto,
        omaggio,
      },
    })

    await refetch()
  }

  async function setPromo(promoCode = null) {
    if (!promoCode) return

    try {
      const { data } = await setPromoCode({ variables: { promoCode } })
      if (data.setPromoCode.status) {
        await handleCouponTracking('ok', promoCode, 'add')
        await refetch()
      } else {
        await handleCouponTracking('ko', promoCode, 'add')
        setSetPromoCodeError(data.setPromoCode.message)
      }
    } catch (error) {
      await handleCouponTracking('ko', promoCode, 'add')
    }
  }

  async function removePromo() {
    await removePromoCode({})
    await refetch()
  }

  return (
    <CartContext.Provider
      value={{
        addToCart,
        cart,
        removeFromCart,
        setPromo,
        setPromoCodeError,
        setPromoCodeLoading,
        removePromo,
        changeItemSize,
        loading: cartLoading || addItemLoading || removeItemLoading,
        refetch,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}

export { CartContextProvider }
