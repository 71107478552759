import { convertLocale } from '@utils/safe'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { escape } from 'utils'
import { BRAND, PROTOCOL_SCHEMA } from 'utils/settings'

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, hreflangs, additionalMetaTags = null }) => {
  const { title = '', description = '', image = '', canonicalUrl = '', noindex = false } = seo || {}

  const router = useRouter()

  const url = new URL(canonicalUrl)

  const ogImage = image || `${PROTOCOL_SCHEMA}://${url.hostname}/static/images/logo.png`

  const og = {
    locale: convertLocale(router.locale),
    site_name: BRAND,
    url: url?.toString(),
    title,
    description,
    images: ogImage
      ? [
          {
            url: ogImage?.includes(PROTOCOL_SCHEMA)
              ? ogImage
              : `${PROTOCOL_SCHEMA}://${url.hostname}${ogImage}`,
          },
        ]
      : [],
    type: 'website',
  }

  const props = {
    title: escape(title),
    description: escape(description),
    additionalMetaTags,
    openGraph: og,
    languageAlternates: hreflangs,
  }
  if (canonicalUrl) props.canonical = canonicalUrl
  if (noindex) props.noindex = true

  return <NextSeo {...props} />
}

export default MetaSeo
