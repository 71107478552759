import React, { useContext } from 'react'
import { Button, WContainer, WishlistButton } from '@components/atoms'
import styles from './PDPBar.module.sass'
import useTrans from '@hooks/useTrans'
import { ProdottoNode } from '@gql/graphql'
import { priceNotation } from '@utils/safe'
import { CartContext } from '@utils/cartContext'
import { isEcommerce } from '@utils/index'
import { useRouter } from 'next/router'

interface Props {
  prodotto: ProdottoNode
  className?: string
  prezzi?: { key: string; label: string; value: number }[]
  visible?: boolean
}

const PDPBar = (props: Props) => {
  const { prodotto = null, className = '', prezzi = null, visible = false } = props
  const { loading: cartLoading, addToCart: contextAddToCart } = useContext(CartContext)

  const t = useTrans()
  const router = useRouter()

  const ecommerce = isEcommerce(router.locale)

  const addToCart = () => {
    contextAddToCart(prodotto.pk, 1)
  }

  return prodotto ? (
    <div className={`${styles.root} ${className} ${visible ? styles[`root--visible`] : ''}`}>
      <WContainer>
        <div className={styles.main}>
          {!!prodotto.nome && <p className={styles.title}>{prodotto.nome}</p>}
          <div className={styles.infos}>
            {!!prezzi?.length && (
              <div className={styles.prices}>
                {prezzi.map((p) => (
                  <span key={p.key} className={`${styles.price} ${styles[`price--${p.key}`]}`}>
                    {!!p.label && <span className={styles.priceLabel}>{p.label} </span>}
                    <span className={styles.priceValue}>
                      {priceNotation(p.value, false, prodotto.valuta?.simbolo)}
                    </span>
                  </span>
                ))}
              </div>
            )}
            <div className={styles.ctaBox}>
              <Button
                label={t('Aggiungi al carrello')}
                onClick={addToCart}
                className={styles.cta}
                loading={cartLoading}
              />
              {ecommerce && <WishlistButton prodotto={prodotto} />}
            </div>
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default PDPBar
