import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Battery({ idVariant = '', className }: Props) {
  return (
    <IconBase name="battery" w={24} className={className}>
      <path
        d="M19.2 15.303L19.2 13.8L21 13.8L21 10.2L19.2 10.2L19.2 8.697C19.2 8.04 18.66 7.5 18.003 7.5L4.206 7.5C3.54 7.5 3 8.04 3 8.697L3 15.294C3 15.96 3.54 16.5 4.197 16.5L18.003 16.5C18.66 16.5 19.2 15.96 19.2 15.303ZM4.8 11.1L9.75 11.1L9.75 9.3L16.5 12.9L11.55 12.9L11.55 14.7L4.8 11.1Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Battery
