import React, { useEffect, useState } from 'react'
import { ProdottoNode } from '@gql/graphql'
import { FavoriteMarked, FavoriteUnmarked } from '@components/icons'
import styles from './WishlistButton.module.sass'
import useWishlist from '@hooks/useWishlist'
import { Cart } from '@utils/cartContext'
import WishlistModal from '../WishlistModal/WishlistModal'

interface Props {
  prodotto?: ProdottoNode
  cart?: Cart
  className?: string
}

const WishlistButton = (props: Props) => {
  const { prodotto = undefined, cart = undefined, className = '' } = props

  const { enabled, marked, toggleWishlist } = useWishlist(prodotto)
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        onClick={enabled ? toggleWishlist : () => setModalOpen(true)}
        className={`${styles.root} ${className}`}
      >
        {marked ? <FavoriteMarked /> : <FavoriteUnmarked />}
      </button>

      <WishlistModal
        id={`wishlist_button_modal_${prodotto.pk}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default WishlistButton
