import React, { useMemo } from 'react'
import styles from './CompareCard.module.sass'
import { ArrowRight, Close } from '@components/icons'
import { ProdottoNode } from '@gql/graphql'
import { Button, ButtonIcon, WImage } from '@components/atoms'
import { prezziProdotto, priceNotation } from '@utils/safe'
import useTrans from '@hooks/useTrans'
import { useRouter } from 'next/router'
import { isEcommerce } from '@utils/index'

interface Props {
  prodotto?: ProdottoNode
  onClose?: () => void
  className?: string
}

const CompareCard = (props: Props) => {
  const { prodotto = undefined, onClose = undefined, className = '' } = props

  const t = useTrans()

  const router = useRouter()
  const ecommerce = isEcommerce(router.locale)

  const pp = prezziProdotto(prodotto)
  const prezzi = [
    { key: 'current', value: pp.prezzoFinale },
    ...(ecommerce ? [{ key: 'listino', value: pp.prezzoListino }] : []),
  ].filter((x) => !!x.value)

  const memoizedImage = useMemo(
    () => <WImage src={prodotto.previewImage} maxWidth={110} maxHeight={110} objectFit="contain" />,
    [prodotto]
  )

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.main}>
        <ButtonIcon icon={<Close />} color={'black'} onClick={onClose} className={styles.close} />
        <div className={styles.imageBox}>{memoizedImage}</div>
        <div className={styles.content}>
          {prodotto.categoria && (
            <span className={styles.category}>{prodotto.categoria.boxTitolo}</span>
          )}
          <h2 className={styles.title}>{prodotto.nome}</h2>
          {prodotto.disponibile && !!prezzi?.length && (
            <div className={styles.prices}>
              {prezzi.map((p) => (
                <span key={p.key} className={`${styles.price} ${styles[`price--${p.key}`]}`}>
                  {priceNotation(p.value, false, prodotto.valuta?.simbolo)}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      <Button
        label={t('Vai al prodotto')}
        iconRight={<ArrowRight />}
        variant="ghost"
        href={prodotto.url}
        className={styles.cta}
        // loading={loadingChangeProdotto || loading}
      />
    </div>
  )
}

export default CompareCard
