import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const PDPDownloadCardSettings = (settingsProps) => {
  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="label"
          type="text"
          label="Label"
          defaultValue={defaultProps['label']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="url"
          type="file"
          label="File"
          defaultValue={defaultProps['url']}
        />
      </ToolbarSection>
    </>
  )
}

export const PDPDownloadCardFloatingSettings = () =>
  React.createElement(PDPDownloadCardSettings, { floating: true })
