import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { MenuItemType } from '@utils/types'
import { HEADER_BREAKPOINT } from '@utils/settings'
import { Logo, WLink, WModal } from '@components/atoms'
import { NavPanel } from '@components/molecules'
import { Close } from '@components/icons'
import styles from './Nav.module.sass'

interface Props {
  item?: MenuItemType
  level?: number
  open?: boolean
  setOpen?: (v: boolean) => void
  className?: string
}

const Nav = (props: Props) => {
  const { item = undefined, level = 0, open = false, setOpen = undefined, className = '' } = props

  const t = useTrans()
  const [prevWidth, setPrevWidth] = useState(undefined)
  const [openLevel, setOpenLevel] = useState(level)

  useEffect(() => {
    const handleResize = () => {
      if (
        !!prevWidth &&
        ((prevWidth < HEADER_BREAKPOINT && window.innerWidth >= HEADER_BREAKPOINT) ||
          (prevWidth >= HEADER_BREAKPOINT && window.innerWidth < HEADER_BREAKPOINT))
      )
        setOpen(false)
      setPrevWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [prevWidth])

  useEffect(() => {
    setOpenLevel(open ? level : level - 1)
  }, [open])

  return item ? (
    <WModal
      id="nav"
      open={open}
      onClose={() => setOpen(false)}
      className={`${styles.root} ${open ? styles['root--open'] : ''} ${
        styles[`root--level${openLevel}`]
      } ${className}`}
    >
      <div className={styles.head}>
        <WLink href={'/'} className={styles.logo}>
          <Logo />
        </WLink>
        <button
          type="button"
          aria-label={t('Chiudi menu')}
          className={styles.close}
          onClick={() => {
            setOpen(false)
            setOpenLevel(0)
          }}
        >
          <Close />
        </button>
      </div>
      <NavPanel item={item} level={level} open setOpen={setOpen} setOpenLevel={setOpenLevel} />
    </WModal>
  ) : (
    <></>
  )
}

export default Nav
