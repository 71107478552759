import React from 'react'
import styles from './Chip.module.sass'

interface Props {
  label?: string
  size?: 'lg' | 'sm'
  className?: string
  href?: string
}

const Chip = (props: Props) => {
  const { label = undefined, size = 'lg', className = '', href = '' } = props

  return label ? (
    href ? (
      <a className={`${styles.root} ${styles[`root--${size}`]} ${className}`} href={href}>
        {label}
      </a>
    ) : (
      <div className={`${styles.root} ${styles[`root--${size}`]} ${className}`}>{label}</div>
    )
  ) : (
    <></>
  )
}

export default Chip
