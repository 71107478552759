import React, { useEffect, useState } from 'react'
import StoreLocator from '../StoreLocator/StoreLocator'
import { AssistenzaRivenditoriStoreLocatorDocument } from '@gql/graphql-get'
import { print } from 'graphql'
import { grapheneGet } from '@utils/index'
import { useRouter } from 'next/router'
import styles from './StoreLocatorAssistenza.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  className?: string
}

const StoreLocatorAssistenza = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const router = useRouter()
  const [rivenditori, setRivenditori] = useState([])

  const fetchRivenditori = async () => {
    const encodedQuery = encodeURIComponent(print(AssistenzaRivenditoriStoreLocatorDocument))
    const response = await grapheneGet.get(
      `?lang=${router.locale}&query=${encodedQuery}&operationName=AssistenzaRivenditoriStoreLocator`
    )
    let { data } = response.data
    setRivenditori(
      data?.assistenzaRivenditoriStoreLocator.map((x) => ({ ...x, tipi: ['assistenza'] }))
    )
  }

  useEffect(() => {
    fetchRivenditori()
  }, [])

  return (
    <div className={styles.root}>
      <StoreLocator
        item={{
          name: t('Centri assistenza'),
          description: t('Cerca il centro assistenza più vicino'),
        }}
        rivenditori={rivenditori}
        rivenditoriFiltered={rivenditori}
        className={className}
      />
    </div>
  )
}

export default StoreLocatorAssistenza
