/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { menusMock } from 'utils/mock'
import { MenuItemType } from 'utils/types'
import { useSelector } from 'react-redux'

export default function useFindMenu(
  chiave: string,
  menu: MenuItemType | undefined = undefined
): MenuItemType | undefined {
  const { menu: reduxMenu } = process.env.STORYBOOK
    ? { menu: menusMock }
    : useSelector((state: any) => state?.menu)

  const findMenu: any = (menu: any, chiave: string) => {
    for (const item of menu.children || menu || []) {
      if (item.key === chiave) return item

      if (item.children) {
        const newItem: object | undefined = findMenu(item.children, chiave)
        if (newItem) return newItem
      }
    }
    return undefined
  }

  return menu ? findMenu(menu, chiave) : findMenu(reduxMenu, chiave)
}
