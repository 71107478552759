import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import CtaToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { getAncestorNode } from 'react-pagebuilder/utils/node'

export const ButtonSettings = () => {
  const { props, id } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }))

  const { parent } = useEditor(
    (state, query) => ({
      parent: getAncestorNode(id, query, 2),
    }),
    props
  )

  const variantOpts = [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
    { label: 'Ghost', value: 'ghost' },
  ]

  const sizeOpts = [
    { label: 'Small', value: 'sm' },
    { label: 'Medium', value: 'md' },
    { label: 'Large', value: 'lg' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="variant"
          type="select"
          label="Variante"
          options={variantOpts}
          defaultValue={defaultProps['variant']}
        />
        {/* La size in alcuni componenti è determinata dal parent */}
        {!['TextImageLarge', 'TextImage', 'TextSection', 'IconTextSection'].includes(
          parent.data?.name
        ) && (
          <ToolbarItem
            propKey="block"
            propSubKey="size"
            type="select"
            label="Dimensione"
            options={sizeOpts}
            defaultValue={defaultProps['size']}
          />
        )}
      </ToolbarSection>
      <CtaToolbarSection defaultProps={defaultProps} initOpen />
    </>
  )
}
