import React, { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react'
import { FormError } from 'components/atoms'
import { CheckboxMarked, CheckboxUnmarked } from 'components/icons'
import styles from './FormCheckbox.module.sass'

interface Props {
  id: string
  label?: ReactNode | string
  name?: string
  value?:
    | (DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & string)
    | number
    | readonly string[]
  className?: string
  color?: '' | 'white'
  size?: 'sm' | 'md'
  checked?: boolean
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const FormCheckbox = (props: Props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    className = '',
    color = '',
    size = 'md',
    checked = false,
    readOnly = false,
    required = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    ${styles.root}
    ${disabled ? styles['root--disabled'] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${color ? styles[`root--${color}`] : ''}
    ${size ? styles[`root--${size}`] : ''}
    ${className ? className : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="checkbox"
          readOnly={readOnly}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error'}
        />
        <span className={styles.styledInput}>
          {checked ? <CheckboxMarked /> : <CheckboxUnmarked />}
        </span>
        <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormCheckbox
