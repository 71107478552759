import React from 'react'
import styles from './LandingNewsletterHeader.module.sass'
import { Logo, WLink } from '@components/atoms'

interface Props {
  className?: string
}

const LandingNewsletterHeader = (props: Props) => {
  const { className = '' } = props

  return (
    <header className={`${styles.root} ${className}`}>
      <WLink href={'/'} className={styles.logo}>
        <Logo />
      </WLink>
    </header>
  )
}

export default LandingNewsletterHeader
