import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription, Tag } from '@components/atoms'
import styles from './TextImage.module.sass'
import { getIcon } from '@utils/icons'
import { checkUrlAndTrackPageBuilder } from '@utils/index'
import { useRouter } from 'next/router'

interface Props {
  item: MenuItemType
  introVariant?:
    | 'black'
    | 'secondarydark'
    | 'white'
    | 'whiteborder'
    | 'promo'
    | 'ideeverdi'
    | 'novita'
    | 'categoria'
  theme?: 'neutrallight' | 'secondarydark' | 'primary' | 'black'
  orientation?: 'vertical' | 'horizontal'
  imagePos?: 'top' | 'bottom'
  data?: string
  caption?: string
  editMode?: boolean
  className?: string
}

const TextImage = (props: Props) => {
  const {
    item = undefined,
    theme = 'neutrallight',
    introVariant = 'black',
    orientation = 'vertical',
    imagePos = 'bottom',
    data = undefined,
    caption = undefined,
    editMode = false,
    className = '',
  } = props

  const router = useRouter()

  return item ? (
    <div
      className={`${styles.root} ${item.image ? styles[`root--${imagePos}`] : ''} ${
        styles[`root--${orientation}`]
      } ${styles[`root--${theme}`]} ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      <div className={styles.main}>
        {(editMode || !!item.intro || !!item.name || !!item.description) && (
          <div className={styles.content}>
            <Tag
              label={item.intro}
              variant={introVariant}
              editMode={editMode}
              className={styles.tag}
            />
            <EditableTitle
              title={data}
              variant="span"
              className={styles.data}
              editMode={editMode}
            />
            <EditableTitle
              title={item.name}
              variant="h3"
              className={styles.title}
              editMode={editMode}
            />
            <EditableDescription
              description={item.description}
              className={styles.description}
              editMode={editMode}
            />
            <EditableDescription
              description={caption}
              className={styles.caption}
              editMode={editMode}
            />
          </div>
        )}
        {editMode ? (
          <>{item.children}</>
        ) : (
          !!item.children?.filter((x) => !!x.url && !!x.ctaLabel)?.length && (
            <div className={styles.ctaBox}>
              {item.children?.map((x, index) => (
                <Button
                  label={x.ctaLabel}
                  href={x.url}
                  onClick={() => checkUrlAndTrackPageBuilder(x, router?.asPath)}
                  newPage={x.blank}
                  iconLeft={
                    x.iconLeft
                      ? x.iconLeft
                      : !!x.icon && x.iconPos === 'left'
                      ? getIcon(x.icon)
                      : undefined
                  }
                  iconRight={
                    x.iconRight
                      ? x.iconRight
                      : !!x.icon && x.iconPos === 'right'
                      ? getIcon(x.icon)
                      : undefined
                  }
                  variant={x.style as 'primary' | 'secondary' | 'ghost'}
                  color={
                    (['secondary', 'ghost'].includes(x.style) &&
                      ['secondarydark', 'black'].includes(theme)) ||
                    theme === 'primary'
                      ? 'white'
                      : ''
                  }
                  theme={['secondarydark', 'primary', 'black'].includes(theme) ? 'dark' : 'light'}
                  size={'lg'}
                  mobileSize={'md'}
                />
              ))}
            </div>
          )
        )}
      </div>
      {!!item.image && (
        <div className={`${styles.imageBox}`}>
          <WImage
            src={item.image}
            maxWidth={600}
            maxHeight={300}
            objectFit="cover"
            alt={item.alt}
            title={item.alt}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default TextImage
