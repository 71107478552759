import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import { useMyAccountChangePasswordMutation, useMyAccountEditMutation } from '@gql/graphql'
import { myAccountProfileEditPasswordValidationSchema } from '@utils/validationSchemas'
import { Button, FormError, FormikField, FormLayout } from '@components/atoms'
import styles from './MyAccountChangePasswordForm.module.sass'
import { handleRescuePasswordTracking } from '@utils/tracking'

interface Props {
  className?: string
}

const MyAccountChangePasswordForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const router = useRouter()
  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  }

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [myAccountChangePassword] = useMyAccountChangePasswordMutation({
    onCompleted: (res) => {
      if (!res.myAccountChangePassword?.status) {
        raiseGlobalError(
          res?.myAccountChangePassword?.message || t('Errore durante il salvataggio')
        )
      } else {
        router.push('/login')
      }
    },
    onError: () => {
      // Errore non previsto - generico
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={myAccountProfileEditPasswordValidationSchema(t)}
        onSubmit={(values) => {
          setGlobalLoading(true)
          myAccountChangePassword({
            variables: values,
          })
        }}
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <FormError
              id="change_password_global_error"
              message={globalError}
              visible={!!globalError}
              variant="global"
              className={styles.globalError}
            />
            <FormLayout space="lg">
              <FormikField
                formik={formikProps}
                id={'change_password_old_password'}
                name="oldPassword"
                type="password"
                label={t('Password attuale')}
                placeholder={t('Inserisci la tua password attuale')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'change_password_new_password'}
                name="newPassword"
                type="password"
                label={t('Nuova Password')}
                placeholder={t('Inserisci la tua nuova password')}
                required
              />
            </FormLayout>
            <div className={styles.info}>
              {t(
                'Dopo aver generato la nuova password, ti inviteremo a effettuare il login con le credenziali aggiornate, così da garantire la massima protezione dei tuoi dati.'
              )}
            </div>
            <div className={styles.ctaBox}>
              <Button type="submit" label={t('Conferma')} size="lg" loading={globalLoading} />
              <Button
                label={t('Annulla')}
                variant="secondary"
                href={'/myaccount/profile'}
                size="lg"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MyAccountChangePasswordForm
