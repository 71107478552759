import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  GenericScalar: any;
};

export type AttributoMultiploType = {
  __typename?: 'AttributoMultiploType';
  chiave?: Maybe<Scalars['String']>;
  valori?: Maybe<Array<Maybe<ValoreAttributoNode>>>;
};

export type AttributoNode = {
  __typename?: 'AttributoNode';
  alias: Scalars['String'];
  /** Gli attributi visualizzati nella categoria */
  categorie: CategoriaNodeConnection;
  chiave: Scalars['String'];
  children: Array<AttributoNode>;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  nome: Scalars['String'];
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  parent?: Maybe<AttributoNode>;
  pk?: Maybe<Scalars['Int']>;
  valori: Array<ValoreAttributoNode>;
};


export type AttributoNodeCategorieArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};

export type BreadcrumbType = {
  __typename?: 'BreadcrumbType';
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CategoriaNewsNode = {
  __typename?: 'CategoriaNewsNode';
  adminUrl?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrl?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  depth: Scalars['Int'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCatalogo?: Maybe<Scalars['Boolean']>;
  lft: Scalars['Int'];
  mainImage?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  modelClass?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  news: Array<NewsNode>;
  noindex: Scalars['Boolean'];
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  pagina?: Maybe<PaginaNode>;
  pk?: Maybe<Scalars['Int']>;
  previewImage?: Maybe<Scalars['String']>;
  rgt: Scalars['Int'];
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoDescriptionEnGb?: Maybe<Scalars['String']>;
  seoDescriptionEs?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  seoH1EnGb?: Maybe<Scalars['String']>;
  seoH1Es?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoTitleEnGb?: Maybe<Scalars['String']>;
  seoTitleEs?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  treeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export type CategoriaNode = Node & {
  __typename?: 'CategoriaNode';
  adminUrl?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  attivo: Scalars['Boolean'];
  /** Gli attributi visualizzati nella categoria */
  attributi: Array<AttributoNode>;
  boxSottotitolo?: Maybe<Scalars['String']>;
  boxSottotitoloEn?: Maybe<Scalars['String']>;
  boxSottotitoloEnGb?: Maybe<Scalars['String']>;
  boxSottotitoloEs?: Maybe<Scalars['String']>;
  boxSottotitoloIt?: Maybe<Scalars['String']>;
  boxTesto?: Maybe<Scalars['String']>;
  boxTestoEn?: Maybe<Scalars['String']>;
  boxTestoEnGb?: Maybe<Scalars['String']>;
  boxTestoEs?: Maybe<Scalars['String']>;
  boxTestoIt?: Maybe<Scalars['String']>;
  boxTitolo?: Maybe<Scalars['String']>;
  boxTitoloEn?: Maybe<Scalars['String']>;
  boxTitoloEnGb?: Maybe<Scalars['String']>;
  boxTitoloEs?: Maybe<Scalars['String']>;
  boxTitoloIt?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrl?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEn?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEnGb?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEs?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlIt?: Maybe<Scalars['String']>;
  chiave: Scalars['String'];
  children?: Maybe<Array<Maybe<CategoriaNode>>>;
  created?: Maybe<Scalars['DateTime']>;
  depth: Scalars['Int'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneVolantino?: Maybe<Scalars['String']>;
  descrizioneVolantinoEn?: Maybe<Scalars['String']>;
  descrizioneVolantinoEnGb?: Maybe<Scalars['String']>;
  descrizioneVolantinoEs?: Maybe<Scalars['String']>;
  descrizioneVolantinoIt?: Maybe<Scalars['String']>;
  googleProductCategory?: Maybe<Scalars['Int']>;
  guidaBreve?: Maybe<Scalars['String']>;
  guidaDettagliata?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Categoria visualizzata in homepage sotto alla hero */
  inHome: Scalars['Boolean'];
  /** Categoria visualizzabile nel menu principale */
  inMenu: Scalars['Boolean'];
  invisibile: Scalars['Int'];
  lft: Scalars['Int'];
  /** Sottocategoria visibile nella pagina della categoria padre */
  listingVisibileInPadre: Scalars['Boolean'];
  mainImage?: Maybe<Scalars['String']>;
  masterprodottoSet: Array<MasterProdottoDocumentiNode>;
  masters: Array<MasterProdottoDocumentiNode>;
  mastersCorrelati: Array<MasterProdottoDocumentiNode>;
  mastersOfferta: Array<MasterProdottoDocumentiNode>;
  mastersStaticizzati: Array<MasterProdottoDocumentiNode>;
  model?: Maybe<Scalars['Int']>;
  modelClass?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  noindex: Scalars['Boolean'];
  noindexEn: Scalars['Boolean'];
  noindexEnGb: Scalars['Boolean'];
  noindexEs: Scalars['Boolean'];
  noindexIt: Scalars['Boolean'];
  nome: Scalars['String'];
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  numeroProdotti?: Maybe<Scalars['Int']>;
  offertaMarketingBox?: Maybe<OffertaMarketingBoxNode>;
  ordine: Scalars['Int'];
  pagebuilders?: Maybe<Array<Maybe<PagebuilderPlaceholderNode>>>;
  pagina?: Maybe<PaginaNode>;
  pagine: PaginaNodeConnection;
  parent?: Maybe<CategoriaNode>;
  path?: Maybe<Scalars['String']>;
  pathEn?: Maybe<Scalars['String']>;
  pathEnGb?: Maybe<Scalars['String']>;
  pathEs?: Maybe<Scalars['String']>;
  pathIt?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  plusPoints: Array<PlusPointNode>;
  previewImage?: Maybe<Scalars['String']>;
  prodottiLanding?: Maybe<Array<Maybe<ProdottoNode>>>;
  prodottiTopDelMese?: Maybe<Array<Maybe<ProdottoNode>>>;
  /** Regola per l'associazione automatica dei prodotti a questa categoria */
  regolaAssociazioneMaster?: Maybe<CategorieCategoriaRegolaAssociazioneMasterChoices>;
  rgt: Scalars['Int'];
  rilevanza: Scalars['Int'];
  rilevanzaTotale?: Maybe<Scalars['Int']>;
  rootMenuSecondario?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoDescriptionEnGb?: Maybe<Scalars['String']>;
  seoDescriptionEs?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  seoH1EnGb?: Maybe<Scalars['String']>;
  seoH1Es?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoTitleEnGb?: Maybe<Scalars['String']>;
  seoTitleEs?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Maybe<CategoriaNode>>>;
  slug: Scalars['String'];
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  sottoTitolo?: Maybe<Scalars['String']>;
  sottoTitoloEn?: Maybe<Scalars['String']>;
  sottoTitoloEnGb?: Maybe<Scalars['String']>;
  sottoTitoloEs?: Maybe<Scalars['String']>;
  sottoTitoloIt?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  treeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  valoriAttributo: Array<ValoreAttributoNode>;
  visibileDal?: Maybe<Scalars['DateTime']>;
  visibileFinoal?: Maybe<Scalars['DateTime']>;
  voceMenu: Scalars['String'];
  voceMenuEn?: Maybe<Scalars['String']>;
  voceMenuEnGb?: Maybe<Scalars['String']>;
  voceMenuEs?: Maybe<Scalars['String']>;
  voceMenuIt?: Maybe<Scalars['String']>;
  /** name entity wikidata per schema categoria */
  wikidataName?: Maybe<Scalars['String']>;
  /** url wikidata per schema categoria */
  wikidataUrl?: Maybe<Scalars['String']>;
};


export type CategoriaNodePagineArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tipologia?: InputMaybe<PagesPaginaTipologiaChoices>;
};

export type CategoriaNodeConnection = {
  __typename?: 'CategoriaNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CategoriaNode` and its cursor. */
export type CategoriaNodeEdge = {
  __typename?: 'CategoriaNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CategoriaNode>;
};

export type CategoriaWarrantyNode = {
  __typename?: 'CategoriaWarrantyNode';
  nome?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CategorieCategoriaRegolaAssociazioneMasterChoices {
  /** In promozione flash */
  FlashPromotion = 'FLASH_PROMOTION',
  /** In promozione (non flash) */
  Promotion = 'PROMOTION'
}

export type DescrizioneAggiuntivaProdottoNode = {
  __typename?: 'DescrizioneAggiuntivaProdottoNode';
  id: Scalars['ID'];
  image?: Maybe<ImageNode>;
  imageLingua?: Maybe<ImageNode>;
  imageLinguaEn?: Maybe<ImageNode>;
  imageLinguaEnGb?: Maybe<ImageNode>;
  imageLinguaEs?: Maybe<ImageNode>;
  imageLinguaIt?: Maybe<ImageNode>;
  prodotto: ProdottoNode;
  testo?: Maybe<Scalars['String']>;
  testoEn?: Maybe<Scalars['String']>;
  testoEnGb?: Maybe<Scalars['String']>;
  testoEs?: Maybe<Scalars['String']>;
  testoIt?: Maybe<Scalars['String']>;
};

export type DistributoreNode = {
  __typename?: 'DistributoreNode';
  area?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  codiceEmak?: Maybe<Scalars['String']>;
  codiceRif?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  continente?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailCommerciale?: Maybe<Scalars['String']>;
  emailTecnica?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indirizzo?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  latitudine?: Maybe<Scalars['String']>;
  localita?: Maybe<Scalars['String']>;
  longitudine?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nazione?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  provincia?: Maybe<Scalars['String']>;
  ragioneSociale?: Maybe<Scalars['String']>;
  sito?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
};

export type DocumentoNode = {
  __typename?: 'DocumentoNode';
  attivo: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  news: NewsNode;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  titolo?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  titoloEnGb?: Maybe<Scalars['String']>;
  titoloEs?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DocumentoProdottoNode = {
  __typename?: 'DocumentoProdottoNode';
  attivo: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lingua: Array<LinguaDocumentoProdottoNode>;
  lingue?: Maybe<Array<Maybe<LinguaDocumentoProdottoNode>>>;
  master: Array<MasterProdottoDocumentiNode>;
  modified?: Maybe<Scalars['DateTime']>;
  nomeDocumento?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  prodotto: Array<ProdottoNode>;
  /** Un titolo che potrebbe venir mostrato in scheda prodotto */
  titolo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FamigliaSistemaPagamentoNode = {
  __typename?: 'FamigliaSistemaPagamentoNode';
  alias?: Maybe<Scalars['String']>;
  chiave?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  sistemiPagamento: Array<SistemaPagamentoNode>;
};

export type FiltroCategoriaNode = {
  __typename?: 'FiltroCategoriaNode';
  disabled?: Maybe<Scalars['Boolean']>;
  facets?: Maybe<Scalars['Int']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nome?: Maybe<Scalars['String']>;
  reset?: Maybe<Scalars['Boolean']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type GroupedSistemaPagamentoNode = {
  __typename?: 'GroupedSistemaPagamentoNode';
  children?: Maybe<Array<Maybe<SistemaPagamentoNode>>>;
  descrizione?: Maybe<Scalars['String']>;
  famiglia?: Maybe<FamigliaSistemaPagamentoNode>;
  pk?: Maybe<Scalars['Int']>;
  rivenditore?: Maybe<RivenditoreNode>;
};

export type HrefLangType = {
  __typename?: 'HrefLangType';
  href?: Maybe<Scalars['String']>;
  hrefLang?: Maybe<Scalars['String']>;
};

export type ImageNode = {
  __typename?: 'ImageNode';
  FileSize?: Maybe<Scalars['BigInt']>;
  Height?: Maybe<Scalars['Float']>;
  Transparent: Scalars['Boolean'];
  Width?: Maybe<Scalars['Float']>;
  alt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  dateTaken?: Maybe<Scalars['DateTime']>;
  defaultAltText?: Maybe<Scalars['String']>;
  defaultCaption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descrizioniAggiuntive: Array<DescrizioneAggiuntivaProdottoNode>;
  descrizioniAggiuntiveAsInLingua: Array<DescrizioneAggiuntivaProdottoNode>;
  descrizioniAggiuntiveAsInLinguaEn: Array<DescrizioneAggiuntivaProdottoNode>;
  descrizioniAggiuntiveAsInLinguaEnGb: Array<DescrizioneAggiuntivaProdottoNode>;
  descrizioniAggiuntiveAsInLinguaEs: Array<DescrizioneAggiuntivaProdottoNode>;
  descrizioniAggiuntiveAsInLinguaIt: Array<DescrizioneAggiuntivaProdottoNode>;
  file?: Maybe<Scalars['String']>;
  hasAllMandatoryData: Scalars['Boolean'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Disabilita ogni controllo dei permessi per questo file. Il file sarà accessibile da chiunque */
  isPublic: Scalars['Boolean'];
  /** MIME type of uploaded content */
  mimeType: Scalars['String'];
  modifiedAt: Scalars['DateTime'];
  mustAlwaysPublishAuthorCredit: Scalars['Boolean'];
  mustAlwaysPublishCopyright: Scalars['Boolean'];
  name: Scalars['String'];
  offertamarketingbox: Array<OffertaMarketingBoxNode>;
  originalFilename?: Maybe<Scalars['String']>;
  owner?: Maybe<UtenteNode>;
  pagina: PaginaNodeConnection;
  paginaPreviews: PaginaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  pluspointSet: Array<PlusPointNode>;
  popup: Array<PopupNode>;
  popupMobile: Array<PopupNode>;
  sha1: Scalars['String'];
  sistemiPagamento: Array<SistemaPagamentoNode>;
  subjectLocation: Scalars['String'];
  uploadedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  valoriAttributi: Array<ValoreAttributoNode>;
  videonews: Array<VideoNewsNode>;
  width?: Maybe<Scalars['Int']>;
};


export type ImageNodePaginaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tipologia?: InputMaybe<PagesPaginaTipologiaChoices>;
};


export type ImageNodePaginaPreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tipologia?: InputMaybe<PagesPaginaTipologiaChoices>;
};

export type ImmagineProdottoType = {
  __typename?: 'ImmagineProdottoType';
  descrizione?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
};

export type IndirizzoNode = {
  __typename?: 'IndirizzoNode';
  cancellato: Scalars['Boolean'];
  cap?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  frazione?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idOld?: Maybe<Scalars['Float']>;
  indirizzo?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  isFatturazione: Scalars['Boolean'];
  modified?: Maybe<Scalars['DateTime']>;
  nazione: NazioneNode;
  nome?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  numeroCivico?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  presso?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  ragioneSociale?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  telefono2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  utente: UtenteNode;
};

export type InteresseNewsletterNode = {
  __typename?: 'InteresseNewsletterNode';
  alias?: Maybe<Scalars['String']>;
  /** Categoria relativa all' interesse */
  categoria: NewsletterInteresseNewsletterCategoriaChoices;
  descrizione?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailchimpInterestId?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  utenti: UtenteNodeConnection;
};


export type InteresseNewsletterNodeUtentiArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LinguaDocumentoProdottoNode = {
  __typename?: 'LinguaDocumentoProdottoNode';
  /** Un titolo che potrebbe venir mostrato in scheda prodotto */
  codice?: Maybe<Scalars['String']>;
  documenti: Array<DocumentoProdottoNode>;
  id: Scalars['ID'];
  ordinamento: Scalars['Int'];
  titolo?: Maybe<Scalars['String']>;
};

export type ListingCategoriaNode = {
  __typename?: 'ListingCategoriaNode';
  attributi?: Maybe<Array<Maybe<ParentFiltroCategoriaNode>>>;
  canonicalUrl?: Maybe<Scalars['String']>;
  elementi?: Maybe<Array<Maybe<ProdottoNode>>>;
  ordinamenti?: Maybe<Array<Maybe<OrdinamentoNode>>>;
  paginator?: Maybe<PaginatorNode>;
  params?: Maybe<Scalars['GenericScalar']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  route?: Maybe<Scalars['String']>;
};

export type MasterProdottoDocumentiNode = {
  __typename?: 'MasterProdottoDocumentiNode';
  alias?: Maybe<Scalars['String']>;
  attivo: Scalars['Boolean'];
  categoriaSet: CategoriaNodeConnection;
  categorie: CategoriaNodeConnection;
  categorieAsStaticizzati: CategoriaNodeConnection;
  categorieCorrelate: CategoriaNodeConnection;
  categorieOfferta: CategoriaNodeConnection;
  codice: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  documenti?: Maybe<Array<Maybe<DocumentoProdottoNode>>>;
  id: Scalars['ID'];
  masterCorrelati: Array<MasterProdottoDocumentiNode>;
  masterprodottoSet: Array<MasterProdottoDocumentiNode>;
  modified?: Maybe<Scalars['DateTime']>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  prodotti: Array<ProdottoNode>;
};


export type MasterProdottoDocumentiNodeCategoriaSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};


export type MasterProdottoDocumentiNodeCategorieArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};


export type MasterProdottoDocumentiNodeCategorieAsStaticizzatiArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};


export type MasterProdottoDocumentiNodeCategorieCorrelateArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};


export type MasterProdottoDocumentiNodeCategorieOffertaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};

export type NazioneNode = {
  __typename?: 'NazioneNode';
  alias: Scalars['String'];
  attiva: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  indirizzi: Array<IndirizzoNode>;
  isoCode: Scalars['String'];
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
};

export type NewsNode = {
  __typename?: 'NewsNode';
  adminUrl?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrl?: Maybe<Scalars['String']>;
  categoria?: Maybe<CategoriaNewsNode>;
  categorie: Array<CategoriaNewsNode>;
  contenutoIndicizzabile?: Maybe<Scalars['String']>;
  correlati?: Maybe<Array<Maybe<NewsNode>>>;
  created?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['Date']>;
  dataOraPubblicazione: Scalars['DateTime'];
  documenti: Array<DocumentoNode>;
  documentiVisibili?: Maybe<Array<Maybe<DocumentoNode>>>;
  evisionId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  inListing: Scalars['Boolean'];
  isExportable: Scalars['Boolean'];
  listingVideos?: Maybe<VideoNewsNode>;
  mainImage?: Maybe<Scalars['String']>;
  messaggio?: Maybe<Scalars['String']>;
  messaggioEn?: Maybe<Scalars['String']>;
  messaggioEnGb?: Maybe<Scalars['String']>;
  messaggioEs?: Maybe<Scalars['String']>;
  messaggioIt?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  modelClass?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  noindex: Scalars['Boolean'];
  pagebuilders?: Maybe<Array<Maybe<PagebuilderPlaceholderNode>>>;
  pagina?: Maybe<PaginaNode>;
  pk?: Maybe<Scalars['Int']>;
  previewImage?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoDescriptionEnGb?: Maybe<Scalars['String']>;
  seoDescriptionEs?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  seoH1EnGb?: Maybe<Scalars['String']>;
  seoH1Es?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoTitleEnGb?: Maybe<Scalars['String']>;
  seoTitleEs?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  sottotitolo?: Maybe<Scalars['String']>;
  sottotitoloEn?: Maybe<Scalars['String']>;
  sottotitoloEnGb?: Maybe<Scalars['String']>;
  sottotitoloEs?: Maybe<Scalars['String']>;
  sottotitoloIt?: Maybe<Scalars['String']>;
  sottotitoloListing?: Maybe<Scalars['String']>;
  sottotitoloListingEn?: Maybe<Scalars['String']>;
  sottotitoloListingEnGb?: Maybe<Scalars['String']>;
  sottotitoloListingEs?: Maybe<Scalars['String']>;
  sottotitoloListingIt?: Maybe<Scalars['String']>;
  tempoLettura: Scalars['BigInt'];
  testo?: Maybe<Scalars['String']>;
  testoEn?: Maybe<Scalars['String']>;
  testoEnGb?: Maybe<Scalars['String']>;
  testoEs?: Maybe<Scalars['String']>;
  testoIt?: Maybe<Scalars['String']>;
  titolo?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  titoloEnGb?: Maybe<Scalars['String']>;
  titoloEs?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  titoloListing?: Maybe<Scalars['String']>;
  titoloListingEn?: Maybe<Scalars['String']>;
  titoloListingEnGb?: Maybe<Scalars['String']>;
  titoloListingEs?: Maybe<Scalars['String']>;
  titoloListingIt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  video: Array<VideoNewsNode>;
};

/** An enumeration. */
export enum NewsletterInteresseNewsletterCategoriaChoices {
  /** Ambiente di lavoro */
  Ambiente = 'AMBIENTE',
  /** Ambiente di lavoro giardinaggio */
  AmbienteGiardinaggio = 'AMBIENTE_GIARDINAGGIO',
  /** Attività di interesse */
  Attivita = 'ATTIVITA'
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type OffertaMarketingBoxNode = {
  __typename?: 'OffertaMarketingBoxNode';
  categoriaSet: CategoriaNodeConnection;
  ctaLabel?: Maybe<Scalars['String']>;
  /** Tooltip */
  helper?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imageSolo: Scalars['Boolean'];
  intro?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['Int']>;
  prezzo?: Maybe<Scalars['Int']>;
  prezzoScontato?: Maybe<Scalars['Int']>;
  sottotitolo?: Maybe<Scalars['String']>;
  testo?: Maybe<Scalars['String']>;
  titolo?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  visibileDal?: Maybe<Scalars['DateTime']>;
  visibileFinoal?: Maybe<Scalars['DateTime']>;
};


export type OffertaMarketingBoxNodeCategoriaSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};

export type OrdinamentoNode = {
  __typename?: 'OrdinamentoNode';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type OrdineNode = Node & {
  __typename?: 'OrdineNode';
  aliasScontoBundles?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  codice?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['String']>;
  dataFattura?: Maybe<Scalars['Date']>;
  dataRicevuta?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  fatturaRichiesta: Scalars['Boolean'];
  giacenzaScalata: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  importoTasseSulTotaleScontato: Scalars['Float'];
  includiMessaggio: Scalars['Boolean'];
  indirizzoFatturazione?: Maybe<IndirizzoNode>;
  indirizzoSpedizione?: Maybe<IndirizzoNode>;
  marginalita: Scalars['Float'];
  messaggio?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  noteConsegna?: Maybe<Scalars['String']>;
  numeroFattura?: Maybe<Scalars['Int']>;
  numeroOdv?: Maybe<Scalars['String']>;
  numeroRicevuta?: Maybe<Scalars['Int']>;
  pagina?: Maybe<PaginaNode>;
  paymentId?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  percentualeTassazione: Scalars['Float'];
  peso?: Maybe<Scalars['Float']>;
  piva?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  prezzoTotale?: Maybe<Scalars['Float']>;
  prezzoTotaleScontato: Scalars['Float'];
  prezzoTotaleScontatoNetto: Scalars['Float'];
  privato: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  promozione?: Maybe<PromozioneNode>;
  promozioneValoreAssoluto: Scalars['Float'];
  promozioneValorePercentuale: Scalars['Float'];
  ragioneSociale?: Maybe<Scalars['String']>;
  rendibile?: Maybe<Scalars['Boolean']>;
  righeOrdine?: Maybe<Array<Maybe<RigaOrdineNode>>>;
  rivenditore?: Maybe<RivenditoreNode>;
  scontoBundles: Scalars['Float'];
  sdi?: Maybe<Scalars['String']>;
  securitytoken?: Maybe<Scalars['String']>;
  sistemaPagamento?: Maybe<SistemaPagamentoNode>;
  sistemaSpedizione?: Maybe<SistemaSpedizioneNode>;
  stato: StatoNode;
  trackingUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  utente?: Maybe<UtenteNode>;
  valuta: ValutaNode;
};

export type OrdineNodeConnection = {
  __typename?: 'OrdineNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrdineNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrdineNode` and its cursor. */
export type OrdineNodeEdge = {
  __typename?: 'OrdineNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OrdineNode>;
};

/** An enumeration. */
export enum OrdiniRigaOrdineTipologiaChoices {
  /** prodotto */
  Prodotto = 'PRODOTTO',
  /** spedizione */
  Spedizione = 'SPEDIZIONE'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PageNode = {
  __typename?: 'PageNode';
  href?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
};

export type PagebuilderPlaceholderNode = {
  __typename?: 'PagebuilderPlaceholderNode';
  created?: Maybe<Scalars['DateTime']>;
  draft: Scalars['Boolean'];
  id: Scalars['ID'];
  jsonDraft?: Maybe<Scalars['String']>;
  jsonPublished?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  objectId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PagesPaginaStatoPubblicazioneChoices {
  /** Non Pubblicato */
  Nopubblicato = 'NOPUBBLICATO',
  /** Pubblicato */
  Pubblicato = 'PUBBLICATO'
}

/** An enumeration. */
export enum PagesPaginaTipologiaChoices {
  /** Pagina */
  Page = 'PAGE',
  /** Pagina applicazione */
  PageApp = 'PAGE_APP',
  /** Pagine Entità */
  PageAppMany = 'PAGE_APP_MANY'
}

export type PaginaNode = Node & {
  __typename?: 'PaginaNode';
  adminUrl?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrl?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEn?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEnGb?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEs?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlIt?: Maybe<Scalars['String']>;
  categorie?: Maybe<Array<Maybe<CategoriaNode>>>;
  categorieLanding?: Maybe<Array<Maybe<CategoriaNode>>>;
  chiave?: Maybe<Scalars['String']>;
  chiaveMenu?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<PaginaNode>>>;
  created?: Maybe<Scalars['DateTime']>;
  ctaLabel?: Maybe<Scalars['String']>;
  ctaLabelEn?: Maybe<Scalars['String']>;
  ctaLabelEnGb?: Maybe<Scalars['String']>;
  ctaLabelEs?: Maybe<Scalars['String']>;
  ctaLabelIt?: Maybe<Scalars['String']>;
  ctaUrl?: Maybe<Scalars['String']>;
  ctaUrlEn?: Maybe<Scalars['String']>;
  ctaUrlEnGb?: Maybe<Scalars['String']>;
  ctaUrlEs?: Maybe<Scalars['String']>;
  ctaUrlIt?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  hreflangs?: Maybe<Array<Maybe<HrefLangType>>>;
  icon?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  inRicerca: Scalars['Boolean'];
  inSitemap: Scalars['Boolean'];
  intro?: Maybe<Scalars['String']>;
  introEn?: Maybe<Scalars['String']>;
  introEnGb?: Maybe<Scalars['String']>;
  introEs?: Maybe<Scalars['String']>;
  introIt?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  migrata: Scalars['Boolean'];
  model?: Maybe<Scalars['Int']>;
  modelClass?: Maybe<Scalars['String']>;
  modificaUrl?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  noindex: Scalars['Boolean'];
  noindexEn: Scalars['Boolean'];
  noindexEnGb: Scalars['Boolean'];
  noindexEs: Scalars['Boolean'];
  noindexIt: Scalars['Boolean'];
  /** Visibile nelle breadcrumbs */
  nome?: Maybe<Scalars['String']>;
  /** Visibile nelle breadcrumbs */
  nomeEn?: Maybe<Scalars['String']>;
  /** Visibile nelle breadcrumbs */
  nomeEnGb?: Maybe<Scalars['String']>;
  /** Visibile nelle breadcrumbs */
  nomeEs?: Maybe<Scalars['String']>;
  /** Visibile nelle breadcrumbs */
  nomeIt?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  pagebuilders?: Maybe<Array<Maybe<PagebuilderPlaceholderNode>>>;
  pagina?: Maybe<PaginaNode>;
  parent?: Maybe<PaginaNode>;
  path?: Maybe<Scalars['String']>;
  pathEn?: Maybe<Scalars['String']>;
  pathEnGb?: Maybe<Scalars['String']>;
  pathEs?: Maybe<Scalars['String']>;
  pathIt?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  previewImage?: Maybe<Scalars['String']>;
  prodotti: Array<ProdottoNode>;
  pubblicato?: Maybe<Scalars['Boolean']>;
  seo?: Maybe<SeoType>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoDescriptionEnGb?: Maybe<Scalars['String']>;
  seoDescriptionEs?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoTitleEnGb?: Maybe<Scalars['String']>;
  seoTitleEs?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  siblings?: Maybe<Array<Maybe<PaginaNode>>>;
  slug?: Maybe<Scalars['String']>;
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  statoPubblicazione: PagesPaginaStatoPubblicazioneChoices;
  template?: Maybe<Scalars['String']>;
  /** Usato per le pagine migrate */
  testo?: Maybe<Scalars['String']>;
  tipologia: PagesPaginaTipologiaChoices;
  titolo: Scalars['String'];
  titoloEn?: Maybe<Scalars['String']>;
  titoloEnGb?: Maybe<Scalars['String']>;
  titoloEs?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  visibile?: Maybe<Scalars['Boolean']>;
  visibilitaAl?: Maybe<Scalars['String']>;
  visibilitaDal?: Maybe<Scalars['String']>;
};

export type PaginaNodeConnection = {
  __typename?: 'PaginaNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PaginaNode` and its cursor. */
export type PaginaNodeEdge = {
  __typename?: 'PaginaNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PaginaNode>;
};

export type PaginatorNode = {
  __typename?: 'PaginatorNode';
  count?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  numItems?: Maybe<Scalars['Int']>;
  numPages?: Maybe<Scalars['Int']>;
  pages?: Maybe<Array<Maybe<PageNode>>>;
};

export type ParentFiltroCategoriaNode = {
  __typename?: 'ParentFiltroCategoriaNode';
  children?: Maybe<Array<Maybe<FiltroCategoriaNode>>>;
  nome?: Maybe<Scalars['String']>;
};

export type PlusPointNode = {
  __typename?: 'PlusPointNode';
  categoria?: Maybe<CategoriaNode>;
  descrizione: Scalars['String'];
  id: Scalars['ID'];
  immagine?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
  prodotto?: Maybe<ProdottoNode>;
  titolo?: Maybe<Scalars['String']>;
};

export type PopupNode = {
  __typename?: 'PopupNode';
  ctaLabel1?: Maybe<Scalars['String']>;
  ctaLabel1En?: Maybe<Scalars['String']>;
  ctaLabel1EnGb?: Maybe<Scalars['String']>;
  ctaLabel1Es?: Maybe<Scalars['String']>;
  ctaLabel1It?: Maybe<Scalars['String']>;
  ctaLabel2?: Maybe<Scalars['String']>;
  ctaLabel2En?: Maybe<Scalars['String']>;
  ctaLabel2EnGb?: Maybe<Scalars['String']>;
  ctaLabel2Es?: Maybe<Scalars['String']>;
  ctaLabel2It?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  linkedObjectId1?: Maybe<Scalars['Int']>;
  linkedObjectId2?: Maybe<Scalars['Int']>;
  mobileImage?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  titolo?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  titoloEnGb?: Maybe<Scalars['String']>;
  titoloEs?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  url1?: Maybe<Scalars['String']>;
  url2?: Maybe<Scalars['String']>;
  /** Data e ora fino a quando sarà visibile (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  /** Data e ora da quando sarà visibile (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum ProdottiStatoProdottoAzioneChoices {
  /** 200 */
  A_200 = 'A_200',
  /** 301 */
  A_301 = 'A_301',
  /** 404 */
  A_404 = 'A_404'
}

export type ProdottoNode = {
  __typename?: 'ProdottoNode';
  accessoriCorrelati: Array<ProdottoNode>;
  adminUrl?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  associati: Array<ProdottoNode>;
  attributi?: Maybe<Array<Maybe<AttributoMultiploType>>>;
  boughtTogether?: Maybe<Array<Maybe<ProdottoNode>>>;
  bundle: Scalars['Boolean'];
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrl?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEn?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEnGb?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlEs?: Maybe<Scalars['String']>;
  /** URL relativo. Valorizzato con l'URL dell'elemento se lasciato vuoto. */
  canonicalUrlIt?: Maybe<Scalars['String']>;
  categoria?: Maybe<CategoriaNode>;
  categoriaCompatibili?: Maybe<CategoriaNode>;
  categorieCorrelate?: Maybe<Array<Maybe<CategoriaNode>>>;
  codice?: Maybe<Scalars['String']>;
  costoSpedizioneVolume: Scalars['Float'];
  created?: Maybe<Scalars['DateTime']>;
  defaultMaster: Scalars['Boolean'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneBreve?: Maybe<Scalars['String']>;
  descrizioneBreveEn?: Maybe<Scalars['String']>;
  descrizioneBreveEnGb?: Maybe<Scalars['String']>;
  descrizioneBreveEs?: Maybe<Scalars['String']>;
  descrizioneBreveIt?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioniAggiuntive: Array<DescrizioneAggiuntivaProdottoNode>;
  detailImages?: Maybe<Array<Maybe<ImmagineProdottoType>>>;
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  disponibile?: Maybe<Scalars['Boolean']>;
  documenti?: Maybe<Array<Maybe<DocumentoProdottoNode>>>;
  ecotassa: Scalars['Float'];
  id: Scalars['ID'];
  inPromoBundle?: Maybe<Scalars['Boolean']>;
  isAbbigliamento?: Maybe<Scalars['Boolean']>;
  isAccessorio?: Maybe<Scalars['Boolean']>;
  isPwDouble: Scalars['Boolean'];
  labelTendina?: Maybe<Scalars['String']>;
  lancio: Scalars['Boolean'];
  linkVideo?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  master: MasterProdottoDocumentiNode;
  model?: Maybe<Scalars['Int']>;
  modelClass?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** Visualizza nei listing e in scheda prodotto quando manca alla scadenza della promozione. */
  mostraCounter: Scalars['Boolean'];
  noindex: Scalars['Boolean'];
  noindexEn: Scalars['Boolean'];
  noindexEnGb: Scalars['Boolean'];
  noindexEs: Scalars['Boolean'];
  noindexIt: Scalars['Boolean'];
  nome: Scalars['String'];
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  novita?: Maybe<Scalars['Boolean']>;
  ordinamento: Scalars['Int'];
  ordinamentoTopDelMese: Scalars['Int'];
  pagina?: Maybe<PaginaNode>;
  pagine: PaginaNodeConnection;
  /** Peso espresso in KG */
  peso?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Int']>;
  plusPoints?: Maybe<Array<Maybe<PlusPointNode>>>;
  previewImage?: Maybe<Scalars['String']>;
  prezzo?: Maybe<Scalars['Float']>;
  prezzoOmnibus?: Maybe<Scalars['Float']>;
  prezzoScontato?: Maybe<Scalars['Float']>;
  prezzoSellin?: Maybe<Scalars['Float']>;
  prezzoUnitario?: Maybe<Scalars['Float']>;
  prezzoUnitarioScontato?: Maybe<Scalars['Float']>;
  prodottiAssociati: Array<ProdottoNode>;
  prodottiCompatibili?: Maybe<Array<Maybe<ProdottoNode>>>;
  prodottiCorrelati: Array<ProdottoNode>;
  prodottiTendina?: Maybe<Array<Maybe<ProdottoNode>>>;
  promo?: Maybe<Scalars['Boolean']>;
  promoApplicata?: Maybe<PromozioneNode>;
  promoBundle?: Maybe<PromozioneNode>;
  promozioni: Array<PromozioneNode>;
  promozioniAbbinate: Array<PromozioneNode>;
  promozioniNegative: Array<PromozioneNode>;
  rating: Scalars['Float'];
  rif?: Maybe<Scalars['String']>;
  righeWishlist: Array<RigaWishlistNode>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionEn?: Maybe<Scalars['String']>;
  seoDescriptionEnGb?: Maybe<Scalars['String']>;
  seoDescriptionEs?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoH1?: Maybe<Scalars['String']>;
  seoH1En?: Maybe<Scalars['String']>;
  seoH1EnGb?: Maybe<Scalars['String']>;
  seoH1Es?: Maybe<Scalars['String']>;
  seoH1It?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleEn?: Maybe<Scalars['String']>;
  seoTitleEnGb?: Maybe<Scalars['String']>;
  seoTitleEs?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  showFormato?: Maybe<Scalars['Boolean']>;
  showGuidaTaglie?: Maybe<Scalars['Boolean']>;
  showTaglia?: Maybe<Scalars['Boolean']>;
  sistemiSpedizione: Array<SistemaSpedizioneNode>;
  slug: Scalars['String'];
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  stato?: Maybe<StatoProdottoNode>;
  suggestedForYou?: Maybe<Array<Maybe<ProdottoNode>>>;
  tabellaMotori?: Maybe<DescrizioneAggiuntivaProdottoNode>;
  topDelMese: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  use?: Maybe<ValoreAttributoNode>;
  utilizzo?: Maybe<Scalars['String']>;
  valuta?: Maybe<ValutaNode>;
  videoImages?: Maybe<Array<Maybe<ImmagineProdottoType>>>;
  visibileAl?: Maybe<Scalars['DateTime']>;
  visibileDal?: Maybe<Scalars['DateTime']>;
};


export type ProdottoNodePagineArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tipologia?: InputMaybe<PagesPaginaTipologiaChoices>;
};

export type ProdottoWarrantyNode = {
  __typename?: 'ProdottoWarrantyNode';
  categoria?: Maybe<CategoriaWarrantyNode>;
  nome?: Maybe<Scalars['String']>;
};

export type PromozioneNode = {
  __typename?: 'PromozioneNode';
  al: Scalars['DateTime'];
  alias?: Maybe<Scalars['String']>;
  attiva: Scalars['Boolean'];
  /** Prezzo di partenza utilizzato per l'applicazione dello sconto (valido solo su contesto 'Prodotto') */
  campoPrezzo?: Maybe<PromozioniPromozioneCampoPrezzoChoices>;
  codice?: Maybe<Scalars['String']>;
  /** Per attivare la promo è necessario inserire il codice in fase di checkout. */
  conCodice: Scalars['Boolean'];
  context: PromozioniPromozioneContextChoices;
  corrieri: Array<SistemaSpedizioneNode>;
  created?: Maybe<Scalars['DateTime']>;
  dal: Scalars['DateTime'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  descrizioneProdotto?: Maybe<Scalars['String']>;
  descrizioneProdottoEn?: Maybe<Scalars['String']>;
  descrizioneProdottoEnGb?: Maybe<Scalars['String']>;
  descrizioneProdottoEs?: Maybe<Scalars['String']>;
  descrizioneProdottoIt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoraDisponibilitaProdotti: Scalars['Boolean'];
  /** Numero di prodotti a carrello necessari per attivare la promo. */
  limiteNumeroProdotti: Scalars['Int'];
  /** Numero di volte che può essere usata la promo dal singolo utente. (0 per disattivare) */
  limiteNumeroUtilizzi: Scalars['Int'];
  /** Spesa minima necessaria per attivare la promozione. */
  limiteSpesa: Scalars['Float'];
  /** La promo è attivabile avendo a carrello solo prodotti presenti in promo. */
  limiteStrettoProdotti: Scalars['Boolean'];
  logicaApplicazione?: Maybe<PromozioniPromozioneLogicaApplicazioneChoices>;
  modified?: Maybe<Scalars['DateTime']>;
  /** Visualizza nei listing e in scheda prodotto quando manca alla scadenza della promozione. */
  mostraCounter: Scalars['Boolean'];
  /** Numero di volte che è stata utilizzata questa promo. In creazione lasciare a 0. */
  numeroUtilizzi: Scalars['Int'];
  ordini: OrdineNodeConnection;
  percentuale?: Maybe<Scalars['Boolean']>;
  pk?: Maybe<Scalars['Int']>;
  prodotti: Array<ProdottoNode>;
  prodottiAbbinati: Array<ProdottoNode>;
  prodottiBundle?: Maybe<Array<Maybe<ProdottoNode>>>;
  prodottiNegativi: Array<ProdottoNode>;
  promoDipendenti?: Maybe<Scalars['Boolean']>;
  regolaArrotondamenti?: Maybe<PromozioniPromozioneRegolaArrotondamentiChoices>;
  /** Per le promo valide solo per gli utenti associati definisce quando create automaticamente l'associazone con l'utente. */
  regolaAssociazioneUtente?: Maybe<PromozioniPromozioneRegolaAssociazioneUtenteChoices>;
  righeOrdine: Array<RigaOrdineNode>;
  /** Soglia peso massimo. */
  sogliaPesoMassimo: Scalars['Float'];
  /** La promo è attivabile solo da utenti iscritti alla newsletter. */
  soloIscrittiNewsletter: Scalars['Boolean'];
  titolo?: Maybe<Scalars['String']>;
  titoloEn?: Maybe<Scalars['String']>;
  titoloEnGb?: Maybe<Scalars['String']>;
  titoloEs?: Maybe<Scalars['String']>;
  titoloIt?: Maybe<Scalars['String']>;
  tuttiGliUtenti: Scalars['Boolean'];
  tuttiICorrieri: Scalars['Boolean'];
  tuttiIProdotti: Scalars['Boolean'];
  utenti: UtenteNodeConnection;
  utentiAbilitati: UtenteNodeConnection;
  valore?: Maybe<Scalars['Float']>;
};


export type PromozioneNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PromozioneNodeUtentiArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PromozioneNodeUtentiAbilitatiArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum PromozioniPromozioneCampoPrezzoChoices {
  /** Prezzo */
  Prezzo = 'PREZZO',
  /** Prezzo unitario (di listino) */
  PrezzoUnitario = 'PREZZO_UNITARIO'
}

/** An enumeration. */
export enum PromozioniPromozioneContextChoices {
  /** Checkout */
  Checkout = 'CHECKOUT',
  /** InviaPromoCode */
  Inviapromocode = 'INVIAPROMOCODE',
  /** Prodotto */
  Prodotto = 'PRODOTTO',
  /** SistemaSpedizione */
  Sistemaspedizione = 'SISTEMASPEDIZIONE'
}

/** An enumeration. */
export enum PromozioniPromozioneLogicaApplicazioneChoices {
  /** Prodotti contemporaneamente presenti a carrello */
  ProdottiAbbinati = 'PRODOTTI_ABBINATI',
  /** Standard */
  Standard = 'STANDARD'
}

/** An enumeration. */
export enum PromozioniPromozioneRegolaArrotondamentiChoices {
  /** Valore intero inferiore */
  TruncateToLowerInt = 'TRUNCATE_TO_LOWER_INT',
  /** Arrotondamento ad intero 0, 5 o 9 */
  ZeroFiveNineEnding = 'ZERO_FIVE_NINE_ENDING'
}

/** An enumeration. */
export enum PromozioniPromozioneRegolaAssociazioneUtenteChoices {
  /** Associa all'utente che si registra sia alla newsletter che al sito */
  Newsletter = 'NEWSLETTER',
  /** Associa all'utente in fase di registrazione */
  Registrazione = 'REGISTRAZIONE'
}

export type ProvinciaNode = {
  __typename?: 'ProvinciaNode';
  codice?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type QueryGet = {
  __typename?: 'QueryGet';
  assistenzaRivenditoriStoreLocator?: Maybe<Array<Maybe<RivenditoreStoreLocatorNode>>>;
  distributoriStoreLocator?: Maybe<Array<Maybe<DistributoreNode>>>;
  listingCategoria?: Maybe<ListingCategoriaNode>;
  regioni?: Maybe<Array<Maybe<RegioneNode>>>;
  rivenditoreStoreLocator?: Maybe<RivenditoreStoreLocatorNode>;
  rivenditoriStoreLocator?: Maybe<StoreLocatorNode>;
};


export type QueryGetListingCategoriaArgs = {
  categoriaId: Scalars['Int'];
  params: Scalars['GenericScalar'];
};


export type QueryGetRivenditoreStoreLocatorArgs = {
  slug: Scalars['String'];
};


export type QueryGetRivenditoriStoreLocatorArgs = {
  provincia?: InputMaybe<Scalars['String']>;
  regione?: InputMaybe<Scalars['String']>;
};

export type RegioneNode = {
  __typename?: 'RegioneNode';
  nome?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  province?: Maybe<Array<Maybe<ProvinciaNode>>>;
  slug?: Maybe<Scalars['String']>;
};

export type RigaCarrelloNode = {
  __typename?: 'RigaCarrelloNode';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  omaggio?: Maybe<Scalars['Boolean']>;
  pk?: Maybe<Scalars['Int']>;
  prodotto?: Maybe<VarianteProdottoNode>;
  quantita?: Maybe<Scalars['Int']>;
  sessionKey?: Maybe<SessionType>;
  utente?: Maybe<UtenteNode>;
};

export type RigaCheckoutNode = {
  __typename?: 'RigaCheckoutNode';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  omaggio: Scalars['Boolean'];
  pk?: Maybe<Scalars['Int']>;
  prodotto?: Maybe<VarianteProdottoNode>;
  quantita?: Maybe<Scalars['Int']>;
  utente: UtenteNode;
};

export type RigaOrdineNode = {
  __typename?: 'RigaOrdineNode';
  codiceProdotto?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  descrizione?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  nomeCategoriaWa?: Maybe<Scalars['String']>;
  nomeMacrocategoriaWa?: Maybe<Scalars['String']>;
  nomePromoWa?: Maybe<Scalars['String']>;
  omaggio: Scalars['Boolean'];
  ordine: OrdineNode;
  peso?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Int']>;
  posizione?: Maybe<Scalars['String']>;
  prezzoSellinUnitario: Scalars['Float'];
  prezzoTotale: Scalars['Float'];
  prezzoTotaleScontato: Scalars['Float'];
  prezzoUnitario: Scalars['Float'];
  prezzoUnitarioScontato: Scalars['Float'];
  prodotto?: Maybe<VarianteProdottoNode>;
  promozione?: Maybe<PromozioneNode>;
  promozioneValoreAssoluto: Scalars['Float'];
  promozioneValorePercentuale: Scalars['Float'];
  quantitaOrdinata: Scalars['Int'];
  quantitaSpedita?: Maybe<Scalars['Int']>;
  tipologia: OrdiniRigaOrdineTipologiaChoices;
};

export type RigaWishlistNode = {
  __typename?: 'RigaWishlistNode';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  omaggio: Scalars['Boolean'];
  pk?: Maybe<Scalars['Int']>;
  prodotto?: Maybe<ProdottoNode>;
  quantita: Scalars['Int'];
  sessionKey?: Maybe<Scalars['String']>;
  utente?: Maybe<UtenteNode>;
};

export type RivenditoreNode = {
  __typename?: 'RivenditoreNode';
  attivoPromoCoupon: Scalars['Boolean'];
  bonifico?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  cf?: Maybe<Scalars['String']>;
  checkout: Scalars['Boolean'];
  citta?: Maybe<Scalars['String']>;
  codiceEmak?: Maybe<Scalars['String']>;
  codiceRif?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  consensoMkt: Scalars['Boolean'];
  consensoProfilazione: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  customerIdStripe?: Maybe<Scalars['String']>;
  dataDiNascita?: Maybe<Scalars['Date']>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  emailConfermata: Scalars['Boolean'];
  fax?: Maybe<Scalars['String']>;
  guest: Scalars['Boolean'];
  id: Scalars['ID'];
  idOld?: Maybe<Scalars['Float']>;
  indirizzi: Array<IndirizzoNode>;
  indirizzo?: Maybe<Scalars['String']>;
  interessi: Array<InteresseNewsletterNode>;
  isActive: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  /** Attribuisce all'utente tutti i privilegi, senza che sia necessario assegnarli esplicitamente. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  latitudine?: Maybe<Scalars['String']>;
  longitudine?: Maybe<Scalars['String']>;
  luogoDiNascita?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nota?: Maybe<Scalars['String']>;
  ordini: OrdineNodeConnection;
  password: Scalars['String'];
  paypalPassword?: Maybe<Scalars['String']>;
  paypalSignature?: Maybe<Scalars['String']>;
  paypalUsername?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  privato: Scalars['Boolean'];
  professione?: Maybe<Scalars['String']>;
  professionista: Scalars['Boolean'];
  promozioni: Array<PromozioneNode>;
  promozioniAbilitate: Array<PromozioneNode>;
  provincia?: Maybe<Scalars['String']>;
  ragioneSociale?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  righeCarrello: Array<RigaCarrelloNode>;
  righeCheckout: Array<RigaCheckoutNode>;
  righeWishlist: Array<RigaWishlistNode>;
  rivenditore?: Maybe<RivenditoreNode>;
  satispayBearer?: Maybe<Scalars['String']>;
  sdi?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  setefiCommerciante?: Maybe<Scalars['String']>;
  setefiPassword?: Maybe<Scalars['String']>;
  setefiUsername?: Maybe<Scalars['String']>;
  sistemiPagamento?: Maybe<Array<Maybe<SistemaPagamentoNode>>>;
  sistemiPagamentoGrouped?: Maybe<Array<Maybe<GroupedSistemaPagamentoNode>>>;
  stripeAccountid?: Maybe<Scalars['String']>;
  stripePrivatekey?: Maybe<Scalars['String']>;
  stripePublickey?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  unicreditHash?: Maybe<Scalars['String']>;
  unicreditTid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  utentePtr: UtenteNode;
  warranty: Array<WarrantyNode>;
};


export type RivenditoreNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RivenditoreStoreLocatorNode = {
  __typename?: 'RivenditoreStoreLocatorNode';
  cap?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  latitudine?: Maybe<Scalars['String']>;
  longitudine?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
  pk?: Maybe<Scalars['Int']>;
  provincia?: Maybe<Scalars['String']>;
  ragioneSociale?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  telefono?: Maybe<Scalars['String']>;
  tipi?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
};

export type SeoType = {
  __typename?: 'SeoType';
  canonicalUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  h1?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  noindex?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type SessionType = {
  __typename?: 'SessionType';
  expireDate: Scalars['DateTime'];
  righeCarrello: Array<RigaCarrelloNode>;
  sessionData: Scalars['String'];
  sessionKey: Scalars['String'];
};

export type SistemaPagamentoNode = {
  __typename?: 'SistemaPagamentoNode';
  alias?: Maybe<Scalars['String']>;
  attivo: Scalars['Boolean'];
  className: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  famiglia?: Maybe<FamigliaSistemaPagamentoNode>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  ordini: OrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type SistemaPagamentoNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SistemaSpedizioneNode = {
  __typename?: 'SistemaSpedizioneNode';
  alias?: Maybe<Scalars['String']>;
  /** Integrazione con api del carrier */
  carrier?: Maybe<Scalars['String']>;
  chiave?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  default: Scalars['Boolean'];
  descrizione?: Maybe<Scalars['String']>;
  descrizioneEn?: Maybe<Scalars['String']>;
  descrizioneEnGb?: Maybe<Scalars['String']>;
  descrizioneEs?: Maybe<Scalars['String']>;
  descrizioneEstesa?: Maybe<Scalars['String']>;
  descrizioneEstesaEn?: Maybe<Scalars['String']>;
  descrizioneEstesaEnGb?: Maybe<Scalars['String']>;
  descrizioneEstesaEs?: Maybe<Scalars['String']>;
  descrizioneEstesaIt?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  /** Se abilitato esclude tutti gli altri sistemi di spedizione, altrimenti è in alternativa */
  esclusivo: Scalars['Boolean'];
  id: Scalars['ID'];
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  ordini: OrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  prezzo?: Maybe<Scalars['Float']>;
  prezzoScontato?: Maybe<Scalars['Float']>;
  prodottiSpedibili: Array<ProdottoNode>;
  promoApplicata?: Maybe<PromozioneNode>;
  promozioni: Array<PromozioneNode>;
  scontoAssoluto?: Maybe<Scalars['Float']>;
  scontoPercentuale?: Maybe<Scalars['Boolean']>;
  valoreAttributo?: Maybe<ValoreAttributoNode>;
};


export type SistemaSpedizioneNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StatoNode = Node & {
  __typename?: 'StatoNode';
  alias: Scalars['String'];
  azione?: Maybe<Scalars['String']>;
  chiave?: Maybe<Scalars['String']>;
  className: Scalars['String'];
  colorBackend?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  fatturato: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  isPreventivo: Scalars['Boolean'];
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordini: OrdineNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  statiConsentiti: StatoNodeConnection;
  statoSet: StatoNodeConnection;
  visibileInListing: Scalars['Boolean'];
};


export type StatoNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StatoNodeStatiConsentitiArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StatoNodeStatoSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StatoNodeConnection = {
  __typename?: 'StatoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StatoNode` and its cursor. */
export type StatoNodeEdge = {
  __typename?: 'StatoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StatoNode>;
};

export type StatoProdottoNode = {
  __typename?: 'StatoProdottoNode';
  acquistabile: Scalars['Boolean'];
  /** Un alias che identifichi lo stato */
  alias: Scalars['String'];
  /** Un alias che identifichi lo stato */
  aliasEn?: Maybe<Scalars['String']>;
  /** Un alias che identifichi lo stato */
  aliasEnGb?: Maybe<Scalars['String']>;
  /** Un alias che identifichi lo stato */
  aliasEs?: Maybe<Scalars['String']>;
  /** Un alias che identifichi lo stato */
  aliasIt?: Maybe<Scalars['String']>;
  azione?: Maybe<ProdottiStatoProdottoAzioneChoices>;
  codice: Scalars['String'];
  created: Scalars['DateTime'];
  escludiSconti: Scalars['Boolean'];
  id: Scalars['ID'];
  inListing: Scalars['Boolean'];
  infoDescrizione?: Maybe<Scalars['String']>;
  infoDescrizioneEn?: Maybe<Scalars['String']>;
  infoDescrizioneEnGb?: Maybe<Scalars['String']>;
  infoDescrizioneEs?: Maybe<Scalars['String']>;
  infoDescrizioneIt?: Maybe<Scalars['String']>;
  infoTitolo?: Maybe<Scalars['String']>;
  infoTitoloEn?: Maybe<Scalars['String']>;
  infoTitoloEnGb?: Maybe<Scalars['String']>;
  infoTitoloEs?: Maybe<Scalars['String']>;
  infoTitoloIt?: Maybe<Scalars['String']>;
  /** 0 se non limitato */
  limiteAcquisto: Scalars['Int'];
  modified: Scalars['DateTime'];
  prodotti: Array<ProdottoNode>;
};

export type StoreLocatorNode = {
  __typename?: 'StoreLocatorNode';
  rivenditoriFiltered?: Maybe<Array<Maybe<RivenditoreStoreLocatorNode>>>;
  rivenditoriStoreLocator?: Maybe<Array<Maybe<RivenditoreStoreLocatorNode>>>;
};

export type UtenteNode = Node & {
  __typename?: 'UtenteNode';
  cf?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  consensoMkt: Scalars['Boolean'];
  consensoProfilazione: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  customerIdStripe?: Maybe<Scalars['String']>;
  dataDiNascita?: Maybe<Scalars['Date']>;
  dataPrimoOrdine?: Maybe<Scalars['String']>;
  dataUltimoOrdine?: Maybe<Scalars['String']>;
  dateJoined: Scalars['DateTime'];
  edit?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emailConfermata: Scalars['Boolean'];
  guest: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  idOld?: Maybe<Scalars['Float']>;
  indirizzi: Array<IndirizzoNode>;
  indirizziFatturazione?: Maybe<Array<Maybe<IndirizzoNode>>>;
  indirizziSpedizione?: Maybe<Array<Maybe<IndirizzoNode>>>;
  interessi: Array<InteresseNewsletterNode>;
  isActive: Scalars['Boolean'];
  isImpersonate?: Maybe<Scalars['Boolean']>;
  isRivenditore?: Maybe<Scalars['Boolean']>;
  isStaff: Scalars['Boolean'];
  /** Attribuisce all'utente tutti i privilegi, senza che sia necessario assegnarli esplicitamente. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  luogoDiNascita?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nome?: Maybe<Scalars['String']>;
  numeroProdottiWishlist?: Maybe<Scalars['Int']>;
  ordini: OrdineNodeConnection;
  pec?: Maybe<Scalars['String']>;
  piva?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  privato: Scalars['Boolean'];
  professione?: Maybe<Scalars['String']>;
  professionista: Scalars['Boolean'];
  progressoProfilo?: Maybe<Scalars['Int']>;
  promozioni: Array<PromozioneNode>;
  promozioniAbilitate: Array<PromozioneNode>;
  ragioneSociale?: Maybe<Scalars['String']>;
  righeCarrello: Array<RigaCarrelloNode>;
  righeCheckout: Array<RigaCheckoutNode>;
  righeWishlist: Array<RigaWishlistNode>;
  rivenditore?: Maybe<RivenditoreNode>;
  sdi?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  showToolbar?: Maybe<Scalars['Boolean']>;
  tipoUtente?: Maybe<Scalars['String']>;
  ultimoOrdine?: Maybe<OrdineNode>;
  username: Scalars['String'];
  warranty: Array<WarrantyNode>;
};


export type UtenteNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UtenteNodeConnection = {
  __typename?: 'UtenteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UtenteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UtenteNode` and its cursor. */
export type UtenteNodeEdge = {
  __typename?: 'UtenteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UtenteNode>;
};

export type ValoreAttributoNode = {
  __typename?: 'ValoreAttributoNode';
  alias: Scalars['String'];
  attributo?: Maybe<AttributoNode>;
  categorie: CategoriaNodeConnection;
  children: Array<ValoreAttributoNode>;
  codice: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['DateTime']>;
  nome: Scalars['String'];
  nomeEn?: Maybe<Scalars['String']>;
  nomeEnGb?: Maybe<Scalars['String']>;
  nomeEs?: Maybe<Scalars['String']>;
  nomeIt?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  parent?: Maybe<ValoreAttributoNode>;
  pk?: Maybe<Scalars['Int']>;
  sistemiSpedizione: Array<SistemaSpedizioneNode>;
  slug: Scalars['String'];
  slugEn?: Maybe<Scalars['String']>;
  slugEnGb?: Maybe<Scalars['String']>;
  slugEs?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
};


export type ValoreAttributoNodeCategorieArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inHome?: InputMaybe<Scalars['Boolean']>;
  inMenu?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  soloConMastersConDocumenti?: InputMaybe<Scalars['Boolean']>;
};

export type ValutaNode = {
  __typename?: 'ValutaNode';
  alias: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isoCode: Scalars['String'];
  modified?: Maybe<Scalars['DateTime']>;
  ordini: OrdineNodeConnection;
  setefiCode?: Maybe<Scalars['String']>;
  simbolo: Scalars['String'];
};


export type ValutaNodeOrdiniArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type VarianteProdottoNode = {
  __typename?: 'VarianteProdottoNode';
  prodotto?: Maybe<ProdottoNode>;
};

export type VideoNewsNode = {
  __typename?: 'VideoNewsNode';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Es: e2780IjFa5A */
  idYoutube?: Maybe<Scalars['String']>;
  image?: Maybe<ImageNode>;
  modified?: Maybe<Scalars['DateTime']>;
  news: NewsNode;
  ordinamento: Scalars['Int'];
};

export type WarrantyNode = {
  __typename?: 'WarrantyNode';
  created?: Maybe<Scalars['DateTime']>;
  dataAcquisto?: Maybe<Scalars['Date']>;
  dataScadenza?: Maybe<Scalars['Date']>;
  descrizione: Scalars['String'];
  durata?: Maybe<Scalars['Int']>;
  estesa: Scalars['Boolean'];
  id: Scalars['ID'];
  modello: Scalars['String'];
  modified?: Maybe<Scalars['DateTime']>;
  numeroSerie: Scalars['String'];
  pk?: Maybe<Scalars['Int']>;
  prodotto?: Maybe<ProdottoWarrantyNode>;
  productRegistrationId: Scalars['String'];
  /** e.g: '/it' */
  url: Scalars['String'];
  utente?: Maybe<UtenteNode>;
};

export type ListingCategoriaQueryVariables = Exact<{
  params: Scalars['GenericScalar'];
  categoriaId: Scalars['Int'];
}>;


export type ListingCategoriaQuery = { __typename?: 'QueryGet', listingCategoria?: { __typename?: 'ListingCategoriaNode', priceMin?: number | null, priceMax?: number | null, canonicalUrl?: string | null, elementi?: Array<{ __typename?: 'ProdottoNode', pk?: number | null, nome: string, previewImage?: string | null, url?: string | null, promo?: boolean | null, lancio: boolean, novita?: boolean | null, utilizzo?: string | null, disponibile?: boolean | null, details?: Array<string | null> | null, prezzo?: number | null, prezzoUnitario?: number | null, prezzoOmnibus?: number | null, prezzoScontato?: number | null, isAccessorio?: boolean | null, isAbbigliamento?: boolean | null, codice?: string | null, categoria?: { __typename?: 'CategoriaNode', alias: string, boxTitolo?: string | null, parent?: { __typename?: 'CategoriaNode', boxTitolo?: string | null, parent?: { __typename?: 'CategoriaNode', boxTitolo?: string | null } | null } | null } | null, stato?: { __typename?: 'StatoProdottoNode', codice: string, acquistabile: boolean } | null, valuta?: { __typename?: 'ValutaNode', isoCode: string, simbolo: string } | null } | null> | null, attributi?: Array<{ __typename?: 'ParentFiltroCategoriaNode', nome?: string | null, children?: Array<{ __typename?: 'FiltroCategoriaNode', nome?: string | null, selected?: boolean | null, href?: string | null, reset?: boolean | null, id?: number | null, facets?: number | null, disabled?: boolean | null } | null> | null } | null> | null, paginator?: { __typename?: 'PaginatorNode', currentPage?: number | null, numPages?: number | null, numItems?: number | null, itemsPerPage?: number | null, pages?: Array<{ __typename?: 'PageNode', href?: string | null, page?: string | null } | null> | null } | null, ordinamenti?: Array<{ __typename?: 'OrdinamentoNode', label?: string | null, value?: string | null } | null> | null } | null };

export type RivenditoreStoreLocatorCardFragment = { __typename?: 'RivenditoreStoreLocatorNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, provincia?: string | null, tipi?: Array<string | null> | null, telefono?: string | null, email?: string | null, tags?: Array<string | null> | null, url?: string | null };

export type RegioniQueryVariables = Exact<{ [key: string]: never; }>;


export type RegioniQuery = { __typename?: 'QueryGet', regioni?: Array<{ __typename?: 'RegioneNode', pk?: number | null, slug?: string | null, nome?: string | null, province?: Array<{ __typename?: 'ProvinciaNode', nome?: string | null, codice?: string | null, slug?: string | null } | null> | null } | null> | null };

export type RivenditoriStoreLocatorQueryVariables = Exact<{
  regione?: InputMaybe<Scalars['String']>;
  provincia?: InputMaybe<Scalars['String']>;
}>;


export type RivenditoriStoreLocatorQuery = { __typename?: 'QueryGet', rivenditoriStoreLocator?: { __typename?: 'StoreLocatorNode', rivenditoriStoreLocator?: Array<{ __typename?: 'RivenditoreStoreLocatorNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, provincia?: string | null, tipi?: Array<string | null> | null, telefono?: string | null, email?: string | null, tags?: Array<string | null> | null, url?: string | null } | null> | null, rivenditoriFiltered?: Array<{ __typename?: 'RivenditoreStoreLocatorNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, provincia?: string | null, tipi?: Array<string | null> | null, telefono?: string | null, email?: string | null, tags?: Array<string | null> | null, url?: string | null } | null> | null } | null };

export type AssistenzaRivenditoriStoreLocatorQueryVariables = Exact<{ [key: string]: never; }>;


export type AssistenzaRivenditoriStoreLocatorQuery = { __typename?: 'QueryGet', assistenzaRivenditoriStoreLocator?: Array<{ __typename?: 'RivenditoreStoreLocatorNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, provincia?: string | null, tipi?: Array<string | null> | null, telefono?: string | null, email?: string | null, tags?: Array<string | null> | null, url?: string | null } | null> | null };

export type RivenditoreStoreLocatorQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type RivenditoreStoreLocatorQuery = { __typename?: 'QueryGet', rivenditoreStoreLocator?: { __typename?: 'RivenditoreStoreLocatorNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, provincia?: string | null, tipi?: Array<string | null> | null, telefono?: string | null, email?: string | null, url?: string | null, tags?: Array<string | null> | null, pagina?: { __typename?: 'PaginaNode', adminUrl?: string | null, breadcrumbs?: Array<{ __typename?: 'BreadcrumbType', label?: string | null, url?: string | null } | null> | null, seo?: { __typename?: 'SeoType', title?: string | null, description?: string | null, image?: string | null, noindex?: boolean | null, canonicalUrl?: string | null } | null } | null } | null };

export type DistributoriStoreLocatorQueryVariables = Exact<{ [key: string]: never; }>;


export type DistributoriStoreLocatorQuery = { __typename?: 'QueryGet', distributoriStoreLocator?: Array<{ __typename?: 'DistributoreNode', pk?: number | null, latitudine?: string | null, longitudine?: string | null, ragioneSociale?: string | null, indirizzo?: string | null, cap?: string | null, citta?: string | null, localita?: string | null, provincia?: string | null, nazione?: string | null, continente?: string | null, telefono?: string | null, fax?: string | null, email?: string | null, sito?: string | null } | null> | null };

export const RivenditoreStoreLocatorCardFragmentDoc = gql`
    fragment RivenditoreStoreLocatorCard on RivenditoreStoreLocatorNode {
  pk
  latitudine
  longitudine
  ragioneSociale
  indirizzo
  cap
  citta
  provincia
  tipi
  telefono
  email
  tags
  url
}
    `;
export const ListingCategoriaDocument = gql`
    query ListingCategoria($params: GenericScalar!, $categoriaId: Int!) {
  listingCategoria(params: $params, categoriaId: $categoriaId) {
    elementi {
      pk
      nome
      previewImage
      url
      promo
      lancio
      novita
      utilizzo
      disponibile
      details
      prezzo
      prezzoUnitario
      prezzoOmnibus
      prezzoScontato
      isAccessorio
      isAbbigliamento
      codice
      categoria {
        alias
        boxTitolo
        parent {
          boxTitolo
          parent {
            boxTitolo
          }
        }
      }
      stato {
        codice
        acquistabile
      }
      valuta {
        isoCode
        simbolo
      }
    }
    attributi {
      nome
      children {
        nome
        selected
        href
        reset
        id
        facets
        disabled
      }
    }
    paginator {
      currentPage
      numPages
      numItems
      itemsPerPage
      pages {
        href
        page
      }
    }
    ordinamenti {
      label
      value
    }
    priceMin
    priceMax
    canonicalUrl
  }
}
    `;

/**
 * __useListingCategoriaQuery__
 *
 * To run a query within a React component, call `useListingCategoriaQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingCategoriaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingCategoriaQuery({
 *   variables: {
 *      params: // value for 'params'
 *      categoriaId: // value for 'categoriaId'
 *   },
 * });
 */
export function useListingCategoriaQuery(baseOptions: Apollo.QueryHookOptions<ListingCategoriaQuery, ListingCategoriaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingCategoriaQuery, ListingCategoriaQueryVariables>(ListingCategoriaDocument, options);
      }
export function useListingCategoriaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingCategoriaQuery, ListingCategoriaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingCategoriaQuery, ListingCategoriaQueryVariables>(ListingCategoriaDocument, options);
        }
export type ListingCategoriaQueryHookResult = ReturnType<typeof useListingCategoriaQuery>;
export type ListingCategoriaLazyQueryHookResult = ReturnType<typeof useListingCategoriaLazyQuery>;
export type ListingCategoriaQueryResult = Apollo.QueryResult<ListingCategoriaQuery, ListingCategoriaQueryVariables>;
export const RegioniDocument = gql`
    query Regioni {
  regioni {
    pk
    slug
    nome
    province {
      nome
      codice
      slug
    }
  }
}
    `;

/**
 * __useRegioniQuery__
 *
 * To run a query within a React component, call `useRegioniQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegioniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegioniQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegioniQuery(baseOptions?: Apollo.QueryHookOptions<RegioniQuery, RegioniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegioniQuery, RegioniQueryVariables>(RegioniDocument, options);
      }
export function useRegioniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegioniQuery, RegioniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegioniQuery, RegioniQueryVariables>(RegioniDocument, options);
        }
export type RegioniQueryHookResult = ReturnType<typeof useRegioniQuery>;
export type RegioniLazyQueryHookResult = ReturnType<typeof useRegioniLazyQuery>;
export type RegioniQueryResult = Apollo.QueryResult<RegioniQuery, RegioniQueryVariables>;
export const RivenditoriStoreLocatorDocument = gql`
    query RivenditoriStoreLocator($regione: String, $provincia: String) {
  rivenditoriStoreLocator(regione: $regione, provincia: $provincia) {
    rivenditoriStoreLocator {
      ...RivenditoreStoreLocatorCard
    }
    rivenditoriFiltered {
      ...RivenditoreStoreLocatorCard
    }
  }
}
    ${RivenditoreStoreLocatorCardFragmentDoc}`;

/**
 * __useRivenditoriStoreLocatorQuery__
 *
 * To run a query within a React component, call `useRivenditoriStoreLocatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRivenditoriStoreLocatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRivenditoriStoreLocatorQuery({
 *   variables: {
 *      regione: // value for 'regione'
 *      provincia: // value for 'provincia'
 *   },
 * });
 */
export function useRivenditoriStoreLocatorQuery(baseOptions?: Apollo.QueryHookOptions<RivenditoriStoreLocatorQuery, RivenditoriStoreLocatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RivenditoriStoreLocatorQuery, RivenditoriStoreLocatorQueryVariables>(RivenditoriStoreLocatorDocument, options);
      }
export function useRivenditoriStoreLocatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RivenditoriStoreLocatorQuery, RivenditoriStoreLocatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RivenditoriStoreLocatorQuery, RivenditoriStoreLocatorQueryVariables>(RivenditoriStoreLocatorDocument, options);
        }
export type RivenditoriStoreLocatorQueryHookResult = ReturnType<typeof useRivenditoriStoreLocatorQuery>;
export type RivenditoriStoreLocatorLazyQueryHookResult = ReturnType<typeof useRivenditoriStoreLocatorLazyQuery>;
export type RivenditoriStoreLocatorQueryResult = Apollo.QueryResult<RivenditoriStoreLocatorQuery, RivenditoriStoreLocatorQueryVariables>;
export const AssistenzaRivenditoriStoreLocatorDocument = gql`
    query AssistenzaRivenditoriStoreLocator {
  assistenzaRivenditoriStoreLocator {
    ...RivenditoreStoreLocatorCard
  }
}
    ${RivenditoreStoreLocatorCardFragmentDoc}`;

/**
 * __useAssistenzaRivenditoriStoreLocatorQuery__
 *
 * To run a query within a React component, call `useAssistenzaRivenditoriStoreLocatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssistenzaRivenditoriStoreLocatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssistenzaRivenditoriStoreLocatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssistenzaRivenditoriStoreLocatorQuery(baseOptions?: Apollo.QueryHookOptions<AssistenzaRivenditoriStoreLocatorQuery, AssistenzaRivenditoriStoreLocatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssistenzaRivenditoriStoreLocatorQuery, AssistenzaRivenditoriStoreLocatorQueryVariables>(AssistenzaRivenditoriStoreLocatorDocument, options);
      }
export function useAssistenzaRivenditoriStoreLocatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssistenzaRivenditoriStoreLocatorQuery, AssistenzaRivenditoriStoreLocatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssistenzaRivenditoriStoreLocatorQuery, AssistenzaRivenditoriStoreLocatorQueryVariables>(AssistenzaRivenditoriStoreLocatorDocument, options);
        }
export type AssistenzaRivenditoriStoreLocatorQueryHookResult = ReturnType<typeof useAssistenzaRivenditoriStoreLocatorQuery>;
export type AssistenzaRivenditoriStoreLocatorLazyQueryHookResult = ReturnType<typeof useAssistenzaRivenditoriStoreLocatorLazyQuery>;
export type AssistenzaRivenditoriStoreLocatorQueryResult = Apollo.QueryResult<AssistenzaRivenditoriStoreLocatorQuery, AssistenzaRivenditoriStoreLocatorQueryVariables>;
export const RivenditoreStoreLocatorDocument = gql`
    query RivenditoreStoreLocator($slug: String!) {
  rivenditoreStoreLocator(slug: $slug) {
    pk
    latitudine
    longitudine
    ragioneSociale
    indirizzo
    cap
    citta
    provincia
    tipi
    telefono
    email
    url
    pagina {
      adminUrl
      breadcrumbs {
        label
        url
      }
      seo {
        title
        description
        image
        noindex
        canonicalUrl
      }
    }
    tags
  }
}
    `;

/**
 * __useRivenditoreStoreLocatorQuery__
 *
 * To run a query within a React component, call `useRivenditoreStoreLocatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRivenditoreStoreLocatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRivenditoreStoreLocatorQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRivenditoreStoreLocatorQuery(baseOptions: Apollo.QueryHookOptions<RivenditoreStoreLocatorQuery, RivenditoreStoreLocatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RivenditoreStoreLocatorQuery, RivenditoreStoreLocatorQueryVariables>(RivenditoreStoreLocatorDocument, options);
      }
export function useRivenditoreStoreLocatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RivenditoreStoreLocatorQuery, RivenditoreStoreLocatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RivenditoreStoreLocatorQuery, RivenditoreStoreLocatorQueryVariables>(RivenditoreStoreLocatorDocument, options);
        }
export type RivenditoreStoreLocatorQueryHookResult = ReturnType<typeof useRivenditoreStoreLocatorQuery>;
export type RivenditoreStoreLocatorLazyQueryHookResult = ReturnType<typeof useRivenditoreStoreLocatorLazyQuery>;
export type RivenditoreStoreLocatorQueryResult = Apollo.QueryResult<RivenditoreStoreLocatorQuery, RivenditoreStoreLocatorQueryVariables>;
export const DistributoriStoreLocatorDocument = gql`
    query DistributoriStoreLocator {
  distributoriStoreLocator {
    pk
    latitudine
    longitudine
    ragioneSociale
    indirizzo
    cap
    citta
    localita
    provincia
    nazione
    continente
    telefono
    fax
    email
    sito
  }
}
    `;

/**
 * __useDistributoriStoreLocatorQuery__
 *
 * To run a query within a React component, call `useDistributoriStoreLocatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributoriStoreLocatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributoriStoreLocatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistributoriStoreLocatorQuery(baseOptions?: Apollo.QueryHookOptions<DistributoriStoreLocatorQuery, DistributoriStoreLocatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributoriStoreLocatorQuery, DistributoriStoreLocatorQueryVariables>(DistributoriStoreLocatorDocument, options);
      }
export function useDistributoriStoreLocatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributoriStoreLocatorQuery, DistributoriStoreLocatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributoriStoreLocatorQuery, DistributoriStoreLocatorQueryVariables>(DistributoriStoreLocatorDocument, options);
        }
export type DistributoriStoreLocatorQueryHookResult = ReturnType<typeof useDistributoriStoreLocatorQuery>;
export type DistributoriStoreLocatorLazyQueryHookResult = ReturnType<typeof useDistributoriStoreLocatorLazyQuery>;
export type DistributoriStoreLocatorQueryResult = Apollo.QueryResult<DistributoriStoreLocatorQuery, DistributoriStoreLocatorQueryVariables>;