import React, { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Form, Formik } from 'formik'
import { useTrans } from 'hooks'
import { scrollTo } from '@utils/safe'
import { adesioneB2CValidationSchema } from '@utils/validationSchemas'
import {
  Button,
  FormCheckbox,
  FormError,
  FormFieldset,
  FormikField,
  FormLayout,
  Modal,
  WContainer,
} from '@components/atoms'
import styles from './AdesioneB2CForm.module.sass'
import { Check } from '@components/icons'
import { useCreateAdesioneB2CMutation } from '@gql/graphql'

interface Props {
  className?: string
}

const AdesioneB2CForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const { locale, domainLocales } = useRouter()
  const formBody = useRef(null)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const yesNoOptions = [
    { label: 'Sì', value: '1' },
    { label: 'No', value: '0' },
  ]

  const metodiPagamentoOptions = [
    { name: 'paypal', label: 'Paypal' },
    { name: 'setefi', label: 'Carta di credito via PayPal' },
    { name: 'bonifico', label: 'Bonifico' },
  ]

  const circuitoOptions = [
    { label: 'Unicredit', value: 'unicredit' },
    { label: 'Stripe', value: 'stripe' },
    { label: 'Nexi', value: 'nexi' },
  ]

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    scrollTo(formBody?.current, 20)
  }

  const [createAdesioneB2C] = useCreateAdesioneB2CMutation({
    onCompleted: (res) => {
      if (!res.createAdesioneB2c?.status) {
        raiseGlobalError(res?.createAdesioneB2c?.message || t('Errore durante il salvataggio'))
      } else {
        setConfirmModalOpen(true)
      }
    },
    onError: () => {
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  const initialValues = {
    codice: '',
    nome: '',
    cognome: '',
    email: '',
    cellulare: '',
    ...metodiPagamentoOptions.reduce((res, x) => ({ ...res, [x.name]: false }), {}),
    circuito: '',
    bonificoBanca: '',
    bonificoAgenzia: '',
    bonificoIban: '',
    privacy: undefined,
    concessione: undefined,
    ragioneSociale: '',
  }

  let canale =
    domainLocales?.find((x) => x.defaultLocale === locale) ||
    domainLocales?.find((x) => x.defaultLocale === 'default')
  const domain = canale?.domain

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <Formik
            initialValues={initialValues}
            validationSchema={adesioneB2CValidationSchema(t)}
            onSubmit={(values) => {
              createAdesioneB2C({
                variables: {
                  adesioneInput: {
                    ...values,
                    privacy: values['privacy'] === '1',
                    concessione: values['concessione'] === '1',
                  },
                },
              })
            }}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <div className={styles.formBody} ref={formBody}>
                  <FormError
                    id="adesione_b2c_global_error"
                    message={globalError}
                    visible={!!globalError}
                    variant="global"
                    className={styles.globalError}
                  />
                  <div className={styles.fieldsets}>
                    <FormFieldset title={t('Informazioni per la vendita online')}>
                      <FormLayout space="lg">
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_codice'}
                          name="codice"
                          type="text"
                          label={t('Codice cliente')}
                          placeholder={t('Inserisci il tuo codice cliente')}
                          required
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_nome'}
                          name="nome"
                          type="text"
                          label={t('Nome')}
                          placeholder={t('Inserisci il tuo nome')}
                          required
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_cognome'}
                          name="cognome"
                          type="text"
                          label={t('Cognome')}
                          placeholder={t('Inserisci il tuo cognome')}
                          required
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_ragione_sociale'}
                          name="ragioneSociale"
                          type="text"
                          label={t('Ragione sociale')}
                          placeholder={t('Inserisci la tua ragione sociale')}
                          required
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_cellulare'}
                          name="cellulare"
                          type="text"
                          label={t('Telefono')}
                          placeholder={t('Inserisci il tuo telefono')}
                          required
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_email'}
                          name="email"
                          type="email"
                          label={t('Email')}
                          placeholder={t('Inserisci la tua email')}
                          required
                          layout="sm"
                        />
                      </FormLayout>
                    </FormFieldset>
                    <FormFieldset title={t('Modalità di pagamento')}>
                      <FormLayout space="lg">
                        <div className={styles.checkboxes}>
                          {metodiPagamentoOptions.map((x) => (
                            <FormCheckbox
                              id={`adesione_b2c_${x.name}`}
                              name={x.name}
                              label={x.label}
                              checked={formikProps.values[x.name]}
                              onChange={(e) => formikProps.handleChange(e)}
                            />
                          ))}
                        </div>
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_circuito'}
                          name="circuito"
                          type="radio"
                          options={circuitoOptions}
                          label={t(
                            'Carta di credito (è possibile integrare solo uno dei circuiti sotto)'
                          )}
                          placeholder={t('Inserisci il tuo nome')}
                          variant="horizontal"
                          layout="full"
                          className={styles.circuitoField}
                        />
                      </FormLayout>
                    </FormFieldset>
                    <FormFieldset title={t('Dati Bonifico Bancario')}>
                      <FormLayout space="lg">
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_banca'}
                          name="bonificoBanca"
                          type="text"
                          label={t('Banca')}
                          placeholder={t('Inserisci la Banca')}
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_agenzia'}
                          name="bonificoAgenzia"
                          type="text"
                          label={t('Agenzia')}
                          placeholder={t("Inserisci l'agenzia")}
                          layout="sm"
                        />
                        <FormikField
                          formik={formikProps}
                          id={'adesione_b2c_iban'}
                          name="bonificoIban"
                          type="text"
                          label={t('Codice IBAN')}
                          placeholder={t('Inserisci il codice IBAN')}
                          layout="sm"
                        />
                      </FormLayout>
                    </FormFieldset>
                    <div className={styles.informative}>
                      <div className={styles.informativa}>
                        <p className={styles.informativaTitle}>{t('Informativa sulla privacy')}</p>
                        <div className={styles.informativaContent}>
                          <p>{t('adesioneb2c_informativa_privacy_titolo')}</p>
                          <div className={styles.informativaScroll}>
                            <div
                              className={styles.informativaScrollContent}
                              dangerouslySetInnerHTML={{
                                __html: t('adesioneb2c_informativa_privacy_testo'),
                              }}
                            />
                          </div>
                          <p>{t('adesioneb2c_consenso_privacy_titolo')}</p>
                          <div
                            dangerouslySetInnerHTML={{ __html: t('adesioneb2c_privacy_testo') }}
                          />
                        </div>
                        <div className={styles.informativaBottom}>
                          <FormikField
                            id="adesione_b2c_privacy"
                            name="privacy"
                            type="radio"
                            options={yesNoOptions}
                            formik={formikProps}
                            layout="none"
                            variant="horizontal"
                          />
                          <span className={styles.informativaNotes}>
                            {t('(ndr: La firma è sostituita dal click)')}
                          </span>
                        </div>
                      </div>
                      <div className={styles.informativa}>
                        <p className={styles.informativaTitle}>
                          {t(
                            'Contratto di concessione fra Emak ed il Rivenditore per la vendita, la distribuzione e l’assistenza, al Cliente dei Prodotti Emak, tramite il servizio offerto dal sito web %site_domain'
                          ).replaceAll('%site_domain', domain)}
                        </p>
                        <div
                          className={styles.informativaContent}
                          dangerouslySetInnerHTML={{
                            __html: t('adesioneb2c_consenso_b2c_testo').replaceAll(
                              '%site_domain',
                              domain
                            ),
                          }}
                        />
                        <div className={styles.informativaBottom}>
                          <FormikField
                            id="adesione_b2c_concessione"
                            name="concessione"
                            type="radio"
                            options={yesNoOptions}
                            formik={formikProps}
                            layout="none"
                            variant="horizontal"
                          />
                          <span className={styles.informativaNotes}>
                            {t('(ndr: La firma è sostituita dal click)')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ctaBox}>
                    <Button
                      type="submit"
                      label={t('Invia richiesta')}
                      loading={globalLoading}
                      size="lg"
                    />
                  </div>
                  <div className={styles.notes}>
                    <p>{t('Campi obbligatori (*)')}</p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </WContainer>
      <Modal
        id="contattaciform_confirmmodal"
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        icon={<Check />}
        title={t('La tua richiesta è stata inviata!')}
        description={t(
          'Il nostro team ti contatterà quanto prima per fornirti tutte le informazioni necessarie.'
        )}
        cta={<Button label={t('Torna alla home')} href="/" size="lg" />}
      />
    </div>
  )
}

export default AdesioneB2CForm
