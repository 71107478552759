import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { RicercaProdottoCardSectionSettings } from './RicercaProdottoCardSectionSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const defaultProps = {}

export const dynamicProps = []

export const RicercaProdottoCardSection: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-ricerca-prodotto-card-section ${props.className}`}
      >
        {children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

RicercaProdottoCardSection.craft = withCraft({
  name: 'RicercaProdottoCardSection',
  defaultProps: defaultProps,
  settings: RicercaProdottoCardSectionSettings,
})
