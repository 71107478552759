import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Peso({ idVariant = '', className }: Props) {
  return (
    <IconBase name="peso" w={50} className={className}>
      <path
        d="M45.0141 41.3805L37.1472 18.7559C36.8414 17.9445 36.0889 17.4506 35.2304 17.4506H31.3499C32.1613 16.1924 32.5611 14.7813 32.5141 13.2643C32.4082 9.33678 29.1862 6.05597 25.2587 5.9031C23.189 5.79727 21.2253 6.56162 19.7201 8.01975C18.2032 9.47789 17.4035 11.3946 17.4035 13.4643C17.4035 14.9224 17.8034 16.2865 18.5677 17.4976H14.6872C13.8288 17.4976 13.0762 18.0503 12.7704 18.8617L5.00937 41.3923C4.76243 41.992 4.8565 42.6976 5.25632 43.262C5.60909 43.8147 6.26761 44.1204 6.91436 44.1204H43.0973C43.7558 44.1204 44.3556 43.8147 44.7554 43.262C45.1552 42.7093 45.214 42.0038 45.0023 41.3923L45.0141 41.3805ZM20.8255 19.4614C21.2253 19.4614 21.6368 19.2145 21.778 18.8029C21.9308 18.4031 21.778 17.9445 21.4722 17.6976C20.214 16.6392 19.4614 15.0753 19.4614 13.4172C19.4614 11.9003 20.0611 10.4892 21.1782 9.43086C22.2836 8.37253 23.6947 7.81985 25.2587 7.86689C28.1279 7.97272 30.5033 10.3833 30.5503 13.2643C30.5973 14.9812 29.8447 16.5922 28.5395 17.6505C28.2337 17.8975 28.0809 18.3561 28.2337 18.7559C28.3866 19.1557 28.7394 19.4144 29.1862 19.4144H35.2892L43.0973 42.039H6.91436L14.7224 19.4614H20.8255Z"
        fill="currentColor"
      />
      <path
        d="M25.0118 17.1449C27.0814 17.1449 28.7864 15.4868 28.7864 13.3702C28.7864 11.2535 27.1284 9.59548 25.0118 9.59548C22.8951 9.59548 21.2371 11.2535 21.2371 13.3702C21.2371 15.4868 22.9539 17.1449 25.0118 17.1449ZM25.0118 11.6533C25.9642 11.6533 26.7756 12.4647 26.7756 13.4172C26.7756 14.3697 25.9642 15.1811 25.0118 15.1811C24.0593 15.1811 23.2479 14.3697 23.2479 13.4172C23.2479 12.4059 24.0593 11.6533 25.0118 11.6533Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Peso
