import * as icons from 'components/icons'

export const getIcon = (key: string | null | undefined, id?: string | number | undefined) => {
  if (!key) return null

  switch (key) {
    case 'logo':
      return getIcon('logo_small', id)
    case 'logo_light':
      return getIcon('logo_small_light', id)
  }

  const keyToComponentName = key
    .split('_')
    .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
    .join('')
  //@ts-ignore
  const Icon = icons[`${keyToComponentName}`]

  if (Icon) return <Icon idVariant={id} />

  return null
}
