import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Consulenza({ idVariant = '', className }: Props) {
  return (
    <IconBase name="consulenza" w={40} className={className}>
      <g clip-path={`url(#consulenza_${idVariant}_clip0_6147_8268)`}>
        <path
          d="M20.76 22.4781L20.76 22.4781L20.7583 22.4816C20.7573 22.4838 20.7566 22.4849 20.7562 22.4853C20.7554 22.4858 20.7514 22.4879 20.7441 22.4882C20.7365 22.4886 20.7305 22.4869 20.7269 22.4851C20.7253 22.4843 20.724 22.4834 20.7227 22.4823C20.722 22.4816 20.721 22.4806 20.7196 22.4789C20.7189 22.4779 20.718 22.4768 20.7171 22.4754C20.717 22.4751 20.7168 22.4748 20.7166 22.4744L18.5755 18.9578L18.4293 18.7178H18.1484H12.4929C11.4949 18.7178 10.6869 17.9109 10.6869 16.9388V6.22912H10.687L10.6869 6.22414C10.6773 5.25778 11.4805 4.45007 12.4866 4.45007H28.372C29.37 4.45007 30.178 5.25699 30.178 6.22912V16.9264C30.178 17.8986 29.37 18.7055 28.372 18.7055H22.8915H22.5774L22.441 18.9885L20.76 22.4781Z"
          fill="currentColor"
          stroke="#161616"
        />
        <path
          d="M21.2105 22.6951L10.6244 26.6603L13.4615 32.781C13.4615 32.781 13.4678 32.7995 13.474 32.8118L15.9049 31.7125C16.2736 31.5395 16.6298 31.5086 17.0235 31.5828L22.604 32.7068C22.7978 32.7439 22.9602 32.7192 23.1352 32.6204L32.259 27.5744C32.9964 27.1605 33.3901 26.5244 33.1026 25.9747C32.7964 25.3941 31.9778 25.3138 31.1654 25.6844L23.7351 29.0443C23.6289 29.0937 23.4977 29.1122 23.3789 29.0814L18.6171 27.8646C17.9484 27.6917 18.2171 26.6973 18.8795 26.8641L23.4477 28.0376C23.8164 27.8338 24.0414 27.5744 24.1413 27.3026C24.4226 26.5676 23.8664 25.7585 23.0852 25.4682L17.9609 23.5845C17.7547 23.5042 17.5672 23.5165 17.3672 23.603L10.6181 26.6603L21.2105 22.6951ZM13.699 33.8433C13.6615 36.0482 10.2869 37.1229 9.17458 34.7203L6.24372 28.3958C5.07512 25.8821 8.43093 24.0292 10.0745 25.7709L16.936 22.6642C17.386 22.4604 17.8609 22.4357 18.3296 22.6148L23.4539 24.4986C24.6225 24.9309 25.4162 26.0673 25.2349 27.2347L30.7342 24.7456C32.0965 24.128 33.4713 24.4368 34.0338 25.4991C34.5775 26.5429 34.0463 27.772 32.7652 28.4823L23.6414 33.5283C23.254 33.7383 22.8228 33.8062 22.3853 33.7198L16.8048 32.6018C16.6423 32.5648 16.4924 32.5895 16.3299 32.6513L13.699 33.8433ZM12.5116 33.2133L9.58077 26.8826C8.86212 25.3262 6.47494 26.4009 7.19984 27.9573L10.1307 34.288C10.8494 35.8382 13.2365 34.7635 12.5116 33.2133Z"
          fill="#161616"
        />
        <path
          d="M26.641 8.61316C27.3284 8.61316 27.3284 9.65077 26.641 9.65077H19.5419C18.8483 9.65077 18.8483 8.61316 19.5419 8.61316H26.641Z"
          fill="#161616"
        />
        <path
          d="M26.641 14.8882C27.3284 14.8882 27.3284 15.9259 26.641 15.9259H14.1864C13.499 15.9259 13.499 14.8882 14.1864 14.8882H26.641Z"
          fill="#161616"
        />
        <path
          d="M26.641 11.7507C27.3284 11.7507 27.3284 12.7883 26.641 12.7883H14.1864C13.499 12.7883 13.499 11.7507 14.1864 11.7507H26.641Z"
          fill="#161616"
        />
      </g>
      <defs>
        <clipPath id={`consulenza_${idVariant}_clip0_6147_8268`}>
          <rect width="28.24" height="32.1352" fill="white" transform="translate(6 3.95007)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default Consulenza
