import React from 'react'
import { MenuItemType } from '@utils/types'
import {
  WImage,
  Button,
  EditableTitle,
  EditableDescription,
  EditableVideo,
  WContainer,
} from '@components/atoms'
import styles from './VideoTextSection.module.sass'

interface Props {
  item: MenuItemType
  theme?: 'light' | 'neutrallight' | 'secondarydark'
  editMode?: boolean
  className?: string
}

const VideoTextSection = (props: Props) => {
  const { item = undefined, theme = 'light', editMode = false, className = '' } = props

  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        editMode ? styles['root--editMode'] : ''
      } ${className}`}
    >
      <WContainer variant="xs">
        <div className={`${styles.layout}`}>
          {(!!item.name || !!item.description) && (
            <div className={styles.main}>
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
            </div>
          )}
          <EditableVideo
            item={item.video}
            preserveRatio
            editMode={editMode}
            className={styles.videoBox}
          />
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default VideoTextSection
