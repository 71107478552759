import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Card({ idVariant = '', className }: Props) {
  return (
    <IconBase name="card" w={24} className={className}>
      <path
        d="M3.00488 3.99979H21.0049C21.5572 3.99979 22.0049 4.4475 22.0049 4.99979V18.9998C22.0049 19.5521 21.5572 19.9998 21.0049 19.9998H3.00488C2.4526 19.9998 2.00488 19.5521 2.00488 18.9998V4.99979C2.00488 4.4475 2.4526 3.99979 3.00488 3.99979ZM20.0049 11.9998H4.00488V17.9998H20.0049V11.9998ZM20.0049 7.99979V5.99979H4.00488V7.99979H20.0049Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Card
