import React from 'react'
import { WImage, Button, EditableTitle, EditableDescription, Tag } from '@components/atoms'
import styles from './TextImageLarge.module.sass'
import { getIcon } from '@utils/icons'
import { handleClickCategoryTracking, handleClickPromoTracking } from '@utils/tracking'
import { checkUrlAndTrackPageBuilder } from '@utils/index'
import { useRouter } from 'next/router'

interface Props {
  item: any
  data?: string
  caption?: string
  imagePos?: 'left' | 'right'
  theme?: 'light' | 'neutrallight' | 'secondarydark' | 'black'
  editMode?: boolean
  staticMedia?: boolean
  className?: string
}

const TextImageLarge = (props: Props) => {
  const {
    item = undefined,
    data = undefined,
    caption = undefined,
    imagePos = 'left',
    theme = 'light',
    editMode = false,
    staticMedia = false,
    className = '',
  } = props

  const router = useRouter()

  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        styles[`root--imagePos-${imagePos}`]
      } 
      ${item.borderTop ? styles[`root--bt`] : ''} 
      ${item.borderBottom ? styles[`root--bb`] : ''} 
      ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      <div className={`${styles.imageBox}`}>
        {!!item.image && (
          <WImage
            src={item.image}
            maxWidth={960}
            maxHeight={500}
            objectFit="cover"
            alt={item.alt}
            title={item.alt}
            staticMedia={staticMedia}
          />
        )}
      </div>
      <div>
        <div className={styles.main}>
          {(!!item.name || !!item.description) && (
            <div className={styles.content}>
              <Tag
                label={item.intro}
                variant={['secondarydark', 'black'].includes(theme) ? 'white' : 'black'}
                size={'sm'}
                className={styles.intro}
                editMode={editMode}
              />
              <EditableTitle
                title={data}
                variant="span"
                className={styles.data}
                editMode={editMode}
              />
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
              <EditableDescription
                description={caption}
                className={styles.caption}
                editMode={editMode}
              />
            </div>
          )}
          {editMode ? (
            <>{item.children}</>
          ) : (
            !!item.children?.filter((x) => !!x.url && !!x.ctaLabel)?.length && (
              <div className={styles.ctaBox}>
                {item.children?.map((x) => (
                  <Button
                    key={x.pk}
                    label={x.ctaLabel}
                    href={x.url}
                    onClick={() => checkUrlAndTrackPageBuilder(x, router.asPath)}
                    newPage={x.blank}
                    iconLeft={
                      x.iconLeft
                        ? x.iconLeft
                        : !!x.icon && x.iconPos === 'left'
                        ? getIcon(x.icon)
                        : undefined
                    }
                    iconRight={
                      x.iconRight
                        ? x.iconRight
                        : !!x.icon && x.iconPos === 'right'
                        ? getIcon(x.icon)
                        : undefined
                    }
                    variant={x.style}
                    color={
                      ['secondary', 'ghost'].includes(x.style) &&
                      ['secondarydark', 'black'].includes(theme)
                        ? 'white'
                        : ''
                    }
                    theme={['secondarydark', 'black'].includes(theme) ? 'dark' : 'light'}
                    size={'lg'}
                    mobileSize={'md'}
                  />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TextImageLarge
