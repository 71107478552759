import React, { act, useContext, useEffect, useState } from 'react'
import styles from './CheckoutPromoForm.module.sass'
import { Accordion, Button, FiltroApplicato, FormField, FormText } from '@components/atoms'
import { PromozioneNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { CartContext } from '@utils/cartContext'
import { trackingGaEventNew } from '@utils/gtm'
import { handleCouponTracking } from '@utils/tracking'

interface Props {
  id: string
  promo?: PromozioneNode
  className?: string
}

const CheckoutPromoForm = (props: Props) => {
  const { id = undefined, promo = undefined, className = '' } = props

  const t = useTrans()
  const [value, setValue] = useState('')

  const {
    setPromo,
    removePromo: removePromoContext,
    setPromoCodeError,
    setPromoCodeLoading,
  } = useContext(CartContext)

  const handlePromo = () => {
    if (!value) return
    setPromo(value)
  }

  const removePromo = () => {
    removePromoContext()
    handleCouponTracking('ok', promo?.codice, 'remove')
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Accordion id={`${id}_accordion`} label={t('Inserisci un codice sconto')}>
        {promo?.codice ? (
          <div className={styles.applied}>
            <FiltroApplicato label={promo.codice} onClose={removePromo} />
            <Button
              label={t('Rimuovi codice')}
              variant="ghost"
              size="sm"
              onClick={removePromo}
              className={styles.removeButton}
            />
          </div>
        ) : (
          <div className={styles.form}>
            <FormField
              id="checkout_promo"
              status={!!setPromoCodeError ? 'error' : undefined}
              errorMessage={t(setPromoCodeError)}
              className={styles.fieldWrap}
            >
              <FormText
                id="checkout_promo"
                placeholder={t('Codice sconto')}
                name={'promo'}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className={styles.field}
                status={!!setPromoCodeError ? 'error' : undefined}
              />
            </FormField>
            <Button
              label={t('Applica')}
              variant="secondary"
              onClick={handlePromo}
              loading={setPromoCodeLoading}
            />
          </div>
        )}
      </Accordion>
    </div>
  )
}

export default CheckoutPromoForm
