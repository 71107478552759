/* eslint-disable react/forbid-dom-props */
import React, { useEffect, useRef, useState } from 'react'
import styles from './ConfrontaProdotti.module.sass'
import useComparatore from '@hooks/useComparatore'
import { CategoriaNode, useProdottiComparatoreQuery } from '@gql/graphql'
import { compareAttributes, getHeaderHeight } from '@utils/safe'
import { mockArray, mockProdotto } from '@utils/mock'
import { Button, FormToggle, WContainer, WLink } from '@components/atoms'
import {
  HEADER_BREAKPOINT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  PREHEADER_BREAKPOINT,
  PREHEADER_HEIGHT,
  PREHEADER_HEIGHT_MOBILE,
} from '@utils/settings'
import useFindMenu from '@hooks/useFindMenu'
import CompareCard from '@components/molecules/CompareCard/CompareCard'
import useTrans from '@hooks/useTrans'
import uniqBy from 'lodash/uniqBy'
import { Loader, Plus } from '@components/icons'
import { LoaderOverlay } from '@components/molecules'

interface Props {
  className?: string
  categoria?: CategoriaNode
}

const ConfrontaProdotti = (props: Props) => {
  const { className = '', categoria = null } = props

  const t = useTrans()
  const tbodyRef = useRef(null)
  const headersRef = useRef<HTMLTableCellElement[]>([])
  const [scrolled, setScrolled] = useState(false)
  const [stickyHeadTranslate, setStickyHeadTranslate] = useState(0)
  const [stickyHeadWidths, setStickyHeadWidths] = useState([])

  const stripeDesktop = useFindMenu('stripe_desktop')
  const stripeMobile = useFindMenu('stripe_mobile')

  const emptyItem = {
    name: t('Aggiungi prodotti da confrontare'),
    ctaLabel: t('Aggiungi un prodotto'),
    url: categoria?.url,
  }

  const { compareItems, removeItem } = useComparatore(categoria?.pk)
  const { data, loading } = useProdottiComparatoreQuery({
    variables: { ids: compareItems?.[categoria.pk]?.map((x) => x.pk) },
    fetchPolicy: 'cache-first',
  })

  const differences = compareAttributes(data?.prodottiByIds)

  const [showOnlyDiff, setShowOnlyDiff] = useState(false)

  const chiaviTipiAttributi = uniqBy(
    data?.prodottiByIds
      ?.map((x) =>
        x.attributi
          .filter((a) => !a.valori.some((v) => v.image))
          .map((a) => ({ chiave: a.chiave, nome: a.valori[0].attributo.nome }))
      )
      .flat(),
    'chiave'
  )

  // Gestione stickyHead visibile passati gli headers
  useEffect(() => {
    const handleScroll = () => {
      const bounds = tbodyRef.current?.getBoundingClientRect()
      if (bounds) {
        const headerHeight = getHeaderHeight()
        setScrolled(bounds.top < headerHeight)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [tbodyRef])

  // Gestione larghezza stickyHeaders
  useEffect(() => {
    if (headersRef?.current?.length) {
      const resizeObservers = []
      headersRef.current?.forEach((el) => {
        if (el) {
          const resizeObserver = new ResizeObserver(() => {
            setStickyHeadWidths(headersRef?.current.map((x) => x?.clientWidth))
          })
          resizeObserver.observe(el)
          resizeObservers.push(resizeObserver)
        }
        resizeObservers.push(undefined)
      })
      return () => resizeObservers.forEach((el) => el?.disconnect())
    }
  }, [headersRef?.current?.length])

  return (
    <div className={`${styles.root} ${scrolled ? styles['root--scrolled'] : ''} ${className}`}>
      <WContainer>
        <div className={styles.stickyWrap}>
          {loading ? (
            <LoaderOverlay />
          ) : data?.prodottiByIds?.length > 0 ? (
            <>
              <FormToggle
                id={'confrontaprodotti_toggle'}
                label={t('Mostra solo le differenze')}
                checked={showOnlyDiff}
                onChange={(e) => setShowOnlyDiff(e.target.checked)}
                className={styles.toggle}
              />
              {data.prodottiByIds.length > 0 && (
                <div className={styles.stickyHead}>
                  <div
                    className={`${styles.stickyHeadList} ${
                      styles[`stickyHeadList--n${data.prodottiByIds.length === 1 ? 2 : 3}`]
                    }`}
                    style={
                      stickyHeadTranslate
                        ? {
                            transform: `translateX(-${stickyHeadTranslate}px)`,
                          }
                        : undefined
                    }
                  >
                    {data.prodottiByIds.map((prodotto, index) => (
                      <div
                        key={prodotto.pk}
                        className={styles.stickyHeadItem}
                        style={
                          stickyHeadWidths?.[index + 1]
                            ? { minWidth: `${stickyHeadWidths[index + 1]}px` }
                            : undefined
                        }
                      >
                        {prodotto.nome}
                      </div>
                    ))}
                    {data.prodottiByIds.length < 3 && (
                      <div
                        className={styles.stickyHeadItem}
                        style={
                          stickyHeadWidths?.[data.prodottiByIds.length]
                            ? { minWidth: `${stickyHeadWidths[data.prodottiByIds.length]}px` }
                            : undefined
                        }
                      >
                        &nbsp;
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`${styles.tableWrap} ${loading ? styles['tableWrap--loading'] : ''}`}
                onScroll={(scroll) => setStickyHeadTranslate(scroll.target?.scrollLeft)}
              >
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th ref={(el: HTMLTableCellElement) => (headersRef.current[0] = el)}></th>
                      {data.prodottiByIds.map((prodotto, index) => (
                        <th
                          key={prodotto.pk}
                          className={styles.productHeader}
                          ref={(el: HTMLTableCellElement) => (headersRef.current[index + 1] = el)}
                        >
                          <CompareCard prodotto={prodotto} onClose={() => removeItem(prodotto)} />
                        </th>
                      ))}
                      {data.prodottiByIds.length < 3 && (
                        <th
                          className={styles.addCompareProductHeader}
                          ref={(el: HTMLTableCellElement) =>
                            (headersRef.current[data.prodottiByIds.length] = el)
                          }
                        >
                          <div className={styles.addCompareProductHeaderContent}>
                            <span className={styles.addCompareProductHeaderIcon}>
                              <Plus />
                            </span>
                            <Button
                              label={t('Aggiungi un prodotto')}
                              variant="secondary"
                              size="lg"
                              mobileSize="md"
                              href={categoria?.url}
                            />
                          </div>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody ref={tbodyRef}>
                    {chiaviTipiAttributi.map((x) => (
                      <tr
                        key={x.chiave}
                        className={`${styles.diffRow} ${
                          showOnlyDiff && !differences.includes(x.chiave)
                            ? styles['diffRow--hidden']
                            : ''
                        }`}
                      >
                        <td className={styles.rowTitleDesktop}>{x.nome}</td>
                        {data.prodottiByIds.map((prodotto, index) => (
                          <td key={`prodotto_${index}`}>
                            <div className={styles.rowTitle}>{x.nome}</div>
                            <div className={styles.values}>
                              {prodotto.attributi
                                .filter((x) => !x.valori.some((v) => v.image))
                                .find((a) => a.chiave === x.chiave)
                                ?.valori?.map((v) => v.nome)
                                .join(', ') || '-'}
                            </div>
                          </td>
                        ))}
                        {data.prodottiByIds.length < 3 && (
                          <td className={styles.addCompareProductRow}></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className={styles.empty}>
              {!!emptyItem?.name && <h2 className={styles.emptyTitle}>{emptyItem.name}</h2>}
              {!!emptyItem?.ctaLabel && !!emptyItem.url && (
                <Button
                  label={emptyItem.ctaLabel}
                  href={emptyItem.url}
                  iconLeft={<Plus />}
                  variant="secondary"
                  size="lg"
                  className={styles.emptyCta}
                />
              )}
            </div>
          )}
        </div>
      </WContainer>
    </div>
  )
}

export default ConfrontaProdotti
