import React from 'react'
import styles from './ButtonIcon.module.sass'
import { Loader } from '@components/icons'
import WLink from '../WLink/WLink'

interface Props {
  ariaLabel?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  icon?: React.ReactNode
  type?: 'button' | 'submit'
  color?: 'black' | 'neutral' | 'primary' | 'transparent'
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  href?: string
  newPage?: boolean
  loading?: boolean
  external?: boolean
  tabIndex?: number
  className?: string
}

const ButtonIcon = (props: Props) => {
  const {
    ariaLabel = undefined,
    type = 'button',
    color = 'black',
    size = 'md',
    icon = undefined,
    disabled = false,
    onClick = undefined,
    href = null,
    newPage = false,
    loading = false,
    external = false,
    tabIndex = undefined,
    className = '',
  } = props

  const classeNames = `
    ${styles.root} 
    ${styles[`root--${size}`]}
    ${color ? styles[`root--${color}`] : ''}
    ${loading ? styles['root--loading'] : ''}
    ${className ? className : ''}`

  const buttonContent = (
    <>
      {icon && <span className={styles.icon}>{icon}</span>}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : null}
        rel={`noreferrer ${newPage ? 'noopener' : ''}`}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {buttonContent}
      </a>
    ) : (
      <WLink
        href={href}
        onClick={onClick}
        className={classeNames}
        // rel={`noreferrer ${newPage ? 'noopener' : ''}`}
        newPage={newPage}
        ariaLabel={ariaLabel}
      >
        {buttonContent}
      </WLink>
    )
  ) : onClick || ['submit', 'reset'].includes(type) ? (
    <button
      type={type}
      className={classeNames}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex || (onClick || type === 'submit' ? undefined : -1)}
      aria-label={ariaLabel}
    >
      {buttonContent}
    </button>
  ) : (
    <span className={classeNames}>{buttonContent}</span>
  )
}

export default ButtonIcon
