import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import ImageToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'
import LayoutToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const TextImageLargeSettings = (settingsProps) => {
  const themeOpts = [
    { label: 'Light', value: 'light' },
    { label: 'Neutral light', value: 'neutrallight' },
    { label: 'Dark Secondary', value: 'secondarydark' },
    { label: 'Black', value: 'black' },
  ]

  const imagePosOpts = [
    { label: 'Sinistra', value: 'left' },
    { label: 'Destra', value: 'right' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          options={themeOpts}
          label="Tema"
          defaultValue={defaultProps['theme']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="imagePos"
          type="select"
          options={imagePosOpts}
          label="Posizione immagine"
          defaultValue={defaultProps['imagePos']}
        />
      </ToolbarSection>
      {!settingsProps.floating && (
        <>
          <ImageToolbarSection defaultProps={defaultProps} />
          <LayoutToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const TextImageLargeFloatingSettings = () =>
  React.createElement(TextImageLargeSettings, { floating: true })
