import React from 'react'
import styles from './SearchBar.module.sass'

interface Props {
  value?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  className?: string
}

const SearchBar = (props: Props) => {
  const {
    value = undefined,
    placeholder = undefined,
    onChange = undefined,
    className = '',
    onKeyDown = undefined,
  } = props

  return (
    <div className={`${styles.root} ${className}`}>
      <input
        type="text"
        name="q"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={styles.input}
        onKeyDown={onKeyDown}
        autocomplete="off"
      />
    </div>
  )
}

export default SearchBar
