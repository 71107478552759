import { ROOT_NODE } from '@craftjs/core'
import { deserializeNodeTree, addNodeTree } from 'react-pagebuilder/utils/node'
import { canDropBlock } from 'react-pagebuilder/utils/rules'
import { parseNodeTree } from 'react-pagebuilder/utils/block'
import { useEffect, useState, useContext } from 'react'
import { CustomBlocksContext } from 'react-pagebuilder/services/customBlocksContext'
import { FormCustomBlock } from './FormCustomBlock'
import { Bin, Add, GenericBlock } from 'react-pagebuilder/pb-components/icons'
import { Button } from 'react-pagebuilder/pb-components/atoms'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'

export const CustomToolbox = () => {
  const {
    connectors: { create },
    query,
    actions,
    selectedNodeId,
  } = useEditor((state) => ({
    selectedNodeId: [...state.events.selected]?.[0],
  }))

  const { customBlocks: customBlocksObject, deleteCustomBlock } = useContext(CustomBlocksContext)

  const [customBlocks, setCustomBlocks] = useState()

  useEffect(() => {
    if (customBlocksObject) {
      let tmp = []
      customBlocksObject.forEach((block) => {
        let newBlock = JSON.parse(JSON.stringify(block))
        const serializedNodeTree = JSON.parse(block.json_content)
        const nodeTree = deserializeNodeTree(serializedNodeTree, query)
        newBlock['nodeTree'] = nodeTree
        const element = parseNodeTree(nodeTree, nodeTree['rootNodeId'])
        newBlock['element'] = element
        if (!block.prebuilt) {
          tmp.push(newBlock)
        }
      })
      setCustomBlocks(tmp)
    }
  }, [customBlocksObject])

  const onDeleteCustomBlock = (name) => {
    deleteCustomBlock(name)
  }

  const addBlock = (element) => {
    const nodeTree = query.parseReactElement(element).toNodeTree()
    const currentNode = nodeTree['nodes'][nodeTree['rootNodeId']]
    let targetNode = null
    if (selectedNodeId) {
      targetNode = query.node(selectedNodeId).get()
    } else {
      targetNode = query.node(ROOT_NODE).get()
    }

    if (canDropBlock(currentNode, targetNode, query.node)) {
      addNodeTree(nodeTree, targetNode.id, actions)
    } else {
      console.log(`Can not drop ${currentNode.data?.name} in ${targetNode.data?.name}`)
    }
  }

  const renderBlock = (element, name) => {
    return (
      <div className="custom-toolbox__tools__item" ref={(ref) => create(ref, element)}>
        <GenericBlock />
        <span>{name}</span>
      </div>
    )
  }

  return (
    <div className="custom-toolbox">
      {/*<div className="custom-toolbox__search"> <SearchCustomBlock /> </div>*/}
      <div className="custom-toolbox__create">
        <FormCustomBlock nodeId={selectedNodeId} />
      </div>
      <div className="custom-toolbox__tools">
        {customBlocks?.length > 0 ? (
          <div className="custom-toolbox__custom-blocks">
            {customBlocks.map((block) => {
              return (
                <div className="custom-toolbox__custom-blocks__item" key={block?.name}>
                  {renderBlock(block.element, block?.name)}
                  <Button
                    variant="ghost"
                    size="sm"
                    icon={<Add />}
                    onClick={() => addBlock(block.element)}
                    className="custom-toolbox__tools__item__add"
                  />
                  <Button
                    variant="ghost"
                    size="sm"
                    icon={<Bin />}
                    onClick={() => onDeleteCustomBlock(block?.name)}
                    className="custom-toolbox__tools__item__delete"
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <div className="empty-message">No elements yet</div>
        )}
      </div>
    </div>
  )
}
