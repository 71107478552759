import { trackingEcommerceEvent, trackingGaEventNew } from './gtm'
import { OrdineNode, ProdottoNode, RigaOrdineNode } from '@gql/graphql'
import { Cart } from './cartContext'
import { ProdottoLinkedObjectType } from './types'
import { reverseGeocode } from './mapbox'

const formatPrice = (price?: number) => {
  return Number(price.toFixed(2))
}

const getCurrentItemBrand = () => {
  let item_brand = ''
  switch (process.env.SITE_ID) {
    case '1':
      item_brand = 'efco'
      break
    case '2':
      item_brand = 'oleo_mac'
      break
    case '3':
      item_brand = 'bertolini'
      break
    case '4':
      item_brand = 'nibbi'
      break
    default:
      item_brand = ''
  }
  return item_brand
}

const getListFromFilter = (filter: string) => {
  let actionFieldList = ''

  switch (filter) {
    case 'consigli':
    case 'reccomended':
      actionFieldList = 'sorting_suggest'
      break
    case 'novita':
      actionFieldList = 'sorting_new'
      break
    case 'offerte':
      actionFieldList = 'sorting_promo'
      break
    case 'prezzo_stato_acquistabile':
      actionFieldList = 'sorting_incrising_price'
      break
    case '-prezzo_stato_acquistabile':
      actionFieldList = 'sorting_decreasing_price'
      break
    case 'pdp':
      actionFieldList = 'pdp'
      break
    default:
      actionFieldList = 'category_listing'
  }

  return actionFieldList
}

const getCurrentList = (filterList: string | undefined = undefined) => {
  if (filterList) {
    return getListFromFilter(filterList)
  }

  if (document.location.href.includes('?q=')) {
    return 'search_results'
  }

  if (document.location.href.includes('/p/')) {
    return 'correlati'
  }

  return 'category_listing'
}

const getAddPositionList = (sku: string) => {
  return sessionStorage.getItem(sku) ?? 'cart'
}

// const getCurrentPosition = (path: string) => {
//   let position = ''

//   switch (path) {
//     case '/':
//       position = 'homepage'
//       break
//     case '/promozioni':
//       position = 'promozioni'
//       break
//     case '/cart':
//       position = 'cart'
//       break
//     case '/cart/checkout':
//       position = 'checkout'
//       break
//     case '/contatti':
//       position = 'contatti'
//       break
//     case '/myaccount':
//       position = 'myaccount'
//       break
//     default:
//       position = ''
//       break
//   }

//   return position
// }

const getCurrentPosition = (path: string) => {
  if (path === '/') {
    return 'homepage'
  }

  return path
}

const getCurrency = (prodotto: ProdottoNode | ProdottoLinkedObjectType) => {
  if ('valuta' in prodotto && prodotto.valuta?.isoCode) {
    return prodotto.valuta.isoCode
  }

  return 'EUR'
}

const getCategoryLevels = (categoria) => {
  return `${categoria?.parent?.parent?.boxTitolo
    ?.toLowerCase()
    ?.replaceAll(' ', '_')}-${categoria?.parent?.boxTitolo
    ?.toLowerCase()
    ?.replaceAll(' ', '_')}-${categoria?.boxTitolo?.toLowerCase()?.replaceAll(' ', '_')}`
}

const getCategoryLevelsSplit = (categoria) => {
  return {
    level1: categoria?.parent?.parent?.boxTitolo
      ? categoria?.parent?.parent?.boxTitolo?.toLowerCase()?.replaceAll(' ', '_')
      : '',
    level2: categoria?.parent?.boxTitolo
      ? categoria?.parent?.boxTitolo?.toLowerCase()?.replaceAll(' ', '_')
      : '',
    level3: categoria?.boxTitolo ? categoria?.boxTitolo?.toLowerCase()?.replaceAll(' ', '_') : '',
  }
}

const formatProduct = (
  prodotto: ProdottoNode | ProdottoLinkedObjectType,
  cart?: Cart,
  action?: 'add' | 'remove',
  filter?: string
) => {
  return {
    item_name: prodotto?.nome ? prodotto.nome.toLowerCase().replaceAll(' ', '_') : '',
    item_id: prodotto.codice,
    price: prodotto.prezzoScontato
      ? formatPrice(prodotto.prezzoScontato ?? 0)
      : formatPrice(prodotto.prezzo ?? 0),
    item_brand: getCurrentItemBrand(),
    item_category: getCategoryLevelsSplit(prodotto.categoria).level1,
    item_category2: getCategoryLevelsSplit(prodotto.categoria).level2,
    item_category3: getCategoryLevelsSplit(prodotto.categoria).level3,
    item_variant:
      'promo' in prodotto
        ? prodotto.promo
          ? 'promo_si'
          : 'promo_no'
        : prodotto.prezzoScontato
        ? 'promo_si'
        : 'promo_no',
    quantity: cart ? getQuantityOfSelectedProduct(prodotto, cart, action) : 0,
    availability: prodotto.disponibile ? 'disponibile' : 'out_of_stock',
    item_list_name: getCurrentList(filter),
  }
}

const formatProductImpression = (
  prodotti: ProdottoNode[] | ProdottoLinkedObjectType[],
  cart,
  list
) => {
  const formattedItems = prodotti.map((prodotto: ProdottoNode | ProdottoLinkedObjectType) => ({
    item_name: prodotto?.nome ? prodotto.nome.toLowerCase().replaceAll(' ', '_') : '',
    item_id: prodotto.codice,
    price: prodotto.prezzoScontato
      ? formatPrice(prodotto.prezzoScontato ?? 0)
      : formatPrice(prodotto.prezzo ?? 0),
    item_brand: getCurrentItemBrand(),
    item_category: getCategoryLevelsSplit(prodotto.categoria).level1 ?? '',
    item_category2: getCategoryLevelsSplit(prodotto.categoria).level2 ?? '',
    item_category3: getCategoryLevelsSplit(prodotto.categoria).level3 ?? '',
    item_variant:
      'promo' in prodotto
        ? prodotto.promo
          ? 'promo_si'
          : 'promo_no'
        : prodotto.prezzoScontato
        ? 'promo_si'
        : 'promo_no',
    quantity: getQuantityOfSelectedProduct(prodotto, cart),
    item_list_name: list,
    availability: prodotto.disponibile ? 'disponibile' : 'out_of_stock',
  }))

  return formattedItems
}

const formatProductsFromCart = (cart) => {
  const formattedItems = cart.items.map((item) => ({
    item_name: item?.prodotto?.prodotto?.nome
      ? item.prodotto.prodotto.nome.toLowerCase().replaceAll(' ', '_')
      : '',
    item_id: item.prodotto.prodotto.codice,
    price: formatPrice(item.prodotto.prodotto.prezzoScontato ?? 0),
    item_brand: getCurrentItemBrand(),
    item_category: getCategoryLevels(item.prodotto.prodotto.categoria),
    item_variant: item.prodotto.prodotto.promo ? 'promo_si' : 'promo_no',
    quantity: item.quantita,
    item_list_name: getAddPositionList(item.prodotto.prodotto.codice),
    availability: item.prodotto.prodotto.disponibile ? 'disponibile' : 'out_of_stock',
  }))

  return formattedItems
}

const getPaymentType = (alias) => {
  switch (alias) {
    case 'Bonifico':
      return 'bank_transfer'
    case 'Carta di credito':
      return 'credit_card'
    case 'PayPal':
      return 'paypal'
    default:
      return ''
  }
}

const generateFormattedItems = (righeOrdine) =>
  righeOrdine
    .filter((r) => r.prodotto)
    .map((riga) => ({
      item_name: riga?.prodotto?.prodotto?.nome
        ? riga?.prodotto?.prodotto?.nome.toLowerCase().replaceAll(' ', '_')
        : '',
      item_id: riga?.prodotto?.prodotto?.codice ?? '',
      price: formatPrice(riga?.prezzoTotaleScontato ?? 0),
      item_brand: getCurrentItemBrand(),
      item_category: getCategoryLevels(riga?.prodotto?.prodotto?.categoria),
      item_variant: riga?.prodotto?.prodotto?.promo ? 'promo_si' : 'promo_no',
      quantity: riga.quantitaOrdinata,
      item_list_name: getAddPositionList(riga?.prodotto?.prodotto?.codice),
      availability: riga?.prodotto?.prodotto?.disponibile ? 'disponibile' : 'out_of_stock',
    }))

export const getQuantityOfSelectedProduct = (
  prodotto: ProdottoNode | ProdottoLinkedObjectType,
  cart: Cart,
  action?: 'add' | 'remove'
) => {
  if (cart) {
    const selectedProduct = cart.items.find((item) => item.prodotto.prodotto.pk === prodotto.pk)
    if (action === 'add') {
      return selectedProduct ? selectedProduct.quantita + 1 : 1
    }
    if (action === 'remove') {
      return selectedProduct ? selectedProduct.quantita - 1 : 0
    }

    return selectedProduct ? selectedProduct.quantita : 0
  }
  return 0
}

export const handleAddToCartTracking = (
  prodotto: ProdottoNode,
  cart: Cart,
  filter: string | undefined = undefined
) => {
  // mi salvo la posizione in cui è stato aggiunto il prodotto
  sessionStorage.setItem(prodotto.codice, getCurrentList(filter))

  trackingEcommerceEvent('add_to_cart', {
    currency: getCurrency(prodotto),
    items: [formatProduct(prodotto, cart, 'add', filter)],
  })
}

export const handleRemoveFromCartTracking = (prodotto: ProdottoNode, cart: Cart) => {
  trackingEcommerceEvent('remove_from_cart', {
    items: [formatProduct(prodotto, cart)],
  })
}

export const handleWishListTracking = (prodotto: ProdottoNode, cart: Cart) => {
  trackingEcommerceEvent('add_to_wishlist', {
    currency: getCurrency(prodotto),
    items: [formatProduct(prodotto, cart)],
  })
}

export const handleRemoveWishListTracking = (prodotto: ProdottoNode, path: string) => {
  trackingGaEventNew('remove_from_wishlist', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: prodotto.codice ?? prodotto.pk,
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleCouponTracking = async (
  label: 'ok' | 'ko',
  discountValue: string,
  action: 'add' | 'remove'
) => {
  trackingGaEventNew('coupon', {
    searchTerm: '',
    position: '',
    label: label,
    type: discountValue,
    step: '',
    category: '',
    action: action,
  })
}

export const handleViewCartTracking = (cart) => {
  trackingEcommerceEvent('view_cart', {
    currency: 'EUR',
    order_value: cart.totaleScontato,
    items: formatProductsFromCart(cart),
  })
}

export const handleBeginCheckoutTracking = (cart) => {
  trackingEcommerceEvent('begin_checkout', {
    currency: 'EUR',
    order_value: cart.totaleScontato,
    coupon: cart.promoApplicata ? cart.promoApplicata.codice : null,
    items: formatProductsFromCart(cart),
  })
}

export const handleShippingCheckoutTracking = (cart, modalitaConsegna: string) => {
  const shippingTier =
    modalitaConsegna === 'corriere_rivenditori' ? 'consegna_domicilio' : 'ritiro_in_negozio'

  trackingEcommerceEvent('add_shipping_info', {
    currency: 'EUR',
    shipping_tier: shippingTier,
    order_value: cart.totaleScontato,
    coupon: cart.promoApplicata ? cart.promoApplicata.codice : null,
    items: formatProductsFromCart(cart),
  })
}

export const handlePaymentCheckoutTracking = (paymentMethod: string, cart) => {
  let paymentType = ''
  switch (paymentMethod) {
    case 'Bonifico':
      paymentType = 'bank_transfer'
      break
    case 'Carta di credito':
      paymentType = 'credit_card'
      break
    case 'PayPal':
      paymentType = 'paypal'
      break
  }

  trackingEcommerceEvent('add_payment_info', {
    currency: 'EUR',
    payment_type: paymentType,
    order_value: cart.totaleScontato,
    coupon: cart.promoApplicata ? cart.promoApplicata.codice : null,
    items: formatProductsFromCart(cart),
  })
}

export const handlePurchaseTracking = (ordine) => {
  const paymentType = getPaymentType(ordine.sistemaPagamento.alias)
  const shippingTier =
    ordine.sistemaSpedizione.chiave === 'corriere_rivenditori'
      ? 'consegna_domicilio'
      : 'ritiro_in_negozio'
  const formattedItems = generateFormattedItems(ordine.righeOrdine)
  const spedizione = ordine.righeOrdine.find((r) => r.tipologia.toLowerCase() === 'spedizione')

  trackingEcommerceEvent('purchase', {
    transaction_id: ordine?.codice,
    currency: 'EUR',
    coupon: ordine?.promoCode?.toLowerCase() ?? null,
    payment_type: paymentType,
    shipping_tier: shippingTier,
    value: ordine?.prezzoTotaleScontato,
    shippping: spedizione.prezzoTotaleScontato ?? null,
    coupon_value: ordine.promozioneValoreAssoluto ?? null,
    items: formattedItems,
  })
}

export const handleSubscribeNewsletterTracking = async (path: string) => {
  trackingGaEventNew('newsletter_subscription', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleTrackingRequestRefund = async (
  ordine: OrdineNode,
  righeOrdine: RigaOrdineNode[]
) => {
  trackingEcommerceEvent('refund', {
    currency: 'EUR',
    refund_value: ordine.prezzoTotaleScontato ?? ordine.prezzoTotale,
    refund: {
      items: righeOrdine.map((riga) => ({
        item_name: riga.prodotto.prodotto.nome,
        item_id: riga.prodotto.prodotto.codice,
        price: riga.prodotto?.prodotto?.prezzoScontato
          ? formatPrice(riga.prodotto.prodotto.prezzoScontato ?? 0)
          : formatPrice(riga.prodotto.prodotto.prezzo ?? 0),
        item_brand: getCurrentItemBrand(),
        item_category: getCategoryLevels(riga.prodotto.prodotto.categoria),
        item_variant: riga.prodotto.prodotto.promo ? 'promo_si' : 'promo_no',
        quantity: riga.quantitaSpedita ?? riga.quantitaOrdinata,
      })),
    },
  })
}

export const handleUserLoginTracking = async (socialLogin?: string, stepError?: boolean) => {
  let position = 'header'
  let type = 'mail'
  let step = stepError ? 'error' : 'complete'

  if (socialLogin) {
    if (socialLogin === 'google-oauth2') {
      type = 'google'
    } else type = 'facebook'
  }

  trackingGaEventNew('login', {
    searchTerm: '',
    position,
    label: '',
    type,
    step: step,
    category: '',
    action: '',
  })
}

export const handleSignUpStartTracking = async (socialLogin?: string, positionCta?: string) => {
  let position = positionCta ? positionCta : 'header'
  let type = 'mail'

  if (socialLogin) {
    if (socialLogin === 'google-oauth2') {
      type = 'google'
    } else type = 'facebook'
  }

  trackingGaEventNew('sign_up_start', {
    searchTerm: '',
    position,
    label: '',
    type,
    step: '',
    category: '',
    action: '',
  })
}

export const handleSignUpCompleteTracking = async (tipoUtente: string, status: string) => {
  let type = tipoUtente === 'privato' ? 'privato' : 'azienda'
  let step = status

  trackingGaEventNew('sign_up', {
    searchTerm: '',
    position: '',
    label: '',
    type,
    step,
    category: '',
    action: '',
  })
}

export const handleClickCategoryTracking = (label: string, path: string) => {
  trackingGaEventNew('click_category', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleMiniCartTracking = (label: string) => {
  trackingGaEventNew('mini-cart', {
    searchTerm: '',
    position: '',
    label: label,
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleListingFilterTracking = (label: string, type: string, action: boolean) => {
  trackingGaEventNew('listing_filter', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: type ? type.toLowerCase().replaceAll(' ', '_') : '',
    step: '',
    category: '',
    action: action === true ? 'remove' : 'add',
  })
}

export const handleProductClickTracking = (
  prodotto: ProdottoNode | ProdottoLinkedObjectType,
  filter: undefined | string = undefined
) => {
  trackingEcommerceEvent('select_item', {
    currency: getCurrency(prodotto),
    items: [formatProduct(prodotto, undefined)],
  })
}

export const handleProductDetailTracking = (prodotto: ProdottoNode, cart) => {
  let list = 'category_listing'

  if (document.referrer.indexOf('?q=') > -1) {
    list = 'search_results'
  }

  if (document.referrer.indexOf('/p/') > -1) {
    list = 'correlati'
  }

  trackingEcommerceEvent('view_item', {
    currency: getCurrency(prodotto),
    items: [formatProduct(prodotto, cart)],
  })
}

export const handleProductImpressionTracking = async (
  products: ProdottoNode[] | ProdottoLinkedObjectType[],
  cart
) => {
  const splitProducts = []
  let i = 0
  while (i < products.length) {
    splitProducts.push(products.slice(i, (i += 12)))
  }

  for (const group of splitProducts) {
    trackingEcommerceEvent('view_item_list', {
      currency: getCurrency(group[0]),
      items: formatProductImpression(group, cart, getCurrentList()),
    })
  }
}

export const handleCartInteractionTracking = (label: string, action: boolean) => {
  trackingGaEventNew('more_info', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: action === true ? 'close' : 'expand',
  })
}

export const handleCosaDeviFareTracking = (type: string, label: string) => {
  trackingGaEventNew('cosa_devi_fare', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: type ? type.toLowerCase().replaceAll(' ', '_') : '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleDownloadTracking = (label: string, type: string, path: string) => {
  trackingGaEventNew('download', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type,
    step: '',
    category: '',
    action: '',
  })
}

export const handleClickPromoTracking = (label: string, path: string) => {
  trackingGaEventNew('click_promo', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleClickCtaTracking = (label: string, path: string) => {
  trackingGaEventNew('click_cta', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleSelectContentTracking = (
  label: string,
  type: 'article' | 'blog' | 'catalog',
  category = '',
  path: string
) => {
  trackingGaEventNew('select_content', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: type ? type.toLowerCase().replaceAll(' ', '_') : '',
    step: '',
    category: category ? category.toLowerCase().replaceAll(' ', '_') : '',
    action: '',
  })
}

export const handleFooterTracking = (label: string, type: string) => {
  trackingGaEventNew('footer', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll('-', '_') : '',
    type: type ? type.toLowerCase().replaceAll('-', '_') : '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleHeaderTracking = (label: string) => {
  trackingGaEventNew('header', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleHeaderMenuTracking = (label: string, level: number) => {
  let positionLevel = ''
  switch (level) {
    case 0:
      positionLevel = 'first_level_menu'
      break
    case 1:
      positionLevel = 'second_level_menu'
      break
    case 2:
      positionLevel = 'third_level_menu'
      break
    default:
      positionLevel = ''
  }

  trackingGaEventNew('menu', {
    searchTerm: '',
    position: positionLevel,
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleSearchTypeTracking = (searchTerm: string) => {
  trackingGaEventNew('search', {
    searchTerm: searchTerm ? searchTerm.toLowerCase().replaceAll(' ', '_') : '',
    position: '',
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleSearchClickTracking = (searchTerm: string, label: string) => {
  trackingGaEventNew('search_click', {
    searchTerm: searchTerm,
    position: '',
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleBuyInPairsTracking = (productCode: string) => {
  trackingGaEventNew('acquista_coppia', {
    searchTerm: '',
    position: '',
    label: productCode ? productCode?.toLowerCase()?.replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleListingSortingTracking = (label: string) => {
  trackingGaEventNew('listing_sort_by', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleCompareTracking = (products: ProdottoNode[], action: string) => {
  const labels = products.map((product) => product.codice || product.pk)

  trackingGaEventNew('comparatore_confronta', {
    searchTerm: '',
    position: '',
    label: labels.join(','),
    type: labels.length,
    step: '',
    category: '',
    action: action,
  })
}

export const handleRescuePasswordTracking = (
  step: 'step1_cta' | 'step2_send' | 'step3_save',
  label: 'ko' | 'ok'
) => {
  trackingGaEventNew('rescue_password', {
    searchTerm: '',
    position: '',
    label: label,
    type: '',
    step: step,
    category: '',
    action: '',
  })
}

export const handleUserRegistrationGuestTracking = () => {
  trackingGaEventNew('sign_up_guest', {
    searchTerm: '',
    position: '',
    label: 'acquisto_senza_registrazione',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleFindStoreCtaTracking = (path: string) => {
  trackingGaEventNew('find_store_cta', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleStoreLocatorSearchTracking = async (coords: number[], path: string) => {
  let searchTerm = ''
  const userGeoInfos = await reverseGeocode(coords)
  if ('error_code' in userGeoInfos === false) {
    searchTerm = `${userGeoInfos.features[0].properties.context?.region?.name}_${userGeoInfos.features[0].properties.context?.region?.alternate?.name}_${userGeoInfos.features[0].properties.context?.country?.name}`
  }

  trackingGaEventNew('store_locator_search', {
    searchTerm: searchTerm
      ? searchTerm.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_')
      : '',
    position: getCurrentPosition(path),
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleStoreLocatorSelectTracking = async (
  coords: number[],
  label: string,
  path: string
) => {
  let searchTerm = ''
  const userGeoInfos = await reverseGeocode(coords)
  if ('error_code' in userGeoInfos === false) {
    searchTerm = `${userGeoInfos.features[0].properties.context?.region?.name}_${userGeoInfos.features[0].properties.context?.region?.alternate?.name}_${userGeoInfos.features[0].properties.context?.country?.name}`
  }

  trackingGaEventNew('store_locator_select', {
    searchTerm: searchTerm
      ? searchTerm.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_')
      : '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleStoreLocatorDetailsTracking = async (
  coords: number[],
  label: string,
  type: 'phone' | 'mail',
  path: string
) => {
  let searchTerm = ''
  const userGeoInfos = await reverseGeocode(coords)
  if ('error_code' in userGeoInfos === false) {
    searchTerm = `${userGeoInfos.features[0].properties.context?.region?.name}_${userGeoInfos.features[0].properties.context?.region?.alternate?.name}_${userGeoInfos.features[0].properties.context?.country?.name}`
  }

  trackingGaEventNew('store_locator_details', {
    searchTerm: searchTerm.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_'),
    position: getCurrentPosition(path),
    label: label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_'),
    type: type,
    step: '',
    category: '',
    action: '',
  })
}

export const handleContattaRivenditoreTracking = (label: string) => {
  trackingGaEventNew('contatta_rivenditore', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleContattaciTracking = (status: string, type: string) => {
  trackingGaEventNew('contact_us', {
    searchTerm: '',
    position: '',
    label: '',
    type: type ? type.toLowerCase().replaceAll(' ', '_') : '',
    step: status,
    category: '',
    action: '',
  })
}

export const handleDeleteAccount = () => {
  trackingGaEventNew('delete_account', {
    searchTerm: '',
    position: '',
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleFaqTracking = (label: string, type: string) => {
  trackingGaEventNew('faq', {
    searchTerm: '',
    position: '',
    label: label ? label?.toLowerCase()?.replaceAll('-', '_')?.replaceAll(' ', '_') : '',
    type: type ? type?.toLowerCase()?.replaceAll('-', '_')?.replaceAll(' ', '_') : '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleGaranziaProdottoTracking = (
  label: string,
  step: 'start' | 'completed',
  action: 'cerca_certificato' | 'registra_prodotto'
) => {
  trackingGaEventNew('garanzia_prodotto', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: step,
    category: '',
    action: action,
  })
}

export const handleSelectVideoTracking = (label: string = '', path: string) => {
  trackingGaEventNew('select_video', {
    searchTerm: '',
    position: getCurrentPosition(path),
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handleUserLogoutTracking = () => {
  trackingGaEventNew('logout', {
    searchTerm: '',
    position: '',
    label: '',
    type: '',
    step: '',
    category: '',
    action: '',
  })
}

export const handlePopupPromoTracking = (label: string, action: 'close' | 'scopri_la_promo') => {
  trackingGaEventNew('pop_up_promo', {
    searchTerm: '',
    position: '',
    label: label ? label.toLowerCase().replaceAll('-', '_').replaceAll(' ', '_') : '',
    type: '',
    step: '',
    category: '',
    action,
  })
}
