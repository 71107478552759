import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  ListingVideoTutorialNode,
  PaginaNode,
  useListingVideoTutorialLazyQuery,
} from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import VideoTutorialListingTemplate from '@components/templates/VideoTutorialListingTemplate/VideoTutorialListingTemplate'

interface BlogListingProps {
  pagina: PaginaNode
  listing: ListingVideoTutorialNode
  buttonUrl?: boolean
}

const VideoTutorialListing = ({ pagina, listing, buttonUrl }: BlogListingProps) => {
  const router = useRouter()
  const [page, setPage] = useState(listing.paginator.currentPage)
  const [videos, setVideos] = useState(listing.videos)
  const [listingVideoTutorialQuery, { loading }] = useListingVideoTutorialLazyQuery({
    fetchPolicy: 'cache-first',
  })

  const nextPageLength = listing?.paginator?.itemsPerPage
  const categoria = listing?.categoria
  const categorie = listing?.categorie
  const t = useTrans()

  const powerBarItems = [
    {
      pk: 0,
      name: t('Tutti i video'),
      selected: !categoria,
      url: listing?.root?.url || '/informazioni-utili/video-tutorial/',
    },
  ].concat(
    categorie?.map((x) => ({
      pk: x.pk,
      name: x.nome,
      selected: categoria?.slug === x.slug,
      url: x.url,
    })) ?? []
  )

  const fetchListing = async () => {
    const { data } = await listingVideoTutorialQuery({
      variables: {
        slug: categoria?.slug,
        params: { p: listing.paginator.currentPage + 1 },
      },
    })
    if (data?.listingVideoTutorial?.videos) {
      listing.paginator = data?.listingVideoTutorial?.paginator
      setVideos([...videos, ...data.listingVideoTutorial.videos])
    }
  }

  useEffect(() => {
    if (page === listing.paginator.currentPage) return
    fetchListing()
  }, [page])

  const handleMore = (event) => {
    event.preventDefault()
    router.push(`${router.asPath.split('?')[0]}?page=${page + 1}`, undefined, { shallow: true })
    setPage(page + 1)
  }

  const linkNextPage = listing?.paginator?.pages[listing?.paginator?.currentPage]?.href

  return (
    <VideoTutorialListingTemplate
      key={listing?.categoria?.pk}
      pagina={pagina}
      videos={videos}
      handleMore={handleMore}
      loading={loading}
      nextPageLength={nextPageLength}
      linkNextPage={linkNextPage}
      powerBarItems={powerBarItems}
      buttonUrl={buttonUrl}
    />
  )
}

export default VideoTutorialListing
