import React from 'react'
import { MenuItemType } from '@utils/types'
import { WContainer, Button, EditableTitle, EditableDescription } from '@components/atoms'
import { IconTextCard } from '@components/molecules'
import styles from './IconTextSection.module.sass'
import { getIcon } from '@utils/icons'

interface Props {
  item: MenuItemType
  theme?: 'light' | 'secondarydark' | 'primary' | 'neutrallight'
  editMode?: boolean
  className?: string
}

const IconTextSection = (props: Props) => {
  const { item = undefined, theme = 'light', editMode = false, className = '' } = props

  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        item?.spaceTop ? styles[`root--st-${item.spaceTop}`] : ''
      } ${item?.spaceBottom ? styles[`root--sb-${item.spaceBottom}`] : ''} 
      ${item.borderTop ? styles[`root--bt`] : ''} 
      ${item.borderBottom ? styles[`root--bb`] : ''} 
      ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      <WContainer>
        <div className={`${styles.layout}`}>
          {(!!editMode ||
            !!item.name ||
            !!item.description ||
            !!item.children?.[0]?.children?.filter((x) => !!x.url && !!x.ctaLabel)?.length) && (
            <div className={styles.main}>
              <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item.description}
                className={styles.description}
                editMode={editMode}
              />
              {(!!item.children?.[0]?.children || editMode) &&
                (editMode ? (
                  <>{item?.children[0]?.children}</>
                ) : (
                  !!item.children[0]?.children?.filter((x) => !!x.url && !!x.ctaLabel)?.length && (
                    <div className={styles.ctaBox}>
                      {item.children?.[0].children
                        .filter((x) => !!x.url && !!x.ctaLabel)
                        ?.map((x) => (
                          <Button
                            key={x.pk}
                            label={x.ctaLabel}
                            href={x.url}
                            newPage={x.blank}
                            iconLeft={
                              x.iconLeft
                                ? x.iconLeft
                                : !!x.icon && x.iconPos === 'left'
                                ? getIcon(x.icon)
                                : undefined
                            }
                            iconRight={
                              x.iconRight
                                ? x.iconRight
                                : !!x.icon && x.iconPos === 'right'
                                ? getIcon(x.icon)
                                : undefined
                            }
                            variant={x.style as 'primary' | 'secondary' | 'ghost'}
                            color={
                              (['secondary', 'ghost'].includes(x.style) &&
                                ['secondarydark', 'black'].includes(theme)) ||
                              theme === 'primary'
                                ? 'white'
                                : ''
                            }
                            theme={
                              ['secondarydark', 'primary', 'black'].includes(theme)
                                ? 'dark'
                                : 'light'
                            }
                            size={'lg'}
                            mobileSize={'md'}
                          />
                        ))}
                    </div>
                  )
                ))}
            </div>
          )}
          {editMode ? (
            <>{item.children?.[1]?.children}</>
          ) : (
            !!item.children?.[1]?.children?.length && (
              <div
                className={`${styles.list} ${
                  styles[`list--n${item.children?.[1]?.children?.length}`]
                }`}
              >
                {item.children?.[1]?.children?.map((x) => (
                  <IconTextCard item={x} key={x.pk} className={styles.item} />
                ))}
              </div>
            )
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default IconTextSection
