import React from 'react'
import { MenuItemType } from '@utils/types'
import { WContainer, Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './NumbersSection.module.sass'
import { NumbersCard } from '@components/molecules'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const NumbersSection = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item?.children?.length || editMode ? (
    <div
      className={`${styles.root} 
      ${item.borderTop ? styles[`root--bt`] : ''} 
      ${item.borderBottom ? styles[`root--bb`] : ''} 
      ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      <WContainer>
        {editMode ? (
          <>{item.children}</>
        ) : (
          <div className={`${styles.list}`}>
            {item.children?.map((x) => (
              <NumbersCard item={x} key={x.pk} className={styles.item} />
            ))}
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default NumbersSection
