import React, { useState } from 'react'
import { Alert, EyeLine, EyeOffLine } from '@components/icons'
import { FormError } from 'components/atoms'
import styles from './FormPassword.module.sass'

interface Props {
  id: string
  type?: 'text' | 'number' | 'email'
  placeholder?: string
  label?: string
  icon?: React.ReactNode // Per Search Glossario
  name?: string
  value?: string | number
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  size?: 'md' | 'lg'
  status?: '' | 'success' | 'error'
  helper?: string
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormPassword = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    label = undefined,
    icon = undefined,
    name = '',
    value = '',
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    size = 'md',
    status = '',
    helper = undefined,
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const classNames = `
    ${styles.root} 
    ${size ? styles[`root--${size}`] : ''}
    ${!!icon ? styles[`root--icon`] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        <input
          id={id}
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error' || undefined}
        />
        <button
          type="button"
          className={styles.toggle}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeLine /> : <EyeOffLine />}
        </button>
      </div>
      {!!helper && <div className={styles.helper}>{helper}</div>}
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormPassword
