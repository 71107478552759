import React, { useEffect, useState } from 'react'
import useUtente from '@hooks/useUtente'
import useTrans from '@hooks/useTrans'
import { Plus } from '@components/icons'
import { Button, FormError, Modal } from '@components/atoms'
import { MyAccountIndirizzoCard } from '@components/molecules'
import styles from './MyAccountIndirizziHub.module.sass'
import {
  useMyAccountDeleteIndirizzoMutation,
  useMyAccountSetIndirizzoDefaultMutation,
} from '@gql/graphql'

interface Props {
  className?: string
}

const MyAccountIndirizziHub = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const { utente, refetchUtente } = useUtente()
  const indirizziSpedizione = utente?.indirizziSpedizione || []
  const indirizziFatturazione = utente?.indirizziFatturazione || []
  const [globalError, setGlobalError] = useState(undefined)
  const [defaultIndirizzoPk, setDefaultIndirizzoPk] = useState(undefined)
  const [confirmDeleteAddress, setConfirmDeleteAddress] = useState(undefined)

  useEffect(() => {
    setConfirmDeleteAddress(undefined)
    setDefaultIndirizzoPk(undefined)
  }, [utente.indirizziFatturazione, utente.indirizziSpedizione])

  const raiseGlobalError = (message) => {
    setGlobalError(message)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [myAccountSetIndirizzoDefault] = useMyAccountSetIndirizzoDefaultMutation({
    onCompleted: (res) => {
      if (!res.myAccountSetIndirizzoDefault?.status) {
        setDefaultIndirizzoPk(undefined)
        raiseGlobalError(
          res?.myAccountSetIndirizzoDefault?.message || t('Errore durante il salvataggio')
        )
      } else refetchUtente()
    },
    onError: () => {
      // Errore non previsto - generico
      setDefaultIndirizzoPk(undefined)
      raiseGlobalError(t('Errore durante il salvataggio'))
    },
  })

  const [myAccountDeleteIndirizzo, { loading: deleteLoading }] =
    useMyAccountDeleteIndirizzoMutation({
      onCompleted: (res) => {
        setConfirmDeleteAddress(undefined)
        if (!res.myAccountDeleteIndirizzo?.status) {
          raiseGlobalError(
            res?.myAccountDeleteIndirizzo?.message || t('Errore durante il salvataggio')
          )
        } else refetchUtente()
      },
      onError: () => {
        // Errore non previsto - generico
        setConfirmDeleteAddress(undefined)
        raiseGlobalError(t('Errore durante il salvataggio'))
      },
    })

  const onDefault = (indirizzoPk) => {
    setDefaultIndirizzoPk(indirizzoPk)
    myAccountSetIndirizzoDefault({
      variables: {
        pk: indirizzoPk,
      },
    })
  }

  const onDelete = () => {
    myAccountDeleteIndirizzo({
      variables: {
        pk: confirmDeleteAddress.pk,
      },
    })
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.bloccoIndirizzi}>
        <h2 className={styles.title}>{t('Indirizzi di consegna')}</h2>
        <FormError
          id="myaccount__global_error"
          message={globalError}
          visible={!!globalError}
          variant="global"
          className={styles.globalError}
        />
        {indirizziSpedizione.length > 0 &&
          indirizziSpedizione.map((x) => (
            <MyAccountIndirizzoCard
              key={`indirizzo_consegna_${x.pk}`}
              indirizzo={x}
              defaultDisabled={!!defaultIndirizzoPk && defaultIndirizzoPk !== x.pk}
              defaultLoading={defaultIndirizzoPk === x.pk}
              onDefault={() => onDefault(x.pk)}
              onDelete={() => setConfirmDeleteAddress(x)}
            />
          ))}
        <Button
          label={t('Nuovo indirizzo di consegna')}
          variant="ghost"
          iconLeft={<Plus />}
          href="/myaccount/addresses/new/?tipo=spedizione"
        />
      </div>

      <div className={styles.bloccoIndirizzi}>
        <h2 className={styles.title}>{t('Indirizzi di fatturazione')}</h2>
        {indirizziFatturazione.length > 0 &&
          indirizziFatturazione.map((x) => (
            <MyAccountIndirizzoCard
              key={`indirizzo_fatturazione_${x.pk}`}
              indirizzo={x}
              defaultDisabled={!!defaultIndirizzoPk && defaultIndirizzoPk !== x.pk}
              defaultLoading={defaultIndirizzoPk === x.pk}
              onDefault={() => onDefault(x.pk)}
              onDelete={() => setConfirmDeleteAddress(x)}
            />
          ))}
        <Button
          label={t('Nuovo indirizzo di fatturazione')}
          variant="ghost"
          iconLeft={<Plus />}
          href="/myaccount/addresses/new/?tipo=fatturazione"
        />
      </div>
      <Modal
        id={`myaccount_indirizzi_hub_confirm_delete`}
        open={!!confirmDeleteAddress}
        onClose={() => setConfirmDeleteAddress(undefined)}
        title={t('Sei sicuro di voler cancellare questo indirizzo?')}
        subtitle={
          confirmDeleteAddress
            ? `<strong>${confirmDeleteAddress.nome} ${confirmDeleteAddress.cognome}</strong><br />
          ${confirmDeleteAddress.indirizzo}${
                confirmDeleteAddress.indirizzo &&
                (confirmDeleteAddress.citta ||
                  confirmDeleteAddress.cap ||
                  confirmDeleteAddress.provincia)
                  ? ', '
                  : ''
              }
          ${confirmDeleteAddress.citta} ${confirmDeleteAddress.cap}
          ${confirmDeleteAddress.provincia ? `(${confirmDeleteAddress.provincia})` : ''}
          ${
            (confirmDeleteAddress.indirizzo ||
              confirmDeleteAddress.citta ||
              confirmDeleteAddress.cap ||
              confirmDeleteAddress.provincia) &&
            !!confirmDeleteAddress.telefono
              ? ' - '
              : ''
          }
          ${confirmDeleteAddress.telefono}
          `
            : ''
        }
        className={styles.confirmDeleteModal}
      >
        <div className={styles.confirmDeleteModalCtaBox}>
          <Button
            label={t('Annulla')}
            variant="secondary"
            onClick={() => setConfirmDeleteAddress(undefined)}
            size="lg"
          />
          <Button label={t('Conferma')} onClick={onDelete} loading={deleteLoading} size="lg" />
        </div>
      </Modal>
    </div>
  )
}

export default MyAccountIndirizziHub
