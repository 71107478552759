import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function List({ idVariant = '', className }: Props) {
  return (
    <IconBase name="list" w={24} className={className}>
      <path
        d="M8 4H21V6H8V4ZM3 3.5H6V6.5H3V3.5ZM3 10.5H6V13.5H3V10.5ZM3 17.5H6V20.5H3V17.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default List
