import React, { useEffect, useState } from 'react'
import { CategoriaNode, ProdottoNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { Button, WContainer } from '@components/atoms'
import { ProdottoCard } from '@components/molecules'
import { PowerBar } from '@components/organisms'
import styles from './ProdottiPromozioneSection.module.sass'
import { MenuItemType } from '@utils/types'
import useProdotti from '@hooks/useProdotti'

interface ListingProps {
  prodotti: ProdottoNode[]
}

const ProdottiListingPromozioneSection = (props: ListingProps) => {
  const { prodotti: items = [] } = props

  const { prodotti } = useProdotti(items)

  return prodotti.map((prodotto) => <ProdottoCard prodotto={prodotto} className={styles.item} />)
}

interface Props {
  item: any
  categorie?: CategoriaNode[]
  className?: string
}

const ProdottiPromozioneSection = (props: Props) => {
  const { item = undefined, categorie = [], className = '' } = props

  const t = useTrans()

  const barItems: (MenuItemType & { selected?: boolean })[] =
    categorie?.map((x) => ({ pk: x.pk, name: x.nome })) || []

  const [selected, setSelected] = useState(barItems[0])

  // limite di prodotti visualizzati per ciascuna tab
  const [limits, setLimits] = useState(
    categorie?.reduce((res, x) => ({ ...res, [x.pk]: 8 }), {}) || {}
  )

  useEffect(() => {
    if (!selected && barItems?.length > 0) setSelected(barItems[0])
  }, [barItems])

  return categorie.length > 0 ? (
    // L'id serve per le ancore interne nelle landing
    <div id="prodotti" className={`${styles.root} ${className}`}>
      <div className={`${styles.layout}`}>
        {(!!item.name || !!item.description) && (
          <WContainer>
            <div className={styles.main}>
              {!!item.name && <h2 className={styles.title}>{item.name}</h2>}
              {!!item.description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </div>
          </WContainer>
        )}
        <PowerBar
          items={barItems.map((x) => ({ ...x, selected: selected?.pk === x.pk }))}
          onItemClick={(item) => setSelected(item)}
          theme="neutrallight"
        />
        <WContainer>
          <div className={`${styles.list}`}>
            {categorie.map(
              (x) =>
                x.prodottiLanding?.length && (
                  <div
                    key={x.pk}
                    className={`${styles.tab} ${
                      x.pk === selected?.pk ? styles['tab--visible'] : ''
                    }`}
                  >
                    <div className={styles.tabList}>
                      <ProdottiListingPromozioneSection
                        prodotti={x.prodottiLanding.slice(0, limits[x.pk])}
                        key={limits[x.pk]}
                      />
                    </div>
                    {x.prodottiLanding.length > limits[x.pk] && (
                      <div className={styles.tabCtaBox}>
                        <Button
                          label={t(
                            x.prodottiLanding.length - limits[x.pk] === 1
                              ? 'Mostra {0} altro prodotto'
                              : 'Mostra altri {0} prodotti',
                            [
                              (x.prodottiLanding.length - limits[x.pk] > 8
                                ? 8
                                : x.prodottiLanding.length - limits[x.pk]
                              ).toString(),
                            ]
                          )}
                          variant="secondary"
                          onClick={() => setLimits((prev) => ({ ...prev, [x.pk]: prev[x.pk] + 8 }))}
                        />
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </WContainer>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProdottiPromozioneSection
