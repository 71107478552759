import { apiAxiosInstance } from 'react-pagebuilder/services/api'

export const AddGblinkPluginDialog = (CKEDITOR) => {
  CKEDITOR.dialog.add('gbfilelinkDialog', function (editor) {
    return {
      title: 'Link interno',
      minWidth: 400,
      minHeight: 200,
      contents: [
        {
          id: 'tab-basic',
          label: 'Basic settings',
          elements: [
            {
              type: 'text',
              id: 'anchor_text',
              label: 'Anchor Text',
              validate: CKEDITOR.dialog.validate.notEmpty('Anchor Text field cannot be empty.'),
            },
            {
              type: 'select',
              id: 'file_id',
              label: 'Link to',
              validate: CKEDITOR.dialog.validate.notEmpty('Link to field cannot be empty.'),
              items: [],
              onLoad: function () {
                var localthis = this
                localthis.add('Loading...', '-')
                apiAxiosInstance()
                  .get('filer_files/')
                  .then((res) => {
                    const data = res.data
                    localthis.remove('-')
                    localthis.add('< Choose >', '-')
                    for (let i in data) {
                      localthis.add(data[i].original_filename, data[i].id)
                    }
                    window.gbfilelink_linkable_objects_loaded = true
                  })
              },
            },
          ],
        },
      ],
      onShow: function () {
        var dialog = this
        var node = editor.getSelection().getStartElement().$
        dialog.setValueOf('tab-basic', 'anchor_text', editor.getSelection().getSelectedText())
        if (node.getAttribute('data-gb-fileid'))
          gbfilelink_wait_set_link(dialog, node.getAttribute('data-gb-fileid'))
      },
      onOk: function () {
        var dialog = this
        var gbfilelink = editor.document.createElement('a')

        gbfilelink.setText(dialog.getValueOf('tab-basic', 'anchor_text'))

        gbfilelink.setAttribute('class', 'gbfilelink')
        gbfilelink.setAttribute('href', '#')
        gbfilelink.setAttribute('data-gb-fileid', dialog.getValueOf('tab-basic', 'file_id'))

        editor.insertElement(gbfilelink)
      },
    }
  })
}

const gbfilelink_wait_set_link = (dialog, value) => {
  setTimeout(function () {
    if (!window.gbfilelink_linkable_objects_loaded) gbfilelink_wait_set_link(dialog, value)
    else dialog.setValueOf('tab-basic', 'file_id', value)
  }, 500)
}
