import React from 'react'
import styles from './FormFieldset.module.sass'

interface Props {
  title?: string
  children?: React.ReactNode
  className?: string
}

const FormFieldset = (props: Props) => {
  const { title = undefined, children = undefined, className = '' } = props

  return (
    <div className={`${styles.root} ${className}`}>
      {!!title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default FormFieldset
