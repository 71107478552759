import React, { useContext, useEffect, useState } from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { RicercaProdottoCard as Original } from 'components/molecules'
import {
  RicercaProdottoCardSettings,
  RicercaProdottoCardFloatingSettings,
  prodottoRetrieve,
} from './RicercaProdottoCardSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { mockProdottoLinkedObject } from '@utils/mock'
import { useRouter } from 'next/router'

export const defaultProps = {
  prodotto: null,
  prodottoId: null,
}

export const dynamicProps = []

export const RicercaProdottoCard: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const router = useRouter()

    const { prodottoId } = blockProps

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    const [prodotto, setProdotto] = useState(blockProps.prodotto || null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
      if (enabled) {
        prodottoRetrieve(prodottoId, router.locale, (res) => setProdotto(res))
      }
    }, [enabled, prodottoId])

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-ricerca-prodotto-card ${
          loading
            ? 'block-ricerca-prodotto-card--loading'
            : error
            ? 'block-ricerca-prodotto-card--error'
            : prodotto
            ? ''
            : 'block-ricerca-prodotto-card--empty'
        } ${props.className}`}
      >
        {prodotto && !loading && !error ? (
          <Original prodotto={prodotto} editMode={enabled} key={prodotto?.pk} />
        ) : (
          <></>
        )}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

RicercaProdottoCard.craft = withCraft({
  name: 'RicercaProdottoCard',
  defaultProps: defaultProps,
  settings: RicercaProdottoCardSettings,
  floatingSettings: RicercaProdottoCardFloatingSettings,
})
