import React from 'react'
import { PlusPointNode } from '@gql/graphql'
import { WImage } from '@components/atoms'
import styles from './PDPInfoCard.module.sass'

interface Props {
  plusPoint?: PlusPointNode
  className?: string
}

const PDPInfoCard = (props: Props) => {
  const { plusPoint, className = '' } = props

  return plusPoint ? (
    <article className={`${styles.root} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={plusPoint.immagine}
          maxWidth={229}
          maxHeight={180}
          objectFit="cover"
          alt={plusPoint.titolo}
        />
      </div>
      <div className={styles.main}>
        <h4 className={styles.title}>{plusPoint.titolo}</h4>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: plusPoint.descrizione }}
        />
      </div>
    </article>
  ) : (
    <></>
  )
}

export default PDPInfoCard
