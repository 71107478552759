import React, { useContext, useEffect, useState } from 'react'
import { RigaCarrelloNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import useProdotti from '@hooks/useProdotti'
import { CartContext } from '@utils/cartContext'
import { prezziProdotto, priceNotation } from '@utils/safe'
import { Bin, FavoriteMarked, FavoriteUnmarked } from '@components/icons'
import {
  Button,
  ButtonIcon,
  Dropdown,
  FormCounter,
  Tag,
  WImage,
  WishlistModal,
  WLink,
} from '@components/atoms'
import styles from './RigaCarrelloCard.module.sass'
import useWishlist from '@hooks/useWishlist'
import { trackingEcommerceEvent } from '@utils/gtm'
import {
  handleAddToCartTracking,
  handleRemoveFromCartTracking,
  handleWishListTracking,
} from '@utils/tracking'

interface Props {
  rigaCarrello?: RigaCarrelloNode
  className?: string
}

const RigaCarrelloCard = (props: Props) => {
  const { rigaCarrello = undefined, className = '' } = props

  const t = useTrans()
  const pp = prezziProdotto(rigaCarrello.prodotto.prodotto)
  const prezzi = [
    { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
    { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
    {
      key: 'current',
      label: rigaCarrello.prodotto.prodotto.promo
        ? t('Promo')
        : rigaCarrello.prodotto.prodotto.lancio
        ? t('Prezzo lancio')
        : '',
      value: pp?.prezzoFinale,
    },
  ].filter((x) => !!x.value)
  const { addToCart, removeFromCart, changeItemSize, cart } = useContext(CartContext)
  const { prodotti, loading: loadingProdottoExtraFields } = useProdotti([
    rigaCarrello.prodotto.prodotto,
  ])
  const taglieOpts = prodotti[0]?.prodottiTendina?.map((x) => ({
    label: x.labelTendina,
    value: x.pk,
  }))
  const tagliaProdotto = rigaCarrello.prodotto.prodotto.pk

  const { enabled, marked, toggleWishlist } = useWishlist(rigaCarrello.prodotto.prodotto)
  const [wishlistModalOpen, setWishlistModalOpen] = useState(false)

  const handleRemoveItem = () => {
    removeFromCart(rigaCarrello.prodotto.prodotto.pk)
    handleRemoveFromCartTracking(rigaCarrello.prodotto.prodotto, cart)
  }

  const handleChangeQuantita = (quantita) => {
    addToCart(rigaCarrello.prodotto.prodotto.pk, quantita - rigaCarrello.quantita)

    if (quantita === 0) {
      handleRemoveFromCartTracking(rigaCarrello.prodotto.prodotto, cart)
      return
    }

    if (quantita > rigaCarrello.quantita) {
      handleAddToCartTracking(rigaCarrello.prodotto.prodotto, cart)
    } else {
      handleRemoveFromCartTracking(rigaCarrello.prodotto.prodotto, cart)
    }
  }

  const handleChangeSize = (pkSize) => {
    changeItemSize(rigaCarrello.prodotto.prodotto.pk, pkSize, rigaCarrello.omaggio)
  }

  return rigaCarrello ? (
    <div className={`${styles.root} ${className}`}>
      <WLink href={rigaCarrello.prodotto.prodotto.url} className={styles.imageBox}>
        <WImage
          src={rigaCarrello.prodotto.prodotto.previewImage}
          maxWidth={64}
          maxHeight={64}
          objectFit="contain"
        />
      </WLink>
      <div className={styles.main}>
        <div className={styles.content}>
          {rigaCarrello.omaggio && (
            <Tag label={t('In omaggio')} variant="promo" className={styles.tag} />
          )}
          {rigaCarrello.prodotto.prodotto.categoria && (
            <span className={styles.category}>
              {rigaCarrello.prodotto.prodotto.categoria.boxTitolo}
            </span>
          )}
          <WLink href={rigaCarrello.prodotto.prodotto.url} className={styles.title}>
            {rigaCarrello.prodotto.prodotto.nome}
          </WLink>
          {!!rigaCarrello.omaggio ? (
            <>
              <div className={styles.attribute}>
                <span className={styles.attributeLabel}>{t('Quantita')}: </span>
                <span className={styles.attributeValue}>{rigaCarrello.quantita}</span>
              </div>
              {(prodotti[0].showTaglia || prodotti[0].showFormato) && (
                <div className={styles.attribute}>
                  <span className={styles.attributeLabel}>
                    {prodotti[0].showTaglia ? t('Taglia: ') : t('Formato: ')}
                  </span>
                  <span className={styles.attributeValue}>
                    {taglieOpts.filter((x) => x.value === tagliaProdotto)?.[0]?.label}
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              {prodotti[0]?.prodottiTendina && prodotti[0]?.prodottiTendina.length > 0 && (
                <div className={styles.attribute}>
                  <span className={styles.attributeLabel}>
                    {`${
                      prodotti[0].showTaglia
                        ? t('Taglia')
                        : prodotti[0].showFormato
                        ? t('Formato')
                        : t('Tipologia')
                    } `}
                  </span>
                  <span className={styles.attributeValue}>
                    {taglieOpts
                      .filter((x) => x.value === tagliaProdotto)?.[0]
                      ?.label.replace(/<\/?[^>]+(>|$)/g, '')}
                  </span>
                </div>
              )}
              <div className={styles.details}>
                <FormCounter
                  id={`riga_carrello_card_${rigaCarrello.pk}_quantita`}
                  value={rigaCarrello.quantita}
                  min={0}
                  readOnly
                  onChange={(e) => handleChangeQuantita(e.target.value)}
                  className={styles.quantityCounter}
                />
                <div className={`${styles.actions} ${styles['actions--desktop']}`}>
                  <Button
                    label={t('Rimuovi')}
                    iconRight={<Bin />}
                    hideDesktopIcon
                    variant="ghost"
                    size="sm"
                    onClick={() => handleRemoveItem()}
                  />
                  <Button
                    label={t(marked ? 'Rimuovi dai preferiti' : 'Aggiungi ai preferiti')}
                    iconRight={marked ? <FavoriteMarked /> : <FavoriteUnmarked />}
                    variant="ghost"
                    size="sm"
                    onClick={enabled ? toggleWishlist : () => setWishlistModalOpen(true)}
                    className={styles.wishlistToggle}
                  />
                </div>
                <div className={`${styles.actions} ${styles['actions--mobile']}`}>
                  <ButtonIcon
                    icon={<Bin />}
                    color="transparent"
                    size="md"
                    onClick={() => handleRemoveItem()}
                  />
                  <ButtonIcon
                    icon={marked ? <FavoriteMarked /> : <FavoriteUnmarked />}
                    color="transparent"
                    size="md"
                    onClick={enabled ? toggleWishlist : () => setWishlistModalOpen(true)}
                    className={styles.wishlistToggle}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {((!!prezzi?.length && !rigaCarrello.omaggio) || cart.promoApplicata?.promoDipendenti) && (
          <div className={styles.pricesBox}>
            {!!prezzi?.length && !rigaCarrello.omaggio && (
              <div className={styles.prices}>
                {prezzi.map((p) => (
                  <div className={`${styles.price} ${styles[`price--${p.key}`]}`} key={p.key}>
                    {!!p.label && <span className={styles.priceLabel}>{p.label}: </span>}
                    <span className={styles.priceValue}>
                      {priceNotation(p.value, false, prodotti[0].valuta?.simbolo)}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {cart.promoApplicata?.promoDipendenti && (
              <Tag label={cart.promoApplicata.titolo} size="sm" variant="black" />
            )}
          </div>
        )}
      </div>
      {!!wishlistModalOpen && (
        <WishlistModal
          id={`riga_carrello_card_wishlist_modal_${rigaCarrello.pk}`}
          open={wishlistModalOpen}
          onClose={() => setWishlistModalOpen(false)}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

export default RigaCarrelloCard
