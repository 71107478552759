import React from 'react'
import { FormError } from '@components/atoms'
import styles from './FormOptions.module.sass'

export interface OptionProps {
  id?: string
  label?: string
  description?: string
  name?: string
  value?: any
  checked?: boolean
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props {
  id: string
  name?: string
  value?: any
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  options: OptionProps[]
}

const FormOptionsOption = (props: OptionProps) => {
  const {
    id = '',
    label = '',
    description = undefined,
    name = '',
    value = '',
    checked = false,
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    onChange = () => {},
  } = props

  const classNames = `
    ${styles.option}
    ${checked ? styles['option--checked'] : ''}
    ${disabled ? styles['option--disabled'] : ''}
    ${className}`

  return (
    <label htmlFor={id} className={classNames}>
      <input
        id={id}
        checked={checked}
        name={name}
        value={value}
        className={styles.optionInput}
        type="radio"
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        onChange={onChange}
      />
      <span className={styles.optionStyledInput} />
      <div className={styles.optionMain}>
        {!!label && <h3 className={styles.optionLabel}>{label}</h3>}
        {!!description && (
          <div
            className={styles.optionDescription}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </label>
  )
}

const FormOptions = (props: Props) => {
  const {
    id = '',
    name = '',
    value = null,
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    options = [],
  } = props

  const onOptionChange = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly) onChange(e)
  }

  const classNames = `
    ${styles.root} 
    ${disabled ? styles['root--disabled'] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <div className={styles.options}>
        {options?.map((option) => (
          <FormOptionsOption
            id={`${id}-${option.value}`}
            name={name}
            label={option.label}
            description={option.description}
            value={option.value}
            checked={option.value === value}
            onChange={onOptionChange}
            readOnly={readOnly || option.readOnly}
            required={required || option.required}
            disabled={disabled || option.disabled}
            key={`formoptionsoption-${id}-${option.value}`}
          />
        ))}
      </div>
      <FormError
        id={`${id}_error`}
        message={errorMessage}
        visible={status === 'error'}
        className={styles.error}
      />
    </div>
  )
}

export default FormOptions
