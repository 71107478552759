import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../../../../services/globalContext'
import { addNodeTree } from '../../../../utils/node'
import { canDropBlock } from '../../../../utils/rules'
import { API_HOST } from '@utils/index'
import { ADMIN_PORT, PROTOCOL_SCHEMA } from '@utils/settings'
import { useRouter } from 'next/router'

export const Asset = () => {
  const { assetId, setAssetId } = useContext(GlobalContext)

  const router = useRouter()

  const handler = async (event) => {
    let data = event.data
    if (data.source === 'filer-wrapper') {
      data = data.data
      const id = data.id
      const name = data?.name

      setAssetId(id)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handler)

    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  /* useEffect(async () => {
        if(assetId) {
            if(selectedNodeId) {
                const response = await axios.get("http://localhost:9999/d/filebrowser_filer/url_image/" + assetId +"/")
                const data = response.data
                const url = "http://localhost:9999" + data.url

                const elements =  (
                    <Image block={{ src: url }} />
                )
                const nodeTree = query.parseReactElement(elements).toNodeTree()
                const currentNode = nodeTree['nodes'][nodeTree['rootNodeId']]

                const targetNode = query.node(selectedNodeId).get()
                if (canDropBlock(currentNode, targetNode)) {
                    addNodeTree(nodeTree, selectedNodeId, actions)
                    setAssetId(null)
                }
            }
            else {
                const response = await axios.get("http://localhost:9999/d/filebrowser_filer/url_image/" + assetId +"/")
                const data = response.data
                const url = "http://localhost:9999" + data.url

                const elements =  (
                    <Image block={{ src: url }} />
                )
                const nodeTree = query.parseReactElement(elements).toNodeTree()
                addNodeTree(nodeTree, ROOT_NODE, actions)
                setAssetId(null)
            }
            
        }
  }, [selectedNodeId, assetId]) */

  return (
    <div className={'asset'}>
      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        src={`${PROTOCOL_SCHEMA}://${window.location.hostname}${
          ADMIN_PORT ? `:${ADMIN_PORT}` : ''
        }/d/filer-wrapper/${router?.locale ? `?lang=${router.locale}` : ''}`}
      />
    </div>
  )
}
