import React from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import {
  Button,
  FormCheckbox,
  FormField,
  FormikField,
  FormLayout,
  FormText,
} from '@components/atoms'
import styles from './CheckoutFormDatiPersonali.module.sass'
import { registrazioneValidation } from '@utils/validationSchemas'
import { useRouter } from 'next/router'

interface Props {
  onCompleted?: (v: any) => void
  className?: string
}

const CheckoutFormDatiPersonali = (props: Props) => {
  const { onCompleted = undefined, className = '' } = props

  const t = useTrans()
  const router = useRouter()

  const tipoUtenteOpts = [
    { label: t('Privato'), value: 'privato' },
    { label: t('Azienda'), value: 'azienda' },
  ]

  const initialValues = {
    email: '',
    tipoUtente: 'privato',
    nome: '',
    cognome: '',
    cf: '',
    ragioneSociale: '',
    piva: '',
    sdi: '',
    noSdi: false,
    pec: '',
    privacy: false,
    marketing: false,
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={registrazioneValidation(t, false)}
        onSubmit={(values, { setSubmitting }) => {
          if (onCompleted) {
            onCompleted(values).then(() => setSubmitting(false))
          }
        }}
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <FormLayout>
              <FormikField
                formik={formikProps}
                id={'checkout_email'}
                name="email"
                type="email"
                label={t('Indirizzo email')}
                placeholder={t('Inserisci il tuo indirizzo email')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'checkout_tipo_utente'}
                name="tipoUtente"
                type="radio"
                variant="horizontal"
                options={tipoUtenteOpts}
                required
                layout="full"
              />
              <FormikField
                formik={formikProps}
                id={'checkout_nome'}
                name="nome"
                type="text"
                label={t('Nome')}
                placeholder={t('Inserisci il tuo nome')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'checkout_cognome'}
                name="cognome"
                type="text"
                label={t('Cognome')}
                placeholder={t('Inserisci il tuo cognome')}
                required
              />
              <FormikField
                formik={formikProps}
                id={'checkout_cf'}
                name="cf"
                type="text"
                label={t('Codice fiscale')}
                placeholder={t('Inserisci il tuo codice fiscale')}
                required
              />
              {formikProps.values.tipoUtente === 'azienda' && (
                <>
                  <FormikField
                    formik={formikProps}
                    id={'checkout_ragione_sociale'}
                    name="ragioneSociale"
                    type="text"
                    label={t('Ragione sociale')}
                    placeholder={t('Inserisci la tua ragione sociale')}
                    required
                  />
                  <FormField
                    id={'checkout_sdi'}
                    label={t('SDI')}
                    required={!formikProps.values.noSdi}
                    childrenLayout="column-sm"
                    className={styles.sdiField}
                  >
                    <FormText
                      id={'checkout_sdi'}
                      name="sdi"
                      type="text"
                      placeholder={t('Inserisci il tuo codice SDI')}
                      required={!formikProps.values.noSdi}
                      disabled={formikProps.values.noSdi}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      status={
                        !formikProps.values.noSdi && formikProps.touched?.['sdi']
                          ? formikProps.errors?.['sdi']
                            ? 'error'
                            : 'success'
                          : ''
                      }
                      value={formikProps.values.sdi}
                      errorMessage={formikProps.errors?.['sdi']}
                    />
                    <FormCheckbox
                      id={'checkout_no_sdi'}
                      label={t('Non ho il codice SDI')}
                      name="noSdi"
                      value="1"
                      checked={formikProps.values.noSdi}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                    />
                  </FormField>
                  <FormikField
                    formik={formikProps}
                    id={'checkout_piva'}
                    name="piva"
                    type="text"
                    label={t('Partita IVA')}
                    placeholder={t('Inserisci il tuo numero di partita IVA')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'checkout_pec'}
                    name="pec"
                    type="email"
                    label={t('PEC')}
                    placeholder={t('Inserisci la tua PEC')}
                    required={formikProps.values.noSdi}
                  />
                </>
              )}
            </FormLayout>
            <div className={styles.requirements}>* {t('Campi obbligatori')}</div>
            <div
              className={styles.privacy}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Ti invitiamo a visionare <a href='/privacy/informativa-per-marketing-e-profilazione/'>l'informativa privacy art. 13 GDPR</a> sul trattamento dei dati"
                ),
              }}
            />
            <FormLayout>
              <FormikField
                formik={formikProps}
                id={'checkout_privacy'}
                name="privacy"
                type="checkbox"
                label={t('Letta e compresa l’informativa*')}
                checkboxLabel={t(
                  'Esprimo il consenso al trattamento dei miei dati per ricevere supporto, risposte ai miei quesiti e per le eventuali attività connesse di cui al punto a) dell’informativa.'
                )}
                fieldVariant="box"
                layout="full"
              />
              <FormikField
                formik={formikProps}
                id={'checkout_marketing'}
                name="marketing"
                type="checkbox"
                label={t('Consenso per il ‘Marketing diretto’ (facoltativo)')}
                checkboxLabel={t(
                  'Esprimo il consenso per le finalità di cui al punto b) dell’informativa e quindi acconsento al ricevimento di comunicazioni pubblicitarie tramite e-mail, telefono, sms e similari. Resta inteso che potrò revocare in qualunque momento il presente consenso.'
                )}
                fieldVariant="box"
                layout="full"
              />
            </FormLayout>
            <div className={styles.ctaBox}>
              <Button
                type="submit"
                label={t('Procedi')}
                size="lg"
                loading={formikProps.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CheckoutFormDatiPersonali
