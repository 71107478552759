import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import VideoToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/VideoToolbarSection'

export const VideoTextSectionSettings = (settingsProps) => {
  const { props } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }))

  const themeOpts = [
    { label: 'Light', value: 'light' },
    { label: 'Neutral', value: 'neutrallight' },
    { label: 'Secondary dark', value: 'secondarydark' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          options={themeOpts}
          label="Theme"
          defaultValue={defaultProps['theme']}
        />
      </ToolbarSection>

      {!settingsProps.floating && (
        <>
          <VideoToolbarSection props={props} defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const VideoTextSectionFloatingSettings = () =>
  React.createElement(VideoTextSectionSettings, { floating: true })
