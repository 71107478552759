import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import useUtente from '@hooks/useUtente'
import { Button } from '@components/atoms'
import { CheckoutFormIndirizzoBlock } from '@components/molecules'
import styles from './CheckoutFormIndirizzo.module.sass'
import { getAddressValidationSchema } from '@utils/validationSchemas'

interface Props {
  variant?: 'spedizione' | 'fatturazione'
  onCompleted?: (v: any) => void
  className?: string
  onlyFatturazione?: boolean
}

const CheckoutFormIndirizzo = (props: Props) => {
  const {
    variant = 'spedizione',
    onCompleted = undefined,
    className = '',
    onlyFatturazione = false,
  } = props

  const t = useTrans()
  const { utente } = useUtente()
  const [addAddressOpen, setAddAddressOpen] = useState({
    spedizione: false,
    fatturazione: false,
  })

  const indirizzoSpedizioneDefault = utente?.indirizziSpedizione.filter(
    (indirizzo) => indirizzo.isDefault
  )[0]

  const indirizzoFatturazioneDefault = utente?.indirizziFatturazione.filter(
    (indirizzo) => indirizzo.isDefault
  )[0]

  const initialValues = {
    indirizzoFatturazioneId: `${
      indirizzoFatturazioneDefault
        ? indirizzoFatturazioneDefault.pk
        : utente?.indirizziFatturazione?.[0]?.pk
    }`,
    nomeFatturazione: '',
    cognomeFatturazione: '',
    indirizzoFatturazione: '',
    informazioniAggiuntiveFatturazione: '',
    cittaFatturazione: '',
    statoFatturazione: '',
    capFatturazione: '',
    telefonoFatturazione: '',
    indirizzoSpedizioneId: `${
      indirizzoSpedizioneDefault
        ? indirizzoSpedizioneDefault.pk
        : utente?.indirizziSpedizione?.[0]?.pk
    }`,
    nomeSpedizione: '',
    cognomeSpedizione: '',
    indirizzoSpedizione: '',
    informazioniAggiuntiveSpedizione: '',
    cittaSpedizione: '',
    statoSpedizione: '',
    capSpedizione: '',
    telefonoSpedizione: '',
    stessoIndirizzo: true,
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        // validationSchema={getAddressValidationSchema(t, variant, addAddressOpen)}
        onSubmit={(values, { setSubmitting }) => {
          values['onlyFatturazione'] = onlyFatturazione

          if (onCompleted) {
            onCompleted(values).then(() => setSubmitting(false))
          }
        }}
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <CheckoutFormIndirizzoBlock
              title={variant === 'spedizione' ? 'Indirizzo di spedizione' : undefined}
              variant={variant}
              formikProps={formikProps}
              addAddressOpen={addAddressOpen[variant]}
              setAddAddressOpen={(v) => setAddAddressOpen((prev) => ({ ...prev, [variant]: v }))}
            />
            {variant === 'spedizione' && !formikProps.values['stessoIndirizzo'] && (
              // sempre fatturazione - quando c'è la spedizione ma con indirizzo di fatturazione diverso
              <CheckoutFormIndirizzoBlock
                title={t('Indirizzo di fatturazione')}
                variant={'fatturazione'}
                formikProps={formikProps}
                addAddressOpen={addAddressOpen['fatturazione']}
                setAddAddressOpen={(v) =>
                  setAddAddressOpen((prev) => ({ ...prev, fatturazione: v }))
                }
                className={styles.indirizzoBlock2}
              />
            )}
            {!addAddressOpen.spedizione && !addAddressOpen.fatturazione && (
              <div className={styles.ctaBox}>
                <Button
                  type="submit"
                  label={t('Procedi')}
                  size="lg"
                  loading={formikProps.isSubmitting}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CheckoutFormIndirizzo
