import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, Tag } from '@components/atoms'
import styles from './EditorialeCard.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  item: MenuItemType
  variant?: 'neutrallight' | 'secondarydark'
  orientation?: 'vertical' | 'horizontal'
  className?: string
}

const EditorialeCard = (props: Props) => {
  const {
    item = undefined,
    variant = 'neutrallight',
    orientation = 'vertical',
    className = '',
  } = props

  const t = useTrans()

  const helperLabel = item?.helper || t(`editorialecard_${item?.pk}_helper`)

  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${variant}`]} ${
        styles[`root--${orientation}`]
      } ${className}`}
    >
      <div className={styles.main}>
        {(!!item.name || !!item.description) && (
          <div className={styles.content}>
            {!!item.intro && (
              <Tag
                label={item.intro}
                variant={variant === 'secondarydark' ? 'promo' : 'black'}
                helper={
                  helperLabel
                    ? {
                        id: `editorialecard_${item.pk}_helper`,
                        label: helperLabel,
                      }
                    : null
                }
              />
            )}
            {!!item.name && <p className={styles.title}>{item.name}</p>}
            {!!item.description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </div>
        )}
        {!!item.url && !!item.ctaLabel && (
          <div className={styles.ctaBox}>
            <Button
              label={item.ctaLabel}
              href={item.url}
              newPage={item.blank}
              theme={variant === 'secondarydark' ? 'dark' : undefined}
              color={variant === 'secondarydark' ? 'white' : undefined}
            />
          </div>
        )}
      </div>
      <div className={`${styles.imageBox}`}>
        {!!item.image && (
          <WImage
            src={item.image}
            maxWidth={350}
            maxHeight={280}
            objectFit="cover"
            alt={item.alt}
            title={item.alt}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default EditorialeCard
