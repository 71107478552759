import React, { useEffect, useState } from 'react'
import styles from './SearchModal.module.sass'
import { EditorialeCard, RicercaBanner, RicercaProdottoCard } from '@components/molecules'
import useFindMenu from '@hooks/useFindMenu'
import Chip from '@components/atoms/Chip/Chip'
import { Search, WContainer } from '@components/atoms'
import { Close } from '@components/icons'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import { handleSearchClickTracking } from '@utils/tracking'

interface Props {
  open?: boolean
  onClose?: () => void
  className?: string
}

const SearchModal = (props: Props) => {
  const { open = false, onClose = undefined, className = '' } = props
  const router = useRouter()
  const searchMenuItems = useFindMenu('search')
  const searchFrequenti = searchMenuItems?.children?.find(
    (child) => child.key === 'search-frequenti'
  )
  const menuItemBanner = useFindMenu('search-banner')
  const menuItemEditoriale = useFindMenu('search-editoriale')
  const [searchResults, setSearchResults] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [products, setProducts] = useState([])
  const t = useTrans()

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        if (onClose) onClose()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  useEffect(() => {
    setSuggestions(searchResults?.autocomplete)
    setProducts(searchResults?.results)
  }, [searchResults])

  return open ? (
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.root} ${open ? styles['root--open'] : ''} ${className}`}>
        <WContainer>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{t('Cosa stai cercando?')}</h2>
            <button
              type="button"
              aria-label={t('Chiudi')}
              className={styles.close}
              onClick={onClose}
            >
              <Close />
            </button>
          </div>
          <div className={styles.layout}>
            <div className={styles.leftLayout}>
              <Search setSearchResults={setSearchResults} className={styles.search} />
              {!!(searchFrequenti?.children?.length || suggestions?.length) && (
                <h3 className={styles.subTitle}>
                  {suggestions?.length > 0 ? t('Ricerche suggerite') : t('Ricerche frequenti')}
                </h3>
              )}
              {suggestions?.length > 0 ? (
                <div className={styles.suggestions}>
                  {suggestions.map((suggestion) => {
                    return (
                      <a
                        href={`/${router.locale}/search/?q=${suggestion?.data?.autocomplete}`}
                        onClick={() =>
                          handleSearchClickTracking('', suggestion?.data?.autocomplete)
                        }
                        dangerouslySetInnerHTML={{ __html: suggestion?.highlight }}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  <div className={styles.frequentiList}>
                    {searchFrequenti?.children?.map((frequente) => {
                      return (
                        <Chip
                          label={frequente.name}
                          href={`/${router.locale}/search?q=${frequente.name}`}
                        />
                      )
                    })}
                  </div>
                  <RicercaBanner item={menuItemBanner} />
                </>
              )}
            </div>
            <div className={styles.rightLayout}>
              {products?.length > 0 ? (
                products.map((product) => {
                  return (
                    <div className={styles.productList}>
                      <RicercaProdottoCard
                        prodotto={{
                          pk: parseInt(product?.django_id),
                          nome: product?.titolo,
                          url: product?.url,
                          previewImage: product?.image,
                          categoria: {
                            alias: product?.categoria,
                            boxTitolo: product?.categoria,
                          },
                          utilizzo: product?.utilizzo_alias,
                          sottotitolo: product?.sottotitolo,
                          disponibile: product?.disponibile,
                        }}
                      />
                    </div>
                  )
                })
              ) : (
                <EditorialeCard
                  item={menuItemEditoriale}
                  orientation={'horizontal'}
                  className={styles.editorialCard}
                />
              )}
            </div>
          </div>
        </WContainer>
      </div>
    </>
  ) : (
    <></>
  )
}

export default SearchModal
