import React, { useEffect } from 'react'
import { integer } from '@opensearch-project/opensearch/api/types'
import { ParentFiltroCategoriaNode } from '@gql/graphql-get'
import useTrans from '@hooks/useTrans'
import { priceNotation } from '@utils/safe'
import { Close } from '@components/icons'
import { Button, WModal } from '@components/atoms'
import { Filtro, FiltroRadio, FiltroSlider } from '@components/molecules'
import styles from './FiltersDrawer.module.sass'
import { isEcommerce } from '@utils/index'
import { useRouter } from 'next/router'

interface Props {
  attributi?: ParentFiltroCategoriaNode[]
  appliedFilters?: { name: string; id: integer }[]
  onAttributoFilterChange?: (filtro: any, type: string) => void
  orderOpts?: { label?: string; value?: string }[]
  order?: string
  onOrderChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  priceRange?: number[]
  priceRangeValues?: number[]
  onPriceRangeChange?: (v: number[]) => void
  open?: boolean
  setOpen?: (v: boolean) => void
  className?: string
  onFilterReset?: () => void
}

const FiltersDrawer = (props: Props) => {
  const {
    attributi = [],
    appliedFilters = undefined,
    onAttributoFilterChange = undefined,
    orderOpts = [],
    order = undefined,
    onOrderChange = undefined,
    priceRange = [],
    onPriceRangeChange = undefined,
    open = false,
    setOpen = undefined,
    className = '',
    onFilterReset = () => {},
  } = props

  const t = useTrans()

  const router = useRouter()
  const ecommerce = isEcommerce(router.locale)

  const onCancel = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    setOpen(false)
  }

  return (
    <WModal
      id={'filtersdrawer'}
      open={open}
      onClose={() => setOpen(false)}
      ariaLabelledby={`filtersdrawer_title`}
      className={`${styles.root} ${open ? styles['root--open'] : ''} ${className}`}
    >
      <div className={styles.head}>
        <h2 className={styles.title} id="filtersdrawer_title">
          {t('Filtra e ordina')}
        </h2>
        <button
          type="button"
          aria-label={t('Chiudi')}
          onClick={() => setOpen(false)}
          className={styles.close}
        >
          <Close />
        </button>
      </div>
      <div className={styles.body}>
        {!!orderOpts?.length && (
          <div className={styles.filtersBlock}>
            <FiltroRadio
              id={'filtersdrawer_ordinamenti'}
              label={t('Ordina per')}
              value={order}
              options={orderOpts.map((x) => ({ ...x, id: x.value }))}
              onChange={onOrderChange}
            />
          </div>
        )}
        {attributi
          .filter((a) => a.children?.length)
          .map((a) => (
            <div className={styles.filtersBlock} key={a.nome}>
              <Filtro
                id={`filtersdrawer_filtro_${a.nome}`}
                attributo={a}
                appliedFilters={appliedFilters}
                onChange={onAttributoFilterChange}
                isOpen
              />
            </div>
          ))}
        {ecommerce && (
          <div className={styles.filtersBlock}>
            <FiltroSlider
              id={'filtersdrawer_prezzo'}
              min={priceRange?.[0]}
              max={priceRange?.[1]}
              label={t('prezzo')}
              formatFunc={priceNotation}
              onChange={onPriceRangeChange}
            />
          </div>
        )}
        <div className={styles.resetBox}>
          <Button label={t('Elimina tutti i filtri')} variant="ghost" onClick={onFilterReset} />
        </div>
      </div>
      <div className={styles.footer}>
        <Button label={t('Annulla')} onClick={onCancel} variant="secondary" />
        <Button label={t('Applica')} onClick={onSubmit} />
      </div>
    </WModal>
  )
}

export default FiltersDrawer
