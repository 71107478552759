import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Exit({ idVariant = '', className }: Props) {
  return (
    <IconBase name="exit" w={20} className={className}>
      <path
        d="M3.33325 15H4.99992V16.6667H14.9999V3.33335H4.99992V5.00002H3.33325V2.50002C3.33325 2.03979 3.70635 1.66669 4.16659 1.66669H15.8333C16.2935 1.66669 16.6666 2.03979 16.6666 2.50002V17.5C16.6666 17.9603 16.2935 18.3334 15.8333 18.3334H4.16659C3.70635 18.3334 3.33325 17.9603 3.33325 17.5V15ZM4.99992 9.16669H10.8333V10.8334H4.99992V13.3334L0.833252 10L4.99992 6.66669V9.16669Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Exit
