import React from 'react'
import { PromozioneNode, RigaOrdineNode } from '@gql/graphql'
import styles from './RigaOrdineCard.module.sass'
import useTrans from '@hooks/useTrans'
import { Tag, WImage } from '@components/atoms'
import { prezziProdotto, priceNotation } from '@utils/safe'
import useProdotti from '@hooks/useProdotti'

interface Props {
  rigaOrdine?: Partial<RigaOrdineNode>
  className?: string
  variant?: 'fill' | 'transparent'
  promozioneApplicata?: PromozioneNode
}

const RigaOrdineCard = (props: Props) => {
  const {
    rigaOrdine = undefined,
    className = '',
    variant = 'fill',
    promozioneApplicata = undefined,
  } = props

  const t = useTrans()

  const { prodotti, loading: loadingProdottoExtraFields } = useProdotti([
    rigaOrdine?.prodotto?.prodotto,
  ])

  const pp = prezziProdotto(rigaOrdine?.prodotto?.prodotto)
  const prezzi = [
    { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
    {
      key: 'current',
      label: rigaOrdine?.prodotto?.prodotto?.promo
        ? t('Promo')
        : rigaOrdine?.prodotto?.prodotto?.lancio
        ? t('Prezzo lancio')
        : '',
      value: pp?.prezzoFinale,
    },
  ].filter((x) => !!x.value)

  const taglieOpts = prodotti[0]?.prodottiTendina?.map((x) => ({
    label: x.labelTendina,
    value: x.pk,
  }))
  const tagliaProdotto = rigaOrdine?.prodotto?.prodotto?.pk

  return rigaOrdine && rigaOrdine.prodotto && prodotti[0] ? (
    <div
      className={`${styles.root} ${
        variant === 'transparent' ? styles['root--transparent'] : ''
      } ${className}`}
    >
      <div className={styles.imageBox}>
        <WImage src={prodotti[0].previewImage} maxWidth={64} maxHeight={64} objectFit="contain" />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          {prodotti[0].categoria && (
            <span className={styles.category}>{prodotti[0].categoria.boxTitolo}</span>
          )}
          <h2 className={styles.title}>{prodotti[0].nome}</h2>
          <div className={styles.attributes}>
            <div className={styles.attribute}>
              <span className={styles.attributeLabel}>{t('Quantità')}: </span>
              <span className={styles.attributeValue}>{rigaOrdine.quantitaOrdinata}</span>
            </div>
            {(prodotti[0].showTaglia || prodotti[0].showFormato) && (
              <div className={styles.attribute}>
                <span className={styles.attributeLabel}>
                  {t(prodotti[0].showTaglia ? 'Taglia' : 'Formato')}:
                </span>
                <span className={styles.attributeValue}>
                  {taglieOpts.filter((x) => x.value === tagliaProdotto)?.[0]?.label}
                </span>
              </div>
            )}
          </div>
        </div>
        {rigaOrdine.omaggio ? (
          <Tag label={t('In omaggio')} variant="promo" className={styles.tag} />
        ) : (
          <div className={styles.pricesBox}>
            {!!prezzi?.length && (
              <div className={styles.prices}>
                {prezzi.map((p) => (
                  <div className={`${styles.price} ${styles[`price--${p.key}`]}`} key={p.key}>
                    {!!p.label && <span className={styles.priceLabel}>{p.label}: </span>}
                    <span className={styles.priceValue}>
                      {priceNotation(p.value, false, prodotti[0].valuta?.simbolo)}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {promozioneApplicata?.promoDipendenti && (
              <Tag label={promozioneApplicata.titolo} size="sm" variant="black" />
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RigaOrdineCard
