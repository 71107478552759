import React, { useState } from 'react'
import { FormError } from 'components/atoms'
import styles from './FormTextarea.module.sass'

interface Props {
  id: string
  placeholder?: string
  label?: string
  name?: string
  value?: string
  className?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  size?: 'md' | 'lg'
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const FormTextarea = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    label = '',
    name = '',
    value = '',
    className = '',
    required = false,
    disabled = false,
    readOnly = false,
    size = 'md',
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const [focus, setFocus] = useState(false)

  const classNames = `
    ${styles.root} 
    ${size ? styles[`root--${size}`] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  const onLocalFocus = (e) => {
    setFocus(true)
    if (onFocus) onFocus(e)
  }

  const onLocalBlur = (e) => {
    setFocus(false)
    if (onBlur) onBlur(e)
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        <textarea
          id={id}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onLocalBlur}
          onFocus={onLocalFocus}
          onChange={onChange}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error'}
        />
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormTextarea
