import React, { useCallback, useEffect, useRef, useState } from 'react'
import Map, { GeolocateControl, NavigationControl, ScaleControl } from 'react-map-gl'
import { useRouter } from 'next/router'
import { DistributoreNode } from '@gql/graphql-get'
import useTrans from '@hooks/useTrans'
import { checkValidCoords } from '@utils/index'
import {
  clusterCountLayer,
  clusterLayer,
  locationPointGetIconLayer,
  unclusteredPointGetIconLayer,
} from '@utils/mapbox'
import { ArrowRight, Search } from '@components/icons'
import { Button, ButtonIcon, CloseBar, FormField, FormSelect, WContainer } from '@components/atoms'
import { DistributoreCard } from '@components/molecules'
import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './StoreLocatorInternational.module.sass'

interface Props {
  distributori?: DistributoreNode[]
  className?: string
}

const StoreLocatorInternational = (props: Props) => {
  const { distributori = [], className = '' } = props

  const t = useTrans()
  const router = useRouter()

  const mapNavItems = [
    { key: 'map', label: t('Vedi mappa') },
    { key: 'list', label: t('Vedi lista') },
  ]

  const countriesPerContinent = distributori.reduce(
    (res, x) => ({
      ...res,
      [x.continente]: (res?.[x.continente] || []).concat(
        res[x.continente]?.includes(x.nazione) ? [] : [x.nazione]
      ),
    }),
    {}
  )

  const [mapNavItem, setMapNavItem] = useState('map')
  const [openResults, setOpenResults] = useState(false)

  const [selectedDistributore, setSelectedDistributore] = useState(null)
  const distributoriLimitStep = 4
  const [distributoriLimit, setDistributoriLimit] = useState(distributoriLimitStep) // nel mobile si visualizzano solo N elementi per volta
  const [distributoriJSON, setDistributoriJSON] = useState<(GeolocationPosition & any) | null>(null)
  const [distributoriList, setDistributoriList] = useState([])
  const [distributoriFiltered, setDistributoriFiltered] = useState([])
  const [resizeDone, setResizeDone] = useState(false)
  const [searchValues, setSearchValues] = useState({
    continent: undefined,
    country: undefined,
  })

  const [loading, setLoading] = useState(false)

  const useMapRef = () => {
    const [map, setMap] = useState(null)
    const ref = useCallback((node) => {
      if (node !== null) {
        setMap(node)
      }
    }, [])
    return [map, ref]
  }

  const [mapCurrent, mapRef] = useMapRef()
  const mapListBoxRef = useRef(null)

  const loadIcons = () =>
    new Promise<void>((resolve, reject) => {
      const icons = [
        {
          name: 'marker',
          href: '/markers/marker.png',
        },
        {
          name: 'markerSelected',
          href: '/markers/marker-selected.png',
        },
      ]
      for (let i = 0; i < icons.length; i++) {
        const element = icons[i]
        const name = element.name
        const href = `/${process.env.SITE}${element.href}`
        if (!mapCurrent.hasImage(name)) {
          mapCurrent.loadImage(href, (error, image) => {
            if (error) {
              reject(error)
            } else {
              if (!mapCurrent.hasImage(name)) {
                mapCurrent.addImage(name, image)
              }
              resolve()
            }
          })
        }
      }
      resolve()
    })

  useEffect(() => {
    setDistributoriJSON({
      type: 'FeatureCollection',
      features: distributoriFiltered.map((distributore) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            typeof distributore.longitudine !== 'number'
              ? distributore.longitudine.replace(',', '.')
              : distributore.longitudine,
            typeof distributore.latitudine !== 'number'
              ? distributore.latitudine.replace(',', '.')
              : distributore.latitudine,
          ],
        },
        properties: {
          icon:
            distributore.pk && distributore.pk === selectedDistributore?.pk
              ? `markerSelected`
              : `marker`,
          distributoreJSON: JSON.stringify({ ...distributore }),
        },
      })),
    })
  }, [distributoriFiltered, selectedDistributore])

  useEffect(() => {
    if (mapListBoxRef.current && selectedDistributore && openResults) {
      const cardId = `distributoreCard-storeLocator-${selectedDistributore.pk}`
      const cardElement = document.getElementById(cardId)
      if (cardElement) {
        const container = mapListBoxRef.current
        const cardPosition = cardElement.offsetTop - container.offsetTop

        container.scrollTo({
          top: cardPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [selectedDistributore, openResults, distributoriList])

  const loadSource = (type = 'rivenditori') => {
    if (type == 'rivenditori') {
      new Promise<void>((resolve, reject) => {
        if (!mapCurrent.getMap().getSource('rivenditori')) {
          mapCurrent.getMap().addSource('rivenditori', {
            type: 'geojson',
            data: distributoriJSON,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 60,
          })
        } else mapCurrent.getMap().getSource('rivenditori').setData(distributoriJSON)
        resolve()
      })
    }
  }

  const loadMapResources = async () => {
    await loadIcons()

    await loadSource('locationPin')
    await loadSource('rivenditori')
    const map = mapCurrent.getMap()

    if (!map.getLayer(clusterLayer.id)) {
      map.addLayer(clusterLayer)
    }

    if (!map.getLayer(locationPointGetIconLayer.id)) {
      map.addLayer(locationPointGetIconLayer, clusterLayer.id)
    }

    if (!map.getLayer(clusterCountLayer.id)) {
      map.addLayer(clusterCountLayer)
    }

    if (!map.getLayer(unclusteredPointGetIconLayer.id)) {
      map.addLayer(unclusteredPointGetIconLayer)
    }
  }

  useEffect(() => {
    setLoading(true)
    mapCurrent?.resize()
    mapCurrent?.once('moveend', () => {
      setResizeDone(true)
      setLoading(false)
    })
  }, [openResults])

  useEffect(() => {
    const map = mapCurrent?.getMap()
    if (map) {
      map.on('load', () => {
        map?.getStyle()?.layers.forEach(function (thisLayer) {
          if (thisLayer.id.indexOf('-label') > 0) {
            map?.setLayoutProperty(thisLayer.id, 'text-field', ['get', 'name_en'])
          }
        })
      })
    }
  }, [mapCurrent])

  useEffect(() => {
    if (mapCurrent && mapCurrent.getMap().getSource('rivenditori')) loadSource('rivenditori')
    if (mapCurrent && mapCurrent.getMap().getSource('locationPin')) loadSource('locationPin')
  }, [distributoriJSON, mapCurrent])

  useEffect(() => {
    if (mapCurrent) {
      if (distributoriFiltered.length > 0) {
        const minLng = Math.min(
          ...distributoriFiltered.map((x) => parseFloat(x.longitudine.replace(',', '.')))
        )
        const minLat = Math.min(
          ...distributoriFiltered.map((x) => parseFloat(x.latitudine.replace(',', '.')))
        )
        const maxLng = Math.max(
          ...distributoriFiltered.map((x) => parseFloat(x.longitudine.replace(',', '.')))
        )
        const maxLat = Math.max(
          ...distributoriFiltered.map((x) => parseFloat(x.latitudine.replace(',', '.')))
        )

        const bbox = [
          [minLng, minLat],
          [maxLng, maxLat],
        ]

        mapCurrent.fitBounds(bbox, {
          padding: 100,
          maxZoom: 8,
        })
      }
    }
  }, [distributoriFiltered, mapCurrent])

  const handleClick = (e) => {
    const features = e.features[0]
    if (features) {
      const clusterName = features.layer?.id
      const clusterId = features.properties?.cluster_id
      if (clusterName === 'clusters' || clusterName === 'cluster-count') {
        mapCurrent.getSource('rivenditori').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err || !zoom) return
        })
      } else if (clusterName === 'unclustered-point') {
        const selectedDistributoreJson = features.properties?.distributoreJSON
          ? JSON.parse(features.properties.distributoreJSON)
          : null
        setSelectedDistributore(selectedDistributoreJson)
        setOpenResults(true)
      }
    } else {
      setSelectedDistributore(null)
    }
  }

  const handleDistributoriList = () => {
    const map = mapCurrent?.getMap()
    const distributoriInView = distributoriFiltered.filter((x) =>
      map
        .getBounds()
        .contains([
          parseFloat(x.longitudine?.replace(',', '.')),
          parseFloat(x.latitudine?.replace(',', '.')),
        ])
    )
    setDistributoriList(distributoriInView)
  }

  const handleMapMove = () => {
    handleDistributoriList()
    setSelectedDistributore(null)
  }

  const handleMapZoom = () => {
    handleDistributoriList()
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    if (searchValues.country) {
      if (document.activeElement instanceof HTMLInputElement) document.activeElement.blur()
      setDistributoriFiltered(
        distributori
          .filter((x) => x.nazione === searchValues.country)
          .filter((x) => checkValidCoords(x.latitudine, x.longitudine))
      )
      setOpenResults(true)
    }
  }

  return (
    <div
      className={`${styles.root} ${openResults ? styles['root--openResults'] : ''} ${className}`}
    >
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.head}>
            <h2 className={styles.title}>{t('Find a Branch or a Distributor')}</h2>
            <form className={styles.form} onSubmit={handleSearch}>
              <FormField
                id="storelocatorinternational_continent"
                label={t('Continent')}
                className={styles.formField}
              >
                <FormSelect
                  id="storelocatorinternational_continent"
                  name={'continent'}
                  options={Object.keys(countriesPerContinent)
                    .map((x) => ({ label: x, value: x }))
                    .sort((a, b) => (a.label < b.label ? -1 : 1))}
                  value={searchValues.continent}
                  onChange={(e) =>
                    setSearchValues({ continent: e.target.value, country: undefined })
                  }
                />
              </FormField>
              {!!searchValues.continent && (
                <>
                  <FormField
                    id="storelocatorinternational_country"
                    label={t('Country')}
                    className={styles.formField}
                  >
                    <FormSelect
                      id="storelocatorinternational_country"
                      name={'country'}
                      options={(countriesPerContinent[searchValues.continent] || [])
                        .map((x) => ({ label: x, value: x }))
                        .sort((a, b) => (a.label < b.label ? -1 : 1))}
                      value={searchValues.country}
                      onChange={(e) =>
                        setSearchValues((prev) => ({ ...prev, country: e.target.value }))
                      }
                    />
                  </FormField>
                  <ButtonIcon
                    type="submit"
                    ariaLabel={t('Search')}
                    icon={<Search />}
                    color="primary"
                    size="lg"
                    disabled={!searchValues.country}
                    className={styles.submitDesktop}
                  />
                  <Button
                    type="submit"
                    label={t('Search')}
                    iconRight={<Search />}
                    disabled={!searchValues.country}
                    className={styles.submitMobile}
                  />
                </>
              )}
            </form>
          </div>
          <div className={styles.mapLayout}>
            <div className={`${styles.mapNav} ${!openResults ? styles['mapNav--hidden'] : ''}`}>
              {mapNavItems.map((x) => (
                <button
                  type="button"
                  disabled={x.key === mapNavItem}
                  onClick={() => setMapNavItem(x.key)}
                >
                  {x.label}
                </button>
              ))}
            </div>
            <div
              className={`${styles.mapListBox} ${styles.mapNavItem} ${
                mapNavItem !== 'list' ? styles['mapNavItem--hidden'] : ''
              }`}
              ref={mapListBoxRef}
            >
              {distributoriList?.length ? (
                <>
                  <div className={styles.mapList}>
                    {distributoriList.map((x, index) => (
                      <DistributoreCard
                        distributore={x}
                        selected={selectedDistributore?.pk === x.pk}
                        onClick={() => setSelectedDistributore(x)}
                        className={`${styles.mapItem} ${
                          index + 1 > distributoriLimit ? styles['mapItem--hidden'] : ''
                        }`}
                      />
                    ))}
                  </div>
                  {distributoriLimit < distributoriList.length && (
                    <div className={styles.mapListMoreBox}>
                      <Button
                        label={t('Carica altri {0} distributori', [
                          Math.min(
                            distributoriList.length - distributoriLimit,
                            distributoriLimitStep
                          ).toString(),
                        ])}
                        variant="secondary"
                        onClick={() =>
                          setDistributoriLimit(distributoriLimit + distributoriLimitStep)
                        }
                        className={styles.mapListMore}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.mapEmptyList}>
                  {loading ? t('Caricamento...') : t('Nessun distributore in questa zona')}
                </div>
              )}
            </div>
            <div
              id="map"
              className={`${styles.map} ${styles.mapNavItem} ${
                mapNavItem !== 'map' ? styles['mapNavItem--hidden'] : ''
              }`}
            >
              <Map
                ref={mapRef}
                mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
                scrollZoom
                mapStyle="mapbox://styles/mapbox/streets-v11"
                interactiveLayerIds={['clusters', 'cluster-count', 'unclustered-point']}
                onLoad={() => loadMapResources()}
                onClick={(e) => handleClick(e)}
                onMoveEnd={() => (resizeDone ? handleMapMove() : () => {})}
                onZoomEnd={() => handleMapZoom()}
                initialViewState={{ latitude: 0.1, longitude: 0.1, zoom: 0 }}
              >
                <GeolocateControl position="bottom-right" />
                <NavigationControl position="bottom-right" />
                <ScaleControl />
              </Map>
            </div>
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default StoreLocatorInternational
