import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import LayoutToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const TextSectionSettings = () => {
  const sizeOpts = [
    { label: 'Medium', value: 'md' },
    { label: 'Small', value: 'sm' },
  ]

  const alignOpts = [
    { label: 'Center', value: 'center' },
    { label: 'Left', value: 'left' },
  ]

  const themeOpts = [
    { label: 'Light', value: 'light' },
    { label: 'Neutral light', value: 'neutrallight' },
    { label: 'Neutral light 2', value: 'neutrallight2' },
    { label: 'Dark Secondary', value: 'secondarydark' },
    { label: 'Black', value: 'black' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          options={themeOpts}
          label="Tema"
          defaultValue={defaultProps['theme']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          options={sizeOpts}
          label="Dimensione"
          defaultValue={defaultProps['size']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="align"
          type="select"
          options={alignOpts}
          label="Allineamento"
          defaultValue={defaultProps['align']}
        />
      </ToolbarSection>
      <LayoutToolbarSection defaultProps={defaultProps} initOpen />
    </>
  )
}
