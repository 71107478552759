import React from 'react'
import styles from './IssuuEmbed.module.sass'

interface Props {
  issuuId?: string
  editMode?: boolean
  className?: string
}

const IssuuEmbed = (props: Props) => {
  const { issuuId = undefined, editMode = false, className = '' } = props

  return issuuId || editMode ? (
    <div
      className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${
        issuuId && editMode ? '' : styles['root--empty']
      } ${className}`}
    >
      {issuuId && (
        <iframe
          allow="fullscreen"
          allowFullScreen
          src={`//e.issuu.com/embed.html?d=${issuuId}&hideIssuuLogo=true&showOtherPublicationsAsSuggestions=true&u=emakgroup`}
          className={styles.iframe}
        ></iframe>
      )}
    </div>
  ) : (
    <></>
  )
}

export default IssuuEmbed
