import React from 'react'
import styles from './CheckoutLoadingModal.module.sass'
import { LoaderBig } from '@components/icons'
import { WModal } from '@components/atoms'
import useTrans from '@hooks/useTrans'

interface Props {
  className?: string
}

const CheckoutLoadingModal = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const item = {
    name: t('Non chiudere questa pagina'),
    description: t('<p>Per favore, attendi la conferma di avvenuta transazione</p>'),
  }

  return (
    <WModal id="checkout_loading_modal" open={true} className={`${styles.root} ${className}`}>
      <div className={styles.main}>
        <span className={styles.icon}>
          <LoaderBig />
        </span>
        {!!item.name && <h1 className={styles.title}>{item.name}</h1>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>
    </WModal>
  )
}

export default CheckoutLoadingModal
