import React from 'react'

export const TabellaDifferenzeCardSettings = (settingsProps) => {
  return (
    <div className="empty-message">
      <p>No options available</p>
    </div>
  )
}

export const TabellaDifferenzeCardFloatingSettings = () =>
  React.createElement(TabellaDifferenzeCardSettings, { floating: true })
