import React, { useState, useEffect } from 'react'
import useWindowDimensions from 'react-pagebuilder/hooks/useWindowDimensions'
import { useUtente } from 'hooks'
import { useRouter } from 'next/router'
//import { useSelector } from 'react-redux'

export const GlobalContext = React.createContext()

const GlobalContextProvider = ({
  contentTypeId,
  objectId,
  pkey,
  editMode: manualEditMode,
  draft,
  children,
}) => {
  const [showImport, setShowImport] = useState(false)
  // useSelector((state) => state.toolbar.edit_mode)
  //const [utente, setUtente] = useState(false) // useSelector((state) => state.utente)
  const [previewMode, setPreviewMode] = useState(false)
  const [wireframeMode, setWireframeMode] = useState(true)
  const { width } = useWindowDimensions()
  const [windowMode, setWindowMode] = useState()
  const [sidebarTab, setSidebarTab] = useState()
  const [leftSidebarTab, setLeftSidebarTab] = useState()
  const [assetId, setAssetId] = useState()
  const [floatingSettingsOpen, setFloatingSettingsOpen] = useState()

  const { utente, loading } = useUtente()

  const [editMode, setEditMode] = useState(undefined)
  const router = useRouter()

  const { locale: lang } = { locale: router?.locale ? router.locale : 'it-it' }

  useEffect(() => {
    if (!loading) {
      if (utente && utente.isStaff && utente.showToolbar) {
        setEditMode(
          manualEditMode !== undefined && manualEditMode !== null ? manualEditMode : utente.edit
        )
      } else {
        setEditMode(false)
      }
    }
  }, [loading, utente, manualEditMode])

  useEffect(() => {
    if (width <= 575) {
      setWindowMode('mobile')
    } else if (width <= 991) {
      setWindowMode('tablet')
    } else {
      setWindowMode('desktop')
    }
  }, [width])

  return (
    <GlobalContext.Provider
      value={{
        contentTypeId,
        objectId,
        pkey,
        lang,
        showImport,
        setShowImport,
        editMode,
        previewMode,
        setPreviewMode,
        utente,
        windowMode,
        wireframeMode,
        setWireframeMode,
        draft,
        sidebarTab,
        setSidebarTab,
        leftSidebarTab,
        setLeftSidebarTab,
        assetId,
        setAssetId,
        floatingSettingsOpen,
        setFloatingSettingsOpen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
