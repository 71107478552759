/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useMeQuery } from 'gql/graphql'
import { PAGEBUILDER_ADMIN_ENABLE } from 'utils'
import { mockArray, mockIndirizzo } from '@utils/mock'

export default function useUtente() {
  if (process.env.STORYBOOK) {
    return {
      utente: {
        nome: 'Webranking',
        cognome: 'Admin',
        email: 'admin@webranking.it',
        isStaff: false,
        isImpersonate: false,
        stopImpersonateUrl: '',
        edit: false,
        showToolbar: false,
        indirizziSpedizione: mockArray(mockIndirizzo, 2),
        indirizziFatturazione: mockArray(mockIndirizzo, 2),
      },
      loading: false,
      refetchUtente: () => {},
    }
  }

  if (!PAGEBUILDER_ADMIN_ENABLE) {
    return {
      utente: null,
      loading: false,
      refetchUtente: () => {},
    }
  }

  const router = useRouter()
  const { loading, data, refetch } = useMeQuery()

  const dispatch = useDispatch()
  const { utente } = useSelector((state: any) => state?.utente)

  useEffect(() => {
    if (data && !loading) {
      dispatch({ type: 'SET_ME', payload: { data } })
    }
  }, [data])

  useEffect(() => {
    if (router.isReady) refetch()
  }, [router.asPath])

  return {
    utente: utente || (data?.me && { ...data?.me }),
    loading,
    refetchUtente: refetch,
  }
}
