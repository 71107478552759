import React from 'react'
import { MenuItemType } from '@utils/types'
import { getIcon } from '@utils/icons'
import { ArrowRight } from '@components/icons'
import { Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './IconTextCard.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const IconTextCard = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      {!!getIcon(item.icon) && <span className={`${styles.icon}`}>{getIcon(item.icon)}</span>}
      <EditableTitle title={item.name} variant="h3" className={styles.title} editMode={editMode} />
      <EditableDescription
        description={item.description}
        className={styles.description}
        editMode={editMode}
      />
      {!!item.url && !!item.ctaLabel && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel}
            href={item.url}
            newPage={item.blank}
            variant={item.variant ? item.variant : 'secondary'}
            iconRight={<ArrowRight />}
            size="lg"
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default IconTextCard
