import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ArrowLeft({ idVariant = '', className }: Props) {
  return (
    <IconBase name="arrow-left" w={24} className={className}>
      <path
        d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ArrowLeft
