import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Ricambi({ idVariant = '', className }: Props) {
  return (
    <IconBase name="ricambi" w={40} className={className}>
      <g clip-path={`url(#ricambi_${idVariant}_clip0_6147_8271)`}>
        <path
          d="M8.88766 26.4948C8.88766 26.4948 8.85016 26.476 8.83141 26.4573L6.21255 24.9447V31.6637L12.0315 35.0201V28.3074L8.88766 26.4948ZM9.11892 25.4197L14.9442 22.0571L12.5566 20.682L6.73132 24.0447L9.11892 25.4197ZM16.2255 21.5946L19.7694 23.6509C19.8819 23.7509 19.9444 23.8947 19.9506 24.0447V31.9763C19.9506 32.1825 19.8256 32.3638 19.6569 32.4513L12.8503 36.3827C12.6566 36.5077 12.4566 36.5077 12.2628 36.3827L5.41877 32.4263C5.25626 32.3263 5.16251 32.1513 5.16251 31.9763V24.0447C5.16251 23.8384 5.28751 23.6509 5.46877 23.5697L12.2941 19.6257C12.4691 19.532 12.6628 19.5382 12.8191 19.632L16.2255 21.5883V21.5946ZM15.988 22.6634L10.1627 26.026L12.5503 27.4011L18.3756 24.0384L15.988 22.6634ZM13.0878 28.3136V35.0326L18.9068 31.6762V24.9572L13.0878 28.3136Z"
          fill="#161616"
        />
        <path
          d="M6.16881 18.3194C7.06885 12.4192 11.6503 7.73775 17.5818 6.76896C18.2631 6.66271 18.4256 7.694 17.7443 7.80025C12.2816 8.68779 8.04389 13.013 7.2126 18.457L7.46886 18.1632C7.66262 17.9507 7.99389 17.9194 8.2064 18.1132C8.4189 18.3069 8.44391 18.6382 8.2564 18.8507L6.78134 20.5383C6.53132 20.8321 6.0438 20.782 5.88755 20.4008L5.03751 18.3007C4.93126 18.0257 5.05626 17.7257 5.33127 17.6194C5.59379 17.5132 5.9063 17.6382 6.01255 17.9132L6.16881 18.3194Z"
          fill="#161616"
        />
        <path
          d="M34.6638 20.9383C34.8388 21.1695 34.7888 21.5008 34.5575 21.6695C34.3262 21.8445 33.995 21.7945 33.8262 21.5633L33.5762 21.2195C33.1699 27.8135 28.126 33.22 21.5507 34.0576C20.8694 34.1451 20.7319 33.1013 21.4194 33.02C27.4322 32.2512 32.0574 27.3635 32.5199 21.357L32.3449 21.6945C32.2137 21.9508 31.9011 22.0508 31.6449 21.9258C31.3886 21.7945 31.2761 21.482 31.4136 21.2258L32.4324 19.2132C32.6074 18.8757 33.0824 18.8194 33.3199 19.1319L34.6638 20.9445V20.9383Z"
          fill="#161616"
        />
        <path
          d="M33.6762 8.04399L30.1261 5.98765L26.7322 4.01882C26.5759 3.93132 26.3759 3.91881 26.2009 4.01882L19.3819 7.95648C19.1943 8.04399 19.0756 8.21899 19.0756 8.43775V16.3693C19.0756 16.5506 19.1693 16.7256 19.3319 16.8194L26.1696 20.7695C26.3634 20.8945 26.5634 20.8945 26.7572 20.7695L33.5637 16.8381C33.7387 16.7506 33.8575 16.5756 33.8575 16.3631V8.43775C33.8512 8.28775 33.795 8.14399 33.6762 8.04399ZM26.4634 5.07511L28.851 6.45017L23.0258 9.81281L20.6382 8.43775L26.4634 5.07511ZM25.9446 19.4257L20.1194 16.0693V9.34404L22.7382 10.8566C22.7382 10.8566 22.7757 10.8879 22.7945 10.8941L25.9446 12.7004V19.4257ZM26.4634 11.8004L24.0758 10.4253L29.901 7.0627L32.2886 8.43775L26.4634 11.8004ZM32.8074 16.0756L26.9822 19.432V12.7067L32.8074 9.35029V16.0756Z"
          fill="#161616"
        />
        <path
          d="M22.7382 10.8566L20.1194 9.34406V16.0693L25.9446 19.4257V12.7004L22.7945 10.8941C22.7945 10.8941 22.757 10.8754 22.7382 10.8566Z"
          fill="currentColor"
        />
        <path
          d="M28.851 6.45019L26.4634 5.07513L20.6382 8.43778L23.0258 9.81283L28.851 6.45019Z"
          fill="currentColor"
        />
        <path
          d="M29.8948 7.06268L24.0758 10.4253L26.4634 11.8004L32.2824 8.43774L29.8948 7.06268Z"
          fill="currentColor"
        />
        <path
          d="M26.9822 19.432L32.8074 16.0756V9.35028L26.9822 12.7067V19.432Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={`ricambi_${idVariant}_clip0_6147_8271`}>
          <rect width="29.77" height="32.5201" fill="white" transform="translate(5 3.95007)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default Ricambi
