import React from 'react'
import useTrans from '@hooks/useTrans'
import { Plus, Minus } from '@components/icons'
import { FormError } from '@components/atoms'
import styles from './FormCounter.module.sass'

interface Props {
  id: string
  placeholder?: string
  name?: string
  value?: number
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  step?: number
  min?: number
  max?: number
  unitaMisura?: string
  unitaMisuraPos?: 'left' | 'right'
  size?: 'md' | 'lg'
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormCounter = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = 0,
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    step = undefined,
    min = undefined,
    max = undefined,
    size = 'md',
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const t = useTrans()

  const classNames = `
    ${styles.root} 
    ${size ? styles[`root--${size}`] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  const getFinalValue: (v: number) => number = (v) => {
    return min !== undefined && v < min ? min : max !== undefined && v > max ? max : v
  }

  const onControlClick = (sign = 1) => {
    const numericValue = value + (step || 1) * sign
    onChange({
      target: {
        name: name,
        value: getFinalValue(numericValue).toString(),
      },
    })
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        <input
          id={id}
          type={'number'}
          name={name}
          value={value}
          step={step}
          min={min}
          max={max}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error' || undefined}
        />
        <button
          type="button"
          className={`${styles.control} ${styles['control--minus']}`}
          aria-label={t('Diminuisci di {0}', [`${step || 1}`])}
          onClick={() => onControlClick(-1)}
        >
          <Minus />
        </button>
        <button
          type="button"
          className={`${styles.control} ${styles['control--plus']}`}
          aria-label={t('Aumenta di {0}', [`${step || 1}`])}
          onClick={() => onControlClick(1)}
        >
          <Plus />
        </button>
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormCounter
