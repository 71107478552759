import React, { useContext, useEffect } from 'react'
import useTrans from '@hooks/useTrans'
import { ProdottoNode } from '@gql/graphql'
import { isProdottoBatteria, prezziProdotto, priceNotation } from '@utils/safe'
import { Battery, CartError, Share } from '@components/icons'
import { Button, Dropdown, ShareButton, Tag, WishlistButton } from '@components/atoms'
import styles from './PDPDetail.module.sass'
import PLPOptionCard from '../PLPOptionCard/PLPOptionCard'
import { useRouter } from 'next/router'
import { isEcommerce, isFiliale, isInternational } from '@utils/index'
import { CartContext } from '@utils/cartContext'
import { handleAddToCartTracking, handleFindStoreCtaTracking } from '@utils/tracking'

interface Props {
  prodotto?: ProdottoNode
  className?: string
  loading?: boolean
  showCompatibili?: boolean
}

const PDPDetail = (props: Props) => {
  const { prodotto = undefined, className = '', loading = false, showCompatibili = false } = props

  const t = useTrans()
  const router = useRouter()
  const { cart, loading: cartLoading, addToCart: contextAddToCart } = useContext(CartContext)

  const ecommerce = isEcommerce(router.locale)
  const filiale = isFiliale(router.locale)
  const international = isInternational(router.locale)

  const batteria = isProdottoBatteria(prodotto)

  const pp = prezziProdotto(prodotto)
  const prezzi = [
    ...(ecommerce
      ? [
          { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
          { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
        ]
      : []),
    ...(filiale && pp?.prezzoListino && pp?.prezzoListino !== pp.prezzoFinale
      ? [{ key: 'listino', label: t('Listino'), value: pp?.prezzoListino }]
      : []),
    ...(filiale || ecommerce ? [{ key: 'current', value: pp.prezzoFinale }] : []),
  ].filter((x) => !!x.value)

  const variantiItems =
    prodotto?.prodottiTendina?.map((x) => ({
      pk: x.pk,
      name: x.labelTendina,
      url: x.url,
      selected: x.pk === prodotto.pk,
    })) || []

  const addToCart = () => {
    contextAddToCart(prodotto.pk, 1, true)

    handleAddToCartTracking(prodotto, cart, 'pdp')
  }

  return prodotto ? (
    <div
      className={`${styles.root} ${
        ecommerce ? styles['root--ecommerce'] : styles['root--noecommerce']
      } ${className}`}
    >
      <div className={styles.main}>
        <div className={styles.head}>
          {prodotto.categoria && (
            <h2 className={`${styles.category} ${batteria ? styles['category--battery'] : ''}`}>
              <span className={styles.categoryLabel}>{prodotto.categoria.boxTitolo}</span>
              {batteria && (
                <span className={styles.categoryIcon}>
                  <Battery />
                </span>
              )}
            </h2>
          )}
          {prodotto.utilizzo && (
            <Tag
              variant="categoria"
              label={prodotto.utilizzo.toUpperCase()}
              className={styles.utilizzoDesktop}
            />
          )}
        </div>
        <h1 className={styles.title}>{prodotto.nome}</h1>
        {prodotto.utilizzo && (
          <Tag
            variant="categoria"
            label={prodotto.utilizzo.toUpperCase()}
            className={styles.utilizzoMobile}
          />
        )}
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: prodotto.descrizioneBreve }}
        />
        {showCompatibili && (
          <Button
            label={t('Vedi prodotti compatibili')}
            variant="ghost"
            size="sm"
            href="#compatibili"
            className={styles.vediCompatibili}
          />
        )}
      </div>
      {variantiItems.length > 1 && (
        <div
          className={`${styles.variants} ${
            styles[
              `variants--${
                variantiItems.length > 3 || prodotto.showTaglia || prodotto.showFormato
                  ? 'dropdown'
                  : 'list'
              }`
            ]
          }`}
        >
          {variantiItems.length > 3 || prodotto.showTaglia || prodotto.showFormato ? (
            <div className={styles.variantsLayout}>
              <div className={styles.variantsBox}>
                <div className={styles.variantsTitle}>
                  {t(
                    prodotto.showTaglia ? 'Taglia' : prodotto.showFormato ? 'Formato' : 'Tipologia'
                  )}
                </div>
                <Dropdown
                  id={'pdpdetail_variants_dropdown'}
                  value={variantiItems.filter((x) => x.selected)?.[0]?.pk}
                  onChange={(v) => {
                    const url = variantiItems.filter((x) => x.pk === v)?.[0]?.url
                    if (url) router.push(url)
                  }}
                  options={variantiItems.map((x) => ({
                    label: x.name,
                    value: x.pk,
                  }))}
                  className={styles.variantsListDropdown}
                />
              </div>
              {prodotto.showGuidaTaglie && (
                <Button
                  label={t('Guida alle taglie')}
                  variant="ghost"
                  size="sm"
                  href="/servizio-clienti/guida-alle-taglie/"
                  className={`${styles.guidaTaglie} ${styles['guidaTaglie--desktop']}`}
                />
              )}
            </div>
          ) : (
            <>
              <dl className={styles.variantsTitle}>
                <dt className={styles.variantsTitleLabel}>{t('Tipologia')}: </dt>
                <dd className={styles.variantsTitleValue}>
                  {variantiItems.find((v) => v.selected)?.name}
                </dd>
              </dl>
              <div className={styles.variantsList}>
                {variantiItems.map((x) => (
                  <PLPOptionCard
                    item={x}
                    selected={x.selected}
                    key={x.pk}
                    className={styles.variantsListItem}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {(!ecommerce || prodotto.disponibile) &&
        !!prezzi?.length &&
        prodotto.stato?.codice !== 'non-disponibile' && (
          <div className={styles.pricesLayout}>
            <div className={styles.prices}>
              {prezzi.map((p) => (
                <span key={p.key} className={`${styles.price} ${styles[`price--${p.key}`]}`}>
                  {!!p.label && <span className={styles.priceLabel}>{p.label} </span>}
                  <span className={styles.priceValue}>
                    {priceNotation(p.value, false, prodotto.valuta?.simbolo)}
                  </span>
                  {p.key === 'current' && cart?.promoApplicata?.promoDipendenti && (
                    <Tag label={cart.promoApplicata.titolo} size="sm" variant="black" />
                  )}
                </span>
              ))}
            </div>
            {prodotto.showGuidaTaglie && (
              <Button
                label={t('Guida alle taglie')}
                variant="ghost"
                size="sm"
                href="/servizio-clienti/guida-alle-taglie/"
                className={`${styles.guidaTaglie} ${styles['guidaTaglie--mobile']}`}
              />
            )}
          </div>
        )}
      <div className={styles.actionsWrap}>
        {!ecommerce && prodotto.tabellaMotori?.testo && (
          <div
            className={`${styles.disponibilitaMessage} ${styles['disponibilitaMessage--noecommerce']}`}
            dangerouslySetInnerHTML={{ __html: prodotto.tabellaMotori.testo }}
          />
        )}
        {ecommerce && !prodotto.disponibile && prodotto.stato?.codice !== 'non-disponibile' && (
          <div className={styles.noBuyLabel}>{t('Non acquistabile online')}</div>
        )}
        <div className={styles.actions}>
          <div className={styles.ecommerceActions}>
            <div className={styles.ctaBox}>
              {ecommerce && prodotto.disponibile ? (
                <Button
                  label={t('Aggiungi al carrello')}
                  onClick={addToCart}
                  className={styles.cta}
                  loading={loading || cartLoading}
                />
              ) : ecommerce && prodotto.stato?.codice === 'non-disponibile' ? (
                <div className={styles.alert}>
                  <span className={styles.alertIcon}>
                    <CartError />
                  </span>
                  <span className={styles.alertLabel}>{t('Articolo fuori produzione')}</span>
                </div>
              ) : !international ? (
                <Button
                  label={t('Trova un rivenditore')}
                  variant="secondary"
                  href="/store-locator"
                  onClick={() => handleFindStoreCtaTracking(router.asPath)}
                  className={styles.cta}
                />
              ) : (
                <></>
              )}
              {ecommerce && (
                <WishlistButton
                  prodotto={prodotto}
                  className={`${styles.wishlist} ${
                    !prodotto.disponibile && prodotto.stato?.codice === 'non-disponibile'
                      ? styles['wishlist--desktop']
                      : ''
                  }`}
                />
              )}
            </div>
            {/* La tabella motori va mostrata sempre, non + disponibile e non (se non ecommerce va da un'altra parte) */}
            {ecommerce && prodotto.tabellaMotori?.testo && (
              <div
                className={styles.disponibilitaMessage}
                dangerouslySetInnerHTML={{ __html: prodotto.tabellaMotori.testo }}
              />
            )}
          </div>
          <div className={styles.bottomActions}>
            <ShareButton
              id={'pdpdetail'}
              item={{
                name: prodotto.nome,
                description: prodotto.descrizioneBreve,
                url: typeof window !== 'undefined' ? window?.location?.href : router.asPath,
              }}
              className={styles.shareButton}
            />
            {/* Per qualche strana ragione, solo in questo caso, nella versione mobile, la wishlist si sposta completamente... */}
            {ecommerce && !prodotto.disponibile && prodotto.stato?.codice === 'non-disponibile' && (
              <WishlistButton
                prodotto={prodotto}
                className={`${styles.wishlist} ${styles['wishlist--mobile']}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default PDPDetail
