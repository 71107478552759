import React from 'react'
import { MenuItemType } from '@utils/types'
import { WContainer, EditableTitle, EditableDescription } from '@components/atoms'
import { TabellaDifferenzeCard } from '@components/molecules'
import styles from './TabellaDifferenze.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const TabellaDifferenze = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          <div className={styles.main}>
            <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
            <EditableDescription
              description={item.description}
              className={styles.description}
              editMode={editMode}
            />
          </div>
          {editMode ? (
            <>{item.children}</>
          ) : (
            <div className={`${styles.list}`}>
              {item.children?.map((x) => (
                <TabellaDifferenzeCard item={x} key={x.pk} className={styles.item} />
              ))}
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default TabellaDifferenze
