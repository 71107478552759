import React, { useState } from 'react'
import styles from './CompareBar.module.sass'
import { CompareBarItem } from '@components/molecules'
import { Button, CloseBar, WContainer, WModal } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { ArrowRight, Close } from '@components/icons'
import { RES_LG_MIN, RES_MD_MIN } from '@utils/settings'
import useComparatore from '@hooks/useComparatore'
import { useRouter } from 'next/router'
import { CategoriaNode } from '@gql/graphql'
import { handleCompareTracking } from '@utils/tracking'

interface Props {
  open?: boolean
  setOpen?: (v: boolean) => void
  prodotti?: any[]
  className?: string
  categoria?: CategoriaNode
}

const CompareBar = (props: Props) => {
  const {
    open = false,
    setOpen = undefined,
    prodotti = [],
    className = '',
    categoria = null,
  } = props

  const t = useTrans()
  const router = useRouter()
  const [mobileOpen, setMobileOpen] = useState(false)

  const { removeItem, resetItems } = useComparatore(categoria?.pk)

  const onConfirm = () => {
    router.push(`${categoria?.url}compare/`)
  }

  return (
    <>
      <div
        className={`${styles.root} ${open ? styles['root--open'] : ''} ${className}`}
        onClick={() => {
          if (window.innerWidth < RES_LG_MIN) setMobileOpen(true)
        }}
      >
        <WContainer>
          <div className={styles.layout}>
            <div className={styles.head}>
              <h2 className={styles.title}>{t('Confronta prodotti')}</h2>
              <p className={styles.description}>
                {t(prodotti.length !== 1 ? '{0} prodotti aggiunti' : '{0} prodotto aggiunto', [
                  `${prodotti.length}`,
                ])}
              </p>
            </div>
            <div className={styles.list}>
              {[...Array(3).keys()].map((x, index) => (
                <CompareBarItem
                  key={index}
                  prodotto={prodotti?.[index]}
                  onClose={() => removeItem(prodotti?.[index])}
                />
              ))}
            </div>
            <Button
              label={t('Confronta')}
              iconRight={<ArrowRight />}
              onClick={(e) => {
                e.stopPropagation() // serve per la versione mobile - se si clicca su qualunque punto della barra tranne che sul button di confirm si apre la dialog
                handleCompareTracking(prodotti, 'add')
                onConfirm()
              }}
              theme="dark"
              mobileSize="sm"
              hideMobileIcon
              disabled={(prodotti?.length || 0) < 2}
              className={styles.submit}
            />
            <button
              type="button"
              aria-label={t('Chiudi')}
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
            >
              <Close />
            </button>
          </div>
        </WContainer>
      </div>
      <WModal
        id="comparebar_modal"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        className={styles.modal}
      >
        <WContainer>
          <div className={styles.modalLayout}>
            <CloseBar onClose={() => setMobileOpen(false)} className={styles.modalCloseBar} />
            <div className={styles.modalHead}>
              <h2 className={styles.modalTitle}>{t('Confronta prodotti')}</h2>
              <button
                type="button"
                aria-label={t('Chiudi')}
                onClick={() => setMobileOpen(false)}
                className={styles.modalClose}
              >
                <Close />
              </button>
            </div>
            <p className={styles.modalDescription}>
              {t('Puoi confrontare fino a 3 prodotti contemporaneamente.')}
            </p>
            <div className={styles.modalList}>
              {prodotti.map((prodotto, index) => (
                <CompareBarItem
                  key={index}
                  prodotto={prodotto}
                  onClose={() => removeItem(prodotto)}
                />
              ))}
            </div>
            {!!prodotti?.length && (
              <div className={styles.modalResetBox}>
                <Button
                  label={t('Elimina tutti')}
                  variant="ghost"
                  onClick={() => {
                    resetItems()
                    setMobileOpen(false)
                  }}
                />
              </div>
            )}
            <div className={styles.modalCtaBox}>
              <Button
                label={t('Confronta i prodotti')}
                onClick={onConfirm}
                disabled={(prodotti?.length || 0) < 2}
              />
            </div>
          </div>
        </WContainer>
      </WModal>
    </>
  )
}

export default CompareBar
