import { useContext, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { checkHashSharedPreview, isSharedDraft } from 'react-pagebuilder/utils/sharedPreview'
import { PagebuilderContext } from '../services/pagebuilderContext'
import { GlobalContext } from '../services/globalContext'
import { Modal, Loader } from '../pb-components/atoms'
import { SSRContent } from '../ssr/SSRContent'

const Editor = dynamic(() => import('../editor').then((mod) => mod.Editor), { ssr: false })
const PlaceholderImport = dynamic(
  () => import('./PlaceholderImport').then((mod) => mod.PlaceholderImport),
  { ssr: false }
)

export const Placeholder = () => {
  const { utente, editMode, draft, showImport, setShowImport } = useContext(GlobalContext)
  const { pagebuilder, isLoading, isSSR } = useContext(PagebuilderContext)
  const [isSharedPreview, setIsSharedPreview] = useState(false)

  useEffect(() => {
    setIsSharedPreview(checkHashSharedPreview())
  }, [])

  if (editMode && utente && utente.isStaff && utente.showToolbar) {
    if (isLoading) return <Loader />

    if (pagebuilder)
      return (
        <div className="pagebuilder-wrapper" style={{ width: '100%', height: '100%' }}>
          <Editor
            json={pagebuilder.json_draft}
            editMode={editMode}
            withConditions={false} // vengono usate le conditions tramite classi css
          />
          {showImport && (
            <Modal open={true} setOpen={setShowImport}>
              <PlaceholderImport />
            </Modal>
          )}
        </div>
      )
  }

  return pagebuilder ? (
    isSharedPreview && isSharedDraft() ? (
      <SSRContent json={pagebuilder.json_draft} key="draft" />
    ) : (
      <SSRContent json={pagebuilder.json_published} key="published" />
    )
  ) : (
    <></>
  )
}
