import React from 'react'
import useTrans from '@hooks/useTrans'
import { Time } from '@components/icons'
import { Button, Tag, WImage } from '@components/atoms'
import styles from './ArticoloSmallCard.module.sass'
import { NewsNode } from '@gql/graphql'
import { handleSelectContentTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  news?: NewsNode
  variant?: '' | 'evidenza'
  className?: string
}

const ArticoloSmallCard = (props: Props) => {
  const { news = undefined, variant = '', className = '' } = props

  const t = useTrans()
  const router = useRouter()

  return news ? (
    <div className={`${styles.root} ${variant ? styles[`root--${variant}`] : ''} ${className}`}>
      {variant === 'evidenza' && (
        <div className={styles.imageBox}>
          <WImage src={news.previewImage} maxWidth={240} maxHeight={240} objectFit="cover" />
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.head}>
          <Tag label={news?.categoria?.nome} variant={'ideeverdi'} />
          {variant === 'evidenza' && !!news.tempoLettura && (
            <div className={styles.time}>
              <span className={styles.timeIcon}>
                <Time />
              </span>
              <span className={styles.timeLabel}>
                {t('{0} min di lettura', [news.tempoLettura.toString()])}
              </span>
            </div>
          )}
        </div>
        <h3 className={styles.title}>{news.titolo}</h3>
        {variant === 'evidenza' && !!news.sottotitolo && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: news.sottotitolo }}
          />
        )}
        <Button
          label={t("Leggi l'articolo")}
          theme={variant === 'evidenza' ? 'dark' : undefined}
          color={variant === 'evidenza' ? 'white' : undefined}
          href={news.url}
          onClick={() =>
            handleSelectContentTracking(
              news.titolo,
              'article',
              news?.categoria?.nome || news.titolo,
              router.asPath
            )
          }
          variant="ghost"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ArticoloSmallCard
