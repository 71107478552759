import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import { MenuItemType } from '@utils/types'
import { EditableTitle } from '@components/atoms'
import { TabellaDifferenzeCardItem } from '@components/molecules'
import styles from './TabellaDifferenzeCard.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

export const TabellaDifferenzeCardSwiperParams: SwiperOptions & { className?: string } = {
  slidesPerView: 1.1,
  spaceBetween: 24,
  modules: [A11y, Navigation, Pagination],
  navigation: true,
  pagination: { clickable: true },
  breakpoints: {
    768: {
      slidesPerView: 2.1,
    },
    992: {
      slidesPerView: 3,
    },
  },
}

const TabellaDifferenzeCard = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      <EditableTitle title={item.name} variant="h3" className={styles.title} editMode={editMode} />
      {editMode ? (
        <>{item.children}</>
      ) : (
        <div className={`${styles.list}`}>
          {item.children?.map((x) => (
            <TabellaDifferenzeCardItem item={x} key={x.pk} className={styles.item} />
          ))}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default TabellaDifferenzeCard
