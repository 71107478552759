import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription, WContainer } from '@components/atoms'
import styles from './TextImageSmall.module.sass'
import { getIcon } from '@utils/icons'

interface Props {
  item: MenuItemType
  theme?: 'light' | 'neutrallight' | 'secondarydark'
  editMode?: boolean
  className?: string
}

const TextImageSmall = (props: Props) => {
  const { item = undefined, theme = 'neutrallight', editMode = false, className = '' } = props
  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        editMode ? styles['root--editMode'] : ''
      } ${className}`}
    >
      <WContainer>
        <div className={`${styles.layout}`}>
          <div className={styles.main}>
            {(!!item.name || !!item.description) && (
              <div className={styles.content}>
                <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
                <EditableDescription
                  description={item.description}
                  className={styles.description}
                  editMode={editMode}
                />
              </div>
            )}
            {!!item.url && !!item.ctaLabel && (
              <div className={styles.ctaBox}>
                <Button
                  label={item.ctaLabel}
                  href={item.url}
                  newPage={item.blank}
                  theme={['secondarydark'].includes(theme) ? 'dark' : undefined}
                  color={['secondarydark'].includes(theme) ? 'white' : undefined}
                  variant="secondary"
                  iconRight={getIcon(item.icon)}
                  size="lg"
                />
              </div>
            )}
          </div>
          <div className={`${styles.imageBox}`}>
            {!!item.image && (
              <WImage
                src={item.image}
                maxWidth={767}
                maxHeight={400}
                alt={item.alt}
                title={item.alt}
                objectFit="cover"
              />
            )}
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default TextImageSmall
