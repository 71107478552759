import React, { useMemo } from 'react'
import styles from './NavListItem.module.sass'
import { MenuItemType } from '@utils/types'
import { WImage, WLink } from '@components/atoms'
import { ChevronRight } from '@components/icons'
import NavPanel from '../NavPanel/NavPanel'
import { getIcon } from '@utils/icons'
import { handleHeaderMenuTracking } from '@utils/tracking'

interface Props {
  item: MenuItemType
  variant?: '' | 'simple'
  open?: boolean
  setOpen?: (v: boolean) => void
  level: number
  setOpenLevel?: (v: number) => void
  className?: string
}

const NavListItem = (props: Props) => {
  const {
    item = undefined,
    open = false,
    setOpen = undefined,
    level = undefined,
    setOpenLevel = undefined,
    className = '',
  } = props

  const classNames = `
    ${styles.root} 
    ${item.url ? styles['root--link'] : styles['root--button']} 
    ${!!item.image ? styles['root--image'] : !!getIcon(item.icon) ? styles['root--icon'] : ''} 
    ${styles[`root--level${level}`]}
    ${open ? styles['root--open'] : ''}
    ${item.style ? styles[`root--${item.style}`] : ''} 
    ${className}
  `

  const content = useMemo(
    () => (
      <>
        {!!item.image ? (
          <div className={styles.imageBox}>
            <WImage
              src={item.image}
              maxWidth={40}
              maxHeight={40}
              objectFit={'contain'}
              alt={item.name}
              loading="eager"
            />
          </div>
        ) : (
          !!getIcon(item.icon) && <span className={styles.icon}>{getIcon(item.icon)}</span>
        )}
        <span className={styles.label}>{item.name}</span>
      </>
    ),
    [item]
  )

  return item ? (
    <>
      {item.url ? (
        <WLink
          href={item.url}
          onClick={() => handleHeaderMenuTracking(item.name, level)}
          newPage={item.blank}
          className={classNames}
        >
          {content}
        </WLink>
      ) : (
        level < 2 && (
          <>
            <button
              type="button"
              className={classNames}
              onClick={() => {
                setOpen(true)
                setOpenLevel(level + 1)
              }}
            >
              {content}
              <span className={styles.arrow}>
                <ChevronRight />
              </span>
            </button>
            {level < 2 && (
              <NavPanel
                item={item}
                open={open}
                setOpen={setOpen}
                className={styles.panel}
                level={level + 1}
                setOpenLevel={setOpenLevel}
              />
            )}
          </>
        )
      )}
    </>
  ) : (
    <></>
  )
}

export default NavListItem
