import React from 'react'
import { useTrans } from 'hooks'
import { ListingHead, Page, PowerBar } from 'components/organisms'
import styles from './VideoTutorialListingTemplate.module.sass'
import { PaginaNode, VideoTutorialNode } from '@gql/graphql'
import { VideoTutorialCard } from '@components/molecules'
import { Button, WContainer } from '@components/atoms'

interface Props {
  pagina?: PaginaNode
  videos?: VideoTutorialNode[]
  handleMore?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  powerBarItems?: {
    pk: number
    name: string
    selected: boolean
    url: string
  }[]
  loading?: boolean
  nextPageLength?: number
  linkNextPage?: string
  buttonUrl?: boolean
}

const VideoTutorialListingTemplate = (props: Props) => {
  const {
    pagina = undefined,
    videos = undefined,
    powerBarItems = [],
    handleMore = undefined,
    loading = false,
    nextPageLength = undefined,
    linkNextPage = undefined,
    buttonUrl = false,
  } = props

  const t = useTrans()

  return (
    <Page pagina={pagina} className={`${styles.root}`}>
      <main className={styles.layout}>
        <ListingHead pagina={pagina} />
        <PowerBar items={powerBarItems} />
        <WContainer>
          <div className={styles.main}>
            <div className={styles.list}>
              {videos.map((v, index) => (
                <VideoTutorialCard
                  key={v.pk}
                  videoTutorial={v}
                  variant={index === 0 ? 'main' : ''}
                  className={`${styles.item} ${index === 0 ? styles['item--full'] : ''}`}
                />
              ))}
            </div>
            {nextPageLength > 0 && (
              <div className={styles.ctaBox}>
                <Button
                  label={t(
                    nextPageLength === 1 ? 'Carica {0} altro video' : 'Carica altri {0} video',
                    [nextPageLength.toString()]
                  )}
                  variant="secondary"
                  onClick={handleMore}
                  loading={loading}
                  href={buttonUrl ? linkNextPage : null}
                />
              </div>
            )}
          </div>
        </WContainer>
      </main>
    </Page>
  )
}

export default VideoTutorialListingTemplate
