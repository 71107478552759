import React from 'react'
import styles from './MyAccountInfoOrdersCard.module.sass'
import { Box, Profile } from '@components/icons'
import useTrans from '@hooks/useTrans'
import { MenuItemType } from '@utils/types'
import { Button, WLink } from '@components/atoms'

interface Props {
  variant?: 'account' | 'orders'
  items?: MenuItemType[]
  children?: React.ReactNode
  className?: string
}

const MyAccountInfoOrdersCard = (props: Props) => {
  const { variant = 'account', items = [], children = undefined, className = '' } = props

  const t = useTrans()

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={`${styles.header} ${variant === 'orders' ? styles['header--orders'] : ''}`}>
        <div className={styles.titleHeader}>
          {variant === 'account' ? <Profile /> : <Box />}
          <h4 className={styles.title}>
            {variant === 'account' ? t('Il mio account') : t('I miei ordini')}
          </h4>
        </div>
        {variant === 'orders' && (
          <Button variant="ghost" label={t('Vedi tutti gli ordini')} href="/myaccount/orders/" />
        )}
      </div>
      {variant === 'account' ? (
        <div className={styles.links}>
          {items?.map((item) => (
            <WLink href={item.url} className={styles.link}>
              {item.ctaLabel}
            </WLink>
          ))}
        </div>
      ) : (
        <div className={styles.children}>{children}</div>
      )}
    </div>
  )
}

export default MyAccountInfoOrdersCard
