export const setPagina = (pagina) => {
  return {
    type: 'SET_CURRENT_PAGE',
    pagina,
  }
}

export const resetPagina = () => ({
  type: 'RESET_CURRENT_PAGE',
})

export const setRecaptchaRequired = () => ({
  type: 'SET_RECAPTCHA_REQUIRED',
})

export default function pagina(
  state = {
    pagina: {},
    recaptchaRequired: false,
  },
  action
) {
  switch (action.type) {
    case 'SET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        pagina: action.pagina,
        recaptchaRequired: false,
      })
    }

    case 'RESET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        pagina: {},
        recaptchaRequired: false,
      })
    }

    case 'SET_RECAPTCHA_REQUIRED': {
      return Object.assign({}, state, {
        recaptchaRequired: true,
      })
    }

    default:
      return state
  }
}
