import React from 'react'
import useTrans from '@hooks/useTrans'
import { MenuItemType } from '@utils/types'
import { SidebarLink } from '@components/atoms'
import styles from './AcquistiOnlineSidebar.module.sass'

interface Props {
  item: MenuItemType
  activePk?: number
  className?: string
}

const AcquistiOnlineSidebar = (props: Props) => {
  const { item = undefined, activePk = undefined, className = '' } = props

  return (
    <aside className={`${styles.root} ${className}`}>
      <ul className={styles.nav}>
        <li className={styles.block}>
          <ul className={styles.blockList}>
            <li>
              <SidebarLink item={item} active={activePk === item.pk} />
            </li>
          </ul>
        </li>
        <li className={styles.block}>
          <ul className={styles.blockList}>
            {item.children?.map((y) => (
              <li key={y.pk}>
                <SidebarLink item={y} active={activePk === y.pk} />
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </aside>
  )
}

export default AcquistiOnlineSidebar
