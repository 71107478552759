import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { WContainerSettings } from './WContainerSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { layoutDefaultProps } from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const defaultProps = {
  ...layoutDefaultProps('none'),
  variant: 'md',
}

export const dynamicProps = []

export const WContainer: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { customVar } = blockProps

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    // non uso il WContainer originale perchè il div intorno mi sminchia tutto, do lo stile direttamente al div del pagebuilder con la ref e amen
    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-w-container block-w-container--${blockProps.variant} ${
          blockProps.spaceTop ? `block-w-container--st-${blockProps.spaceTop}` : ''
        } ${blockProps?.spaceBottom ? `block-w-container--sb-${blockProps.spaceBottom}` : ''} ${
          props.className
        }`}
      >
        {React.Children.toArray(children).filter((child) => !child.props.hidden)}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

WContainer.craft = withCraft({
  name: 'WContainer',
  defaultProps: defaultProps,
  settings: WContainerSettings,
})
