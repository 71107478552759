import React from 'react'
import { CategoriaNode } from '@gql/graphql'
import { ButtonIcon, WImage, WLink } from '@components/atoms'
import styles from './CategoriaHubCard.module.sass'
import { ChevronRight } from '@components/icons'
import useTrans from '@hooks/useTrans'
import { handleClickCategoryTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  categoria?: CategoriaNode
  className?: string
}

const CategoriaHubCard = (props: Props) => {
  const { categoria = undefined, className = '' } = props

  const t = useTrans()
  const router = useRouter()

  const getContent = () => (
    <>
      {categoria.previewImage && (
        <div className={styles.imageBox}>
          <WImage
            src={categoria.previewImage}
            maxWidth={100}
            maxHeight={100}
            alt={categoria.nome}
            objectFit="contain"
          />
        </div>
      )}
      <div className={styles.main}>
        <h2 className={styles.title}>{categoria.nome}</h2>
        <span className={styles.num}>
          {t(categoria.numeroProdotti === 1 ? '1 prodotto' : '{0} prodotti', [
            `${categoria.numeroProdotti}`,
          ])}
        </span>
      </div>
      <ButtonIcon icon={<ChevronRight />} color="primary" size="lg" />
    </>
  )

  return categoria ? (
    categoria?.url ? (
      <WLink
        href={categoria.url}
        onClick={() => handleClickCategoryTracking(categoria.nome, router.asPath)}
        className={`${styles.root} ${className}`}
      >
        {getContent()}
      </WLink>
    ) : (
      <div className={`${styles.root} ${className}`}>{getContent()}</div>
    )
  ) : (
    <></>
  )
}

export default CategoriaHubCard
