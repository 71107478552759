import replace from 'lodash/replace'
import TagManager from 'react-gtm-module'
import { Cart } from './cartContext'
import { UtenteNode } from '@gql/graphql'

// per assicurare che gli eventi siano eseguiti sempre dopo il wSetup
let eventQueue = []
let isReady = false

export const setTrackingReady = () => {
  isReady = false
}

export const removeTags = (str) => {
  if (str === null || str === '') return ''
  else str = str?.toString()
  return str
    ?.replace(/(<([^>]+)>)/gi, '')
    .replace(' ', '_')
    .toLowerCase()
}

const sendGTMData = (data: any): void => {
  if (data?.event != 'wSetup' && data?.event != 'virtual_pageview') {
    // se non è ancora scattato il wSetup aggiungo l'evento alla coda
    if (!isReady) {
      eventQueue.push(data)
      return
    }
  }

  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

export const uniformData = (param: string) =>
  replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()

export const getPageType = (page, item, path) => {
  const pageChiave = page?.chiave || ''

  let pageType = 'others'

  if (pageChiave.includes('404')) pageType = 'error page'
  if (pageChiave.includes('homepage')) pageType = 'homepage'
  if (
    pageChiave.includes('contatti') ||
    path?.startsWith('/contatti') ||
    path?.startsWith('/servizio-clienti')
  )
    pageType = 'customer service page'
  if (pageChiave.includes('contatti-form')) pageType = 'contact form' // da eseguire dopo "customer service page"
  if (pageChiave.includes('ricerca')) pageType = 'internal search'

  // myaccount
  if (pageChiave.includes('myaccount') || path.includes('myaccount')) pageType = 'account'

  // idee verdi
  if (pageChiave.includes('blog-home')) pageType = 'idee_verdi'

  // cart e checkout
  if (pageChiave === 'cart') pageType = 'cart'
  if (pageChiave === 'checkout') pageType = 'checkout'
  if (pageChiave === 'payment-ok') pageType = 'thankyou'

  // news
  if (pageChiave.includes('news-detail')) pageType = 'news - article page'
  if (pageChiave.includes('news-categoria')) pageType = 'news - article list page'
  if (pageChiave.includes('news-index')) pageType = 'news - archivio'

  // newsletter
  if (pageChiave.includes('newsletter-detail')) pageType = 'newsletter - article page'
  if (pageChiave.includes('newsletter-tipologia') || pageChiave.includes('newsletter-index'))
    pageType = 'newsletter - article list page'
  if (pageChiave.includes('newsletter-archivio')) pageType = 'newsletter - archivio'

  // PLP e PDP
  if (item?.modelClass?.includes('categoria')) pageType = 'listing'
  if (item?.modelClass?.includes('prodotto')) pageType = 'product'

  return pageType
}

export const getItemPage = (page) => {
  if (page.prodotto) {
    return page.prodotto
  }

  if (page.categoria) {
    return page.categoria
  }

  if (page.news) {
    return page.news
  }

  if (page.categoriaHelpOnline) {
    return page.categoriaHelpOnline
  }

  if (page.video) {
    return page.video
  }

  if (page.rivenditore) {
    return page.rivenditore
  }

  return page ?? null
}

const getCurrentChannel = (pageType: string, currentPath: string) => {
  if (
    pageType === 'listing' ||
    pageType === 'product' ||
    currentPath === '/cart' ||
    currentPath.includes('/checkout')
  ) {
    return 'store'
  }

  return 'corporate'
}

const getShopType = (pageType: string, path: string) => {
  const level = path.split('/').length

  if (pageType === 'listing') {
    if (level === 4) {
      return 'first-level'
    }
    if (level === 5) {
      return 'second-level'
    }
    if (level === 6) {
      return 'third-level'
    }
  }
  if (pageType === 'product') {
    return 'product'
  }
  if (path.includes('/checkout')) {
    return 'checkout'
  }
  if (path.includes('/myaccount')) {
    return 'account'
  }

  return 'other'
}

const getLevelCategory = (pageType: string, path: string) => {
  const segments = path.split('/')

  let firstLevelCategory = ''
  let secondLevelCategory = ''
  let thirdLevelCategory = ''

  if (pageType === 'listing') {
    firstLevelCategory = segments[2] || ''
    secondLevelCategory = segments[3] || ''
    thirdLevelCategory = segments[4] || ''
  }

  return { firstLevelCategory, secondLevelCategory, thirdLevelCategory }
}

const formatDate = (date: string) => {
  const d = new Date(date)

  const month = String(d.getMonth() + 1).padStart(2, '0') // I mesi in JavaScript vanno da 0 a 11
  const day = String(d.getDate()).padStart(2, '0')
  const year = d.getFullYear()

  return `${day}/${month}/${year}`
}

const getGTMPageProps = (paginaCorrente, router, utente: UtenteNode, cart: Cart) => {
  const page = paginaCorrente?.pagina
  const item = getItemPage(paginaCorrente)
  const pagePath = router?.asPath
  const pageType = getPageType(page, item, pagePath)

  let productAvailability = ''
  let userType = ''

  if (item && item.modelClass === 'prodotto' && item.disponibile) {
    productAvailability = 'disponibile'
  }
  if (item && item.modelClass === 'prodotto' && !item.disponibile) {
    productAvailability = 'out_of_stock'
  }

  if (utente) {
    if (utente.privato) {
      userType = 'b2c'
    } else {
      userType = 'b2b'
    }
  }

  const { firstLevelCategory, secondLevelCategory, thirdLevelCategory } = getLevelCategory(
    pageType,
    pagePath
  )

  return {
    channel: getCurrentChannel(pageType, pagePath),
    loggedStatus: utente && !utente.guest ? 'logged' : 'not_logged',
    pageType: pageType,
    shopType: getShopType(pageType, router?.asPath),
    productAvailability: productAvailability,
    firstLevelCategory: firstLevelCategory,
    secondLevelCategory: secondLevelCategory,
    thirdLevelCategory: thirdLevelCategory,
    wishlistTotalProducts: utente ? utente.numeroProdottiWishlist : 0,
    cartTotalProducts: cart?.items?.length || 0,
    userId: utente ? utente.pk : '',
    userType: userType,
    dateRegistration: utente ? formatDate(utente.dateJoined) : '',
    dateFirstPurchase: utente ? utente.dataPrimoOrdine : '',
    dateLastPurchase: utente ? utente.dataUltimoOrdine : '',
  }
}

let currentPage = {}
let currentChannel = ''

export const setPage = (page) => {
  currentPage = page
  currentChannel = 'it'
}

export const trackingRegistrazioneLead = (formType, orderId, customerType, productType) => {
  sendGTMData({
    event: 'ga4_event',
    event_name: 'lead_form',
    form_type: formType?.toLowerCase() || '',
    order_id: orderId.toString() || '',
    customer_type: customerType?.toLowerCase() || '',
    product_type: productType?.toLowerCase() || '',
    page_url: window.location.href,
  })
}

export const trackingSimulatore = (productType) => {
  sendGTMData({
    event: 'ga4_event',
    event_name: 'simulatore',
    product_type: productType || '',
    page_url: window.location.href,
  })
}

export const trackingGAevent = (eventName, values = {}) => {
  // const path = getPagePath(currentPage)

  const data = {
    event: 'ga4_event',
    event_name: eventName || '',
    ...values,
  }

  sendGTMData(data)
}

export const trackingGaEventNew = (eventName, values = {}) => {
  const data = {
    event: 'GA4event',
    eventName: eventName || '',
    ...values,
  }

  sendGTMData(data)
}

export const trackingEcommerceEvent = (eventName: string, values = {}) => {
  const eCommerceCleanData = {
    ecommerce: null,
  }
  const data = {
    event: eventName,
    ecommerce: { ...values },
  }

  sendGTMData(eCommerceCleanData)
  sendGTMData(data)
}

export const updateGTMDataLayer = (paginaCorrente, router, environment, utente, cart) => {
  const gtmPageProps = getGTMPageProps(paginaCorrente, router, utente, cart)

  sendGTMData({
    event: 'wSetup',
    cookiebot: 'true',
    new_history_url: window.location.href,
    ...gtmPageProps,
  })

  sendGTMData({
    event: 'virtual_pageview',
    page_path: document.location.href,
    page_title: paginaCorrente?.pagina?.seo?.title
      ? String(paginaCorrente?.pagina?.seo?.title)?.toLowerCase()?.replace(/ /g, '_')
      : '',
  })

  isReady = true

  // dopo il wSetup eseguo tutti gli eventi della coda
  while (eventQueue.length > 0) {
    const eventData = eventQueue.shift()
    sendGTMData(eventData)
  }
}
