import React from 'react'
import { useTrans } from 'hooks'
import { HeroImage, NewsletterSection, Page, PowerBar } from 'components/organisms'
import styles from './BlogTemplate.module.sass'
import { NewsEmakInformaNode, NewsNode, PaginaNode } from '@gql/graphql'
import { ArticoloCard, VideoTutorialCard } from '@components/molecules'
import { Button, WContainer } from '@components/atoms'
import { useRouter } from 'next/router'

interface Props {
  pagina?: PaginaNode
  articoli?: NewsNode[] | NewsEmakInformaNode[]
  handleMore?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  powerBarItems?: {
    pk: number
    name: string
    selected: boolean
    url: string
  }[]
  loading?: boolean
  nextPageLength?: number
  linkNextPage?: string
  buttonUrl?: boolean
}

const BlogTemplate = (props: Props) => {
  const {
    pagina = undefined,
    articoli = undefined,
    powerBarItems = [],
    handleMore = undefined,
    loading = false,
    nextPageLength = undefined,
    linkNextPage = undefined,
    buttonUrl = false,
  } = props

  const router = useRouter()
  const t = useTrans()

  const scontoItem = {
    pk: 1,
    name: t(`newsletter_section_title_${process.env.SITE_ID}_${router.locale}`),
    description: t(`newsletter_section_description_${process.env.SITE_ID}_${router.locale}`),
  }

  return (
    <Page pagina={pagina} className={`${styles.root}`}>
      <main className={styles.layout}>
        <HeroImage pagina={pagina} />
        <PowerBar items={powerBarItems} />
        <WContainer>
          <div className={styles.main}>
            <div className={styles.list}>
              {articoli?.map((n, index) => (
                <React.Fragment key={n.pk}>
                  {n.listingVideos ? (
                    <VideoTutorialCard
                      key={n.pk}
                      videoTutorial={{ ...n, idYoutube: n.listingVideos.idYoutube }}
                      variant={index === 0 ? 'main' : ''}
                      className={`${styles.item} ${index === 0 ? styles['item--full'] : ''}`}
                    />
                  ) : (
                    <ArticoloCard
                      news={n}
                      variant={index === 0 ? 'main' : ''}
                      className={`${styles.item} ${index === 0 ? styles['item--full'] : ''}`}
                    />
                  )}
                  {index === 2 && (
                    <NewsletterSection
                      item={scontoItem}
                      className={`${styles.item} ${styles['item--full']}`}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
            {nextPageLength > 0 && (
              <div className={styles.ctaBox}>
                <Button
                  label={t(
                    nextPageLength === 1
                      ? 'Carica {0} altro articolo'
                      : 'Carica altri {0} articoli',
                    [nextPageLength.toString()]
                  )}
                  variant="secondary"
                  onClick={handleMore}
                  loading={loading}
                  href={buttonUrl ? linkNextPage : null}
                />
              </div>
            )}
          </div>
        </WContainer>
      </main>
    </Page>
  )
}

export default BlogTemplate
