import React from 'react'
import styles from './SearchTrigger.module.sass'
import { Search } from '@components/icons'
import useTrans from '@hooks/useTrans'

interface Props {
  onClick?: () => void
  className?: string
}

const SearchTrigger = (props: Props) => {
  const { onClick = undefined, className = '' } = props

  const t = useTrans()

  return (
    <button type="button" className={`${styles.root} ${className}`} onClick={onClick}>
      <span className={styles.icon}>
        <Search />
      </span>
      <span className={styles.label}>{t('Cerca')}</span>
    </button>
  )
}

export default SearchTrigger
