import React from 'react'
import useTrans from '@hooks/useTrans'
import { Accordion } from '@components/atoms'
import { CheckoutRiepilogo } from '@components/organisms'
import styles from './CheckoutSidebar.module.sass'

interface Props {
  dati?: any
  variant?: 'checkout' | 'cart'
  className?: string
}

const CheckoutSidebar = (props: Props) => {
  const { dati = undefined, variant = 'checkout', className = '' } = props
  const t = useTrans()

  const accordionItems = [
    {
      pk: 1,
      label: t('accordion_checkout_label_1'),
      content: t('accordion_checkout_content_1'),
    },
    {
      pk: 2,
      label: t('accordion_checkout_label_2'),
      content: t('accordion_checkout_content_2'),
    },
    {
      pk: 3,
      label: t('accordion_checkout_label_3'),
      content: t('accordion_checkout_content_3'),
    },
    {
      pk: 4,
      label: t('accordion_checkout_label_4'),
      content: t('accordion_checkout_content_4'),
    },
  ]

  return (
    <div className={`${styles.root} ${className}`}>
      <CheckoutRiepilogo dati={dati} variant={variant} />
      {!!accordionItems?.length && (
        <div className={styles.list}>
          {accordionItems.map((a) => (
            <div className={styles.item} key={a.pk}>
              <Accordion id={`checkout_sidebar_accordion_${a.pk}`} label={a.label}>
                <div
                  className={styles.itemContent}
                  dangerouslySetInnerHTML={{ __html: a.content }}
                />
              </Accordion>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CheckoutSidebar
