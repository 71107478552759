import React, { useEffect, useRef, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { RivenditoreNode } from '@gql/graphql'
import { RadioMarked, RadioUnmarked } from '@components/icons'
import { Button, FormError } from 'components/atoms'
import styles from './FormRivenditoreRadio.module.sass'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export interface OptionProps {
  id: string
  rivenditore?: RivenditoreNode
  name?: string
  checked?: boolean
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props {
  id: string
  name?: string
  value?: any
  className?: string
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  rivenditori: RivenditoreNode[]
}

const FormRivenditoreRadioOption = (props: OptionProps) => {
  const {
    id = '',
    name = undefined,
    rivenditore = undefined,
    checked = false,
    className = '',
    onChange = () => {},
  } = props

  const t = useTrans()
  const [mapOpen, setMapOpen] = useState(false)
  const lat = parseFloat(rivenditore?.latitudine.replace(',', '.'))
  const lng = parseFloat(rivenditore?.longitudine.replace(',', '.'))

  const classNames = `${styles.option} ${mapOpen ? styles['option--open'] : ''} ${className}`

  const mapRef = useRef()

  useEffect(() => {
    mapRef?.current?.resize()
  }, [mapOpen])

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          id={id}
          checked={checked}
          name={name}
          value={`${rivenditore?.pk}`}
          className={styles.input}
          type="radio"
          onChange={onChange}
        />
        <span className={styles.styledInput}>{checked ? <RadioMarked /> : <RadioUnmarked />}</span>
        <div className={styles.main}>
          <div className={styles.content}>
            <p className={styles.title}>{rivenditore?.ragioneSociale}</p>
            <div className={styles.details}>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>{t('Indirizzo')}:</span>
                <span className={styles.detailValue}>
                  {rivenditore?.indirizzo} - {rivenditore?.citta} ({rivenditore?.provincia})
                </span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>{t('Metodi di pagamento consentiti')}: </span>
                <span className={styles.detailValue}>
                  {Array.from(
                    new Set(rivenditore?.sistemiPagamento?.map((x) => x.famiglia?.nome))
                  ).join(', ')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.mapTriggerBox}>
            <Button
              label={t(mapOpen ? 'Nascondi mappa' : 'Vedi sulla mappa')}
              variant="ghost"
              size="sm"
              onClick={() => setMapOpen(!mapOpen)}
            />
          </div>
        </div>
      </label>
      {!!rivenditore && !!lng && !!lat && (
        <div className={styles.map}>
          <Map
            mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: lng,
              latitude: lat,
              zoom: 11,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            ref={mapRef}
            interactive={false}
          >
            <Marker longitude={lng} latitude={lat} anchor="bottom">
              <img src={`/${process.env.SITE}/markers/marker.png`} />
            </Marker>
          </Map>
        </div>
      )}
    </div>
  )
}

const FormRivenditoreRadio = (props: Props) => {
  const {
    id = '',
    name = '',
    value = null,
    className = '',
    status = '',
    errorMessage = '',
    onChange = () => {},
    rivenditori = [],
  } = props

  const onOptionChange = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly) onChange(e)
  }

  const classNames = `
    ${styles.root} 
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <div className={styles.options}>
        {rivenditori?.map((rivenditore) => (
          <FormRivenditoreRadioOption
            id={`${id}-${rivenditore?.pk}`}
            name={name}
            rivenditore={rivenditore}
            checked={`${rivenditore?.pk}` === value}
            onChange={onOptionChange}
            key={`formrivenditoreradiooption-${id}-${rivenditore?.pk}`}
          />
        ))}
      </div>
      <FormError
        id={`${id}_error`}
        message={errorMessage}
        visible={status === 'error'}
        className={styles.error}
      />
    </div>
  )
}

export default FormRivenditoreRadio
