import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { TRADUZIONI } from 'utils/endpoints'

const replaceSostituzioni = (value: string, sostituzioni?: string[]) => {
  let result = value
  if (sostituzioni?.length) {
    result = Object.values(sostituzioni).reduce(
      (prev, sost, i) => prev.replace(`{${i}}`, sost),
      value
    )
  }
  return result
}

export default function useTrans() {
  // Fix per Storybook
  if (process.env.STORYBOOK)
    return (chiave: string, sostituzioni?: string[]) => replaceSostituzioni(chiave, sostituzioni)

  const { traduzioni } = useSelector((state: any) => state.traduzioni)
  const mounted = useRef(false)
  const router = useRouter()

  useEffect(() => {
    mounted.current = true
  }, [traduzioni])

  useEffect(() => {
    mounted.current = true
  }, [])

  const getTraduzione = (chiave: string, sostituzioni?: string[]) => {
    let result = ''
    if (!chiave) {
      return ''
    }
    try {
      const { valore } = traduzioni.find((t) => t.chiave === chiave)
      result = replaceSostituzioni(valore, sostituzioni)
    } catch (error) {
      // console.log(error)
      api
        .post(TRADUZIONI, { chiave }, { params: { lang: router.locale } })
        // .then(() => console.log('Inserita Chiave', chiave))
        .catch((error_) => console.log(error_))
      result = replaceSostituzioni(chiave, sostituzioni)
    }
    return result
  }

  return getTraduzione
}
