import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import useUtente from './useUtente'
import {
  ProdottoNode,
  useAddWishlistItemMutation,
  useRemoveWishlistItemMutation,
  useWishlistButtonQuery,
} from '@gql/graphql'
import { handleRemoveWishListTracking, handleWishListTracking } from '@utils/tracking'
import { CartContext } from '@utils/cartContext'

export default function useWishlist(prodotto: ProdottoNode) {
  const router = useRouter()
  const { utente } = useUtente()

  const { data, refetch } = useWishlistButtonQuery()
  const { cart } = useContext(CartContext)

  const [addItem] = useAddWishlistItemMutation({ variables: { idProdotto: prodotto?.pk } })
  const [removeItem] = useRemoveWishlistItemMutation({ variables: { idProdotto: prodotto?.pk } })

  const marked = data?.wishlist?.items?.map((x) => x.prodotto?.pk).includes(prodotto?.pk)
  const enabled = !!utente

  const toggleWishlist = async () => {
    if (marked) {
      await removeItem()
      handleRemoveWishListTracking(prodotto, router.asPath)
    } else {
      await addItem()
      handleWishListTracking(prodotto, cart)
    }
    refetch()
  }

  useEffect(() => {
    if (utente && router.isReady && !router.asPath?.includes('/wishlist/')) refetch()
  }, [router.asPath])

  return { marked, toggleWishlist, enabled }
}
