export const REDIRECTS = 'redirects/'
export const TOOLBAR = 'toolbar/'
export const TRADUZIONI = 'traduzioni/'
export const MENU = 'menu/'
export const LISTING = 'listing/'
export const CHECK_REVALIDATE = 'check-revalidate/'
export const DOWNLOAD_CERTIFICATO = 'warranty/certificato_garanzia/'
export const CERCA_CERTIFICATO = 'warranty/get_product_registrations_by_owner_info/'
export const CERCA_SERIALE = 'warranty/get_serial_number/'
export const CERCA_FAMIGLIA = 'warranty/get_machine_models_paged/'
export const REGISTRA_GARANZIA = 'warranty/send_product_registration/'
