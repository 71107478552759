import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Plus({ idVariant = '', className }: Props) {
  return (
    <IconBase name="plus" w={24} className={className}>
      <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="currentColor" />
    </IconBase>
  )
}

export default Plus
