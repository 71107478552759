import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { TextImage as Original } from 'components/molecules'
import { TextImageSettings, TextImageFloatingSettings } from './TextImageSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  imageDefaultProps,
  imageItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'
import { getTextEditor } from '../TextEditor'
import { getItemChildren } from 'react-pagebuilder/utils/node'
import { buttonItemParams } from '../Button'

export const defaultProps = {
  ...imageDefaultProps(),
  introVariant: 'black',
  theme: 'neutrallight',
  orientation: 'vertical',
  imagePos: 'bottom',
  layout: '100',
}

export const textImageItemParams = (enabled, id, blockProps, children) => ({
  ...imageItemParams(blockProps),
  pk: id,
  intro: getTextEditor(children?.[0], enabled),
  name: getTextEditor(children?.[2], enabled),
  description: getTextEditor(children?.[3], enabled),
  children: getItemChildren(enabled, children?.[4], buttonItemParams),
})

export const dynamicProps = []

export const TextImage: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-text-image block-text-image--${blockProps.layout} ${props.className}`}
      >
        <Original
          item={textImageItemParams(enabled, id, blockProps, children)}
          introVariant={blockProps.introVariant}
          theme={blockProps.theme}
          orientation={blockProps.orientation}
          imagePos={blockProps.imagePos}
          data={getTextEditor(children?.[1], enabled)}
          caption={getTextEditor(children?.[5], enabled)}
          editMode={enabled}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

TextImage.craft = withCraft({
  name: 'TextImage',
  defaultProps: defaultProps,
  settings: TextImageSettings,
  floatingSettings: TextImageFloatingSettings,
})
