import React, { useContext } from 'react'
import styles from './CartDrawer.module.sass'
import { Button, WModal } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { Close } from '@components/icons'
import { CartContext } from '@utils/cartContext'
import {
  CheckoutPromoForm,
  CheckoutRiepilogoCosti,
  RigaPreCarrelloCard,
} from '@components/molecules'
import { mockCarrello } from '@utils/mock'
import { handleMiniCartTracking } from '@utils/tracking'

interface Props {
  open?: boolean
  onClose?: () => void
  className?: string
}

const CartDrawer = (props: Props) => {
  const { open = false, onClose = undefined, className = '' } = props

  const t = useTrans()
  const { cart: cartContext } = useContext(CartContext)
  const cart = process.env.STORYBOOK ? mockCarrello(1) : cartContext

  return (
    <WModal
      id="cart_drawer"
      open={open}
      onClose={onClose}
      className={`${styles.root} ${className}`}
    >
      <div className={styles.layout}>
        <div className={styles.head}>
          <div className={styles.headContent}>
            <p className={styles.title}>{t('Carrello')}</p>
            <button
              type="button"
              aria-label={t('Chiudi')}
              onClick={onClose}
              className={styles.close}
            >
              <Close />
            </button>
          </div>
        </div>
        <div className={styles.body}>
          {cart.items?.length ? (
            <div className={styles.list}>
              {cart.items.map((x) => (
                <RigaPreCarrelloCard key={x.prodotto.prodotto.pk} rigaCarrello={x} />
              ))}
            </div>
          ) : (
            <div className={styles.empty}>{t('Il tuo carrello è vuoto')}</div>
          )}
        </div>
        <div className={styles.footer}>
          <CheckoutPromoForm
            id={'cart_drawer_promo_form'}
            promo={cart.promoApplicata}
            className={styles.promo}
          />
          <CheckoutRiepilogoCosti dati={cart} variant="drawer" />
          <div className={styles.ctaBox}>
            <Button
              label={t('Visualizza carrello')}
              variant="secondary"
              onClick={() => handleMiniCartTracking('view_cart')}
              href="/cart"
            />
            {cart.items?.length > 0 && (
              <Button
                label={t("Procedi con l'ordine")}
                href="/cart/checkout"
                onClick={() => handleMiniCartTracking('shop_now')}
              />
            )}
          </div>
        </div>
      </div>
    </WModal>
  )
}

export default CartDrawer
