import React from 'react'
import { MenuItemType } from '@utils/types'
import { getIcon } from '@utils/icons'
import WLink from '../WLink/WLink'
import styles from './HeaderMenuItem.module.sass'

interface Props {
  item?: MenuItemType
  notification?: boolean
  badge?: number
  variant?: 'icon' | ''
  size?: 'sm' | 'md'
  responsive?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  className?: string
}

const HeaderMenuItem = (props: Props) => {
  const {
    item = undefined,
    notification = false,
    badge = undefined,
    variant = '',
    size = 'md',
    onClick = undefined,
    className = '',
  } = props

  const icon = getIcon(item?.icon)

  const classNames = `
    ${styles.root} 
    ${variant ? styles[`root--${variant}`] : ''} 
    ${item.style ? styles[`root--${item.style}`] : ''} 
    ${notification ? styles[`root--notification`] : ''} 
    ${styles[`root--${size}`]} 
    ${className}
  `

  const getContent = () => (
    <>
      {!!badge && <span className={styles.badge}>{badge}</span>}
      {!!icon && <span className={styles.icon}>{icon}</span>}
      {!!item.name && variant !== 'icon' && <span className={styles.label}>{item.name}</span>}
    </>
  )

  return item ? (
    item.url ? (
      <WLink
        href={item.url}
        className={classNames}
        ariaLabel={variant !== 'icon' ? item.name : undefined}
        onClick={() => onClick()}
      >
        {getContent()}
      </WLink>
    ) : (
      <button
        type="button"
        onClick={onClick}
        className={classNames}
        aria-label={variant !== 'icon' ? item.name : undefined}
      >
        {getContent()}
      </button>
    )
  ) : (
    <></>
  )
}

export default HeaderMenuItem
