import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ArrowUp({ idVariant = '', className }: Props) {
  return (
    <IconBase name="arrow-up" w={24} className={className}>
      <path
        d="M13.0001 7.82843V20H11.0001V7.82843L5.63614 13.1924L4.22192 11.7782L12.0001 4L19.7783 11.7782L18.3641 13.1924L13.0001 7.82843Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ArrowUp
