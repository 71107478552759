import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { defaultProps } from './index'
import VideoToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/VideoToolbarSection'
import ImageToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'

export const BlogVideoSettings = (settingsProps) => {
  const { props } = useNode((node) => ({
    props: node.data.props,
    id: node.id,
  }))

  return (
    <>
      {!settingsProps.floating && (
        <>
          <VideoToolbarSection props={props} defaultProps={defaultProps} />
          <ImageToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const BlogVideoFloatingSettings = () =>
  React.createElement(BlogVideoSettings, { floating: true })
