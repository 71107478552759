/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import merge from 'deepmerge'

// il destructuring non funziona per process.env
const GQL_HOST = process.env.GQL_HOST
const GQL_HOST_GET = process.env.GQL_HOST_GET

let apolloClient
let prevToken

export default function createApolloClient(
  locale = null,
  initialState = null,
  jwt = null,
  cookies = null,
  graphqlGet = false,
  domain = null
) {
  const ssrMode = typeof window === 'undefined'

  const link = graphqlGet
    ? new HttpLink({
        uri: GQL_HOST_GET + `${locale ? `?lang=${locale}` : ''}`,
        credentials: 'include',
        fetchOptions: {
          method: 'GET',
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
    : createUploadLink({
        uri:
          (domain ? `https://${domain}/d/graphql/` : GQL_HOST) +
          `${locale ? `?lang=${locale}` : ''}`,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })

  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })
}

export function initializeApollo(
  locale = null,
  initialState = null,
  jwt = null,
  cookies = null,
  graphqlGet = false,
  domain = null
) {
  const _apolloClient =
    jwt !== prevToken || graphqlGet !== apolloClient.graphqlGet
      ? createApolloClient(locale, initialState, jwt, cookies, graphqlGet, domain)
      : apolloClient ?? createApolloClient(locale, initialState, jwt, cookies, graphqlGet, domain)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  prevToken = jwt

  return _apolloClient
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export function useApollo(pageProps, cookies = null, locale = null, domainLocales = []) {
  const canale = domainLocales?.find((x) => x.defaultLocale === locale)
  const jwt = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : null
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo(locale, state, jwt, cookies, false, canale?.domain),
    [locale, state, jwt, cookies]
  )
  return store
}
