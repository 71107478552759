import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function CheckboxUnmarked({ idVariant = '', className }: Props) {
  return (
    <IconBase name="checkbox-unmarked" w={24} className={className}>
      <rect x="4.5" y="4.5" width="15" height="15" stroke="currentColor" />
    </IconBase>
  )
}

export default CheckboxUnmarked
