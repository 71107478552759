import React from 'react'

function Container({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--container${className ? ' ' + className : ''}`}
    >
      <path
        d="M17.5 3.99219C18.8807 3.99219 20 5.11148 20 6.49219V10.4922C20 11.0445 19.5523 11.4922 19 11.4922C18.4477 11.4922 18 11.0445 18 10.4922V6.49219C18 6.21605 17.7761 5.99219 17.5 5.99219H6.5C6.22386 5.99219 6 6.21605 6 6.49219V10.4922C6 11.0445 5.55229 11.4922 5 11.4922C4.44771 11.4922 4 11.0445 4 10.4922V6.49219C4 5.11148 5.11929 3.99219 6.5 3.99219H17.5Z"
        fill="currentColor"
      />
      <path
        d="M18.1998 13.0364L18.1018 12.9518L18.0944 12.9463C17.7027 12.6557 17.1473 12.6877 16.7921 13.0429C16.4369 13.3981 16.4049 13.9535 16.6955 14.3452L16.701 14.3526L16.7856 14.4507L17.5859 15.25H14.3968L14.2885 15.2583L14.279 15.2598C13.8149 15.3317 13.5 15.7705 13.5 16.2499C13.5 16.7292 13.8147 17.1682 14.2788 17.2402L14.2883 17.2416L14.3966 17.25H17.5865L16.7849 18.0517L16.6919 18.1612L16.6859 18.1696C16.4338 18.5211 16.437 18.9984 16.6955 19.3468L16.701 19.3542L16.7923 19.46L16.9088 19.5589L16.9171 19.5649C17.2687 19.817 17.746 19.8138 18.0944 19.5553L18.1018 19.5498L18.1998 19.4653L20.5608 17.1068L20.6253 17.0614L20.6321 17.0558C20.8606 16.8697 21 16.5739 21 16.2509C21 15.8949 20.8301 15.5696 20.5566 15.3905L18.1998 13.0364Z"
        fill="currentColor"
      />
      <path
        d="M7.27365 14.3555L7.18896 14.4536L6.38868 15.2529H9.56824C10.099 15.2528 10.4746 15.7329 10.4746 16.2528C10.4746 16.7321 10.1599 17.171 9.69583 17.2431L9.6863 17.2445L9.57806 17.2529H6.38811L7.18254 18.0474C7.5377 18.4026 7.56975 18.958 7.27915 19.3497L7.27365 19.3571L7.18901 19.4552L7.18254 19.4616C6.82736 19.8168 6.27192 19.8488 5.88025 19.5582L5.87284 19.5527L5.77485 19.4681L3.41844 17.1143C3.14473 16.9353 2.97461 16.6099 2.97461 16.2538C2.97461 15.8978 3.14451 15.5725 3.41804 15.3934L5.76832 13.0458C6.15886 12.6553 6.79205 12.6553 7.18254 13.0458C7.53772 13.401 7.56976 13.9564 7.27915 14.3481L7.27365 14.3555Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Container
