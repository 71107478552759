import React from 'react'
import styles from './RicercaPaginaCard.module.sass'
import { Button, WLink } from '@components/atoms'
import useTrans from '@hooks/useTrans'

interface Props {
  pagina?: any
  className?: string
}

const RicercaPaginaCard = (props: Props) => {
  const { pagina = undefined, className = '' } = props

  const t = useTrans()

  return pagina ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.content}>
        {!!pagina.titolo && <h3 className={styles.title}>{pagina.titolo}</h3>}
        {!!pagina.descrizione && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
          />
        )}
      </div>
      <div className={styles.ctaBox}>
        <WLink href={pagina.url} className={styles.cta}>
          <Button label={t('Leggi di più')} variant="ghost" />
        </WLink>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RicercaPaginaCard
