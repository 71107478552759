import React from 'react'
import styles from './VideoTutorialHead.module.sass'
import { Breadcrumbs, EditableVideo, Tag, WContainer } from '@components/atoms'
import { PaginaNode, VideoTutorialNode } from '@gql/graphql'

interface Props {
  pagina?: PaginaNode
  videoTutorial?: VideoTutorialNode
  className?: string
}

const VideoTutorialHead = (props: Props) => {
  const { pagina = undefined, videoTutorial = undefined, className = '' } = props

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="blog-head">
        <div className={styles.layout}>
          <div className={styles.main}>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} className={styles.breadcrumbs} />
            <div className={styles.content}>
              <div className={styles.tags}>
                <Tag label={videoTutorial?.categoria?.nome} variant="black" />
              </div>
              {!!videoTutorial.titolo && <h1 className={styles.title}>{videoTutorial.titolo}</h1>}
              {!!videoTutorial.sottotitolo && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: videoTutorial.sottotitolo }}
                />
              )}
            </div>
          </div>
          {videoTutorial.idYoutube && (
            <div className={styles.videoBox}>
              <EditableVideo
                item={{
                  provider: 'youtube',
                  videoId: videoTutorial.idYoutube,
                  autoplay: false,
                  muted: false,
                  ratio: '16:9',
                }}
                preserveRatio
              />
            </div>
          )}
        </div>
      </WContainer>
    </div>
  )
}

export default VideoTutorialHead
