import React from 'react'
import { MenuItemType } from '@utils/types'
import { WLink } from '@components/atoms'
import styles from './PowerBarItem.module.sass'
import { handleClickCategoryTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  item?: MenuItemType
  selected?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const PowerBarItem = (props: Props) => {
  const { item = undefined, selected = false, onClick = undefined, className = '' } = props
  const classNames = `${styles.root} ${selected ? styles['root--selected'] : ''} ${className}`

  const router = useRouter()

  return item ? (
    onClick ? (
      <button type="button" onClick={onClick} className={classNames}>
        {item.name}
      </button>
    ) : item.url ? (
      <WLink
        href={item.url}
        onClick={() => handleClickCategoryTracking(item.name, router.asPath)}
        className={classNames}
      >
        {item.name}
      </WLink>
    ) : (
      <div className={classNames}>{item.name}</div>
    )
  ) : (
    <></>
  )
}

export default PowerBarItem
