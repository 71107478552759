import React, { useState } from 'react'
import { MenuItemType } from '@utils/types'
import styles from './NewsletterSection.module.sass'
import { Button, FormCheckbox, FormField, FormText, Modal, WImage } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { useNewsletterSubscriptionMutation } from '@gql/graphql'
import { Formik } from 'formik'
import { newsletterSectionValidation } from '@utils/validationSchemas'
import { newsletterEnabled } from '@utils/index'
import { useRouter } from 'next/router'
import { handleSubscribeNewsletterTracking } from '@utils/tracking'

interface Props {
  item?: MenuItemType
  variant?: '' | 'home'
  className?: string
}

const NewsletterSection = (props: Props) => {
  const { item = undefined, variant = '', className = '' } = props

  const router = useRouter()
  const currentPath = router.asPath

  if (!newsletterEnabled(router.locale)) return null

  const t = useTrans()

  const [subscribe, { loading }] = useNewsletterSubscriptionMutation()

  const [modalOpen, setModalOpen] = useState(false)
  const [esito, setEsito] = useState({ status: false, message: '' })

  return (
    <div className={`${styles.root} ${variant ? styles[`root--${variant}`] : ''} ${className}`}>
      <div className={styles.content}>
        <h2 className={styles.title}>{item.name}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: item.description }}
          className={styles.description}
        />
      </div>
      <div className={styles.main}>
        {variant === 'home' && (
          <div className={`${styles.imageBox}`}>
            {!!item.image && (
              <WImage
                src={item.image}
                maxWidth={960}
                maxHeight={230}
                objectFit="cover"
                alt={item.alt}
                title={item.alt}
                staticMedia
              />
            )}
          </div>
        )}
        <div className={styles.newsletter}>
          {variant === 'home' ? (
            <>
              <h3 className={styles.newsletterTitle}>{t('Iscriviti alla newsletter')}</h3>
              <h4 className={styles.newsletterSubtitle}>
                {t('Digita qui il tuo indirizzo e-mail e ricevi 10€ di buono')}
              </h4>
            </>
          ) : (
            <h3 className={styles.newsletterSubtitle}>
              {t('Digita qui il tuo indirizzo e-mail e ricevi 10€ di buono')}
            </h3>
          )}

          <Formik
            validationSchema={newsletterSectionValidation(t)}
            initialValues={{ email: '', privacy: false }}
            onSubmit={async ({ email }) => {
              const {
                data: {
                  newsletterSubscription: { status, message },
                },
              } = await subscribe({ variables: { email } })
              if (status) {
                await handleSubscribeNewsletterTracking(currentPath)
              }
              setEsito({ status, message })
              setModalOpen(true)
            }}
            className={styles.form}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <>
                <div className={styles.formRow}>
                  <FormField
                    id="newslettersection_emailfield"
                    layout="full"
                    className={styles.formEmail}
                    labelClassName={styles.formEmailLabel}
                    errorMessage={errors.email}
                    status={touched.email ? (errors.email ? 'error' : 'success') : null}
                  >
                    <FormText
                      id={'newslettersection_emailfield'}
                      type="email"
                      placeholder={t('Il tuo indirizzo email')}
                      onBlur={handleBlur('email')}
                      value={values.email || ''}
                      onChange={handleChange('email')}
                    />
                  </FormField>
                  <div className={styles.ctaBox}>
                    <Button
                      type="submit"
                      variant={variant === 'home' ? 'secondary' : 'primary'}
                      label={t('Ottieni lo sconto')}
                      className={styles.formSubmit}
                      loading={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
                <FormField
                  id="newslettersection_privacyfield"
                  layout="full"
                  errorMessage={errors.privacy}
                  status={touched.privacy ? (errors.privacy ? 'error' : 'success') : null}
                >
                  <FormCheckbox
                    id="newslettersection_privacyfield"
                    label={t(
                      "Avendo letto l'Informativa Privacy, esprimo il consenso al ricevimento della newsletter. Resta inteso che potrò revocare in qualunque momento il presente consenso."
                    )}
                    checked={values?.privacy}
                    onChange={handleChange('privacy')}
                  />
                </FormField>
              </>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        id={'newsletter-modal'}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={esito.status ? t('Il tuo sconto sta arrivando!') : t('Iscrizione già attiva')}
        description={esito.message}
        cta={
          <Button
            label={
              esito.status
                ? t('Esplora il mondo {0}', [process.env.BRAND])
                : t('Torna alla homepage')
            }
            href={`/`}
            onClick={() => setModalOpen(false)}
          />
        }
        className={`${styles.modal} ${className}`}
      />
    </div>
  )
}

export default NewsletterSection
