import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import useFindMenu from '@hooks/useFindMenu'
import { getIcon } from '@utils/icons'
import { ChevronDown } from '@components/icons'
import { FormField, FormSelect, Logo, WContainer, WLink } from '@components/atoms'
import styles from './Footer.module.sass'
import { useRouter } from 'next/router'
import { handleFooterTracking } from '@utils/tracking'

interface Props {
  className?: string
}

const Footer = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const router = useRouter()

  const footerMenu = useFindMenu('footer')
  const socialMenu = useFindMenu('social')
  const [menuMobileOpenItems, setMenuMobileOpenItems] = useState([])

  const getFilialiOptions = (t, router) => {
    const isStaging = process.env.ENV === 'local' || process.env.ENV === 'staging'
    const siteUrls = {
      '1': {
        it: isStaging ? 'https://efco-staging.webranking.biz/it-it/' : 'https://www.efco.it/it-it/',
        int: isStaging ? 'https://efco-staging.webranking.biz/int/' : 'https://www.myefco.com/int/',
      },
      '2': {
        it: isStaging
          ? 'https://oleomac-staging.webranking.biz/it-it/'
          : 'https://www.oleomac.it/it-it/',
        int: isStaging
          ? 'https://oleomac-int-staging.webranking.tools/'
          : 'https://www.myoleo-mac.com/int/',
        en: isStaging
          ? 'https://oleomac-staging.webranking.biz/en-gb/'
          : 'https://www.oleo-mac.co.uk/en-gb/',
        es: isStaging
          ? 'https://oleomac-staging.webranking.biz/es-es/'
          : 'https://www.oleomac.es/es-es/',
        extra: [
          { label: t('Brasile'), value: 'https://oleomacbrasil.com.br' },
          { label: t('Cina'), value: 'https://www.oleomac.cn' },
          { label: t('Francia'), value: 'https://www.oleomac.fr' },
          { label: t('Polonia'), value: 'https://www.victus.pl' },
          { label: t('Ucraina'), value: 'https://www.epi.com.ua' },
        ],
      },
      '3': {
        it: isStaging
          ? 'https://mybertolini-staging.webranking.biz/it-it/'
          : 'https://www.mybertolini.it/it-it/',
        int: isStaging
          ? 'https://mybertolini-staging.webranking.biz/int/'
          : 'https://www.mybertolini.com/int/',
        es: isStaging
          ? 'https://mybertolini-staging.webranking.biz/es-es/'
          : 'https://www.bertolini.es/es-es/',
      },
      'default': {
        it: isStaging
          ? 'https://mynibbi-staging.webranking.biz/it-it/'
          : 'https://www.mynibbi.it/it-it/',
        int: isStaging
          ? 'https://mynibbi-staging.webranking.biz/int/'
          : 'https://www.mynibbi.com/int/',
      },
    }

    const siteId = process.env.SITE_ID || 'default'
    const urls = siteUrls[siteId] || siteUrls['default']

    const filialiOptions = [
      { label: t('Italia'), value: urls.it, selected: router.locale === 'it-it' },
      { label: t('International'), value: urls.int, selected: router.locale === 'int' },
      ...(urls.en
        ? [{ label: t('Regno Unito'), value: urls.en, selected: router.locale === 'en-gb' }]
        : []),
      ...(urls.es
        ? [{ label: t('Spagna'), value: urls.es, selected: router.locale === 'es-es' }]
        : []),
      ...(urls.extra || []),
    ]

    return filialiOptions
  }

  const filialiOptions = getFilialiOptions(t, router)

  return (
    <footer className={`${styles.root} ${className}`}>
      <div className={styles.top}>
        <WContainer>
          <div className={styles.topLayout}>
            <div>
              <WLink href={'/'} className={styles.logo}>
                <Logo />
              </WLink>
            </div>
            {!!socialMenu?.children?.length && (
              <ul className={styles.socialMenu}>
                {socialMenu.children.map((x) => (
                  <li key={x.pk}>
                    <WLink
                      href={x.url}
                      className={styles.socialItem}
                      onClick={() => handleFooterTracking(x.name, 'social')}
                      newPage
                      external
                    >
                      {!!getIcon(x.icon) && (
                        <span className={styles.socialItemIcon}>{getIcon(x.icon)}</span>
                      )}
                      {!!x.name && <span className={styles.socialItemLabel}>{x.name}</span>}
                    </WLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </WContainer>
      </div>
      <div className={styles.main}>
        <WContainer>
          <div className={styles.mainLayout}>
            {footerMenu?.children.map((x, index) => (
              <div key={x.pk} className={styles.col}>
                <div
                  className={`${styles.menu} ${
                    menuMobileOpenItems.includes(x) ? styles['menu--open'] : ''
                  }`}
                >
                  <div className={styles.menuTitle}>
                    <span
                      className={`${styles.menuTitleLabel} ${styles['menuTitleLabel--desktop']}`}
                    >
                      {x.name}
                    </span>
                    <button
                      type="button"
                      aria-expanded={menuMobileOpenItems.includes(x)}
                      aria-controls={`footermenu_${x.pk}_panel`}
                      id={`footermenu_${x.pk}_trigger`}
                      onClick={() =>
                        setMenuMobileOpenItems(
                          menuMobileOpenItems.includes(x)
                            ? menuMobileOpenItems.filter((a) => a !== x)
                            : menuMobileOpenItems.concat([x])
                        )
                      }
                      className={styles.menuTitleTrigger}
                    >
                      <span
                        className={`${styles.menuTitleLabel} ${styles['menuTitleLabel--mobile']}`}
                      >
                        {x.name}
                      </span>
                      <span className={styles.menuTitleIcon}>
                        <ChevronDown />
                      </span>
                    </button>
                  </div>
                  <div
                    id={`footermenu_${x.pk}_panel`}
                    aria-labelledby={`footermenu_${x.pk}_trigger`}
                    className={styles.menuPanel}
                  >
                    <ul className={styles.menuList}>
                      {x.children?.map((y) => (
                        <li key={y.pk}>
                          <WLink
                            href={y.url}
                            onClick={() => handleFooterTracking(y.key, x.key)}
                            newPage={y.blank}
                            className={styles.menuItem}
                          >
                            {y.name}
                          </WLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {index === footerMenu.children.length - 1 && filialiOptions?.length > 0 && (
                  <div className={styles.filiali}>
                    <FormField
                      id={'footer_filiali'}
                      label={t('Scopri le nostre filiali')}
                      layout="full"
                    >
                      <FormSelect
                        id={'footer_filiali'}
                        value={filialiOptions.find((o) => o.selected)}
                        variant="dropdown"
                        options={filialiOptions}
                        onChange={(e) => {
                          window.open(e.target.value, '_blank')
                          handleFooterTracking(
                            filialiOptions.find((o) => o.value === e.target.value)?.label,
                            'filiali'
                          )
                        }}
                      />
                    </FormField>
                  </div>
                )}
              </div>
            ))}
          </div>
        </WContainer>
      </div>
      <div className={styles.bottom}>
        <WContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: t(`footer_${process.env.SITE_ID}_${router.locale}`),
              //       t(`© 1996-2024 Emak S.p.A. Via E. Fermi, 4 - 42011 Bagnolo in Piano (Reggio Emilia) ITALY
              // <br />
              // Capitale Sociale € 42.623.057,10 int. vers. - Codice Fiscale e Partita IVA 00130010358
              // Registro A.E.E. nº IT08020000000632 - Registro Pile/Accumulatori IT09060P00000161`),
            }}
          />
        </WContainer>
      </div>
    </footer>
  )
}

export default Footer
