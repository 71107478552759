import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './TextBox.module.sass'
import { getIcon } from '@utils/icons'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const TextBox = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      {(!!item.name || !!item.description) && (
        <div className={styles.content}>
          <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
          <EditableDescription
            description={item.description}
            className={styles.description}
            editMode={editMode}
          />
        </div>
      )}
      {!!item.url && !!item.ctaLabel && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel}
            href={item.url}
            newPage={item.blank}
            theme="dark"
            size="lg"
            variant={item.variant || 'primary'}
            color={item.variant === 'ghost' ? 'white' : ''}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default TextBox
