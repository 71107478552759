import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LogoEfco({ idVariant = '', className }: Props) {
  return (
    <IconBase name="logo-efco" w={200} h={70} className={className}>
      <path
        d="M3.63636 61.8861C3.63636 59.4237 5.09556 57.6453 7.69476 57.6453C10.294 57.6453 11.7531 59.4237 11.7531 61.8861C11.7531 64.3485 10.294 66.1269 7.69476 66.1269C5.09556 66.1269 3.63636 64.3485 3.63636 61.8861ZM9.97475 61.8861C9.97475 60.5181 9.29075 59.0589 7.69476 59.0589C6.09876 59.0589 5.41476 60.5181 5.41476 61.8861C5.41476 63.2997 6.09876 64.7133 7.69476 64.7133C9.29075 64.7133 9.97475 63.2541 9.97475 61.8861Z"
        fill="black"
      />
      <path
        d="M20.1435 65.899H18.4107V64.759H18.3651C17.9091 65.5798 16.9515 66.0814 16.0851 66.0814C13.9875 66.0814 13.0755 65.0326 13.0755 62.935V57.8734H14.8539V62.7982C14.8539 64.2118 15.4467 64.7134 16.4043 64.7134C17.8635 64.7134 18.3651 63.7558 18.3651 62.5246V57.8734H20.1435V65.899Z"
        fill="black"
      />
      <path
        d="M21.8764 57.8733H23.518V59.4237H23.5636C23.746 58.5573 24.8404 57.6453 25.8892 57.6453C26.2996 57.6453 26.3908 57.6909 26.5732 57.6909V59.4237C26.3452 59.3781 26.0716 59.3325 25.798 59.3325C24.5668 59.3325 23.6092 60.2901 23.6092 62.0685V65.8989H21.8308V57.8733H21.8764Z"
        fill="black"
      />
      <path
        d="M31.2699 57.8279H34.2339V58.6943H34.2795C34.6899 58.1927 35.1459 57.5999 36.6507 57.5999C38.9307 57.5999 40.0251 59.8343 40.0251 61.8863C40.0251 63.8015 39.2955 66.0814 36.6051 66.0814C35.6931 66.0814 34.8723 65.6711 34.4163 64.9871H34.3707V68.4982H31.2699V57.8279ZM35.6019 63.8471C36.6963 63.8471 36.9699 62.7982 36.9699 61.8407C36.9699 60.8831 36.7419 59.8343 35.6019 59.8343C34.5075 59.8343 34.2339 60.8831 34.2339 61.8407C34.2795 62.7982 34.5075 63.8471 35.6019 63.8471Z"
        fill="black"
      />
      <path
        d="M45.2691 66.0814C42.2139 66.0814 40.8459 64.075 40.8459 61.8406C40.8459 59.6063 42.2139 57.5999 45.2691 57.5999C48.3243 57.5999 49.6923 59.6063 49.6923 61.8406C49.6923 64.075 48.3243 66.0814 45.2691 66.0814ZM45.2691 59.8799C44.0835 59.8799 43.9467 61.0199 43.9467 61.8863C43.9467 62.7526 44.0835 63.8926 45.2691 63.8926C46.4547 63.8926 46.5915 62.7526 46.5915 61.8863C46.5915 60.9743 46.4547 59.8799 45.2691 59.8799Z"
        fill="black"
      />
      <path
        d="M61.1378 65.8991H57.9914L56.8058 60.9287H56.7602L55.5746 65.8991H52.4282L49.9202 57.8279H53.1122L54.2978 62.7983H54.3434L55.3466 57.8279H58.265L59.3138 62.7983H59.3594L60.4994 57.8279H63.6914L61.1378 65.8991Z"
        fill="black"
      />
      <path
        d="M66.8834 62.5701C66.9746 63.4821 67.4762 64.1205 68.4338 64.1205C68.9354 64.1205 69.4826 63.9381 69.7106 63.5277H72.5834C71.945 65.2605 70.2578 66.0813 68.4338 66.0813C65.8346 66.0813 63.9194 64.5765 63.9194 61.8861C63.9194 59.5605 65.6522 57.6453 68.1602 57.6453C71.3522 57.6453 72.7658 59.4237 72.7658 62.5701H66.8834ZM69.8474 60.9285C69.8474 60.1989 69.3002 59.5605 68.4794 59.5605C67.5674 59.5605 67.0202 60.0621 66.8834 60.9285H69.8474Z"
        fill="black"
      />
      <path
        d="M73.9515 57.8279H76.9155V59.1047H76.9611C77.4627 58.1471 78.1011 57.5999 79.2411 57.5999C79.5603 57.5999 79.8339 57.6455 80.1531 57.7367V60.4727C79.8339 60.3815 79.5603 60.2903 78.9219 60.2903C77.6907 60.2903 77.0523 61.0198 77.0523 62.7526V65.9446H73.9515V57.8279Z"
        fill="black"
      />
      <path
        d="M81.0652 63.8015H83.2084V65.8991C83.2084 67.1758 82.342 68.0878 81.1108 68.3614V67.4038C81.7948 67.2214 82.114 66.583 82.114 65.8991H81.0196V63.8015H81.0652Z"
        fill="black"
      />
      <path
        d="M88.498 57.8734H90.4588L92.5564 63.8926H92.602L94.654 57.8734H96.5236L93.3772 66.355C92.7844 67.8142 92.374 69.091 90.55 69.091C90.1396 69.091 89.7292 69.0453 89.3188 68.9997V67.495C89.5924 67.5406 89.866 67.5862 90.1396 67.5862C90.9604 67.5862 91.1884 67.1302 91.3708 66.4462L91.5532 65.8078L88.498 57.8734Z"
        fill="black"
      />
      <path
        d="M96.9338 61.8861C96.9338 59.4237 98.393 57.6453 100.992 57.6453C103.591 57.6453 105.051 59.4237 105.051 61.8861C105.051 64.3485 103.591 66.1269 100.992 66.1269C98.4386 66.1269 96.9338 64.3485 96.9338 61.8861ZM103.318 61.8861C103.318 60.5181 102.634 59.0589 101.038 59.0589C99.4418 59.0589 98.7578 60.5181 98.7578 61.8861C98.7578 63.2997 99.4418 64.7133 101.038 64.7133C102.588 64.7133 103.318 63.2541 103.318 61.8861Z"
        fill="black"
      />
      <path
        d="M113.487 65.899H111.754V64.759H111.708C111.252 65.5798 110.295 66.0814 109.428 66.0814C107.331 66.0814 106.419 65.0326 106.419 62.935V57.8734H108.197V62.7982C108.197 64.2118 108.79 64.7134 109.747 64.7134C111.207 64.7134 111.708 63.7558 111.708 62.5246V57.8734H113.487V65.899Z"
        fill="black"
      />
      <path
        d="M115.174 57.8733H116.815V59.4237H116.861C117.043 58.5573 118.138 57.6453 119.187 57.6453C119.597 57.6453 119.688 57.6909 119.871 57.6909V59.4237C119.643 59.3781 119.369 59.3325 119.095 59.3325C117.864 59.3325 116.907 60.2901 116.907 62.0685V65.8989H115.128V57.8733H115.174Z"
        fill="black"
      />
      <path
        d="M124.613 57.8279H127.577V58.6943H127.623C128.033 58.1927 128.489 57.5999 129.994 57.5999C132.274 57.5999 133.368 59.8343 133.368 61.8863C133.368 63.8015 132.639 66.0814 129.948 66.0814C129.036 66.0814 128.215 65.6711 127.759 64.9871H127.714V68.4982H124.613V57.8279ZM128.945 63.8471C130.039 63.8471 130.313 62.7982 130.313 61.8407C130.313 60.8831 130.085 59.8343 128.945 59.8343C127.851 59.8343 127.577 60.8831 127.577 61.8407C127.577 62.7982 127.851 63.8471 128.945 63.8471Z"
        fill="black"
      />
      <path
        d="M142.443 64.075C142.443 64.6678 142.488 65.3518 142.808 65.899H139.661C139.57 65.671 139.524 65.3518 139.57 65.1238H139.524C138.886 65.8534 137.928 66.0814 136.925 66.0814C135.375 66.0814 134.098 65.3518 134.098 63.6191C134.098 61.0655 137.062 61.1567 138.658 60.8831C139.068 60.7919 139.479 60.7007 139.479 60.1535C139.479 59.6063 138.977 59.4239 138.476 59.4239C137.518 59.4239 137.336 59.9255 137.336 60.2447H134.463C134.554 57.9647 136.697 57.5999 138.612 57.5999C142.488 57.5999 142.443 59.1959 142.443 60.7463V64.075ZM139.479 62.2511C139.114 62.4335 138.704 62.5246 138.248 62.6614C137.564 62.7982 137.199 62.9806 137.199 63.5278C137.199 63.8926 137.609 64.2574 138.156 64.2574C138.84 64.2574 139.388 63.847 139.433 62.9806V62.2511H139.479Z"
        fill="black"
      />
      <path
        d="M148.599 60.1535C148.599 59.8799 148.462 59.6975 148.234 59.5607C148.051 59.4239 147.778 59.3327 147.55 59.3327C147.094 59.3327 146.638 59.4239 146.638 59.9711C146.638 60.1991 146.82 60.3359 147.003 60.3815C147.504 60.6095 148.69 60.655 149.693 61.0198C150.742 61.339 151.608 61.9319 151.608 63.2542C151.608 65.4886 149.465 66.0814 147.504 66.0814C145.589 66.0814 143.537 65.3062 143.491 63.2542H146.41C146.41 63.5278 146.547 63.7558 146.775 63.9838C146.911 64.1206 147.231 64.2118 147.595 64.2118C148.006 64.2118 148.644 64.075 148.644 63.5734C148.644 63.1174 148.371 62.9807 146.957 62.7071C144.631 62.2967 143.674 61.5671 143.674 60.1535C143.674 58.1015 145.863 57.5999 147.504 57.5999C149.237 57.5999 151.335 58.1015 151.426 60.1535H148.599Z"
        fill="black"
      />
      <path
        d="M157.354 60.1535C157.354 59.8799 157.217 59.6975 156.989 59.5607C156.807 59.4239 156.533 59.3327 156.305 59.3327C155.849 59.3327 155.393 59.4239 155.393 59.9711C155.393 60.1991 155.575 60.3359 155.758 60.3815C156.259 60.6095 157.445 60.655 158.448 61.0198C159.497 61.339 160.363 61.9319 160.363 63.2542C160.363 65.4886 158.22 66.0814 156.259 66.0814C154.344 66.0814 152.292 65.3062 152.247 63.2542H155.165C155.165 63.5278 155.302 63.7558 155.53 63.9838C155.667 64.1206 155.986 64.2118 156.351 64.2118C156.761 64.2118 157.399 64.075 157.399 63.5734C157.399 63.1174 157.126 62.9807 155.712 62.7071C153.387 62.2967 152.429 61.5671 152.429 60.1535C152.429 58.1015 154.618 57.5999 156.259 57.5999C157.992 57.5999 160.09 58.1015 160.181 60.1535H157.354Z"
        fill="black"
      />
      <path
        d="M164.65 56.9613H161.549V54.8181H164.65V56.9613ZM161.549 57.8277H164.65V65.8989H161.549V57.8277Z"
        fill="black"
      />
      <path
        d="M170.304 66.0814C167.295 66.0814 165.881 64.075 165.881 61.8406C165.881 59.6063 167.249 57.5999 170.304 57.5999C173.359 57.5999 174.727 59.6063 174.727 61.8406C174.727 64.075 173.359 66.0814 170.304 66.0814ZM170.304 59.8799C169.119 59.8799 168.982 61.0199 168.982 61.8863C168.982 62.7526 169.119 63.8926 170.304 63.8926C171.49 63.8926 171.627 62.7526 171.627 61.8863C171.627 60.9743 171.49 59.8799 170.304 59.8799Z"
        fill="black"
      />
      <path
        d="M175.913 57.8279H178.877V58.8311H178.923C179.515 58.0103 180.336 57.5999 181.567 57.5999C183.027 57.5999 184.349 58.4663 184.349 60.3359V65.8534H181.248V61.6127C181.248 60.7007 181.157 60.0167 180.245 60.0167C179.698 60.0167 179.014 60.2903 179.014 61.5671V65.8078H175.913V57.8279Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.989 14.5534L45.6794 12.7294L46.7738 8.3518C46.7738 8.3518 48.1874 1.96781 54.7994 2.19581H58.5842L61.4114 0.00701289H54.7994C54.7994 0.00701289 46.7282 -0.540187 44.6763 8.0326L42.989 14.5534ZM24.7035 29.0998L27.3027 27.1846L32.1819 8.3518C32.1819 8.3518 33.5955 1.96781 40.2075 2.19581H46.2722L46.7738 0.00701289H40.1619C40.1619 0.00701289 32.0907 -0.540187 30.0387 8.0326L24.7035 29.0998ZM6.5547 43.6917L9.3363 41.6398L17.8635 8.3518C17.8635 8.3518 19.2771 1.96781 25.9347 2.19581H31.9995L32.5011 0.00701289H25.8891C25.8891 0.00701289 17.8179 -0.540187 15.7203 8.0326L6.5547 43.6917ZM44.2203 16.195H51.0146C51.0146 16.195 58.4474 16.9702 60.8642 8.671L62.7794 1.42061L44.2203 16.195ZM26.1171 30.8326H48.3698C48.3698 30.8326 55.073 31.015 57.3074 23.5822L58.6754 18.7942H41.0283L26.1171 30.8326ZM7.8315 45.3333H42.8523C42.8523 45.3333 51.5162 45.8806 53.6138 37.8094L54.9362 33.1126H23.0619L7.8315 45.3333Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0482 25.9991C90.413 26.6375 91.1426 27.1391 92.465 27.2303C94.7906 27.3671 95.9762 26.5463 96.5234 25.9991H104.503C103.455 27.5951 99.7154 32.2919 91.8722 32.2919C83.801 32.2919 81.9314 28.8719 81.5666 25.9991H90.0482ZM124.932 25.9991C125.388 28.7351 127.212 32.2919 133.642 32.3831C141.987 32.5655 145.817 28.5527 147.458 25.9991H138.612C137.928 26.4095 136.879 26.7287 135.466 26.6831C134.691 26.6375 134.143 26.3639 133.824 25.9991H124.932ZM165.835 25.9991C165.106 26.5463 164.194 26.9567 163.099 26.9111C162.142 26.8655 161.503 26.5463 161.138 25.9991H152.474C152.794 28.8719 154.344 31.8359 159.132 32.3375C168.617 33.3407 172.219 29.4191 173.815 27.6407C174.271 27.1391 174.682 26.5919 175.001 25.9991H165.835Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.5211 25.9989C81.4755 25.5429 81.4299 25.0869 81.4299 24.6765C81.4299 21.7125 84.4851 10.2213 97.0707 10.2669C109.976 10.3125 106.191 23.0805 106.191 23.1261C106.191 23.1717 89.8659 23.1261 89.8659 23.1261C89.8659 23.1261 89.2731 24.7677 90.0027 25.9533H81.5211V25.9989ZM96.5235 25.9989C96.7971 25.7253 96.8883 25.4973 96.8883 25.4973H104.823C104.823 25.4973 104.732 25.6797 104.504 25.9989H96.5235ZM133.779 25.9989C132.867 24.9957 133.095 23.2173 133.095 23.2173C133.095 23.2173 134.098 16.0125 138.703 16.0125C142.215 16.0125 141.303 18.7029 141.303 18.7029H149.739C149.739 18.7029 151.927 9.99329 139.707 10.2669C127.486 10.5405 124.932 22.8069 124.932 22.8069C124.932 22.8069 124.659 24.2205 124.932 25.9989H133.779ZM147.459 25.9989C148.234 24.8133 148.462 23.9013 148.462 23.9013H140.208C140.208 23.9013 139.935 25.1781 138.567 25.9989H147.459ZM152.475 25.9989C152.155 23.1717 153.022 20.4357 153.022 20.4357C153.022 20.4357 156.214 10.5405 167.067 10.5405C177.919 10.5405 177.235 17.2437 177.19 18.9309C177.144 20.7549 176.46 23.6277 175.047 25.9989H165.881C168.07 24.2661 168.708 20.8005 168.708 20.8005C168.708 20.8005 169.803 16.0125 166.428 15.9669C162.689 15.8757 161.184 20.6637 161.184 20.6637C161.184 20.6637 159.953 24.3117 161.184 26.0445H152.475V25.9989ZM90.9603 19.0221H98.9403C98.9403 19.0221 99.5331 15.3741 95.8851 15.3285C91.7355 15.2829 91.0059 18.9309 91.0059 18.9309L90.9603 19.0221Z"
        fill="black"
      />
      <path
        d="M115.675 6.43657H75.7298C75.7298 6.43657 75.0458 6.34537 74.8178 7.07497C73.085 13.687 67.5674 34.6629 67.5674 34.6629C67.5674 34.6629 67.2482 35.8941 69.1178 35.8941H180.929C180.929 35.8941 183.072 36.1221 183.574 34.2069C185.671 26.1357 190.55 7.85017 190.55 7.85017C190.687 7.34857 190.505 6.39097 189.228 6.39097H124.978L125.297 5.25098L193.56 5.20538C193.56 5.20538 195.293 5.15978 194.974 6.80137C194.654 8.44297 186.538 38.9493 186.538 38.9493C186.538 38.9493 186.264 39.9525 184.668 39.9525H66.1538C66.1538 39.9525 65.0594 39.9525 65.4242 38.6301C65.789 37.3077 73.8602 6.11738 73.8602 6.11738C73.8602 6.11738 74.225 5.20538 75.4106 5.20538C76.6874 5.20538 104.959 5.20538 116.542 5.20538C119.323 2.01338 123.427 2.19578 123.427 2.19578H127.759L126.209 7.30297C123.519 7.30297 122.379 9.35497 121.923 10.723C121.695 11.407 121.421 12.9118 121.421 12.9118H124.978L123.701 17.7454H120.235L116.177 32.2005H107.923L111.982 17.8366H109.018L110.34 12.9118H113.167L114.991 7.85017L115.584 6.71018L115.675 6.43657Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.434 12.5925V11.4525H183.209C183.619 11.4525 184.03 11.5437 184.03 11.9997C184.03 12.5469 183.619 12.5925 183.118 12.5925H182.434ZM182.434 13.0941H183.072L184.075 14.6901H184.714L183.665 13.0485C184.212 13.0029 184.623 12.6837 184.623 12.0453C184.623 11.3157 184.212 10.9965 183.3 10.9965H181.887V14.7357H182.434V13.0941ZM180.473 12.8205C180.473 11.2701 181.613 10.1301 183.118 10.1301C184.577 10.1301 185.717 11.2701 185.717 12.8205C185.717 14.3709 184.577 15.5109 183.118 15.5109C181.613 15.5109 180.473 14.3709 180.473 12.8205ZM183.118 16.0581C184.851 16.0581 186.355 14.6901 186.355 12.8205C186.355 10.9509 184.851 9.5829 183.118 9.5829C181.339 9.5829 179.835 10.9509 179.835 12.8205C179.835 14.6901 181.339 16.0581 183.118 16.0581Z"
        fill="black"
      />
    </IconBase>
  )
}

export default LogoEfco
