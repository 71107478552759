import React from 'react'
import Link from 'next/link'
import { isExternalDomain, isFileDownloadUrl } from 'utils'
import { useRouter } from 'next/router'

interface Props {
  href?: string
  external?: boolean
  newPage?: boolean
  prefetch?: boolean
  title?: string
  focusable?: boolean
  ariaLabel?: string
  role?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  handleTracking?: (ty?: string, c?: string, t?: string, cta?: string) => void
  className?: string
  children?: any
}

const WLink = (props: Props) => {
  const {
    href = null,
    title = undefined,
    focusable = true,
    ariaLabel = undefined,
    role = undefined,
    newPage = false,
    prefetch = true,
    onClick = undefined,
    className = '',
    children = null,
  } = props

  const router = useRouter()

  const isFileDownload = isFileDownloadUrl(href)
  const isExternal = isExternalDomain(href)

  return isFileDownload || isExternal ? (
    <a
      href={href || '/'}
      className={className}
      target={newPage ? '_blank' : undefined}
      rel={newPage || isExternal ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      role={role}
      tabIndex={focusable ? undefined : -1}
    >
      {children}
    </a>
  ) : (
    <Link
      href={href || '/'}
      className={className}
      target={newPage ? '_blank' : undefined}
      rel={newPage ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      role={role}
      tabIndex={focusable ? undefined : -1}
      {...(!prefetch || router.isPreview ? { prefetch: false } : {})}
    >
      {children}
    </Link>
  )
}

export default WLink
