import React, { useContext, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { ProdottoNode } from '@gql/graphql'
import useProdotti from '@hooks/useProdotti'
import { ProdottoCard } from '@components/molecules'
import styles from './SliderProdotti.module.sass'
import { handleProductImpressionTracking } from '@utils/tracking'
import { CartContext } from '@utils/cartContext'

interface Props {
  prodotti?: ProdottoNode[]
  navigation?: boolean
  correlati: boolean
  pagination?: boolean
  className?: string
}

const SliderProdotti = (props: Props) => {
  const {
    prodotti: items = [],
    navigation = false,
    correlati,
    pagination = false,
    className = '',
  } = props

  const { prodotti, loading } = useProdotti(items)
  const { cart } = useContext(CartContext)

  useEffect(() => {
    if (cart && prodotti) {
      handleProductImpressionTracking(prodotti, cart)
    }
  }, [])

  return prodotti?.length ? (
    <Swiper
      slidesPerView={1.1}
      modules={[A11y].concat(navigation ? [Navigation] : []).concat(pagination ? [Pagination] : [])}
      navigation={navigation}
      pagination={pagination ? { type: 'progressbar' } : undefined}
      watchOverflow
      centerInsufficientSlides
      breakpoints={{
        768: {
          slidesPerView: 2.1,
        },
        992: {
          slidesPerView: 3.1,
        },
        1199: {
          slidesPerView: 4,
        },
      }}
      className={`${styles.root} ${navigation ? 'swiper--bottomNav' : ''} ${className}`}
    >
      {prodotti.map((prodotto) => (
        <SwiperSlide key={prodotto.pk}>
          <ProdottoCard
            correlati={correlati ?? false}
            prodotto={prodotto}
            className={styles.item}
            inSlider
            loading={loading}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <></>
  )
}

export default SliderProdotti
