import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LogoNibbi({ idVariant = '', className }: Props) {
  return (
    <IconBase name="logo-nibbi" w={150} h={71} className={className}>
      <path
        d="M1.00044 64.4783C1.00044 62.5639 2.13165 61.2152 4.13303 61.2152C6.1344 61.2152 7.26562 62.6074 7.26562 64.4783C7.26562 66.3926 6.1344 67.7849 4.13303 67.7849C2.17516 67.7849 1.00044 66.3926 1.00044 64.4783ZM5.91686 64.4783C5.91686 63.3906 5.39476 62.3029 4.13303 62.3029C2.9148 62.3029 2.3492 63.4341 2.3492 64.4783C2.3492 65.566 2.87129 66.6972 4.13303 66.6972C5.39476 66.6972 5.91686 65.566 5.91686 64.4783Z"
        fill="black"
      />
      <path
        d="M13.7918 67.611H12.443V66.7408H12.3995C12.0514 67.3499 11.3118 67.785 10.6157 67.785C9.00586 67.785 8.30973 66.9583 8.30973 65.3485V61.3893H9.70199V65.218C9.70199 66.3057 10.1371 66.6973 10.8767 66.6973C12.0079 66.6973 12.3995 65.9577 12.3995 65.0005V61.3893H13.7918V67.611Z"
        fill="black"
      />
      <path
        d="M15.1405 61.3892H16.4458V62.6074H16.4893C16.6633 61.9548 17.4465 61.2152 18.3166 61.2152C18.6212 61.2152 18.7082 61.2587 18.8387 61.2587V62.5639C18.6647 62.5204 18.4472 62.5204 18.2296 62.5204C17.2724 62.5204 16.5328 63.26 16.5328 64.6523V67.6109H15.1405V61.3892Z"
        fill="black"
      />
      <path
        d="M22.4501 61.3457H24.756V62.0418H24.7995C25.1041 61.6502 25.4521 61.2152 26.6268 61.2152C28.4107 61.2152 29.2373 62.9555 29.2373 64.5218C29.2373 66.0011 28.6717 67.7849 26.5833 67.7849C25.8872 67.7849 25.2346 67.4803 24.8865 66.9582H24.843V69.6557H22.4501V61.3457ZM25.8002 66.0446C26.6703 66.0446 26.8444 65.2179 26.8444 64.5218C26.8444 63.7821 26.6703 62.9555 25.8002 62.9555C24.93 62.9555 24.756 63.7821 24.756 64.5218C24.756 65.2179 24.93 66.0446 25.8002 66.0446Z"
        fill="black"
      />
      <path
        d="M33.2834 67.7849C30.934 67.7849 29.8898 66.2186 29.8898 64.4783C29.8898 62.7379 30.934 61.2152 33.2834 61.2152C35.6328 61.2152 36.677 62.7379 36.677 64.4783C36.7205 66.2186 35.6328 67.7849 33.2834 67.7849ZM33.2834 62.9555C32.3697 62.9555 32.2392 63.8257 32.2392 64.4783C32.2392 65.1309 32.3697 66.0446 33.2834 66.0446C34.1971 66.0446 34.3276 65.1744 34.3276 64.4783C34.3276 63.8257 34.1971 62.9555 33.2834 62.9555Z"
        fill="black"
      />
      <path
        d="M45.5529 67.6108H43.1164L42.2027 63.7821L41.2891 67.6108H38.8091L36.8512 61.3456H39.3747L40.2884 65.2178L41.115 61.3456H43.334L44.1606 65.2178H44.2041L45.0743 61.3456H47.5542L45.5529 67.6108Z"
        fill="black"
      />
      <path
        d="M50.034 65.0006C50.0775 65.6968 50.5126 66.2188 51.2523 66.2188C51.6438 66.2188 52.0789 66.0883 52.2529 65.7838H54.4719C53.9933 67.1325 52.688 67.7416 51.2523 67.7416C49.2509 67.7416 47.7281 66.5669 47.7281 64.4785C47.7281 62.6512 49.0333 61.1719 51.0347 61.1719C53.5147 61.1719 54.6024 62.5642 54.6024 65.0006H50.034ZM52.2965 63.7824C52.2965 63.2168 51.8614 62.7382 51.2523 62.7382C50.5561 62.7382 50.121 63.1298 50.034 63.7824H52.2965Z"
        fill="black"
      />
      <path
        d="M55.4726 61.3459H57.7786V62.3466H57.822C58.2136 61.607 58.6922 61.1719 59.5624 61.1719C59.8234 61.1719 60.041 61.2154 60.2585 61.2589V63.3473C59.9975 63.2603 59.7799 63.1733 59.3013 63.1733C58.3442 63.1733 57.8656 63.7389 57.8656 65.0441V67.5241H55.4726V61.3459Z"
        fill="black"
      />
      <path
        d="M60.9982 66.0012H62.6515V67.611C62.6515 68.6117 61.9554 69.3078 61.0417 69.5254V68.7857C61.5638 68.6552 61.8249 68.1331 61.8249 67.611H60.9982V66.0012Z"
        fill="black"
      />
      <path
        d="M66.7847 61.3893H68.3075L69.9173 66.0447H69.9608L71.5271 61.3893H72.9629L70.5264 67.959C70.0913 69.0902 69.7433 70.0909 68.3075 70.0909C68.0029 70.0909 67.6549 70.0474 67.3503 70.0039V68.8727C67.5679 68.9162 67.7854 68.9597 68.0029 68.9597C68.6556 68.9597 68.7861 68.6116 68.9601 68.0895L69.1341 67.611L66.7847 61.3893Z"
        fill="black"
      />
      <path
        d="M73.3109 64.4783C73.3109 62.5639 74.4422 61.2152 76.4435 61.2152C78.4449 61.2152 79.5761 62.6074 79.5761 64.4783C79.5761 66.3926 78.4449 67.7849 76.4435 67.7849C74.4422 67.7849 73.3109 66.3926 73.3109 64.4783ZM78.2274 64.4783C78.2274 63.3906 77.7053 62.3029 76.4435 62.3029C75.2253 62.3029 74.6597 63.4341 74.6597 64.4783C74.6597 65.566 75.1818 66.6972 76.4435 66.6972C77.7053 66.6972 78.2274 65.566 78.2274 64.4783Z"
        fill="black"
      />
      <path
        d="M86.1022 67.611H84.7535V66.7408H84.71C84.3619 67.3499 83.6223 67.785 82.9262 67.785C81.3164 67.785 80.6202 66.9583 80.6202 65.3485V61.3893H82.0125V65.218C82.0125 66.3057 82.4476 66.6973 83.1872 66.6973C84.3184 66.6973 84.71 65.9577 84.71 65.0005V61.3893H86.1022V67.611Z"
        fill="black"
      />
      <path
        d="M87.451 61.3892H88.7563V62.6074H88.7998C88.9738 61.9548 89.757 61.2152 90.6271 61.2152C90.9317 61.2152 91.0187 61.2587 91.1492 61.2587V62.5639C90.9752 62.5204 90.7577 62.5204 90.5401 62.5204C89.5829 62.5204 88.8433 63.26 88.8433 64.6523V67.6109H87.451V61.3892Z"
        fill="black"
      />
      <path
        d="M94.7606 61.3457H97.0665V62.0418H97.11C97.4146 61.6502 97.7626 61.2152 98.9373 61.2152C100.721 61.2152 101.548 62.9555 101.548 64.5218C101.548 66.0011 100.982 67.7849 98.8938 67.7849C98.1977 67.7849 97.5451 67.4803 97.197 66.9582H97.1535V69.6557H94.7606V61.3457ZM98.1107 66.0446C98.9808 66.0446 99.1549 65.2179 99.1549 64.5218C99.1549 63.7821 98.9808 62.9555 98.1107 62.9555C97.2405 62.9555 97.0665 63.7821 97.0665 64.5218C97.0665 65.2179 97.2405 66.0446 98.1107 66.0446Z"
        fill="black"
      />
      <path
        d="M108.553 66.2186C108.553 66.6972 108.596 67.2193 108.857 67.6109H106.421C106.377 67.4368 106.334 67.1758 106.334 67.0017H106.29C105.768 67.5674 105.072 67.7414 104.289 67.7414C103.071 67.7414 102.07 67.1758 102.07 65.8705C102.07 63.9127 104.376 63.9562 105.594 63.7386C105.899 63.6951 106.247 63.5646 106.247 63.173C106.247 62.7379 105.855 62.6074 105.463 62.6074C104.724 62.6074 104.593 62.999 104.593 63.2601H102.374C102.461 61.5197 104.115 61.2152 105.594 61.2152C108.596 61.2152 108.553 62.4769 108.553 63.6516V66.2186ZM106.247 64.7828C105.942 64.9134 105.638 65.0004 105.289 65.0874C104.767 65.2179 104.463 65.3484 104.463 65.7835C104.463 66.0881 104.767 66.3491 105.202 66.3491C105.725 66.3491 106.16 66.0446 106.203 65.3919V64.7828H106.247Z"
        fill="black"
      />
      <path
        d="M113.339 63.1733C113.339 62.9557 113.208 62.8252 113.078 62.6947C112.947 62.5642 112.73 62.5207 112.555 62.5207C112.207 62.5207 111.816 62.6077 111.816 62.9992C111.816 63.1733 111.946 63.2603 112.077 63.3038C112.468 63.4778 113.382 63.5213 114.165 63.7824C114.948 64.0434 115.645 64.522 115.645 65.5227C115.645 67.263 113.991 67.7416 112.468 67.7416C110.989 67.7416 109.379 67.1325 109.336 65.5227H111.598C111.598 65.7403 111.685 65.9143 111.903 66.0883C112.033 66.1753 112.251 66.2624 112.555 66.2624C112.86 66.2624 113.382 66.1318 113.382 65.7838C113.382 65.4357 113.165 65.3052 112.077 65.1311C110.25 64.8266 109.51 64.2175 109.51 63.1733C109.51 61.607 111.207 61.1719 112.468 61.1719C113.817 61.1719 115.427 61.5635 115.514 63.1298H113.339V63.1733Z"
        fill="black"
      />
      <path
        d="M120.126 63.1733C120.126 62.9557 119.995 62.8252 119.865 62.6947C119.734 62.5642 119.517 62.5207 119.343 62.5207C118.995 62.5207 118.603 62.6077 118.603 62.9992C118.603 63.1733 118.733 63.2603 118.864 63.3038C119.256 63.4778 120.169 63.5213 120.952 63.7824C121.736 64.0434 122.432 64.522 122.432 65.5227C122.432 67.263 120.778 67.7416 119.256 67.7416C117.776 67.7416 116.166 67.1325 116.123 65.5227H118.385C118.385 65.7403 118.472 65.9143 118.69 66.0883C118.821 66.1753 119.038 66.2624 119.343 66.2624C119.647 66.2624 120.169 66.1318 120.169 65.7838C120.169 65.4357 119.952 65.3052 118.864 65.1311C117.037 64.8266 116.297 64.2175 116.297 63.1733C116.297 61.607 117.994 61.1719 119.256 61.1719C120.604 61.1719 122.214 61.5635 122.301 63.1298H120.126V63.1733Z"
        fill="black"
      />
      <path
        d="M125.738 60.6933H123.345V59.04H125.738V60.6933ZM123.389 61.3459H125.782V67.6111H123.389V61.3459Z"
        fill="black"
      />
      <path
        d="M130.133 67.7849C127.783 67.7849 126.739 66.2186 126.739 64.4783C126.739 62.7379 127.783 61.2152 130.133 61.2152C132.482 61.2152 133.526 62.7379 133.526 64.4783C133.57 66.2186 132.482 67.7849 130.133 67.7849ZM130.133 62.9555C129.219 62.9555 129.088 63.8257 129.088 64.4783C129.088 65.1309 129.219 66.0446 130.133 66.0446C131.046 66.0446 131.177 65.1744 131.177 64.4783C131.177 63.8257 131.046 62.9555 130.133 62.9555Z"
        fill="black"
      />
      <path
        d="M134.483 61.3459H136.789V62.1291H136.833C137.268 61.52 137.92 61.1719 138.878 61.1719C140.009 61.1719 141.009 61.868 141.009 63.3038V67.6111H138.617V64.348C138.617 63.6084 138.53 63.1298 137.833 63.1298C137.442 63.1298 136.876 63.3473 136.876 64.348V67.6546H134.483V61.3459Z"
        fill="black"
      />
      <path
        d="M26.9748 52.0351C13.0957 52.0351 1.87065 40.81 1.87065 26.9309C1.87065 13.0518 13.0957 1.82674 26.9748 1.82674C40.8539 1.82674 52.079 13.0518 52.079 26.9309C52.1225 40.81 40.8539 52.0351 26.9748 52.0351Z"
        fill="#E30613"
      />
      <path
        d="M52.9493 26.9308C52.9493 41.245 41.3326 52.8617 27.0185 52.8617C12.7043 52.8617 1.08762 41.245 1.08762 26.9308C1.08762 12.6167 12.7043 1 27.0185 1C41.3326 1 52.9493 12.6167 52.9493 26.9308Z"
        stroke="black"
        strokeWidth="0.687"
        strokeMiterlimit="3.864"
      />
      <path
        d="M20.1439 40.8535L26.2351 20.1002C26.9747 17.6202 24.1032 15.0532 23.4506 16.9676L16.2717 40.897C12.2255 40.8535 12.0514 40.8535 7.35256 40.897L15.8802 12.9213H31.5866C36.8946 13.7915 35.4153 19.23 35.4153 19.23L33.5445 25.3211H36.6335L38.5914 18.7949C39.157 16.8371 36.6335 15.0532 36.1549 16.4455L36.1114 16.2714C35.8504 15.0097 35.1978 13.7045 33.6315 12.9213H41.3324C44.2039 13.0083 46.5534 12.2687 47.38 16.489C47.8586 19.6216 46.7709 22.6671 44.9871 24.9296C43.7689 26.0608 42.3331 26.5394 40.8103 26.9744C42.4201 27.3225 45.9008 31.4123 41.0279 38.6346C40.0707 40.0269 38.8089 40.549 37.3297 40.8535C36.677 40.8535 20.1439 40.8535 20.1439 40.8535ZM32.7613 38.5041L35.9809 27.6706H32.8483L29.4982 38.5041H32.7613Z"
        fill="white"
      />
      <path
        d="M123.563 25.3646H126.739L128.697 18.8384C129.263 16.8371 126.652 15.0532 126.217 16.576L123.563 25.3646ZM119.647 38.5041H122.823L126.043 27.6706H122.867L119.647 38.5041ZM110.075 40.8535L118.647 12.9648L131.438 12.9213C134.309 13.0083 136.659 12.2687 137.486 16.489C137.964 19.6216 136.876 22.6671 135.093 24.9296C133.874 26.0608 132.439 26.5394 130.916 26.9744C132.526 27.3225 136.006 31.4123 131.133 38.6346C130.176 40.0269 128.914 40.549 127.435 40.8535C124.172 40.8535 110.075 40.8535 110.075 40.8535Z"
        fill="#E30613"
      />
      <path
        d="M141.097 40.8535H132.83C135.441 31.5862 138.356 22.1015 141.01 12.8777H149.494L141.097 40.8535Z"
        fill="#E30613"
      />
      <path
        d="M77.2267 12.9214C82.5347 13.7916 81.0554 19.2301 81.0554 19.2301L74.3987 40.8537H65.7405L71.8317 20.1003C72.5713 17.6203 69.6998 15.0533 69.0472 16.9677L61.8683 40.8972C57.8221 40.8537 57.648 40.8537 52.9492 40.8972L61.4768 12.9214H77.2267Z"
        fill="#E30613"
      />
      <path
        d="M85.0146 40.9404H76.7481C79.3586 31.6732 82.2736 22.1884 84.9276 12.9647H93.4117L85.0146 40.9404Z"
        fill="#E30613"
      />
      <path
        d="M100.852 25.3646H104.028L105.986 18.8384C106.551 16.8371 103.941 15.0532 103.506 16.576L100.852 25.3646ZM96.9794 38.5041H100.155L103.375 27.6706H100.199L96.9794 38.5041ZM87.3641 40.8535L95.9352 12.9648L108.727 12.9213C111.598 13.0083 113.948 12.2687 114.774 16.489C115.253 19.6216 114.165 22.6671 112.381 24.9296C111.163 26.0608 109.727 26.5394 108.204 26.9744C109.814 27.3225 113.295 31.4123 108.422 38.6346C107.465 40.0269 106.203 40.549 104.724 40.8535C101.461 40.8535 87.3641 40.8535 87.3641 40.8535Z"
        fill="#E30613"
      />
    </IconBase>
  )
}

export default LogoNibbi
