import React from 'react'
import { Editor as CraftEditor, Frame, Element } from '@craftjs/core'
import * as PagebuilderComponents from '../components'
import { RenderNode } from './RenderNode'
import { Viewport } from './Viewport'
import { ConditionsWrapper } from 'react-pagebuilder/pagebuilder/ConditionsWrapper'
import { ROOT } from '../components'

export const resolver = PagebuilderComponents

export const Editor = ({ json, editMode, withConditions }) => {
  const Wrapper = withConditions ? ConditionsWrapper : React.Fragment

  return (
    <>
      {editMode ? (
        <CraftEditor resolver={resolver} enabled={true} onRender={RenderNode}>
          <Viewport>
            <Frame data={json}>
              <Element is={ROOT} canvas></Element>
            </Frame>
          </Viewport>
        </CraftEditor>
      ) : (
        <CraftEditor resolver={resolver} enabled={false}>
          <Wrapper>
            <Frame data={json}></Frame>
          </Wrapper>
        </CraftEditor>
      )}
    </>
  )
}
