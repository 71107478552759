import { ProdottoNode, usePrezziProdottiQuery, useProdottiTendinaQuery } from '@gql/graphql'
import { useState, useEffect } from 'react'

export default function useProdotti(initials: ProdottoNode[]) {
  const [prodotti, setProdotti] = useState(initials)
  const [loading, setLoading] = useState(true)

  const { data: prodottiTendina, loading: loadingTendine } = useProdottiTendinaQuery({
    variables: { ids: initials.map((x) => x?.pk) },
    fetchPolicy: 'cache-first',
  })

  const { data: prezziProdotti, loading: loadingPrezzi } = usePrezziProdottiQuery({
    variables: { ids: initials.map((x) => x?.pk) },
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!loadingPrezzi && !loadingTendine) {
      setLoading(true)

      const updatedProdotti = initials.map((prodotto) => {
        const prezziDynamic = prezziProdotti?.prezziProdotti.find((x) => x.pk === prodotto.pk)
        const tendinaDynamic = prodottiTendina?.prodottiTendina.find((x) => x.pk === prodotto.pk)

        // Merge prodotto with data from both queries
        return { ...prodotto, ...prezziDynamic, ...tendinaDynamic }
      })

      setProdotti(updatedProdotti)
      setLoading(false)
    }
  }, [loadingPrezzi, loadingTendine, prezziProdotti, prodottiTendina])

  return { prodotti, loading: loading || loadingPrezzi || loadingTendine }
}
