import React from 'react'
import { MenuItemType } from '@utils/types'
import { WLink } from '@components/atoms'
import styles from './SidebarLink.module.sass'

interface Props {
  item?: MenuItemType
  active?: boolean
  className?: string
}

const SidebarLink = (props: Props) => {
  const { item = undefined, active = false, className = '' } = props

  return item ? (
    <WLink
      href={item.url}
      className={`${styles.root} ${active ? styles['root--active'] : ''} ${className}`}
    >
      <span className={styles.icon}>{item.iconLeft}</span>
      <span className={styles.label}>{item.name}</span>
    </WLink>
  ) : (
    <></>
  )
}

export default SidebarLink
