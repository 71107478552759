import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { CardGroupSettings } from './CardGroupSettings'

export const defaultProps = {}

export const dynamicProps = []

export const CardGroup: any = withBlock((props) => {
  const blockProps = {
    ...defaultProps,
    ...props.block,
  }

  const { isSSR, children } = props

  const _ = props.renderValue

  const {
    id,
    connectors: { connect },
    selected,
    hovered,
    actions: { setProp, setCustom },
    customs,
  } = useNode(
    (state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }),
    props
  )

  const { enabled } = useEditor(
    (state) => ({
      enabled: state.options.enabled,
    }),
    props
  )

  return (
    <>
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-card-group ${
          children?.length ? `block-card-group--n${children.length}` : ''
        } ${!children?.length ? 'block-card-group--empty' : ``} ${
          props.type ? `block-card-group--${props.type}` : ''
        } ${props.className}`}
      >
        {React.Children.toArray(children).filter((child: any) => !child.props.hidden)}
      </div>
    </>
  )
}, defaultProps)

CardGroup.craft = withCraft({
  name: 'CardGroup',
  defaultProps: defaultProps,
  settings: CardGroupSettings,
  floatingSettings: undefined,
  canDrag: undefined,
  canDrop: undefined,
  canMoveIn: undefined,
  canMoveOut: undefined,
})
