const comuniData = require('./comuni.json')

export const cittaComuniOptions = () => {
  const extractedComuni = comuniData.map((comune) => ({
    label: comune.nome,
    value: `${comune.nome}|${comune.sigla}`,
  }))

  return extractedComuni
}

export const statoOptions = [{ label: 'Italia', value: 'it' }]
