import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LogoBertolini({ idVariant = '', className }: Props) {
  return (
    <IconBase name="logo-bertolini" w={201} h={70} className={className}>
      <path
        d="M7.05478 59.2417C7.05478 57.7097 6.26561 56.085 4.50159 56.085C2.73757 56.085 1.9484 57.7097 1.9484 59.2417C1.9484 60.7736 2.73757 62.3983 4.50159 62.3983C6.26561 62.3983 7.05478 60.82 7.05478 59.2417ZM-0.00131226 59.2417C-0.00131226 56.5028 1.62345 54.5531 4.50159 54.5531C7.37973 54.5531 9.00449 56.5028 9.00449 59.2417C9.00449 61.9805 7.37973 63.9302 4.50159 63.9302C1.66987 63.9767 -0.00131226 61.9805 -0.00131226 59.2417Z"
        fill="black"
      />
      <path
        d="M18.3351 63.6983H16.3854V62.4449H16.339C15.8748 63.3269 14.8071 63.9304 13.7858 63.9304C11.4647 63.9304 10.4434 62.7698 10.4434 60.4487V54.8317H12.3931V60.3095C12.3931 61.8878 13.043 62.4449 14.1107 62.4449C15.7355 62.4449 16.2926 61.3772 16.2926 60.0309V54.8317H18.2423V63.6983H18.3351Z"
        fill="black"
      />
      <path
        d="M20.2383 54.8319H22.0951V56.5495H22.1415C22.3737 55.621 23.5342 54.5998 24.7412 54.5998C25.2054 54.5998 25.2982 54.6462 25.4839 54.6462V56.5495C25.2054 56.503 24.9268 56.4566 24.6483 56.4566C23.3021 56.4566 22.2344 57.5243 22.2344 59.5205V63.7448H20.2847V54.8319H20.2383Z"
        fill="black"
      />
      <path
        d="M35.4649 61.4699C36.6719 61.4699 36.9504 60.3094 36.9504 59.2417C36.9504 58.174 36.6719 57.0134 35.4649 57.0134C34.258 57.0134 33.9794 58.174 33.9794 59.2417C33.9794 60.3094 34.258 61.4699 35.4649 61.4699ZM30.6835 54.7852H33.9794V55.76H34.0259C34.4437 55.203 34.9543 54.5531 36.6255 54.5531C39.1787 54.5531 40.3856 57.0598 40.3856 59.2881C40.3856 61.3771 39.5965 63.9302 36.5791 63.9302C35.5578 63.9302 34.6293 63.466 34.1187 62.7233H34.0723V66.6227H30.6835V54.7852Z"
        fill="black"
      />
      <path
        d="M46.1883 57.0134C44.8885 57.0134 44.7028 58.2668 44.7028 59.2417C44.7028 60.1701 44.8885 61.4699 46.1883 61.4699C47.4881 61.4699 47.6738 60.2165 47.6738 59.2417C47.6274 58.3132 47.4881 57.0134 46.1883 57.0134ZM46.1883 63.9302C42.8459 63.9302 41.314 61.702 41.314 59.2417C41.314 56.7813 42.8459 54.5531 46.1883 54.5531C49.5307 54.5531 51.0626 56.7813 51.0626 59.2417C51.0626 61.702 49.5307 63.9302 46.1883 63.9302Z"
        fill="black"
      />
      <path
        d="M63.7353 63.698H60.2537L58.9539 58.2202H58.9075L57.5613 63.698H54.0796L51.2943 54.785H54.8224L56.1686 60.3092H56.215L57.3292 54.785H60.5323L61.6928 60.3092H61.7856L63.039 54.785H66.5671L63.7353 63.698Z"
        fill="black"
      />
      <path
        d="M73.3448 58.2203C73.3448 57.3848 72.7413 56.7349 71.8593 56.7349C70.838 56.7349 70.2345 57.2919 70.0953 58.2203H73.3448ZM70.0953 60.0308C70.1881 61.0521 70.7452 61.7484 71.8129 61.7484C72.3699 61.7484 72.9734 61.5627 73.2519 61.0985H76.455C75.7587 63.0482 73.9018 63.9302 71.8593 63.9302C68.9811 63.9302 66.8457 62.259 66.8457 59.2416C66.8457 56.642 68.749 54.5066 71.5808 54.5066C75.1088 54.5066 76.6871 56.5027 76.6871 59.9844H70.0953V60.0308Z"
        fill="black"
      />
      <path
        d="M77.8942 54.7852H81.1902V56.2242H81.2366C81.7937 55.1565 82.49 54.5531 83.7434 54.5531C84.0683 54.5531 84.3933 54.5995 84.7647 54.6923V57.7097C84.4397 57.5705 84.1148 57.4776 83.4184 57.4776C82.0722 57.4776 81.3295 58.2668 81.3295 60.1701V63.6981H77.9407V54.7852H77.8942Z"
        fill="black"
      />
      <path
        d="M85.8321 61.3773H88.246V63.6984C88.246 65.0911 87.2712 66.1587 85.925 66.4373V65.3696C86.6677 65.1839 87.0391 64.4411 87.0391 63.6984H85.8321V61.3773Z"
        fill="black"
      />
      <path
        d="M94.0489 54.8317H96.1843L98.5054 61.47H98.5518L100.826 54.8317H102.869L99.3874 64.2089C98.7375 65.8336 98.2733 67.2727 96.2307 67.2727C95.7665 67.2727 95.3023 67.2263 94.8845 67.1799V65.5087C95.2094 65.5551 95.488 65.648 95.8129 65.648C96.7413 65.648 96.9735 65.1837 97.2056 64.3946L97.4377 63.6983L94.0489 54.8317Z"
        fill="black"
      />
      <path
        d="M110.436 59.2417C110.436 57.7097 109.647 56.085 107.883 56.085C106.119 56.085 105.33 57.7097 105.33 59.2417C105.33 60.7736 106.119 62.3983 107.883 62.3983C109.647 62.3983 110.436 60.82 110.436 59.2417ZM103.426 59.2417C103.426 56.5028 105.051 54.5531 107.929 54.5531C110.807 54.5531 112.432 56.5028 112.432 59.2417C112.432 61.9805 110.807 63.9302 107.929 63.9302C105.051 63.9767 103.426 61.9805 103.426 59.2417Z"
        fill="black"
      />
      <path
        d="M121.716 63.6983H119.767V62.4449H119.72C119.256 63.3269 118.188 63.9304 117.167 63.9304C114.846 63.9304 113.825 62.7698 113.825 60.4487V54.8317H115.774V60.3095C115.774 61.8878 116.424 62.4449 117.492 62.4449C119.117 62.4449 119.674 61.3772 119.674 60.0309V54.8317H121.623V63.6983H121.716Z"
        fill="black"
      />
      <path
        d="M123.62 54.8319H125.476V56.5495H125.523C125.755 55.621 126.916 54.5998 128.123 54.5998C128.587 54.5998 128.68 54.6462 128.865 54.6462V56.5495C128.587 56.503 128.308 56.4566 128.03 56.4566C126.683 56.4566 125.616 57.5243 125.616 59.5205V63.7448H123.666V54.8319H123.62Z"
        fill="black"
      />
      <path
        d="M138.846 61.4699C140.053 61.4699 140.331 60.3094 140.331 59.2417C140.331 58.174 140.053 57.0134 138.846 57.0134C137.639 57.0134 137.36 58.174 137.36 59.2417C137.36 60.3094 137.639 61.4699 138.846 61.4699ZM134.064 54.7852H137.36V55.76H137.407C137.825 55.203 138.335 54.5531 140.006 54.5531C142.56 54.5531 143.766 57.0598 143.766 59.2881C143.766 61.3771 142.977 63.9302 139.96 63.9302C138.939 63.9302 138.01 63.466 137.5 62.7233H137.453V66.6227H134.064V54.7852Z"
        fill="black"
      />
      <path
        d="M150.498 59.7059C150.08 59.8916 149.616 60.0308 149.152 60.1237C148.409 60.3093 147.991 60.495 147.991 61.0985C147.991 61.5163 148.455 61.9341 149.059 61.9341C149.848 61.9341 150.451 61.4699 150.498 60.5415V59.7059ZM153.794 61.702C153.794 62.3519 153.84 63.1411 154.211 63.6981H150.73C150.637 63.466 150.591 63.0947 150.591 62.8625H150.544C149.801 63.6981 148.78 63.9302 147.666 63.9302C145.948 63.9302 144.509 63.0947 144.509 61.2378C144.509 58.4061 147.805 58.4989 149.569 58.174C150.034 58.0811 150.498 57.9418 150.498 57.3848C150.498 56.7813 149.941 56.5492 149.384 56.5492C148.316 56.5492 148.13 57.1063 148.13 57.4776H144.974C145.066 54.9709 147.434 54.5531 149.569 54.5531C153.84 54.5531 153.794 56.3171 153.794 58.0347V61.702Z"
        fill="black"
      />
      <path
        d="M160.617 57.3851C160.617 57.1065 160.432 56.8744 160.2 56.7352C160.014 56.5495 159.735 56.4566 159.457 56.4566C158.993 56.4566 158.435 56.5495 158.435 57.1529C158.435 57.4315 158.621 57.5243 158.853 57.6172C159.41 57.8493 160.71 57.9421 161.871 58.3135C162.985 58.6849 164.006 59.3348 164.006 60.8203C164.006 63.2806 161.639 63.9769 159.457 63.9769C157.368 63.9769 155.047 63.1413 155 60.8203H158.25C158.25 61.1452 158.389 61.3773 158.668 61.6094C158.853 61.7487 159.178 61.888 159.596 61.888C160.06 61.888 160.757 61.7023 160.757 61.1916C160.757 60.681 160.478 60.4953 158.9 60.2632C156.3 59.799 155.232 59.0098 155.232 57.4315C155.232 55.1568 157.646 54.5998 159.457 54.5998C161.406 54.5998 163.728 55.1104 163.82 57.4315H160.617V57.3851Z"
        fill="black"
      />
      <path
        d="M170.366 57.3851C170.366 57.1065 170.18 56.8744 169.948 56.7352C169.763 56.5495 169.484 56.4566 169.206 56.4566C168.741 56.4566 168.184 56.5495 168.184 57.1529C168.184 57.4315 168.37 57.5243 168.556 57.6172C169.113 57.8493 170.413 57.9421 171.573 58.3135C172.687 58.6849 173.709 59.3348 173.709 60.8203C173.709 63.2806 171.341 63.9769 169.159 63.9769C167.07 63.9769 164.749 63.1413 164.703 60.8203H167.952C167.952 61.1452 168.091 61.3773 168.37 61.6094C168.556 61.7487 168.881 61.888 169.298 61.888C169.763 61.888 170.459 61.7023 170.459 61.1916C170.459 60.681 170.18 60.4953 168.602 60.2632C166.003 59.799 164.935 59.0098 164.935 57.4315C164.935 55.1568 167.349 54.5998 169.159 54.5998C171.109 54.5998 173.43 55.1104 173.523 57.4315H170.366V57.3851Z"
        fill="black"
      />
      <path
        d="M174.962 54.7853H178.35V63.7446H174.962V54.7853ZM178.397 53.8104H174.962V51.3965H178.35V53.8104H178.397Z"
        fill="black"
      />
      <path
        d="M184.664 57.0134C183.364 57.0134 183.178 58.2668 183.178 59.2417C183.178 60.1701 183.364 61.4699 184.664 61.4699C185.964 61.4699 186.149 60.2165 186.149 59.2417C186.149 58.3132 185.964 57.0134 184.664 57.0134ZM184.664 63.9302C181.322 63.9302 179.79 61.702 179.79 59.2417C179.79 56.7813 181.322 54.5531 184.664 54.5531C188.006 54.5531 189.538 56.7813 189.538 59.2417C189.538 61.702 188.053 63.9302 184.664 63.9302Z"
        fill="black"
      />
      <path
        d="M190.885 54.7852H194.181V55.8993H194.227C194.877 55.0173 195.759 54.5531 197.152 54.5531C198.776 54.5531 200.215 55.5279 200.215 57.5705V63.6981H196.78V59.0095C196.78 57.9883 196.641 57.2455 195.666 57.2455C195.063 57.2455 194.32 57.524 194.32 58.9631V63.6981H190.931V54.7852H190.885Z"
        fill="black"
      />
      <path d="M200.169 6.83169H-0.00131226V33.1064H200.169V6.83169Z" fill="#009FE3" />
      <path
        d="M46.1418 26.9787H59.8826C62.3429 26.9787 64.1998 26.3288 64.1998 23.2649C64.1998 21.1295 63.5035 20.1083 61.6002 19.9226V19.8761C62.8071 19.5512 63.6892 19.0406 63.6892 16.6266C63.6892 13.8878 62.018 12.9593 58.5828 12.9593H46.1882V26.9787H46.1418ZM50.459 21.4081H58.2114C59.1398 21.4081 59.7433 21.6402 59.7433 22.5686C59.7433 23.3578 59.1398 23.5899 58.2114 23.5899H50.459V21.4081ZM50.459 16.3017H58.0721C58.8613 16.3017 59.3255 16.5802 59.3255 17.4158C59.3255 18.205 58.8613 18.4835 58.0721 18.4835H50.459V16.3017Z"
        fill="white"
      />
      <path
        d="M66.7993 26.9789H82.4434V23.5901H71.1165V21.3619H81.6078V18.4373H71.1165V16.1626H82.1649V12.9131H66.7993V26.9789Z"
        fill="white"
      />
      <path
        d="M89.3136 16.3947H96.0448C97.391 16.3947 98.0409 16.6269 98.0409 17.8802V18.3445C98.0409 19.3657 97.6231 19.83 96.4626 19.83H89.3136V16.3947ZM85.0428 26.9789H89.3601V23.3116H96.1376C97.4839 23.3116 97.9016 23.7758 97.9016 25.2613V26.9789H102.219V24.565C102.219 22.2439 101.151 21.6868 99.8514 21.4547V21.4083C101.847 20.9441 102.265 19.83 102.265 17.9731V17.0911C102.265 14.5843 101.383 12.9131 98.0409 12.9131H84.9964V26.9789H85.0428Z"
        fill="white"
      />
      <path
        d="M104.169 16.3947H110.204V26.9789H114.521V16.3947H120.556V12.9131H104.169V16.3947Z"
        fill="white"
      />
      <path
        d="M122.134 22.0581C122.134 26.1897 124.455 27.1645 128.354 27.1645H135.271C139.171 27.1645 141.492 26.1897 141.492 22.0581V17.8802C141.492 13.7951 139.171 12.7738 135.271 12.7738H128.354C124.455 12.7738 122.134 13.7487 122.134 17.8802V22.0581ZM126.59 19.18C126.59 17.416 127.008 16.3483 129.283 16.3483H134.343C136.618 16.3483 137.035 17.416 137.035 19.18V20.7119C137.035 22.7545 136.618 23.4972 134.575 23.4972H129.051C127.008 23.4972 126.59 22.708 126.59 20.7119V19.18Z"
        fill="white"
      />
      <path d="M144.091 26.9789H158.064V23.358H148.362V12.9131H144.091V26.9789Z" fill="white" />
      <path d="M164.47 12.9131H160.153V26.9789H164.47V12.9131Z" fill="white" />
      <path
        d="M167.581 26.9789H171.898V16.7661H171.945L180.022 26.9789H186.707V12.9131H182.436V23.1259H182.389L174.266 12.9131H167.581V26.9789Z"
        fill="white"
      />
      <path d="M194.18 12.9131H189.863V26.9789H194.18V12.9131Z" fill="white" />
      <path d="M41.8711 1.91107H5.75502V38.0271H41.8711V1.91107Z" fill="black" />
      <path
        d="M41.9636 1.81818H5.66189V38.1199H41.9636V1.81818Z"
        stroke="black"
        strokeWidth="0.251"
      />
      <path
        d="M13.5075 27.1643L14.6217 22.3829H10.5366V17.4622H15.7358L16.8035 12.7272H10.4901V7.80648H30.5443C32.169 7.80648 33.5153 8.87418 33.9331 10.4061L35.465 16.673C35.5114 16.8123 35.5114 16.9051 35.5114 17.0444C35.5114 17.8336 34.8615 18.4835 33.9795 18.5763L34.0259 19.9225C35.1864 19.9225 36.2542 20.7117 36.5327 21.8258L37.8789 27.675C37.9718 28.0463 38.0646 28.3713 38.0646 28.7427C38.0646 30.5995 36.5791 32.085 34.6758 32.085H10.4901V27.1643H13.5075Z"
        fill="white"
      />
      <path
        d="M31.5653 23.5898L32.0296 25.7252C32.2153 26.6536 31.3332 27.1643 30.7298 27.1643H19.0315L20.0992 22.3828H29.987C30.7762 22.3828 31.3797 22.8935 31.5653 23.5898Z"
        fill="black"
      />
      <path
        d="M22.3739 12.7738H27.8052C28.5479 12.7738 29.1514 13.3309 29.3835 13.9808L29.8478 15.884C29.8478 15.9769 29.8478 16.1162 29.8478 16.1626C29.8478 16.9053 29.1978 17.4624 28.5479 17.4624H21.3062L22.3739 12.7738Z"
        fill="black"
      />
    </IconBase>
  )
}

export default LogoBertolini
