import React from 'react'
import styles from './MyAccountLayout.module.sass'
import MyAccountSidebar from '../MyAccountSidebar/MyAccountSidebar'
import { Button, WContainer } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { ArrowLeft } from '@components/icons'

interface Props {
  mainVariant?: 'wide' | 'narrow'
  activeKey?: string
  title?: string
  subtitle?: string
  description?: string
  backUrl?: string
  children?: React.ReactNode
  className?: string
}

const MyAccountLayout = (props: Props) => {
  const {
    mainVariant = 'wide',
    activeKey = undefined,
    backUrl = undefined,
    title = undefined,
    subtitle = undefined,
    description = undefined,
    children = undefined,
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`${styles.root} ${styles[`root--${mainVariant}`]} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <MyAccountSidebar activeKey={activeKey} className={styles.sidebar} />
          <main className={styles.main}>
            <Button
              label={t('Indietro')}
              iconLeft={<ArrowLeft />}
              href={backUrl || '/myaccount/'}
              variant="ghost"
              className={`${styles.back} ${backUrl ? '' : styles['back--onlyMobile']}`}
            />
            <div className={styles.content}>
              {!!title && <h1 className={styles.title}>{title}</h1>}
              {!!subtitle && (
                <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
              )}
              {!!description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {children}
            </div>
          </main>
        </div>
      </WContainer>
    </div>
  )
}

export default MyAccountLayout
