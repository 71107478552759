import React, { useId, useState } from 'react'
import Select from 'react-select'
import { ChevronDown } from 'components/icons'
import { FormError } from 'components/atoms'
import styles from './FormSelect.module.sass'
import AsyncSelect from 'react-select/async'
import useTrans from '@hooks/useTrans'

export interface Option {
  label?: string
  value?: any
  selected?: boolean
}
export interface Props {
  id: string
  placeholder?: string
  label?: string
  name?: string
  value?: any
  className?: string
  required?: boolean
  disabled?: boolean
  variant?: '' | 'dropdown'
  size?: 'md' | 'lg'
  menuPos?: 'bottom' | 'top'
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void
  onChange?: (e: any) => void
  isSearchable?: boolean
  inSlider?: boolean
  options: Option[]
  async?: boolean
}

const FormSelectDropdownIndicator = () => (
  <div className="react-select__dropdown-indicator">
    <ChevronDown />
  </div>
)

const FormSelect = (props: Props) => {
  const t = useTrans()
  const {
    id = '',
    placeholder = '',
    name = '',
    value = null,
    className = '',
    required = false,
    disabled = false,
    variant = '',
    size = 'md',
    menuPos = 'bottom',
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    options = [],
    isSearchable = false,
    inSlider = false,
    async = false,
  } = props

  const [menuOpen, setMenuOpen] = useState(false)

  const classNames = ` 
    ${styles.root}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  const reactSelectClassNames = ` 
    react-select
    ${menuPos ? `react-select--menu-${menuPos}` : ''}
    ${inSlider ? `react-select--in-slider` : ''}
    ${menuOpen ? `react-select--open` : ''}
    ${size ? `react-select--${size}` : ''}
    ${status ? `react-select--${status}` : ''}
    ${variant ? `react-select--${variant}` : ''}
  `

  const convertOptionToEvent = (option) => ({
    target: {
      type: 'select',
      name: name,
      value: option?.value,
      label: option?.label,
    },
  })

  const onLocalChange = (option) => {
    onChange(convertOptionToEvent(option))
    if (inSlider) setMenuOpen(false)
  }
  const onLocalFocus = (e) => {
    setMenuOpen(true)
    if (onFocus) onFocus(e)
  }

  const onLocalBlur = (e) => {
    setMenuOpen(false)
    if (onBlur) onBlur(e)
  }

  const filterOptions = (inputValue: string) => {
    if (inputValue.length < 2) return []
    return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
  }

  const loadOptions = (inputValue: string, callback: (options: any) => void) => {
    setTimeout(() => {
      callback(filterOptions(inputValue))
    }, 200)
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        {async ? (
          <AsyncSelect
            cacheOptions
            className={reactSelectClassNames}
            defaultOptions
            loadOptions={loadOptions}
            inputId={id}
            classNamePrefix="react-select"
            closeMenuOnSelect
            required={required}
            isClearable={false}
            isSearchable={isSearchable}
            isDisabled={disabled || false}
            instanceId={useId()}
            name={name}
            placeholder={placeholder}
            components={{ DropdownIndicator: FormSelectDropdownIndicator }}
            onChange={onLocalChange}
            onBlur={onLocalBlur}
            onFocus={onLocalFocus}
            options={options}
            value={
              options?.find((option) => option.selected) ||
              options?.find((option) => option.value === value) ||
              null
            }
            aria-describedby={status === 'error' ? `${id}_error` : undefined}
            aria-invalid={status === 'error'}
            noOptionsMessage={() => t('Inizia a digitare e seleziona la voce dalla lista')}
          />
        ) : (
          <Select
            inputId={id}
            className={reactSelectClassNames}
            classNamePrefix="react-select"
            closeMenuOnSelect
            required={required}
            isClearable={false}
            isSearchable={isSearchable}
            isDisabled={disabled || false}
            instanceId={useId()}
            name={name}
            placeholder={placeholder}
            components={{ DropdownIndicator: FormSelectDropdownIndicator }}
            onChange={onLocalChange}
            onBlur={onLocalBlur}
            onFocus={onLocalFocus}
            options={options}
            value={
              options?.find((option) => option.selected) ||
              options?.find((option) => option.value === value) ||
              null
            }
            aria-describedby={status === 'error' ? `${id}_error` : undefined}
            aria-invalid={status === 'error'}
          />
        )}
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
