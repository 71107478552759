import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import ImageToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'

export const TextImageSettings = (settingsProps) => {
  const themeOpts = [
    { label: 'Neutral light', value: 'neutrallight' },
    { label: 'Secondary dark', value: 'secondarydark' },
    { label: 'Primary', value: 'primary' },
    { label: 'Black', value: 'black' },
  ]

  const introVariantOpts = [
    { label: 'Black', value: 'black' },
    { label: 'Secondary dark', value: 'secondarydark' },
    { label: 'White', value: 'white' },
    { label: 'White border', value: 'whiteborder' },
    { label: 'Promo', value: 'promo' },
    { label: 'Idee verdi', value: 'ideeverdi' },
    { label: 'Novita', value: 'novita' },
    { label: 'Categoria', value: 'categoria' },
  ]

  const orientationOpts = [
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
  ]
  const imagePosOpts = [
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
  ]
  const layoutOpts = [
    { label: '33%', value: '33' },
    { label: '50%', value: '50' },
    { label: '66%', value: '66' },
    { label: '100%', value: '100' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="layout"
          type="select"
          label="Layout"
          options={layoutOpts}
          defaultValue={defaultProps['layout']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          label="Tema"
          options={themeOpts}
          defaultValue={defaultProps['theme']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="introVariant"
          type="select"
          label="Tema tag"
          options={introVariantOpts}
          defaultValue={defaultProps['introVariant']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="orientation"
          type="select"
          label="Orientamento"
          options={orientationOpts}
          defaultValue={defaultProps['orientation']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="imagePos"
          type="select"
          label="Posizione immagine"
          options={imagePosOpts}
          defaultValue={defaultProps['imagePos']}
        />
      </ToolbarSection>
      {!settingsProps.floating && (
        <>
          <ImageToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const TextImageFloatingSettings = () =>
  React.createElement(TextImageSettings, { floating: true })
