/* eslint-disable react-hooks/exhaustive-deps */
import '../styles/global.sass'
import 'react-loading-skeleton/dist/skeleton.css'

import React, { useContext, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'gql/client'
import NProgress from 'nprogress'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { api, getApi, isEmpty } from 'utils'
import pagina, { setPagina } from 'containers/pagina'
import useUtente from '@hooks/useUtente'
import { wrapper } from '../store'
// import { LoaderOverlay } from '@components/atoms'
import { getItemPage, getPageType, setPage, setTrackingReady, updateGTMDataLayer } from 'utils/gtm'
import { CHECK_REVALIDATE } from '@utils/endpoints'

import { Inter, Manrope } from 'next/font/google'
import { CartContext, CartContextProvider } from '@utils/cartContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { SkeletonTheme } from 'react-loading-skeleton'
import { LoaderOverlay } from '@components/molecules'
import Omnisend from '@utils/Omnisend'
import { GoogleTagManager } from '@next/third-parties/google'
import PromoModalProvider, { PromoModalContext } from '@utils/promoModalContext'
import PopupModal from '@components/molecules/PopupModal/PopupModal'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

const fontManrope = Manrope({
  weight: ['400', '700', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
})

const fontInter = Inter({
  weight: ['400', '500', '600', '700'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
})

function PageWrapper({ loginRequired = false, pageProps, children }) {
  const router = useRouter()
  const { pagina } = useSelector((state: any) => state?.pagina)
  const { utente, loading } = useUtente()
  const { cart, loading: cartLoading } = useContext(CartContext)

  const dispatch = useDispatch()
  const environment = process.env.NODE_ENV

  const handleNProgress = () => NProgress.start()
  const handleNProgressDone = () => NProgress.done()

  // if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  //   const ReactDOM = require('react-dom')
  //   const axe = require('@axe-core/react')
  //   axe(React, ReactDOM, 1000)
  // }

  const [routeChangeCompleted, setRouteChangeCompleted] = useState(false)

  useEffect(() => {
    setRouteChangeCompleted(true)
    if (isEmpty(pageProps)) {
      dispatch(setPagina({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      dispatch(setPagina(pageProps))
    }
  }, [router.asPath])

  useEffect(() => {
    if (routeChangeCompleted && !loading && !cartLoading && !isEmpty(pagina)) {
      updateGTMDataLayer(pagina, router, environment, utente, cart)
      setPage(pagina)
      setRouteChangeCompleted(false)
    }
  }, [routeChangeCompleted, pagina, loading, cartLoading])

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setTrackingReady()
      handleNProgress()
    }

    router.events.on('routeChangeComplete', handleNProgressDone)
    router.events.on('routeChangeError', handleNProgressDone)
    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeComplete', handleNProgressDone)
      router.events.off('routeChangeError', handleNProgressDone)
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router])

  const handleCheckRevalidate = async (path: string, revalidate: number, domain: string) => {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    await api.get(CHECK_REVALIDATE, {
      params: { path, revalidate, domain },
    })
  }

  useEffect(() => {
    if (pageProps?.revalidate)
      handleCheckRevalidate(
        router.asPath.split('?')?.[0],
        pageProps.revalidate,
        window.location.hostname
      )
  }, [pageProps?.revalidate])

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (!loading) {
        if (loginRequired && !loading && (!utente || utente.guest))
          router.replace(`/login?next=${router.asPath}`)
      }
    }, 300)

    return () => clearTimeout(checkUtente)
  }, [loginRequired, utente, loading])

  return loginRequired ? (
    loading ? (
      <LoaderOverlay />
    ) : utente ? (
      children
    ) : (
      <LoaderOverlay />
    )
  ) : (
    children
  )
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const apolloStore = useApollo(pageProps, null, router.locale, router.domainLocales)

  return (
    <>
      <style jsx global>{`
        html {
          --font-manrope: ${fontManrope.style.fontFamily};
          --font-inter: ${fontInter.style.fontFamily};
        }
      `}</style>

      <ApolloProvider client={apolloStore}>
        <Omnisend />

        <GoogleTagManager gtmId={process.env.GTM_ID} />
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY} useEnterprise>
            <CartContextProvider>
              <PromoModalProvider
                pageType={getPageType(pageProps.pagina, getItemPage(pageProps), router.asPath)}
              >
                <SkeletonTheme baseColor="#F3E9D8" highlightColor="#FFFDF9">
                  <PageWrapper
                    loginRequired={Component.loginRequired || false}
                    pageProps={pageProps}
                  >
                    <PopupModal />
                    <Component {...pageProps} />
                  </PageWrapper>
                </SkeletonTheme>
              </PromoModalProvider>
            </CartContextProvider>
          </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </>
  )
}

export default wrapper.withRedux(MyApp)
