import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import { landingNewsletterAdvValidation } from '@utils/validationSchemas'
import { Button, FormError, FormikField, FormLayout, Modal, WContainer } from '@components/atoms'
import styles from './LandingNewsletterAdvForm.module.sass'
import { useLandingNewsletterSubscriptionMutation } from '@gql/graphql'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface Props {
  className?: string
}

const LandingNewsletterAdvForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [error, setError] = useState('') // TODO: gestione errore

  const [modalOpen, setModalOpen] = useState(false)

  const [subscribe, { loading }] = useLandingNewsletterSubscriptionMutation()

  const tipoUtenteOpts = [
    { label: t('Privato'), value: 'privato' },
    { label: t('Azienda'), value: 'azienda' },
  ]

  const initialValues = {
    email: '',
    tipoUtente: tipoUtenteOpts[0].value,
    nome: '',
    cognome: '',
    privacy: false,
    consensoMkt: false,
  }

  const { executeRecaptcha } = useGoogleReCaptcha()

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              let token = 'faketoken'
              if (process.env.ENVIRONMENT === 'prod') {
                if (!executeRecaptcha) return

                token = await executeRecaptcha('newsletter_adv')
                if (!token) return
              }
              const input = {
                nome: values.nome,
                cognome: values.cognome,
                email: values.email,
                privato: values.tipoUtente === 'privato',
                consensoProfilazione: values.consensoMkt,
                landing: 'adv',
                captcha: token,
              }

              const {
                data: {
                  landingNewsletterSubscription: { status, message },
                },
              } = await subscribe({ variables: { input } })

              if (status) setModalOpen(true)

              resetForm()
            }}
            validationSchema={landingNewsletterAdvValidation(t)}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <FormError
                  id="adv_global_error"
                  message={error}
                  visible={!!error}
                  variant="global"
                />
                <FormLayout space="sm">
                  <FormikField
                    formik={formikProps}
                    id={'adv_nome'}
                    name="nome"
                    type="text"
                    label={t('Nome')}
                    placeholder={t('Nome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'adv_cognome'}
                    name="cognome"
                    type="text"
                    label={t('Cognome')}
                    placeholder={t('Cognome')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'adv_email'}
                    name="email"
                    type="email"
                    label={t('E-mail')}
                    placeholder={t('Email')}
                    required
                  />
                  <FormikField
                    formik={formikProps}
                    id={'adv_tipo_utente'}
                    label={t('Sei un privato o un professionista?')}
                    name="tipoUtente"
                    type="radio"
                    variant="horizontal"
                    options={tipoUtenteOpts}
                    required
                  />
                </FormLayout>
                <div
                  className={styles.privacy}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "Ti invitiamo a visionare <a href='/privacy/informativa-per-marketing-e-profilazione/'>l'informativa privacy art. 13 GDPR</a> sul trattamento dei dati"
                    ),
                  }}
                />
                <FormLayout space="sm">
                  <FormikField
                    formik={formikProps}
                    id={'adv_privacy'}
                    name="privacy"
                    type="checkbox"
                    label={t("Letta e compresa l'informativa*")}
                    checkboxLabel={t(
                      "Esprimo il consenso al trattamento dei miei dati per ricevere supporto, risposte ai miei quesiti e per le eventuali attività connesse di cui al punto a) dell'informativa."
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                  <FormikField
                    formik={formikProps}
                    id={'adv_marketing'}
                    name="consensoMkt"
                    type="checkbox"
                    label={t('Consenso per la "Profilazione" (facoltativo)')}
                    checkboxLabel={t(
                      'Esprimo il consenso per la finalità di cui al punto c) dell’informativa e quindi acconsento all’individuazione e definizione dei miei gusti e preferenze di consumo. Resta inteso che potrò revocare in qualunque momento il presente consenso.'
                    )}
                    fieldVariant="box"
                    layout="full"
                  />
                </FormLayout>
                <Button
                  type="submit"
                  label={t('Ottieni lo sconto')}
                  loading={loading}
                  size="lg"
                  className={styles.cta}
                />
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          id={'newsletter-modal'}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={t('Che bello averti tra noi, ecco il tuo buono!')}
          description={t(
            'Ti abbiamo inviato un’email con il coupon di 10€. <br/>Naviga il sito per trovare ispirazione e scegliere su quali prodotti utilizzare lo sconto.'
          )}
          cta={
            <Button
              label={t('Esplora il mondo {0}', [process.env.BRAND])}
              href={`/`}
              onClick={() => setModalOpen(false)}
            />
          }
          className={`${styles.modal} ${className}`}
        />
      </WContainer>
    </div>
  )
}

export default LandingNewsletterAdvForm
