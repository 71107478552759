import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Menu({ idVariant = '', className }: Props) {
  return (
    <IconBase name="menu" w={24} className={className}>
      <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" fill="currentColor" />
    </IconBase>
  )
}

export default Menu
