import React from 'react'
import { ROOT_NODE } from '@craftjs/core'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { isDisabled } from '../../../../utils/node'
import { Click } from 'react-pagebuilder/pb-components/icons'

export const Toolbar = () => {
  const { selected, related, query } = useEditor((state) => {
    const selected = [...state.events.selected]?.[0]
    return {
      selected: selected,
      related: selected && state.nodes[selected].related,
    }
  })

  return (
    <>
      {selected && selected !== ROOT_NODE && !isDisabled(selected, query) && related.toolbar ? (
        React.createElement(related.toolbar)
      ) : (
        <div className="empty-message">
          <p>Click on a component to start editing.</p>
          <Click />
        </div>
      )}
    </>
  )
}
