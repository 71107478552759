import React from 'react'
import { RigaCheckoutNode } from '@gql/graphql'
import styles from './RigaCheckoutCard.module.sass'
import { Tag, WImage } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { prezziProdotto, priceNotation } from '@utils/safe'
import useProdotti from '@hooks/useProdotti'

interface Props {
  rigaCheckout: RigaCheckoutNode
  className?: string
}

const RigaCheckoutCard = (props: Props) => {
  const { rigaCheckout = undefined, className = '' } = props

  const t = useTrans()
  const pp = prezziProdotto(rigaCheckout.prodotto.prodotto)
  const prezzi = [
    { key: 'current', value: pp?.prezzoFinale },
    { key: 'listino', value: pp?.prezzoListino },
  ].filter((x) => !!x.value)
  const { prodotti, loading } = useProdotti([rigaCheckout.prodotto.prodotto])
  const taglieOpts =
    prodotti[0]?.prodottiTendina?.map((x) => ({
      label: x.labelTendina,
      value: x.pk,
    })) || []
  const tagliaProdotto = rigaCheckout.prodotto.prodotto.pk

  return rigaCheckout ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={rigaCheckout.prodotto.prodotto.previewImage}
          maxWidth={64}
          maxHeight={64}
          objectFit="contain"
        />
      </div>
      <div className={styles.main}>
        {rigaCheckout.omaggio && (
          <Tag label={t('In omaggio')} variant="promo" className={styles.tag} />
        )}
        {rigaCheckout.prodotto.prodotto.categoria && (
          <span className={styles.category}>
            {rigaCheckout.prodotto.prodotto.categoria.boxTitolo}
          </span>
        )}
        <h2 className={styles.title}>{rigaCheckout.prodotto.prodotto.nome}</h2>
        <div className={styles.attributes}>
          <div className={styles.attribute}>
            <span className={styles.attributeLabel}>{t('Quantita')}: </span>
            <span className={styles.attributeValue}>{rigaCheckout.quantita}</span>
          </div>
          {(prodotti[0].showTaglia || prodotti[0].showFormato) && (
            <div className={styles.attribute}>
              <span className={styles.attributeLabel}>
                {prodotti[0].showTaglia ? t('Taglia') : t('Formato')}:{' '}
              </span>
              <span className={styles.attributeValue}>
                {taglieOpts.filter((x) => x.value === tagliaProdotto)?.[0]?.label}
              </span>
            </div>
          )}
        </div>
        {!rigaCheckout.omaggio && !!prezzi?.length && (
          <div className={styles.prices}>
            {prezzi.map((p) => (
              <span className={`${styles.price} ${styles[`price--${p.key}`]}`} key={p.key}>
                {priceNotation(p.value, false, prodotti[0].valuta?.simbolo)}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RigaCheckoutCard
