import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ArrowDown({ idVariant = '', className }: Props) {
  return (
    <IconBase name="arrow-down" w={24} className={className}>
      <path
        d="M13.0001 16.1716L18.3641 10.8076L19.7783 12.2218L12.0001 20L4.22192 12.2218L5.63614 10.8076L11.0001 16.1716V4H13.0001V16.1716Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ArrowDown
