import React from 'react'
import { BootstrapOverlayTrigger, BootstrapTooltip } from 'react-pagebuilder/pb-components/atoms'

const Tooltip = ({ label = '', id = '', pos = 'top', className = '', children = null }) => {
  const renderTooltip = (props) => (
    <BootstrapTooltip
      id={id}
      className={`pb-tooltip${className ? ` ${className}` : ''}`}
      {...props}
    >
      {label}
    </BootstrapTooltip>
  )

  return (
    <BootstrapOverlayTrigger placement={pos} delay={{ show: 50, hide: 50 }} overlay={renderTooltip}>
      {children}
    </BootstrapOverlayTrigger>
  )
}

export default Tooltip
