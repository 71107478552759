import { API_HOST } from '@utils/index'
import { BRAND } from '@utils/settings'
import {
  BreadcrumbJsonLd,
  FAQPageJsonLd,
  NewsArticleJsonLd,
  OrganizationJsonLd,
  ProductJsonLd,
  SiteLinksSearchBoxJsonLd,
} from 'next-seo'

interface Props {
  item?: any
  type?: string
}

const JsonLd = (props: Props) => {
  const { item = undefined, type = '' } = props

  switch (type) {
    case 'organization': {
      return (
        <OrganizationJsonLd
          type="Organization"
          name={BRAND}
          legalName="Emak S.p.A."
          id={`${API_HOST}`}
          url={`${API_HOST}`}
          logo={`${API_HOST}/static/images/logo.png`}
          image={`${API_HOST}/static/images/logo.png`}
          address={{
            streetAddress: 'Via E. Fermi, 4 - 42011 Bagnolo in Piano (Reggio Emilia)',
            addressLocality: 'Bagnolo in Piano',
            addressRegion: 'RE',
            postalCode: '42011',
            addressCountry: 'IT',
          }}
          sameAs={
            process.env.SITE_ID === '2'
              ? [
                  'http://www.facebook.com/OleoMacItalia',
                  'http://www.youtube.com/user/MyOleomac',
                  'https://www.instagram.com/OleomacItalia/',
                ]
              : process.env.SITE_ID === '1'
              ? [
                  'http://www.facebook.com/EfcoItalia',
                  'http://www.youtube.com/user/MyEfco',
                  'https://www.instagram.com/EfcoItalia/',
                ]
              : []
          }
        />
      )
    }
    case 'breadcrumbs': {
      return <BreadcrumbJsonLd itemListElements={item} />
    }
    case 'faq': {
      return <FAQPageJsonLd mainEntity={item} />
    }
    case 'news': {
      return <NewsArticleJsonLd {...item} />
    }
    case 'search': {
      return (
        <SiteLinksSearchBoxJsonLd
          url={`${API_HOST}`}
          potentialActions={[
            {
              target: `${API_HOST}?q`,
              queryInput: 'search_term_string',
            },
          ]}
        />
      )
    }
    case 'prodotto': {
      return (
        <ProductJsonLd
          productName={`${item.nome} ${item.categoria?.boxTitolo} ${BRAND}`}
          description={item.descrizione?.replace(/<[^>]*>?/gm, '')}
          images={item.detailImages?.map((x) => x.image)}
          sku={item.codice}
          mpn={item.rif}
          offers={[
            {
              price: item.prezzoScontato || item.prezzo,
              priceCurrency: 'EUR',
              itemCondition: 'https://schema.org/NewCondition',
              availability: `https://schema.org/${item.disponibile ? 'InStock' : 'OutOfStock'}`,
              url: `${API_HOST}${item.url}`,
              seller: {
                name: BRAND,
              },
            },
          ]}
        />
      )
    }
    default: {
      return null
    }
  }
}

export default JsonLd
