import React from 'react'
import { ProdottoNode } from '@gql/graphql'
import { WImage } from '@components/atoms'
import styles from './PDPProdottoBannerCard.module.sass'
import { prezziProdotto, priceNotation } from '@utils/safe'

interface Props {
  prodotto?: ProdottoNode
  variant?: '' | 'modal'
  className?: string
}

const PDPProdottoBannerCard = (props: Props) => {
  const { prodotto = undefined, variant = '', className = '' } = props

  const prezzi = prezziProdotto(prodotto)

  return prodotto ? (
    <article className={`${styles.root} ${variant ? styles[`root--${variant}`] : ''} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={prodotto.previewImage}
          maxWidth={250}
          maxHeight={150}
          objectFit="contain"
          alt={prodotto.nome}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <h4 className={styles.title}>{prodotto.nome}</h4>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: prodotto.descrizioneBreve }}
          />
        </div>
        {!!prezzi.prezzoFinale && (
          <div className={styles.price}>
            {priceNotation(prezzi.prezzoFinale, false, prodotto.valuta?.simbolo)}
          </div>
        )}
      </div>
    </article>
  ) : (
    <></>
  )
}

export default PDPProdottoBannerCard
