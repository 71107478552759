import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from './globalContext'
import { api, apiAxiosInstance } from './api'
import { useRouter } from 'next/router'

export const PagebuilderContext = React.createContext()

const PagebuilderContextProvider = ({ serverSideContent, children }) => {
  const { contentTypeId, objectId, pkey, lang, editMode, utente } = useContext(GlobalContext)

  const router = useRouter()

  const [pagebuilder, setPagebuilder] = useState()
  const [isLoading, setLoading] = useState(true)
  const [urlOpts, setUrlOpts] = useState()

  useEffect(() => {
    // se SSR non è necessario lo useEffect
    if (serverSideContent) return

    if ((!utente || (utente && !utente.is_staff)) && serverSideContent) {
      setLoading(false)
      setPagebuilder(serverSideContent)
      return
    }

    if (pkey && lang && editMode !== undefined && editMode !== null) {
      setLoading(true)

      api
        .getPagebuilder({
          contentTypeId,
          objectId,
          key: pkey,
          lang,
          edit_mode: utente && utente.isStaff && utente.showToolbar && utente.edit ? true : false,
        })
        .then((data) => {
          setPagebuilder(data)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }, [pkey, lang, editMode, utente])

  useEffect(() => {
    if (editMode) {
      apiAxiosInstance()
        .get(`linkable_objects/?pagebuilder=true&lang=${router.locale}`)
        .then((res) => {
          setUrlOpts(
            res.data.reduce(
              (r, x) =>
                r.concat(
                  x.linkable_objects.map((y) => ({
                    label: y.alias,
                    value: x.content_type.id + '|' + y.id,
                    groupLabel: x.content_type.model,
                  }))
                ),
              []
            )
          )
        })
    }
  }, [editMode])

  const update = (pagebuilder) => {
    setPagebuilder(pagebuilder)
  }

  const saveDraft = (json_draft, refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .saveDraftPagebuilder(pagebuilder.id, json_draft)
      .then((data) => {
        update(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  const publish = (refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .publishPagebuilder(pagebuilder.id)
      .then((data) => {
        update(data)
        console.log(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  const unpublish = (refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .unpublishPagebuilder(pagebuilder.id)
      .then((data) => {
        update(data)
        console.log(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  // SSR
  if (serverSideContent) {
    return (
      <PagebuilderContext.Provider
        value={{ pagebuilder: serverSideContent, isLoading: false, isSSR: true }}
      >
        {children}
      </PagebuilderContext.Provider>
    )
  }

  return (
    <PagebuilderContext.Provider
      value={{
        pagebuilder,
        isLoading,
        saveDraft,
        publish,
        unpublish,
        urlOpts,
        isSSR: false,
      }}
    >
      {children}
    </PagebuilderContext.Provider>
  )
}

export default PagebuilderContextProvider
