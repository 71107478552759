import React, { useEffect, useRef, useState } from 'react'
import { Button, MetaSeo, JsonLd } from 'components/atoms'
import {
  Header,
  Footer,
  PreHeader,
  LandingNewsletterHeader,
  // PreHeader,
  // GoogleReCaptchaWrapper,
  // Popup,
} from 'components/organisms'
import styles from './Page.module.sass'
import useUtente from '@hooks/useUtente'
import useTrans from '@hooks/useTrans'
import useFindMenu from '@hooks/useFindMenu'
// import { PaginaNode } from '@gql/graphql'
import { isToolbarVisible } from '@utils/index'

interface Props {
  pagina: any // PaginaNode
  item?: any
  customMainRef?: any
  children: React.ReactNode
  className?: string
  contentClassName?: string
  pagebuilderContents?: any[]
  logoLinkDisabled?: boolean
  headerCta?: React.ReactNode
  headerVersion?: 'default' | 'none'
  footerVersion?: 'default' | 'none'
}

const Page = (props: Props) => {
  const {
    pagina,
    item = {},
    customMainRef = undefined,
    children = null,
    className = '',
    contentClassName = '',
    pagebuilderContents = [],
    logoLinkDisabled = false,
    headerCta = undefined,
    headerVersion = 'default',
    footerVersion = 'default',
  } = props

  const [scrollY, setScrollY] = useState(0)
  const mainRef = useRef(undefined)
  const { utente } = useUtente()
  const showToolbar = isToolbarVisible(utente)
  const t = useTrans()

  const stripeDesktop = useFindMenu('stripe_desktop')
  const stripeMobile = useFindMenu('stripe_mobile')

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
    if (window.scrollY < scrollY) document.body.classList.add('w-scrollup')
    else document.body.classList.remove('w-scrollup')
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollY])

  const [Toolbar, setToolbar] = useState<React.JSX.Element>()

  useEffect(() => {
    // carico il componente solo se l'utente ha i permessi
    const loadToolbar = async () => {
      const ToolbarComponent = (await import('components/organisms/Toolbar/Toolbar')).default
      setToolbar(<ToolbarComponent page={pagina} item={item} />)
    }

    if (showToolbar) {
      loadToolbar()
    }
  }, [showToolbar])

  return (
    <>
      {/* <GoogleReCaptchaWrapper> */}
      <div className={styles.pageWrap}>
        <Button
          label={t('Vai al contenuto')}
          onClick={() => {
            ;(customMainRef || mainRef)?.current?.focus()
          }}
          className={styles.skipToMain}
        />
        {showToolbar && Toolbar}
        {headerVersion !== 'none' && (
          <PreHeader
            content={stripeDesktop?.description}
            contentMobile={stripeMobile?.description}
            className={`${styles.preheader} preheader`}
          />
        )}
        <div
          className={`${styles.root} ${
            headerVersion === 'none' ? styles['root--noheader'] : ''
          } page ${headerVersion === 'none' ? 'page--noheader' : ''} ${className}`}
        >
          {pagina?.seo && <MetaSeo seo={pagina.seo} hreflangs={pagina.hreflangs} />}
          {headerVersion !== 'none' && <Header />}
          <div className={`${styles.content} ${contentClassName}`} ref={mainRef} tabIndex={-1}>
            {children}
          </div>
          {footerVersion !== 'none' && <Footer />}
        </div>
      </div>

      <JsonLd type="search" />
      {/* </GoogleReCaptchaWrapper> */}
    </>
  )
}

export default Page
