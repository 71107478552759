import React from 'react'
import useTrans from '@hooks/useTrans'
import { Button, Chip, Tag } from '@components/atoms'
import styles from './RivenditoreCard.module.sass'
import { mockArray, mockGenericItem } from '@utils/mock'
import { ArrowRight } from '@components/icons'
import { RivenditoreStoreLocatorNode } from '@gql/graphql-get'
import { slugify } from '@utils/index'

interface Props {
  rivenditore?: RivenditoreStoreLocatorNode
  selected?: boolean
  onClick?: () => void
  className?: string
}

const RivenditoreCard = (props: Props) => {
  const { rivenditore = undefined, selected = false, onClick = undefined, className = '' } = props
  const t = useTrans()
  const items = rivenditore.tags

  return rivenditore ? (
    <div
      className={`${styles.root} ${selected ? styles['root--selected'] : ''} ${className}`}
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      id={`rivenditoreCard-storeLocator-${rivenditore.pk}`}
    >
      <div className={styles.tags}>
        {(rivenditore.tipi.includes('rivenditore') || rivenditore.tipi.includes('b2b')) && (
          <Tag label={t('Punto vendita')} variant={selected ? 'white' : 'categoria'} size="sm" />
        )}
        {rivenditore.tipi.includes('assistenza') && rivenditore.tipi.length == 1 && (
          <Tag
            label={t('Centro assistenza')}
            variant={selected ? 'white' : 'categoria'}
            size="sm"
          />
        )}
        {rivenditore.tipi.includes('rivenditore') && (
          <Tag label={t('Vendita online')} variant="novita" size="sm" />
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          {!!rivenditore.ragioneSociale && (
            <h3 className={styles.title}>{rivenditore.ragioneSociale}</h3>
          )}
          {(!!rivenditore.indirizzo ||
            !!rivenditore.cap ||
            !!rivenditore.citta ||
            !!rivenditore.provincia) && (
            <div className={styles.indirizzo}>
              {rivenditore.indirizzo}
              {!!rivenditore.cap && (
                <>
                  {rivenditore.indirizzo ? <>, </> : <></>} {rivenditore.cap}
                </>
              )}
              {!!rivenditore.citta && (
                <>
                  {rivenditore.indirizzo || rivenditore.cap ? <>, </> : <></>}
                  {rivenditore.citta}
                </>
              )}
              {rivenditore.provincia ? <> ({rivenditore.provincia})</> : <></>}
            </div>
          )}
        </div>
        {!!items.length && (
          <div className={styles.list}>
            {items.map((x) => (
              <Chip label={t(x)} size="sm" />
            ))}
          </div>
        )}
      </div>
      <Button
        label={t('Vedi dettagli')}
        iconRight={<ArrowRight />}
        variant="ghost"
        size="sm"
        href={rivenditore.url}
      />
    </div>
  ) : (
    <></>
  )
}

export default RivenditoreCard
