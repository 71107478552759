import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import * as icons from 'components/icons'
import Button from '../Button/Button'
import styles from './Icons.module.sass'

interface Props {
  className?: string
}

const Icons = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()

  const [showHidden, setShowHidden] = useState(true)
  const [illustrations, setIllustrations] = useState(true)

  // Le ciclo due alla volta nascondendo la prima, se qualcosa scompare vanno sistemati gli id
  return (
    <div className={`${styles.root} ${showHidden ? styles['root--nohidden'] : ''} ${className}`}>
      <div className={styles.actions}>
        <Button
          label={illustrations ? t('Vedi tutte') : t('Vedi solo illustrazioni')}
          onClick={() => setIllustrations(!illustrations)}
          variant="ghost"
        />
        <Button
          label={!showHidden ? t('Mostra nascoste') : t('Nascondi ref')}
          onClick={() => setShowHidden(!showHidden)}
          variant="ghost"
        />
      </div>
      <div className={styles.list}>
        {Object.keys(icons)
          .filter((k) => (illustrations ? k.endsWith('Illustration') : true))
          .map((key, index) => {
            const Icon = icons[key]
            return (
              <div className={styles.group} key={index}>
                <div className={styles.title}>{key}</div>
                <div className={styles.item}>
                  <div className={styles.hide}>
                    <Icon idVariant={`${index}_1`} />
                  </div>
                  <div className={styles.light}>
                    <Icon idVariant={`${index}_2`} />
                  </div>
                  <div className={styles.dark}>
                    <Icon idVariant={`${index}_3`} />
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Icons
