import React from 'react'
import { useTrans } from 'hooks'
import { NewsEmakInformaNode, NewsNode, PaginaNode } from '@gql/graphql'
import { BannerBlock, ListingHead, Page, TextImageLarge } from 'components/organisms'
import styles from './CataloghiTemplate.module.sass'
import { Button, WContainer } from '@components/atoms'
import { TextImage } from '@components/molecules'

interface Props {
  pagina?: PaginaNode
  cataloghi?: NewsNode[] | NewsEmakInformaNode[]
  handleMore?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  loading?: boolean
  nextPageLength?: number
  linkNextPage?: string
  buttonUrl?: boolean
}

const CataloghiTemplate = (props: Props) => {
  const {
    pagina = undefined,
    cataloghi = undefined,
    handleMore = undefined,
    loading = false,
    nextPageLength = undefined,
    linkNextPage = undefined,
    buttonUrl = false,
  } = props

  const t = useTrans()

  return (
    <Page pagina={pagina} className={`${styles.root}`}>
      <main className={styles.layout}>
        <ListingHead
          pagina={{ ...pagina, titolo: pagina?.nome, descrizione: pagina?.descrizione }}
        />
        {!!cataloghi?.length && (
          <div className={styles.list}>
            <WContainer>
              <TextImageLarge
                item={{
                  pk: cataloghi[0].pk,
                  intro: cataloghi[0].categoria?.nome,
                  name: cataloghi[0].titolo,
                  description: cataloghi[0].sottotitolo,
                  image: cataloghi[0].previewImage,
                  children: [
                    {
                      pk: cataloghi[0].pk,
                      ctaLabel: t('Sfoglialo online'),
                      url: cataloghi[0].url,
                    },
                  ],
                }}
                theme="neutrallight"
                imagePos="right"
              />
            </WContainer>
            {cataloghi.length > 1 && (
              <BannerBlock layout={{ spaceTop: 'none', spaceBottom: 'none' }}>
                <div className={'block-card-group'}>
                  {cataloghi.slice(1, cataloghi.length).map((x) => (
                    <div className={`block-text-image block-text-image--33`} key={x.pk}>
                      <TextImage
                        item={{
                          pk: x.pk,
                          intro: x.categoria?.nome,
                          name: x.titolo,
                          description: x.sottotitolo,
                          image: x.previewImage,
                          children: [
                            {
                              pk: x.pk,
                              ctaLabel: t('Sfoglialo online'),
                              url: x.url,
                            },
                          ],
                        }}
                        orientation="vertical"
                        introVariant="black"
                        theme="neutrallight"
                        imagePos="top"
                      />
                    </div>
                  ))}
                </div>
                {nextPageLength > 0 && (
                  <div className={styles.ctaBox}>
                    <Button
                      label={t(
                        nextPageLength === 1
                          ? 'Carica {0} altro articolo'
                          : 'Carica altri {0} articoli',
                        [nextPageLength.toString()]
                      )}
                      variant="secondary"
                      onClick={handleMore}
                      loading={loading}
                      href={buttonUrl ? linkNextPage : null}
                    />
                  </div>
                )}
              </BannerBlock>
            )}
          </div>
        )}
      </main>
    </Page>
  )
}

export default CataloghiTemplate
