import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ChevronUp({ idVariant = '', className }: Props) {
  return (
    <IconBase name="chevron-up" w={24} className={className}>
      <path
        d="M11.9999 10.8284L7.0502 15.7782L5.63599 14.364L11.9999 8L18.3639 14.364L16.9497 15.7782L11.9999 10.8284Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ChevronUp
