import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import {
  CategoriaNode,
  usePrezziProdottiLazyQuery,
  useProdottiTendinaLazyQuery,
} from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { Button, FiltroApplicato, FormField, FormSelect } from '@components/atoms'
import styles from './ProdottiListing.module.sass'
import { EditorialeCard, Filtro, FiltroSlider, ProdottoCard } from '@components/molecules'
import { priceNotation } from '@utils/safe'
import FiltersDrawer from '../FiltersDrawer/FiltersDrawer'
import { Close, Compare, Filter } from '@components/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRouter } from 'next/router'
import { ListingCategoriaDocument, ListingCategoriaNode } from '@gql/graphql-get'
import CompareBar from '../CompareBar/CompareBar'
import useComparatore from '@hooks/useComparatore'
import { print } from 'graphql'
import { grapheneGet, isEcommerce } from 'utils'
import {
  handleListingFilterTracking,
  handleListingSortingTracking,
  handleProductClickTracking,
  handleProductImpressionTracking,
} from '@utils/tracking'
import { CartContext } from '@utils/cartContext'

interface Props {
  categoria?: CategoriaNode
  listing?: ListingCategoriaNode
  className?: string
}

const ProdottiListing = (props: Props) => {
  const { categoria = undefined, listing: initial = undefined, className = '' } = props

  const t = useTrans()

  const editorialeItem = categoria.offertaMarketingBox
  const router = useRouter()
  const [appliedFilters, setAppliedFilters] = useState([])
  const [listing, setListing] = useState(initial)
  const [elementi, setElementi] = useState(listing?.elementi)
  const orderOpts = listing?.ordinamenti || []
  const [order, setOrder] = useState(orderOpts?.[0]?.value || null)
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false)
  const [pmin, setPmin] = useState(null)
  const [pmax, setPmax] = useState(null)
  const [page, setPage] = useState(listing?.paginator?.currentPage)
  const [loading, setLoading] = useState(false)
  const isFirstRender = useRef(true)
  const [compareMode, setCompareMode] = useState(false)
  const [hasTracked, setHasTracked] = useState(false)

  const { cart } = useContext(CartContext)
  const { compareItems } = useComparatore(categoria?.pk)

  const [loadProdottiPrezzi, { loading: loadingPrezziProdotti }] = usePrezziProdottiLazyQuery()
  const [loadProdottiTendina, { loading: loadingProdottiTendina }] = useProdottiTendinaLazyQuery()

  const ecommerce = isEcommerce(router.locale)

  const isAccessorio = elementi?.[0]?.isAccessorio
  const isAbbigliamento = elementi?.[0]?.isAbbigliamento
  const compareEnabled = !isAccessorio && !isAbbigliamento

  const fetchPrezziTendina = async (elementi, existingElementi) => {
    try {
      const [prezziResult, tendinaResult] = await Promise.all([
        loadProdottiPrezzi({
          variables: { ids: elementi.map((x) => x.pk) },
          fetchPolicy: 'cache-first',
        }),
        loadProdottiTendina({
          variables: { ids: elementi.map((x) => x.pk) },
          fetchPolicy: 'cache-first',
        }),
      ])

      const prezziProdotti = prezziResult?.data?.prezziProdotti
      const prodottiTendina = tendinaResult?.data?.prodottiTendina

      const mergedElementi = elementi.map((prodotto) => ({
        ...prodotto,
        ...(prezziProdotti.find((x) => x.pk === prodotto.pk) || {}),
        ...(prodottiTendina.find((x) => x.pk === prodotto.pk) || {}),
      }))

      setElementi([...existingElementi, ...mergedElementi])
    } catch (error) {
      console.error(
        'Error fetching prezzi and tendina',
        error instanceof Error ? error.message : String(error)
      )
    }
  }

  const onFiltersReset = () => {
    handleListingFilterTracking('reset', 'reset', true)
    setPmax(initial.priceMax)
    setPmin(initial.priceMin)
    setAppliedFilters([])
  }

  const onAttributoFilterChange = (filtro, type: string) => {
    setAppliedFilters((prevFilters) => {
      const updatedFilters = prevFilters.some((f) => f.id === filtro.id)
        ? prevFilters.filter((f) => f.id !== filtro.id)
        : [...prevFilters, { name: filtro.nome, id: filtro.id, type: type }]

      return updatedFilters
    })
  }

  const onSliderFilterChange = async (values) => {
    setPmin(values[0])
    setPmax(values[1])
    setAppliedFilters((prevFilters) => {
      const hasPriceFilter = prevFilters.some((filtro) => filtro.role === 'priceFilter')

      if (values[0] === listing.priceMin && values[1] === listing.priceMax) {
        return prevFilters.filter((filtro) => filtro.role !== 'priceFilter')
      }

      if (hasPriceFilter) {
        return prevFilters.map((filtro) =>
          filtro.role === 'priceFilter'
            ? { name: `€ ${values[0]} - € ${values[1]}`, id: null, role: 'priceFilter' }
            : filtro
        )
      } else {
        return [
          ...prevFilters,
          { name: `€ ${values[0]} - € ${values[1]}`, id: null, role: 'priceFilter' },
        ]
      }
    })
  }

  const onShowAdvancedFilters = () => {
    //TODO
  }

  const loadMore = (event) => {
    event.preventDefault()
    setPage(page + 1)
    router.push(`${router.asPath.split('?')[0]}?page=${page + 1}`, undefined, {
      shallow: true,
    })
  }

  async function fetchListing(page, overwrite = false) {
    const idAppliedFilters = appliedFilters.map((filtro) => filtro.id)
    setLoading(true)

    const variables = {
      params: {
        p: parseInt(page),
        filtri_id: idAppliedFilters,
        o: order,
        pmin: pmin,
        pmax: pmax,
      },
      categoriaId: categoria.pk,
    }

    const encodedVariables = encodeURIComponent(JSON.stringify(variables))
    const encodedQuery = encodeURIComponent(print(ListingCategoriaDocument))

    const response = await grapheneGet.get(
      `?lang=${router.locale}&query=${encodedQuery}&operationName=ListingCategoria&variables=${encodedVariables}`
    )
    const { data } = response.data

    handleProductImpressionTracking(data.listingCategoria.elementi, cart)

    const newElementi = data.listingCategoria?.elementi || []
    fetchPrezziTendina(newElementi, overwrite ? [] : elementi)

    setListing((prevListing) => ({
      ...prevListing,
      paginator: data.listingCategoria.paginator,
      attributi: data.listingCategoria.attributi,
    }))
    setLoading(false)
  }

  useEffect(() => {
    if (cart && listing && !hasTracked) {
      handleProductImpressionTracking(listing?.elementi, cart)
      setHasTracked(true)
    }
  }, [cart, listing])

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false
      return
    }

    setPage(1)
    fetchListing(1, true)
  }, [order, appliedFilters])

  useEffect(() => {
    if (page === initial.paginator.currentPage) {
      fetchPrezziTendina(elementi, [])
      return
    }
    fetchListing(page, false)
  }, [page])

  return listing ? (
    <>
      <div className={`${styles.root} ${className}`}>
        <aside className={styles.sidebar}>
          {listing?.attributi?.map((filtro) => {
            return filtro.children?.length > 0 ? (
              <div key={filtro.nome} className={styles.filtersBlock}>
                <Filtro
                  id={`prodottilisting_filtro_${filtro.nome}`}
                  attributo={filtro}
                  onChange={onAttributoFilterChange}
                  className={styles.filtersItem}
                  appliedFilters={appliedFilters}
                  isOpen
                />
              </div>
            ) : null
          })}
          {ecommerce && (
            <div className={styles.filtersBlock}>
              <FiltroSlider
                id="prezzo"
                label={t('prezzo')}
                min={listing?.priceMin}
                max={listing?.priceMax}
                formatFunc={priceNotation}
                onChange={onSliderFilterChange}
                className={styles.filtersItem}
              />
            </div>
          )}
          <div className={styles.filtersAdvancedBox}>
            {/* TODO: da decommentare una volta stabiliti i filtri "avanzati" */}
            {/* <Button
              label={t('Mostra filtri avanzati')}
              variant="ghost"
              onClick={onShowAdvancedFilters}
            /> */}
          </div>
        </aside>
        <div className={styles.main}>
          <div className={styles.top}>
            <div className={`${styles.num} ${styles['num--desktop']}`}>
              {t(listing?.paginator?.numItems === 1 ? '{0} prodotto' : '{0} prodotti', [
                `${listing?.paginator?.numItems}`,
              ])}
            </div>
            <div className={`${styles.utils} ${styles['utils--desktop']}`}>
              {compareEnabled && (
                <Button
                  label={compareMode ? t('Chiudi confronta') : t('Confronta')}
                  variant="secondary"
                  onClick={() => setCompareMode(!compareMode)}
                  iconLeft={compareMode ? <Close /> : <Compare />}
                />
              )}
              {orderOpts?.length > 0 && (
                <FormField
                  id="ordinamento"
                  label={t('Ordina per')}
                  className={styles.order}
                  layout="none"
                >
                  <FormSelect
                    id="ordinamento"
                    value={order}
                    options={orderOpts}
                    onChange={(e) => {
                      setOrder(e.target.value)
                      handleListingSortingTracking(e.target.label)
                    }}
                  />
                </FormField>
              )}
            </div>
            <div className={`${styles.utils} ${styles['utils--mobile']}`}>
              <Button
                label={t('Filtra e ordina')}
                variant="ghost"
                size="lg"
                onClick={() => setFiltersDrawerOpen(true)}
                iconLeft={<Filter />}
              />
              {compareEnabled && (
                <Button
                  label={compareMode ? t('Chiudi confronta') : t('Confronta')}
                  variant="ghost"
                  size="lg"
                  onClick={() => setCompareMode(!compareMode)}
                  iconLeft={compareMode ? <Close /> : <Compare />}
                />
              )}
            </div>
          </div>
          <div
            className={`${styles.selectedFilters}  ${
              !appliedFilters.length ? styles['selectedFilters--noFilters'] : ''
            }`}
          >
            <div className={styles.selectedFiltersActions}>
              <div className={`${styles.num} ${styles['num--mobile']}`}>
                {t(listing?.paginator?.numItems === 1 ? '{0} prodotto' : '{0} prodotti', [
                  `${listing?.paginator?.numItems}`,
                ])}
              </div>
              {!!appliedFilters.length && (
                <button
                  type="button"
                  onClick={onFiltersReset}
                  className={styles.selectedFiltersReset}
                >
                  {t('Elimina tutti i filtri')}
                </button>
              )}
            </div>
            {!!appliedFilters.length && (
              <>
                <div
                  className={`${styles.selectedFiltersList} ${styles['selectedFiltersList--desktop']}`}
                >
                  {appliedFilters.map((f) => (
                    <FiltroApplicato
                      key={f.name}
                      label={f.name}
                      onClose={() => {
                        if (f.role && f.role === 'priceFilter') {
                          setPmax(listing.priceMax)
                          setPmin(listing.priceMin)
                        }
                        handleListingFilterTracking(f.name, f.type, true)
                        setAppliedFilters(appliedFilters.filter((x) => x !== f))
                      }}
                    />
                  ))}
                </div>
                <div
                  className={`${styles.selectedFiltersList} ${styles['selectedFiltersList--mobile']}`}
                >
                  <Swiper
                    spaceBetween={8}
                    slidesPerView={'auto'}
                    className={styles.selectedFiltersSwiper}
                  >
                    {appliedFilters.map((f) => (
                      <SwiperSlide key={f.name}>
                        <FiltroApplicato
                          label={f.name}
                          onClose={() => {
                            if (f.role && f.role === 'priceFilter') {
                              setPmax(listing.priceMax)
                              setPmin(listing.priceMin)
                            }
                            handleListingFilterTracking(f.name, f.type, true)
                            setAppliedFilters(appliedFilters.filter((x) => x !== f))
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </div>
          <div className={styles.list}>
            {elementi?.map((elemento, index) => (
              <Fragment key={`${elemento.pk}-${elemento.prezzoScontato}`}>
                {(index === 3 || (elementi.length < 4 && index === 0)) && !!editorialeItem && (
                  <EditorialeCard
                    item={editorialeItem}
                    variant="secondarydark"
                    className={styles.listItem}
                  />
                )}
                <ProdottoCard
                  prodotto={{ ...elemento, categoriaComparatore: categoria }} // serve la categoria per il comparatore
                  compareMode={compareMode}
                  order={order}
                  appliedFilters={appliedFilters.length > 0 ? true : false}
                  compare={compareItems?.[categoria.pk]?.find((x) => x.pk === elemento.pk)}
                  loading={loading}
                  className={styles.listItem}
                />
              </Fragment>
            ))}
          </div>
          {listing?.paginator?.itemsPerPage <= 0 ||
          listing?.paginator?.currentPage === listing?.paginator?.numPages ? (
            <></>
          ) : (
            <div className={styles.loadMore}>
              <Button
                label={t('Mostra altri {0} prodotti', [`${listing?.paginator?.itemsPerPage}`])}
                variant="secondary"
                onClick={loadMore}
                loading={loading}
                href={listing?.paginator?.pages[listing?.paginator?.currentPage].href}
              />
            </div>
          )}
        </div>
      </div>
      <CompareBar
        prodotti={compareItems[categoria.pk]}
        open={compareMode}
        setOpen={setCompareMode}
        categoria={categoria}
        className={styles.compareBar}
      />
      <FiltersDrawer
        attributi={listing.attributi}
        appliedFilters={appliedFilters}
        onAttributoFilterChange={onAttributoFilterChange}
        order={order}
        orderOpts={orderOpts}
        onOrderChange={(e) => {
          setOrder(e.target.value)
        }}
        open={filtersDrawerOpen}
        setOpen={setFiltersDrawerOpen}
        priceRange={[listing?.priceMin, listing?.priceMax]}
        onPriceRangeChange={onSliderFilterChange}
        onFilterReset={onFiltersReset}
      />
    </>
  ) : (
    <></>
  )
}

export default ProdottiListing
