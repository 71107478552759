import React from 'react'
import useTrans from '@hooks/useTrans'
import { Close } from '@components/icons'
import { EditableVideo, WModal } from '@components/atoms'
import styles from './VideoModal.module.sass'

interface Props {
  video?: any
  open?: boolean
  onClose?: () => void
  className?: string
}

const VideoModal = (props: Props) => {
  const { video = undefined, open = false, onClose = undefined, className = '' } = props

  const t = useTrans()

  return (
    <WModal id="video_modal" open={open} className={`${styles.root} ${className}`}>
      <div className={styles.layout}>
        <button type="button" aria-label={t('Chiudi')} className={styles.close} onClick={onClose}>
          <Close />
        </button>
        {!!video?.idYoutube && (
          <EditableVideo
            item={{
              provider: 'youtube',
              videoId: video.idYoutube,
              autoplay: true,
              loop: false,
              controls: true,
              ratio: '16:9',
            }}
            preserveRatio
            className={styles.iframe}
          />
        )}
      </div>
    </WModal>
  )
}

export default VideoModal
