import React from 'react'
import { FormError } from 'react-pagebuilder/pb-components/atoms'
import groupBy from 'lodash/groupBy'
import Select from 'react-select'

const FormSelect = ({
  options = [],
  placeholder = '',
  name = '',
  value = '',
  className = '',
  readOnly = false,
  disabled = false,
  status = '',
  errorMessage = '',
  onBlur = () => {},
  onFocus = () => {},
  onChange = () => {},
  isSearchable = true,
  formatOptionLabel = undefined,
}) => {
  const classNames = `
    pb-form-select 
    ${status ? ` pb-form-select--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  const _groupedOptions = groupBy(options, 'groupLabel')
  const hasMultipleGroups = Object.keys(_groupedOptions)?.length > 1
  const groupedOptions = Object.keys(_groupedOptions)?.map((k) => ({
    label: k,
    options: _groupedOptions[k],
  }))

  const convertOptionToEvent = (option) => ({
    target: {
      type: 'select',
      name: name,
      value: option?.value,
      label: option?.label,
    },
  })

  const onLocalChange = (option) => {
    onChange(convertOptionToEvent(option))
  }

  return (
    <div className={classNames}>
      <Select
        className="pb-form-select"
        classNamePrefix="pb-react-select"
        isSearchable={isSearchable}
        isDisabled={disabled || false}
        readOnly={readOnly}
        menuPortalTarget={document.body}
        menuPlacement="top"
        instanceId={name}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onLocalChange}
        options={hasMultipleGroups ? groupedOptions : options}
        value={
          options?.find((option) => option.selected) ||
          options?.find((option) => option.value == value)
        }
        formatOptionLabel={formatOptionLabel}
      />
      <FormError
        className="pb-form-input__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
