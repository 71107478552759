import React from 'react'
import styles from './StoreLocatorGeoList.module.sass'
import { WContainer, WLink } from '@components/atoms'
import { ProvinciaNode, RegioneNode } from '@gql/graphql-get'

interface Props {
  title?: string
  items?: RegioneNode[] | ProvinciaNode[]
  className?: string
}
const StoreLocatorGeoList = (props: Props) => {
  const { title = undefined, items = [], className = '' } = props

  return items?.length > 0 ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          {!!title && <h2 className={styles.title}>{title}</h2>}
          <div className={styles.list}>
            {items.map((x) => (
              <WLink href={x.slug} className={styles.item}>
                {x.nome}
              </WLink>
            ))}
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default StoreLocatorGeoList
