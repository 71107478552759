import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { TabAttivitaBlockSettings } from './TabAttivitaBlockSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { getItemChildren } from 'react-pagebuilder/utils/node'
import { attivitaCardItemParams } from '../AttivitaCard'

export const defaultProps = {
  label: 'Label',
}

export const tabAttivitaBlockItemParams = (enabled, id, blockProps, children) => ({
  pk: id,
  name: blockProps.label,
  children: getItemChildren(enabled, children?.[0], attivitaCardItemParams),
})

export const dynamicProps = []

export const TabAttivitaBlock: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={`block-tab-attivita-block ${props.className}`}
      >
        {children?.[0]}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

TabAttivitaBlock.craft = withCraft({
  name: 'TabAttivitaBlock',
  defaultProps: defaultProps,
  settings: TabAttivitaBlockSettings,
})
