import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  ListingNewsEmakInformaNode,
  ListingNewsNode,
  PaginaNode,
  useListingNewsEmakInformaLazyQuery,
  useListingNewsLazyQuery,
} from '@gql/graphql'
import BlogTemplate from '@components/templates/BlogTemplate/BlogTemplate'
import CataloghiTemplate from '@components/templates/CataloghiTemplate/CataloghiTemplate'
import useTrans from '@hooks/useTrans'

interface BlogListingProps {
  pagina: PaginaNode
  listing: ListingNewsNode | ListingNewsEmakInformaNode
  isCatalogo?: boolean
  buttonUrl?: boolean
}

const BlogListing = ({
  pagina,
  listing,
  isCatalogo = false,
  buttonUrl = false,
}: BlogListingProps) => {
  const router = useRouter()
  const [page, setPage] = useState(listing.paginator.currentPage)
  const [articoli, setArticoli] = useState(listing.news)
  const [listingNewsQuery, { loading: loadingNews }] = useListingNewsLazyQuery({
    fetchPolicy: 'cache-first',
  })
  const [listingNewsEmakInforma, { loading: loadingNewsEmakInforma }] =
    useListingNewsEmakInformaLazyQuery({
      fetchPolicy: 'cache-first',
    })

  const nextPageLength = listing?.paginator?.itemsPerPage
  const categoria = 'categoria' in listing ? listing.categoria : undefined
  const categorie = 'categoria' in listing ? listing?.categorie : undefined
  const t = useTrans()

  const powerBarItems = categorie
    ? [
        {
          pk: 0,
          name: t('Tutti gli articoli'),
          selected: !categoria,
          url: listing?.root?.url || '/idee-verdi/',
        },
      ].concat(
        categorie
          ?.filter((x) => !x.isCatalogo)
          .map((x) => ({
            pk: x.pk,
            name: x.nome,
            selected: categoria?.slug === x.slug,
            url: x.url,
          })) ?? []
      )
    : []

  const fetchListing = async () => {
    if ('categoria' in listing) {
      const { data } = await listingNewsQuery({
        variables: {
          slug: categoria?.slug,
          params: { p: page },
        },
      })

      if (data?.listingNews?.news) {
        listing.paginator = data?.listingNews?.paginator
        setArticoli([...articoli, ...data.listingNews.news])
      }
    } else {
      const { data } = await listingNewsEmakInforma({
        variables: {
          slug: categoria?.slug,
          params: { p: page },
        },
      })

      if (data?.listingNewsEmakInforma?.news) {
        listing.paginator = data?.listingNewsEmakInforma?.paginator
        setArticoli([...articoli, ...data.listingNewsEmakInforma.news])
      }
    }
  }

  useEffect(() => {
    if (page === listing.paginator.currentPage) return
    fetchListing()
  }, [page])

  const handleMore = (event) => {
    event.preventDefault()
    router.replace(`${router.asPath.split('?')[0]}?page=${page + 1}`, undefined, {
      shallow: true,
      scroll: false,
    })
    setPage(page + 1)
  }

  const linkNextPage = listing?.paginator?.pages[listing?.paginator?.currentPage]?.href

  return isCatalogo ? (
    <CataloghiTemplate
      key={'categoria' in listing ? listing?.categoria?.pk : undefined}
      pagina={pagina}
      cataloghi={articoli}
      handleMore={handleMore}
      loading={loadingNews || loadingNewsEmakInforma}
      nextPageLength={nextPageLength}
      linkNextPage={linkNextPage}
      buttonUrl={buttonUrl}
    />
  ) : (
    <BlogTemplate
      key={'categoria' in listing ? listing?.categoria?.pk : undefined}
      pagina={pagina}
      articoli={articoli}
      powerBarItems={powerBarItems}
      handleMore={handleMore}
      loading={loadingNews || loadingNewsEmakInforma}
      nextPageLength={nextPageLength}
      linkNextPage={linkNextPage}
      buttonUrl={buttonUrl}
    />
  )
}

export default BlogListing
