import React from 'react'
import styles from './NumbersCard.module.sass'
import { EditableDescription, EditableTitle } from '@components/atoms'
import { MenuItemType } from '@utils/types'

interface Props {
  item?: MenuItemType
  editMode?: boolean
  className?: string
}

const NumbersCard = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${className}`}>
      <EditableTitle variant="h3" title={item.name} className={styles.title} editMode={editMode} />
      <EditableDescription
        description={item.description}
        className={styles.description}
        editMode={editMode}
      />
    </div>
  ) : (
    <></>
  )
}

export default NumbersCard
