import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ChevronLeft({ idVariant = '', className }: Props) {
  return (
    <IconBase name="chevron-left" w={24} className={className}>
      <path
        d="M9.8284 12.0007L14.7782 16.9504L13.364 18.3646L7 12.0007L13.364 5.63672L14.7782 7.05093L9.8284 12.0007Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ChevronLeft
