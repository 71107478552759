import React from 'react'
import { PaginaNode } from '@gql/graphql'
import { MenuItemType } from '@utils/types'
import { WImage, Button, WContainer, Breadcrumbs } from '@components/atoms'
import styles from './LandingHead.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  pagina: PaginaNode
  item: MenuItemType
  className?: string
}

const LandingHead = (props: Props) => {
  const { pagina = undefined, item = undefined, className = '' } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} theme="dark" mobileTheme="light" />
          <div className={styles.main}>
            {(!!item.intro || !!item.name || !!item.description) && (
              <div className={styles.content}>
                {!!item.intro && <p className={styles.intro}>{item.intro}</p>}
                {!!item.name && <h1 className={styles.title}>{item.name}</h1>}
                {!!item.description && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                )}
              </div>
            )}
            {!!item.url && !!item.ctaLabel && (
              <div className={styles.ctaBox}>
                <Button label={item.ctaLabel} href={item.url} newPage={item.blank} size="lg" />
              </div>
            )}
          </div>
        </div>
      </WContainer>
      <div className={`${styles.imageBox}`}>
        {!!pagina.mainImage && (
          <WImage
            src={pagina.mainImage}
            maxWidth={1920}
            maxHeight={600}
            objectFit="cover"
            alt={pagina.titolo}
            title={pagina.titolo}
            priority
            loading="eager"
            className={styles.desktopImage}
          />
        )}
        {!!pagina.previewImage && (
          <WImage
            src={pagina.previewImage}
            maxWidth={767}
            maxHeight={410} // Proporzioni per 200x375
            alt={pagina.titolo}
            title={pagina.titolo}
            priority
            loading="eager"
            className={styles.mobileImage}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default LandingHead
