import React, { useEffect, useState } from 'react'
import { WContainer, TabBar } from '@components/atoms'
import { SliderProdotti } from '@components/organisms'
import styles from './TabProdotti.module.sass'

interface Props {
  item: any
  className?: string
  correlati?: boolean
}

const TabProdotti = (props: Props) => {
  const { correlati, item = undefined, className = '' } = props

  const barItems = item.children?.map((x) => ({ label: x.boxTitolo, pk: x.pk })) || []
  const [selected, setSelected] = useState(barItems[0])

  useEffect(() => {
    if (!selected && barItems?.length > 0) setSelected(barItems[0])
  }, [barItems])

  return item?.children?.length > 0 ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          {(!!item.name || !!item.description) && (
            <div className={styles.main}>
              {!!item.name && <h2 className={styles.title}>{item.name}</h2>}
              {!!item.description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </div>
          )}
          <TabBar items={barItems} selected={selected} setSelected={setSelected} />
          <div className={styles.list}>
            {item.children.map((x) => (
              <div
                key={x.pk}
                className={`${styles.slider} ${
                  x.pk === selected?.pk ? styles['slider--visible'] : ''
                }`}
              >
                <SliderProdotti correlati={correlati ?? false} prodotti={x.prodottiTopDelMese} />
              </div>
            ))}
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default TabProdotti
