import { ROOT_NODE } from '@craftjs/core'
import React, { useEffect } from 'react'
import { TextEditorSettings } from './TextEditorSettings'
import { withBlock } from '../withBlock'
import { getAncestors } from '../../utils/node'
import { withCraft } from '../withCraft'
import { SimpleTextEditor } from '../SimpleTextEditor'
import { RichTextEditor } from '../../plugins/RichTextEditor'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'

export const defaultProps = {
  text: '<p>Enter your text!</p>',
  noHtml: false,
}

export const dynamicProps = ['text']

export const getTextEditor = (el, editMode) => {
  return editMode || el?.props?.block?.text ? (editMode ? el : el?.props?.block?.text) : null
}

export const TextEditor = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { text, noHtml } = blockProps

    const { isSSR } = props

    const _ = props.renderValue
    const __ = props.renderText

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { actions, query, enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    useEffect(() => {
      if (isSSR) return

      if (selected) {
        return
      }
      actions.history.ignore().setCustom(id, (customs) => {
        customs.editing = false
      })
    }, [selected])

    const enableEditable = () => {
      if (selected && (!props.conditions || !props.conditions.disabled)) {
        actions.history.ignore().setCustom(id, (customs) => {
          customs.editing = true
        })
      }
    }

    useEffect(() => {
      if (enabled) {
        const nodes = getAncestors(id, query)
        nodes.pop(ROOT_NODE)
        nodes.push(id)
        nodes.forEach((nodeId) => {
          const isDraggable = query.node(nodeId).isDraggable()
          if (isDraggable) {
            const element = document.getElementsByClassName(nodeId)[0]
            if (element) {
              if (customs.editing) {
                element.removeAttribute('draggable')
              } else {
                element.setAttribute('draggable', true)
              }
            }
          }
        })
      }
    }, [customs.editing])

    const className =
      'text-editor ' +
      (customs.editing ? '' : 'ql-editor ') +
      (props.block?.className ? props.block.className + ' ' : '') +
      props.className

    return enabled ? (
      noHtml ? (
        customs.editing ? (
          <div
            id={id}
            ref={(ref) => props.retRef(ref)}
            onDoubleClick={(e) => props.onDoubleClick(e, enableEditable)}
            className={className}
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            <SimpleTextEditor
              value={text}
              setValue={(value) => setProp((props) => (props.block.text = value), 500)}
            />
          </div>
        ) : (
          <div
            id={id}
            ref={(ref) => props.retRef(ref)}
            onDoubleClick={(e) => props.onDoubleClick(e, enableEditable)}
            className={className}
            dangerouslySetInnerHTML={{ __html: __(text) }}
          />
        )
      ) : (
        <div
          id={id}
          ref={(ref) => props.retRef(ref)}
          onDoubleClick={(e) => props.onDoubleClick(e, enableEditable)}
          className={className}
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <div
            className={`text-editor__wrap ${customs.editing ? '' : 'text-editor__wrap--render'}`}
          >
            <RichTextEditor
              key="editing"
              name={id}
              value={text}
              setValue={(value) => {
                actions.history.ignore().setProp(id, (props) => {
                  props.block.text = value
                })
              }}
              noHtml={noHtml}
              readOnly={!customs.editing}
            />
          </div>
        </div>
      )
    ) : (
      <div className={props.className} dangerouslySetInnerHTML={{ __html: __(text) }} />
    )
  },
  defaultProps,
  dynamicProps
)

TextEditor.craft = withCraft({
  name: 'Text',
  defaultProps: defaultProps,
  settings: TextEditorSettings,
})
