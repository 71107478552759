import React from 'react'
import styles from './LoaderOverlay.module.sass'
import { Loader } from '@components/icons'

interface Props {
  className?: string
}

const LoaderOverlay = (props: Props) => {
  const { className = '' } = props

  return (
    <div className={`${styles.root} ${className}`}>
      <Loader />
    </div>
  )
}

export default LoaderOverlay
