import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import { MenuItemType } from '@utils/types'
import { WContainer, Button, EditableTitle, EditableDescription, WImage } from '@components/atoms'
import styles from './BlogImageGallery.module.sass'
import { BlogImageGalleryCard } from '@components/molecules'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const BlogImageGallery = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    // L'id serve per le ancore interne nelle landing
    <div
      id="gallery"
      className={`${styles.root} ${
        styles[`root--n${item.children?.length < 5 ? item.children.length : 'Many'}`]
      } ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      {editMode ? (
        <>{item.children}</>
      ) : (
        !!Array.isArray(item.children) &&
        item.children?.length &&
        (item.children?.length < 5 ? (
          <div className={`${styles.list}`}>
            {item.children?.map((x) => (
              <BlogImageGalleryCard
                key={x.pk}
                item={x}
                numItems={item.children.length}
                className={styles.item}
              />
            ))}
          </div>
        ) : (
          <div className={`${styles.slider}`}>
            <Swiper
              slidesPerView={1.1}
              spaceBetween={20}
              modules={[A11y, Navigation, Pagination]}
              navigation={true}
              pagination={{ clickable: true, type: 'progressbar' }}
              breakpoints={{
                768: {
                  slidesPerView: 1,
                },
              }}
              className="swiper--bottomNav"
            >
              {item.children.map((x) => (
                <SwiperSlide key={x.pk}>
                  <BlogImageGalleryCard
                    item={x}
                    numItems={item.children.length}
                    className={styles.item}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))
      )}
    </div>
  ) : (
    <></>
  )
}

export default BlogImageGallery
