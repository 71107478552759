import useUtente from '@hooks/useUtente'
import { useRouter } from 'next/router'
import { PageBuilder } from 'react-pagebuilder/pagebuilder'
import { checkHashSharedPreview } from 'react-pagebuilder/utils/sharedPreview'

interface Props {
  contentTypeId: number
  objectId: number
  pkey: string
  serverSideContent?: any
  size?: '' | 'xs'
}

const PlaceholderReact = (props: Props) => {
  const { contentTypeId = 0, objectId = 0, pkey = '', serverSideContent = null, size = '' } = props

  const router = useRouter()
  const { utente } = useUtente()

  if (!utente?.edit || checkHashSharedPreview()) {
    return (
      <div className={`pb-content ${size ? `pb-content--${size}` : ''}`}>
        <PageBuilder
          contentTypeId={contentTypeId}
          objectId={objectId}
          pkey={pkey}
          serverSideContent={serverSideContent}
        />
      </div>
    )
  }

  if (utente?.edit) {
    return (
      <div className={`pb-content active ${size ? `pb-content--${size}` : ''}`}>
        <iframe
          className="pagebuilder-placeholder"
          src={`/${router.locale}/pagebuilder/?contentTypeId=${contentTypeId}&objectId=${objectId}&key=${pkey}`}
          title="Pagebuilder"
          style={{ border: '1px solid #484b52' }}
        />
      </div>
    )
  }

  return null
}

export default PlaceholderReact
