import React from 'react'
import styles from './Stepper.module.sass'
import { Check } from '@components/icons'

interface ItemProps {
  index: number
  step: {
    key?: number
    label?: string
    icon?: React.ReactNode
  }
  status: 'past' | 'active' | 'future'
}

interface Props {
  steps: {
    chiave?: string
    label?: string
    icon?: React.ReactNode
  }[]
  activeStepIndex: number
  className?: string
}

const StepperItem = (props: ItemProps) => {
  const { index = 1, step = undefined, status = 'future' } = props

  return (
    <span className={`${styles.item} ${styles[`item--${status}`]}`}>
      <span className={styles.dot}>{status === 'past' ? <Check /> : step.icon}</span>
      <span className={styles.label}>{step.label}</span>
    </span>
  )
}

const Stepper = (props: Props) => {
  const { steps = [], activeStepIndex = 1, className = '' } = props

  return (
    <div className={`${styles.root} ${className}`}>
      {steps?.length &&
        steps.map((step, index) => (
          <StepperItem
            key={step.chiave}
            index={index + 1}
            step={step}
            status={
              index + 1 === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : 'future'
            }
          />
        ))}
    </div>
  )
}

Stepper.Item = StepperItem

export default Stepper
