import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function LoaderBig({ idVariant = '', className }: Props) {
  return (
    <IconBase name="loader-big" w={24} className={className}>
      <g clipPath={`url(#loaderbig_${idVariant}_clip0_3272_14929)`}>
        <path
          d="M13.2218 1.22182C13.2218 0.547027 12.6748 0 12 0C11.3252 0 10.7782 0.547027 10.7782 1.22182V6.10909C10.7782 6.78388 11.3252 7.33091 12 7.33091C12.6748 7.33091 13.2218 6.78388 13.2218 6.10909V1.22182Z"
          fill="#161616"
        />
        <path
          d="M13.2218 17.8909C13.2218 17.2161 12.6748 16.6691 12 16.6691C11.3252 16.6691 10.7782 17.2161 10.7782 17.8909V22.7782C10.7782 23.453 11.3252 24 12 24C12.6748 24 13.2218 23.453 13.2218 22.7782V17.8909Z"
          fill="#959595"
        />
        <path
          d="M22.7782 13.2218C23.453 13.2218 24 12.6748 24 12C24 11.3252 23.453 10.7782 22.7782 10.7782H17.8909C17.2161 10.7782 16.6691 11.3252 16.6691 12C16.6691 12.6748 17.2161 13.2218 17.8909 13.2218H22.7782Z"
          fill="#D5D5D5"
        />
        <path
          d="M6.10908 13.2218C6.78388 13.2218 7.3309 12.6748 7.3309 12C7.3309 11.3252 6.78388 10.7782 6.10908 10.7782H1.22181C0.54702 10.7782 -6.67572e-06 11.3252 -6.67572e-06 12C-6.67572e-06 12.6748 0.54702 13.2218 1.22181 13.2218H6.10908Z"
          fill="#565656"
        />
        <path
          d="M3.2764 5.55326C2.69202 5.21587 1.94476 5.41609 1.60737 6.00048C1.26997 6.58486 1.4702 7.33212 2.05459 7.66951L6.28709 10.1131C6.87147 10.4505 7.61873 10.2503 7.95612 9.66593C8.29352 9.08155 8.09329 8.33429 7.50891 7.9969L3.2764 5.55326Z"
          fill="#404040"
        />
        <path
          d="M17.7127 13.8871C17.1283 13.5497 16.381 13.7499 16.0436 14.3343C15.7062 14.9187 15.9064 15.666 16.4908 16.0034L20.7233 18.447C21.3077 18.7844 22.055 18.5842 22.3924 17.9998C22.7298 17.4154 22.5295 16.6681 21.9452 16.3308L17.7127 13.8871Z"
          fill="#BFBFBF"
        />
        <path
          d="M18.4478 3.27673C18.7852 2.69234 18.5849 1.94509 18.0005 1.60769C17.4162 1.2703 16.6689 1.47052 16.3315 2.05491L13.8879 6.28741C13.5505 6.8718 13.7507 7.61905 14.3351 7.95644C14.9195 8.29384 15.6667 8.09361 16.0041 7.50923L18.4478 3.27673Z"
          fill="#FCFCFC"
        />
        <path
          d="M10.1127 17.7132C10.4501 17.1288 10.2499 16.3816 9.66552 16.0442C9.08113 15.7068 8.33388 15.907 7.99648 16.4914L5.55285 20.7239C5.21545 21.3083 5.41568 22.0555 6.00006 22.3929C6.58445 22.7303 7.3317 22.5301 7.6691 21.9457L10.1127 17.7132Z"
          fill="#808080"
        />
        <path
          d="M7.66912 2.05491C7.33172 1.47052 6.58447 1.2703 6.00008 1.60769C5.41569 1.94509 5.21547 2.69234 5.55286 3.27673L7.9965 7.50923C8.3339 8.09361 9.08115 8.29384 9.66554 7.95644C10.2499 7.61905 10.4501 6.8718 10.1128 6.28741L7.66912 2.05491Z"
          fill="#2B2B2B"
        />
        <path
          d="M16.0041 16.4914C15.6667 15.907 14.9195 15.7068 14.3351 16.0442C13.7507 16.3816 13.5505 17.1288 13.8879 17.7132L16.3315 21.9457C16.6689 22.5301 17.4161 22.7303 18.0005 22.3929C18.5849 22.0555 18.7851 21.3083 18.4477 20.7239L16.0041 16.4914Z"
          fill="#AAAAAA"
        />
        <path
          d="M21.9454 7.66925C22.5298 7.33185 22.73 6.5846 22.3926 6.00021C22.0553 5.41583 21.308 5.2156 20.7236 5.553L16.4911 7.99663C15.9067 8.33403 15.7065 9.08128 16.0439 9.66567C16.3813 10.2501 17.1285 10.4503 17.7129 10.1129L21.9454 7.66925Z"
          fill="#EAEAEA"
        />
        <path
          d="M7.50915 16.0031C8.09354 15.6657 8.29376 14.9185 7.95637 14.3341C7.61897 13.7497 6.87172 13.5495 6.28733 13.8869L2.05483 16.3305C1.47044 16.6679 1.27022 17.4151 1.60761 17.9995C1.94501 18.5839 2.69226 18.7841 3.27665 18.4467L7.50915 16.0031Z"
          fill="#6B6B6B"
        />
      </g>
      <defs>
        <clipPath id={`loaderbig_${idVariant}_clip0_3272_14929`}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default LoaderBig
