import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, EditableTitle, EditableDescription } from '@components/atoms'
import styles from './GallerySlide.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const GallerySlide = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      {!!item.image && (
        <WImage
          src={item.image}
          maxWidth={860}
          maxHeight={487}
          objectFit="cover"
          alt={item.alt}
          title={item.alt}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

export default GallerySlide
