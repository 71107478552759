import React, { useContext } from 'react'
import { RigaCarrelloNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import useProdotti from '@hooks/useProdotti'
import { CartContext } from '@utils/cartContext'
import { prezziProdotto, priceNotation } from '@utils/safe'
import { Button, Tag, WImage, WLink } from '@components/atoms'
import styles from './RigaPreCarrelloCard.module.sass'
import { trackingEcommerceEvent } from '@utils/gtm'
import { handleRemoveFromCartTracking } from '@utils/tracking'

interface Props {
  rigaCarrello?: RigaCarrelloNode
  className?: string
}

const RigaPreCarrelloCard = (props: Props) => {
  const { rigaCarrello = undefined, className = '' } = props

  const t = useTrans()
  const pp = prezziProdotto(rigaCarrello.prodotto.prodotto)
  const prezzi = [
    { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
    { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
    {
      key: 'current',
      label: rigaCarrello.prodotto.prodotto.promo
        ? t('Promo')
        : rigaCarrello.prodotto.prodotto.lancio
        ? t('Prezzo lancio')
        : '',
      value: pp?.prezzoFinale,
    },
  ].filter((x) => !!x.value)
  const { removeFromCart, cart } = useContext(CartContext)
  const { prodotti, loading } = useProdotti([rigaCarrello.prodotto.prodotto])
  const taglieOpts =
    prodotti[0]?.prodottiTendina?.map((x) => ({
      label: x.labelTendina,
      value: x.pk,
    })) || []
  const tagliaProdotto = rigaCarrello.prodotto.prodotto.pk

  const handleRemoveItem = () => {
    removeFromCart(rigaCarrello.prodotto.prodotto.pk)
    handleRemoveFromCartTracking(rigaCarrello.prodotto.prodotto, cart)
  }

  return rigaCarrello ? (
    <div
      className={`${styles.root} ${
        rigaCarrello.omaggio ? styles['root--omaggio'] : ''
      } ${className}`}
    >
      <WLink href={rigaCarrello.prodotto.prodotto.url} className={styles.imageBox}>
        <WImage
          src={rigaCarrello.prodotto.prodotto.previewImage}
          maxWidth={64}
          maxHeight={64}
          objectFit="contain"
        />
      </WLink>
      <div className={styles.main}>
        {rigaCarrello.prodotto.prodotto.categoria && (
          <span className={styles.category}>
            {rigaCarrello.prodotto.prodotto.categoria.boxTitolo}
          </span>
        )}
        <WLink href={rigaCarrello.prodotto.prodotto.url} className={styles.title}>
          {rigaCarrello.prodotto.prodotto.nome}
        </WLink>
        <div className={styles.bottom}>
          <div className={styles.details}>
            <div className={styles.attributes}>
              <div className={styles.attribute}>
                <span className={styles.attributeLabel}>{t('Quantita')}: </span>
                <span className={styles.attributeValue}>{rigaCarrello.quantita}</span>
              </div>
              {(prodotti[0].showTaglia || prodotti[0].showFormato) && (
                <div className={styles.attribute}>
                  <span className={styles.attributeLabel}>
                    {prodotti[0].showTaglia ? t('Taglia') : t('Formato')}:{' '}
                  </span>
                  <span className={styles.attributeValue}>
                    {taglieOpts.filter((x) => x.value === tagliaProdotto)?.[0]?.label}
                  </span>
                </div>
              )}
            </div>
            {!rigaCarrello.omaggio && (
              <Button
                label={t('Rimuovi')}
                hideDesktopIcon
                variant="ghost"
                size="sm"
                onClick={() => handleRemoveItem()}
                className={styles.removeButton}
              />
            )}
          </div>
          {rigaCarrello.omaggio ? (
            <Tag label={t('In omaggio')} variant="promo" className={styles.tag} />
          ) : (
            (!!prezzi.length || cart.promoApplicata?.promoDipendenti) && (
              <div className={styles.pricesBox}>
                {!!prezzi?.length && (
                  <div className={styles.prices}>
                    {prezzi.map((p) => (
                      <div className={`${styles.price} ${styles[`price--${p.key}`]}`} key={p.key}>
                        {!!p.label && <span className={styles.priceLabel}>{p.label}: </span>}
                        <span className={styles.priceValue}>
                          {priceNotation(p.value, false, prodotti[0].valuta?.simbolo)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {cart.promoApplicata?.promoDipendenti && (
                  <Tag label={cart.promoApplicata.titolo} size="sm" variant="black" />
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RigaPreCarrelloCard
