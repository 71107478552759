import React from 'react'
import styles from './FormError.module.sass'

interface Props {
  id: string
  message?: string | string[]
  variant?: '' | 'global'
  visible?: boolean
  className?: string
}

const FormError = (props: Props) => {
  const { id = '', message = '', variant = '', visible = false, className = '' } = props

  return visible && message ? (
    <div
      id={id}
      className={`${styles.root} ${variant ? styles[`root--${variant}`] : ''} ${className}`}
      aria-live={'assertive'}
    >
      <span className={styles.label}>{message as string}</span>
    </div>
  ) : (
    <></>
  )
}

export default FormError
