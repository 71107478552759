import React from 'react'
import { MenuItemType } from '@utils/types'
import styles from './BlogBanner.module.sass'
import { Button, EditableDescription, EditableTitle, WContainer } from '@components/atoms'
import { ArticoloCard, ArticoloSmallCard } from '@components/molecules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'
import { NewsNode } from '@gql/graphql'
import { handleSelectContentTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  item?: MenuItemType
  news?: NewsNode[]
  className?: string
  editMode?: boolean
}

const BlogBanner = (props: Props) => {
  const { item = undefined, news = [], className = '', editMode = false } = props

  const router = useRouter()

  return editMode || (news?.length && item) ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.main}>
            {(!!item.name || !!item.description) && (
              <div className={styles.content}>
                <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
                <EditableDescription
                  className={styles.description}
                  description={item.description}
                  editMode={editMode}
                />
              </div>
            )}
            {!!item.ctaLabel && !!item.url && (
              <div className={styles.ctaBox}>
                <Button
                  label={item.ctaLabel}
                  href={item.url}
                  onClick={() =>
                    handleSelectContentTracking(item.ctaLabel, 'blog', item.name, router.asPath)
                  }
                  size="lg"
                />
              </div>
            )}
          </div>
          <div className={styles.list}>
            {news.map((n, index) => (
              <ArticoloSmallCard news={n} key={n.pk} variant={index === 0 ? 'evidenza' : ''} />
            ))}
          </div>
          <div className={styles.slider}>
            <Swiper
              spaceBetween={20}
              slidesPerView={1.1}
              watchOverflow
              modules={[Pagination, A11y]}
              pagination={{
                type: 'progressbar',
              }}
              breakpoints={{
                576: { slidesPerView: 2.1 },
              }}
            >
              {news.map((n, index) => (
                <SwiperSlide key={index}>
                  <ArticoloCard news={n} key={n.pk} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default BlogBanner
