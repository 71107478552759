let crypto = undefined
try {
  crypto = require('crypto')
} catch (e) {}

const PARAM_HASH = 'share_code'
const PARAM_DRAFT = 'draft'
const PATH_PREVIEW = '/pagebuilder/share'

// Genera un hash che permette (successivamente alla verifica) la condivisione della versione pubblicata o della bozza di una pagina non ancora pubblicata
export const generateHashSharedPreview = (urlObj = null, path = null) => {
  let url = urlObj || null
  if (!url && !path) {
    const urlString = typeof window !== 'undefined' ? window?.top?.location?.href : null
    try {
      url = new URL(urlString)
    } catch (e) {
      return false
    }
  }
  const pagePath = path || url.pathname
  const hash = pagePath
    ? crypto
        ?.createHash('sha256', process.env.PAGEBUILDER_SECRET_PREVIEW_SHARE)
        ?.update(pagePath)
        .digest('hex')
    : null

  return hash
}

// Verifica che l'hash passato come query parameter coincida con quello generato sul momento (basato sul path della pagina)
export const checkHashSharedPreview = () => {
  const urlString = typeof window !== 'undefined' ? window?.top?.location?.href : null
  if (typeof urlString === 'string') {
    let url = null
    try {
      url = new URL(urlString)
    } catch (e) {
      return false
    }
    const hash = generateHashSharedPreview(url)
    const hashPassed = url?.searchParams?.get(PARAM_HASH)

    return hash && hashPassed ? hash === hashPassed : false
  }
  return false
}

// Verifica che l'hash passato alla funzione coincida con quello generato sul momento (basato sul path passato alla funzione)
export const checkHashPathSharedPreview = (hashPassed = null, path = null) => {
  if (hashPassed && path) {
    const hash = generateHashSharedPreview(null, path.split('?')[0])
    return hash && hashPassed ? hash === hashPassed : false
  }
  return false
}

// Genera l'url per la condivisione del contenuto (draft o pubblicato) basandosi sul path dell'url corrente e aggiungendo il parametro di hash
export const generateCurrentUrlSharedPreview = (isDraft = false) => {
  const urlString = typeof window !== 'undefined' ? window?.top?.location?.href : null
  if (typeof urlString === 'string') {
    let url = null
    try {
      url = new URL(urlString)
    } catch (e) {
      return ''
    }
    const hash = generateHashSharedPreview(url)
    if (hash) {
      url.searchParams.append(PARAM_HASH, hash)
      if (isDraft) {
        url.searchParams.append(PARAM_DRAFT, true)
      }
      // if (!url.pathname.includes(PATH_PREVIEW)) url.pathname = PATH_PREVIEW + url.pathname
      return url.href
    }
    return ''
  }
  return ''
}

// Verifica se l'url condiviso è quello della bozza
export const isSharedDraft = () => {
  const urlString = typeof window !== 'undefined' ? window?.top?.location?.href : null
  if (typeof urlString === 'string') {
    let url = null
    try {
      url = new URL(urlString)
    } catch (e) {
      return false
    }
    const isDraft = url?.searchParams?.get(PARAM_DRAFT)
    return isDraft === 'true' || false
  }
  return false
}
