import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Button, FormikField, FormText } from '@components/atoms'
import styles from './CheckoutFormRivenditore.module.sass'
import { RivenditoreNode, useRivenditoriGeolocatedLazyQuery } from '@gql/graphql'
import { handleStoreLocatorSearchTracking, handleStoreLocatorSelectTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  formikProps: any
  className?: string
  rivenditori: RivenditoreNode[]
  setRivenditori: (rivenditori: RivenditoreNode[]) => void
}

const CheckoutFormRivenditore = (props: Props) => {
  const { formikProps = undefined, className = '', rivenditori = [], setRivenditori = null } = props

  const [value, setValue] = useState(undefined)
  const [rivenditoreGeolocatedQuery, { loading }] = useRivenditoriGeolocatedLazyQuery()

  const t = useTrans()
  const router = useRouter()

  useEffect(() => {
    if (formikProps?.values?.rivenditoreId) {
      const selectedRivenditore = rivenditori.find(
        (r) => r.pk === Number(formikProps.values.rivenditoreId)
      )

      if (!selectedRivenditore) {
        return
      }

      handleStoreLocatorSelectTracking(
        [
          Number(selectedRivenditore.longitudine.replace(',', '.')),
          Number(selectedRivenditore.latitudine.replace(',', '.')),
        ],
        selectedRivenditore?.ragioneSociale,
        router.asPath
      )
    }
  }, [formikProps?.values?.rivenditoreId])

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.search}>
        <label className={styles.searchLabel} htmlFor={'checkout_form_rivenditore_geocoder'}>
          {t('Inserisci la città o il CAP per trovare il Rivenditore più vicino')}
        </label>
        <div className={styles.searchLayout}>
          {/* FormGeocoder? TODO*/}
          <FormText
            id="checkout_form_rivenditore_geocoder"
            placeholder={t('Digita la città o il CAP')}
            className={styles.searchField}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            label={t('Cerca')}
            variant="secondary"
            onClick={async (event) => {
              event.preventDefault()
              const { data } = await rivenditoreGeolocatedQuery({
                variables: {
                  utenteGeo: value,
                },
              })
              if (data?.rivenditoriGeolocated) {
                setRivenditori(data.rivenditoriGeolocated)
                handleStoreLocatorSearchTracking(
                  [
                    Number(data.rivenditoriGeolocated[0].longitudine.replace(',', '.')),
                    Number(data.rivenditoriGeolocated[0].latitudine.replace(',', '.')),
                  ],
                  router.asPath
                )
              }
            }}
            loading={loading}
          />
        </div>
      </div>
      {!!rivenditori?.length && (
        <FormikField
          formik={formikProps}
          id="checkout_rivenditore_id"
          name="rivenditoreId"
          type="rivenditore_radio"
          rivenditori={rivenditori}
          layout="full"
        />
      )}
    </div>
  )
}

export default CheckoutFormRivenditore
