import React from 'react'
import { defaultProps } from './index'
import CtaToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import ImageToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'
import { ToolbarSection } from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ToolbarItem'

export const TextImageSmallSettings = (settingsProps) => {
  const themeOpts = [
    { label: 'Light', value: 'light' },
    { label: 'Neutral', value: 'neutrallight' },
    { label: 'Secondary dark', value: 'secondarydark' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          options={themeOpts}
          label="Theme"
          defaultValue={defaultProps['theme']}
        />
      </ToolbarSection>
      {!settingsProps.floating && (
        <>
          <ImageToolbarSection defaultProps={defaultProps} />
          <CtaToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const TextImageSmallFloatingSettings = () =>
  React.createElement(TextImageSmallSettings, { floating: true })
