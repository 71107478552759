import React from 'react'
import { Download } from '@components/icons'
import { WLink } from '@components/atoms'
import styles from './PDPDownloadCard.module.sass'
import { handleDownloadTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  x?: any
  url: string
  label?: string
  editMode?: boolean
  className?: string
  catalogo?: boolean
  manuale?: boolean
}

const PDPDownloadCard = (props: Props) => {
  const {
    x,
    label = undefined,
    url = undefined,
    editMode = undefined,
    className = '',
    catalogo,
    manuale,
  } = props

  const router = useRouter()

  const onDownloadTrack = () => {
    let type = ''
    if (catalogo) type = 'catalogo'
    if (manuale) type = 'manuale'
    handleDownloadTracking(label, type, router.asPath)
  }

  return url || editMode ? (
    <WLink
      href={editMode ? '#' : url}
      className={`${styles.root} ${className}`}
      external
      newPage
      onClick={onDownloadTrack}
    >
      {!!label && <span className={styles.label}>{label}</span>}
      <span className={styles.icon}>
        <Download />
      </span>
    </WLink>
  ) : (
    <></>
  )
}

export default PDPDownloadCard
