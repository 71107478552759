import React, { useEffect, useRef, useState } from 'react'
import styles from './Dropdown.module.sass'
import { ChevronDown } from '@components/icons'
import { clickInRef } from '@utils/safe'

interface Props {
  id: string | number
  value?: string | number
  direction?: 'top' | 'bottom'
  options?: { label: string; value: string | number }[]
  disabled?: boolean
  onChange?: (v: string | number) => void
  onClick?: () => boolean
  className?: string
}

const Dropdown = (props: Props) => {
  const {
    id = undefined,
    value = undefined,
    direction = 'bottom',
    options = [],
    disabled = false,
    onChange = undefined,
    onClick = undefined,
    className = '',
  } = props

  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        setOpen(false)
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  useEffect(() => {
    const clickHandler = (event) => {
      if (!clickInRef(event, ref?.current)) {
        setOpen(false)
      }
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  }, [])

  return (
    <div
      className={`${styles.root} ${open ? styles['root--open'] : ''} ${
        styles[`root--${direction}`]
      } ${className}`}
      ref={ref}
    >
      <button
        className={styles.trigger}
        id={`dropdown_${id}_trigger`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : 'false'}
        disabled={disabled}
        onClick={() => {
          if (onClick) {
            const res = onClick()
            if (res) setOpen(!open)
          } else setOpen(!open)
        }}
      >
        <span className={styles.triggerLabel}>
          {options.filter((x) => x.value === value)?.[0]?.label}
        </span>
        <span className={styles.arrow}>
          <ChevronDown />
        </span>
      </button>
      <div className={styles.menu} aria-labelledby={`dropdown_${id}_trigger`}>
        {options.map((x) => (
          <button
            key={x.value}
            type="button"
            onClick={() => {
              onChange(x.value)
              setOpen(false)
            }}
            className={`${styles.option} ${x.value === value ? styles['option--selected'] : ''}`}
          >
            {x.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Dropdown
