import React, { useEffect, useState, ReactNode } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useTrans, useUtente } from 'hooks'
import { api, getApi, isToolbarVisible } from 'utils'
import { PaginaType } from 'utils/types'
// import { BULK_SAVE_MENU_ITEM } from '@gql/queries'
import { TOOLBAR } from 'utils/endpoints'
// import { Toolbar as MenuEditorToolbar } from 'menu-editor/components/organisms'
import { Tooltip } from 'components/atoms'
import styles from './Toolbar.module.sass'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

interface ButtonProps {
  label?: string
  disabled?: boolean
  icon?: ReactNode
  iconPos?: 'left' | 'right'
  variant?: 'primary' | 'secondary' | 'ghost'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
}

interface Props {
  page?: PaginaType
  item?: any
}

const Loader = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 10.3334C2 14.7517 5.58172 18.3334 10 18.3334C14.4183 18.3334 18 14.7517 18 10.3334C18 5.9151 14.4183 2.33337 10 2.33337"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

const Info = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#toolbar_info)">
      <path
        d="M9.16675 6.66675C9.16675 6.20651 9.53984 5.83342 10.0001 5.83342C10.4603 5.83342 10.8334 6.20651 10.8334 6.66675C10.8334 7.12699 10.4603 7.50008 10.0001 7.50008C9.53984 7.50008 9.16675 7.12699 9.16675 6.66675ZM10.0001 14.1667C10.4584 14.1667 10.8334 13.7917 10.8334 13.3334V10.0001C10.8334 9.54175 10.4584 9.16675 10.0001 9.16675C9.54175 9.16675 9.16675 9.54175 9.16675 10.0001V13.3334C9.16675 13.7917 9.54175 14.1667 10.0001 14.1667ZM10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.6751 3.33341 10.0001C3.33341 6.32508 6.32508 3.33341 10.0001 3.33341C13.6751 3.33341 16.6667 6.32508 16.6667 10.0001C16.6667 13.6751 13.6751 16.6667 10.0001 16.6667Z"
        fill="#141414"
      />
    </g>
    <defs>
      <clipPath id="toolbar_info">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const ToolbarButton = (props: ButtonProps) => {
  const {
    label = '',
    variant = 'primary',
    disabled = false,
    icon = null,
    iconPos = 'left',
    onClick = () => {},
    href = null,
    newPage = false,
    className = '',
    loading = false,
  } = props

  const classeNames = `
    ${styles.button} 
    ${styles[`button--${variant}`]}
    ${icon && !label ? styles['button--no-label'] : ''}
    ${
      icon
        ? styles[`button--icon-${iconPos}`]
        : variant === 'ghost'
        ? styles[`button--icon-right`]
        : ''
    }
    ${loading ? styles['button--loading'] : ''}
    ${className ? className : ''}`

  const buttonContent = (
    <>
      {icon && <span className={styles.buttonIcon}>{icon}</span>}
      {label && <span className={`${styles.buttonLabel}`}>{label}</span>}
      {loading && (
        <span className={styles.buttonLoader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    <a href={href} className={classeNames} target={newPage ? '_blank' : undefined}>
      {buttonContent}
    </a>
  ) : (
    <button type={'button'} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  )
}

const Toolbar = (props: Props) => {
  const { page = null, item = null } = props

  const router = useRouter()
  const t = useTrans()
  const { utente } = useUtente()
  const dispatch = useDispatch()
  const menuChanges = useSelector((state: any) => state.menu.menuChanges)

  const [paginaContienePagebuilder, setPaginaContienePagebuilder] = useState(false)
  const [paginaContieneMenuItemEditabili, setPaginaContieneMenuItemEditabili] = useState(false)
  const [menuItemsSaving, setMenuItemSaving] = useState(false)

  // const [bulkSaveMenuItems] = useMutation(BULK_SAVE_MENU_ITEM, {
  //   onCompleted: (res) => {
  //     setMenuItemSaving(false)
  //     if (res.bulkSaveMenuItem.status) {
  //       for (let x in res.bulkSaveMenuItem.menuItems) {
  //         dispatch({ type: 'REPLACE_MENU', payload: res.bulkSaveMenuItem.menuItems[x] })
  //       }
  //       dispatch({ type: 'CLEAR_MENU_CHANGES' })
  //     }
  //   },
  // })

  useEffect(() => {
    setTimeout(() => {
      setPaginaContienePagebuilder(document.getElementsByClassName('pb-content').length > 0)
      setPaginaContieneMenuItemEditabili(true)
    }, 1000)
  }, [router.asPath])

  const handleSwitchMode = (edit: boolean) => {
    getApi(window.location.hostname)
      .post(TOOLBAR + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  // const hasMenuChanges =
  //   Object.keys(menuChanges.edits).length ||
  //   Object.keys(menuChanges.adds).length ||
  //   menuChanges.removes.length

  // const handleSaveMenuItems = () => {
  //   if (hasMenuChanges) {
  //     setMenuItemSaving(true)
  //     bulkSaveMenuItems({
  //       variables: {
  //         adds: Object.keys(menuChanges.adds).reduce(
  //           (res, parentId) =>
  //             res.concat(
  //               menuChanges.adds[parentId].map((a) => ({
  //                 ...Object.keys(a)
  //                   .filter((k) => k !== 'key')
  //                   .reduce((res, k) => ({ ...res, [k]: a[k] }), {}), // rimuovo 'key' che serve solo a frontend
  //                 video: 'video' in a ? a.video.id || null : undefined,
  //                 videoMobile: 'videoMobile' in a ? a.videoMobile.id || null : undefined,
  //                 image: 'image' in a ? a.image.id || null : undefined,
  //                 imageMobile: 'imageMobile' in a ? a.imageMobile.id || null : undefined,
  //                 linkedObjectId:
  //                   'linkedObject' in a ? a.linkedObject.linkedObjectId || null : undefined,
  //                 linkedContentTypeId:
  //                   'linkedObject' in a ? a.linkedObject.linkedContentTypeId || null : undefined,
  //                 linkedObject: undefined,
  //                 file: 'file' in a ? a.file.id || null : undefined,
  //                 parentId: parseInt(parentId),
  //               }))
  //             ),
  //           []
  //         ),
  //         edits: Object.keys(menuChanges.edits).map((x) => ({
  //           ...menuChanges.edits[x],
  //           video:
  //             'video' in menuChanges.edits[x] ? menuChanges.edits[x].video.id || null : undefined,
  //           videoMobile:
  //             'videoMobile' in menuChanges.edits[x]
  //               ? menuChanges.edits[x]?.videoMobile?.id || null
  //               : undefined,
  //           image:
  //             'image' in menuChanges.edits[x] ? menuChanges.edits[x].image.id || null : undefined,
  //           imageMobile:
  //             'imageMobile' in menuChanges.edits[x]
  //               ? menuChanges.edits[x]?.imageMobile?.id || null
  //               : undefined,
  //           linkedObjectId:
  //             'linkedObject' in menuChanges.edits[x]
  //               ? menuChanges.edits[x].linkedObject.linkedObjectId || null
  //               : undefined,
  //           linkedContentTypeId:
  //             'linkedObject' in menuChanges.edits[x]
  //               ? menuChanges.edits[x].linkedObject.linkedContentTypeId || null
  //               : undefined,
  //           linkedObject: undefined,
  //           file: 'file' in menuChanges.edits[x] ? menuChanges.edits[x].file.id || null : undefined,
  //           id: parseInt(x),
  //         })),
  //         removes: menuChanges.removes,
  //       },
  //     })
  //   }
  // }

  const adminUrl = (page?.adminUrl ? new URL(page?.adminUrl)?.origin || '' : '') + '/d/admin/'

  const VisibilitaTooltipDescription = (props: any) => {
    const { item } = props
    return (
      <div>
        <p>Stato pubblicazione: {item.pubblicato ? 'pubblicato' : 'non pubblicato'}</p>
        {item.visibilitaDal && <p>Visibilità dal: {item.visibilitaDal}</p>}
        {item.visibilitaAl && <p>Visibilità fino al: {item.visibilitaAl}</p>}
      </div>
    )
  }

  if (!isToolbarVisible(utente)) return null

  return (
    <>
      {/* <MenuEditorToolbar /> */}
      <div className={`${styles.root} toolbar`}>
        {utente.isImpersonate ? (
          <div className={`${styles.user} ${styles['user--impersonate']}`}>
            Stai impersonando{' '}
            {utente.nome || utente.cognome ? (
              <span>
                {utente.nome} {utente.cognome}
              </span>
            ) : (
              <span>{utente.email}</span>
            )}{' '}
            - <a href="/d/impersonate/stop/">{t('Smetti di impersonare')}</a>
          </div>
        ) : (
          <>
            <ToolbarButton
              label="Admin"
              href={adminUrl}
              variant="secondary"
              newPage
              className={`${styles.adminLink} ${styles.button}`}
            />
            <div className={styles.user}>
              {utente.nome} {utente.cognome}
            </div>
            <section className={styles.left} />
            <section className={styles.right}>
              {item && 'visibile' in item ? (
                <span className={styles.status}>
                  <span>
                    {t('Stato: ')}
                    <span className={`${styles[`visibilita--${item.visibile}`]}`}>
                      {item.visibile ? t('visibile') : t('non visibile')}
                    </span>
                  </span>
                  {/* <Tooltip
                    id={`tooltip-toolbar-item-visibilita`}
                    label={<VisibilitaTooltipDescription item={item} />}
                    pos="bottom"
                    className={styles.tooltip}
                  >
                    <span className={styles.info}>
                      <Info />
                    </span>
                  </Tooltip> */}
                </span>
              ) : (
                page &&
                'visibile' in page && (
                  <span className={styles.status}>
                    <span>
                      Stato:{' '}
                      <span className={`${styles[`visibilita--${page.visibile}`]}`}>
                        {page.visibile ? 'visibile' : 'non visibile'}
                      </span>
                    </span>
                    <Tooltip
                      id={`tooltip-toolbar-page-visibilita`}
                      label={<VisibilitaTooltipDescription item={page} />}
                      pos="bottom"
                      className={styles.tooltip}
                    >
                      <span className={styles.info}>
                        <Info />
                      </span>
                    </Tooltip>
                  </span>
                )
              )}
              {item && item.adminUrl && (
                <ToolbarButton
                  label={t('Modifica {0}', [item.modelClass?.capitalize()])}
                  variant="ghost"
                  href={item.adminUrl}
                  newPage
                />
              )}
              {page && page.adminUrl && item.adminUrl !== page.adminUrl && (
                <ToolbarButton
                  label={t('Admin Pagina')}
                  variant="ghost"
                  href={page.adminUrl}
                  newPage
                />
              )}
              <div className={styles.actions}>
                {paginaContienePagebuilder &&
                  (utente.edit ? (
                    <>
                      {/* {paginaContieneMenuItemEditabili && (
                        <>
                          <Tooltip
                            id={`tooltip-toolbar-menuitem-save`}
                            label={'Salva le modifiche agli elementi della pagina'}
                            pos="bottom"
                            className={styles.tooltip}
                          >
                            <div>
                              <ToolbarButton
                                icon={
                                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path
                                        d="M4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z"
                                        fill="currentColor"
                                      />
                                    </g>
                                  </svg>
                                }
                                label="Salva modifiche"
                                onClick={() => handleSaveMenuItems()}
                                disabled={!hasMenuChanges || menuItemsSaving}
                                className={styles.button}
                                loading={menuItemsSaving}
                              />
                            </div>
                          </Tooltip>
                        </>
                      )} */}
                      <Tooltip
                        id={`tooltip-toolbar-vedi-pubblicata`}
                        label={'Visualizza il contenuto pubblicato della pagina'}
                        pos="bottom"
                        className={styles.tooltip}
                      >
                        <div>
                          <ToolbarButton
                            label={t('Vedi pubblicata')}
                            onClick={() => handleSwitchMode(false)}
                          />
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip
                      id={`tooltip-toolbar-modifica`}
                      label={'Modifica il contenuto della pagina col pagebuilder'}
                      pos="bottom"
                      className={styles.tooltip}
                    >
                      <div>
                        <ToolbarButton
                          label={t('Modifica')}
                          variant="secondary"
                          onClick={() => handleSwitchMode(true)}
                        />
                      </div>
                    </Tooltip>
                  ))}
                {router.isPreview ? (
                  <Tooltip
                    id={`tooltip-toolbar-stop-preview`}
                    label={
                      'Con la modalità preview disattivata non potrai visualizzare le pagine nello stato "non visibile"'
                    }
                    pos="bottom"
                    className={styles.tooltip}
                  >
                    <div>
                      <ToolbarButton
                        label={t('Disattiva modalità preview')}
                        variant="secondary"
                        href={`/api/stop-preview?secret=${
                          process.env.PREVIEW_MODE_SECRET
                        }&redirect=${new URL(window.location.href).pathname}`}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    id={`tooltip-toolbar-attiva-preview`}
                    label={
                      'Con la modalità preview attiva puoi visualizzare le pagine anche se nello stato "non visibile"'
                    }
                    pos="bottom"
                    className={styles.tooltip}
                  >
                    <div>
                      <ToolbarButton
                        label={t('Attiva modalità preview')}
                        variant="secondary"
                        href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${
                          new URL(window.location.href).pathname
                        }`}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </section>
            <section className={styles.rightMobile}>
              {router.isPreview ? (
                <Tooltip
                  id={`tooltip-toolbar-stop-preview`}
                  label={
                    'Con la modalità preview disattivata non potrai visualizzare le pagine nello stato "non visibile"'
                  }
                  pos="bottom"
                  className={styles.tooltip}
                >
                  <div>
                    <ToolbarButton
                      label={t('Disattiva preview')}
                      variant="secondary"
                      href={`/api/stop-preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${
                        new URL(window.location.href).pathname
                      }`}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  id={`tooltip-toolbar-attiva-preview`}
                  label={
                    'Con la modalità preview attiva puoi visualizzare le pagine anche se nello stato "non visibile"'
                  }
                  pos="bottom"
                  className={styles.tooltip}
                >
                  <div>
                    <ToolbarButton
                      label={t('Attiva preview')}
                      variant="secondary"
                      href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${
                        new URL(window.location.href).pathname
                      }`}
                    />
                  </div>
                </Tooltip>
              )}
            </section>
          </>
        )}
      </div>
    </>
  )
}

export default Toolbar
