import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import useTrans from '@hooks/useTrans'
import { Button, FormikField, FormLayout } from '@components/atoms'
import styles from './CheckoutFormPagamento.module.sass'
import { getAssetsUrl } from '@utils/index'
import { SistemaPagamentoNode } from '@gql/graphql'

interface Props {
  onCompleted?: (v: any) => void
  className?: string
  sistemiPagamento?: SistemaPagamentoNode[]
  handlePaymentCheckoutTracking: (paymentMethod: string, cart) => void
  cart: any
}

const CheckoutFormPagamento = (props: Props) => {
  const {
    onCompleted = undefined,
    className = '',
    sistemiPagamento = [],
    handlePaymentCheckoutTracking,
    cart,
  } = props

  const t = useTrans()

  const pagamentoOpts = sistemiPagamento.map((sistemaPagamento) => ({
    label: sistemaPagamento?.famiglia?.nome,
    description: sistemaPagamento?.descrizione,
    value: sistemaPagamento?.pk.toString(),
    loghi: [
      {
        url: getAssetsUrl(`/images/${sistemaPagamento?.famiglia?.chiave}.png`),
        alt: sistemaPagamento?.famiglia?.nome,
      },
    ],
  }))

  const [initialValues, setInitialValues] = useState({
    pagamento: pagamentoOpts[0]?.value,
  })

  const getSelectedMethod = (value: string) => {
    return pagamentoOpts.find((opt) => opt.value === value)
  }

  useEffect(() => {
    if (sistemiPagamento.length > 0) {
      // Set the initial value when sistemiPagamento is populated
      setInitialValues({
        pagamento: pagamentoOpts[0]?.value || '',
      })
    }
  }, [sistemiPagamento])

  return (
    <div className={`${styles.root} ${className}`}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const selectedMethod = getSelectedMethod(values.pagamento)

          if (onCompleted) {
            onCompleted(values).then(() => {
              handlePaymentCheckoutTracking(selectedMethod?.label, cart)
              setSubmitting(false)
            })
          }
        }}
        enableReinitialize
      >
        {(formikProps) => (
          <Form className={styles.form}>
            <FormLayout>
              <FormikField
                formik={formikProps}
                id={'checkout_pagamento'}
                name="pagamento"
                type="radio"
                options={pagamentoOpts}
                label={t('Scegli un metodo di pagamento:')}
                required
                fieldVariant="box"
                layout="full"
              />
            </FormLayout>
            <div className={styles.ctaBox}>
              <Button
                type="submit"
                label={t("Conferma l'acquisto")}
                size="lg"
                loading={formikProps.isSubmitting}
              />
            </div>
            <div className={styles.notes}>
              <strong>
                <u>
                  {t(
                    "L'ordine si intende confermato previa verifica nei magazzini dei nostri rivenditori."
                  )}
                </u>
              </strong>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CheckoutFormPagamento
