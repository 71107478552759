import { Element } from '@craftjs/core'
import {
  AttivitaCard,
  BannerBlock,
  BlogImageGallery,
  BlogImageGalleryCard,
  BlogVideo,
  Button,
  CardGroup,
  HomeHero,
  HomeHeroBlock,
  HomeHeroSlide,
  TabAttivita,
  TabAttivitaBlock,
  TextEditor,
  TextImage,
  TextImageLarge,
  TextSection,
  Text,
  WContainer,
  RicercaProdottoCardSection,
  RicercaProdottoCard,
  PDPDownloadCard,
  IssuuEmbed,
  IconTextCard,
  IconTextSection,
  TextImageSmall,
  VideoTextSection,
  Gallery,
  GallerySlide,
  TabellaDifferenze,
  TabellaDifferenzeCard,
  TabellaDifferenzeCardItem,
  Accordion,
  TextBox,
  BlogBanner,
  NumbersSection,
  NumbersCard,
} from 'react-pagebuilder/components'

import {
  Container,
  Columns2,
  Button as ButtonIcon,
  Text as TextIcon,
  Image as ImageIcon,
  Video as VideoIcon,
} from 'react-pagebuilder/pb-components/icons'

// shortcut
const INTRO = <TextEditor block={{ text: 'Intro', noHtml: true }} />
const DATA = <TextEditor block={{ text: 'Data', noHtml: true }} />
const TITOLO = <TextEditor block={{ text: 'Titolo', noHtml: true }} />
const DESCRIZIONE = <TextEditor block={{ text: 'Lorem ipsum dolor sit amet' }} />
const CAPTION = <TextEditor block={{ text: 'Lorem ipsum dolor sit amet' }} />
const CTA_LABEL = <TextEditor block={{ text: 'Button', noHtml: true }} />

export const WCONTAINER = <Element canvas is={WContainer}></Element>

export const TEXT = (
  <Element is={Text}>
    <TextEditor
      block={{
        text: '<p>Hello world!</p>',
        className: 'text__content',
      }}
    />
  </Element>
)

export const BUTTON = <Element is={Button}>{CTA_LABEL}</Element>

export const TEXT_IMAGE_LARGE = (
  <Element is={TextImageLarge}>
    {INTRO}
    {TITOLO}
    {DESCRIZIONE}
    {CAPTION}
    <Element is={CardGroup} canvas type={'textImageLarge'}>
      {BUTTON}
      {BUTTON}
    </Element>
    {DATA}
  </Element>
)

export const TEXT_IMAGE = (
  <Element is={TextImage}>
    {INTRO}
    {DATA}
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type={'textImage'}>
      {BUTTON}
      {BUTTON}
    </Element>
    {CAPTION}
  </Element>
)

export const BANNER_BLOCK = (
  <Element is={BannerBlock}>
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type={'bannerBlock'}></Element>
  </Element>
)

export const TEXT_SECTION = (
  <Element is={TextSection}>
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type={'textSection'}>
      {BUTTON}
      {BUTTON}
    </Element>
  </Element>
)

export const HOME_HERO_SLIDE = (
  <Element is={HomeHeroSlide}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const HOME_HERO_BLOCK = (
  <Element is={HomeHeroBlock}>
    <Element is={CardGroup} canvas type="homeHeroBlock">
      {HOME_HERO_SLIDE}
    </Element>
  </Element>
)

export const HOME_HERO = (
  <Element is={HomeHero}>
    <Element is={CardGroup} canvas type="homeHero">
      {HOME_HERO_BLOCK}
    </Element>
  </Element>
)

export const ATTIVITA_CARD = <Element is={AttivitaCard}></Element>

export const TAB_ATTIVITA_BLOCK = (
  <Element is={TabAttivitaBlock}>
    <Element is={CardGroup} canvas type="tabAttivitaBlock">
      {ATTIVITA_CARD}
    </Element>
  </Element>
)

export const TAB_ATTIVITA = (
  <Element is={TabAttivita}>
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type="tabAttivita">
      {TAB_ATTIVITA_BLOCK}
    </Element>
  </Element>
)

export const BLOG_IMAGE_GALLERY_CARD = <Element is={BlogImageGalleryCard} />
export const BLOG_IMAGE_GALLERY = (
  <Element is={BlogImageGallery}>
    <Element is={CardGroup} canvas type="blogImageGallery">
      {BLOG_IMAGE_GALLERY_CARD}
    </Element>
  </Element>
)

export const BLOG_VIDEO = <Element is={BlogVideo} />

export const RICERCA_PRODOTTO_CARD = <Element is={RicercaProdottoCard} />

export const RICERCA_PRODOTTO_CARD_SECTION = (
  <Element is={RicercaProdottoCardSection} canvas>
    {RICERCA_PRODOTTO_CARD}
    {RICERCA_PRODOTTO_CARD}
  </Element>
)

export const PDP_DOWNLOAD_CARD = <Element is={PDPDownloadCard} />

export const ISSUU_EMBED = <Element is={IssuuEmbed} />

export const ICON_TEXT_CARD = (
  <Element is={IconTextCard}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const ICON_TEXT_SECTION = (
  <Element is={IconTextSection}>
    {TITOLO}
    {DESCRIZIONE}
    <Element canvas is={CardGroup} type="iconTextSectionButtons">
      {BUTTON}
      {BUTTON}
    </Element>
    <Element canvas is={CardGroup} type="iconTextSectionCards">
      {ICON_TEXT_CARD}
      {ICON_TEXT_CARD}
    </Element>
  </Element>
)

export const TEXT_IMAGE_SMALL = (
  <Element is={TextImageSmall}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const VIDEO_TEXT_SECTION = (
  <Element is={VideoTextSection}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const GALLERY_SLIDE = <Element is={GallerySlide} />
export const GALLERY = (
  <Element is={Gallery}>
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type="gallery">
      {GALLERY_SLIDE}
    </Element>
  </Element>
)

export const TABELLA_DIFFERENZE_CARD_ITEM = (
  <Element is={TabellaDifferenzeCardItem}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const TABELLA_DIFFERENZE_CARD = (
  <Element is={TabellaDifferenzeCard}>
    {TITOLO}
    <Element is={CardGroup} canvas type="tabellaDifferenzeCard">
      {TABELLA_DIFFERENZE_CARD_ITEM}
      {TABELLA_DIFFERENZE_CARD_ITEM}
    </Element>
  </Element>
)

export const TABELLA_DIFFERENZE = (
  <Element is={TabellaDifferenze}>
    {TITOLO}
    {DESCRIZIONE}
    <Element is={CardGroup} canvas type="tabellaDifferenze">
      {TABELLA_DIFFERENZE_CARD}
      {TABELLA_DIFFERENZE_CARD}
    </Element>
  </Element>
)

export const ACCORDION = <Element is={Accordion}>{DESCRIZIONE}</Element>

export const TEXT_BOX = (
  <Element is={TextBox}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const BLOG_BANNER = (
  <Element is={BlogBanner}>
    {TITOLO}
    {DESCRIZIONE}
  </Element>
)

export const NUMBERS_CARD = (
  <Element is={NumbersCard}>
    <TextEditor block={{ text: '999', noHtml: true }} />
    {DESCRIZIONE}
  </Element>
)

export const NUMBERS_SECTION = (
  <Element is={NumbersSection}>
    <Element is={CardGroup} canvas>
      {NUMBERS_CARD}
    </Element>
  </Element>
)

export const conf = [
  {
    title: 'Layout',
    blocks: {
      wcontainer: {
        name: 'Container',
        icon: <Container />,
        block: WCONTAINER,
      },
    },
  },
  {
    title: 'Basic',
    blocks: {
      accordion: {
        name: 'Accordion',
        image: 'accordion.png',
        block: ACCORDION,
      },
      button: {
        name: 'Button',
        icon: <ButtonIcon />,
        block: BUTTON,
      },
      text: {
        name: 'Testo',
        icon: <TextIcon />,
        block: TEXT,
      },
    },
  },
  {
    title: 'Box',
    blocks: {
      banner_block: {
        name: 'Banner block',
        image: 'bannerBlock.png',
        block: BANNER_BLOCK,
      },
      blog_banner: {
        name: 'Blog Banner',
        image: 'blogBanner.png',
        block: BLOG_BANNER,
      },
      blog_image_galley: {
        name: 'Blog image gallery',
        image: 'blogImageGallery.png',
        block: BLOG_IMAGE_GALLERY,
      },
      blog_video: {
        name: 'Blog video',
        image: 'blogVideo.png',
        block: BLOG_VIDEO,
      },
      icon_text_section: {
        name: 'Icon text section',
        image: 'iconTextSection.png',
        block: ICON_TEXT_SECTION,
      },
      issuu_embed: {
        name: 'Issuu embed',
        image: 'issuuEmbed.png',
        block: ISSUU_EMBED,
      },
      numbers_section: {
        name: 'Numbers section',
        image: 'numbersSection.png',
        block: NUMBERS_SECTION,
      },
      pdp_download_card: {
        name: 'Download button',
        image: 'pdpDownloadCard.png',
        block: PDP_DOWNLOAD_CARD,
      },
      ricerca_prodotto_card_section: {
        name: 'Prodotti section',
        image: 'ricercaProdottoCardSection.png',
        block: RICERCA_PRODOTTO_CARD_SECTION,
      },
      tab_attivita: {
        name: 'Tab attività',
        image: 'tabAttivita.png',
        block: TAB_ATTIVITA,
      },
      tabella_differenze: {
        name: 'Tabella differenze',
        image: 'tabellaDifferenze.png',
        block: TABELLA_DIFFERENZE,
      },
      textBox: {
        name: 'Text box',
        image: 'textBox.png',
        block: TEXT_BOX,
      },
      textImageLarge: {
        name: 'Text image large',
        image: 'textImageLarge.png',
        block: TEXT_IMAGE_LARGE,
      },
      textImageSmall: {
        name: 'Text image small',
        image: 'textImageSmall.png',
        block: TEXT_IMAGE_SMALL,
      },
      textSection: {
        name: 'Text section',
        image: 'textSection.png',
        block: TEXT_SECTION,
      },
      videoTextSection: {
        name: 'Video text section',
        image: 'videoTextSection.png',
        block: VIDEO_TEXT_SECTION,
      },
    },
  },
  {
    title: 'Card',
    blocks: {
      attivita_card: {
        name: 'Attività card',
        image: 'attivitaCard.png',
        block: ATTIVITA_CARD,
      },
      blog_image_galley_card: {
        name: 'Blog image gallery card',
        image: 'blogImageGalleryCard.png',
        block: BLOG_IMAGE_GALLERY_CARD,
      },
      icon_text_section: {
        name: 'Icon text card',
        image: 'iconTextCard.png',
        block: ICON_TEXT_CARD,
      },
      numbers_card: {
        name: 'Numbers card',
        image: 'numbersCard.png',
        block: NUMBERS_CARD,
      },
      ricerca_prodotto_card: {
        name: 'Prodotto card',
        image: 'ricercaProdottoCard.png',
        block: RICERCA_PRODOTTO_CARD,
      },
      tab_attivita_block: {
        name: 'Tab attività block',
        image: 'tabAttivitaBlock.png',
        block: TAB_ATTIVITA_BLOCK,
      },
      tabella_differenze_card: {
        name: 'Tabella differenze card',
        image: 'tabellaDifferenzeCard.png',
        block: TABELLA_DIFFERENZE_CARD,
      },
      tabella_differenze_card_item: {
        name: 'Tabella differenze card item',
        image: 'tabellaDifferenzeCardItem.png',
        block: TABELLA_DIFFERENZE_CARD_ITEM,
      },
      text_image_large: {
        name: 'Text image',
        image: 'textImage.png',
        block: TEXT_IMAGE,
      },
    },
  },
  {
    title: 'Slider',
    blocks: {
      gallery: {
        name: 'Gallery',
        image: 'gallery.png',
        block: GALLERY,
      },
      homeHero: {
        name: 'Home hero',
        image: 'homeHero.jpg',
        block: HOME_HERO,
      },
    },
  },
  {
    title: 'Slide',
    blocks: {
      gallerySlide: {
        name: 'Gallery slide',
        image: 'gallerySlide.png',
        block: GALLERY_SLIDE,
      },
      homeHeroBlock: {
        name: 'Home hero block',
        image: 'homeHeroBlock.jpg',
        block: HOME_HERO_BLOCK,
      },
      homeHero: {
        name: 'Home hero slide',
        image: 'homeHeroSlide.jpg',
        block: HOME_HERO_SLIDE,
      },
    },
  },
]
