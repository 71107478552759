export const PROTOCOL_SCHEMA = process.env.NODE_ENV === 'production' ? 'https' : 'http'
export const PORT = process.env.NODE_ENV === 'production' ? undefined : '3000'
export const ADMIN_PORT = process.env.NODE_ENV === 'production' ? undefined : '9999'

export const BRAND = process.env.BRAND

export const RES_MD_MIN = 768
export const RES_LG_MIN = 992

export const PREHEADER_HEIGHT = 29
export const PREHEADER_HEIGHT_MOBILE = 26
export const HEADER_MAIN_HEIGHT = 62
export const HEADER_TOP_HEIGHT = 33
export const HEADER_HEIGHT_MOBILE = 117
export const TOOLBAR_HEIGHT = 40
export const HEADER_HEIGHT = HEADER_MAIN_HEIGHT + HEADER_TOP_HEIGHT
export const PREHEADER_BREAKPOINT = 768
export const HEADER_BREAKPOINT = 1200

export const INTERNAL_DOMAINS = [
  'www.efco.it',
  'www.myefco.com',
  'www.oleomac.it',
  'www.myoleo-mac.com',
  'www.oleomac.es',
  'www.oleo-mac.co.uk',
  'www.mybertolini.it',
  'www.mybertolini.com',
  'www.bertolini.es',
  'www.mynibbi.it',
  'www.mynibbi.com',
]

//MapBox
// export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoid2VicmFua2luZyIsImEiOiJjbGlpcWI1ODUwMHJkM2twaG9kazJpMm1kIn0.P-4qUurUrzwhbhYVcOkmuQ'

// PRODOTTI
export const CHIAVE_PLUS = 'plus'
