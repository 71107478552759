import { resolver } from 'react-pagebuilder/editor'

// [key] può essere droppato solo in [[parent, grandparent])] - se non specificato può andare solo in ROOT
const parentMap = {
  Button: [
    ['CardGroup', 'TextImage'],
    ['CardGroup', 'TextImageLarge'],
    ['CardGroup', 'TextSection'],
    ['CardGroup', 'IconTextSection'],
    ['WContainer'],
  ],
  Accordion: [['WContainer']],
  Text: [['WContainer']],
  TextBox: [['WContainer']],
  TextImage: [['CardGroup', 'BannerBlock'], ['WContainer']],
  TextImageLarge: [['WContainer'], ['ROOT']],
  HomeHeroBlock: [['CardGroup', 'HomeHero']],
  HomeHeroSlide: [['CardGroup', 'HomeHeroBlock']],
  TabAttivitaBlock: [['CardGroup', 'TabAttivita']],
  AttivitaCard: [['CardGroup', 'TabAttivitaBlock']],
  BlogImageGalleryCard: [['CardGroup', 'BlogImageGallery']],
  BlogImageGallery: [['WContainer']],
  BlogVideo: [['WContainer']],
  RicercaProdottoCardSection: [['WContainer']],
  RicercaProdottoCard: [['RicercaProdottoCardSection']],
  PDPDownloadCard: [['WContainer']],
  IssuuEmbed: [['WContainer']],
  GallerySlide: [['CardGroup', 'Gallery']],
  TabellaDifferenzeCard: [['CardGroup', 'TabellaDifferenze']],
  TabellaDifferenzeCardItem: [['CardGroup', 'TabellaDifferenzeCard']],
  OptionCard: [['CardGroup', 'OptionSection']],
  IconTextCard: [['CardGroup', 'IconTextSection']],
  NumbersCard: [['CardGroup', 'NumbersSection']],
}
// In [key] possono esserci solo [list]
const childrenMap = {
  CardGroup: [
    'Button',
    'TextImage',
    'HomeHeroBlock',
    'HomeHeroSlide',
    'TabAttivitaBlock',
    'AttivitaCard',
    'BlogImageGalleryCard',
    'GallerySlide',
    'TabellaDifferenzeCard',
    'TabellaDifferenzeCardItem',
    'OptionCard',
    'IconTextCard',
    'NumbersCard',
  ],
  WContainer: [
    'Accordion',
    'Button',
    'TextImageLarge',
    'BlogImageGallery',
    'BlogVideo',
    'Text',
    'RicercaProdottoCardSection',
    'TextBox',
    'TextImage',
    'PDPDownloadCard',
    'IssuuEmbed',
  ],
}

export const canDrag = (currentNode, helpers, fun) => {
  if (currentNode.data.props.conditions.disabled) {
    return false
  }

  if (fun) {
    return fun(currentNode, helpers)
  } else {
    return true
  }
}

export const canDrop = (targetNode, currentNode, helpers, fun) => {
  if (targetNode.data.props.conditions.disabled) {
    return false
  }

  const parentId = targetNode.data.parent
  const parent = parentId ? helpers(parentId).get() : null

  if (Object.keys(parentMap).includes(currentNode.data.name)) {
    if (
      !parentMap[currentNode.data.name].some(
        (p) => p[0].includes(targetNode.data.name) && (p.length < 2 || p[1] === parent?.data?.name)
      )
    )
      return false
  } else if (targetNode.data.name !== 'ROOT') return false

  if (fun) {
    return fun(targetNode, currentNode, helpers)
  } else {
    return true
  }
}

export const canMoveIn = (incomingNodes, currentNode, helpers, fun) => {
  if (currentNode.data.props.conditions.disabled) {
    return false
  }

  const parentId = currentNode.data.parent
  const parent = parentId ? helpers(parentId).get() : null

  if (Object.keys(childrenMap).includes(currentNode.data.name))
    if (
      !incomingNodes.every((incomingNode) =>
        childrenMap[currentNode.data.name].includes(incomingNode.data.name)
      )
    )
      return false

  const incomingNodesType = _.uniq(incomingNodes.map((n) => n.data.name))
  if (
    !incomingNodesType.every(
      (t) =>
        !Object.keys(parentMap).includes(t) ||
        parentMap[t].some(
          (p) => p[0] === currentNode.data.name && (p.length < 2 || parent.data.name === p[1])
        )
    )
  )
    return false

  if (fun) {
    return fun(incomingNodes, currentNode, helpers)
  } else {
    return true
  }
}

export const canMoveOut = (outgoingNodes, currentNode, helpers, fun) => {
  if (outgoingNodes.every((outgoingNode) => outgoingNode.data.props.conditions.disabled)) {
    return false
  }

  if (fun) {
    return fun(outgoingNodes, currentNode, helpers)
  } else {
    return true
  }
}

export const canDropBlock = (currentNode, targetNode, helpers) => {
  const Block = resolver[targetNode.data?.name]

  if (Block && targetNode.data.isCanvas) {
    return Block.craft.rules.canMoveIn([currentNode], targetNode, helpers)
  }

  return false
}
