import React from 'react'
import styles from './ProgressBar.module.sass'

interface Props {
  className?: string
  progress?: Number
}

const ProgressBar = (props: Props) => {
  const { className = '', progress = 0 } = props

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  )
}

export default ProgressBar
