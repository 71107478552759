import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap'
import styles from './Tooltip.module.sass'

export interface Props {
  label: string | ReactNode
  id: string
  className?: string
  pos?: 'top' | 'right' | 'bottom' | 'left'
  children?: any
}
const Tooltip = (mainProps: Props) => {
  const { label = '', id = '', pos = 'top', className = '', children = null } = mainProps

  const renderTooltip = (props: any) => (
    <BTooltip id={id} className={`${styles.root} ${className}`} {...props}>
      <div dangerouslySetInnerHTML={{ __html: label }} className={styles.content} />
    </BTooltip>
  )
  return (
    <OverlayTrigger placement={pos} delay={{ show: 50, hide: 50 }} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}
export default Tooltip
