import React from 'react'
import useTrans from '@hooks/useTrans'
import { DistributoreNode } from '@gql/graphql-get'
import styles from './DistributoreCard.module.sass'

interface Props {
  distributore?: DistributoreNode
  selected?: boolean
  onClick?: () => void
  className?: string
}

const DistributoreCard = (props: Props) => {
  const { distributore = undefined, selected = false, onClick = undefined, className = '' } = props
  const t = useTrans()

  const details = [
    {
      label: t('Nazione'),
      value: distributore?.nazione || undefined,
    },
    {
      label: t('Indirizzo'),
      value: [
        distributore.indirizzo,
        distributore.cap,
        [distributore.localita, distributore.citta, distributore.provincia]
          .filter((x) => !!x)
          .join(' '),
      ]
        .filter((x) => !!x)
        .map((x, index) => (
          <>
            {index > 0 ? <br /> : <></>}
            {x}
          </>
        )),
    },
    {
      label: t('Telefono'),
      value: distributore.telefono || undefined,
    },
    {
      label: t('Fax'),
      value: distributore?.fax || undefined,
    },
    {
      label: t('Email'),
      value: distributore?.email ? (
        <a href={`mailto:${distributore.email}`}>{distributore.email}</a>
      ) : undefined,
    },
    {
      label: t('Website'),
      value: distributore?.sito?.trim() ? (
        <a
          href={
            /^https?:\/\//.test(distributore.sito)
              ? distributore.sito
              : `https://${distributore.sito}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {distributore.sito}
        </a>
      ) : undefined,
    },
  ].filter((x) => !!x.value)

  return distributore ? (
    <div
      className={`${styles.root} ${selected ? styles['root--selected'] : ''} ${className}`}
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      id={`distributoreCard-storeLocator-${distributore.pk}`}
    >
      <div className={styles.main}>
        {!!distributore.ragioneSociale && (
          <h3 className={styles.title}>{distributore.ragioneSociale}</h3>
        )}
        {!!details?.length && (
          <div className={styles.details}>
            {details.map((x) => (
              <div className={styles.detailItem}>
                <strong>{x.label}:</strong> {x.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default DistributoreCard
