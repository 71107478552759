import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useTrans from '@hooks/useTrans'
import {
  MenuItemType,
  NewsLinkedObjectType,
  ProdottoLinkedObjectType,
  VideoTutorialLinkedObjectType,
} from '@utils/types'
import { ArrowLeft, ArrowRight, Close } from '@components/icons'
import { Button, LangDropdown, NavTag } from '@components/atoms'
import {
  NavListItem,
  NavPromoCard,
  RicercaProdottoCard,
  RicercaNewsCard,
  RicercaVideoTutorialCard,
} from '@components/molecules'
import styles from './NavPanel.module.sass'
import { useRouter } from 'next/router'
import { handleHeaderMenuTracking } from '@utils/tracking'

interface Props {
  item?: MenuItemType
  open?: boolean
  setOpen?: (v: boolean) => void
  level?: number
  setOpenLevel?: (v: number) => void
  className?: string
}

const NavPanel = (props: Props) => {
  const {
    item = undefined,
    open = false,
    setOpen = undefined,
    level = undefined,
    setOpenLevel = undefined,
    className = '',
  } = props

  const t = useTrans()
  const router = useRouter()

  const [childOpen, setChildOpen] = useState(undefined)

  // Metodi per far contento typescript
  const linkedObjectIsNews = (l: any): l is NewsLinkedObjectType => {
    return true // l.hasOwnProperty("sottotitolo")
  }
  const linkedObjectIsProdotto = (l: any): l is ProdottoLinkedObjectType => {
    return true // l.hasOwnProperty("nome")
  }
  const linkedObjectIsVideoTutorial = (l: any): l is VideoTutorialLinkedObjectType => {
    return true // !l.hasOwnProperty("sottotitolo") && !l.hasOwnProperty("nome")
  }

  return item ? (
    <div
      className={`${styles.root} ${open ? styles['root--open'] : ''} ${
        level ? styles[`root--level${level}`] : ''
      } ${className}`}
    >
      {level > 0 && (
        <div className={styles.head}>
          <button
            type="button"
            aria-label={t('Indietro')}
            className={styles.back}
            onClick={() => setOpen(false)}
          >
            <ArrowLeft />
          </button>
          <div className={styles.title}>{item.name}</div>
          <button
            type="button"
            aria-label={t('Chiudi menu')}
            className={styles.close}
            onClick={() => {
              setOpen(false)
              setOpenLevel(level - 1)
            }}
          >
            <Close />
          </button>
        </div>
      )}
      <div className={styles.body}>
        {item.children
          ?.filter((x) => !!x.children?.length)
          .map((x, idx) => (
            <div
              key={x.pk}
              className={`${styles.block} ${x.style ? styles[`block--${x.style}`] : ''}`}
            >
              {!!x.name && <div className={styles.blockTitle}>{x.name}</div>}
              {x.style === 'promo' ? (
                <div className={styles.promoList}>
                  {x.children.map((y) => (
                    <NavPromoCard item={y} key={y.pk} />
                  ))}
                </div>
              ) : x.style === 'news' ? (
                <div className={styles.newsList}>
                  {x.children.map(
                    (y) =>
                      linkedObjectIsNews(y.linkedObject) && (
                        <RicercaNewsCard size="sm" news={y.linkedObject} key={y.pk} />
                      )
                  )}
                </div>
              ) : x.style === 'prodotti' ? (
                <div className={styles.prodottiList}>
                  {x.children.map(
                    (y) =>
                      linkedObjectIsProdotto(y.linkedObject) && (
                        <RicercaProdottoCard size="sm" prodotto={y.linkedObject} key={y.pk} />
                      )
                  )}
                </div>
              ) : x.style === 'video' ? (
                <div className={styles.videoList}>
                  {x.children.map(
                    (y) =>
                      linkedObjectIsVideoTutorial(y.linkedObject) && (
                        <RicercaVideoTutorialCard
                          size="sm"
                          videoTutorial={y.linkedObject}
                          key={y.pk}
                        />
                      )
                  )}
                </div>
              ) : x.style === 'slider' ? (
                <Swiper spaceBetween={8} slidesPerView={'auto'} className={styles.slider}>
                  {x.children?.map((y) => (
                    <SwiperSlide key={y.pk}>
                      <NavTag item={y} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                // Default: voci normali
                !!x.children?.length && (
                  <ul className={styles.list}>
                    {x.children
                      .filter((y) => !!y.url || !!y.children?.length)
                      .map((y) => (
                        <li key={y.pk}>
                          {y.style === 'button' ? (
                            <Button
                              label={y.name}
                              href={y.url}
                              variant="ghost"
                              size="sm"
                              iconRight={<ArrowRight />}
                            />
                          ) : (
                            <NavListItem
                              item={y}
                              open={childOpen === y}
                              setOpen={(v) => {
                                handleHeaderMenuTracking(y.name, level)
                                setChildOpen(v ? y : undefined)
                              }}
                              level={level}
                              setOpenLevel={setOpenLevel}
                            />
                          )}
                        </li>
                      ))}
                    {idx === item?.children?.length - 1 &&
                      level == 0 &&
                      ['it-it', 'int'].includes(router.locale) && (
                        <div className={styles.langDropdown}>
                          <span>{t('Cambio lingua')}</span>
                          <LangDropdown direction="top" />
                        </div>
                      )}
                  </ul>
                )
              )}
            </div>
          ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default NavPanel
