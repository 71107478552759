/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProdottoNode } from '@gql/graphql'
import useLocalStorage from './useLocalStorage'
import { handleCompareTracking } from '@utils/tracking'

const initialState = {
  limitReached: false,
  compareItems: [],
}

export default function useComparatore(categoriaId: number) {
  const [comparatore, setComparatore] = useLocalStorage('comparatore', initialState)

  const { compareItems } = useSelector((state: any) => state?.comparatore)
  const dispatch = useDispatch()

  const maxCompareItems = 3

  useEffect(() => {
    setComparatore({
      compareItems,
    })
  }, [compareItems])

  const addItem = (prodotto: ProdottoNode) => {
    if (compareItems?.[categoriaId]?.length === maxCompareItems) return

    const found = compareItems?.[categoriaId]?.find((x) => x.pk === prodotto.pk)
    if (!found) {
      dispatch({ type: 'ADD_COMPARE_ITEM', payload: { key: categoriaId, compareItem: prodotto } })
    }
  }

  const removeItem = (prodotto: ProdottoNode) => {
    dispatch({ type: 'REMOVE_COMPARE_ITEM', payload: { key: categoriaId, compareItem: prodotto } })
    handleCompareTracking([prodotto], 'remove')
  }

  const resetItems = () => {
    dispatch({ type: 'RESET_COMPARE_ITEMS' })
  }

  return {
    maxCompareItems,
    compareItems,
    addItem,
    removeItem,
    resetItems,
  }
}
