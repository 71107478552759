import React from 'react'

function Reducescreen({ className }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0742 13.3859C18.1248 13.507 18.1959 13.6187 18.2842 13.7159C18.3814 13.8042 18.493 13.8752 18.6142 13.9259C18.7344 13.9771 18.8635 14.0043 18.9942 14.0059L26.9942 14.0059C27.2594 14.0059 27.5138 13.9005 27.7013 13.713C27.8888 13.5254 27.9942 13.2711 27.9942 13.0059C27.9942 12.7406 27.8888 12.4863 27.7013 12.2988C27.5138 12.1112 27.2594 12.0059 26.9942 12.0059L21.4042 12.0059L29.7042 3.71586C29.7979 3.6229 29.8723 3.5123 29.9231 3.39044C29.9739 3.26858 30 3.13787 30 3.00586C30 2.87385 29.9739 2.74314 29.9231 2.62128C29.8723 2.49942 29.7979 2.38882 29.7042 2.29586C29.6112 2.20213 29.5006 2.12774 29.3788 2.07697C29.2569 2.0262 29.1262 2.00006 28.9942 2.00006C28.8622 2.00006 28.7315 2.0262 28.6096 2.07697C28.4878 2.12774 28.3772 2.20213 28.2842 2.29586L19.9942 10.5959L19.9942 5.00586C19.9942 4.74064 19.8888 4.48629 19.7013 4.29875C19.5138 4.11122 19.2594 4.00586 18.9942 4.00586C18.729 4.00586 18.4746 4.11122 18.2871 4.29875C18.0996 4.48629 17.9942 4.74064 17.9942 5.00586L17.9942 13.0059C17.9958 13.1365 18.023 13.2656 18.0742 13.3859Z"
        fill="currentColor"
      />
      <path
        d="M10.5959 12.0059L5.00586 12.0059C4.74064 12.0059 4.48629 12.1112 4.29875 12.2988C4.11122 12.4863 4.00586 12.7406 4.00586 13.0059C4.00586 13.2711 4.11122 13.5254 4.29875 13.713C4.48629 13.9005 4.74064 14.0059 5.00586 14.0059L13.0059 14.0059C13.1365 14.0043 13.2656 13.9771 13.3859 13.9259C13.507 13.8752 13.6187 13.8042 13.7159 13.7159C13.8042 13.6187 13.8752 13.507 13.9259 13.3859C13.9771 13.2656 14.0043 13.1365 14.0059 13.0059L14.0059 5.00586C14.0059 4.74064 13.9005 4.48629 13.713 4.29875C13.5254 4.11122 13.2711 4.00586 13.0059 4.00586C12.7406 4.00586 12.4863 4.11122 12.2988 4.29875C12.1112 4.48629 12.0059 4.74064 12.0059 5.00586L12.0059 10.5959L3.71586 2.29586C3.6229 2.20213 3.5123 2.12774 3.39044 2.07697C3.26858 2.0262 3.13787 2.00006 3.00586 2.00006C2.87385 2.00006 2.74314 2.0262 2.62128 2.07697C2.49942 2.12774 2.38882 2.20213 2.29586 2.29586C2.20213 2.38882 2.12774 2.49942 2.07697 2.62128C2.0262 2.74314 2.00006 2.87385 2.00006 3.00586C2.00006 3.13787 2.0262 3.26858 2.07697 3.39044C2.12774 3.51229 2.20213 3.6229 2.29586 3.71586L10.5959 12.0059Z"
        fill="currentColor"
      />
      <path
        d="M18.9959 27.9959C19.2611 27.9959 19.5155 27.8906 19.703 27.703C19.8906 27.5155 19.9959 27.2611 19.9959 26.9959L19.9959 21.4059L28.2859 29.7059C28.3791 29.7991 28.4898 29.8731 28.6117 29.9236C28.7335 29.974 28.864 30 28.9959 30C29.1278 30 29.2583 29.974 29.3802 29.9236C29.502 29.8731 29.6127 29.7991 29.7059 29.7059C29.7991 29.6127 29.8731 29.502 29.9236 29.3802C29.974 29.2583 30 29.1278 30 28.9959C30 28.864 29.974 28.7335 29.9236 28.6117C29.8731 28.4898 29.7991 28.3791 29.7059 28.2859L21.4059 19.9959L26.9959 19.9959C27.2611 19.9959 27.5155 19.8906 27.703 19.703C27.8906 19.5155 27.9959 19.2611 27.9959 18.9959C27.9959 18.7307 27.8906 18.4763 27.703 18.2888C27.5155 18.1013 27.2611 17.9959 26.9959 17.9959L18.9959 17.9959C18.8652 17.9975 18.7361 18.0247 18.6159 18.0759C18.4918 18.1208 18.3791 18.1925 18.2858 18.2858C18.1925 18.3791 18.1208 18.4918 18.0759 18.6159C18.0247 18.7361 17.9975 18.8652 17.9959 18.9959L17.9959 26.9959C17.9959 27.2611 18.1013 27.5155 18.2888 27.703C18.4763 27.8906 18.7307 27.9959 18.9959 27.9959Z"
        fill="currentColor"
      />
      <path
        d="M3.71391 29.7059L12.0039 21.4059L12.0039 26.9959C12.0039 27.2611 12.1093 27.5155 12.2968 27.703C12.4843 27.8906 12.7387 27.9959 13.0039 27.9959C13.2691 27.9959 13.5235 27.8906 13.711 27.703C13.8985 27.5155 14.0039 27.2611 14.0039 26.9959L14.0039 18.9959C14.0023 18.8652 13.9751 18.7361 13.9239 18.6159C13.8733 18.4947 13.8022 18.3831 13.7139 18.2859C13.6167 18.1976 13.5051 18.1266 13.3839 18.0759C13.2637 18.0247 13.1346 17.9975 13.0039 17.9959L5.00391 17.9959C4.73869 17.9959 4.48434 18.1013 4.2968 18.2888C4.10926 18.4763 4.00391 18.7307 4.00391 18.9959C4.00391 19.2611 4.10926 19.5155 4.2968 19.703C4.48434 19.8906 4.73869 19.9959 5.00391 19.9959L10.5939 19.9959L2.29391 28.2859C2.1056 28.4742 1.99982 28.7296 1.99982 28.9959C1.99982 29.2622 2.1056 29.5176 2.29391 29.7059C2.48221 29.8942 2.7376 30 3.00391 30C3.27021 30 3.5256 29.8942 3.71391 29.7059Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Reducescreen
