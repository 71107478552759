import React, { useState } from 'react'
import { FormError } from 'components/atoms'
import styles from './FormText.module.sass'
import { Alert } from '@components/icons'

interface Props {
  id: string
  type?: 'text' | 'number' | 'email'
  placeholder?: string
  label?: string
  icon?: React.ReactNode // Per Search Glossario
  name?: string
  value?: string | number
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  size?: 'md' | 'lg'
  status?: '' | 'success' | 'error'
  min?: number
  max?: number
  step?: number | string
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormText = (props: Props) => {
  const {
    id = '',
    type = 'text',
    placeholder = '',
    label = undefined,
    icon = undefined,
    name = '',
    value = '',
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    size = 'md',
    status = '',
    min = undefined,
    max = undefined,
    step = undefined,
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const [focus, setFocus] = useState(false)

  const classNames = `
    ${styles.root} 
    ${size ? styles[`root--${size}`] : ''}
    ${!!icon ? styles[`root--icon`] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  const onLocalFocus = (e) => {
    setFocus(true)
    if (onFocus) onFocus(e)
  }

  const onLocalBlur = (e) => {
    setFocus(false)
    if (onBlur) onBlur(e)
  }

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        <input
          id={id}
          type={type}
          name={name}
          value={value}
          step={step}
          min={min}
          max={max}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          onBlur={onLocalBlur}
          onFocus={onLocalFocus}
          onChange={onChange}
          aria-required={required}
          aria-describedby={status === 'error' ? `${id}_error` : undefined}
          aria-invalid={status === 'error' || undefined}
        />
        <span className={styles.alertIcon}>
          <Alert />
        </span>
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormText
