import React from 'react'
import { defaultProps } from './index'
import CtaToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'

export const BlogBannerSettings = (settingsProps) => {
  return (
    <>
      {!settingsProps.floating && (
        <>
          <CtaToolbarSection defaultProps={defaultProps} initOpen />
        </>
      )}
    </>
  )
}

export const BlogBannerFloatingSettings = () =>
  React.createElement(BlogBannerSettings, { floating: true })
