/* eslint-disable unicorn/no-object-as-default-parameter */

export const initializeState = () => {
  let initialState = {
    compareItems: {},
  }
  if (typeof window !== 'undefined' && window?.localStorage) {
    const item = window.localStorage.getItem('comparatore')
    if (item) {
      const parsedItem = JSON.parse(item)
      initialState = {
        ...initialState,
        compareItems:
          'compareItems' in parsedItem ? parsedItem.compareItems : initialState.compareItems,
      }
    }
  }
  return initialState
}

export default function reducer(
  state = initializeState(),
  // @ts-ignore
  action
) {
  switch (action.type) {
    case 'RESET_COMPARE_ITEMS': {
      const newState = { ...state.compareItems }
      newState[action.payload.key] = []
      return {
        ...state,
        compareItems: { ...newState },
      }
    }
    case 'ADD_COMPARE_ITEM': {
      const newState = { ...state.compareItems }
      newState[action.payload.key] = [
        ...(state.compareItems[action.payload.key] || []),
        action.payload.compareItem,
      ]
      return {
        ...state,
        compareItems: { ...newState },
      }
    }
    case 'REMOVE_COMPARE_ITEM': {
      const newState = { ...state.compareItems }
      newState[action.payload.key] = state.compareItems[action.payload.key].filter(
        (x) => x.pk !== action.payload.compareItem?.pk
      )
      return {
        ...state,
        compareItems: { ...newState },
      }
    }
    default: {
      return state
    }
  }
}
