import React from 'react'
import { NewsNode, PaginaNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { Time } from '@components/icons'
import { Breadcrumbs, EditableVideo, Tag, WContainer, WImage } from '@components/atoms'
import styles from './BlogHead.module.sass'

interface Props {
  pagina?: PaginaNode
  news?: NewsNode
  className?: string
}

const BlogHead = (props: Props) => {
  const { pagina = undefined, news = undefined, className = '' } = props

  const t = useTrans()

  return news ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="blog-head">
        <div className={styles.layout}>
          <div className={styles.main}>
            <Breadcrumbs
              breadcrumbs={pagina?.breadcrumbs}
              className={styles.breadcrumbs}
              showLast
            />
            <div className={styles.content}>
              <div className={styles.tags}>
                <Tag label={news?.categoria?.nome} variant="ideeverdi" />
                {!!news.tempoLettura && (
                  <div className={styles.time}>
                    <span className={styles.timeIcon}>
                      <Time />
                    </span>
                    <span className={styles.timeLabel}>
                      {t('{0} min di lettura', [news.tempoLettura.toString()])}
                    </span>
                  </div>
                )}
              </div>
              {!!news.titolo && <h1 className={styles.title}>{news.titolo}</h1>}
              {!!news.sottotitolo && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: news.sottotitolo }}
                />
              )}
            </div>
          </div>
          {!!news.listingVideos ? (
            <div className={styles.videoBox}>
              <EditableVideo
                item={{
                  provider: 'youtube',
                  videoId: news.listingVideos.idYoutube,
                  autoplay: false,
                  muted: false,
                  ratio: '16:9',
                }}
                preserveRatio
              />
            </div>
          ) : !!news.mainImage ? (
            <div className={styles.imageBox}>
              <WImage
                src={news.mainImage}
                maxWidth={1080}
                maxHeight={500}
                priority
                loading="eager"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default BlogHead
