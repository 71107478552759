import React from 'react'
import { defaultProps } from './index'
import CtaToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'

export const TextBoxSettings = (settingsProps) => {
  return (
    <>{!settingsProps.floating && <CtaToolbarSection defaultProps={defaultProps} initOpen />}</>
  )
}

export const TextBoxFloatingSettings = () =>
  React.createElement(TextBoxSettings, { floating: true })
