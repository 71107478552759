/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import menu from './containers/menu'
import utente from './containers/utente'
import traduzioni from './containers/traduzioni'
import pagina from './containers/pagina'
import comparatore from './containers/comparatore'

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      }
      if (state.utente.utente) nextState.utente.utente = state.utente.utente // preserve utente value on client side navigation
      if (state.comparatore) nextState.comparatore = state.comparatore // preserve comparatore value on client side navigation
      return nextState

    default:
      return combineReducers({
        menu,
        utente,
        traduzioni,
        pagina,
        comparatore,
      })(state, action)
  }
}

export default rootReducer
