import { useState } from 'react'

function useCookie(
  key: string,
  initialValue: string
): [string | undefined, (value: string, expirationDays?: number) => void, () => void] {
  // ? Faccio i check su Window per evitare Crash dovuti al SSR
  const getCookie = (key: string) => {
    try {
      if (typeof window !== 'undefined') {
        return (
          document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=')
            return parts[0] === key ? decodeURIComponent(parts[1]) : r
          }, '') || initialValue
        )
      }
    } catch (error) {
      console.log(error)
      return initialValue
    }
  }

  const [cookieExists, setCookieExists] = useState<string | undefined>(() => getCookie(key))

  // ? Versione Wrappata del setState => per settare il Valore Nuovo
  const setCookie = (value: string, expirationDays: number = 365) => {
    try {
      if (typeof window !== 'undefined') {
        const expires = new Date()
        expires.setDate(expires.getDate() + expirationDays)
        document.cookie = `${key}=${encodeURIComponent(
          value
        )}; expires=${expires.toUTCString()}; path=/; secure`
        setCookieExists(value)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCookie = () => {
    try {
      if (typeof window !== 'undefined' && cookieExists) {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure`
        setCookieExists(undefined)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [cookieExists, setCookie, deleteCookie]
}

export default useCookie
