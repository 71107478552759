import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Filter({ idVariant = '', className }: Props) {
  return (
    <IconBase name="filter" w={24} className={className}>
      <path
        d="M7 3V6H3V8H7V11H9V3H7ZM11 8H21V6H11V8ZM17 13V16H21V18H17V21H15V13H17ZM13 18H3V16H13V18Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Filter
