import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import LayoutToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const IconTextSectionSettings = (settingsProps) => {
  const themeOpts = [
    { label: 'Light', value: 'light' },
    { label: 'Neutral light', value: 'neutrallight' },
    { label: 'Secondary dark', value: 'secondarydark' },
    { label: 'Primary', value: 'primary' },
  ]

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="theme"
          type="select"
          options={themeOpts}
          label="Tema"
          defaultValue={defaultProps['theme']}
        />
      </ToolbarSection>
      <LayoutToolbarSection defaultProps={defaultProps} />
    </>
  )
}

export const IconTextSectionFloatingSettings = () =>
  React.createElement(IconTextSectionSettings, { floating: true })
