import React from 'react'
import { MenuItemType } from '@utils/types'
import { Button, EditableDescription, EditableTitle, WContainer } from '@components/atoms'
import styles from './TextSection.module.sass'
import { getIcon } from '@utils/icons'
import { checkUrlAndTrackPageBuilder } from '@utils/index'
import { Router, useRouter } from 'next/router'

interface Props {
  item?: MenuItemType
  theme?: 'light' | 'neutrallight' | 'neutrallight2' | 'secondarydark' | 'black'
  align?: 'center' | 'left'
  size?: 'md' | 'sm'
  editMode?: boolean
  className?: string
}

const TextSection = (props: Props) => {
  const {
    item = undefined,
    theme = 'light',
    align = 'center',
    size = 'md',
    editMode = false,
    className = '',
  } = props

  const router = useRouter()

  return item ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${styles[`root--${size}`]} 
      ${styles[`root--${align}`]} 
      ${item.spaceTop ? styles[`root--st-${item.spaceTop}`] : ''} 
      ${item.spaceBottom ? styles[`root--sb-${item.spaceBottom}`] : ''} 
      ${item.borderTop ? styles[`root--bt`] : ''} 
      ${item.borderBottom ? styles[`root--bb`] : ''} 
      ${className}`}
    >
      <WContainer>
        <div className={styles.layout}>
          <EditableTitle title={item.name} className={styles.title} editMode={editMode} />
          <EditableDescription
            description={item.description}
            className={styles.description}
            editMode={editMode}
          />
          {editMode ? (
            <>{item.children}</>
          ) : (
            !!item.children?.filter((x) => !!x.url && !!x.ctaLabel)?.length && (
              <div className={styles.ctaBox}>
                {item.children?.map((x, index) => (
                  <Button
                    label={x.ctaLabel}
                    href={x.url}
                    onClick={() => checkUrlAndTrackPageBuilder(x, router.asPath)}
                    newPage={x.blank}
                    iconLeft={
                      x.iconLeft
                        ? x.iconLeft
                        : !!x.icon && x.iconPos === 'left'
                        ? getIcon(x.icon)
                        : undefined
                    }
                    iconRight={
                      x.iconRight
                        ? x.iconRight
                        : !!x.icon && x.iconPos === 'right'
                        ? getIcon(x.icon)
                        : undefined
                    }
                    variant={x.style as 'primary' | 'secondary' | 'ghost'}
                    color={
                      ['secondary', 'ghost'].includes(x.style) &&
                      ['secondarydark', 'black'].includes(theme)
                        ? 'white'
                        : ''
                    }
                    theme={['secondarydark', 'black'].includes(theme) ? 'dark' : 'light'}
                    size={size === 'sm' ? 'md' : 'lg'}
                    mobileSize={size}
                  />
                ))}
              </div>
            )
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default TextSection
