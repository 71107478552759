import React, { useState, useContext, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { GlobalContext } from '../services/globalContext'
import { defaultConditionsProps } from '../conditions'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { getIcon } from '@utils/icons'

export const withBlock =
  (Component, defaultProps, dynamicProps = undefined) =>
  (props) => {
    const isSSR = props?.isSSR || false

    const children = isSSR ? props?.children : props?.children?.props?.children

    const {
      id,
      connectors: { connect, drag },
      customs,
      blockProps,
      conditionsProps,
      selected,
      hovered,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        hovered: state.events.hovered,
        customs: state.data.custom,
        blockProps: state.data.props.block,
        conditionsProps: state.data.props.conditions,
      }),
      props
    )

    const { actions, enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    const { previewMode, wireframeMode, setFloatingSettingsOpen } = useContext(GlobalContext)

    useEffect(() => {
      if (!isSSR) {
        // imposta le defaultProps alle props non specificate
        Object.keys(defaultProps).forEach((propKey) => {
          if (blockProps[propKey] === undefined) {
            actions.history.ignore().setProp(id, (props) => {
              props.block[propKey] = defaultProps[propKey]
            })
          }
        })

        // imposta le defaultConditionsProps alle props non specificate
        Object.keys(defaultConditionsProps).forEach((propKey) => {
          if (conditionsProps[propKey] === undefined) {
            actions.history.ignore().setProp(id, (props) => {
              props.conditions[propKey] = defaultConditionsProps[propKey]
            })
          }
        })
      }
    }, [])

    const renderValue = (val) => val

    const renderText = (text) => {
      if (text)
        // replace "<spid>"" with icon
        return text.replace(
          /<spid>|&lt;spid&gt;/g,
          ReactDOMServer.renderToStaticMarkup(getIcon('spid'))
        )

      return text
    }

    const retRef = (ref, type = 'drag') => {
      if (isSSR) return null

      if (!enabled) {
        return null
      } else if (props.conditions && props.conditions.disabled) {
        return null
      } else {
        if (type === 'drag') {
          return connect(drag(ref))
        } else if (type === 'connect') {
          return connect(ref)
        } else {
          return connect(null)
        }
      }
    }

    const [className, setClassName] = useState('')

    useEffect(() => {
      let dynamicClassName = className
      // if (anyConditionSatisfied(localStorage, props?.conditions)) {
      //   dynamicClassName += ' component-hidden'
      // } else {
      dynamicClassName = dynamicClassName.replace(' component-hidden', '')
      // }
      if (!isSSR) {
        dynamicClassName = `${id} ${
          enabled && !previewMode && wireframeMode ? 'with-wireframe' : ''
        } ${dynamicClassName.replace(' component-selected', '').replace(' component-hovered', '')}` // pulisco le classi di "stato" con il replace
        if (selected) {
          if (!customs.editing) {
            dynamicClassName += ' component-selected'
          }
        } else if (hovered) {
          dynamicClassName += ' component-hovered'
        }
      }

      setClassName([...new Set(dynamicClassName.split(' '))].join(' ')) // remove duplicated classes
    }, [selected, hovered, enabled, previewMode, wireframeMode, customs.editing, props?.conditions])

    const onDoubleClick = (event, fun) => {
      event.stopPropagation()
      if (fun) {
        fun()
      } else {
        setFloatingSettingsOpen(true)
      }
    }

    return (
      <>
        <Component
          {...props}
          className={className}
          renderValue={renderValue}
          renderText={renderText}
          retRef={retRef}
          onDoubleClick={onDoubleClick}
          children={children}
        />
      </>
    )
  }
