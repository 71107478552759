import React from 'react'
import styles from './NavPromoCard.module.sass'
import { MenuItemType } from '@utils/types'
import { Button, Tag } from '@components/atoms'
import { getIcon } from '@utils/icons'

interface Props {
  item?: MenuItemType
  className?: string
}

const NavPromoCard = (props: Props) => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.content}>
        {!!item.intro && (
          <Tag
            label={item.intro}
            variant={'promo'}
            icon={getIcon(item.icon)}
            className={styles.tag}
          />
        )}
        {!!item.name && <div className={styles.title}>{item.name}</div>}
        {!!item.description && (
          <div
            dangerouslySetInnerHTML={{ __html: item.description }}
            className={styles.description}
          />
        )}
      </div>
      {!!item.ctaLabel && !!item.url && (
        <Button color="white" label={item.ctaLabel} href={item.url} newPage={item.blank} />
      )}
    </div>
  ) : (
    <></>
  )
}

export default NavPromoCard
