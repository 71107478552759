import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function InquinamentoAcustico({ idVariant = '', className }: Props) {
  return (
    <IconBase name="inquinamento-acustico" w={50} className={className}>
      <path
        d="M4.2309 21.4784C3.66492 21.4784 3.21423 21.9395 3.21423 22.495V27.4945C3.21423 28.0605 3.6754 28.5112 4.2309 28.5112C4.7864 28.5112 5.24756 28.05 5.24756 27.4945V22.495C5.24756 21.9291 4.7864 21.4784 4.2309 21.4784Z"
        fill="currentColor"
      />
      <path
        d="M8.38141 29.444C8.94739 29.444 9.39808 28.9828 9.39808 28.4273V21.5727C9.39808 21.0067 8.93691 20.556 8.38141 20.556C7.82591 20.556 7.36475 21.0172 7.36475 21.5727V28.4273C7.36475 28.9933 7.82591 29.444 8.38141 29.444Z"
        fill="currentColor"
      />
      <path
        d="M12.5423 31.3096C13.1083 31.3096 13.559 30.8484 13.559 30.2929V19.707C13.559 19.1411 13.0978 18.6904 12.5423 18.6904C11.9868 18.6904 11.5256 19.1515 11.5256 19.707V30.2929C11.5256 30.8589 11.9868 31.3096 12.5423 31.3096Z"
        fill="currentColor"
      />
      <path
        d="M16.7034 32.8922C17.2694 32.8922 17.7201 32.4311 17.7201 31.8756V18.1454C17.7201 17.5794 17.2589 17.1287 16.7034 17.1287C16.1479 17.1287 15.6868 17.5899 15.6868 18.1454V31.8756C15.6868 32.4416 16.1479 32.8922 16.7034 32.8922Z"
        fill="currentColor"
      />
      <path
        d="M20.8539 34.8417C21.4199 34.8417 21.8706 34.3806 21.8706 33.8251V16.1749C21.8706 15.6089 21.4094 15.1582 20.8539 15.1582C20.2984 15.1582 19.8373 15.6194 19.8373 16.1749V33.8251C19.8373 34.391 20.2984 34.8417 20.8539 34.8417Z"
        fill="currentColor"
      />
      <path
        d="M25.0148 39.2857C25.5808 39.2857 26.0315 38.8245 26.0315 38.269V11.7309C26.0315 11.165 25.5703 10.7143 25.0148 10.7143C24.4593 10.7143 23.9982 11.1754 23.9982 11.7309V38.2586C23.9982 38.8245 24.4593 39.2752 25.0148 39.2752V39.2857Z"
        fill="currentColor"
      />
      <path
        d="M29.176 34.3177C29.7419 34.3177 30.1926 33.8565 30.1926 33.301V16.7094C30.1926 16.1435 29.7315 15.6928 29.176 15.6928C28.6205 15.6928 28.1593 16.1539 28.1593 16.7094V33.301C28.1593 33.867 28.6205 34.3177 29.176 34.3177Z"
        fill="currentColor"
      />
      <path
        d="M33.3265 31.7288C33.8925 31.7288 34.3431 31.2677 34.3431 30.7122V19.2983C34.3431 18.7323 33.882 18.2816 33.3265 18.2816C32.771 18.2816 32.3098 18.7428 32.3098 19.2983V30.7122C32.3098 31.2782 32.771 31.7288 33.3265 31.7288Z"
        fill="currentColor"
      />
      <path
        d="M37.4874 30.3768C38.0533 30.3768 38.504 29.9156 38.504 29.3601V20.6608C38.504 20.0948 38.0429 19.6441 37.4874 19.6441C36.9319 19.6441 36.4707 20.1053 36.4707 20.6608V29.3601C36.4707 29.9261 36.9319 30.3768 37.4874 30.3768Z"
        fill="currentColor"
      />
      <path
        d="M41.6483 29.3811C42.2142 29.3811 42.6649 28.9199 42.6649 28.3644V21.6356C42.6649 21.0696 42.2038 20.6189 41.6483 20.6189C41.0928 20.6189 40.6316 21.0801 40.6316 21.6356V28.3644C40.6316 28.9304 41.0928 29.3811 41.6483 29.3811Z"
        fill="currentColor"
      />
      <path
        d="M45.7988 21.3211C45.2328 21.3211 44.7821 21.7823 44.7821 22.3378V27.6517C44.7821 28.2177 45.2433 28.6684 45.7988 28.6684C46.3543 28.6684 46.8154 28.2072 46.8154 27.6517V22.3378C46.8154 21.7718 46.3543 21.3211 45.7988 21.3211Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default InquinamentoAcustico
