/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable unicorn/no-object-as-default-parameter */
import { api } from 'utils'
import { MENU } from 'utils/endpoints'

export const fetchMenu = (locale: string) => {
  const payload = api.get(MENU, { params: { lang: locale } })

  return {
    type: 'FETCH_MENU',
    payload,
  }
}

export default function reducer(
  state = {
    menu: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_MENU_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_MENU_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_MENU_FULFILLED': {
      const menu = action.payload.data

      return {
        ...state,
        fetching: false,
        fetched: true,
        menu,
      }
    }

    default: {
      return state
    }
  }
}
