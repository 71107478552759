import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ChevronDown({ idVariant = '', className }: Props) {
  return (
    <IconBase name="chevron-down" w={24} className={className}>
      <path
        d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ChevronDown
