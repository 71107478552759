import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Search as SearchIcon } from '@components/icons'
import styles from './Search.module.sass'
import { useRouter } from 'next/router'
import axios from 'axios'
import Button from '../Button/Button'
import { handleSearchTypeTracking } from '@utils/tracking'

interface Props {
  className?: string
  onClick?: () => void
  setSearchResults?: (data) => void
}

const Search = (props: Props) => {
  const { className = '', onClick = undefined, setSearchResults = () => {} } = props
  const [query, setQuery] = useState('')
  const router = useRouter()

  const t = useTrans()

  const handleSubmit = (event: React.FormEvent) => {
    if (query) {
      event.preventDefault()
      handleSearchTypeTracking(query)
      router.push(`/search/?q=${query}`)
    }
  }

  const fetchSearchResults = async () => {
    let params = {
      query,
      suggestions: true,
      lang: router.locale,
      fromSearchBar: true,
      size: 2,
    }
    const { data } = await axios.get(`/api/search/`, { params })
    setSearchResults(data)
  }

  useEffect(() => {
    if (!(query.length >= 4)) {
      setSearchResults(null)
      return
    }

    fetchSearchResults()
  }, [query])

  return (
    <form role="search" className={`${styles.root} ${className}`} onSubmit={handleSubmit}>
      <div className={styles.inputWrap}>
        <input
          type="search"
          name="q"
          placeholder={t('Cerca')}
          className={styles.input}
          onChange={(e) => setQuery(e.target.value)}
          onClick={onClick}
          autoFocus
          autoComplete="off"
        />
        <span className={styles.icon}>
          <SearchIcon />
        </span>
      </div>
      <Button type="submit" label={t('Cerca')} className={styles.submit} />
    </form>
  )
}

export default Search
