import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { usePrezziProdottiLazyQuery } from '@gql/graphql'
import { ProdottoLinkedObjectType } from '@utils/types'
import { prezziProdotto, priceNotation } from '@utils/safe'
import { Battery } from '@components/icons'
import { Tag, WImage, WLink } from '@components/atoms'
import styles from './RicercaProdottoCard.module.sass'
import { isEcommerce, isFiliale } from '@utils/index'
import { useRouter } from 'next/router'
import { handleProductClickTracking } from '@utils/tracking'

interface Props {
  prodotto?: ProdottoLinkedObjectType
  size?: 'sm' | 'md'
  editMode?: boolean
  className?: string
  loadPrezzi?: boolean
}

const RicercaProdottoCard = (props: Props) => {
  const {
    prodotto = undefined,
    size = 'md',
    editMode = false,
    className = '',
    loadPrezzi = true,
  } = props

  const router = useRouter()
  const ecommerce = isEcommerce(router.locale)
  const filiale = isFiliale(router.locale)

  const [item, setItem] = useState(prodotto)

  const [getPrezzi] = usePrezziProdottiLazyQuery({
    fetchPolicy: 'cache-first',
  })

  const t = useTrans()

  const pp = prezziProdotto(item)
  const prezzi = [
    ...(ecommerce
      ? [
          { key: 'listino', label: t('Listino'), value: pp?.prezzoListino },
          { key: 'prev', label: t('Prima era'), value: pp?.prezzoPrima },
        ]
      : []),
    ...(filiale && pp?.prezzoListino && pp?.prezzoListino !== pp.prezzoFinale
      ? [{ key: 'listino', label: t('Listino'), value: pp?.prezzoListino }]
      : []),
    ...(filiale || ecommerce ? [{ key: 'current', value: pp.prezzoFinale }] : []),
  ].filter((x) => !!x.value)

  async function updatePrezzi(pk: number) {
    const {
      data: { prezziProdotti },
    } = await getPrezzi({ variables: { ids: [pk] } })

    setItem({ ...item, ...prezziProdotti?.[0] })
  }

  useEffect(() => {
    // carico i prezzi client-side per le promo
    if (prodotto && item && loadPrezzi) {
      updatePrezzi(item.pk)
    }
  }, [prodotto])

  const prodottoBatteria = prodotto?.categoria?.alias?.toLowerCase()?.includes('batteria')
  return item ? (
    <div className={`${styles.root} ${styles[`root--${size}`]} ${className}`}>
      {item.previewImage && (
        <div className={`${styles.imageBox}`}>
          <WImage
            src={item.previewImage}
            maxWidth={120}
            maxHeight={120}
            alt={item.nome}
            title={item.nome}
            objectFit="contain"
          />
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.content}>
          {!!item.utilizzo && (
            <Tag label={item.utilizzo} variant="categoria" className={styles.tag} />
          )}
          {!!item.categoria && (
            <div className={styles.battery}>
              <span className={styles.batteryLabel}>{item.categoria.boxTitolo}</span>
              {prodottoBatteria && (
                <span className={styles.batteryIcon}>
                  <Battery />
                </span>
              )}
            </div>
          )}
          {!!item.nome && (
            <h3 className={styles.title}>
              <WLink
                href={editMode ? '#' : item.url}
                onClick={() => handleProductClickTracking(item)}
                className={styles.link}
              >
                {item.nome}
              </WLink>
            </h3>
          )}
          {item.sottotitolo && <div className={styles.formato}>{item.sottotitolo}</div>}
        </div>
        {item.disponibile && (
          <div className={styles.prices}>
            {prezzi.map((x) => (
              <div className={`${styles.price} ${styles[`price--${x.key}`]}`} key={x.key}>
                <span className={styles.priceLabel}>{x.label}</span>{' '}
                <span className={styles.priceValue}>
                  {priceNotation(x.value, false, prodotto.valuta?.simbolo)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default RicercaProdottoCard
