import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import useTrans from '@hooks/useTrans'
import { scrollTo } from '@utils/safe'
import { manualiValidationSchema } from '@utils/validationSchemas'
import {
  Button,
  FormError,
  FormikField,
  FormLayout,
  FormOptions,
  WContainer,
} from '@components/atoms'
import styles from './ManualiForm.module.sass'
import {
  useCategorieRicercaManualiQuery,
  useGetCategorieByParentLazyQuery,
  useProdottiRicercaManualiLazyQuery,
} from '@gql/graphql'
import { handleDownloadTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  className?: string
}

const ManualiForm = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const formBody = useRef(null)
  const router = useRouter()

  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)

  const { data: dataCategorieRicercaManuali } = useCategorieRicercaManualiQuery()
  const categoriaOptions = dataCategorieRicercaManuali?.categorieRicercaManuali?.map((x) => {
    return { value: x.pk.toString(), label: x.nome }
  })

  const [categorieByParentQuery] = useGetCategorieByParentLazyQuery()
  const [prodottiRicercaManualiQuery] = useProdottiRicercaManualiLazyQuery()

  const [lineeOptions, setLineeOptions] = useState([])
  const [classiOptions, setClassiOptions] = useState([])
  const [prodottiOptions, setProdottiOptions] = useState([])
  const [lingueOptions, setLingueOptions] = useState([])
  const [manualiOptions, setManualiOptions] = useState([])

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    scrollTo(formBody?.current, 20)
  }

  const initialValues = {
    categoria: '',
    linea: '',
    classe: '',
    prodotto: '',
    lingua: '',
    manuale: '',
  }

  const placeholder = '---'

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: t(
                'Seleziona la categoria di tuo interesse e compila i dati richiesti per ricevere il manuale.'
              ),
            }}
          />
          <Formik
            initialValues={initialValues}
            validationSchema={manualiValidationSchema(t)}
            validateOnMount
            onSubmit={(values) => {
              const manuale = manualiOptions.filter((x) => x.value === values.manuale)[0]
              if (manuale) {
                const link = document.createElement('a')
                link.href = manuale.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                handleDownloadTracking(manuale.label, 'manuale', router.asPath)
              } else {
                raiseGlobalError(t('Errore durante il download del manuale'))
              }
            }}
          >
            {(formikProps) => (
              <Form className={styles.form} ref={formBody}>
                <FormError
                  id="contattaci_global_error"
                  message={globalError}
                  visible={!!globalError}
                  variant="global"
                  className={styles.globalError}
                />
                <FormOptions
                  id={'manuali_categoria'}
                  name="categoria"
                  value={formikProps.values.categoria}
                  options={categoriaOptions}
                  onChange={async (e) => {
                    formikProps.setValues({
                      ...initialValues,
                      categoria: e.target.value,
                    })

                    const { data } = await categorieByParentQuery({
                      variables: {
                        parentId: parseInt(e.target.value),
                      },
                    })

                    setLineeOptions(
                      data?.categorieByParent?.map((x) => {
                        return { value: x.pk.toString(), label: x.nome }
                      })
                    )
                  }}
                />
                <FormLayout space="lg">
                  <FormikField
                    formik={formikProps}
                    id={'manuali_linea'}
                    name="linea"
                    type="select"
                    options={lineeOptions}
                    label={t('Linee di prodotto')}
                    onChange={async (e) => {
                      const { data: lineeProdottoData } = await categorieByParentQuery({
                        variables: {
                          parentId: parseInt(e.target.value),
                          inMenu: true,
                        },
                      })

                      formikProps.setValues({
                        ...initialValues,
                        categoria: formikProps.values.categoria,
                        linea: e.target.value,
                      })

                      setClassiOptions([
                        { value: undefined, label: placeholder },
                        ...lineeProdottoData?.categorieByParent?.map((x) => {
                          return { value: x.pk.toString(), label: x.nome }
                        }),
                      ])

                      const { data: prodottiData } = await prodottiRicercaManualiQuery({
                        variables: {
                          idCategoria: parseInt(e.target.value),
                        },
                      })

                      setProdottiOptions(
                        prodottiData?.prodottiRicercaManuali?.map((x) => {
                          return { value: x.pk.toString(), label: x.alias, documenti: x.documenti }
                        })
                      )
                    }}
                    placeholder={placeholder}
                  />

                  <FormikField
                    formik={formikProps}
                    id={'manuali_classe'}
                    name="classe"
                    type="select"
                    options={classiOptions}
                    disabled={classiOptions.length == 1 && classiOptions[0].label == placeholder}
                    label={t('Classi')}
                    onChange={async (e) => {
                      formikProps.handleChange(e)
                      const { data: prodottiData } = await prodottiRicercaManualiQuery({
                        variables: {
                          idCategoria: parseInt(e.target.value || formikProps.values.linea),
                        },
                      })

                      setProdottiOptions(
                        prodottiData?.prodottiRicercaManuali?.map((x) => {
                          return {
                            value: x.pk.toString(),
                            label: x.alias,
                            documenti: x.documenti,
                          }
                        })
                      )
                    }}
                    placeholder={placeholder}
                  />
                  <FormikField
                    formik={formikProps}
                    id={'manuali_prodotto'}
                    name="prodotto"
                    type="select"
                    options={prodottiOptions}
                    label={t('Prodotto')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                      const prodotto = prodottiOptions.find((x) => x.value === e.target.value)
                      const languageMap = new Map()

                      prodotto?.documenti.forEach((document) => {
                        document.lingue.forEach((lingua) => {
                          if (!languageMap.has(lingua.codice)) {
                            languageMap.set(lingua.codice, {
                              id: lingua.id,
                              codice: lingua.codice,
                            })
                          }
                        })
                      })

                      const uniqueLanguages = Array.from(languageMap.values())

                      setLingueOptions([
                        { value: undefined, label: t('Tutte le lingue') },
                        ...uniqueLanguages.map((x) => ({
                          value: x.id.toString(),
                          label: x.codice,
                        })),
                      ])

                      setManualiOptions(
                        prodotto?.documenti.map((x) => ({
                          value: x.pk.toString(),
                          label: x.nomeDocumento,
                          url: x.url,
                        }))
                      )
                    }}
                    placeholder={placeholder}
                  />
                  <FormikField
                    formik={formikProps}
                    id={'manuali_lingua'}
                    name="lingua"
                    type="select"
                    options={lingueOptions}
                    label={t('Lingua')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                      const prodotto = prodottiOptions.find(
                        (x) => x.value === formikProps.values.prodotto
                      )

                      let filteredDocuments = null
                      if (e.target.value) {
                        filteredDocuments = prodotto?.documenti.filter((document) =>
                          document.lingue.some((lingua) => lingua.id === e.target.value)
                        )
                      }

                      setManualiOptions(
                        (filteredDocuments || prodotto?.documenti).map((document) => ({
                          value: document.pk.toString(),
                          label: document.nomeDocumento,
                          url: document.url,
                        }))
                      )
                    }}
                    placeholder={t('Tutte le lingue')}
                  />
                  <FormikField
                    formik={formikProps}
                    id={'manuali_manuale'}
                    name="manuale"
                    type="select"
                    options={manualiOptions}
                    label={t('Manuali')}
                    onChange={(e) => {
                      formikProps.handleChange(e)
                    }}
                    placeholder={placeholder}
                  />
                </FormLayout>
                <div className={styles.ctaBox}>
                  <Button
                    type="submit"
                    label={t('Scarica il manuale selezionato')}
                    disabled={
                      !formikProps.isValid ||
                      !manualiOptions.filter((x) => x.value === formikProps.values.manuale)[0] ||
                      !manualiOptions.filter((x) => x.value === formikProps.values.manuale)[0].url
                    }
                    loading={globalLoading}
                    size="lg"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </WContainer>
    </div>
  )
}

export default ManualiForm
