import React from 'react'
import useTrans from '@hooks/useTrans'
import { Button } from '@components/atoms'
import styles from './CheckoutStepBlock.module.sass'
import { Completed } from '@components/icons'

interface Props {
  status?: 'open' | 'closed' | 'completed'
  title?: string
  step?: number
  numSteps?: number
  description?: string
  completedTitle?: string
  completedDescription?: string
  hideHeadMobile?: boolean
  onEdit?: () => void
  children?: React.ReactNode
  className?: string
}

const CheckoutStepBlock = (props: Props) => {
  const {
    status = 'closed',
    title = undefined,
    step = undefined,
    numSteps = undefined,
    description = undefined,
    completedTitle = undefined,
    completedDescription = undefined,
    hideHeadMobile = false,
    onEdit = undefined,
    children = undefined,
    className = '',
  } = props

  const t = useTrans()

  return (
    <div
      className={`${styles.root} ${styles[`root--${status}`]} ${
        hideHeadMobile ? styles['root--noHeadMobile'] : ''
      } ${className}`}
    >
      <div className={styles.head}>
        {status === 'completed' && (
          <span className={styles.completedIcon}>
            <Completed />
          </span>
        )}
        {!!title && <h2 className={styles.title}>{title}</h2>}
        {!!step && !!numSteps && (
          <span className={styles.step}>
            {step}/{numSteps}
          </span>
        )}
      </div>
      <div className={styles.openBody}>
        {description && (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <div className={styles.formFields}>{children}</div>
      </div>
      {(!!completedTitle || !!onEdit) && (
        <div className={styles.completedBody}>
          {(!!completedTitle || !!completedDescription) && (
            <div className={styles.completedContent}>
              {!!completedTitle && <div className={styles.completedTitle}>{completedTitle}</div>}
              {!!completedDescription && (
                <div
                  className={styles.completedDescription}
                  dangerouslySetInnerHTML={{ __html: completedDescription }}
                />
              )}
            </div>
          )}
          {!!onEdit && (
            <Button
              label={t('Modifica')}
              variant="ghost"
              className={styles.edit}
              onClick={onEdit}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default CheckoutStepBlock
