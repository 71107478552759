import React from 'react'
import useTrans from '@hooks/useTrans'
import { OrdineNode } from '@gql/graphql'
import { dateFormat, priceNotation } from '@utils/safe'
import { ArrowRight } from '@components/icons'
import { Button, WImage } from '@components/atoms'
import styles from './MyAccountOrdineCard.module.sass'

interface Props {
  ordine?: OrdineNode
  className?: string
}

const MyAccountOrdineCard = (props: Props) => {
  const { ordine = undefined, className = '' } = props

  const limitImages = 4

  const t = useTrans()

  const righeOrdine = ordine?.righeOrdine?.filter((x) => x.tipologia === 'PRODOTTO') || []

  return ordine ? (
    <div className={`${styles.root} ${className}`}>
      <h5 className={styles.title}>{t('Ordine n. {0}', [ordine.codice])}</h5>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.item}>
            <p className={styles.key}>{t('Data dell’ordine')}</p>
            <p className={styles.value}>{dateFormat(ordine.created)}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.key}>{t('Totale')}</p>
            <p className={styles.value}>{priceNotation(ordine.prezzoTotaleScontato)}</p>
          </div>
          <div className={`${styles.item} ${styles['item--stato']}`}>
            <p className={styles.key}>{t('Stato dell’ordine')}</p>
            <p className={styles.value}>{ordine?.stato?.nome}</p>
          </div>
        </div>
        <div className={styles.images}>
          {righeOrdine
            .slice(0, righeOrdine.length > limitImages ? limitImages - 1 : limitImages)
            .map((x, index) => (
              <div key={index} className={styles.imageBox}>
                <WImage
                  src={x?.prodotto?.prodotto?.previewImage}
                  maxWidth={64}
                  maxHeight={64}
                  objectFit={'contain'}
                />
              </div>
            ))}
          {righeOrdine?.length > limitImages && (
            <div className={`${styles.imageBox} ${styles['imageBox--nImages']}`}>
              <p>+{righeOrdine?.length - limitImages + 1}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        {ordine.sistemaSpedizione?.chiave !== 'ritiro' && ordine.trackingUrl && (
          <Button
            label={t('Traccia l’ordine')}
            variant="ghost"
            className={styles.trackButton}
            external
            newPage
            href={ordine.trackingUrl}
          />
        )}
        <Button
          label={t('Dettagli')}
          iconRight={<ArrowRight />}
          variant="secondary"
          className={styles.detailsButton}
          href={ordine.url}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MyAccountOrdineCard
