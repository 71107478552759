export const SwitchToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
}) => {
  return (
    <>
      {!onlyDynamic && (
        <input
          type="checkbox"
          name={propSubKey}
          defaultChecked={value}
          onChange={(e) =>
            setProp((props) => {
              const value = e.target.checked
              props[propKey][propSubKey] = onChange ? onChange(value) : value
            }, 1000)
          }
        />
      )}
    </>
  )
}
