import React from 'react'
import styles from './ArticoloCard.module.sass'
import { Button, Tag, WImage, WLink } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import { Time } from '@components/icons'
import { NewsNode } from '@gql/graphql'
import { hasHTML } from '@utils/safe'
import { handleSelectContentTracking } from '@utils/tracking'
import { useRouter } from 'next/router'

interface Props {
  news?: NewsNode
  variant?: '' | 'main'
  className?: string
}

const ArticoloCard = (props: Props) => {
  const { news = undefined, variant = '', className = '' } = props

  const t = useTrans()
  const router = useRouter()

  return news ? (
    <div className={`${styles.root} ${variant ? styles[`root--${variant}`] : ''} ${className}`}>
      <div className={styles.imageBox}>
        <WImage src={news.previewImage} maxWidth={768} maxHeight={424} objectFit="cover" />
      </div>
      <div className={styles.main}>
        <div className={styles.head}>
          <Tag label={news.categoria?.nome} variant={'ideeverdi'} />
          {!!news.tempoLettura && (
            <div className={styles.time}>
              <span className={styles.timeIcon}>
                <Time />
              </span>
              <span className={styles.timeLabel}>
                {t('{0} min di lettura', [news.tempoLettura.toString()])}
              </span>
            </div>
          )}
        </div>
        <h3 className={styles.title}>{news.titolo}</h3>
        {!!news.sottotitolo && !hasHTML(news.sottotitolo) && (
          <div className={styles.description}>{news.sottotitolo}</div>
        )}
        <WLink
          href={news.url}
          className={styles.cta}
          onClick={() => {
            handleSelectContentTracking(
              news.titolo,
              'article',
              news?.categoria?.nome || news.titolo,
              router.asPath
            )
          }}
        >
          <Button label={t("Leggi l'articolo")} variant="ghost" />
        </WLink>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ArticoloCard
