import React from 'react'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useEditor } from '../../hooks/useEditor'

export const defaultProps = {
  oldStyle: false,
}

export const dynamicProps = []

export const ROOT = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    const { children } = props
    const { oldStyle } = blockProps

    return (
      <div
        ref={(ref) => props.retRef(ref, 'connect')}
        className={
          (enabled ? 'ROOT--editing ' : '') + (oldStyle ? 'ROOT--old-style ' : '') + props.className
        }
        onDoubleClick={props.onDoubleClick}
      >
        {children}
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

ROOT.craft = withCraft({
  name: 'ROOT',
  defaultProps,
  settings: null,
})
