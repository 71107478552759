import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { WContainer } from '@components/atoms'
import { VideoTutorialCard } from '@components/molecules'
import styles from './VideoTutorialCorrelati.module.sass'

interface Props {
  title?: string
  videoTutorial?: any[] // VideoTutorialNode
  className?: string
}

const VideoTutorialCorrelati = (props: Props) => {
  const { title = undefined, videoTutorial = [], className = '' } = props

  return videoTutorial?.length ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          {!!title && <h2 className={styles.title}>{title}</h2>}
          <div className={`${styles.slider}`}>
            <Swiper
              slidesPerView={1.1}
              spaceBetween={24}
              modules={[A11y, Navigation, Pagination]}
              navigation
              pagination={{ clickable: true, type: 'progressbar' }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              className="swiper--bottomNav"
            >
              {videoTutorial.map((x) => (
                <SwiperSlide key={x.pk}>
                  <VideoTutorialCard videoTutorial={x} className={styles.item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default VideoTutorialCorrelati
