import React from 'react'
import styles from './FiltroRadio.module.sass'
import { Accordion, FormRadio } from '@components/atoms'
import { OptionProps } from '@components/atoms/FormRadio/FormRadio'

interface Props {
  id: string | number
  label?: string
  value?: string
  options?: OptionProps[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const FiltroRadio = (props: Props) => {
  const {
    id = undefined,
    label = undefined,
    value = undefined,
    options = [],
    onChange = undefined,
    className = '',
  } = props

  return options?.length ? (
    <Accordion
      id={`filtroradio_${id}`}
      variant="filter"
      label={label}
      className={`${styles.root} ${className}`}
    >
      <FormRadio
        id={`filtroradio_${id}`}
        value={value}
        variant="filtroRadio"
        options={options}
        onChange={onChange}
      />
    </Accordion>
  ) : (
    <></>
  )
}

export default FiltroRadio
