import React from 'react'
import useTrans from '@hooks/useTrans'
import { ArrowRight } from '@components/icons'
import { Button, ProgressBar } from '@components/atoms'
import styles from './ProfiloProgressCard.module.sass'

interface Props {
  progress?: number
  layout?: '' | 'full'
  className?: string
}

const ProfiloProgressCard = (props: Props) => {
  const { progress = undefined, layout = '', className = '' } = props

  const t = useTrans()

  return progress < 100 ? (
    <div className={`${styles.root} ${layout ? styles[`root--${layout}`] : ''} ${className}`}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('Il tuo profilo non è completo')}</h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: t(
              'Completa il profilo inserendo le informazioni mancanti e ricevi  via email la mini guida con i nostri consigli per prenderti cura del verde tutto l’anno.'
            ),
          }}
        />
      </div>
      <div className={styles.progress}>
        <div
          className={styles.progressLabel}
          dangerouslySetInnerHTML={{
            __html: t(`<span>{0}%</span> completato`, [progress.toString()]),
          }}
        />
        <ProgressBar progress={progress} className={styles.progressBar} />
        <Button
          label={t('Completa il profilo')}
          iconRight={<ArrowRight />}
          className={styles.button}
          href={'/myaccount/profile/edit'}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProfiloProgressCard
