import React from 'react'

export const HomeHeroSettings = (settingsProps) => {
  return (
    <>
      <div className="empty-message">
        <p>No options available</p>
      </div>
    </>
  )
}
