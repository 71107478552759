/* eslint-disable react/button-has-type */
import React, { ReactNode } from 'react'
import WLink from '../WLink/WLink'
import { Loader } from '@components/icons'
import styles from './Button.module.sass'

export interface Props {
  label?: string
  labelMobile?: string
  ariaLabel?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary' | 'ghost'
  color?: '' | 'white'
  size?: 'sm' | 'md' | 'lg'
  mobileSize?: 'sm' | 'md' | 'lg'
  theme?: 'light' | 'dark'
  disabled?: boolean
  iconLeft?: ReactNode
  iconRight?: ReactNode
  hideMobileIcon?: boolean
  hideDesktopIcon?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
  tabIndex?: number
  forceAnchor?: boolean
}

const Button = (props: Props) => {
  const {
    label = undefined,
    labelMobile = undefined,
    ariaLabel = undefined,
    type = 'button',
    variant = 'primary',
    color = '',
    size = 'md',
    theme = 'light',
    mobileSize = undefined,
    disabled = false,
    iconLeft = undefined,
    iconRight = undefined,
    hideDesktopIcon = false,
    hideMobileIcon = false,
    onClick = undefined,
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
    tabIndex = undefined,
    forceAnchor = false,
  } = props

  const classNames = `
    ${styles.root} 
    ${styles[`root--${variant}`]}
    ${styles[`root--${size}`]}
    ${styles[`root--${theme}`]}
    ${color ? styles[`root--${color}`] : ''}
    ${mobileSize && mobileSize !== size ? styles[`root--mobile-${mobileSize}`] : ''}
    ${
      !!iconLeft || !!iconRight
        ? !hideDesktopIcon
          ? !hideMobileIcon
            ? // icon desktop+mobile
              !label
              ? !labelMobile
                ? styles['root--no-label'] // No label
                : styles['root--no-label-desktop'] // Label mobile
              : typeof labelMobile !== 'undefined' && !labelMobile
              ? styles['root--no-label-mobile'] // Label desktop
              : '' // Label desktop+mobile
            : // icon desktop - no icona mobile quindi ignoro il caso label mobile
            !!label
            ? '' // Label desktop
            : styles['root--no-label-desktop'] // No label desktop
          : !hideMobileIcon
          ? // icon mobile - no icona desktop quindi ignoro il caso label desktop
            (!!label && typeof labelMobile === 'undefined') || !!labelMobile
            ? '' // Label mobile
            : styles['root--no-label-mobile'] // No label mobile
          : // no icon - gestisco tutto come se ci fosse la label
            ''
        : ''
    }
    ${hideDesktopIcon ? styles[`root--hideDesktopIcon`] : ''}
    ${hideMobileIcon ? styles[`root--hideMobileIcon`] : ''}
    ${loading ? styles['root--loading'] : ''}
    ${className ? className : ''}`

  const buttonContent = (
    <>
      {iconLeft && <span className={styles.icon}>{iconLeft}</span>}
      {label && (
        <span className={`${styles.label} ${labelMobile ? styles['label--desktop'] : ''}`}>
          {label}
        </span>
      )}
      {labelMobile && (
        <span className={`${styles.label} ${styles['label--mobile']}`}>{labelMobile}</span>
      )}
      {iconRight && <span className={styles.icon}>{iconRight}</span>}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href || forceAnchor ? (
    external ? (
      <a
        className={classNames}
        href={href}
        target={newPage ? '_blank' : null}
        rel={`noreferrer ${newPage ? 'noopener' : ''}`}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {buttonContent}
      </a>
    ) : (
      <WLink
        href={href}
        onClick={onClick}
        className={classNames}
        // rel={`noreferrer ${newPage ? 'noopener' : ''}`}
        newPage={newPage}
        ariaLabel={ariaLabel}
      >
        {buttonContent}
      </WLink>
    )
  ) : onClick || onClick || ['submit', 'reset'].includes(type) ? (
    <button
      type={type}
      className={classNames}
      disabled={disabled || loading}
      onClick={onClick}
      tabIndex={tabIndex || (onClick || ['submit', 'reset'].includes(type) ? undefined : -1)}
      aria-label={ariaLabel}
    >
      {buttonContent}
    </button>
  ) : (
    <span className={classNames}>{buttonContent}</span>
  )
}

export default Button
