import { LayerProps } from 'react-map-gl'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import { ReverseGeocodingRes } from './types'

const icons = [
  {
    name: 'allValues',
    href: '/markers/all.png',
  },
  {
    name: 'allValuesSelected',
    href: '/markers/all-selected.png',
  },
  {
    name: 'b2c',
    href: '/markers/b2c.png',
  },
  {
    name: 'b2cSelected',
    href: '/markers/b2c-selected.png',
  },
  {
    name: 'assistenza',
    href: '/markers/assistenza.png',
  },
  {
    name: 'assistenzaSelected',
    href: '/markers/assistenza-selected.png',
  },
  {
    name: 'b2bassistenza',
    href: '/markers/b2bassistenza.png',
  },
  {
    name: 'b2bassistenzaSelected',
    href: '/markers/b2bassistenza-selected.png',
  },
  {
    name: 'locationPin',
    href: '/markers/location.png',
  },
]

export function getCorrectIcon(rivenditore) {
  const tipi = rivenditore.tipi

  const hasAssistenza = tipi.includes('assistenza')
  const hasB2c = tipi.includes('rivenditore')
  const hasB2b = tipi.includes('b2b')

  // B2c -> B2c + B2b
  // B2b -> B2b + assistenza

  if (hasB2c) {
    return 'allValues'
  }

  if (hasB2b) {
    return 'b2bassistenza'
  }

  if (hasAssistenza) {
    return 'assistenza'
  }

  return null
}

export const loadIconsUtils = (mapCurrent, resolve, reject) => {
  for (let i = 0; i < icons.length; i++) {
    const element = icons[i]
    const name = element['name']
    const href = `/${process.env.SITE}/${element['href']}`

    if (!mapCurrent.hasImage(name)) {
      mapCurrent.loadImage(href, (error, image) => {
        if (error) {
          reject(error)
        } else {
          if (!mapCurrent.hasImage(name)) {
            mapCurrent.addImage(name, image)
          }
          resolve()
        }
      })
    }
  }
}

const MAINCOLORS = {
  1: '#B41818',
  2: '#D24400',
  3: '#007DB3',
  4: '#D71A1A',
}

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'rivenditori',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': MAINCOLORS[process.env.SITE_ID],
    'circle-radius': 24,
  },
}

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'rivenditori',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 16,
  },
  paint: {
    'text-color': '#ffffff',
  },
}

export const unclusteredPointGetIconLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'symbol',
  source: 'rivenditori',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
  },
}

export const locationPointGetIconLayer: LayerProps = {
  id: 'location-point',
  type: 'symbol',
  source: 'locationPin',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'locationPin',
    'icon-allow-overlap': true,
  },
}

export const getCoordinates = async (address) => {
  try {
    const response = await mbxGeocoding({ accessToken: process.env.MAPBOX_ACCESS_TOKEN })
      .forwardGeocode({
        query: address,
        limit: 1,
      })
      .send()

    if (response.body?.features?.length) {
      return response.body.features[0].center
    } else {
      throw new Error('No results found')
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error)
    throw error
  }
}

export const reverseGeocode = async (
  coordinates: number[] | string
): Promise<ReverseGeocodingRes | { message: string; error_code: string }> => {
  try {
    const respose = await fetch(
      `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${coordinates[0]}&latitude=${coordinates[1]}&access_token=${process.env.MAPBOX_ACCESS_TOKEN}`
    )
    const data = await respose.json()
    return data
  } catch (error) {
    return { message: 'Error fetching reverse geocode', error_code: '500' }
  }
}
