import React, { useState, useEffect, useContext } from 'react'
import { ROOT_NODE } from '@craftjs/core'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { useTimeout } from '../../../hooks/useTimeout'
import { PagebuilderContext } from '../../../services/pagebuilderContext'
import { Tooltip } from 'react-pagebuilder/pb-components/atoms'
import {
  Close,
  Bin,
  Undo,
  Redo,
  LockOpen,
  LockClose,
  Import,
  Export,
  Save,
  Publish,
  Unpublish,
  VisibilityOff,
  VisibilityOn,
  Preview,
  Check,
  Edit,
  More,
  PreviewMode,
  WireframeMode,
  Desktop,
  Tablet,
  Smartphone,
  SmartphoneLandscape,
  Fullscreen,
  Reducescreen,
} from 'react-pagebuilder/pb-components/icons'
import { GlobalContext } from 'react-pagebuilder/services/globalContext'
import { api } from 'react-pagebuilder/services/api'
import { generateCurrentUrlSharedPreview } from 'react-pagebuilder/utils/sharedPreview'
let downloadFile = undefined
try {
  downloadFile = require('file-saver').saveAs
} catch (er) {}

const TopbarButton = ({ id, label, icon, tooltip, onClick, disabled, href, blank, status }) => {
  const clickHandle = (event) => {
    if (disabled) {
      return
    } else {
      onClick()
    }
  }

  const content = (
    <>
      {label && <span>{label}</span>}
      {icon && icon}
    </>
  )

  const wrappedContent = href ? (
    <a
      className={`topbar__item${status ? ` topbar__item--${status}` : ''} topbar__item--${id}${
        disabled ? ' disabled' : ''
      }`}
      href={href}
      target={blank ? '_blank' : ''}
    >
      {content}
    </a>
  ) : (
    <div
      className={`topbar__item${status ? ` topbar__item--${status}` : ''} topbar__item--${id}${
        disabled ? ' disabled' : ''
      }`}
      onClick={clickHandle}
    >
      {content}
    </div>
  )

  return tooltip ? (
    <Tooltip id={id} pos="bottom" label={tooltip}>
      {wrappedContent}
    </Tooltip>
  ) : (
    <>{wrappedContent}</>
  )
}

export const Topbar = () => {
  const { query, actions, selectedNodeId, enabled, canUndo, canRedo, nodes } = useEditor(
    (state, query) => ({
      selectedNodeId: [...state.events.selected]?.[0],
      enabled: state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
      nodes: state.nodes,
    })
  )

  const {
    contentTypeId,
    objectId,
    pkey,
    setShowImport,
    previewMode,
    setPreviewMode,
    wireframeMode,
    setWireframeMode,
  } = useContext(GlobalContext)
  const { pagebuilder, isLoading, saveDraft, publish, unpublish } = useContext(PagebuilderContext)

  const isTextEditorOpen = (nodes) => {
    return Object.keys(nodes).find((nodeId) => {
      return nodes[nodeId].data?.name === 'TextEditor' && nodes[nodeId].data.custom.editing === true
    })
  }

  const [isUndoEnabled, setUndoEnabled] = useState(false)
  const [isRedoEnabled, setRedoEnabled] = useState(false)

  useEffect(() => {
    if (isTextEditorOpen(nodes)) {
      setUndoEnabled(false)
      setRedoEnabled(false)
    } else {
      setUndoEnabled(canUndo)
      setRedoEnabled(canRedo)
    }
  }, [canUndo, canRedo, nodes])

  const [viewMore, setViewMore] = useState(false)

  const clearCanvas = () => {
    const nodesToDelete = query.node(ROOT_NODE).get().data.nodes
    nodesToDelete.forEach((nodeId) => {
      actions.history.throttle(100).delete(nodeId)
    })
  }

  const [draftsDifferent, setDraftsDifferent] = useState(false)

  const removeCustoms = (data) => {
    const json = JSON.parse(data)
    let obj = {}
    Object.keys(json).forEach((nodeId) => {
      obj[nodeId] = json[nodeId]
      obj[nodeId]['custom'] = {}
    })
    return JSON.stringify(obj)
  }

  // auto-save
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false)
  const [needToSave, setNeedToSave] = useState(false)

  const save = () => {
    saveToDb()
    setNeedToSave(false)
  }

  const timeout = useTimeout(save, 4000) // ogni 4 secondi prova a salvare

  const saveToDb = () => {
    const data = query.serialize()
    saveDraft(data)
    // setDraftsDifferent(false)
  }

  const onNodesChange = (query) => {
    if (autoSaveEnabled) {
      setNeedToSave(true)
      timeout.restart()
    }

    if (removeCustoms(query.serialize()) === pagebuilder.json_draft) {
      setDraftsDifferent(false)
    } else {
      setDraftsDifferent(true)
    }
  }

  useEffect(() => {
    if (pagebuilder) {
      if (removeCustoms(query.serialize()) === pagebuilder.json_draft) {
        setDraftsDifferent(false)
      } else {
        setDraftsDifferent(true)
      }
    }
  }, [pagebuilder])

  useEffect(() => {
    actions.setOptions((options) => (options.onNodesChange = onNodesChange))
  }, [autoSaveEnabled, pagebuilder])

  const exportPagebuilder = () => {
    api
      .getPagebuilderJsonFile({ id: pagebuilder.id })
      .then((data) => {
        const file = new File([JSON.stringify(data)], `pagebuilder-${pagebuilder.id}.json`, {
          type: 'application/json',
        })
        downloadFile(file)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [fullscreen, setFullscreen] = useState(false)

  const openFullScreen = () => {
    if (fullscreen) return
    const element = document.documentElement
    if (!element) return
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen()
    }
    setFullscreen(true)
  }

  const closeFullScreen = () => {
    if (!fullscreen) return
    if (!document) return
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
    setFullscreen(false)
  }

  return (
    <div className="topbar">
      <div className="topbar__items topbar__items--previews">
        <p>Previews:</p>
        <TopbarButton
          id="draft-preview"
          href={generateCurrentUrlSharedPreview(true)}
          blank={true}
          icon={<Preview />}
          tooltip="Open draft preview"
        />
        <TopbarButton
          id="preview"
          href={generateCurrentUrlSharedPreview()}
          blank={true}
          icon={<Preview />}
          tooltip="Open preview"
        />
      </div>
      <>
        <div className="topbar__items topbar__items--main">
          <TopbarButton
            id="save-status"
            status={draftsDifferent ? (autoSaveEnabled ? 'warning' : 'error') : 'success'}
            label={
              draftsDifferent ? (autoSaveEnabled ? 'Modified...' : 'Unsaved changes') : 'Saved'
            }
            icon={draftsDifferent ? <Edit /> : <Check />}
          />
          <TopbarButton
            id="fullscreen"
            onClick={fullscreen ? closeFullScreen : openFullScreen}
            icon={fullscreen ? <Reducescreen /> : <Fullscreen />}
            tooltip={'Fullscreen'}
          />
          {/*<TopbarButton
              id="mode"
              onClick={() => setWireframeMode(!wireframeMode)}
              icon={wireframeMode ? <WireframeMode /> : <PreviewMode />}
              tooltip={wireframeMode ? 'Mode: WIREFRAME' : 'Mode: PREVIEW'}
            />*/}

          <TopbarButton
            id="undo"
            onClick={() => actions.history.undo()}
            disabled={!isUndoEnabled}
            icon={<Undo />}
            tooltip={isUndoEnabled ? 'Undo' : 'Nothing to undo'}
          />
          <TopbarButton
            id="redo"
            onClick={() => actions.history.redo()}
            disabled={!isRedoEnabled}
            icon={<Redo />}
            tooltip={isRedoEnabled ? 'Redo' : 'Nothing to redo'}
          />
          <TopbarButton id="delete" onClick={clearCanvas} icon={<Bin />} tooltip="Delete all" />

          <TopbarButton
            id="save-draft"
            onClick={saveToDb}
            disabled={!draftsDifferent}
            icon={<Save />}
            tooltip="Save draft"
          />
          <TopbarButton
            id="publish"
            onClick={publish}
            disabled={pagebuilder.json_draft === pagebuilder.json_published}
            icon={<Publish />}
            tooltip="Publish"
          />
          <TopbarButton
            id="unpublish"
            onClick={unpublish}
            disabled={pagebuilder.draft}
            icon={<Unpublish />}
            tooltip="Unpublish"
          />
          {/* SOLO DEBUG <TopbarButton onClick={printInfo} label="Print" /> */}
        </div>
        <div className={`topbar__items topbar__items--advanced ${viewMore ? 'visible' : ''}`}>
          <TopbarButton
            id="export"
            onClick={exportPagebuilder}
            icon={<Export />}
            tooltip="Export"
          />
          <TopbarButton
            id="import"
            onClick={() => setShowImport(true)}
            icon={<Import />}
            tooltip="Import from pagebuilder"
          />
          {/* <TopbarButton
              id="autosave"
              onClick={() => setAutoSaveEnabled(!autoSaveEnabled)}
              icon={autoSaveEnabled ? <LockClose /> : <LockOpen />}
              tooltip={autoSaveEnabled ? 'Autosave ENABLED' : 'Autosave DISABLED'}
            /> */}
        </div>
        <div className="topbar__items topbar__items--more">
          <TopbarButton
            id="more"
            onClick={() => setViewMore(!viewMore)}
            tooltip={viewMore ? 'Hide advanced options' : 'Show advanced actions'}
            icon={viewMore ? <Close /> : <More />}
          />
        </div>
      </>
    </div>
  )
}
