import React from 'react'
import { PaginaNode } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { getIcon } from '@utils/icons'
import { ArrowLeft } from '@components/icons'
import { Breadcrumbs, Button, WContainer } from '@components/atoms'
import { AcquistiOnlineSidebar } from '@components/organisms'
import styles from './AcquistiOnlineLayout.module.sass'

interface Props {
  pagina: PaginaNode
  children?: React.ReactNode
  className?: string
}

const AcquistiOnlineLayout = (props: Props) => {
  const { pagina = undefined, children = undefined, className = '' } = props

  const t = useTrans()

  const sidebarNavItem = {
    pk: 1,
    iconLeft: getIcon(pagina.parent?.icon),
    name: pagina.parent?.titolo,
    url: pagina.parent?.url,
    children: pagina.siblings?.map(({ pk, titolo, url, icon }) => ({
      pk,
      iconLeft: getIcon(icon),
      name: titolo,
      url,
    })),
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <AcquistiOnlineSidebar
            item={sidebarNavItem}
            activePk={pagina.pk}
            className={styles.sidebar}
          />
          <main className={styles.main}>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} className={styles.breadcrumbs} />
            <Button
              label={t('Indietro')}
              iconLeft={<ArrowLeft />}
              href={sidebarNavItem.url}
              variant="ghost"
              className={styles.back}
            />
            <h1 className={styles.title}>{pagina?.titolo}</h1>
            {!!pagina.descrizione && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: pagina?.descrizione }}
              />
            )}
            {children}
          </main>
        </div>
      </WContainer>
    </div>
  )
}

export default AcquistiOnlineLayout
