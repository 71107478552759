import React from 'react'
import useTrans from '@hooks/useTrans'
import { Accordion, Button } from '@components/atoms'
import {
  CheckoutPromoForm,
  CheckoutRiepilogoCosti,
  CheckoutRiepilogoProdottoCard,
} from '@components/molecules'
import { CheckoutStepBlock } from '@components/organisms'
import styles from './CheckoutRiepilogo.module.sass'

interface Props {
  dati?: any
  variant?: 'cart' | 'checkout'
  className?: string
}

const CheckoutRiepilogo = (props: Props) => {
  const { dati = undefined, variant = 'checkout', className = '' } = props

  const t = useTrans()

  return (
    <div className={`${styles.root} ${styles[`root--${variant}`]} ${className}`}>
      {variant === 'cart' && (
        <Button
          label={t("Procedi con l'ordine")}
          size="lg"
          className={`${styles.cta} ${styles['cta--mobile']}`}
          href="/cart/checkout"
        />
      )}
      <CheckoutStepBlock
        title={t('Riepilogo')}
        status={'open'}
        hideHeadMobile
        className={styles.block}
      >
        <div className={styles.layout}>
          <div className={styles.main}>
            {variant !== 'cart' && (
              <div className={styles.products}>
                <Accordion
                  id="checkout_riepilogo_accordion_products"
                  label={t('Prodotti ({0})', [`${dati?.items?.length}`])}
                >
                  <div className={styles.productsList}>
                    {dati?.items.map((x) => (
                      <CheckoutRiepilogoProdottoCard
                        key={x.pk}
                        prodotto={x.prodotto.prodotto}
                        quantita={x.quantita}
                      />
                    ))}
                  </div>
                </Accordion>
              </div>
            )}
            {variant === 'cart' && (
              <CheckoutPromoForm
                id={'checkout_riepilogo_promo_form'}
                className={styles.promo}
                promo={dati?.promoApplicata}
              />
            )}
            <CheckoutRiepilogoCosti dati={dati} />
          </div>
          {variant === 'cart' && (
            <Button
              label={t("Procedi con l'ordine")}
              size="lg"
              className={`${styles.cta} ${styles['cta--desktop']}`}
              href="/cart/checkout"
            />
          )}
        </div>
      </CheckoutStepBlock>
    </div>
  )
}

export default CheckoutRiepilogo
