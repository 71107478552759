// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://3e311c0047814f6d8d83b5d0f542856c@o19983.ingest.us.sentry.io/6522960',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['*'],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection captured*',
    'Wrapped error for Dust debugging. Original error message: Argument error in toBase64. Expected argument types [string], but received [undefined].',
  ],
})
