import React from 'react'
import { MenuItemType } from '@utils/types'
import { EditableVideo } from '@components/atoms'
import styles from './BlogVideo.module.sass'

interface Props {
  item: MenuItemType
  editMode?: boolean
  className?: string
}

const BlogVideo = (props: Props) => {
  const { item = undefined, editMode = false, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''}  ${className}`}>
      <EditableVideo item={item.video} preserveRatio editMode={editMode} className={styles.video} />
    </div>
  ) : (
    <></>
  )
}

export default BlogVideo
