import React, { useContext } from 'react'
import { WImage, Button, EditableTitle, EditableDescription, WModal } from '@components/atoms'
import styles from './PopupModal.module.sass'
import { Close } from '@components/icons'
import useTrans from '@hooks/useTrans'
import { PromoModalContext } from '@utils/promoModalContext'
import { handlePopupPromoTracking } from '@utils/tracking'
import useIsMobile from '@hooks/useIsMobile'
import { PopupNode } from '@gql/graphql'

const PopupModal = () => {
  const { promoModal, setPromoModal } = useContext(PromoModalContext)
  const t = useTrans()
  const isMobile = useIsMobile()

  const item: PopupNode = promoModal.data
  if (!item) return null

  return (
    <WModal
      className={
        promoModal.open && !(sessionStorage.getItem('popup_viewed') === 'true') ? styles.root : ''
      }
      id="promo-modal"
      open={promoModal.open && !(sessionStorage.getItem('popup_viewed') === 'true')}
    >
      <button
        type="button"
        aria-label={t('Chiudi')}
        onClick={() => {
          handlePopupPromoTracking(item.titolo, 'close')
          setPromoModal({ ...promoModal, open: false })
          sessionStorage.setItem('popup_viewed', 'true')
        }}
        className={styles.close}
      >
        <Close />
      </button>

      {!!item.image && (
        <div className={styles.imageBox}>
          <WImage
            src={isMobile && item.mobileImage ? item.mobileImage : item.image}
            maxWidth={600}
            maxHeight={300}
            objectFit="cover"
            alt={item.titolo}
            title={item.titolo}
          />
        </div>
      )}

      <div className={styles.main}>
        <div className={styles.content}>
          <EditableTitle title={item.titolo} variant="h3" className={styles.title} />
          <EditableDescription description={item.descrizione} className={styles.description} />
        </div>

        {(item.ctaLabel1 || item.ctaLabel2) && (
          <div className={styles.ctaBox}>
            {item.ctaLabel1 && item.url1 && (
              <Button
                href={item.url1}
                label={item.ctaLabel1}
                newPage={!item.linkedObjectId1}
                variant="secondary"
                onClick={() => {
                  handlePopupPromoTracking(item.titolo, 'scopri_la_promo')
                  setPromoModal({ ...promoModal, open: false })
                  sessionStorage.setItem('popup_viewed', 'true')
                }}
              />
            )}

            {item.ctaLabel2 && item.url2 && (
              <Button
                href={item.url2}
                label={item.ctaLabel2}
                newPage={!item.linkedObjectId2}
                variant="primary"
                onClick={() => {
                  handlePopupPromoTracking(item.titolo, 'scopri_la_promo')
                  setPromoModal({ ...promoModal, open: false })
                  sessionStorage.setItem('popup_viewed', 'true')
                }}
              />
            )}
          </div>
        )}
      </div>
    </WModal>
  )
}

export default PopupModal
