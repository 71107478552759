import { getAssetsUrl } from '.'
import { getIcon } from './icons'
import { MenuItemType, PopupPromoItem } from './types'

export const mockArray = (mock: (index: number) => any, num: number, indexBase: number = 1) =>
  [...Array(num).keys()].map((k) => mock(k + indexBase))

export const imgUrl = (w, h = null) => `https://placehold.co/${w}x${h || w}`

export const mockGenericItem: (index: number) => MenuItemType = (index: number) => ({
  pk: index,
  image: imgUrl(1920, 780),
  intro: 'Tag',
  icon: 'arrow_right',
  iconLeft: getIcon('arrow_right'),
  name: 'Name',
  description:
    '<p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
  ctaLabel: 'Button',
  url: '/',
  video: {
    provider: 'local',
    url: getAssetsUrl('/video/example.mp4'),
    autoplay: true,
  },
})

export const mockPagina = (index: number) => ({
  pk: index,
  titolo: 'Titolo',
  descrizione:
    '<p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit, sed do eiusmod tempor incididunt.</p>',
  mainImage: imgUrl(1296, 400),
  breadcrumbs: [{ label: 'Home', url: '/' }, { label: 'Titolo' }],
})

export const mockPaginaNoImage = (index: number) => ({
  pk: index,
  titolo: 'Titolo',
  descrizione:
    '<p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipiscing elit, sed do eiusmod tempor incididunt.</p>',
  breadcrumbs: [{ label: 'Home', url: '/' }, { label: 'Titolo' }],
})

export const mockStripe = (index: number) => ({
  pk: index,
  boxTitolo: 'Category',
  url: '/',
  previewImage: imgUrl(54),
})

export const mockNewsLinkedObject = (index) => ({
  pk: index,
  categoria: { nome: 'Consigli sempreverdi' },
  tempoLettura: 5,
  previewImage: imgUrl(120),
  titolo: 'Quali piante crescono bene con la vite?',
  sottotitolo:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
  url: '/',
})

export const mockNewsSearchResult = (index) => ({
  pk: index,
  categoria: { nome: 'Consigli sempreverdi' },
  tempoLettura: 5,
  image: imgUrl(120),
  titolo: 'Quali piante crescono bene con la vite?',
  sottotitolo:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
  url: '/',
})

export const mockNews = (index) => ({
  pk: index,
  categoria: { nome: 'Consigli sempreverdi' },
  tempoLettura: 5,
  mainImage: imgUrl(1080, 500),
  titolo: 'Quali piante crescono bene con la vite?',
  titoloListing: 'Titolo listing',
  sottotitoloListing: '<p>Lorem ipsum sottotitolo listing</p>',
  descrizione:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>',
  url: '/',
})

export const mockCategoriaProdotto = (index) => ({
  pk: index,
  nome: 'Categoria',
  boxTitolo: 'Categoria',
  url: '/',
  numeroProdotti: 30,
  previewImage: imgUrl(648, 500),
  sottoTitolo: 'Testo seo',
  descrizione: '<p>Lorem ipsum</p>',
  guidaDettagliata:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <a href="#">exercitation</a> ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>',
  offertaMarketingBox: mockGenericItem(1),
})

export const mockProdottoLinkedObject = (index) => ({
  pk: index,
  nome: 'BCi 30 con batteria Bi 5,0 OM e caricabatterie CRG',
  url: '/',
  previewImage: imgUrl(70),
  categoria: {
    alias: 'Decespugliatori a batteria',
    boxTitolo: 'Decespugliatori a batteria',
  },
  utilizzo: 'Professionale',
})

export const mockProdottoSearchResult = (index) => ({
  django_id: index,
  title: 'BCi 30 con batteria Bi 5,0 OM e caricabatterie CRG',
  url: '/',
  image: imgUrl(70),
  categoria: 'Decespugliatori a batteria',
  utilizzo_alias: 'Professionale',
})

export const mockPlusPoint = (index) => ({
  pk: index,
  categoria: {
    nome: 'Categoria',
  },
  descrizione:
    index === 1
      ? '<p>Lorem <a href="#">ipsum</a> Lorem <a href="#">ipsum</a> Lorem <a href="#">ipsum</a> Lorem <a href="#">ipsum</a> Lorem <a href="#">ipsum</a> Lorem <a href="#">ipsum</a></p>'
      : '<p>Lorem <a href="#">ipsum</a></p>',
  immagine: imgUrl(650, 500),
  prodotto: mockProdotto(index),
  titolo: 'Titolo plus point',
})

export const mockRivenditore = (index) => ({
  pk: index,
  // attivoPromoCoupon: Scalars['Boolean'];
  // bonifico?: Maybe<Scalars['String']>;
  // cap?: Maybe<Scalars['String']>;
  // cf?: Maybe<Scalars['String']>;
  // checkout: Scalars['Boolean'];
  citta: 'San Mauro Torinese',
  // codiceEmak?: Maybe<Scalars['String']>;
  // codiceRif?: Maybe<Scalars['String']>;
  // cognome?: Maybe<Scalars['String']>;
  // consensoMkt: Scalars['Boolean'];
  // consensoProfilazione: Scalars['Boolean'];
  // created?: Maybe<Scalars['DateTime']>;
  // customerIdStripe?: Maybe<Scalars['String']>;
  // dataDiNascita?: Maybe<Scalars['Date']>;
  // dateJoined: Scalars['DateTime'];
  email: 'amministrazione@mantovelli.it',
  // emailConfermata: Scalars['Boolean'];
  // fax?: Maybe<Scalars['String']>;
  // guest: Scalars['Boolean'];
  // id: Scalars['ID'];
  // idOld?: Maybe<Scalars['Float']>;
  // indirizzi: Array<IndirizzoNode>;
  indirizzo: 'Via settimo, 178',
  // interessi: Array<InteresseNewsletterNode>;
  // isActive: Scalars['Boolean'];
  // isStaff: Scalars['Boolean'];
  // /** Attribuisce all'utente tutti i privilegi, senza che sia necessario assegnarli esplicitamente. */
  // isSuperuser: Scalars['Boolean'];
  // lastLogin?: Maybe<Scalars['DateTime']>;
  latitudine: 44.70014049242609,
  longitudine: 10.638052986419321,
  // luogoDiNascita?: Maybe<Scalars['String']>;
  // modified?: Maybe<Scalars['DateTime']>;
  nome: 'Nome rivenditore',
  // nota?: Maybe<Scalars['String']>;
  // ordini: OrdineNodeConnection;
  // password: Scalars['String'];
  // paypalPassword?: Maybe<Scalars['String']>;
  // paypalSignature?: Maybe<Scalars['String']>;
  // paypalUsername?: Maybe<Scalars['String']>;
  // pec?: Maybe<Scalars['String']>;
  // piva?: Maybe<Scalars['String']>;
  // pk?: Maybe<Scalars['Int']>;
  // privato: Scalars['Boolean'];
  // professione?: Maybe<Scalars['String']>;
  // professionista: Scalars['Boolean'];
  // promozioni: Array<PromozioneNode>;
  // promozioniAbilitate: Array<PromozioneNode>;
  provincia: 'TO',
  ragioneSociale: 'Ragione sociale rivenditore',
  // rating?: Maybe<Scalars['Int']>;
  // righeCarrello: Array<RigaCarrelloNode>;
  // righeCheckout: Array<RigaCheckoutNode>;
  // rivenditore?: Maybe<RivenditoreNode>;
  // satispayBearer?: Maybe<Scalars['String']>;
  // sdi?: Maybe<Scalars['String']>;
  // sesso?: Maybe<Scalars['String']>;
  // setefiCommerciante?: Maybe<Scalars['String']>;
  // setefiPassword?: Maybe<Scalars['String']>;
  // setefiUsername?: Maybe<Scalars['String']>;
  sistemiPagamento: [
    { pk: 1, nome: 'Carta di credito' },
    { pk: 2, nome: 'Paypal' },
    { pk: 3, nome: 'Bonifico' },
  ],
  // stripeAccountid?: Maybe<Scalars['String']>;
  // stripePrivatekey?: Maybe<Scalars['String']>;
  // stripePublickey?: Maybe<Scalars['String']>;
  telefono: '0522123456',
  // unicreditHash?: Maybe<Scalars['String']>;
  // unicreditTid?: Maybe<Scalars['String']>;
  // username: Scalars['String'];
  // utentePtr: UtenteNode;
})

export const mockProdotto = (index, correlati = false) => ({
  pk: index,
  codice: '54049001S2',
  nome: 'BCi 30 con batteria Bi 5,0 OM e caricabatterie CRG',
  descrizione:
    'Con il decespugliatore a batteria BCi 30 ti prendi cura del tuo giardino fino all’ultimo filo d’erba. Una volta impugnato, la sua leggerezza e la sua semplicità di utilizzo ti conquisteranno.',
  descrizioneBreve:
    'La batteria agli ioni di litio ti assicura la massima resa ed è intercambiabile con quella da 5 Ah.',
  url: '/',
  image: imgUrl(120),
  previewImage: imgUrl(229, 180),
  novita: index === 1,
  promo: index < 3,
  utilizzo: 'Professionale',
  prezzo: 321,
  prezzoOmnibus: 299,
  prezzoScontato: 279,
  prezzoUnitario: 321,
  showTaglia: true,
  disponibile: index % 2 !== 0,
  plusPoints: [
    {
      pk: index * 100 + 1,
      titolo: 'Batteria Bi 2,0 OM',
      descrizione:
        '<p>La batteria agli ioni di litio ti assicura la massima resa ed è intercambiabile con quella da 5 Ah.</p>',
    },
    {
      pk: index * 100 + 2,
      titolo: 'Batteria Bi 2,0 OM',
      descrizione:
        '<p>La batteria agli ioni di litio ti assicura la massima resa ed è intercambiabile con quella da 5 Ah.</p>',
    },
    {
      pk: index * 100 + 3,
      titolo: 'Batteria Bi 2,0 OM',
      descrizione:
        '<p>La batteria agli ioni di litio ti assicura la massima resa ed è intercambiabile con quella da 5 Ah.</p>',
    },
    {
      pk: index * 100 + 4,
      titolo: 'Batteria Bi 2,0 OM',
      descrizione:
        '<p>La batteria agli ioni di litio ti assicura la massima resa ed è intercambiabile con quella da 5 Ah.</p>',
    },
  ],
  prodottiTendina: [
    { pk: index * 100 + 1, labelTendina: 'S', url: '/' },
    { pk: index, labelTendina: 'M', url: '/' },
    { pk: index * 100 + 3, labelTendina: 'L', url: '/' },
    { pk: index * 100 + 4, labelTendina: 'XL', url: '/' },
  ],
  prodottiCorrelati: correlati ? mockArray(mockProdotto, 4) : [],
  stato: {
    codice: '',
  },
  categoria: {
    alias: 'Decespugliatori a batteria',
    nome: 'Decespugliatori a batteria',
    boxTitolo: 'Decespugliatori a batteria',
  },
  attributi: [
    {
      pk: index * 100 + 1,
      chiave: 'Dotazione di taglio',
      valori: ['Testina Load&Go Ø 103 mm, con filo nylon Ø 2 mm'],
    },
    {
      pk: index * 100 + 2,
      chiave: 'Autonomia fino a',
      valori: [
        "2,0 Ah: 24' uso leggero",
        "2,0 Ah: 16' uso intensivo",
        "5,0 Ah: 60' uso leggero",
        "5,0 Ah: 40' uso intensivo",
      ],
    },
    { pk: index * 100 + 3, chiave: 'Tipo di motore', valori: ['Brushed (a spazzole)'] },
    { pk: index * 100 + 4, chiave: 'Impugnatura', valori: ['Singola, regolabile'] },
    { pk: index * 100 + 5, chiave: 'Diametro di taglio', valori: ['30 cm'] },
    { pk: index * 100 + 6, chiave: 'Batterie compatibili', valori: ['Bi 2,0 OM, Bi 5,0 OM'] },
    {
      pk: index * 100 + 7,
      chiave: 'Tempo di ricarica con CRG',
      valori: ['88’ (2,0 Ah), 220’ (5,0 Ah)'],
    },
    {
      pk: index * 100 + 8,
      chiave: 'Tempo di ricarica con FAST CRG',
      valori: ['40’ (2,0 Ah), 90’ (5,0 Ah)'],
    },
    { pk: index * 100 + 9, chiave: 'Livello pressione/potenza acustica', valori: ['76/87 dB(A)'] },
    { pk: index * 100 + 10, chiave: 'Livello di vibrazioni sx/dx', valori: ['< 2,5 m/s'] },
    {
      pk: index * 100 + 11,
      chiave: 'Dotazione',
      valori: ['Prezzo con batteria Bi 2,0 Ah e caricabatterie CRG'],
    },
    { pk: index * 100 + 12, chiave: 'Peso senza batteria', valori: ['2,9 kg'] },
  ],
  loghi: [
    {
      pk: index * 100 + 1,
      descrizione: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: imgUrl(68),
    },
    {
      pk: index * 100 + 2,
      descrizione: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: imgUrl(68),
    },
    {
      pk: index * 100 + 3,
      descrizione: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: imgUrl(68),
    },
    {
      pk: index * 100 + 4,
      descrizione: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: imgUrl(68),
    },
  ],
  documenti: [
    { pk: index * 100 + 1, nomeDocumento: 'Manuale - 5450030A - CZ', url: '/' },
    { pk: index * 100 + 2, nomeDocumento: 'Manuale - 5450030A - CZ', url: '/' },
    { pk: index * 100 + 3, nomeDocumento: 'Manuale - 5450030A - CZ', url: '/' },
    { pk: index * 100 + 4, nomeDocumento: 'Manuale - 5450030A - CZ', url: '/' },
  ],
  details:
    index % 3 === 0
      ? [
          'Potenza/Cilindrata 1,2 HP - 0,9 kW/25,4 cm³ 0,9 kW/25,4 cm³',
          'Dotazione di taglio testina Load&Go Ø 110 mm con filo Ø 2,40 mm',
        ]
      : ['La massima comodità per la cura del tuo giardino'],
})

export const mockBundleProdotti = (index) => ({
  pk: index,
  titolo: 'Vuoi ottenere lo sconto?',
  descrizione:
    'Se acquisti il tagliaerba Gi 44 P insieme al decespugliatore BCi 30, risparmi €35 sul totale dei due prodotti.',
  prodottiBundle: mockArray(mockProdotto, 3),
  valore: 35,
})

export const mockRigaCarrello = (index) => ({
  pk: index,
  omaggio: index === 2,
  prodotto: { prodotto: mockProdotto(index) },
  quantita: 1,
})

export const mockCarrello = (index) => ({
  items: mockArray(mockRigaCarrello, 2),
  numProdotti: 2,
  prezzo: 143,
  promoApplicata: {
    pk: 1,
    codice: 'PROMO20',
  },
  scontoAssoluto: 25,
  scontoPercentuale: 0,
  totaleProdotti: 143,
  totaleProdottiScontato: 118,
  totaleScontato: 128,
  totaleServizi: 10,
  hasOmaggio: false,
  correlati: mockProdotto(2),
})

export const sistemiSpedizioneMock = [
  {
    pk: 1,
    alias: 'Consegna a domicilio',
    descrizioneEstesa:
      '<p>Il rivenditore effettuerà la spedizione e il corriere consegnerà i prodotti direttamente a casa tua.</p>\
<p>Garantiamo la consegna al corriere in 6/10 giorni lavorativi.</p>',
    prezzo: 0,
    chiave: 'domicilio',
  },
  {
    pk: 2,
    alias: 'Ritiro in negozio',
    descrizioneEstesa:
      '<p>Potrai ritirare la merce presso il Rivenditore più vicino a te senza spese aggiuntive.</p>',
    prezzo: 10,
    chiave: 'ritiro',
  },
]

export const mockRigaCheckout = (index) => ({
  pk: index,
  omaggio: index === 2,
  prodotto: { prodotto: mockProdotto(index) },
  quantita: 1,
})

export const mockCheckout = (index) => ({
  items: mockArray(mockRigaCheckout, 2),
  numProdotti: 2,
  prezzo: 143,
  promoApplicata: {
    pk: 1,
    codice: 'PROMO20',
  },
  scontoAssoluto: 25,
  scontoPercentuale: 0,
  totaleProdotti: 143,
  totaleProdottiScontato: 118,
  totaleScontato: 128,
  totaleServizi: 10,
  indirizzoFatturazione: mockIndirizzo(index),
  indirizzoSpedizione: mockIndirizzo(index),
  rivenditore: mockRivenditore(index),
  rivenditori: mockArray(mockRivenditore, 3),
  sistemaPagamento: { pk: 1, nome: 'Carta di credito' },
  sistemaSpedizione: { pk: 1, nome: 'Consegna a domicilio' },
  sistemiPagamento: [
    { pk: 1, nome: 'Carta di credito' },
    { pk: 2, nome: 'Paypal' },
    { pk: 3, nome: 'Bonifico' },
  ],
  sistemiSpedizione: [
    { pk: 1, nome: 'Consegna a domicilio' },
    { pk: 2, nome: 'Ritiro in negozio' },
  ],
  totaleScontatoNetto: 140,
  totaleSenzaPromozioni: 143,
})

export const mockRigaOrdine = (index) => ({
  pk: index,
  omaggio: index === 2,
  prezzoTotale: 174,
  prezzoTotaleScontato: 143,
  quantitaOrdinata: index,
  prodotto: {
    prodotto: mockProdotto(index),
  },
})

export const mockSistemaPagamento = (index) => ({
  pk: index,
  alias: 'bonifico',
  attivo: true,
  // className: Scalars['String'];
  // created?: Maybe<Scalars['DateTime']>;
  // descrizione: ''
  // descrizioneEn?: Maybe<Scalars['String']>;
  // descrizioneEnGb?: Maybe<Scalars['String']>;
  // descrizioneEs?: Maybe<Scalars['String']>;
  // descrizioneIt?: Maybe<Scalars['String']>;
  // famiglia?: Maybe<FamigliaSistemaPagamentoNode>;
  // id: Scalars['ID'];
  // image?: Maybe<Scalars['String']>;
  // modified?: Maybe<Scalars['DateTime']>;
  nome: 'Bonifico',
  // nomeEn?: Maybe<Scalars['String']>;
  // nomeEnGb?: Maybe<Scalars['String']>;
  // nomeEs?: Maybe<Scalars['String']>;
  // nomeIt?: Maybe<Scalars['String']>;
  // ordinamento: Scalars['Int'];
  // ordini: OrdineNodeConnection;
})

export const mockOrdine = (index) => ({
  pk: index,
  numeroOdv: 'OM0019228',
  data: '2024-08-28',
  rivenditore: mockRivenditore(1),
  righeOrdine: mockArray(mockRigaOrdine, 4),
  sistemaPagamento: mockSistemaPagamento(1),
  prezzoTotaleScontato: 69,
  codice: 53711929171,
  stato: {
    nome: 'Consegnato',
  },
})

export const mockVideoTutorialLinkedObject = (index) => ({
  pk: index,
  titolo: 'Tutorial: come montare un decespugliatore',
  categoria: { titolo: 'Tutorial' },
  idYoutube: '4QmB9tqjPjY',
  url: '/',
})

export const mockVideoTutorialSearchResult = (index) => ({
  pk: index,
  titolo: 'Tutorial: come montare un decespugliatore',
  categoria: 'Tutorial',
  idYoutube: '4QmB9tqjPjY',
  url: '/',
})

export const mockPaginaSearchResult = (index) => ({
  pk: index,
  titolo: 'Pagina generica',
  descrizione:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
  url: '/',
})

export const mockPromozione = (index) => ({
  pk: index,
  intro: 'In negozio e online',
  name: 'Promo Primavera',
  data: 'Dal 22 marzo al 30 giugno',
  description: `
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <p class="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
  `,
  image: imgUrl(960, 500),
  ctaLabel: 'Button text',
  url: '/',
})

export const mockFiltro = (index: number) => ({
  nome: 'Filtro ' + index,
  children: [
    { id: 1, nome: 'Attributo 1', facets: 4 },
    { id: 2, nome: 'Attributo 2', facets: 5, selected: true },
    { id: 3, nome: 'Attributo 3', facets: 1 },
    { id: 4, nome: 'Attributo 4', facets: 4 },
  ],
})

export const mockProdottiListing = (index: number) => ({
  elementi: mockArray(mockProdotto, 11),
  attributi: mockArray(mockFiltro, 12),
  ordinamentsi: [
    { label: 'Consigliati', value: 'consigliati' },
    { label: 'Prezzo crescente', value: 'prezzo' },
    { label: 'Prezzo decrescente', value: '-prezzo' },
  ],
  paginator: {
    itemsPerPage: 12,
  },
  route: undefined,
})

export const mockIndirizzo = (index: number) => ({
  pk: index,
  nome: 'Annalisa',
  cognome: 'Rossi',
  indirizzo: 'via Domodossola 34',
  citta: 'Milano',
  cap: '20100',
  telefono: '3334455666',
  isDefault: index % 2 != 0,
  provincia: 'mi',
  nazione: 'it',
  note: 'Chiamare numero telefono alla consegna, grazie',
})

export const mockCertificatoGaranzia = (index: number) => ({
  pk: index,
  prodotto: mockProdotto(index),
  numeroSerie: '43561992011',
  dataRegistrazione: '2023-07-14',
  dataScadenza: '2025-07-14',
})

export const menusMock: MenuItemType = {
  children: [
    {
      pk: 1,
      key: 'top',
      children: [
        { pk: 11, name: 'I nostri rivenditori', url: '/' },
        { pk: 12, name: 'Idee verdi', style: 'green', url: '/' },
        { pk: 13, name: 'Sponsorizzazioni', url: '/' },
        { pk: 14, name: 'Eventi', url: '/' },
      ],
    },
    {
      pk: 2,
      key: 'utils',
      children: [{ pk: 21, name: 'Hai bisogno di assistenza?', url: '/' }],
    },
    {
      pk: 3,
      key: 'main',
      children: [
        {
          pk: 31,
          children: [
            {
              pk: 311,
              name: 'Prodotti',
              image: imgUrl(40),
              children: [
                {
                  pk: 3111,
                  name: 'Label',
                  children: [
                    {
                      pk: 31111,
                      name: 'Category name 1',
                      image: imgUrl(40),
                      children: [
                        {
                          pk: 311111,
                          name: 'Label',
                          children: [
                            { pk: 3111111, name: 'Category name 1', url: '/' },
                            { pk: 3111112, name: 'Category name 2', url: '/' },
                            { pk: 3111113, name: 'Category name 3', url: '/' },
                            { pk: 3111114, name: 'Category name 4', url: '/' },
                          ],
                        },
                        {
                          pk: 311112,
                          name: '',
                          style: 'promo',
                          children: [
                            {
                              pk: 3111121,
                              intro: 'Promo bundle',
                              name: 'Prendi due e risparmia',
                              description:
                                '<p>I nostri prodotti sono molto più di uno strumento per la cura del verde, ma un mezzo per vivere la tua passione.</p>',
                              ctaLabel: 'Button text',
                              url: '/',
                            },
                          ],
                        },
                        {
                          pk: 311113,
                          name: 'Articoli',
                          style: 'news',
                          children: [
                            { pk: 3111131, linkedObject: mockNewsLinkedObject(31111311) },
                            { pk: 3111132, linkedObject: mockNewsLinkedObject(31111321) },
                          ],
                        },
                        {
                          pk: 311114,
                          name: 'Prodotti in evidenza',
                          style: 'prodotti',
                          children: [
                            { pk: 3111141, linkedObject: mockProdottoLinkedObject(31111411) },
                            { pk: 3111142, linkedObject: mockProdottoLinkedObject(31111421) },
                          ],
                        },
                        {
                          pk: 311115,
                          name: 'Video',
                          style: 'video',
                          children: [
                            { pk: 3111151, linkedObject: mockVideoTutorialLinkedObject(31111511) },
                            { pk: 3111152, linkedObject: mockVideoTutorialLinkedObject(31111521) },
                          ],
                        },
                      ],
                    },
                    {
                      pk: 31112,
                      name: 'Category name 2',
                      image: imgUrl(40),
                      children: [],
                    },
                    {
                      pk: 31113,
                      name: 'Category name 3',
                      image: imgUrl(40),
                      children: [],
                    },
                  ],
                },
                {
                  pk: 3112,
                  name: 'Label',
                  children: [
                    { pk: 31121, name: 'Category name 21', children: [] },
                    { pk: 31122, name: 'Category name 22', children: [] },
                    { pk: 31123, name: 'Category name 23', children: [] },
                  ],
                },
                {
                  pk: 3113,
                  name: '',
                  children: [
                    { pk: 31131, style: 'button', name: 'Vedi tutti i prodotti', url: '/' },
                  ],
                },
              ],
            },
            {
              pk: 312,
              name: 'Accessori',
              image: imgUrl(40),
              children: [{ pk: 31211, name: 'Test', url: '/' }],
            },
            {
              pk: 313,
              name: 'Abbigliamento',
              image: imgUrl(40),
              children: [{ pk: 31311, name: 'Test', url: '/' }],
            },
          ],
        },
        {
          pk: 32,
          children: [
            {
              pk: 3211,
              name: 'Promozioni',
              icon: 'star',
              children: [{ pk: 32111, name: 'Test', url: '/' }],
            },
          ],
        },
      ],
    },
    {
      pk: 4,
      key: 'attivita',
      style: 'slider',
      name: 'Che attività devi svolgere?',
      children: [
        { pk: 41, name: "Taglio dell'erba", image: imgUrl(340, 460), url: '/' },
        { pk: 42, name: 'Taglio e potatura', image: imgUrl(340, 460), url: '/' },
        { pk: 43, name: 'Preparazione del terreno', image: imgUrl(340, 460), url: '/' },
        { pk: 44, name: 'Trattamenti e irrigazione', image: imgUrl(340, 460), url: '/' },
      ],
    },
    {
      pk: 5,
      key: 'social',
      children: [
        {
          pk: 51,
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/OleomacItalia/',
          blank: true,
        },
        {
          pk: 52,
          name: 'Facebook',
          icon: 'facebook',
          url: 'http://www.facebook.com/OleoMacItalia',
          blank: true,
        },
        {
          pk: 53,
          name: 'Youtube',
          icon: 'youtube',
          url: 'http://www.youtube.com/MyOleomac',
          blank: true,
        },
      ],
    },
    {
      pk: 6,
      key: 'footer',
      children: [
        {
          pk: 61,
          name: 'SERVIZIO CLIENTI',
          children: [
            { pk: 611, name: 'Condizioni di vendita', url: '/' },
            { pk: 612, name: 'Guida all’acquisto', url: '/' },
            { pk: 613, name: 'Contattaci** (per l’ecommerce)', url: '/' },
            { pk: 614, name: 'Pagamenti sicuri', url: '/' },
            { pk: 615, name: 'Spedizioni e resi', url: '/' },
            { pk: 616, name: 'Guida alle taglie', url: '/' },
          ],
        },
        {
          pk: 62,
          name: 'INFORMAZIONI UTILI',
          children: [
            { pk: 621, name: 'Informativa', url: '/' },
            { pk: 622, name: 'Manuali tecnici', url: '/' },
            { pk: 623, name: 'Video tutorial', url: '/' },
            { pk: 624, name: 'Download cataloghi', url: '/' },
            { pk: 625, name: 'Domande frequenti', url: '/' },
          ],
        },
        {
          pk: 63,
          name: 'MONDO OLEO-MAC',
          children: [
            { pk: 631, name: 'Chi siamo', url: '/' },
            { pk: 632, name: 'Visione aziendale', url: '/' },
            { pk: 633, name: 'Emak OPE', url: '/' },
            { pk: 634, name: 'Gruppo Emak', url: '/' },
            { pk: 635, name: 'Lavora con noi', url: '/' },
          ],
        },
        {
          pk: 64,
          name: 'AREA RISERVATA',
          children: [{ pk: 641, name: 'Area Rivenditori', url: '/' }],
        },
      ],
    },
    {
      pk: 7,
      key: 'stripe_desktop',
      description:
        'Ottieni la spedizione gratuita con una spesa minima di 60€, trinciatutto, trattorini e motocarriole esclusi.',
    },
    {
      pk: 8,
      key: 'stripe_mobile',
      description: 'Spedizione gratuita con una spesa minima di 60€',
    },
    {
      pk: 9,
      alias: 'Search',
      key: 'search',
      name: '',
      description: null,
      intro: null,
      ctaLabel: null,
      url: null,
      image: null,
      imageMobile: null,
      style: null,
      icon: null,
      linkedObject: null,
      children: [
        {
          pk: 3106,
          alias: 'Frequenti',
          key: 'search-frequenti',
          name: '',
          description: null,
          intro: null,
          ctaLabel: null,
          url: null,
          image: null,
          imageMobile: null,
          style: null,
          icon: null,
          linkedObject: null,
          children: [
            {
              pk: 3107,
              alias: 'Decespugliatori',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3108,
              alias: 'Accessori per trattorini',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3109,
              alias: 'Abbigliamento',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3110,
              alias: 'Motoseghe',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3111,
              alias: 'Motoseghe',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3112,
              alias: 'Accessori per trattorini',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
            {
              pk: 3113,
              alias: 'Abbigliamento',
              key: null,
              name: '',
              description: null,
              intro: null,
              ctaLabel: null,
              url: '/c/prodotti/decespugliatori/',
              image: null,
              imageMobile: null,
              style: null,
              icon: null,
              linkedObject: null,
              children: [],
            },
          ],
        },
        {
          pk: 3129,
          alias: 'Frequenti',
          key: 'search-banner',
          name: 'Cerchi un manuale per un articolo specifico?',
          description: null,
          intro: null,
          ctaLabel: 'Richiedilo qui',
          url: 'https://www.oleomac.it/it-it/informazioni-utili/manuali-tecnici/',
          image: null,
          imageMobile: null,
          style: null,
          icon: 'Book',
          linkedObject: null,
        },
        {
          pk: 3109,
          alias: 'Editoriale',
          key: 'search-editoriale',
          name: 'Performanti e convenienti',
          description:
            'I nostri prodotti sono molto più di uno strumento per la cura del verde, ma un mezzo per vivere la tua passione.',
          intro: 'Tag',
          ctaLabel: 'Button Text',
          url: 'https://www.efco.it/it-it/landing/promo-autunno/',
          image:
            '/media/filer_public/c6/42/c642b41b-38d4-4fbb-b362-7c70e88980f3/image.png?site__exact=2',
          imageMobile: null,
          style: null,
          icon: null,
          linkedObject: null,
          children: [],
        },
      ],
    },
  ],
}

export const mockUtente = (index: number) => ({
  nome: 'Mario',
  cognome: 'Rossi',
  email: 'mario.rossi@example.com',
  cf: 'RSSMRA85M01H501Z',
  privato: true,
  ragioneSociale: 'Mario Rossi S.r.l.',
  piva: '01234567890',
  sdi: 'ABCDEF12',
  pec: 'mario.rossi@pec.it',
})

export const testoResoHowTo = `<p>Hai bisogno di effettuare un reso? Ci sono casi in cui non occorre avviare la procedura. Per fugare ogni dubbio ti invitiamo a consultare la nostra guida.</p>

<p><strong>Prima di fare il reso:</strong></p>
<ul>
    <li>Se hai ricevuto il prodotto difettoso, danneggiato o con parti mancanti, <strong>contatta il Rivenditore</strong> che ti ha spedito il materiale per valutare insieme come procedere.</li>
    <li>Non è possibile effettuare un cambio diretto di un prodotto. Se per esempio desideri cambiare la taglia di un capo di abbigliamento, ti invitiamo a rendere il prodotto e procedere con un nuovo ordine della taglia corretta.</li>
</ul>

<p>Se invece hai cambiato idea sul tuo acquisto, puoi richiedere un <strong>reso entro 14 giorni dalla ricezione del tuo ordine.</strong></p>

<p>Per farlo ti chiediamo di:</p>
<ul>
    <li>Inviare una <u>comunicazione scritta</u> al Rivenditore che ha in carico il tuo ordine (lo trovi indicato nella conferma d'ordine).</li>
    <li>Compilare il <u>modulo di reso</u>.</li>
</ul>

<p>Dopo aver concluso questi passaggi, il Rivenditore ti contatterà per concordare le modalità di restituzione della merce.</p>

<p><strong>Informazioni utili:</strong></p>
<ul>
    <li>Per effettuare il reso il prodotto deve essere perfettamente integro.</li>
    <li>Le spese di ritorno sono a carico del cliente.</li>
    <li><a href="#">Leggi qui le condizioni di reso.</a></li>
</ul>`

export const mockCategoriaNews = (index: number) => ({
  pk: index,
  alias:
    index === 4
      ? 'Novità di prodotto'
      : index === 3
      ? 'Guida al prodotto'
      : index === 2
      ? 'Fai da te'
      : 'Cura del verde',
  nome:
    index === 4
      ? 'Novità di prodotto'
      : index === 3
      ? 'Guida al prodotto'
      : index === 2
      ? 'Fai da te'
      : 'Cura del verde',
  // descrizione = models.CharField(max_length=255, null=True, blank=True)
  slug:
    index === 4
      ? 'novita-di-prodotto'
      : index === 3
      ? 'guida-al-prodotto'
      : index === 2
      ? 'fai-da-te'
      : 'cura-del-verde',
  // ordinamento = models.IntegerField(null=False, blank=False, default=0),
  news: mockArray(mockNewsLinkedObject, 7),
  url: '/',
})

export const mockCategoriaVideoTutorial = (index: number) => ({
  pk: index,
  alias:
    index === 5
      ? 'Processo produttivo'
      : index === 4
      ? 'Guida all’utilizzo'
      : index === 3
      ? 'Montaggio e preparazione'
      : index === 2
      ? 'Presentazione prodotti'
      : 'Prodotti in azione',
  nome:
    index === 5
      ? 'Processo produttivo'
      : index === 4
      ? 'Guida all’utilizzo'
      : index === 3
      ? 'Montaggio e preparazione'
      : index === 2
      ? 'Presentazione prodotti'
      : 'Prodotti in azione',
  // descrizione = models.CharField(max_length=255, null=True, blank=True)
  slug:
    index === 5
      ? 'processo-produttivo'
      : index === 4
      ? 'guida-all-utilizzo'
      : index === 3
      ? 'montaggio-e-preparazione'
      : index === 2
      ? 'presentazione-prodotti'
      : 'prodotti-in-azione',
  // ordinamento = models.IntegerField(null=False, blank=False, default=0),
  news: mockArray(mockNewsLinkedObject, 7),
  url: '/',
})

export const mockFaq = (index: number) => ({
  pk: index,
  nome: 'A chi posso rivolgermi in caso di guasto o malfunzionamento del mio prodotto?',
  descrizione:
    '<p>Qualora il tuo prodotto sia guasto o abbia un problema funzionale ti consigliamo di rivolgerti ad un nostro Centro di Assistenza Autorizzato: un tecnico specializzato provvederà a darti l’assistenza necessaria per risolvere il tuo problema. Ricordati di esibire la prova di acquisto (scontrino o fattura).</p>',
})

export const mockCategoriaFaq = (index: number) => ({
  pk: index,
  titolo: 'Prodotto',
  descrizione: "<p>Tutto quello che c'è da sapere sui nostri prodotti e il loro utilizzo.</p>",
  url: '/',
  faqs: mockArray(mockFaq, 5),
})

export const mockPopupPromo = (index: number): PopupPromoItem => ({
  titolo: 'Title placeholder lorem ipsum dolor sit amet',
  descrizione:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  image: imgUrl(1920, 780),
  cta_label_1: 'Button text',
  url_1: '/',
  cta_label_2: 'Button text',
  visibile: true,
})
