import React, { useEffect, useRef } from 'react'
import styles from './EditableVideo.module.sass'

interface Props {
  item?: any
  preserveRatio?: boolean
  editMode?: boolean
  className?: string
  style?: any
}

export const videoProviders = {
  // Solo da youtube per ora
  // local: null,
  youtube: {
    url: 'https://www.youtube.com/embed/',
  },
  youtube_nocookie: {
    url: 'https://www.youtube-nocookie.com/embed/',
  },
  // vimeo: {
  //   url: 'https://player.vimeo.com/video/',
  // },
}

const EditableVideo = (props: Props) => {
  const {
    item = undefined,
    preserveRatio = false,
    editMode = false,
    className = '',
    style = undefined,
  } = props

  const getIframeSrc = () => {
    if (item?.provider && videoProviders[item.provider]) {
      let url = videoProviders[item.provider].url + item.videoId + '?'
      if (item.autoplay) {
        url += '&autoplay=1'
      }
      if (item.muted) {
        url += '&mute=1'
      }
      if (item.loop) {
        url += `&loop=1&playlist=${item.videoId}`
      }
      if (item.provider !== 'vimeo') {
        if (!item.controls) {
          url += '&controls=0&showinfo=0'
        }
        if (!item.related) {
          url += '&rel=0'
        }
        if (!item.disablekb) {
          url += '&disablekb=1'
        }
      }

      return url
    }
    return null
  }

  return item?.provider !== 'local' ? (
    <div
      className={`${styles.root} ${styles['root--iframe']} ${
        item.ratio && preserveRatio ? styles[`root--${item.ratio.replace(':', '-')}`] : ''
      } ${className}`}
    >
      <iframe
        src={getIframeSrc()}
        allowFullScreen
        allow={editMode ? '' : 'autoplay'}
        style={{
          ...(styles || {}),
          pointerEvents: editMode ? 'none' : undefined,
        }}
        className={styles.iframe}
        aria-hidden={item.ariaHidden || undefined}
      />
    </div>
  ) : item?.url ? (
    <video
      controls={item.controls}
      autoPlay={item.autoplay}
      playsInline
      muted={item.autoplay ? true : item.muted}
      loop={item.loop}
      className={`${styles.embed} ${className}`}
      aria-hidden={item.ariaHidden || undefined}
      style={style}
    >
      <source src={item.url} type="video/mp4" />
    </video>
  ) : editMode ? (
    <div className={`${styles.root} ${styles['root--editMode']} ${styles['root--empty']}`}></div>
  ) : (
    <></>
  )
}

export default EditableVideo
