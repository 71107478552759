import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PlusPointNode } from '@gql/graphql'
import { PDPInfoCard } from '@components/molecules'
import styles from './PDPInfoSlider.module.sass'
import { A11y, Navigation, Pagination } from 'swiper/modules'

interface Props {
  plusPoints?: PlusPointNode[]
  className?: string
}

const PDPInfoSlider = (props: Props) => {
  const { plusPoints = [], className = '' } = props

  return plusPoints.length > 0 ? (
    <div className={`${styles.root} ${className}`}>
      <Swiper
        spaceBetween={16}
        slidesPerView={'auto'}
        modules={[A11y, Navigation, Pagination]}
        navigation
        pagination={{
          type: 'progressbar',
        }}
        loop={plusPoints.length > 3 ? true : false}
        watchOverflow
        className={'swiper--bottomNav'}
      >
        {plusPoints.map((x) => (
          <SwiperSlide key={x.pk}>
            <PDPInfoCard plusPoint={x} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}

export default PDPInfoSlider
