import React from 'react'
import Modal from '../Modal/Modal'
import styles from './WishlistModal.module.sass'
import useTrans from '@hooks/useTrans'
import Button from '../Button/Button'
import { useRouter } from 'next/router'

interface Props {
  id: string
  open?: boolean
  onClose?: () => void
  className?: string
}

const WishlistModal = (props: Props) => {
  const { id = undefined, open = false, onClose = undefined, className = '' } = props

  const t = useTrans()
  const router = useRouter()

  return (
    <Modal
      id={id}
      open={open}
      onClose={onClose}
      title={t('Ti piace questo articolo?')}
      description={t('Accedi o registrati per salvarlo nella tua lista dei preferiti!')}
      cta={<Button label={t('Accedi o registrati')} href={`/${router.locale}/login/`} />}
      className={`${styles.root} ${className}`}
    />
  )
}

export default WishlistModal
