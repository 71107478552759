import React from 'react'
import styles from './RecuperaPasswordLayout.module.sass'
import { WContainer } from '@components/atoms'

interface Props {
  pagina?: any
  size?: 'md' | 'lg'
  children?: React.ReactNode
  className?: string
}

const RecuperaPasswordLayout = (props: Props) => {
  const { pagina = undefined, size = 'md', children = undefined, className = '' } = props

  return (
    <main className={`${styles.root} ${styles[`root--${size}`]} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.content}>
            {!!pagina.icon && <span className={styles.icon}>{pagina.icon}</span>}
            {!!pagina?.titolo && <h1 className={styles.title}>{pagina.titolo}</h1>}
            {!!pagina?.descrizione && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: pagina.descrizione }}
              />
            )}
            {children}
          </div>
        </div>
      </WContainer>
    </main>
  )
}

export default RecuperaPasswordLayout
