import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { MenuItemType } from '@utils/types'
import { WContainer } from '@components/atoms'
import { PowerBarItem } from '@components/molecules'
import styles from './PowerBar.module.sass'

interface Props {
  items: (MenuItemType & { selected?: boolean })[]
  onItemClick?: (item: MenuItemType & { selected?: boolean }) => void
  theme?: 'light' | 'neutrallight'
  layout?: '' | 'boxed'
  className?: string
}

const PowerBar = (props: Props) => {
  const {
    items = [],
    theme = 'light',
    layout = '',
    onItemClick = undefined,
    className = '',
  } = props

  return items.length > 0 ? (
    <div
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        layout ? styles[`root--${layout}`] : ''
      } ${className}`}
    >
      <WContainer>
        <Swiper
          key={items.findIndex((x) => x.selected) || undefined}
          slidesPerView={'auto'}
          spaceBetween={40}
          modules={[A11y, Navigation]}
          navigation
          className="swiper--arrowNav"
          initialSlide={items.findIndex((x) => x.selected) || undefined}
        >
          {items.map((x) => (
            <SwiperSlide key={x.pk}>
              <PowerBarItem
                selected={x.selected}
                item={x}
                onClick={onItemClick ? () => onItemClick(x) : undefined}
                className={styles.item}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default PowerBar
