import React from 'react'

interface Props {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span'
  title?: string
  editMode?: boolean
  className?: string
}

const EditableTitle = (props: Props) => {
  const { variant = 'h2', title = undefined, editMode = false, className = '' } = props

  return !!title || editMode ? (
    editMode ? (
      <div className={className}>{title}</div>
    ) : variant === 'h3' ? (
      <h3 className={className}>{title}</h3>
    ) : variant === 'h4' ? (
      <h4 className={className}>{title}</h4>
    ) : variant === 'p' ? (
      <p className={className}>{title}</p>
    ) : variant === 'span' ? (
      <span className={className}>{title}</span>
    ) : variant === 'h1' ? (
      <h1 className={className}>{title}</h1>
    ) : (
      <h2 className={className}>{title}</h2>
    )
  ) : (
    <></>
  )
}

export default EditableTitle
