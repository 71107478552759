import React from 'react'
import styles from './ButtonSocial.module.sass'
import { FacebookIllustration, GoogleIllustration } from '@components/icons'

interface Props {
  label?: string
  variant?: 'facebook' | 'google'
  className?: string
  onClick?: () => void
}

const ButtonSocial = (props: Props) => {
  const { label = undefined, variant = 'facebook', className = '', onClick = null } = props
  return (
    <button type="button" className={`${styles.root} ${className}`} onClick={onClick}>
      <span className={styles.icon}>
        {variant === 'google' ? <GoogleIllustration /> : <FacebookIllustration />}
      </span>
      {!!label && <span className={styles.label}>{label}</span>}
    </button>
  )
}

export default ButtonSocial
