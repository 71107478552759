export const ColorToolbarItem = ({
  propKey,
  propSubKey,
  value,
  setProp,
  onChange,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
}) => {
  return (
    <>
      {/* {!onlyDynamic &&
        <ColorPicker 
                value={value}
                onChange={(color) => setProp((props) => {
                    const value = color.rgb // {r: x, g: x, b: x, a: x}
                    props[propKey][propSubKey] = onChange ? onChange(value) : value
                }, 1000)} 
        />
        } */}
      {!onlyDynamic && (
        <input
          type="color"
          value={value === 'transparent' || value === 'inherit' ? '#000000' : value}
          onChange={(e) =>
            setProp((props) => {
              const value = e.target.value
              props[propKey][propSubKey] = onChange ? onChange(value) : value
            }, 1000)
          }
        />
      )}
    </>
  )
}
