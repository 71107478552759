/* eslint-disable react/no-array-index-key */
import React from 'react'
import { BreadcrumbType } from '@gql/graphql'
import useTrans from '@hooks/useTrans'
import { WLink, JsonLd } from '@components/atoms'
import styles from './Breadcrumbs.module.sass'

interface ItemProps {
  label: string
  url?: string
  first?: boolean
  last?: boolean
}
interface Props {
  breadcrumbs: BreadcrumbType[]
  theme?: 'light' | 'dark'
  mobileTheme?: 'light' | 'dark'
  className?: string
  showLast?: boolean
}

const BreadcrumbsItem = (props: ItemProps) => {
  const { label = '', url = '', first = false, last = false } = props

  return (
    <div className={`${styles.item}`}>
      {last || !url ? (
        <span className={styles.itemLabel}>{label}</span>
      ) : (
        <WLink className={styles.itemLink} href={url}>
          {label}
        </WLink>
      )}
    </div>
  )
}

const Breadcrumbs = (props: Props) => {
  const {
    breadcrumbs = [],
    theme = 'light',
    mobileTheme = undefined,
    className = '',
    showLast = false,
  } = props

  const t = useTrans()

  const jsonLdItems = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.label ? item.label.replace(/"/g, '') : '',
    item: item.url,
  }))

  return (
    <section
      className={`${styles.root} ${styles[`root--${theme}`]} ${
        mobileTheme && mobileTheme !== theme ? styles[`root--mobile-${mobileTheme}`] : ''
      } ${className}`}
    >
      {breadcrumbs.map((item, index) => (
        <BreadcrumbsItem
          label={item.label}
          url={item.url}
          first={index === 0}
          last={showLast ? false : index === breadcrumbs.length - 1}
          key={index}
        />
      ))}
      <JsonLd item={jsonLdItems} type="breadcrumbs" />
    </section>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
