export const conditions = {
  Visibilità: {
    visibleFrom: {
      description: 'Dal',
      type: 'datetime-local',
    },
    visibleTo: {
      description: 'Al',
      type: 'datetime-local',
    },
  },
}

export const defaultConditionsProps = {
  visibleFrom: '',
  visibleTo: '',
}

export function anyConditionSatisfied(storage, conditionsProps) {
  if (!conditionsProps) return false

  return true

  // check su condizioni di visibilità temporale spostate lato django in PagebuilderPlaceholder.remove_nodes_by_conditions() per evitare lo sfarfallio (es. in slide hero)
  //  ||
  // (conditionsProps.visibleFrom && new Date(conditionsProps.visibleFrom) > new Date()) ||
  // (conditionsProps.visibleTo && new Date(conditionsProps.visibleTo) < new Date())
}
