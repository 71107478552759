import React, { useEffect, useState } from 'react'
import { getYearOptions } from '@utils/safe'
import { Props as FormSelectProps } from 'components/atoms/FormSelect/FormSelect'
import { FormError, FormField, FormSelect } from '@components/atoms'
import styles from './FormDate.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  id: string
  placeholder?: string
  name?: string
  value: string
  disabled?: boolean
  showSublabel?: boolean
  status?: '' | 'success' | 'error'
  onChange: (e: any) => void
  dayProps?: FormSelectProps
  monthProps?: FormSelectProps
  yearProps?: FormSelectProps
  yearFrom?: number
  yearNumber?: number
  errorMessage?: string
  className?: string
}

const FormDate = (props: Props) => {
  const {
    id = '',
    placeholder = 'GG/MM/YYYY',
    name = '',
    value = null,
    disabled = false,
    showSublabel = true,
    status = '',
    onChange = () => {},
    dayProps = {
      options: [...Array.from({ length: 31 }).keys()].map((i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString().padStart(2, '0'),
      })),
    },
    monthProps = {
      options: [...Array.from({ length: 12 }).keys()].map((i) => ({
        label: (i + 1).toString(),
        value: (i + 1).toString().padStart(2, '0'),
      })),
    },
    yearProps = null,
    yearFrom = 1900,
    yearNumber = 122,
    errorMessage = undefined,
    className = '',
  } = props

  const t = useTrans()
  const [day, setDay] = useState<string>(value?.split('-')?.[2])
  const [month, setMonth] = useState<string>(value?.split('-')?.[1])
  const [year, setYear] = useState<string>(value?.split('-')?.[0])

  useEffect(() => {
    onChange({
      target: {
        name: name,
        value: year && month && day ? `${year}-${month}-${day}` : undefined,
      },
    })
  }, [day, month, year])

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.list}>
        <FormField id={`${id}_day`} label={showSublabel ? t('Giorno') : undefined}>
          <FormSelect
            id={`${id}_day`}
            placeholder={placeholder.split('/')[0]}
            name={name ? `${name}_day` : 'date_day'}
            value={day}
            disabled={disabled}
            status={status}
            onChange={(e) => setDay(e.target.value)}
            isSearchable
            {...dayProps}
          />
        </FormField>
        <FormField id={`${id}_day`} label={showSublabel ? t('Mese') : undefined}>
          <FormSelect
            id={`${id}_month`}
            placeholder={placeholder.split('/')[1]}
            name={name ? `${name}_month` : 'date_month'}
            value={month}
            disabled={disabled}
            status={status}
            onChange={(e) => setMonth(e.target.value)}
            isSearchable
            {...monthProps}
          />
        </FormField>
        <FormField id={`${id}_day`} label={showSublabel ? t('Anno') : undefined}>
          <FormSelect
            id={`${id}_year`}
            placeholder={placeholder.split('/')[2]}
            name={name ? `${name}_year` : 'date_year'}
            value={year}
            disabled={disabled}
            status={status}
            onChange={(e) => setYear(e.target.value)}
            isSearchable
            {...{
              options: getYearOptions(yearFrom, yearNumber),
              ...yearProps,
            }}
          />
        </FormField>
      </div>
      <FormError
        id={`${id}_error`}
        className={styles.error}
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormDate
