import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ImpattoAmbientale({ idVariant = '', className }: Props) {
  return (
    <IconBase name="impatto-ambientale" w={50} className={className}>
      <path
        d="M29.8095 43.262C24.4708 44.6731 18.7794 43.6618 14.2403 40.5103H27.928C28.3043 40.5103 28.5983 40.2046 28.5983 39.8401C28.5983 39.4755 28.2926 39.1698 27.928 39.1698H12.5235C9.33677 36.3594 7.18484 32.5611 6.43225 28.3749H14.2168C15.5221 28.3749 16.5687 27.3283 16.5804 26.023C16.5804 25.4704 17.039 25.0118 17.5917 25.0118H22.6482C24.318 25.0118 25.6703 23.6712 25.682 22.0014C25.682 21.0607 26.4346 20.3081 27.3636 20.2846H30.7385C31.1148 20.2846 31.4087 19.9788 31.4087 19.6143C31.4087 19.2498 31.103 18.944 30.7385 18.944H27.3636C25.6938 18.9558 24.3415 20.3199 24.3297 21.9897C24.3297 22.9186 23.5654 23.6595 22.6482 23.6595H17.5917C16.2864 23.6595 15.2281 24.7178 15.2281 26.023C15.2281 26.5757 14.7813 27.0343 14.2286 27.0343H6.24411C6.17355 26.3641 6.13827 25.682 6.13827 25.0118C6.15003 14.8518 14.1933 6.51458 24.3415 6.15005V7.81985C24.3297 8.76058 23.5654 9.51317 22.6246 9.50141C20.9431 9.50141 19.5908 10.8772 19.6025 12.547C19.6025 14.2286 20.9783 15.5809 22.6482 15.5691H30.7385C31.1148 15.5691 31.4087 15.2634 31.4087 14.8989C31.4087 14.5343 31.103 14.2286 30.7385 14.2286H22.6482C21.7192 14.2286 20.9548 13.476 20.9548 12.547C20.9548 11.6181 21.7074 10.8537 22.6364 10.8537C24.3062 10.8537 25.6703 9.48965 25.682 7.81985V6.15005C27.54 6.20884 29.3744 6.54986 31.1265 7.14958C31.4793 7.27893 31.8673 7.10254 31.9967 6.74976C32.126 6.39699 31.9497 6.00894 31.5969 5.87959H31.5734C20.9783 2.246 9.47788 7.8904 5.85605 18.4619C5.13874 20.5668 4.77421 22.7775 4.76245 25C4.76245 25.929 4.82125 26.8697 4.9506 27.7869C6.49105 38.8523 16.7215 46.5781 27.7869 45.0376C28.5865 44.9318 29.3744 44.7672 30.1387 44.5673C30.5033 44.4732 30.7149 44.0969 30.6209 43.7441C30.5268 43.3796 30.1505 43.1679 29.7977 43.262H29.8095Z"
        fill="currentColor"
      />
      <path
        d="M25.6702 31.0677H30.7267C31.103 31.0677 31.3969 30.762 31.3969 30.3975C31.3969 30.0329 31.0912 29.7272 30.7267 29.7272H25.6702C23.9887 29.7272 22.6364 31.0913 22.6364 32.7611C22.6364 34.4309 24.0004 35.7949 25.6702 35.7949H28.0926C28.4689 35.7949 28.7629 35.4892 28.7629 35.1246C28.7629 34.7601 28.4571 34.4544 28.0926 34.4544H25.6702C24.7412 34.4544 23.9887 33.7018 23.9887 32.7728C23.9887 31.8438 24.7412 31.0913 25.6702 31.0913V31.0677Z"
        fill="currentColor"
      />
      <path
        d="M39.158 33.5489V9.14864C39.158 8.06679 38.3114 7.1731 37.2295 7.12606C36.6886 7.10254 36.1595 7.30245 35.7714 7.67874C35.3481 8.07855 35.1129 8.64299 35.1129 9.21919V33.5372C32.7611 34.6543 31.7498 37.4647 32.8669 39.8283C33.6195 41.4158 35.1834 42.4506 36.9356 42.5212C37.0061 42.5212 37.0649 42.5212 37.1355 42.5212C39.746 42.5212 41.8509 40.4045 41.8509 37.794C41.8509 35.9713 40.8043 34.3133 39.158 33.5254V33.5489ZM36.7004 8.66651C36.818 8.54892 36.9708 8.49012 37.1355 8.47836H37.159C37.5235 8.49012 37.8057 8.79586 37.8057 9.14864V14.2051H36.4534V9.21919C36.4534 9.00753 36.5475 8.79586 36.7004 8.65475V8.66651ZM39.4638 40.2516C38.8053 40.8866 37.9116 41.2277 36.9826 41.1924C35.254 41.1101 33.8664 39.746 33.7606 38.0174C33.6665 36.524 34.5837 35.1482 36.0066 34.6543C36.277 34.5602 36.4534 34.3015 36.4534 34.0193V15.5809H37.8057V34.0193C37.8057 34.3015 37.9821 34.5602 38.2526 34.6543C40.0047 35.2775 40.9219 37.206 40.3104 38.9581C40.1341 39.452 39.8518 39.8989 39.4638 40.2634V40.2516Z"
        fill="currentColor"
      />
      <path
        d="M41.5216 31.8321V9.14864C41.5216 6.72625 39.5578 4.76246 37.1472 4.76246C37.0414 4.76246 36.9355 4.76246 36.8415 4.76246C34.5132 4.96237 32.7375 6.91439 32.761 9.24271V31.8086C30.8678 33.1961 29.7389 35.3951 29.7272 37.7352C29.7272 41.6863 32.8081 44.9553 36.7474 45.2023C36.8767 45.2023 37.0179 45.2023 37.1472 45.2023C41.2394 45.2023 44.5672 41.8744 44.5555 37.7822C44.5555 35.4186 43.4266 33.1961 41.5216 31.7968V31.8321ZM41.3217 42.2154C40.1105 43.3678 38.4878 43.9675 36.818 43.8735C33.5959 43.6736 31.0795 41.0042 31.0795 37.7705C31.0912 35.7479 32.1143 33.8547 33.8076 32.7493C33.9958 32.6199 34.1133 32.4083 34.1133 32.1849V9.26623C34.0898 7.64346 35.3128 6.2794 36.9355 6.12653C37.0061 6.12653 37.0767 6.12653 37.1472 6.12653C38.817 6.12653 40.1811 7.47883 40.1928 9.14864V32.1731C40.1928 32.3965 40.3104 32.6082 40.4986 32.7375C43.2973 34.5837 44.0734 38.3467 42.2272 41.1453C41.9685 41.5334 41.6745 41.8862 41.3335 42.2037L41.3217 42.2154Z"
        fill="currentColor"
      />
      <path
        d="M43.5442 10.842H44.5555C44.9318 10.842 45.2257 10.5362 45.2257 10.1717C45.2257 9.80715 44.92 9.50141 44.5555 9.50141H43.5442C43.1679 9.50141 42.8739 9.80715 42.8739 10.1717C42.8739 10.5362 43.1796 10.842 43.5442 10.842Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 12.8645H43.5442C43.1679 12.8645 42.8739 13.1703 42.8739 13.5348C42.8739 13.8993 43.1796 14.2051 43.5442 14.2051H44.5555C44.9318 14.2051 45.2257 13.8993 45.2257 13.5348C45.2257 13.1703 44.92 12.8645 44.5555 12.8645Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 16.2394H43.5442C43.1679 16.2394 42.8739 16.5452 42.8739 16.9097C42.8739 17.2742 43.1796 17.58 43.5442 17.58H44.5555C44.9318 17.58 45.2257 17.2742 45.2257 16.9097C45.2257 16.5452 44.92 16.2394 44.5555 16.2394Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 19.6025H43.5442C43.1679 19.6025 42.8739 19.9083 42.8739 20.2728C42.8739 20.6373 43.1796 20.9431 43.5442 20.9431H44.5555C44.9318 20.9431 45.2257 20.6373 45.2257 20.2728C45.2257 19.9083 44.92 19.6025 44.5555 19.6025Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 22.9774H43.5442C43.1679 22.9774 42.8739 23.2832 42.8739 23.6477C42.8739 24.0122 43.1796 24.318 43.5442 24.318H44.5555C44.9318 24.318 45.2257 24.0122 45.2257 23.6477C45.2257 23.2832 44.92 22.9774 44.5555 22.9774Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 26.3523H43.5442C43.1679 26.3523 42.8739 26.658 42.8739 27.0226C42.8739 27.3871 43.1796 27.6928 43.5442 27.6928H44.5555C44.9318 27.6928 45.2257 27.3871 45.2257 27.0226C45.2257 26.658 44.92 26.3523 44.5555 26.3523Z"
        fill="currentColor"
      />
      <path
        d="M44.5555 29.7272H43.5442C43.1679 29.7272 42.8739 30.0329 42.8739 30.3975C42.8739 30.762 43.1796 31.0677 43.5442 31.0677H44.5555C44.9318 31.0677 45.2257 30.762 45.2257 30.3975C45.2257 30.0329 44.92 29.7272 44.5555 29.7272Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ImpattoAmbientale
