import { useEditor as useCraftEditor } from '@craftjs/core'

const SSR_DEFAULT_STATE = {
  options: {
    enabled: false,
  },
}

/**
 * This hook is used so we can achieve SSR of the CraftJS nodes.
 * If we are in SSR mode, we stub out the Editor return type.
 */

export function useEditor(collect: any, options: any = {}) {
  if (options?.isSSR) {
    const collected = collect ? collect(SSR_DEFAULT_STATE) : SSR_DEFAULT_STATE
    return {
      ...collected,
    }
  } else {
    return useCraftEditor(collect)
  }
}
