import React from 'react'

interface Props {
  description?: string
  variant?: '' | 'blockquote'
  editMode?: boolean
  className?: string
}

const EditableDescription = (props: Props) => {
  const { description = undefined, variant = '', editMode = false, className = '' } = props

  return !!description || editMode ? (
    typeof description === 'string' ? (
      variant === 'blockquote' ? (
        <blockquote className={className} dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <div className={className} dangerouslySetInnerHTML={{ __html: description }} />
      )
    ) : (
      <div className={className}>{description}</div>
    )
  ) : (
    <></>
  )
}

export default EditableDescription
