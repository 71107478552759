import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Pagination } from 'swiper/modules'
import { CategoriaNode } from '@gql/graphql'
import { MenuItemType } from '@utils/types'
import { WContainer, Button } from '@components/atoms'
import { CategoriaLinkCard } from '@components/molecules'
import styles from './CategorieLinkBanner.module.sass'

interface Props {
  item: MenuItemType
  categorie?: CategoriaNode[]
  theme?: 'neutrallight' | 'secondarydark' | 'primary'
  className?: string
}

const CategorieLinkBanner = (props: Props) => {
  const { item = undefined, categorie = [], theme = 'neutrallight', className = '' } = props

  return !!categorie?.length ? (
    <div className={`${styles.root} ${styles[`root--${theme}`]} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          {(!!item.name || !!item.description) && (
            <div className={styles.main}>
              {!!item.name && <h2 className={styles.title}>{item.name}</h2>}
              {!!item.description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </div>
          )}
          <div className={`${styles.slider}`}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              modules={[A11y, Pagination]}
              pagination={{ type: 'progressbar', clickable: true }}
              centerInsufficientSlides
              breakpoints={{
                1200: {
                  slidesPerView: 5,
                },
              }}
              className={`swiper ${['primary'].includes(theme) ? 'swiper--dark' : ''}`}
            >
              {categorie.map((categoria) => (
                <SwiperSlide key={categoria.pk}>
                  <CategoriaLinkCard
                    categoria={{
                      ...categoria, // per le categorie dal terzo livello in poi mettiamo boxTitolo e previewImage del parent
                      boxTitolo:
                        categoria.depth > 2 ? categoria.parent?.boxTitolo : categoria.boxTitolo,
                      previewImage:
                        categoria.depth > 2
                          ? categoria.parent?.previewImage
                          : categoria.previewImage,
                    }}
                    className={styles.item}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {!!item.ctaLabel && !!item.url && (
            <div className={styles.ctaBox}>
              <Button
                label={item.ctaLabel}
                href={item.url}
                newPage={item.blank}
                variant="secondary"
                theme={['primary', 'secondarydark'].includes(theme) ? 'dark' : undefined}
                color={['primary', 'secondarydark'].includes(theme) ? 'white' : undefined}
              />
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default CategorieLinkBanner
