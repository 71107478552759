import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function RadioUnmarked({ idVariant = '', className }: Props) {
  return (
    <IconBase name="radio-unmarked" w={24} className={className}>
      <rect x="2.5" y="2.5" width="19" height="19" rx="9.5" stroke="currentColor" />
    </IconBase>
  )
}

export default RadioUnmarked
