import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { TextImageLarge as Original } from 'components/organisms'
import { TextImageLargeSettings, TextImageLargeFloatingSettings } from './TextImageLargeSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  imageDefaultProps,
  imageItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/ImageToolbarSection'
import { getTextEditor } from '../TextEditor'
import { getItemChildren } from 'react-pagebuilder/utils/node'
import { buttonItemParams } from '../Button'
import {
  layoutDefaultProps,
  layoutItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/LayoutToolbarSection'

export const defaultProps = {
  ...imageDefaultProps(),
  ...layoutDefaultProps(undefined, false),
  theme: 'light',
  imagePos: 'left',
}

export const textImageLargeParams = (enabled, id, blockProps, children) => ({
  item: {
    ...imageItemParams(blockProps),
    ...layoutItemParams(blockProps),
    pk: id,
    intro: getTextEditor(children?.[0], enabled),
    name: getTextEditor(children?.[1], enabled),
    description: getTextEditor(children?.[2], enabled),
    children: getItemChildren(enabled, children?.[4], buttonItemParams),
  },
  caption: getTextEditor(children?.[3], enabled),
  data: getTextEditor(children?.[5], enabled),
  theme: blockProps.theme,
  imagePos: blockProps.imagePos,
})

export const dynamicProps = []

export const TextImageLarge: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-text-image-large ${props.className}`}>
        <Original {...textImageLargeParams(enabled, id, blockProps, children)} editMode={enabled} />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

TextImageLarge.craft = withCraft({
  name: 'TextImageLarge',
  defaultProps: defaultProps,
  settings: TextImageLargeSettings,
  floatingSettings: TextImageLargeFloatingSettings,
})
