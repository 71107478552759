import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage } from '@components/atoms'
import styles from './BlogImageGalleryCard.module.sass'

interface Props {
  item?: MenuItemType
  numItems?: number
  editMode?: boolean
  className?: string
}

const BlogImageGalleryCard = (props: Props) => {
  const { item = undefined, numItems = 1, editMode = false, className = '' } = props

  const sizeMap = {
    1: { w: 864, h: 487 },
    2: { w: 420, h: 420 },
    3: { w: 272, h: 272 },
    4: { w: 198, h: 186 },
  }

  return item ? (
    <div className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${className}`}>
      <WImage
        src={item.image}
        maxWidth={sizeMap[numItems]?.w || 864}
        maxHeight={sizeMap[numItems]?.h || 487}
        alt={item.alt}
        title={item.alt}
        objectFit="cover"
      />
    </div>
  ) : (
    <></>
  )
}

export default BlogImageGalleryCard
