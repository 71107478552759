import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import useTrans from '@hooks/useTrans'
import { scrollTo } from '@utils/safe'
import { cittaComuniOptions } from '@utils/checkout'
import { contattaciValidationSchema } from '@utils/validationSchemas'
import {
  Button,
  FormError,
  FormikField,
  FormLayout,
  FormOptions,
  Modal,
  WContainer,
} from '@components/atoms'
import styles from './ContattaciForm.module.sass'
import { Check } from '@components/icons'
import { MotivazioniNazioniNode, useCreateContattoCustomMutation } from '@gql/graphql'
import { useRouter } from 'next/router'
import { handleContattaciTracking } from '@utils/tracking'

interface Props {
  className?: string
  motivazioniNazioni: MotivazioniNazioniNode
}

const ContattaciForm = (props: Props) => {
  const { className = '', motivazioniNazioni = undefined } = props

  const t = useTrans()
  const formBody = useRef(null)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [globalError, setGlobalError] = useState(undefined)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState<undefined | string>(undefined)

  const router = useRouter()

  const tipiContattoOptions = motivazioniNazioni?.motivazioniContattoCustom?.map((x) => {
    return {
      value: x.pk.toString(),
      label: x.nome,
      description: x.descrizione,
    }
  })

  const cittaOptions = cittaComuniOptions()

  const nazioneOptions = motivazioniNazioni?.nazioni?.map((x) => ({
    label: x.nome,
    value: x.pk.toString(),
  }))

  const raiseGlobalError = (message) => {
    setGlobalLoading(false)
    setGlobalError(message)
    scrollTo(formBody?.current, 20)
  }

  const [createContatto] = useCreateContattoCustomMutation({
    onCompleted: (res) => {
      if (!res.createContattoCustom?.status) {
        raiseGlobalError(res?.createContattoCustom?.message || t('Errore durante il salvataggio'))
        if (selectedReason) handleContattaciTracking('error', selectedReason)
      } else {
        setConfirmModalOpen(true)
        if (selectedReason) handleContattaciTracking('complete', selectedReason)
      }
    },
    onError: () => {
      raiseGlobalError(t('Errore durante il salvataggio'))
      if (selectedReason) handleContattaciTracking('error', selectedReason)
    },
  })

  const nazioneItaliaPk = 1
  const nazioneDefaultPk = {
    'default': '1',
    'it-it': '1',
    'int': '',
    'es-es': '202',
    'en-gb': '',
  }

  const initialValues = {
    motivo: '',
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    azienda: '',
    nazione: nazioneDefaultPk[router?.locale]?.toString() || '',
    comune: '',
    provincia: '',
    cap: '',
    messaggio: '',
    privacy: false,
    marketing: false,
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <WContainer variant="xs">
        <div className={styles.layout}>
          <div className={styles.main}>
            <h2 className={styles.title}>{t('Come possiamo aiutarti?')}</h2>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Seleziona una delle opzioni sottostanti: così possiamo fornirti un'assistenza più mirata e veloce."
                ),
              }}
            />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={contattaciValidationSchema(t)}
            onSubmit={(values) => {
              const selectedReason = tipiContattoOptions.find(
                (x) => x.value === values?.motivo
              ).label
              setSelectedReason(selectedReason)

              createContatto({
                variables: {
                  contattoInput: {
                    ...values,
                    motivo: parseInt(values?.motivo),
                    comune: values?.comune?.split('|')[0],
                    provincia: values?.comune?.split('|')[1],
                    nazione: parseInt(values.nazione),
                  },
                },
              })
            }}
          >
            {(formikProps) => (
              <Form className={styles.form}>
                <FormOptions
                  id={'contattaci_tipo_contatto'}
                  name="motivo"
                  value={formikProps.values.motivo}
                  options={tipiContattoOptions}
                  onChange={(e) => formikProps.handleChange(e)}
                />
                {!!formikProps.values.motivo && (
                  <div className={styles.formBody} ref={formBody}>
                    <FormError
                      id="contattaci_global_error"
                      message={globalError}
                      visible={!!globalError}
                      variant="global"
                      className={styles.globalError}
                    />
                    <FormLayout space="lg">
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_nome'}
                        name="nome"
                        type="text"
                        label={t('Nome')}
                        placeholder={t('Inserisci il tuo nome')}
                        required
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_cognome'}
                        name="cognome"
                        type="text"
                        label={t('Cognome')}
                        placeholder={t('Inserisci il tuo cognome')}
                        required
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_email'}
                        name="email"
                        type="email"
                        label={t('Email')}
                        placeholder={t('Inserisci la tua email')}
                        required
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_telefono'}
                        name="telefono"
                        type="text"
                        label={t('Numero di telefono')}
                        placeholder={t('Inserisci il tuo numero di telefono')}
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_azienda'}
                        name="azienda"
                        type="text"
                        label={t('Azienda')}
                        placeholder={t('Inserisci la tua azienda')}
                        layout="full"
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_nazione'}
                        name="nazione"
                        type="select"
                        options={nazioneOptions}
                        label={t('Nazione')}
                        placeholder={t('Inserisci la nazione')}
                        required
                        isSearchable
                      />
                      {formikProps.values.nazione === nazioneItaliaPk.toString() ? (
                        <>
                          <FormikField
                            formik={formikProps}
                            id={'contattaci_comune'}
                            name="comune"
                            type="select"
                            options={cittaOptions}
                            isSearchable={true}
                            label={t('Città')}
                            placeholder={t('Inserisci la città')}
                            required
                            onChange={(e) => {
                              formikProps?.handleChange(e)
                              formikProps.setFieldValue(
                                'provincia',
                                e.target.value?.split('|')?.length || 0 > 1
                                  ? e.target.value?.split('|')[1]
                                  : undefined
                              )
                            }}
                            async
                          />
                          <FormikField
                            formik={formikProps}
                            id={'contattaci_provincia'}
                            name="provincia"
                            type="text"
                            label={t('Provincia')}
                            placeholder={'-'}
                            disabled
                          />
                        </>
                      ) : (
                        <FormikField
                          formik={formikProps}
                          id={'contattaci_comune'}
                          name="comune"
                          type="text"
                          label={t('Città')}
                          placeholder={t('Inserisci la città')}
                          required
                        />
                      )}
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_cap'}
                        name="cap"
                        type="text"
                        label={t('Codice postale')}
                        placeholder={t('Inserisci il tuo codice postale')}
                        required
                      />
                      <FormikField
                        formik={formikProps}
                        id={'contattaci_messaggio'}
                        name="messaggio"
                        type="textarea"
                        label={t('Messaggio')}
                        placeholder={t('Inserisci il tuo messaggio')}
                        layout="full"
                      />
                    </FormLayout>
                    <div
                      className={styles.privacyMessage}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "Ti invitiamo a visionare <a href='/privacy/informativa-per-marketing-e-profilazione/'>l'informativa privacy art. 13 GDPR</a> sul trattamento dei dati"
                        ),
                      }}
                    />
                    <FormLayout>
                      <FormikField
                        formik={formikProps}
                        id={'checkout_privacy'}
                        name="privacy"
                        type="checkbox"
                        label={t('Letta e compresa l’informativa*')}
                        checkboxLabel={t(
                          'Esprimo il consenso al trattamento dei miei dati per ricevere supporto, risposte ai miei quesiti e per le eventuali attività connesse di cui al punto a) dell’informativa.'
                        )}
                        fieldVariant="box"
                        layout="full"
                      />
                      <FormikField
                        formik={formikProps}
                        id={'checkout_marketing'}
                        name="marketing"
                        type="checkbox"
                        label={t('Consenso per il ‘Marketing diretto’ (facoltativo)')}
                        checkboxLabel={t(
                          'Esprimo il consenso per le finalità di cui al punto b) dell’informativa e quindi acconsento al ricevimento di comunicazioni pubblicitarie tramite e-mail, telefono, sms e similari. Resta inteso che potrò revocare in qualunque momento il presente consenso.'
                        )}
                        fieldVariant="box"
                        layout="full"
                      />
                    </FormLayout>
                    <div className={styles.ctaBox}>
                      <Button
                        type="submit"
                        label={t('Invia messaggio')}
                        loading={globalLoading}
                        size="lg"
                      />
                    </div>
                    <div className={styles.notes}>
                      <p>{t('(ndr: La firma è sostituita dal click)')}</p>
                      <p>{t('Campi obbligatori (*)')}</p>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </WContainer>
      <Modal
        id="contattaciform_confirmmodal"
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        icon={<Check />}
        title={t('La tua richiesta è stata inviata!')}
        description={t('Il nostro team ti contatterà al più presto.')}
        cta={<Button label={t('Torna alla home')} href="/" size="lg" />}
      />
    </div>
  )
}

export default ContattaciForm
