import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Completed({ idVariant = '', className }: Props) {
  return (
    <IconBase name="completed" w={24} className={className}>
      <rect width="24" height="24" rx="12" fill="currentColor" />
      <path
        d="M10.3326 14.6425L17.9929 6.98218L19.1714 8.16069L10.3326 16.9995L5.0293 11.6962L6.20781 10.5177L10.3326 14.6425Z"
        fill="currentColor"
        className="c2"
      />
    </IconBase>
  )
}

export default Completed
