import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function CartError({ idVariant = '', className }: Props) {
  return (
    <IconBase name="cart-error" w={32} className={className}>
      <path
        d="M11.0049 11.9998V9.99975C11.0049 7.23833 13.2435 4.99976 16.0049 4.99976C18.7663 4.99976 21.0049 7.23833 21.0049 9.99975V11.9998H24.0049C24.5572 11.9998 25.0049 12.4475 25.0049 12.9998V24.9998C25.0049 25.552 24.5572 25.9998 24.0049 25.9998H8.00488C7.4526 25.9998 7.00488 25.552 7.00488 24.9998V12.9998C7.00488 12.4475 7.4526 11.9998 8.00488 11.9998H11.0049ZM11.0049 13.9998H9.00488V23.9998H23.0049V13.9998H21.0049V15.9998H19.0049V13.9998H13.0049V15.9998H11.0049V13.9998ZM13.0049 11.9998H19.0049V9.99975C19.0049 8.3429 17.6617 6.99975 16.0049 6.99975C14.348 6.99975 13.0049 8.3429 13.0049 9.99975V11.9998Z"
        fill="currentColor"
      />
      <circle cx="16" cy="16" r="15" stroke="currentColor" strokeWidth="2" className="c2" />
      <line
        x1="4.29289"
        y1="26.2929"
        x2="26.2929"
        y2="4.29289"
        stroke="currentColor"
        strokeWidth="2"
        className="c2"
      />
    </IconBase>
  )
}

export default CartError
