import React, { useState } from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
} from 'react-share'
import { Compare, Facebook, MailLine, Printer, Share, Whatsapp } from '@components/icons'
import { Button, Modal } from '@components/atoms'
import styles from './ShareButton.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  id: string
  item: {
    name: string
    description: string
    url: string
  }
  className?: string
}

const ShareButton = (props: Props) => {
  const { id = undefined, item = undefined, className = '' } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)

  return item ? (
    <>
      <Button
        label={t('Condividi')}
        iconRight={<Share />}
        variant="ghost"
        onClick={() => setOpen(true)}
        className={`${styles.root} ${className}`}
      />
      <Modal
        id={`share_button_modal_${id}`}
        open={open}
        title={t('Condividi')}
        onClose={() => setOpen(false)}
        className={styles.modal}
      >
        <div className={styles.modalCtaBox}>
          <FacebookShareButton url={item.url} className={styles.buttonWrap}>
            {/* TODO: aggiungere eventuale hashtag */}
            <Button label={t('Facebook')} iconLeft={<Facebook />} variant="secondary" size="lg" />
          </FacebookShareButton>
          <WhatsappShareButton url={item.url} className={styles.buttonWrap}>
            {/* TODO: aggiungere messaggio */}
            <Button label={t('Whatsapp')} iconLeft={<Whatsapp />} variant="secondary" size="lg" />
          </WhatsappShareButton>
          <Button
            label={t('Copia link')}
            iconLeft={<Compare />}
            variant="secondary"
            size="lg"
            onClick={() => navigator.clipboard.writeText(item.url)}
          />
          <EmailShareButton
            url={item.url}
            subject={item.name}
            body={item.description}
            className={styles.buttonWrap}
          >
            {/* TODO: personalizzare subject e body */}
            <Button
              label={t('Invia via mail')}
              iconLeft={<MailLine />}
              variant="secondary"
              size="lg"
            />
          </EmailShareButton>
          <Button
            label={t('Stampa')}
            iconLeft={<Printer />}
            variant="secondary"
            size="lg"
            onClick={() => window.print()}
          />
        </div>
      </Modal>
    </>
  ) : (
    <></>
  )
}

export default ShareButton
