import React from 'react'
import { useNode } from 'react-pagebuilder/hooks/useNode'
import { useEditor } from 'react-pagebuilder/hooks/useEditor'
import { TextBox as Original } from 'components/molecules'
import { TextBoxSettings, TextBoxFloatingSettings } from './TextBoxSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import {
  ctaDefaultProps,
  ctaItemParams,
} from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'
import { getTextEditor } from '../TextEditor'

export const defaultProps = {
  ...ctaDefaultProps('Call to action'),
}

export const textBoxItemParams = (enabled, id, blockProps, children) => ({
  ...ctaItemParams(blockProps, enabled),
  pk: id,
  name: getTextEditor(children?.[0], enabled),
  description: getTextEditor(children?.[1], enabled),
})

export const dynamicProps = []

export const TextBox: any = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }

    const { children } = props

    const _ = props.renderValue

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode(
      (state) => ({
        selected: state.events.selected,
        dragged: state.events.dragged,
        hovered: state.events.hovered,
        customs: state.data.custom,
      }),
      props
    )

    const { enabled } = useEditor(
      (state) => ({
        enabled: state.options.enabled,
      }),
      props
    )

    return (
      <div ref={(ref) => props.retRef(ref)} className={`block-text-box ${props.className}`}>
        <Original item={textBoxItemParams(enabled, id, blockProps, children)} editMode={enabled} />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

TextBox.craft = withCraft({
  name: 'TextBox',
  defaultProps: defaultProps,
  settings: TextBoxSettings,
  floatingSettings: TextBoxFloatingSettings,
})
