import React, { useCallback, useEffect, useRef, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { ArrowRight, Position, Search } from '@components/icons'
import { Button, ButtonIcon, CloseBar, SearchBar, WContainer } from '@components/atoms'
import styles from './StoreLocator.module.sass'
import { RivenditoreCard, RivenditoreModal } from '@components/molecules'
import Map, { GeolocateControl, NavigationControl, ScaleControl } from 'react-map-gl'
import { checkValidCoords } from '@utils/index'
import 'mapbox-gl/dist/mapbox-gl.css'
import { RivenditoreStoreLocatorNode } from '@gql/graphql-get'
import {
  clusterCountLayer,
  clusterLayer,
  getCoordinates,
  getCorrectIcon,
  loadIconsUtils,
  locationPointGetIconLayer,
  unclusteredPointGetIconLayer,
} from '@utils/mapbox'
import { useRouter } from 'next/router'
import { debounce } from 'lodash'
import { MenuItemType } from '@utils/types'
import { handleStoreLocatorSearchTracking, handleStoreLocatorSelectTracking } from '@utils/tracking'

interface Props {
  item?: MenuItemType
  className?: string
  rivenditori?: RivenditoreStoreLocatorNode[]
  rivenditoriFiltered?: RivenditoreStoreLocatorNode[]
}

const StoreLocator = (props: Props) => {
  const { item = undefined, className = '', rivenditori = [], rivenditoriFiltered = [] } = props

  const t = useTrans()
  const router = useRouter()

  const mapNavItems = [
    { key: 'map', label: t('Vedi mappa') },
    { key: 'list', label: t('Vedi lista') },
  ]
  const [mapNavItem, setMapNavItem] = useState('map')
  const [openResults, setOpenResults] = useState(false)

  const [selectedRivenditore, setSelectedRivenditore] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const rivenditoriLimitStep = 4
  const [rivenditoriLimit, setRivenditoriLimit] = useState(rivenditoriLimitStep) // nel mobile si visualizzano solo N elementi per volta
  const [rivenditoriJSON, setRivenditoriJSON] = useState<(GeolocationPosition & any) | null>(null)
  const [rivenditoriList, setRivenditoriList] = useState(rivenditoriFiltered)
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition | null>(null)
  const [resizeDone, setResizeDone] = useState(false)
  const [address, setAddress] = useState(null)
  const [locationPinJson, setLocationPinJson] = useState<(GeolocationPosition & any) | null>(null)
  const [suggestions, setSuggestions] = useState([])

  const useMapRef = () => {
    const [map, setMap] = useState(null)
    const ref = useCallback((node) => {
      if (node !== null) {
        setMap(node)
      }
    }, [])
    return [map, ref]
  }

  const [mapCurrent, mapRef] = useMapRef()
  const mapListBoxRef = useRef(null)

  const loadIcons = () =>
    new Promise<void>((resolve, reject) => {
      loadIconsUtils(mapCurrent, resolve, reject)
      resolve()
    })

  useEffect(() => {
    setRivenditoriJSON({
      type: 'FeatureCollection',
      features: rivenditori
        .filter((x) => checkValidCoords(x.latitudine, x.longitudine))
        .map((rivenditore) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              typeof rivenditore.longitudine !== 'number'
                ? rivenditore.longitudine.replace(',', '.')
                : rivenditore.longitudine,
              typeof rivenditore.latitudine !== 'number'
                ? rivenditore.latitudine.replace(',', '.')
                : rivenditore.latitudine,
            ],
          },
          properties: {
            icon:
              rivenditore.pk && rivenditore.pk === selectedRivenditore?.pk
                ? `${getCorrectIcon(rivenditore)}Selected`
                : `${getCorrectIcon(rivenditore)}`,
            rivenditoreJSON: JSON.stringify({ ...rivenditore }),
          },
        })),
    })
  }, [rivenditori, selectedRivenditore])

  useEffect(() => {
    if (mapListBoxRef.current && selectedRivenditore && openResults) {
      const cardId = `rivenditoreCard-storeLocator-${selectedRivenditore.pk}`
      const cardElement = document.getElementById(cardId)
      if (cardElement) {
        const container = mapListBoxRef.current
        const cardPosition = cardElement.offsetTop - container.offsetTop

        container.scrollTo({
          top: cardPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [selectedRivenditore, openResults, rivenditoriList])

  const loadSource = (type = 'rivenditori') => {
    if (type == 'rivenditori') {
      new Promise<void>((resolve, reject) => {
        if (!mapCurrent.getMap().getSource('rivenditori')) {
          mapCurrent.getMap().addSource('rivenditori', {
            type: 'geojson',
            data: rivenditoriJSON,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 60,
          })
        } else mapCurrent.getMap().getSource('rivenditori').setData(rivenditoriJSON)
        resolve()
      })
    }
    if (type == 'locationPin') {
      new Promise<void>((resolve, reject) => {
        if (!mapCurrent.getMap().getSource('locationPin')) {
          mapCurrent.getMap().addSource('locationPin', {
            type: 'geojson',
            data: locationPinJson,
          })
        } else mapCurrent.getMap().getSource('locationPin').setData(locationPinJson)
        resolve()
      })
    }
  }

  const loadMapResources = async () => {
    await loadIcons()

    await loadSource('locationPin')
    await loadSource('rivenditori')
    const map = mapCurrent.getMap()

    if (!map.getLayer(clusterLayer.id)) {
      map.addLayer(clusterLayer)
    }

    if (!map.getLayer(locationPointGetIconLayer.id)) {
      map.addLayer(locationPointGetIconLayer, clusterLayer.id)
    }

    if (!map.getLayer(clusterCountLayer.id)) {
      map.addLayer(clusterCountLayer)
    }

    if (!map.getLayer(unclusteredPointGetIconLayer.id)) {
      map.addLayer(unclusteredPointGetIconLayer)
    }
  }

  useEffect(() => {
    mapCurrent?.resize()
    mapCurrent?.once('moveend', () => {
      setResizeDone(true)
    })
  }, [openResults])

  useEffect(() => {
    const map = mapCurrent?.getMap()
    if (map) {
      map.on('load', () => {
        const locale = router.locale?.split('-')[0]
        map?.getStyle()?.layers.forEach(function (thisLayer) {
          if (thisLayer.id.indexOf('-label') > 0) {
            map?.setLayoutProperty(thisLayer.id, 'text-field', ['get', `name_${locale}`])
          }
        })
      })
    }
  }, [mapCurrent])

  useEffect(() => {
    if (mapCurrent && mapCurrent.getMap().getSource('rivenditori')) loadSource('rivenditori')
    if (mapCurrent && mapCurrent.getMap().getSource('locationPin')) loadSource('locationPin')
  }, [rivenditoriJSON, mapCurrent, locationPinJson])

  useEffect(() => {
    if (mapCurrent) {
      const rivenditoriFilteredValid = rivenditoriFiltered.filter((x) =>
        checkValidCoords(x.latitudine?.replace(',', '.'), x.longitudine?.replace(',', '.'))
      )

      if (rivenditoriFilteredValid.length > 0) {
        const minLng = Math.min(
          ...rivenditoriFilteredValid.map((x) => parseFloat(x.longitudine.replace(',', '.')))
        )
        const minLat = Math.min(
          ...rivenditoriFilteredValid.map((x) => parseFloat(x.latitudine.replace(',', '.')))
        )
        const maxLng = Math.max(
          ...rivenditoriFilteredValid.map((x) => parseFloat(x.longitudine.replace(',', '.')))
        )
        const maxLat = Math.max(
          ...rivenditoriFilteredValid.map((x) => parseFloat(x.latitudine.replace(',', '.')))
        )

        const bbox = [
          [minLng, minLat],
          [maxLng, maxLat],
        ]

        mapCurrent.fitBounds(bbox, {
          padding: 100,
          maxZoom: 15,
        })
      }
    }
  }, [rivenditoriFiltered, mapCurrent])

  const handleClick = (e) => {
    const features = e.features[0]
    if (features) {
      const clusterName = features.layer?.id
      const clusterId = features.properties?.cluster_id
      if (clusterName === 'clusters' || clusterName === 'cluster-count') {
        mapCurrent.getSource('rivenditori').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err || !zoom) return
        })
      } else if (clusterName === 'unclustered-point') {
        const selectedRivenditoreJson = features.properties?.rivenditoreJSON
          ? JSON.parse(features.properties.rivenditoreJSON)
          : null
        setSelectedRivenditore(selectedRivenditoreJson)
        setOpenResults(true)
        handleStoreLocatorSelectTracking(
          [
            Number(selectedRivenditoreJson.longitudine.replace(',', '.')),
            Number(selectedRivenditoreJson.latitudine.replace(',', '.')),
          ],
          selectedRivenditoreJson.ragioneSociale,
          router.asPath
        )
      }
    } else {
      setSelectedRivenditore(null)
    }
  }

  const handleRivenditoriList = () => {
    const map = mapCurrent?.getMap()
    const rivenditoriInView = rivenditori
      .filter((x) =>
        checkValidCoords(
          parseFloat(x.latitudine?.replace(',', '.')),
          parseFloat(x.longitudine?.replace(',', '.'))
        )
      )
      .filter((x) =>
        map
          .getBounds()
          .contains([
            parseFloat(x.longitudine?.replace(',', '.')),
            parseFloat(x.latitudine?.replace(',', '.')),
          ])
      )
    setRivenditoriList(rivenditoriInView)
  }

  const handleMapMove = () => {
    handleRivenditoriList()
    setSelectedRivenditore(null)
  }

  const handleMapZoom = () => {
    handleRivenditoriList()
  }

  const handleUseCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        handleStoreLocatorSearchTracking(
          [position.coords.longitude, position.coords.latitude],
          router.asPath
        )
        setCurrentLocation(position)
        setOpenResults(true)
      })
    }
  }

  const centerMap = (lat, lng, zoom = 9, duration = 2000, callback = undefined) => {
    if (checkValidCoords(lat, lng)) {
      mapCurrent?.easeTo({
        center: [lng, lat],
        zoom: zoom,
        duration: duration,
      })

      setLocationPinJson({
        type: 'FeatureCollection',
        features:
          lat && lng
            ? [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [lng, lat],
                  },
                  properties: {
                    icon: 'locationPin',
                  },
                },
              ]
            : [],
      })

      mapCurrent?.once('moveend', () => {
        if (typeof callback === 'function') {
          callback()
        }
      })
    }
  }

  useEffect(() => {
    if (currentLocation?.coords) {
      centerMap(currentLocation.coords.latitude, currentLocation.coords.longitude)
    }
  }, [currentLocation])

  const handleSearch = async (e) => {
    e.preventDefault()
    document.activeElement.blur()
    try {
      const coordinates = await getCoordinates(
        `${address} ${router.locale?.split('-')?.[0]?.toUpperCase()}`
      )
      if (coordinates) {
        handleStoreLocatorSearchTracking(coordinates, router.asPath)
        centerMap(coordinates[1], coordinates[0], undefined, undefined, () => setOpenResults(true))
      }
    } catch (error) {
      console.error('Failed to retrieve coordinates:', error)
    }
  }

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSuggestions([])
        return
      }
      const language = router.locale?.split('-')?.[0]?.toUpperCase()
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${
            process.env.MAPBOX_ACCESS_TOKEN
          }&autocomplete=true&language=${language}&country=${language == 'EN' ? 'GB' : language}`
        )
        const data = await response.json()
        setSuggestions(data.features || [])
      } catch (error) {
        console.error('Error fetching suggestions:', error)
      }
    }, 300),
    []
  )

  return (
    <div
      className={`${styles.root} ${openResults ? styles['root--openResults'] : ''} ${className}`}
    >
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.head}>
            <div className={styles.content}>
              {!!item.name && <h2 className={styles.title}>{item.name}</h2>}
              {!!item.description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              )}
            </div>
            <div className={styles.form} onSubmit={handleSearch}>
              <form className={styles.searchBar}>
                <SearchBar
                  placeholder={t('Inserisci il tuo indirizzo o la tua città')}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value)
                    fetchSuggestions(e.target.value)
                  }}
                />
                {suggestions.length > 0 && (
                  <div className={styles.suggestionsList}>
                    {suggestions.map((suggestion, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => {
                          setAddress(suggestion.place_name)
                          setSuggestions([])
                          centerMap(
                            suggestion.geometry.coordinates[1],
                            suggestion.geometry.coordinates[0],
                            undefined,
                            undefined,
                            () => setOpenResults(true)
                          )
                          handleStoreLocatorSearchTracking(
                            [
                              suggestion.geometry.coordinates[0],
                              suggestion.geometry.coordinates[1],
                            ],
                            router.asPath
                          )
                        }}
                        className={styles.option}
                      >
                        {suggestion.place_name}
                      </button>
                    ))}
                  </div>
                )}
              </form>
              <ButtonIcon
                icon={<Search />}
                size="lg"
                ariaLabel={t('Cerca')}
                color="primary"
                onClick={handleSearch}
              />
              <Button
                label={t('Usa la tua posizione')}
                variant="secondary"
                iconRight={<Position />}
                className={styles.searchButton}
                onClick={handleUseCurrentPosition}
              />
            </div>
          </div>
          <div className={styles.mapLayout}>
            <div className={`${styles.mapNav} ${!openResults ? styles['mapNav--hidden'] : ''}`}>
              {mapNavItems.map((x) => (
                <button
                  type="button"
                  disabled={x.key === mapNavItem}
                  onClick={() => setMapNavItem(x.key)}
                >
                  {x.label}
                </button>
              ))}
            </div>
            <div
              className={`${styles.mapListBox} ${styles.mapNavItem} ${
                mapNavItem !== 'list' ? styles['mapNavItem--hidden'] : ''
              }`}
              ref={mapListBoxRef}
            >
              {rivenditoriList?.length ? (
                <>
                  <div className={styles.mapList}>
                    {rivenditoriList.map((x, index) => (
                      <RivenditoreCard
                        rivenditore={x}
                        selected={
                          selectedRivenditore?.pk === x.pk &&
                          selectedRivenditore?.latitudine === x.latitudine &&
                          selectedRivenditore?.longitudine === x.longitudine
                        }
                        onClick={() => {
                          handleStoreLocatorSelectTracking(
                            [
                              Number(x.longitudine.replace(',', '.')),
                              Number(x.latitudine.replace(',', '.')),
                            ],
                            x.ragioneSociale,
                            router.asPath
                          )
                          setSelectedRivenditore(x)
                        }}
                        className={`${styles.mapItem} ${
                          index + 1 > rivenditoriLimit ? styles['mapItem--hidden'] : ''
                        }`}
                      />
                    ))}
                  </div>
                  {rivenditoriLimit < rivenditoriList.length && (
                    <div className={styles.mapListMoreBox}>
                      <Button
                        label={t('Carica altri {0} rivenditori', [
                          Math.min(
                            rivenditoriList.length - rivenditoriLimit,
                            rivenditoriLimitStep
                          ).toString(),
                        ])}
                        variant="secondary"
                        onClick={() => setRivenditoriLimit(rivenditoriLimit + rivenditoriLimitStep)}
                        className={styles.mapListMore}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.mapEmptyList}>{t('Nessun rivenditore in questa zona')}</div>
              )}
            </div>
            <div
              id="map"
              className={`${styles.map} ${styles.mapNavItem} ${
                mapNavItem !== 'map' ? styles['mapNavItem--hidden'] : ''
              }`}
            >
              <Map
                ref={mapRef}
                mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
                scrollZoom
                mapStyle="mapbox://styles/mapbox/streets-v11"
                interactiveLayerIds={['clusters', 'cluster-count', 'unclustered-point']}
                onLoad={() => loadMapResources()}
                onClick={(e) => handleClick(e)}
                onMoveEnd={() => (resizeDone ? handleMapMove() : () => {})}
                onZoomEnd={() => handleMapZoom()}
              >
                <GeolocateControl position="bottom-right" />
                <NavigationControl position="bottom-right" />
                <ScaleControl />
              </Map>
            </div>
          </div>
        </div>
      </WContainer>
      {selectedRivenditore && mapNavItem === 'map' && (
        <div
          className={styles.selectedRivenditoreLayer}
          tabIndex={0}
          onClick={() => setModalOpen(true)}
        >
          <CloseBar
            onClose={() => setModalOpen(true)}
            className={styles.selectedRivenditoreLayerCloseBar}
          />
          {!!selectedRivenditore.ragioneSociale && (
            <h3 className={styles.selectedRivenditoreLayerTitle}>
              {selectedRivenditore.ragioneSociale}
            </h3>
          )}
          <Button
            label={t('Vedi dettagli')}
            iconRight={<ArrowRight />}
            variant="secondary"
            className={styles.selectedRivenditoreLayerCta}
            href={selectedRivenditore.url}
          />
        </div>
      )}
      <RivenditoreModal
        rivenditore={selectedRivenditore}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  )
}

export default StoreLocator
