import React from 'react'
import styles from './Stripe.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import { StripeCard } from '@components/molecules'
import { CategoriaNode } from '@gql/graphql'
import { WContainer } from '@components/atoms'

interface Props {
  items?: CategoriaNode[]
  theme?: 'light' | 'neutrallight' | 'secondarydark' | 'black'
  className?: string
}

const Stripe = (props: Props) => {
  const { items = [], theme = 'light', className = '' } = props

  // caricate tutte le immagini con preload (e non solo la prima come al solito) per evitare shift di layout

  return items?.length ? (
    <div className={`${styles.root} ${styles[`root--${theme}`]} ${className}`}>
      <Swiper
        slidesPerView={'auto'}
        centerInsufficientSlides
        modules={[A11y, Navigation]}
        navigation
      >
        {items.map((x) => (
          <SwiperSlide key={x.pk}>
            <StripeCard item={x} loading="eager" priority />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <></>
  )
}

export default Stripe
