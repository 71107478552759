import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function Minus({ idVariant = '', className }: Props) {
  return (
    <IconBase name="minus" w={24} className={className}>
      <path d="M5 11V13H19V11H5Z" fill="currentColor" />
    </IconBase>
  )
}

export default Minus
