import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { clickInRef } from '@utils/safe'
import styles from './WModal.module.sass'

interface Props {
  id: string
  open: boolean
  ariaLabel?: string
  ariaLabelledby?: string
  onClose?: (v?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
  className?: string
  children?: ReactNode
}

// Modale generica - gestisce solo il comportamento accessibile di default - NO STILE
const WModal = (props: Props) => {
  const {
    id,
    open = false,
    ariaLabel = undefined,
    ariaLabelledby = undefined,
    onClose = undefined,
    className = '',
    children = null,
  } = props

  const ref = useRef(null)
  const [prevActiveElement, setPrevActiveElement] = useState(null)
  // const [touchStart, setTouchStart] = useState(null)

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        if (onClose) onClose()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  // useEffect(() => {
  //   const touchStartHandler = (e) => {
  //     setTouchStart(!!e.touches[0]?.clientX || !!e.touches[0]?.clientY ? [e.touches[0].clientX, e.touches[0].clientY] : null)
  //   }
  //   const touchMoveHandler = (e) => {
  //     if (!!touchStart && (e.touches[0]?.clientX || e.touches[0]?.clientY)) {
  //       if (e.touches[0]?.clientY > touchStart[1] + 50) {
  //         setTouchStart(null)
  //         onClose()
  //       }
  //     }
  //   }
  //   document.addEventListener('touchstart', touchStartHandler)
  //   document.addEventListener('touchmove', touchMoveHandler)
  //   return () => {
  //     document.removeEventListener('touchstart', touchStartHandler)
  //     document.removeEventListener('touchmove', touchMoveHandler)
  //   }
  // }, [touchStart])

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')

    if (open) {
      // setTouchStart(null)
      if (ref.current) {
        // Salvo l'elemento che aveva il focus prima
        setPrevActiveElement(document.activeElement)

        ref.current.showModal()
        // Listener per click fuori dai bounds (backdrop)
        const handleBackdropClick = (e) => {
          if (!clickInRef(e, ref.current)) {
            if (onClose) onClose()
          }
        }
        ref.current.addEventListener('click', handleBackdropClick)
        return () => ref.current?.removeEventListener('click', handleBackdropClick)
      }
    } else {
      ref.current?.close()
      // rimetto il focus sull'elemento che aveva il focus prima
      if (prevActiveElement) prevActiveElement.focus()
    }
  }, [open])

  return (
    <dialog
      className={`${styles.root} ${className}`}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      ref={ref}
      id={id}
    >
      {children}
    </dialog>
  )
}

export default WModal
