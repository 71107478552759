import React from 'react'
import { EditableDescription, EditableTitle, WContainer } from '@components/atoms'
import styles from './BannerBlock.module.sass'

interface Props {
  item?: any
  editMode?: boolean
  children?: React.ReactNode
  className?: string
}

const BannerBlock = (props: Props) => {
  const { item = undefined, editMode = false, children = undefined, className = '' } = props

  return editMode || !!item || !!children ? (
    <div
      className={`${styles.root} ${item?.spaceTop ? styles[`root--st-${item.spaceTop}`] : ''} ${
        item?.spaceBottom ? styles[`root--sb-${item.spaceBottom}`] : ''
      } 
      ${item?.borderTop ? styles[`root--bt`] : ''} 
      ${item?.borderBottom ? styles[`root--bb`] : ''} 
      ${editMode ? styles['root--editMode'] : ''} ${className}`}
    >
      <WContainer>
        <div className={`${styles.layout}`}>
          {(editMode || !!item?.name || !!item?.description) && (
            <div className={styles.main}>
              <EditableTitle title={item?.name} className={styles.title} editMode={editMode} />
              <EditableDescription
                description={item?.description}
                className={styles.description}
                editMode={editMode}
              />
            </div>
          )}
          {children}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default BannerBlock
