import React from 'react'
import styles from './PLPOptionCard.module.sass'
import { MenuItemType } from '@utils/types'
import { WLink } from '@components/atoms'

interface Props {
  item?: MenuItemType
  selected?: boolean
  className?: string
}

const PLPOptionCard = (props: Props) => {
  const { item = undefined, selected = false, className = '' } = props

  return selected || !item.url ? (
    <div className={`${styles.root} ${selected ? styles['root--selected'] : ''} ${className}`}>
      {item.name}
    </div>
  ) : (
    <WLink href={item.url} className={`${styles.root} ${className}`}>
      {item.name}
    </WLink>
  )
}

export default PLPOptionCard
