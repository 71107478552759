import React from 'react'
import { defaultProps } from './index'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import CtaToolbarSection from 'react-pagebuilder/editor/Viewport/Sidebar/Toolbar/CtaToolbarSection'

export const IconTextCardSettings = (settingsProps) => {
  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="mainIcon"
          type="icon"
          label="Icona"
          defaultValue={defaultProps['mainIcon']}
        />
      </ToolbarSection>
      {!settingsProps.floating && (
        <>
          <CtaToolbarSection defaultProps={defaultProps} />
        </>
      )}
    </>
  )
}

export const IconTextCardFloatingSettings = () =>
  React.createElement(IconTextCardSettings, { floating: true })
