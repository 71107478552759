import React, { useEffect, useState } from 'react'
import styles from './CheckoutFormIndirizzoBlock.module.sass'
import { Button, FormikField, FormLayout } from '@components/atoms'
import useUtente from '@hooks/useUtente'
import { Plus } from '@components/icons'
import CheckoutFormPartIndirizzo from '../CheckoutFormPartIndirizzo/CheckoutFormPartIndirizzo'
import useTrans from '@hooks/useTrans'
import { useCreateAddressMutation } from '@gql/graphql'

interface Props {
  title?: string
  variant?: 'spedizione' | 'fatturazione'
  formikProps?: any
  addAddressOpen?: boolean
  setAddAddressOpen?: (v: boolean) => void
  className?: string
}

const CheckoutFormIndirizzoBlock = (props: Props) => {
  const {
    title = undefined,
    variant = 'spedizione',
    formikProps = undefined,
    addAddressOpen = false,
    setAddAddressOpen = undefined,
    className = '',
  } = props

  const t = useTrans()
  const { utente, refetchUtente } = useUtente()
  const indirizzi =
    variant === 'fatturazione' ? utente?.indirizziFatturazione : utente?.indirizziSpedizione

  const [createAddress] = useCreateAddressMutation()

  const firstLetterUppercase = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <FormLayout>
        {!addAddressOpen && !!indirizzi?.length ? (
          <>
            <FormikField
              formik={formikProps}
              id={`checkout_indirizzo_${variant}_id`}
              name={
                variant === 'fatturazione' ? 'indirizzoFatturazioneId' : 'indirizzoSpedizioneId'
              }
              type="radio"
              options={indirizzi?.map((indirizzo) => ({
                value: `${indirizzo?.pk}`,
                label: `${indirizzo?.nome} ${indirizzo.cognome}`,
                description: `<p>${indirizzo?.indirizzo}, ${indirizzo.citta} ${indirizzo.cap}</p><p>${indirizzo.telefono}</p>`,
              }))}
              layout="full"
            />
          </>
        ) : (
          <>
            <CheckoutFormPartIndirizzo formikProps={formikProps} variant={variant} />
            {!!indirizzi?.length && (
              <Button
                label={t('Salva nuovo indirizzo')}
                size="lg"
                onClick={async () => {
                  if (!formikProps.isValid) return

                  const { data } = await createAddress({
                    variables: {
                      address: {
                        nome: formikProps.values[`nome${firstLetterUppercase(variant)}`],
                        cognome: formikProps.values[`cognome${firstLetterUppercase(variant)}`],
                        indirizzo: formikProps.values[`indirizzo${firstLetterUppercase(variant)}`],
                        note: formikProps.values[`info${firstLetterUppercase(variant)}`],
                        citta:
                          formikProps.values[`citta${firstLetterUppercase(variant)}`].split('|')[0],
                        provincia:
                          formikProps.values[`citta${firstLetterUppercase(variant)}`].split('|')[1],
                        stato: formikProps.values[`stato${firstLetterUppercase(variant)}`],
                        cap: formikProps.values[`cap${firstLetterUppercase(variant)}`],
                        telefono: formikProps.values[`telefono${firstLetterUppercase(variant)}`],
                        isFatturazione: variant === 'fatturazione',
                      },
                    },
                  })

                  const newAddressId = data?.createAddress?.pk
                  formikProps.setFieldValue(
                    variant === 'fatturazione'
                      ? 'indirizzoFatturazioneId'
                      : 'indirizzoSpedizioneId',
                    newAddressId.toString()
                  )
                  await refetchUtente()
                  setAddAddressOpen(false)
                }}
              />
            )}
          </>
        )}
        {!addAddressOpen && (
          <>
            {variant !== 'fatturazione' && (
              <FormikField
                formik={formikProps}
                id={'checkout_stesso_indirizzo'}
                name="stessoIndirizzo"
                type="checkbox"
                checkboxLabel={t('Usa questo indirizzo come indirizzo di fatturazione')}
                layout="full"
              />
            )}
            {!!indirizzi?.length && (
              <div className={styles.addBox}>
                <Button
                  label={t('Aggiungi nuovo indirizzo')}
                  variant="ghost"
                  size="sm"
                  iconLeft={<Plus />}
                  onClick={() => setAddAddressOpen(true)}
                />
              </div>
            )}
          </>
        )}
      </FormLayout>
    </div>
  )
}

export default CheckoutFormIndirizzoBlock
