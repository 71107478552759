import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  idVariant?: string
  className?: string
}

function ChevronRight({ idVariant = '', className }: Props) {
  return (
    <IconBase name="chevron-right" w={24} className={className}>
      <path
        d="M14.1717 12.0007L9.22192 7.05093L10.6361 5.63672L17.0001 12.0007L10.6361 18.3646L9.22192 16.9504L14.1717 12.0007Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default ChevronRight
