import React from 'react'
import styles from './PreHeader.module.sass'
import { WContainer } from '@components/atoms'

interface Props {
  content?: string
  contentMobile?: string
  className?: string
}

const PreHeader = (props: Props) => {
  const { content = undefined, contentMobile = undefined, className = '' } = props

  return content || contentMobile ? (
    <div
      className={`${styles.root} preheader ${!content ? styles['root--no-desktop'] : ''} ${
        !contentMobile ? styles['root--no-mobile'] : ''
      } ${className}`}
    >
      <WContainer>
        <div
          className={`${styles.content} ${styles['content--desktop']}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div
          className={`${styles.content} ${styles['content--mobile']}`}
          dangerouslySetInnerHTML={{ __html: contentMobile }}
        />
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default PreHeader
