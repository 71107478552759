import { ToolbarItem } from './ToolbarItem'
import { ToolbarSection } from './ToolbarSection'
import { videoProviders } from '@components/atoms/EditableVideo/EditableVideo'

export type PagebuilderVideoItemType = {
  video?: {
    provider?: string
    videoId?: string
    ratio?: '4:3' | '16:9' | '9:16'
    autoplay?: boolean
    loop?: boolean
    controls?: boolean
    related?: boolean
    muted?: boolean
    ariaHidden?: boolean
    url?: string
  }
}

export const videoDefaultProps = (hiddenProps: string[] = []) => ({
  ...(hiddenProps.includes('autoplay') ? {} : { autoplay: false }),
  ...(hiddenProps.includes('muted') ? {} : { muted: false }),
  ...(hiddenProps.includes('loop') ? {} : { loop: false }),
  ...(hiddenProps.includes('controls') ? {} : { controls: false }),
  ...(hiddenProps.includes('related') ? {} : { related: false }),
  videoSrc: '',
  provider: 'youtube',
  videoId: '',
  ratio: '16:9',
  ariaHidden: false,
})

export const videoItemParams: (props?: any) => PagebuilderVideoItemType = (props) => ({
  video: {
    url: props.videoSrc,
    provider: props.provider,
    videoId: props.videoId,
    ratio: props.ratio,
    autoplay: props.autoplay,
    loop: props.loop,
    controls: props.controls,
    related: props.related,
    muted: props.muted,
    ariaHidden: props.ariaHidden,
  },
})

const VideoToolbarSection = ({ defaultProps, props, initOpen = true }) => {
  const ratioOpts = [
    { label: 'Orizzontale 4:3', value: '4:3' },
    { label: 'Orizzontale 16:9', value: '16:9' },
    { label: 'Verticale 9:16', value: '9:16' },
  ]

  return (
    <ToolbarSection title="Video" initOpen={initOpen}>
      <ToolbarItem
        propKey="block"
        propSubKey="provider"
        type="select"
        label="Provider"
        values={Object.keys(videoProviders)}
        defaultValue={defaultProps['provider']}
      />
      {props.block.provider === 'local' ? (
        <>
          <ToolbarItem
            propKey="block"
            propSubKey="src"
            type="text"
            label="Desktop"
            description="Paste your url or upload a file"
            defaultValue={defaultProps['videoSrc']}
          />
          <ToolbarItem propKey="block" propSubKey="videoSrc" type="video" />
        </>
      ) : (
        <>
          <ToolbarItem
            propKey="block"
            propSubKey="videoId"
            type="text"
            label="Video ID"
            defaultValue={defaultProps['videoId']}
          />
          {'ratio' in defaultProps && (
            <ToolbarItem
              propKey="block"
              propSubKey="ratio"
              type="select"
              options={ratioOpts}
              label="Dimensioni"
              defaultValue={defaultProps['ratio']}
            />
          )}
        </>
      )}
      {'autoplay' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="autoplay"
          type="switch"
          label="Autoplay"
          defaultValue={defaultProps['autoplay']}
        />
      )}
      {'muted' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="muted"
          type="switch"
          label="Muted"
          defaultValue={defaultProps['muted']}
        />
      )}
      {'loop' in defaultProps && (
        <ToolbarItem
          propKey="block"
          propSubKey="loop"
          type="switch"
          label="Loop"
          defaultValue={defaultProps['loop']}
        />
      )}
      {'controls' in defaultProps && props.block.provider !== 'vimeo' && (
        <ToolbarItem
          propKey="block"
          propSubKey="controls"
          type="switch"
          label="Controls"
          defaultValue={defaultProps['controls']}
        />
      )}
      {'related' in defaultProps &&
        ['youtube', 'youtube_nocookie'].includes(props.block.provider) && (
          <ToolbarItem
            propKey="block"
            propSubKey="related"
            type="switch"
            label="Related"
            defaultValue={defaultProps['related']}
          />
        )}
      <ToolbarItem
        propKey="block"
        propSubKey="ariaHidden"
        type="switch"
        label="Escludi da accessibilità"
        defaultValue={defaultProps['ariaHidden']}
      />
    </ToolbarSection>
  )
}

export default VideoToolbarSection
