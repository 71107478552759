import React, { useContext, useState } from 'react'
import useFindMenu from '@hooks/useFindMenu'
import useTrans from '@hooks/useTrans'
import useUtente from '@hooks/useUtente'
import {
  Button,
  HeaderMenuItem,
  LangDropdown,
  Logo,
  SearchTrigger,
  WContainer,
  WLink,
} from '@components/atoms'
import { CartDrawer, Nav, SearchModal } from '@components/organisms'
import styles from './Header.module.sass'
import { CartContext } from '@utils/cartContext'
import { useRouter } from 'next/router'
import { isEcommerce } from '@utils/index'
import { handleHeaderMenuTracking, handleHeaderTracking } from '@utils/tracking'

interface Props {
  className?: string
}

const Header = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const router = useRouter()
  const { utente } = useUtente()

  const ecommerce = isEcommerce(router.locale)

  const utilsMenu = useFindMenu('utils')
  const topMenu = useFindMenu('top')
  const mainMenu = useFindMenu('main')
  const attivitaMenu = useFindMenu('attivita')

  const [openMenuItem, setOpenMenuItem] = useState(undefined)
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const { cart } = useContext(CartContext)

  const quantity =
    cart?.items && cart?.items.length > 0
      ? cart.items.reduce((sum, item) => sum + item.quantita, 0)
      : 0

  return (
    <>
      <header
        className={`${styles.root} ${searchOpen ? styles['root--searchOpen'] : ''} ${className}`}
      >
        <div className={styles.top}>
          <WContainer>
            <div className={styles.topLayout}>
              <div className={styles.utils}>
                {['it-it', 'int'].includes(router.locale) && <LangDropdown />}
                {!!utilsMenu?.children?.length && (
                  <ul className={styles.utilsMenu}>
                    {utilsMenu.children.map((x) => (
                      <li key={x.pk}>
                        <HeaderMenuItem
                          item={x}
                          onClick={() => handleHeaderTracking(x.name)}
                          size="sm"
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {!!topMenu?.children?.length && (
                <ul className={styles.topMenu}>
                  {topMenu.children.map((x) => (
                    <li key={x.pk}>
                      <HeaderMenuItem
                        item={x}
                        onClick={() => handleHeaderTracking(x.name)}
                        size="sm"
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </WContainer>
        </div>
        <div className={styles.main}>
          <WContainer>
            <div className={styles.mainLayout}>
              <div className={styles.mainList}>
                <HeaderMenuItem
                  item={{ icon: 'menu', name: t('Menu') }}
                  variant="icon"
                  onClick={() => {
                    handleHeaderTracking('menu')
                    setMenuMobileOpen(!menuMobileOpen)
                  }}
                  className={styles.menuTriggerMobile}
                />
                {router.locale === 'it-it' && (
                  <HeaderMenuItem
                    item={{ icon: 'profile', name: t('Profilo'), url: '/myaccount/' }}
                    variant="icon"
                    onClick={() => handleHeaderTracking('account')}
                    className={styles.profileTriggerMobile}
                    notification={utente?.progressoProfilo < 100}
                  />
                )}
                {!!mainMenu?.children?.length && (
                  <nav className={styles.nav}>
                    <ul>
                      {mainMenu.children.map((x) => (
                        <li key={x.pk}>
                          <ul>
                            {x.children?.map((y) => (
                              <li key={y.pk}>
                                <HeaderMenuItem
                                  item={y}
                                  onClick={() => {
                                    handleHeaderMenuTracking(y.name, 0)
                                    if (!y.children?.length) {
                                      setOpenMenuItem(undefined)
                                    } else {
                                      setOpenMenuItem(openMenuItem === y ? undefined : y)
                                    }
                                  }}
                                />
                                {!!y.children?.length && (
                                  <Nav
                                    level={1}
                                    item={y}
                                    open={openMenuItem === y}
                                    setOpen={(v) => {
                                      if (v) {
                                        setOpenMenuItem(y)
                                      } else {
                                        setOpenMenuItem(undefined)
                                      }
                                    }}
                                  />
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
              <WLink
                href={'/'}
                onClick={() => handleHeaderTracking('logo')}
                className={styles.logo}
              >
                <Logo />
              </WLink>
              <div className={styles.mainActions}>
                <SearchTrigger
                  onClick={() => {
                    handleHeaderTracking('search')
                    setSearchOpen(true)
                  }}
                  className={styles.searchDesktop}
                />
                {router.locale === 'it-it' ? (
                  utente && !utente.guest ? (
                    <HeaderMenuItem
                      item={{ icon: 'profile', name: t('Profilo'), url: '/myaccount/' }}
                      variant="icon"
                      onClick={() => handleHeaderTracking('account')}
                      notification={utente?.progressoProfilo < 100}
                      className={styles.profileTriggerDesktop}
                    />
                  ) : (
                    <Button
                      label={t('Accedi o registrati')}
                      href="/login"
                      onClick={() => handleHeaderTracking('Accedi o registrati')}
                      size="sm"
                      className={styles.profileTriggerDesktop}
                    />
                  )
                ) : null}
                {utente && ecommerce && (
                  <HeaderMenuItem
                    item={{
                      icon: 'favorite_unmarked',
                      name: t('Wishlist'),
                      url: '/myaccount/wishlist/',
                    }}
                    onClick={() => handleHeaderTracking('wishlist')}
                    variant="icon"
                  />
                )}
                {ecommerce && (
                  <HeaderMenuItem
                    item={{ icon: 'cart', name: t('Carrello') }}
                    variant="icon"
                    badge={quantity}
                    onClick={() => {
                      handleHeaderTracking('cart')
                      setCartOpen(true)
                    }}
                    className={styles.cartIconDesktop}
                  />
                )}
                {ecommerce && (
                  <HeaderMenuItem
                    item={{ icon: 'cart', name: t('Carrello') }}
                    variant="icon"
                    badge={quantity}
                    onClick={() => {
                      handleHeaderTracking('cart')
                      router.push('/cart')
                    }}
                    className={styles.cartIconMobile}
                  />
                )}
              </div>
            </div>
          </WContainer>
        </div>
        <div className={styles.searchMobileBox}>
          <WContainer>
            {!searchOpen && (
              <SearchTrigger
                onClick={() => {
                  handleHeaderTracking('search')
                  setSearchOpen(true)
                }}
                className={styles.searchMobile}
              />
            )}
          </WContainer>
        </div>
        <Nav
          item={{
            ...mainMenu,
            children: (mainMenu?.children || []).concat([
              attivitaMenu || [],
              {
                pk: 'actions_mobile',
                style: 'categorie',
                children: [
                  {
                    pk: 'actions_mobile_accedi',
                    name: utente ? t('Il mio profilo') : t('Accedi o registrati'),
                    icon: 'profile',
                    url: '/',
                  },
                  ...(ecommerce
                    ? [
                        {
                          pk: 'actions_mobile_preferiti',
                          name: t('I miei preferiti'),
                          icon: 'favorite_unmarked',
                          url: '/myaccount/wishlist/',
                        },
                      ]
                    : []),
                ],
              },
              {
                pk: 'extra_mobile',
                style: 'categorie',
                children: [...(topMenu?.children || []), ...(utilsMenu?.children || [])],
              },
            ]),
          }}
          level={0}
          open={menuMobileOpen}
          setOpen={setMenuMobileOpen}
        />
      </header>
      <CartDrawer open={cartOpen} onClose={() => setCartOpen(false)} />
      <SearchModal open={searchOpen} onClose={() => setSearchOpen(false)} />
    </>
  )
}

export default Header
