import React from 'react'
import styles from './MyAccountSidebar.module.sass'
import { MenuItemType } from '@utils/types'
import { LogoutButton, SidebarLink, WLink } from '@components/atoms'
import { getIcon } from '@utils/icons'
import { useRouter } from 'next/router'
import { isEcommerce } from '@utils/index'

interface Props {
  activeKey?: string
  className?: string
}

const MyAccountSidebar = (props: Props) => {
  const { activeKey = undefined, className = '' } = props

  const router = useRouter()
  const ecommerce = isEcommerce(router.locale)

  const items: MenuItemType[] = [
    {
      pk: 1,
      name: '',
      children: [
        {
          pk: 11,
          name: 'Dashboard',
          url: '/myaccount/',
          icon: 'dashboard',
        },
      ],
    },
    {
      pk: 2,
      name: 'Il mio account',
      children: [
        {
          pk: 21,
          key: 'profilo',
          name: 'Il mio profilo',
          url: '/myaccount/profile/',
          icon: 'profile',
        },
        {
          pk: 22,
          key: 'indirizzi',
          name: 'I miei indirizzi',
          url: '/myaccount/addresses/',
          icon: 'map',
        },
        ...(ecommerce
          ? [
              {
                pk: 23,
                key: 'ordini',
                name: 'I miei ordini',
                url: '/myaccount/orders/',
                icon: 'box',
              },
            ]
          : []),
        {
          pk: 24,
          key: 'privacy',
          name: 'Privacy e preferenze',
          url: '/myaccount/privacy/',
          icon: 'lock',
        },
      ],
    },
    {
      pk: 3,
      name: 'Certificati di garanzia',
      children: [
        {
          pk: 31,
          key: 'certificati',
          name: 'Certificati di garanzia',
          url: '/myaccount/warranty/',
          icon: 'document',
        },
        {
          pk: 32,
          key: 'certificati_search',
          name: 'Cerca un certificato',
          url: '/myaccount/warranty/certificate/',
          icon: 'search',
        },
      ],
    },
  ]

  return (
    <aside className={`${styles.root} ${className}`}>
      <div className={styles.list}>
        {items.map((x) => (
          <div className={styles.block} key={x.pk}>
            {!!x.name && <div className={styles.blockTitle}>{x.name}</div>}
            <div className={styles.blockList}>
              {x.children.map((y) => (
                <SidebarLink
                  key={y.pk}
                  item={{
                    iconLeft: getIcon(y.icon),
                    name: y.name,
                    url: y.url,
                  }}
                  active={activeKey === y.key}
                  className={styles.item}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <LogoutButton />
    </aside>
  )
}

export default MyAccountSidebar
