import React from 'react'
import useTrans from '@hooks/useTrans'
import { priceNotation } from '@utils/safe'
import { RadioMarked, RadioUnmarked } from '@components/icons'
import { FormError, WImage } from 'components/atoms'
import styles from './FormRadio.module.sass'

export interface OptionProps {
  id: string
  variant?:
    | ''
    | 'prodottoCard'
    | 'filtroRadio'
    | 'horizontal'
    | 'horizontal-half'
    | 'horizontal-buttons'
  label?: string
  description?: string
  price?: number
  loghi?: { url: string; alt: string }[]
  name?: string
  value?: any
  checked?: boolean
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props {
  id: string
  name?: string
  value?: any
  variant?:
    | ''
    | 'prodottoCard'
    | 'filtroRadio'
    | 'horizontal'
    | 'horizontal-half'
    | 'horizontal-buttons'
  className?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  options: OptionProps[]
  radioLayout?: 'vertical' | 'horizontal'
}

const FormRadioOption = (props: OptionProps) => {
  const {
    id = '',
    variant = '',
    label = '',
    description = undefined,
    price = undefined,
    loghi = [],
    name = '',
    value = '',
    checked = false,
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    onChange = () => {},
  } = props

  const t = useTrans()

  const classNames = `
    ${styles.option}
    ${variant ? styles[`option--${variant}`] : ''}
    ${disabled ? styles['option--disabled'] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          id={id}
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="radio"
          readOnly={readOnly}
          required={required}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={styles.styledInput}>{checked ? <RadioMarked /> : <RadioUnmarked />}</span>
        {!description && !price ? (
          <span className={styles.label}>{label}</span>
        ) : (
          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.contentHead}>
                <span className={styles.label}>{label}</span>
                {!!price && <span className={styles.price}>{priceNotation(price, true)}</span>}
                {price === 0 && <span className={styles.price}>{t('Gratuito')}</span>}
              </div>
              {!!description && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
            {!!loghi.length && (
              <div className={styles.loghi}>
                {loghi.map((x) => (
                  <img src={x.url} alt={x.alt} key={x.url} loading="lazy" />
                ))}
              </div>
            )}
          </div>
        )}
      </label>
    </div>
  )
}

const FormRadio = (props: Props) => {
  const {
    id = '',
    name = '',
    value = null,
    variant = '',
    className = '',
    readOnly = false,
    required = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    options = [],
  } = props

  const onOptionChange = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly) onChange(e)
  }

  const classNames = `
    ${styles.root} 
    ${variant ? styles[`root--${variant}`] : ''}
    ${disabled ? styles['root--disabled'] : ''}
    ${status ? styles[`root--${status}`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <div className={styles.options}>
        {options?.map((option) => (
          <FormRadioOption
            id={`${id}-${option.value}`}
            name={name}
            variant={variant}
            label={option.label}
            description={option.description}
            price={option.price}
            loghi={option.loghi}
            value={option.value}
            checked={option.value === value}
            onChange={onOptionChange}
            readOnly={readOnly || option.readOnly}
            required={required || option.required}
            disabled={disabled || option.disabled}
            key={`formradiooption-${id}-${option.value}`}
          />
        ))}
      </div>
      <FormError
        id={`${id}_error`}
        message={errorMessage}
        visible={status === 'error'}
        className={styles.error}
      />
    </div>
  )
}

export default FormRadio
