import React from 'react'

function Text({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--text${className ? ' ' + className : ''}`}
    >
      <path
        d="M10 34v4h28v-4h-28zm9-8.4h10l1.8 4.4h4.2l-9.5-22h-3l-9.5 22h4.2l1.8-4.4zm5-13.64l3.74 10.04h-7.48l3.74-10.04z"
        fill="currentColor"
      />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default Text
