import { useState, useEffect } from 'react'
import { RES_MD_MIN } from '../utils/settings'

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width
        setIsMobile(width < RES_MD_MIN)
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}
