import React from 'react'
import { MenuItemType } from '@utils/types'
import { WImage, Button, WContainer } from '@components/atoms'
import styles from './PageNotFoundHead.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const PageNotFoundHead = (props: Props) => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`${styles.root} ${className}`}>
      <WContainer>
        <div className={`${styles.layout}`}>
          <div className={styles.main}>
            {(!!item.name || !!item.description) && (
              <div className={styles.content}>
                {!!item.name && <p className={styles.title}>{item.name}</p>}
                {!!item.description && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                )}
              </div>
            )}
            {!!item.url && !!item.ctaLabel && (
              <div className={styles.ctaBox}>
                <Button label={item.ctaLabel} href={item.url} newPage={item.blank} size="lg" />
              </div>
            )}
          </div>
          <div className={`${styles.imageBox}`}>
            {!!item.image && (
              <WImage
                src={item.image}
                maxWidth={767}
                maxHeight={500}
                objectFit="cover"
                alt={item.alt}
                title={item.alt}
                staticMedia
                loading="eager"
                priority
              />
            )}
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default PageNotFoundHead
