import React, { useEffect, useRef, useState } from 'react'
import { Plus, Minus } from '@components/icons'
import { ProdottoNode, ProdottoWarrantyNode } from '@gql/graphql'
import styles from './Accordion.module.sass'
import { useRouter } from 'next/router'
import { scrollTo } from '@utils/safe'
import { handleCartInteractionTracking } from '@utils/tracking'

interface Props {
  id: string
  label?: string
  prodotto?: ProdottoNode | ProdottoWarrantyNode
  variant?: '' | 'filter' | 'faq'
  badge?: number
  isOpen?: boolean
  editMode?: boolean
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

const Accordion = (props: Props) => {
  const {
    id = undefined,
    label = undefined,
    prodotto = undefined,
    variant = '',
    badge = undefined,
    children = undefined,
    isOpen = false,
    editMode = false,
    className = '',
    onClick = () => undefined,
  } = props

  const router = useRouter()

  const [open, setOpen] = useState(isOpen)
  const [transitioning, setTransitioning] = useState(false)
  const [height, setHeight] = useState(undefined)

  const innerContentRef = useRef<HTMLDivElement>(null)
  const accordionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHeight(open ? innerContentRef.current?.clientHeight : 0)
    setTransitioning(true)
    setTimeout(() => {
      setTransitioning(false)
      setHeight(undefined)
    }, 300)
  }, [open])

  useEffect(() => {
    if (router.asPath?.split('#')?.[1] === id) {
      setOpen(true)
      scrollTo(accordionRef?.current, 0)
    }
  }, [router.asPath])

  const toggleOpen = () => {
    setHeight(open ? innerContentRef.current?.clientHeight : 0)
    setTimeout(() => setOpen(!open), 1)

    if (router.asPath.includes('cart')) {
      handleCartInteractionTracking(label, open)
    }
  }

  return (
    <div
      className={`${styles.root} ${editMode ? styles['root--editMode'] : ''} ${
        open || editMode ? styles['root--open'] : ''
      } ${transitioning ? styles['root--transitioning'] : ''} ${
        variant ? styles[`root--${variant}`] : ''
      } ${className}`}
      ref={accordionRef}
    >
      <h3 id={`${id}_label`}>
        <button
          type="button"
          className={styles.trigger}
          onClick={() => {
            if (!editMode) {
              toggleOpen()
              onClick()
            }
          }}
          aria-expanded={open}
          aria-controls={`${id}_panel`}
        >
          {prodotto ? (
            <div className={styles.prodotto}>
              {!!prodotto.categoria?.nome && (
                <span className={styles.category}>{prodotto.categoria.nome}</span>
              )}
              {!!prodotto.nome && <span className={styles.productName}>{prodotto.nome}</span>}
            </div>
          ) : (
            <span>{label}</span>
          )}
          {!!badge && <span className={styles.badge}>{badge}</span>}
          <span className={styles.arrow}>{open || editMode ? <Minus /> : <Plus />}</span>
        </button>
      </h3>
      <div
        id={`${id}_panel`}
        aria-labelledby={`${id}_label`}
        className={styles.content}
        style={{
          maxHeight: height,
        }}
      >
        <div className={styles.innerContent} ref={innerContentRef}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default Accordion
